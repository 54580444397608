import data from './data'

export type Stop = {
    time: number,
    name: string,
    notes?: string
}

export type StopInfo = {
    stop: Stop,
    isCurrentlyStopped: boolean,
}

export class Stops {
    static next(time: number, speed: number = -1): StopInfo {
        for (let i = 0; i < data.length - 1; i++) {
            let last: Stop = data[i];
            let next: Stop = data[i+1];

            const afterMostRecentStop = last.time <= time && next.time >= time;

            if (afterMostRecentStop && last.time + 660 >= time && speed <= 1) {
                return {stop: last, isCurrentlyStopped: true}
            }

            if (last.time <= time && next.time >= time) {
                return {stop: next, isCurrentlyStopped: false};
            }
        }

        return {stop: data[data.length - 1], isCurrentlyStopped: (speed < 1)};
    }
}
import waypointsData from './data'

type Position = {
    latitude: number
    longitude: number
    elevation: number
}

export class Waypoint {
    time: number
    position: Position
    speed: number

    static fromArray(input: [number, number, number, number, number]): Waypoint {
        return new Waypoint(input[0], { latitude: input[1], longitude: input[2], elevation: input[3] }, input[4])
    }

    constructor(time: number, position: Position, speed: number) {
        this.time = time
        this.position = position
        this.speed = speed
    }
}

export class Waypoints {
    static waypoints: Waypoint[]

    static load(): void {
        if (this.waypoints !== undefined) {
            return;
        }
        
        this.waypoints = waypointsData.map((el) => {
            return Waypoint.fromArray(el as [number, number, number, number, number])
        });
    }

    static current(time: number): Waypoint {
        this.load();

        for (let i = 0; i < this.waypoints.length - 1; i++) {
            let curr: Waypoint = this.waypoints[i];
            let next: Waypoint = this.waypoints[i+1];

            if (curr.time <= time && next.time > time) {
                return curr;
            }
        }

        return this.waypoints[this.waypoints.length - 1];
    }
}
import { Component, ReactNode } from 'react';
import 'react-d3-speedometer'
import ReactSpeedometer from 'react-d3-speedometer';

type Props = {
    value: number
}

class Speedometer extends Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    render(): ReactNode {
        return (
            <ReactSpeedometer 
                value={this.props.value} 
                maxValue={140} 
                segments={7} 
                needleHeightRatio={0.7} 
                currentValueText="${value} km/h"
                textColor="#fff"
            />
        )
    }
}

export default Speedometer
import React from 'react';
import './App.css';
import Speedometer from './Speedometer'
import { Waypoint, Waypoints } from './waypoints/waypoints';
import { NsbLogo } from './NsbLogo'
import { StopInfo, Stops } from './stops/stops'
import { Video } from './Video'
import { Map } from './Map'
import { NextStopDisplay } from './NextStopDisplay';

type State = {
  time: number,
  waypoint: Waypoint,
  stopInfo: StopInfo,
}

class App extends React.Component<{}, State> {
  constructor(props?: object) {
    super(props ?? {})
    this.state = {
      time: 0,
      waypoint: Waypoints.current(0),
      stopInfo: Stops.next(0, 0)
    }

    this.tick = this.tick.bind(this);
  }
  
  tick(video: HTMLVideoElement): void {
    const waypoint = Waypoints.current(video.currentTime)

    this.setState({
      time: video.currentTime,
      waypoint: waypoint,
      stopInfo: Stops.next(video.currentTime, waypoint.speed)
    })
  }

  render() {
    const videoUrls = [
      "https://nrk_minutt-vh.akamaihd.net/pd_only/spring_1266.webm",
      "https://nrk_minutt-vh.akamaihd.net/pd_only/spring_1266.mp4",
    ]

    return (
      <div>
        <div className="grid-container">
          <div className="video">
            <Video showControls={true} videoLinks={videoUrls} onTick={this.tick} startTime={165} />
          </div>
          <div className="map">
            <Map waypoint={this.state.waypoint} />
          </div>
          <div className="speed">
            <Speedometer value={Math.round(this.state.waypoint.speed * 3.6)} />
          </div>
          <div className="stationInfo">
            <div className="logo">
              <NsbLogo color="white" />
            </div>
            <NextStopDisplay stopInfo={this.state.stopInfo} />
          </div>
        </div>
      </div>
    );
  }
}


export default App;

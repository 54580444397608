export default [
  [
    0,
    63.43674,
    10.4007,
    10.5,
    0
  ],
  [
    169,
    63.43674,
    10.4007,
    10.5,
    0
  ],
  [
    171,
    63.43674,
    10.4007,
    10.5,
    0
  ],
  [
    173,
    63.43673,
    10.40087,
    9.4,
    1.55
  ],
  [
    175,
    63.43674,
    10.40094,
    9.3,
    2.04
  ],
  [
    177,
    63.43674,
    10.40103,
    9.3,
    2.65
  ],
  [
    179,
    63.43675,
    10.40115,
    9.3,
    3.32
  ],
  [
    181,
    63.43676,
    10.40129,
    9.3,
    3.94
  ],
  [
    183,
    63.43678,
    10.40146,
    9.3,
    4.47
  ],
  [
    185,
    63.43679,
    10.40165,
    9.3,
    4.91
  ],
  [
    187,
    63.43681,
    10.40185,
    9.4,
    5.31
  ],
  [
    189,
    63.43682,
    10.40206,
    9.5,
    5.66
  ],
  [
    191,
    63.43684,
    10.4023,
    9.5,
    5.96
  ],
  [
    193,
    63.43686,
    10.40254,
    9.6,
    6.25
  ],
  [
    195,
    63.43688,
    10.40279,
    9.7,
    6.52
  ],
  [
    197,
    63.4369,
    10.40305,
    9.7,
    6.74
  ],
  [
    199,
    63.43691,
    10.40333,
    9.9,
    6.98
  ],
  [
    201,
    63.43692,
    10.40361,
    10,
    7.23
  ],
  [
    203,
    63.43694,
    10.40391,
    10.1,
    7.45
  ],
  [
    205,
    63.43695,
    10.40421,
    10.2,
    7.66
  ],
  [
    207,
    63.43695,
    10.40452,
    10.4,
    7.85
  ],
  [
    209,
    63.43696,
    10.40484,
    10.6,
    8.04
  ],
  [
    211,
    63.43697,
    10.40517,
    10.7,
    8.28
  ],
  [
    213,
    63.43698,
    10.4055,
    11,
    8.39
  ],
  [
    215,
    63.43699,
    10.40584,
    11.2,
    8.54
  ],
  [
    217,
    63.437,
    10.40619,
    11.4,
    8.68
  ],
  [
    219,
    63.43701,
    10.40654,
    11.5,
    8.81
  ],
  [
    221,
    63.43701,
    10.40689,
    11.7,
    8.94
  ],
  [
    223,
    63.43702,
    10.40725,
    11.8,
    9.07
  ],
  [
    225,
    63.43703,
    10.40762,
    11.8,
    9.25
  ],
  [
    227,
    63.43704,
    10.408,
    11.8,
    9.45
  ],
  [
    229,
    63.43705,
    10.40838,
    11.7,
    9.67
  ],
  [
    231,
    63.43706,
    10.40877,
    11.7,
    9.88
  ],
  [
    233,
    63.43707,
    10.40917,
    11.6,
    10.09
  ],
  [
    235,
    63.43708,
    10.40958,
    11.6,
    10.35
  ],
  [
    237,
    63.43709,
    10.41,
    11.5,
    10.46
  ],
  [
    239,
    63.4371,
    10.41042,
    11.4,
    10.56
  ],
  [
    241,
    63.43712,
    10.41084,
    11.4,
    10.66
  ],
  [
    243,
    63.43713,
    10.41127,
    11.6,
    10.74
  ],
  [
    245,
    63.43714,
    10.4117,
    11.9,
    10.8
  ],
  [
    247,
    63.43715,
    10.41214,
    12.1,
    10.85
  ],
  [
    249,
    63.43716,
    10.41257,
    12.3,
    10.89
  ],
  [
    251,
    63.43717,
    10.41301,
    12.7,
    10.89
  ],
  [
    253,
    63.43718,
    10.41345,
    13,
    10.88
  ],
  [
    255,
    63.43719,
    10.41388,
    13.1,
    10.87
  ],
  [
    257,
    63.4372,
    10.41432,
    13.1,
    10.87
  ],
  [
    259,
    63.43722,
    10.41475,
    12.9,
    10.97
  ],
  [
    261,
    63.43723,
    10.4152,
    12.7,
    11.15
  ],
  [
    263,
    63.43725,
    10.41565,
    12.5,
    11.43
  ],
  [
    265,
    63.43726,
    10.41611,
    12.3,
    11.78
  ],
  [
    267,
    63.43728,
    10.41659,
    12.1,
    12.17
  ],
  [
    269,
    63.4373,
    10.41709,
    11.9,
    12.66
  ],
  [
    271,
    63.43732,
    10.4176,
    11.7,
    13.25
  ],
  [
    273,
    63.43735,
    10.41815,
    11.5,
    13.86
  ],
  [
    275,
    63.43737,
    10.41871,
    11.3,
    14.5
  ],
  [
    277,
    63.4374,
    10.41931,
    11,
    15.16
  ],
  [
    279,
    63.43743,
    10.41993,
    10.7,
    15.87
  ],
  [
    281,
    63.43747,
    10.42057,
    10.7,
    16.51
  ],
  [
    283,
    63.43752,
    10.42124,
    10.9,
    17.11
  ],
  [
    285,
    63.4376,
    10.42191,
    11.3,
    17.59
  ],
  [
    287,
    63.43769,
    10.4226,
    11.8,
    17.99
  ],
  [
    289,
    63.4378,
    10.42329,
    12.2,
    18.29
  ],
  [
    291,
    63.43793,
    10.42397,
    12.9,
    18.57
  ],
  [
    293,
    63.43808,
    10.42464,
    13.4,
    18.86
  ],
  [
    295,
    63.43826,
    10.4253,
    13.9,
    19.11
  ],
  [
    297,
    63.43844,
    10.42595,
    14.5,
    19.38
  ],
  [
    299,
    63.43863,
    10.42661,
    15.1,
    19.64
  ],
  [
    301,
    63.43883,
    10.42728,
    15.7,
    19.86
  ],
  [
    303,
    63.43901,
    10.42796,
    16.5,
    20.08
  ],
  [
    305,
    63.43919,
    10.42867,
    17.1,
    20.27
  ],
  [
    307,
    63.43937,
    10.42939,
    17.6,
    20.45
  ],
  [
    309,
    63.43953,
    10.43013,
    18.2,
    20.62
  ],
  [
    311,
    63.43969,
    10.43088,
    18.6,
    20.82
  ],
  [
    313,
    63.43983,
    10.43165,
    19.2,
    20.99
  ],
  [
    315,
    63.43997,
    10.43244,
    19.9,
    21.11
  ],
  [
    317,
    63.4401,
    10.43324,
    20.4,
    21.3
  ],
  [
    319,
    63.44021,
    10.43406,
    20.9,
    21.46
  ],
  [
    321,
    63.44032,
    10.43489,
    21.1,
    21.63
  ],
  [
    323,
    63.44042,
    10.43573,
    22,
    21.76
  ],
  [
    325,
    63.4405,
    10.43659,
    22.6,
    21.93
  ],
  [
    327,
    63.44058,
    10.43746,
    23.2,
    22.06
  ],
  [
    329,
    63.44064,
    10.43833,
    23.7,
    22.2
  ],
  [
    331,
    63.44069,
    10.43922,
    24.2,
    22.33
  ],
  [
    333,
    63.44073,
    10.44012,
    24.6,
    22.45
  ],
  [
    335,
    63.44075,
    10.44102,
    25,
    22.58
  ],
  [
    337,
    63.44076,
    10.44193,
    25.5,
    22.69
  ],
  [
    339,
    63.44076,
    10.44284,
    26.2,
    22.78
  ],
  [
    341,
    63.44075,
    10.44376,
    27.1,
    22.87
  ],
  [
    343,
    63.44073,
    10.44468,
    27.6,
    22.97
  ],
  [
    346,
    63.44066,
    10.44606,
    28.3,
    23.1
  ],
  [
    348,
    63.4406,
    10.44698,
    28.9,
    23.23
  ],
  [
    350,
    63.44053,
    10.44791,
    29.5,
    23.37
  ],
  [
    352,
    63.44044,
    10.44882,
    30.7,
    23.07
  ],
  [
    354,
    63.44034,
    10.44974,
    31.3,
    23.52
  ],
  [
    356,
    63.44023,
    10.45066,
    31.8,
    23.52
  ],
  [
    358,
    63.4401,
    10.45157,
    32.4,
    23.82
  ],
  [
    360,
    63.43995,
    10.45248,
    32.8,
    23.82
  ],
  [
    362,
    63.43979,
    10.45338,
    33.3,
    23.82
  ],
  [
    364,
    63.43962,
    10.45427,
    33.7,
    24.09
  ],
  [
    366,
    63.43943,
    10.45515,
    34.3,
    24.09
  ],
  [
    368,
    63.43923,
    10.45602,
    34.8,
    24.28
  ],
  [
    370,
    63.43902,
    10.45687,
    35.4,
    24.28
  ],
  [
    372,
    63.43879,
    10.45772,
    36.1,
    24.28
  ],
  [
    374,
    63.43856,
    10.45855,
    36.7,
    24.49
  ],
  [
    376,
    63.43832,
    10.45939,
    37.3,
    24.49
  ],
  [
    378,
    63.43809,
    10.46023,
    37.7,
    24.69
  ],
  [
    380,
    63.43785,
    10.46108,
    37.8,
    24.84
  ],
  [
    382,
    63.43761,
    10.46193,
    37.8,
    24.89
  ],
  [
    384,
    63.43737,
    10.46277,
    37.8,
    24.89
  ],
  [
    386,
    63.43714,
    10.46362,
    37.8,
    24.93
  ],
  [
    388,
    63.4369,
    10.46447,
    37.8,
    24.93
  ],
  [
    390,
    63.43667,
    10.46534,
    37.7,
    24.95
  ],
  [
    392,
    63.43648,
    10.46624,
    37.6,
    24.98
  ],
  [
    394,
    63.43633,
    10.46719,
    37.5,
    25.02
  ],
  [
    396,
    63.43622,
    10.46817,
    37.4,
    25.04
  ],
  [
    398,
    63.43616,
    10.46916,
    37.3,
    25.12
  ],
  [
    400,
    63.4361,
    10.47016,
    37.2,
    25.18
  ],
  [
    402,
    63.43606,
    10.47117,
    37,
    25.2
  ],
  [
    404,
    63.43605,
    10.47218,
    36.6,
    25.23
  ],
  [
    406,
    63.43609,
    10.47319,
    36.1,
    25.2
  ],
  [
    408,
    63.43615,
    10.47418,
    35.5,
    24.86
  ],
  [
    410,
    63.43624,
    10.47516,
    35,
    24.64
  ],
  [
    412,
    63.43633,
    10.47612,
    34.4,
    24.51
  ],
  [
    414,
    63.43643,
    10.47706,
    34,
    23.99
  ],
  [
    416,
    63.43652,
    10.47799,
    33.7,
    23.44
  ],
  [
    418,
    63.43658,
    10.47891,
    33.4,
    22.74
  ],
  [
    420,
    63.43661,
    10.47981,
    33.2,
    22.26
  ],
  [
    422,
    63.43662,
    10.48069,
    32.9,
    22.14
  ],
  [
    424,
    63.43661,
    10.48158,
    32.5,
    22.13
  ],
  [
    426,
    63.4366,
    10.48247,
    32.3,
    22.26
  ],
  [
    428,
    63.43658,
    10.48336,
    32.1,
    22.48
  ],
  [
    430,
    63.43655,
    10.48426,
    32.7,
    22.57
  ],
  [
    432,
    63.43652,
    10.48518,
    32.4,
    22.88
  ],
  [
    434,
    63.43646,
    10.4861,
    32.1,
    23.31
  ],
  [
    436,
    63.43638,
    10.48702,
    31.6,
    23.58
  ],
  [
    438,
    63.43627,
    10.48794,
    31.1,
    23.86
  ],
  [
    440,
    63.43614,
    10.48885,
    30.6,
    24.14
  ],
  [
    442,
    63.43598,
    10.48976,
    30,
    24.27
  ],
  [
    444,
    63.4358,
    10.49066,
    29.6,
    24.54
  ],
  [
    446,
    63.43561,
    10.49155,
    29.3,
    24.62
  ],
  [
    448,
    63.4354,
    10.49242,
    28.9,
    24.71
  ],
  [
    450,
    63.43514,
    10.49324,
    28.6,
    24.8
  ],
  [
    452,
    63.43485,
    10.49398,
    28.2,
    24.73
  ],
  [
    454,
    63.4345,
    10.49462,
    27.9,
    24.82
  ],
  [
    456,
    63.43414,
    10.49521,
    27.6,
    24.81
  ],
  [
    458,
    63.43378,
    10.49581,
    27.4,
    24.81
  ],
  [
    460,
    63.43345,
    10.49649,
    26.9,
    24.83
  ],
  [
    462,
    63.43317,
    10.49726,
    26.5,
    24.86
  ],
  [
    464,
    63.43293,
    10.4981,
    26.2,
    24.91
  ],
  [
    466,
    63.43275,
    10.49901,
    25.8,
    24.95
  ],
  [
    468,
    63.43261,
    10.49996,
    25.4,
    25.01
  ],
  [
    470,
    63.4325,
    10.50094,
    25.2,
    25.08
  ],
  [
    472,
    63.43239,
    10.50192,
    24.9,
    25.14
  ],
  [
    474,
    63.43226,
    10.50289,
    24.6,
    25.18
  ],
  [
    476,
    63.43211,
    10.50383,
    24.3,
    25.24
  ],
  [
    478,
    63.43192,
    10.50476,
    24.1,
    25.25
  ],
  [
    480,
    63.43173,
    10.50567,
    23.7,
    25.32
  ],
  [
    482,
    63.43153,
    10.50659,
    23.4,
    25.39
  ],
  [
    484,
    63.43133,
    10.50751,
    23,
    25.47
  ],
  [
    486,
    63.43113,
    10.50843,
    22.6,
    25.55
  ],
  [
    488,
    63.43093,
    10.50935,
    22.2,
    25.62
  ],
  [
    490,
    63.43072,
    10.51026,
    21.8,
    25.7
  ],
  [
    492,
    63.43049,
    10.51117,
    21.5,
    25.75
  ],
  [
    494,
    63.43026,
    10.51205,
    21.2,
    25.8
  ],
  [
    496,
    63.43,
    10.51292,
    21,
    25.82
  ],
  [
    498,
    63.42974,
    10.51378,
    20.7,
    25.87
  ],
  [
    500,
    63.42947,
    10.51461,
    20.5,
    25.92
  ],
  [
    502,
    63.42918,
    10.51543,
    20.2,
    25.96
  ],
  [
    504,
    63.42888,
    10.51623,
    19.9,
    26.03
  ],
  [
    506,
    63.42856,
    10.51701,
    19.4,
    26.1
  ],
  [
    508,
    63.42824,
    10.51777,
    18.9,
    26.16
  ],
  [
    510,
    63.42792,
    10.51854,
    18.6,
    26.21
  ],
  [
    512,
    63.4276,
    10.51932,
    18.3,
    26.25
  ],
  [
    514,
    63.42731,
    10.52015,
    18,
    26.28
  ],
  [
    516,
    63.42705,
    10.52103,
    17.6,
    26.3
  ],
  [
    518,
    63.42683,
    10.52196,
    17.2,
    26.37
  ],
  [
    520,
    63.42665,
    10.52294,
    16.7,
    26.44
  ],
  [
    522,
    63.42652,
    10.52396,
    16.4,
    26.51
  ],
  [
    524,
    63.42643,
    10.525,
    16,
    26.58
  ],
  [
    526,
    63.4264,
    10.52607,
    15.6,
    26.64
  ],
  [
    528,
    63.42642,
    10.52714,
    15.4,
    26.69
  ],
  [
    530,
    63.42649,
    10.52819,
    15.2,
    26.74
  ],
  [
    532,
    63.42661,
    10.52924,
    15.1,
    26.75
  ],
  [
    534,
    63.42677,
    10.53025,
    14.9,
    26.75
  ],
  [
    536,
    63.42697,
    10.53123,
    14.8,
    26.76
  ],
  [
    538,
    63.42719,
    10.53219,
    14.7,
    26.76
  ],
  [
    540,
    63.42741,
    10.53314,
    14.6,
    26.74
  ],
  [
    542,
    63.42765,
    10.53407,
    14.5,
    26.7
  ],
  [
    544,
    63.42789,
    10.53499,
    14.4,
    26.67
  ],
  [
    546,
    63.42813,
    10.53592,
    14.4,
    26.64
  ],
  [
    548,
    63.42838,
    10.53683,
    14.4,
    26.53
  ],
  [
    550,
    63.42863,
    10.53773,
    14.5,
    26.02
  ],
  [
    552,
    63.42887,
    10.53861,
    14.5,
    25.4
  ],
  [
    554,
    63.42911,
    10.53946,
    14.3,
    24.77
  ],
  [
    556,
    63.42934,
    10.54029,
    14,
    24.06
  ],
  [
    558,
    63.42957,
    10.5411,
    13.7,
    23.22
  ],
  [
    560,
    63.42978,
    10.54189,
    13.5,
    22.58
  ],
  [
    562,
    63.42998,
    10.54266,
    13.2,
    22.32
  ],
  [
    564,
    63.43017,
    10.54344,
    12.9,
    22.23
  ],
  [
    566,
    63.43033,
    10.54426,
    12.7,
    22.21
  ],
  [
    568,
    63.43044,
    10.54511,
    12.4,
    22.25
  ],
  [
    570,
    63.4305,
    10.54599,
    12.1,
    22.35
  ],
  [
    572,
    63.43049,
    10.54689,
    11.8,
    22.54
  ],
  [
    574,
    63.43043,
    10.54778,
    11.4,
    22.62
  ],
  [
    576,
    63.43031,
    10.54864,
    11.1,
    22.75
  ],
  [
    578,
    63.43013,
    10.54947,
    10.7,
    22.86
  ],
  [
    580,
    63.42993,
    10.55027,
    10.4,
    22.98
  ],
  [
    582,
    63.42974,
    10.55109,
    10,
    23.17
  ],
  [
    584,
    63.42958,
    10.55195,
    9.6,
    23.36
  ],
  [
    586,
    63.42946,
    10.55285,
    9.2,
    23.54
  ],
  [
    588,
    63.42938,
    10.55378,
    9,
    23.73
  ],
  [
    590,
    63.42933,
    10.55473,
    8.7,
    23.93
  ],
  [
    592,
    63.42934,
    10.55569,
    8.4,
    24.14
  ],
  [
    594,
    63.42938,
    10.55666,
    8.2,
    24.35
  ],
  [
    596,
    63.42947,
    10.55761,
    8,
    24.53
  ],
  [
    598,
    63.4296,
    10.55855,
    8,
    24.7
  ],
  [
    600,
    63.42978,
    10.55946,
    8,
    24.84
  ],
  [
    602,
    63.43,
    10.56033,
    8,
    24.95
  ],
  [
    604,
    63.43026,
    10.56115,
    8.1,
    25.05
  ],
  [
    606,
    63.43056,
    10.56191,
    8.2,
    25.11
  ],
  [
    608,
    63.43089,
    10.56258,
    8.2,
    25.16
  ],
  [
    610,
    63.43126,
    10.56319,
    8.2,
    25.2
  ],
  [
    612,
    63.43162,
    10.56379,
    9.7,
    25.18
  ],
  [
    614,
    63.43197,
    10.56443,
    10.1,
    25.18
  ],
  [
    616,
    63.43229,
    10.56515,
    10.6,
    25.13
  ],
  [
    618,
    63.43256,
    10.56595,
    11,
    25.13
  ],
  [
    620,
    63.4328,
    10.5668,
    11.2,
    25.09
  ],
  [
    622,
    63.433,
    10.5677,
    11.3,
    25.17
  ],
  [
    624,
    63.43317,
    10.56864,
    11.2,
    25.28
  ],
  [
    627,
    63.43343,
    10.57006,
    10.9,
    25.44
  ],
  [
    629,
    63.43361,
    10.571,
    10.7,
    25.66
  ],
  [
    631,
    63.43381,
    10.57193,
    10.4,
    25.92
  ],
  [
    633,
    63.43403,
    10.57286,
    10.1,
    26.18
  ],
  [
    635,
    63.43427,
    10.57378,
    9.9,
    26.3
  ],
  [
    637,
    63.43452,
    10.5747,
    9.7,
    26.3
  ],
  [
    639,
    63.43478,
    10.5756,
    9.6,
    26.81
  ],
  [
    641,
    63.43506,
    10.57648,
    9.6,
    26.81
  ],
  [
    643,
    63.43536,
    10.57736,
    9.6,
    27.35
  ],
  [
    645,
    63.43567,
    10.57822,
    9.6,
    27.35
  ],
  [
    647,
    63.43599,
    10.57905,
    9.5,
    27.35
  ],
  [
    649,
    63.43632,
    10.57987,
    9.6,
    27.46
  ],
  [
    651,
    63.43666,
    10.58066,
    9.6,
    27.46
  ],
  [
    653,
    63.43701,
    10.58143,
    9.6,
    27.33
  ],
  [
    655,
    63.43734,
    10.58223,
    9.6,
    27.33
  ],
  [
    657,
    63.43765,
    10.58308,
    9.6,
    27.33
  ],
  [
    659,
    63.43793,
    10.58397,
    9.8,
    27.16
  ],
  [
    661,
    63.43819,
    10.58489,
    9.8,
    27.08
  ],
  [
    663,
    63.43844,
    10.58581,
    9.9,
    26.98
  ],
  [
    665,
    63.43868,
    10.58674,
    9.9,
    26.9
  ],
  [
    667,
    63.43893,
    10.58767,
    10,
    26.85
  ],
  [
    669,
    63.43918,
    10.58859,
    10.2,
    26.8
  ],
  [
    671,
    63.43942,
    10.58951,
    10.1,
    26.73
  ],
  [
    673,
    63.43966,
    10.59043,
    10.1,
    26.66
  ],
  [
    675,
    63.43991,
    10.59134,
    10.5,
    26.64
  ],
  [
    677,
    63.44015,
    10.59226,
    10.6,
    26.54
  ],
  [
    679,
    63.44039,
    10.59317,
    10.7,
    26.41
  ],
  [
    681,
    63.44063,
    10.59408,
    10.8,
    26.28
  ],
  [
    683,
    63.44086,
    10.59498,
    10.9,
    25.7
  ],
  [
    685,
    63.44108,
    10.59588,
    10.8,
    25.09
  ],
  [
    687,
    63.44128,
    10.59676,
    10.7,
    24.75
  ],
  [
    689,
    63.44146,
    10.59764,
    10.6,
    23.62
  ],
  [
    691,
    63.44163,
    10.59848,
    10.5,
    22.81
  ],
  [
    693,
    63.44179,
    10.59931,
    10.5,
    22.38
  ],
  [
    695,
    63.44194,
    10.60013,
    10.6,
    22.11
  ],
  [
    697,
    63.44209,
    10.60095,
    10.9,
    22.03
  ],
  [
    699,
    63.4422,
    10.60178,
    11,
    21.8
  ],
  [
    701,
    63.44225,
    10.60265,
    11.1,
    21.66
  ],
  [
    703,
    63.44224,
    10.60351,
    11.1,
    21.52
  ],
  [
    705,
    63.44217,
    10.60435,
    10.8,
    21.42
  ],
  [
    707,
    63.44204,
    10.60516,
    10.3,
    21.36
  ],
  [
    709,
    63.44186,
    10.60592,
    9.7,
    21.25
  ],
  [
    711,
    63.44163,
    10.60661,
    9.3,
    21.26
  ],
  [
    713,
    63.44136,
    10.60722,
    8.6,
    21.31
  ],
  [
    715,
    63.44105,
    10.60773,
    8,
    21.31
  ],
  [
    717,
    63.4407,
    10.60813,
    7.4,
    21.33
  ],
  [
    719,
    63.44033,
    10.60843,
    6.8,
    21.4
  ],
  [
    721,
    63.43996,
    10.60867,
    6.3,
    21.45
  ],
  [
    723,
    63.43958,
    10.60889,
    6,
    21.53
  ],
  [
    725,
    63.4392,
    10.60912,
    6,
    21.57
  ],
  [
    727,
    63.43884,
    10.60943,
    6,
    21.62
  ],
  [
    729,
    63.43849,
    10.60984,
    5.9,
    21.59
  ],
  [
    731,
    63.43818,
    10.61037,
    5.9,
    21.58
  ],
  [
    733,
    63.43792,
    10.611,
    5.9,
    21.54
  ],
  [
    735,
    63.4377,
    10.61171,
    5.9,
    21.47
  ],
  [
    737,
    63.43753,
    10.61248,
    6,
    21.44
  ],
  [
    739,
    63.43741,
    10.6133,
    6.1,
    21.3
  ],
  [
    741,
    63.43736,
    10.61414,
    6.3,
    21.19
  ],
  [
    743,
    63.43736,
    10.61498,
    6.6,
    21.07
  ],
  [
    745,
    63.43742,
    10.61581,
    6.9,
    20.94
  ],
  [
    747,
    63.43753,
    10.61661,
    7.2,
    20.85
  ],
  [
    749,
    63.43767,
    10.61739,
    7.3,
    20.78
  ],
  [
    751,
    63.43782,
    10.61815,
    7.5,
    20.74
  ],
  [
    753,
    63.43796,
    10.61892,
    7.7,
    20.71
  ],
  [
    755,
    63.43811,
    10.61969,
    7.9,
    20.7
  ],
  [
    757,
    63.43825,
    10.62045,
    8.1,
    20.67
  ],
  [
    759,
    63.43839,
    10.62121,
    8.2,
    20.63
  ],
  [
    761,
    63.43854,
    10.62198,
    8.4,
    20.62
  ],
  [
    763,
    63.43868,
    10.62274,
    8.5,
    20.61
  ],
  [
    765,
    63.43882,
    10.6235,
    8.7,
    20.62
  ],
  [
    767,
    63.43897,
    10.62426,
    8.8,
    20.67
  ],
  [
    769,
    63.43911,
    10.62503,
    8.9,
    20.73
  ],
  [
    771,
    63.43926,
    10.6258,
    9,
    20.81
  ],
  [
    773,
    63.4394,
    10.62657,
    9.2,
    20.91
  ],
  [
    775,
    63.43955,
    10.62734,
    9.4,
    20.99
  ],
  [
    777,
    63.43971,
    10.6281,
    9.6,
    21.11
  ],
  [
    779,
    63.43991,
    10.62883,
    9.7,
    21.23
  ],
  [
    781,
    63.44013,
    10.62952,
    9.8,
    21.36
  ],
  [
    783,
    63.44039,
    10.63016,
    10.1,
    21.49
  ],
  [
    785,
    63.44067,
    10.63077,
    10.4,
    21.62
  ],
  [
    787,
    63.44096,
    10.63135,
    10.7,
    21.7
  ],
  [
    789,
    63.44125,
    10.63193,
    11,
    21.76
  ],
  [
    791,
    63.44154,
    10.63252,
    11.5,
    21.77
  ],
  [
    793,
    63.44182,
    10.63313,
    12,
    21.79
  ],
  [
    795,
    63.44207,
    10.6338,
    12.4,
    21.75
  ],
  [
    797,
    63.44227,
    10.63454,
    12.8,
    21.68
  ],
  [
    799,
    63.44243,
    10.63534,
    13.2,
    21.61
  ],
  [
    801,
    63.44254,
    10.63617,
    13.6,
    21.55
  ],
  [
    803,
    63.44259,
    10.63702,
    14,
    21.48
  ],
  [
    805,
    63.4426,
    10.63788,
    14.3,
    21.44
  ],
  [
    807,
    63.44255,
    10.63873,
    14.6,
    21.38
  ],
  [
    809,
    63.44245,
    10.63956,
    15,
    21.3
  ],
  [
    811,
    63.44231,
    10.64035,
    15.2,
    21.24
  ],
  [
    813,
    63.44212,
    10.64109,
    15.2,
    21.17
  ],
  [
    815,
    63.4419,
    10.64179,
    15.3,
    21.17
  ],
  [
    817,
    63.44167,
    10.64249,
    15.3,
    21.2
  ],
  [
    819,
    63.44148,
    10.64322,
    15.3,
    21.28
  ],
  [
    821,
    63.44131,
    10.64399,
    15.2,
    21.41
  ],
  [
    823,
    63.44119,
    10.64481,
    15,
    21.53
  ],
  [
    825,
    63.4411,
    10.64566,
    14.9,
    21.66
  ],
  [
    827,
    63.44106,
    10.64652,
    14.8,
    21.8
  ],
  [
    829,
    63.44107,
    10.6474,
    14.8,
    21.9
  ],
  [
    831,
    63.44111,
    10.64827,
    14.7,
    21.99
  ],
  [
    833,
    63.44121,
    10.64913,
    14.6,
    22.05
  ],
  [
    835,
    63.44132,
    10.64998,
    14.4,
    22.05
  ],
  [
    837,
    63.44142,
    10.65084,
    14.5,
    22.11
  ],
  [
    839,
    63.44147,
    10.65171,
    14.7,
    22.13
  ],
  [
    841,
    63.44147,
    10.6526,
    14.3,
    22.14
  ],
  [
    843,
    63.4414,
    10.65348,
    13.8,
    22.19
  ],
  [
    845,
    63.44128,
    10.65433,
    13.1,
    22.48
  ],
  [
    847,
    63.44111,
    10.65514,
    12.5,
    22.33
  ],
  [
    849,
    63.44088,
    10.65589,
    11.9,
    22.42
  ],
  [
    851,
    63.44061,
    10.65656,
    11.3,
    22.54
  ],
  [
    853,
    63.4403,
    10.65714,
    10.6,
    22.65
  ],
  [
    855,
    63.43995,
    10.65765,
    10,
    22.75
  ],
  [
    857,
    63.4396,
    10.65814,
    9.6,
    22.87
  ],
  [
    859,
    63.43925,
    10.65864,
    9.4,
    23.01
  ],
  [
    861,
    63.4389,
    10.65914,
    9.3,
    23.1
  ],
  [
    863,
    63.43856,
    10.65968,
    9.3,
    23.17
  ],
  [
    865,
    63.43825,
    10.6603,
    9.3,
    23.24
  ],
  [
    867,
    63.43797,
    10.66101,
    9.4,
    23.36
  ],
  [
    869,
    63.43774,
    10.66179,
    9.5,
    23.46
  ],
  [
    871,
    63.43754,
    10.66262,
    9.7,
    23.58
  ],
  [
    873,
    63.43739,
    10.6635,
    10.1,
    23.64
  ],
  [
    875,
    63.43729,
    10.66442,
    10.6,
    23.66
  ],
  [
    877,
    63.43723,
    10.66537,
    11.1,
    23.65
  ],
  [
    879,
    63.43723,
    10.66631,
    11.6,
    23.61
  ],
  [
    881,
    63.43728,
    10.66725,
    12.2,
    23.59
  ],
  [
    883,
    63.43738,
    10.66818,
    12.8,
    23.57
  ],
  [
    886,
    63.43755,
    10.66954,
    13.6,
    23.58
  ],
  [
    888,
    63.43765,
    10.67046,
    14.1,
    23.64
  ],
  [
    890,
    63.43772,
    10.6714,
    14.7,
    23.71
  ],
  [
    892,
    63.43775,
    10.67235,
    15.4,
    23.79
  ],
  [
    894,
    63.43776,
    10.67331,
    15.8,
    23.85
  ],
  [
    896,
    63.43773,
    10.67427,
    16.4,
    24.03
  ],
  [
    898,
    63.43766,
    10.67523,
    16.9,
    24.21
  ],
  [
    900,
    63.43757,
    10.67618,
    17.5,
    24.21
  ],
  [
    902,
    63.43744,
    10.67711,
    18,
    24.21
  ],
  [
    904,
    63.43728,
    10.67803,
    18.4,
    24.48
  ],
  [
    906,
    63.4371,
    10.67895,
    18.5,
    24.48
  ],
  [
    908,
    63.43693,
    10.67986,
    18.5,
    24.88
  ],
  [
    910,
    63.43675,
    10.68079,
    18.5,
    24.88
  ],
  [
    912,
    63.43657,
    10.68173,
    18.5,
    24.88
  ],
  [
    914,
    63.43639,
    10.68268,
    18.5,
    25.58
  ],
  [
    916,
    63.4362,
    10.68364,
    18.5,
    26.05
  ],
  [
    918,
    63.43601,
    10.68461,
    18.6,
    26.33
  ],
  [
    920,
    63.43583,
    10.68559,
    18.9,
    26.59
  ],
  [
    922,
    63.43564,
    10.68658,
    19.4,
    26.79
  ],
  [
    924,
    63.43545,
    10.68757,
    19.8,
    26.89
  ],
  [
    926,
    63.43526,
    10.68856,
    20.3,
    26.89
  ],
  [
    928,
    63.43507,
    10.68955,
    20.7,
    26.86
  ],
  [
    930,
    63.43488,
    10.69054,
    21.2,
    26.82
  ],
  [
    932,
    63.43469,
    10.69153,
    21.6,
    26.78
  ],
  [
    934,
    63.4345,
    10.69252,
    21.8,
    26.75
  ],
  [
    936,
    63.43431,
    10.69351,
    21.9,
    26.78
  ],
  [
    938,
    63.43412,
    10.6945,
    21.9,
    26.86
  ],
  [
    940,
    63.43395,
    10.6955,
    22,
    26.94
  ],
  [
    942,
    63.43379,
    10.69652,
    21.9,
    27
  ],
  [
    944,
    63.43364,
    10.69756,
    21.9,
    27.13
  ],
  [
    946,
    63.43351,
    10.69861,
    21.9,
    27.1
  ],
  [
    948,
    63.4334,
    10.69966,
    21.8,
    27.04
  ],
  [
    950,
    63.4333,
    10.70072,
    21.8,
    26.98
  ],
  [
    952,
    63.43322,
    10.70179,
    22,
    26.96
  ],
  [
    954,
    63.43315,
    10.70285,
    22,
    26.86
  ],
  [
    956,
    63.43307,
    10.70392,
    22.1,
    26.8
  ],
  [
    958,
    63.43299,
    10.70497,
    22.1,
    26.74
  ],
  [
    960,
    63.43291,
    10.70603,
    22.1,
    26.69
  ],
  [
    962,
    63.43284,
    10.70708,
    22.1,
    26.63
  ],
  [
    964,
    63.43276,
    10.70813,
    21.8,
    26.26
  ],
  [
    966,
    63.43269,
    10.70916,
    21.7,
    25.82
  ],
  [
    968,
    63.43261,
    10.71018,
    21.6,
    25.44
  ],
  [
    970,
    63.43254,
    10.71117,
    21.6,
    24.96
  ],
  [
    972,
    63.43244,
    10.71214,
    21.6,
    24.83
  ],
  [
    974,
    63.43228,
    10.71306,
    21.3,
    24.67
  ],
  [
    976,
    63.43207,
    10.71393,
    20.9,
    24.66
  ],
  [
    978,
    63.43182,
    10.71475,
    20.5,
    24.68
  ],
  [
    980,
    63.43156,
    10.71555,
    20,
    24.73
  ],
  [
    982,
    63.43132,
    10.71639,
    19.6,
    24.77
  ],
  [
    984,
    63.43113,
    10.71729,
    19.2,
    24.87
  ],
  [
    986,
    63.43099,
    10.71823,
    18.8,
    24.93
  ],
  [
    988,
    63.4309,
    10.71921,
    18.4,
    24.97
  ],
  [
    990,
    63.43087,
    10.72021,
    18.1,
    25.01
  ],
  [
    992,
    63.4309,
    10.72121,
    17.8,
    25.06
  ],
  [
    994,
    63.43097,
    10.7222,
    17.5,
    25.12
  ],
  [
    996,
    63.43107,
    10.72319,
    17.2,
    25.18
  ],
  [
    998,
    63.43116,
    10.72418,
    17,
    25.27
  ],
  [
    1000,
    63.43125,
    10.72517,
    16.7,
    25.34
  ],
  [
    1002,
    63.43135,
    10.72617,
    16.5,
    25.4
  ],
  [
    1004,
    63.43144,
    10.72717,
    16.2,
    25.47
  ],
  [
    1006,
    63.43154,
    10.72817,
    15.9,
    25.53
  ],
  [
    1008,
    63.43163,
    10.72917,
    15.6,
    25.6
  ],
  [
    1010,
    63.43172,
    10.73018,
    15.3,
    25.67
  ],
  [
    1012,
    63.43182,
    10.73119,
    15,
    25.74
  ],
  [
    1014,
    63.43191,
    10.7322,
    14.6,
    25.81
  ],
  [
    1016,
    63.432,
    10.73321,
    14.3,
    25.89
  ],
  [
    1018,
    63.4321,
    10.73423,
    13.9,
    25.95
  ],
  [
    1020,
    63.43219,
    10.73525,
    13.6,
    26.02
  ],
  [
    1022,
    63.43229,
    10.73627,
    13.3,
    26.07
  ],
  [
    1024,
    63.43238,
    10.7373,
    13,
    26.12
  ],
  [
    1026,
    63.43248,
    10.73832,
    12.6,
    26.2
  ],
  [
    1028,
    63.43257,
    10.73935,
    12.3,
    26.26
  ],
  [
    1030,
    63.43267,
    10.74038,
    12,
    26.31
  ],
  [
    1032,
    63.43276,
    10.74142,
    11.8,
    26.38
  ],
  [
    1034,
    63.43283,
    10.74246,
    11.5,
    26.08
  ],
  [
    1036,
    63.43284,
    10.74349,
    11.1,
    25.73
  ],
  [
    1038,
    63.43283,
    10.74452,
    10.9,
    25.44
  ],
  [
    1040,
    63.43281,
    10.74553,
    10.5,
    25.22
  ],
  [
    1042,
    63.43279,
    10.74653,
    10.3,
    24.98
  ],
  [
    1044,
    63.43279,
    10.74753,
    10,
    24.72
  ],
  [
    1046,
    63.43282,
    10.74851,
    9.7,
    24.48
  ],
  [
    1048,
    63.43289,
    10.74947,
    9.5,
    24.41
  ],
  [
    1050,
    63.43298,
    10.75043,
    9.3,
    24.41
  ],
  [
    1052,
    63.43304,
    10.7514,
    9,
    24.46
  ],
  [
    1054,
    63.43306,
    10.75238,
    8.8,
    24.47
  ],
  [
    1056,
    63.43302,
    10.75335,
    8.7,
    24.48
  ],
  [
    1058,
    63.43294,
    10.75431,
    8.3,
    24.46
  ],
  [
    1060,
    63.43281,
    10.75525,
    8.1,
    24.4
  ],
  [
    1062,
    63.43265,
    10.75616,
    7.9,
    24.33
  ],
  [
    1064,
    63.43249,
    10.75706,
    7.7,
    24.28
  ],
  [
    1066,
    63.43232,
    10.75796,
    7.6,
    24.24
  ],
  [
    1068,
    63.43216,
    10.75887,
    7.5,
    24.21
  ],
  [
    1070,
    63.43199,
    10.75977,
    7.4,
    24.19
  ],
  [
    1072,
    63.43183,
    10.76066,
    7.3,
    24.14
  ],
  [
    1074,
    63.43167,
    10.76156,
    7.2,
    24.08
  ],
  [
    1076,
    63.43151,
    10.76245,
    7.1,
    24.03
  ],
  [
    1078,
    63.43134,
    10.76334,
    7.1,
    23.98
  ],
  [
    1080,
    63.43116,
    10.76421,
    7.1,
    23.93
  ],
  [
    1082,
    63.43096,
    10.76506,
    7,
    23.86
  ],
  [
    1084,
    63.43073,
    10.76587,
    7,
    23.79
  ],
  [
    1086,
    63.43049,
    10.76665,
    7,
    23.74
  ],
  [
    1088,
    63.43022,
    10.7674,
    6.8,
    23.62
  ],
  [
    1090,
    63.42994,
    10.7681,
    6.6,
    23.62
  ],
  [
    1092,
    63.42963,
    10.76877,
    6.2,
    23.59
  ],
  [
    1094,
    63.42932,
    10.76941,
    5.9,
    23.55
  ],
  [
    1096,
    63.42899,
    10.77002,
    5.8,
    23.5
  ],
  [
    1098,
    63.42866,
    10.77061,
    5.6,
    23.47
  ],
  [
    1100,
    63.42832,
    10.77117,
    5.6,
    23.44
  ],
  [
    1102,
    63.42797,
    10.7717,
    5.5,
    23.38
  ],
  [
    1104,
    63.42761,
    10.77217,
    5.4,
    23.32
  ],
  [
    1106,
    63.42724,
    10.77262,
    5.4,
    23.26
  ],
  [
    1108,
    63.42687,
    10.77306,
    5.2,
    23.22
  ],
  [
    1110,
    63.4265,
    10.7735,
    5.2,
    23.17
  ],
  [
    1112,
    63.42613,
    10.77394,
    5.1,
    23.12
  ],
  [
    1114,
    63.42576,
    10.77437,
    5.1,
    23.07
  ],
  [
    1116,
    63.4254,
    10.77481,
    5.1,
    23.02
  ],
  [
    1118,
    63.42503,
    10.77524,
    5.1,
    22.97
  ],
  [
    1120,
    63.42467,
    10.77567,
    5.1,
    22.92
  ],
  [
    1122,
    63.4243,
    10.77611,
    5.2,
    22.87
  ],
  [
    1124,
    63.42394,
    10.77654,
    5.2,
    22.81
  ],
  [
    1127,
    63.42341,
    10.77722,
    5.1,
    22.74
  ],
  [
    1129,
    63.42305,
    10.77767,
    5.2,
    22.68
  ],
  [
    1131,
    63.42269,
    10.77807,
    5.4,
    22.68
  ],
  [
    1133,
    63.4223,
    10.77836,
    5.7,
    22.56
  ],
  [
    1135,
    63.42191,
    10.77855,
    5.5,
    22.56
  ],
  [
    1137,
    63.4215,
    10.77863,
    5.3,
    22.56
  ],
  [
    1139,
    63.4211,
    10.77864,
    5.1,
    22.35
  ],
  [
    1141,
    63.4207,
    10.77861,
    5,
    22.35
  ],
  [
    1143,
    63.4203,
    10.77861,
    4.9,
    22.16
  ],
  [
    1145,
    63.4199,
    10.77869,
    4.9,
    22.16
  ],
  [
    1147,
    63.41952,
    10.77887,
    5.1,
    22.16
  ],
  [
    1149,
    63.41913,
    10.77908,
    5.2,
    21.92
  ],
  [
    1151,
    63.41875,
    10.77925,
    5.4,
    21.92
  ],
  [
    1153,
    63.41836,
    10.77934,
    5.7,
    21.56
  ],
  [
    1155,
    63.41798,
    10.7794,
    6,
    21.35
  ],
  [
    1157,
    63.4176,
    10.77948,
    6.2,
    21.18
  ],
  [
    1159,
    63.41723,
    10.77964,
    6.4,
    21
  ],
  [
    1161,
    63.41687,
    10.77985,
    6.7,
    20.86
  ],
  [
    1163,
    63.41651,
    10.78007,
    7,
    20.69
  ],
  [
    1165,
    63.41615,
    10.78029,
    7.2,
    20.53
  ],
  [
    1167,
    63.4158,
    10.78051,
    7.3,
    20.26
  ],
  [
    1169,
    63.41546,
    10.78072,
    7.5,
    19.37
  ],
  [
    1171,
    63.41513,
    10.78093,
    7.5,
    18.83
  ],
  [
    1173,
    63.41481,
    10.78112,
    7.6,
    18.26
  ],
  [
    1175,
    63.4145,
    10.78132,
    7.6,
    17.67
  ],
  [
    1177,
    63.4142,
    10.78154,
    7.7,
    17.37
  ],
  [
    1179,
    63.41392,
    10.78181,
    7.8,
    16.47
  ],
  [
    1181,
    63.41368,
    10.78214,
    7.9,
    15.89
  ],
  [
    1183,
    63.41346,
    10.78253,
    7.9,
    15.34
  ],
  [
    1185,
    63.41326,
    10.78295,
    8,
    14.85
  ],
  [
    1187,
    63.4131,
    10.7834,
    8,
    14.64
  ],
  [
    1189,
    63.41295,
    10.78388,
    8.1,
    14.08
  ],
  [
    1191,
    63.41284,
    10.78437,
    8.2,
    13.69
  ],
  [
    1193,
    63.41274,
    10.78486,
    8.2,
    13.29
  ],
  [
    1195,
    63.41265,
    10.78534,
    8.3,
    12.8
  ],
  [
    1197,
    63.41256,
    10.7858,
    8.4,
    12.55
  ],
  [
    1199,
    63.41247,
    10.78624,
    8.5,
    11.62
  ],
  [
    1201,
    63.4124,
    10.78665,
    8.6,
    10.91
  ],
  [
    1203,
    63.41232,
    10.78705,
    8.6,
    10.38
  ],
  [
    1205,
    63.41226,
    10.78742,
    8.8,
    10.12
  ],
  [
    1207,
    63.41219,
    10.7878,
    9,
    10.05
  ],
  [
    1209,
    63.41213,
    10.78817,
    9.1,
    9.94
  ],
  [
    1211,
    63.41206,
    10.78854,
    9.3,
    9.93
  ],
  [
    1213,
    63.412,
    10.78891,
    9.4,
    9.95
  ],
  [
    1215,
    63.41194,
    10.78928,
    9.6,
    9.92
  ],
  [
    1217,
    63.41189,
    10.78967,
    9.7,
    9.93
  ],
  [
    1219,
    63.41185,
    10.79005,
    9.7,
    9.91
  ],
  [
    1221,
    63.41181,
    10.79043,
    9.8,
    9.92
  ],
  [
    1223,
    63.41176,
    10.79082,
    10,
    9.91
  ],
  [
    1225,
    63.41172,
    10.7912,
    10.2,
    9.86
  ],
  [
    1227,
    63.41168,
    10.79158,
    10.3,
    9.82
  ],
  [
    1229,
    63.41166,
    10.79197,
    10.3,
    9.78
  ],
  [
    1231,
    63.41165,
    10.79236,
    10.5,
    9.76
  ],
  [
    1233,
    63.41165,
    10.79275,
    10.6,
    9.73
  ],
  [
    1235,
    63.41167,
    10.79314,
    10.7,
    9.68
  ],
  [
    1237,
    63.41169,
    10.79352,
    10.8,
    9.63
  ],
  [
    1239,
    63.4117,
    10.79391,
    10.9,
    9.59
  ],
  [
    1241,
    63.41172,
    10.79429,
    11,
    9.56
  ],
  [
    1243,
    63.41174,
    10.79467,
    11.1,
    9.51
  ],
  [
    1245,
    63.41176,
    10.79505,
    11.2,
    9.47
  ],
  [
    1247,
    63.41177,
    10.79542,
    11.3,
    9.41
  ],
  [
    1249,
    63.41179,
    10.7958,
    11.4,
    9.36
  ],
  [
    1251,
    63.41181,
    10.79617,
    11.4,
    9.33
  ],
  [
    1253,
    63.41183,
    10.79654,
    11.4,
    9.27
  ],
  [
    1255,
    63.41186,
    10.7969,
    11.5,
    9.22
  ],
  [
    1257,
    63.41189,
    10.79726,
    11.5,
    9.17
  ],
  [
    1259,
    63.41194,
    10.79761,
    11.5,
    9.05
  ],
  [
    1261,
    63.41199,
    10.79794,
    11.5,
    8.47
  ],
  [
    1263,
    63.41205,
    10.79825,
    11.5,
    7.98
  ],
  [
    1265,
    63.41211,
    10.79852,
    11.6,
    7.37
  ],
  [
    1267,
    63.41217,
    10.79877,
    11.6,
    6.71
  ],
  [
    1269,
    63.41223,
    10.79899,
    11.6,
    6.02
  ],
  [
    1271,
    63.41228,
    10.79919,
    11.6,
    5.4
  ],
  [
    1273,
    63.41233,
    10.79936,
    11.6,
    4.83
  ],
  [
    1275,
    63.41237,
    10.79951,
    11.6,
    4.33
  ],
  [
    1277,
    63.41241,
    10.79965,
    11.6,
    3.96
  ],
  [
    1279,
    63.41245,
    10.79978,
    11.6,
    3.67
  ],
  [
    1281,
    63.41248,
    10.7999,
    11.6,
    3.39
  ],
  [
    1283,
    63.41251,
    10.8,
    11.5,
    2.81
  ],
  [
    1285,
    63.41253,
    10.80008,
    11.5,
    1.92
  ],
  [
    1287,
    63.41255,
    10.80012,
    11.5,
    0.92
  ],
  [
    1289,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1291,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1293,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1295,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1297,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1299,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1301,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1303,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1305,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1307,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1309,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1311,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1313,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1315,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1317,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1319,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1321,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1323,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1325,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1327,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1329,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1331,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1333,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1335,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1337,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1339,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1341,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1343,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1345,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1347,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1349,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1351,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1353,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1355,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1357,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1359,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1362,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1364,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1366,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1368,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1370,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1372,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1374,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1376,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1378,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1380,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1382,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1384,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1386,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1388,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1390,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1392,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1394,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1396,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1398,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1400,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1402,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1404,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1406,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1408,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1410,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1412,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1414,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1416,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1418,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1420,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1422,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1424,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1426,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1428,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1430,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1432,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1434,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1436,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1438,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1440,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1442,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1444,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1446,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1448,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1450,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1452,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1454,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1456,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1458,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1460,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1462,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1464,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1466,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1468,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1470,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1472,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1474,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1476,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1478,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1480,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1482,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1484,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1486,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1488,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1490,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1492,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1494,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1496,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1498,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1500,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1502,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1504,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1506,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1508,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1510,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1512,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1514,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1516,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1518,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1520,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1522,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1524,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1526,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1528,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1530,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1532,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1534,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1536,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1538,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1540,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1542,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1544,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1546,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1548,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1550,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1552,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1554,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1556,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1558,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1560,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1562,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1564,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1566,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1568,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1570,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1572,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1574,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1576,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1578,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1580,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1582,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1584,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1586,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1588,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1590,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1592,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1595,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1597,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1599,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1601,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1603,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1605,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1607,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1609,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1611,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1613,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1615,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1617,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1619,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1621,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1623,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1625,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1627,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1629,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1631,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1633,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1635,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1637,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1639,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1641,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1643,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1645,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1647,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1649,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1651,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1653,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1655,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1657,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1659,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1661,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1663,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1665,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1667,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1669,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1671,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1673,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1675,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1677,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1679,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1681,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1683,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1685,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1687,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1689,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1691,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1693,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1695,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1697,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1699,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1701,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1703,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1705,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1707,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1709,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1711,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1713,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1715,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1717,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1719,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1721,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1723,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1725,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1727,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1729,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1731,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1733,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1735,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1737,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1739,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1741,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1743,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1745,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1747,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1749,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1751,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1753,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1755,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1757,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1759,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1761,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1763,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1765,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1767,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1769,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1771,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1773,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1775,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1777,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1779,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1781,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1783,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1785,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1787,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1789,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1791,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1793,
    63.41255,
    10.80014,
    11.5,
    0
  ],
  [
    1795,
    63.41259,
    10.80011,
    9.2,
    1.6
  ],
  [
    1797,
    63.41261,
    10.80019,
    9.2,
    2.64
  ],
  [
    1799,
    63.41263,
    10.80029,
    9.2,
    3.64
  ],
  [
    1801,
    63.41267,
    10.80044,
    9.1,
    4.53
  ],
  [
    1803,
    63.41272,
    10.8006,
    9.1,
    5.24
  ],
  [
    1805,
    63.41277,
    10.8008,
    9.1,
    5.87
  ],
  [
    1807,
    63.41283,
    10.80101,
    9.1,
    6.39
  ],
  [
    1809,
    63.41289,
    10.80124,
    9.1,
    6.87
  ],
  [
    1811,
    63.41294,
    10.8015,
    9.1,
    7.26
  ],
  [
    1813,
    63.41299,
    10.80177,
    9.4,
    7.6
  ],
  [
    1815,
    63.41305,
    10.80205,
    9.5,
    7.94
  ],
  [
    1817,
    63.41312,
    10.80234,
    9.5,
    8.27
  ],
  [
    1819,
    63.4132,
    10.80263,
    9.6,
    8.62
  ],
  [
    1821,
    63.41328,
    10.80293,
    9.6,
    8.97
  ],
  [
    1823,
    63.41337,
    10.80325,
    9.6,
    9.37
  ],
  [
    1825,
    63.41346,
    10.80357,
    9.6,
    9.82
  ],
  [
    1828,
    63.41361,
    10.80409,
    9.5,
    10.24
  ],
  [
    1830,
    63.41372,
    10.80444,
    9.5,
    10.67
  ],
  [
    1832,
    63.41383,
    10.80482,
    9.6,
    11
  ],
  [
    1834,
    63.41394,
    10.8052,
    9.6,
    11.14
  ],
  [
    1836,
    63.41405,
    10.80559,
    9.6,
    11.14
  ],
  [
    1838,
    63.41417,
    10.80598,
    9.6,
    11.88
  ],
  [
    1840,
    63.41429,
    10.80639,
    9.6,
    11.88
  ],
  [
    1842,
    63.41442,
    10.80681,
    9.7,
    11.88
  ],
  [
    1844,
    63.41456,
    10.80723,
    9.7,
    12.79
  ],
  [
    1846,
    63.41471,
    10.80765,
    9.8,
    13.43
  ],
  [
    1848,
    63.41487,
    10.80808,
    9.8,
    13.9
  ],
  [
    1850,
    63.41505,
    10.80851,
    9.7,
    14.45
  ],
  [
    1852,
    63.41524,
    10.80894,
    9.5,
    15.06
  ],
  [
    1854,
    63.41545,
    10.80936,
    9.2,
    16.02
  ],
  [
    1856,
    63.41567,
    10.80979,
    8.9,
    16.68
  ],
  [
    1858,
    63.41592,
    10.8102,
    8.5,
    17.35
  ],
  [
    1860,
    63.41618,
    10.8106,
    8.2,
    18.03
  ],
  [
    1862,
    63.41646,
    10.81099,
    7.8,
    18.38
  ],
  [
    1864,
    63.41677,
    10.81135,
    7.4,
    19.37
  ],
  [
    1866,
    63.41709,
    10.81169,
    7,
    20
  ],
  [
    1868,
    63.41743,
    10.81199,
    6.6,
    20.63
  ],
  [
    1870,
    63.41778,
    10.81226,
    6.3,
    21.03
  ],
  [
    1872,
    63.41814,
    10.81252,
    5.9,
    21.03
  ],
  [
    1874,
    63.4185,
    10.81278,
    5.6,
    21.03
  ],
  [
    2030,
    63.44439,
    10.88081,
    2.9,
    31.52
  ],
  [
    2032,
    63.4446,
    10.88203,
    6.5,
    31.48
  ],
  [
    2034,
    63.44479,
    10.88321,
    5.9,
    31.03
  ],
  [
    2036,
    63.44497,
    10.88437,
    5.5,
    30.44
  ],
  [
    2038,
    63.44515,
    10.88551,
    5,
    29.58
  ],
  [
    2040,
    63.44532,
    10.8866,
    4.4,
    28.56
  ],
  [
    2042,
    63.44547,
    10.88766,
    4,
    27.33
  ],
  [
    2044,
    63.44562,
    10.88867,
    3.7,
    25.98
  ],
  [
    2046,
    63.44576,
    10.88963,
    3.6,
    24.56
  ],
  [
    2048,
    63.44587,
    10.89054,
    3.5,
    23.05
  ],
  [
    2050,
    63.44596,
    10.89141,
    3.5,
    21.55
  ],
  [
    2052,
    63.44604,
    10.89222,
    3.5,
    20.35
  ],
  [
    2054,
    63.4461,
    10.893,
    3.6,
    19.27
  ],
  [
    2056,
    63.44616,
    10.89373,
    3.6,
    18.18
  ],
  [
    2058,
    63.44621,
    10.89442,
    3.7,
    17.11
  ],
  [
    2060,
    63.44623,
    10.89508,
    3.8,
    16.06
  ],
  [
    2062,
    63.44624,
    10.89569,
    3.9,
    14.99
  ],
  [
    2064,
    63.44624,
    10.89627,
    3.9,
    13.94
  ],
  [
    2066,
    63.44623,
    10.8968,
    3.9,
    12.9
  ],
  [
    2068,
    63.44623,
    10.89729,
    4.2,
    12.22
  ],
  [
    2070,
    63.44623,
    10.89777,
    4.3,
    11.85
  ],
  [
    2072,
    63.44622,
    10.89824,
    4.3,
    11.66
  ],
  [
    2074,
    63.44622,
    10.8987,
    4.4,
    11.59
  ],
  [
    2076,
    63.44622,
    10.89916,
    4.5,
    11.55
  ],
  [
    2078,
    63.44622,
    10.89961,
    4.5,
    11.52
  ],
  [
    2080,
    63.44621,
    10.90007,
    4.5,
    11.51
  ],
  [
    2083,
    63.44621,
    10.90076,
    4.5,
    11.48
  ],
  [
    2085,
    63.44621,
    10.90121,
    4.6,
    11.47
  ],
  [
    2087,
    63.44621,
    10.90167,
    4.7,
    11.47
  ],
  [
    2089,
    63.4462,
    10.90212,
    4.7,
    11.42
  ],
  [
    2091,
    63.4462,
    10.90257,
    4.8,
    11.42
  ],
  [
    2093,
    63.44622,
    10.90302,
    4.9,
    11.32
  ],
  [
    2095,
    63.44625,
    10.90347,
    5,
    11.32
  ],
  [
    2097,
    63.4463,
    10.9039,
    5.2,
    11.32
  ],
  [
    2099,
    63.44636,
    10.90432,
    5.3,
    11.24
  ],
  [
    2101,
    63.44645,
    10.90472,
    5.5,
    11.24
  ],
  [
    2103,
    63.44656,
    10.9051,
    5.6,
    11.1
  ],
  [
    2105,
    63.44668,
    10.90545,
    5.7,
    11.04
  ],
  [
    2107,
    63.4468,
    10.90578,
    5.7,
    10.99
  ],
  [
    2109,
    63.44693,
    10.90611,
    5.8,
    10.95
  ],
  [
    2111,
    63.44706,
    10.90644,
    5.8,
    10.93
  ],
  [
    2113,
    63.44719,
    10.90677,
    5.8,
    10.97
  ],
  [
    2115,
    63.44732,
    10.90711,
    5.8,
    11
  ],
  [
    2117,
    63.44745,
    10.90744,
    5.8,
    11.02
  ],
  [
    2119,
    63.44757,
    10.90777,
    5.8,
    11.02
  ],
  [
    2121,
    63.4477,
    10.90811,
    5.8,
    11
  ],
  [
    2123,
    63.44783,
    10.90844,
    5.8,
    11.01
  ],
  [
    2125,
    63.44796,
    10.90877,
    5.8,
    10.99
  ],
  [
    2127,
    63.44809,
    10.90911,
    5.8,
    11
  ],
  [
    2129,
    63.44822,
    10.90944,
    5.9,
    11.08
  ],
  [
    2131,
    63.44835,
    10.90978,
    5.9,
    11.22
  ],
  [
    2133,
    63.44848,
    10.91012,
    6,
    11.41
  ],
  [
    2135,
    63.44862,
    10.91047,
    6,
    11.62
  ],
  [
    2137,
    63.44876,
    10.91081,
    6,
    11.73
  ],
  [
    2139,
    63.44891,
    10.91115,
    6,
    12.11
  ],
  [
    2141,
    63.44907,
    10.91148,
    6,
    12.39
  ],
  [
    2143,
    63.44924,
    10.9118,
    6,
    12.67
  ],
  [
    2145,
    63.44943,
    10.91211,
    6.1,
    12.93
  ],
  [
    2147,
    63.44962,
    10.9124,
    6.1,
    13.03
  ],
  [
    2149,
    63.44982,
    10.91267,
    6.1,
    13.02
  ],
  [
    2151,
    63.45003,
    10.91291,
    6.1,
    12.97
  ],
  [
    2153,
    63.45024,
    10.91313,
    6.1,
    12.93
  ],
  [
    2155,
    63.45046,
    10.91332,
    6.1,
    12.89
  ],
  [
    2157,
    63.45067,
    10.91348,
    6.2,
    12.51
  ],
  [
    2159,
    63.45089,
    10.91362,
    6.2,
    11.98
  ],
  [
    2161,
    63.45109,
    10.91372,
    6.3,
    11.59
  ],
  [
    2163,
    63.45129,
    10.91381,
    6.4,
    11.36
  ],
  [
    2165,
    63.45149,
    10.91387,
    6.5,
    11.44
  ],
  [
    2167,
    63.4517,
    10.91391,
    6.6,
    11.18
  ],
  [
    2169,
    63.4519,
    10.91393,
    6.4,
    11.56
  ],
  [
    2171,
    63.45211,
    10.91392,
    7.4,
    11.17
  ],
  [
    2173,
    63.45231,
    10.91391,
    7.5,
    11.1
  ],
  [
    2175,
    63.45251,
    10.91391,
    7.5,
    11.06
  ],
  [
    2177,
    63.4527,
    10.9139,
    7.4,
    11.02
  ],
  [
    2179,
    63.4529,
    10.91389,
    7.4,
    10.99
  ],
  [
    2181,
    63.4531,
    10.91388,
    7.3,
    10.96
  ],
  [
    2183,
    63.45329,
    10.91387,
    7.2,
    10.44
  ],
  [
    2185,
    63.45347,
    10.91386,
    7,
    9.91
  ],
  [
    2187,
    63.45365,
    10.91386,
    6.9,
    9.33
  ],
  [
    2189,
    63.4538,
    10.91385,
    6.7,
    8.57
  ],
  [
    2191,
    63.45395,
    10.91385,
    6.5,
    7.73
  ],
  [
    2193,
    63.45408,
    10.91384,
    6.3,
    6.72
  ],
  [
    2195,
    63.45419,
    10.91384,
    6.1,
    5.66
  ],
  [
    2197,
    63.45428,
    10.91384,
    6,
    4.55
  ],
  [
    2199,
    63.45435,
    10.91383,
    5.9,
    3.5
  ],
  [
    2201,
    63.4544,
    10.91383,
    6,
    2.74
  ],
  [
    2203,
    63.45445,
    10.91382,
    6.1,
    2.13
  ],
  [
    2205,
    63.45448,
    10.91382,
    6.1,
    1.52
  ],
  [
    2207,
    63.45449,
    10.91381,
    6.2,
    0.75
  ],
  [
    2209,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2211,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2213,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2215,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2217,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2219,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2221,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2224,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2226,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2228,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2230,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2232,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2234,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2236,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2238,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2240,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2242,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2244,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2246,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2248,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2250,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2252,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2254,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2256,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2258,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2260,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2262,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2264,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2266,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2268,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2270,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2272,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2274,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2276,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2278,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2280,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2282,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2284,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2286,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2288,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2290,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2292,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2294,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2296,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2298,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2300,
    63.4545,
    10.91381,
    6.2,
    0
  ],
  [
    2302,
    63.45452,
    10.91376,
    4.5,
    1.5
  ],
  [
    2304,
    63.45455,
    10.91376,
    4.4,
    1.98
  ],
  [
    2306,
    63.45459,
    10.91376,
    4.4,
    2.47
  ],
  [
    2308,
    63.45464,
    10.91375,
    4.4,
    3
  ],
  [
    2310,
    63.4547,
    10.91375,
    4.4,
    3.67
  ],
  [
    2312,
    63.45477,
    10.91375,
    4.2,
    4.36
  ],
  [
    2314,
    63.45485,
    10.91375,
    4.2,
    4.95
  ],
  [
    2316,
    63.45494,
    10.91374,
    4.5,
    4.34
  ],
  [
    2318,
    63.45501,
    10.91371,
    5,
    3.28
  ],
  [
    2320,
    63.45508,
    10.91366,
    6.8,
    3.31
  ],
  [
    2322,
    63.45513,
    10.91358,
    8.1,
    3.01
  ],
  [
    2324,
    63.45521,
    10.91349,
    8.3,
    4.27
  ],
  [
    2326,
    63.45533,
    10.91335,
    8.7,
    6.45
  ],
  [
    2328,
    63.45543,
    10.91322,
    9.3,
    6.45
  ],
  [
    2331,
    63.45569,
    10.91309,
    9.9,
    9.17
  ],
  [
    2333,
    63.4564,
    10.91321,
    9.8,
    14.57
  ],
  [
    2335,
    63.4565,
    10.91366,
    7.8,
    11.06
  ],
  [
    2337,
    63.45667,
    10.91369,
    6.6,
    11.36
  ],
  [
    2339,
    63.45686,
    10.91369,
    5.7,
    11.63
  ],
  [
    2341,
    63.45707,
    10.91368,
    4.9,
    11.86
  ],
  [
    2343,
    63.45728,
    10.91364,
    4.3,
    12.16
  ],
  [
    2345,
    63.4575,
    10.91359,
    4.1,
    12.16
  ],
  [
    2347,
    63.45771,
    10.91354,
    4,
    12.16
  ],
  [
    2354,
    63.45865,
    10.91404,
    2.3,
    13.19
  ],
  [
    2356,
    63.45873,
    10.9139,
    2.1,
    12.09
  ],
  [
    2358,
    63.4589,
    10.91375,
    1.5,
    12.11
  ],
  [
    2360,
    63.45911,
    10.91371,
    1.6,
    12.09
  ],
  [
    2362,
    63.45932,
    10.91368,
    1.6,
    12.08
  ],
  [
    2364,
    63.45954,
    10.91367,
    1.9,
    12.07
  ],
  [
    2366,
    63.45975,
    10.91366,
    2.2,
    12.08
  ],
  [
    2368,
    63.45997,
    10.91365,
    2.6,
    12.1
  ],
  [
    2370,
    63.46019,
    10.91365,
    2.9,
    12.13
  ],
  [
    2372,
    63.4604,
    10.91364,
    3.2,
    12.18
  ],
  [
    2374,
    63.46062,
    10.91363,
    3.7,
    12.21
  ],
  [
    2376,
    63.46084,
    10.91363,
    4,
    12.27
  ],
  [
    2378,
    63.46106,
    10.91362,
    4.4,
    12.33
  ],
  [
    2380,
    63.46128,
    10.91361,
    4.7,
    12.35
  ],
  [
    2382,
    63.46151,
    10.91361,
    5.1,
    12.4
  ],
  [
    2384,
    63.46173,
    10.9136,
    5.4,
    12.45
  ],
  [
    2386,
    63.46195,
    10.9136,
    5.7,
    12.48
  ],
  [
    2388,
    63.46218,
    10.91359,
    6,
    12.53
  ],
  [
    2390,
    63.4624,
    10.91359,
    6.2,
    12.61
  ],
  [
    2392,
    63.46263,
    10.91358,
    6.4,
    12.55
  ],
  [
    2394,
    63.46285,
    10.91358,
    6.5,
    12.42
  ],
  [
    2396,
    63.46307,
    10.91357,
    6.5,
    12.27
  ],
  [
    2398,
    63.46329,
    10.91357,
    6.5,
    12.18
  ],
  [
    2400,
    63.46351,
    10.91357,
    6.5,
    12.08
  ],
  [
    2402,
    63.46373,
    10.91356,
    6.5,
    12.01
  ],
  [
    2404,
    63.46394,
    10.91356,
    6.5,
    11.93
  ],
  [
    2406,
    63.46415,
    10.91355,
    6.5,
    11.91
  ],
  [
    2408,
    63.46437,
    10.91355,
    6.5,
    11.77
  ],
  [
    2411,
    63.46467,
    10.91354,
    6.5,
    11.17
  ],
  [
    2413,
    63.46486,
    10.91354,
    6.6,
    10.67
  ],
  [
    2415,
    63.46504,
    10.91354,
    6.7,
    10.67
  ],
  [
    2417,
    63.46521,
    10.91353,
    6.8,
    10.67
  ],
  [
    2419,
    63.46537,
    10.91352,
    6.9,
    9.13
  ],
  [
    2421,
    63.46552,
    10.91347,
    6.9,
    9.13
  ],
  [
    2423,
    63.46567,
    10.9134,
    7,
    8.41
  ],
  [
    2425,
    63.46581,
    10.9133,
    7.2,
    8.41
  ],
  [
    2427,
    63.46595,
    10.9132,
    7.3,
    8.41
  ],
  [
    2429,
    63.46609,
    10.9131,
    7.4,
    8.18
  ],
  [
    2431,
    63.46623,
    10.91304,
    7.6,
    8.29
  ],
  [
    2433,
    63.46638,
    10.913,
    7.7,
    8.44
  ],
  [
    2435,
    63.46654,
    10.91299,
    7.9,
    8.59
  ],
  [
    2437,
    63.46669,
    10.91302,
    8.2,
    8.65
  ],
  [
    2439,
    63.46684,
    10.91306,
    8.3,
    8.59
  ],
  [
    2441,
    63.467,
    10.9131,
    8.4,
    8.58
  ],
  [
    2443,
    63.46715,
    10.91315,
    8.4,
    8.57
  ],
  [
    2445,
    63.4673,
    10.91319,
    8.4,
    8.62
  ],
  [
    2447,
    63.46745,
    10.91323,
    8.3,
    8.65
  ],
  [
    2449,
    63.46761,
    10.91328,
    8.2,
    8.73
  ],
  [
    2451,
    63.46776,
    10.91332,
    8.1,
    8.76
  ],
  [
    2453,
    63.46792,
    10.91337,
    8.1,
    8.77
  ],
  [
    2455,
    63.46807,
    10.91341,
    8.1,
    8.78
  ],
  [
    2457,
    63.46823,
    10.91345,
    8.1,
    8.78
  ],
  [
    2459,
    63.46839,
    10.91347,
    8.1,
    8.77
  ],
  [
    2461,
    63.46854,
    10.91347,
    8.1,
    8.79
  ],
  [
    2463,
    63.4687,
    10.91347,
    8.1,
    8.78
  ],
  [
    2465,
    63.46886,
    10.91347,
    8.1,
    8.8
  ],
  [
    2467,
    63.46902,
    10.91346,
    8.1,
    8.8
  ],
  [
    2469,
    63.46917,
    10.91346,
    8.1,
    8.79
  ],
  [
    2471,
    63.46933,
    10.91346,
    8.1,
    8.79
  ],
  [
    2473,
    63.46949,
    10.91346,
    8.1,
    8.79
  ],
  [
    2475,
    63.46965,
    10.91345,
    8.1,
    8.75
  ],
  [
    2477,
    63.4698,
    10.91345,
    8.1,
    8.71
  ],
  [
    2479,
    63.46996,
    10.91345,
    8.1,
    8.69
  ],
  [
    2481,
    63.47011,
    10.91344,
    8.1,
    8.66
  ],
  [
    2483,
    63.47027,
    10.91344,
    8.1,
    8.62
  ],
  [
    2485,
    63.47042,
    10.91344,
    8.1,
    8.5
  ],
  [
    2487,
    63.47057,
    10.91344,
    8.1,
    7.86
  ],
  [
    2489,
    63.4707,
    10.91343,
    8.1,
    7.17
  ],
  [
    2491,
    63.47082,
    10.91343,
    8.1,
    6.25
  ],
  [
    2493,
    63.47092,
    10.91343,
    8.1,
    5.06
  ],
  [
    2495,
    63.471,
    10.91343,
    8.1,
    3.71
  ],
  [
    2497,
    63.47105,
    10.91343,
    8.1,
    2.31
  ],
  [
    2499,
    63.47108,
    10.91343,
    8.1,
    1.1
  ],
  [
    2501,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2503,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2505,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2507,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2509,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2511,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2513,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2515,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2517,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2519,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2521,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2523,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2525,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2527,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2529,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2531,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2533,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2535,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2537,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2539,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2541,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2543,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2545,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2547,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2549,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2551,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2554,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2556,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2558,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2560,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2562,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2564,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2566,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2568,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2570,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2572,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2574,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2576,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2578,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2580,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2582,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2584,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2586,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2588,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2590,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2592,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2594,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2596,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2598,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2600,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2602,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2604,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2606,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2608,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2610,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2612,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2614,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2616,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2618,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2620,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2622,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2624,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2626,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2628,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2630,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2632,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2634,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2636,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2638,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2640,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2642,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2644,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2646,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2648,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2650,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2652,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2654,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2656,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2658,
    63.47109,
    10.91343,
    8,
    0
  ],
  [
    2660,
    63.47112,
    10.91343,
    7.4,
    2.12
  ],
  [
    2662,
    63.47116,
    10.91343,
    7.4,
    2.87
  ],
  [
    2664,
    63.47122,
    10.91343,
    7.4,
    3.72
  ],
  [
    2666,
    63.4713,
    10.91343,
    7.4,
    4.43
  ],
  [
    2668,
    63.47138,
    10.91342,
    7.4,
    5.04
  ],
  [
    2670,
    63.47148,
    10.91342,
    7.4,
    5.58
  ],
  [
    2672,
    63.47158,
    10.91342,
    7.4,
    6.09
  ],
  [
    2674,
    63.4717,
    10.91342,
    7.4,
    6.62
  ],
  [
    2676,
    63.47182,
    10.91341,
    7.4,
    7.15
  ],
  [
    2678,
    63.47196,
    10.91341,
    7.5,
    7.67
  ],
  [
    2680,
    63.4721,
    10.91341,
    7.5,
    8.16
  ],
  [
    2682,
    63.47225,
    10.9134,
    7.5,
    8.63
  ],
  [
    2684,
    63.47241,
    10.9134,
    7.5,
    9.07
  ],
  [
    2686,
    63.47258,
    10.9134,
    7.7,
    9.51
  ],
  [
    2688,
    63.47275,
    10.91339,
    7.7,
    9.93
  ],
  [
    2691,
    63.47303,
    10.91339,
    7.8,
    10.32
  ],
  [
    2693,
    63.47322,
    10.91338,
    7.9,
    10.72
  ],
  [
    2695,
    63.47342,
    10.91338,
    8,
    10.94
  ],
  [
    2697,
    63.47362,
    10.91338,
    8,
    11.06
  ],
  [
    2699,
    63.47382,
    10.91337,
    8.1,
    11.1
  ],
  [
    2701,
    63.47402,
    10.91337,
    8.1,
    11.1
  ],
  [
    2703,
    63.47423,
    10.91336,
    8.2,
    11.3
  ],
  [
    2705,
    63.47443,
    10.91336,
    8.2,
    11.3
  ],
  [
    2707,
    63.47464,
    10.91335,
    8.2,
    11.3
  ],
  [
    2709,
    63.47486,
    10.91335,
    8.1,
    11.77
  ],
  [
    2711,
    63.47508,
    10.91334,
    8.1,
    11.77
  ],
  [
    2713,
    63.4753,
    10.91334,
    8.1,
    12.48
  ],
  [
    2715,
    63.47553,
    10.91333,
    8.1,
    12.89
  ],
  [
    2717,
    63.47577,
    10.91333,
    8.1,
    13.26
  ],
  [
    2719,
    63.47602,
    10.91333,
    8.1,
    13.8
  ],
  [
    2721,
    63.47628,
    10.91332,
    8.1,
    14.34
  ],
  [
    2723,
    63.47655,
    10.9133,
    8.1,
    14.87
  ],
  [
    2725,
    63.47682,
    10.91327,
    8.1,
    15.72
  ],
  [
    2727,
    63.47711,
    10.91321,
    8.1,
    16
  ],
  [
    2729,
    63.47741,
    10.91313,
    8,
    16.84
  ],
  [
    2731,
    63.47771,
    10.91303,
    8,
    17.37
  ],
  [
    2733,
    63.47803,
    10.9129,
    8,
    17.9
  ],
  [
    2735,
    63.47834,
    10.91274,
    8,
    18.41
  ],
  [
    2737,
    63.47867,
    10.91254,
    8,
    18.65
  ],
  [
    2739,
    63.479,
    10.91232,
    8.1,
    19.36
  ],
  [
    2741,
    63.47933,
    10.91206,
    8,
    19.81
  ],
  [
    2743,
    63.47967,
    10.91176,
    8,
    20.27
  ],
  [
    2745,
    63.48001,
    10.91143,
    8,
    20.69
  ],
  [
    2747,
    63.48035,
    10.91106,
    8,
    20.9
  ],
  [
    2749,
    63.48068,
    10.91064,
    8,
    21.53
  ],
  [
    2751,
    63.48102,
    10.91019,
    8,
    21.93
  ],
  [
    2753,
    63.48135,
    10.9097,
    8,
    22.32
  ],
  [
    2755,
    63.48168,
    10.90916,
    8.1,
    22.7
  ],
  [
    2757,
    63.482,
    10.90859,
    8.1,
    23.07
  ],
  [
    2759,
    63.48232,
    10.90797,
    8.1,
    23.43
  ],
  [
    2761,
    63.48262,
    10.9073,
    8.1,
    23.77
  ],
  [
    2763,
    63.48292,
    10.90659,
    8,
    24.13
  ],
  [
    2765,
    63.4832,
    10.90586,
    7.9,
    24.49
  ],
  [
    2767,
    63.48349,
    10.9051,
    7.8,
    24.81
  ],
  [
    2769,
    63.48379,
    10.90435,
    8,
    25.11
  ],
  [
    2771,
    63.48411,
    10.90362,
    8.4,
    25.31
  ],
  [
    2773,
    63.48444,
    10.9029,
    9.2,
    25.44
  ],
  [
    2775,
    63.48478,
    10.90221,
    10,
    25.48
  ],
  [
    2777,
    63.48513,
    10.90155,
    10.9,
    25.5
  ],
  [
    2779,
    63.48549,
    10.90091,
    11.9,
    25.51
  ],
  [
    2781,
    63.48586,
    10.9003,
    12.9,
    25.49
  ],
  [
    2783,
    63.48624,
    10.89973,
    13.8,
    25.5
  ],
  [
    2785,
    63.48662,
    10.89917,
    14.7,
    25.49
  ],
  [
    2787,
    63.48701,
    10.89863,
    15.3,
    25.57
  ],
  [
    2789,
    63.48741,
    10.8981,
    15.8,
    25.7
  ],
  [
    2791,
    63.48781,
    10.89758,
    16.3,
    25.78
  ],
  [
    2793,
    63.48821,
    10.89707,
    17,
    25.9
  ],
  [
    2795,
    63.48863,
    10.89657,
    17.8,
    26.03
  ],
  [
    2797,
    63.48904,
    10.89609,
    18.7,
    26.07
  ],
  [
    2799,
    63.48948,
    10.89571,
    19.6,
    26.1
  ],
  [
    2801,
    63.48994,
    10.89548,
    20.3,
    26.09
  ],
  [
    2803,
    63.49041,
    10.8954,
    21,
    26.1
  ],
  [
    2805,
    63.49088,
    10.89545,
    21.6,
    26.13
  ],
  [
    2807,
    63.49135,
    10.89552,
    22,
    26.18
  ],
  [
    2809,
    63.49182,
    10.89554,
    22.6,
    26.26
  ],
  [
    2811,
    63.49229,
    10.89548,
    23.3,
    26.36
  ],
  [
    2813,
    63.49276,
    10.89539,
    24.1,
    26.49
  ],
  [
    2815,
    63.49324,
    10.89528,
    24.9,
    26.5
  ],
  [
    2817,
    63.49371,
    10.89518,
    25.5,
    26.54
  ],
  [
    2819,
    63.49419,
    10.89507,
    26.5,
    26.54
  ],
  [
    2821,
    63.49466,
    10.89497,
    27.3,
    26.57
  ],
  [
    2823,
    63.49514,
    10.89487,
    27.8,
    26.58
  ],
  [
    2825,
    63.49561,
    10.89476,
    27.8,
    26.58
  ],
  [
    2827,
    63.49609,
    10.89466,
    29.7,
    26.6
  ],
  [
    2829,
    63.49657,
    10.89452,
    30.4,
    26.57
  ],
  [
    2831,
    63.49703,
    10.89428,
    31.3,
    26.57
  ],
  [
    2833,
    63.49748,
    10.89391,
    32.1,
    26.59
  ],
  [
    2836,
    63.4981,
    10.89312,
    33.1,
    26.56
  ],
  [
    2838,
    63.49848,
    10.89246,
    33.9,
    26.6
  ],
  [
    2840,
    63.49884,
    10.89177,
    34.6,
    26.67
  ],
  [
    2842,
    63.4992,
    10.89105,
    35.3,
    26.67
  ],
  [
    2844,
    63.49956,
    10.89032,
    36,
    26.75
  ],
  [
    2846,
    63.4999,
    10.88957,
    36.6,
    26.75
  ],
  [
    2848,
    63.50025,
    10.8888,
    37.2,
    26.93
  ],
  [
    2850,
    63.50058,
    10.88801,
    37.8,
    26.93
  ],
  [
    2852,
    63.50091,
    10.8872,
    38.3,
    26.93
  ],
  [
    2854,
    63.50123,
    10.88637,
    38.9,
    27.15
  ],
  [
    2856,
    63.50152,
    10.8855,
    39.4,
    27.21
  ],
  [
    2858,
    63.50179,
    10.88459,
    40,
    27.3
  ],
  [
    2860,
    63.50203,
    10.88363,
    40.6,
    27.36
  ],
  [
    2862,
    63.50225,
    10.88263,
    41.2,
    27.37
  ],
  [
    2864,
    63.50243,
    10.8816,
    41.9,
    27.44
  ],
  [
    2866,
    63.50258,
    10.88055,
    42.5,
    27.46
  ],
  [
    2868,
    63.50269,
    10.87947,
    43.3,
    27.47
  ],
  [
    2870,
    63.50278,
    10.87838,
    44.2,
    27.48
  ],
  [
    2872,
    63.50282,
    10.87728,
    45.1,
    27.45
  ],
  [
    2874,
    63.50284,
    10.87617,
    46.1,
    27.39
  ],
  [
    2876,
    63.50282,
    10.87507,
    47.1,
    27.36
  ],
  [
    2878,
    63.50278,
    10.87397,
    48,
    27.3
  ],
  [
    2880,
    63.50272,
    10.87287,
    49,
    27.26
  ],
  [
    2882,
    63.50266,
    10.87178,
    50,
    27.23
  ],
  [
    2884,
    63.50261,
    10.87069,
    51,
    27.18
  ],
  [
    2886,
    63.50255,
    10.8696,
    52,
    26.95
  ],
  [
    2888,
    63.5025,
    10.86853,
    52.8,
    26.65
  ],
  [
    2890,
    63.50244,
    10.86747,
    53.3,
    26.33
  ],
  [
    2892,
    63.50239,
    10.86642,
    53.7,
    26.09
  ],
  [
    2894,
    63.50236,
    10.86537,
    54,
    25.95
  ],
  [
    2896,
    63.50239,
    10.86433,
    54.3,
    25.79
  ],
  [
    2898,
    63.50249,
    10.86333,
    54.6,
    25.63
  ],
  [
    2900,
    63.50266,
    10.86237,
    54.9,
    25.48
  ],
  [
    2902,
    63.50288,
    10.86148,
    55.2,
    25.38
  ],
  [
    2904,
    63.50316,
    10.86067,
    55.3,
    25.34
  ],
  [
    2906,
    63.50348,
    10.85995,
    55.3,
    25.32
  ],
  [
    2908,
    63.50381,
    10.85925,
    55.3,
    25.33
  ],
  [
    2910,
    63.50414,
    10.85855,
    55.3,
    25.38
  ],
  [
    2912,
    63.50447,
    10.85784,
    55.7,
    25.22
  ],
  [
    2914,
    63.50479,
    10.85711,
    55.7,
    25.59
  ],
  [
    2916,
    63.50509,
    10.85632,
    55.8,
    25.72
  ],
  [
    2918,
    63.50535,
    10.85546,
    55.8,
    25.87
  ],
  [
    2920,
    63.50557,
    10.85454,
    55.9,
    26.06
  ],
  [
    2922,
    63.50575,
    10.85357,
    56,
    26.3
  ],
  [
    2924,
    63.50588,
    10.85255,
    56.2,
    26.56
  ],
  [
    2926,
    63.50597,
    10.85149,
    56.7,
    26.78
  ],
  [
    2928,
    63.50604,
    10.85042,
    57.3,
    26.95
  ],
  [
    2930,
    63.5061,
    10.84934,
    57.9,
    27.06
  ],
  [
    2932,
    63.50618,
    10.84826,
    58.5,
    27.14
  ],
  [
    2934,
    63.50626,
    10.84718,
    59.2,
    27.21
  ],
  [
    2936,
    63.50637,
    10.84611,
    59.8,
    27.27
  ],
  [
    2938,
    63.50648,
    10.84504,
    60.4,
    27.34
  ],
  [
    2940,
    63.50661,
    10.84398,
    61,
    27.41
  ],
  [
    2942,
    63.50676,
    10.84292,
    61.7,
    27.51
  ],
  [
    2944,
    63.50691,
    10.84187,
    62.3,
    27.58
  ],
  [
    2946,
    63.50706,
    10.84081,
    62.9,
    27.66
  ],
  [
    2948,
    63.50722,
    10.83975,
    63.6,
    27.74
  ],
  [
    2950,
    63.50738,
    10.83869,
    64.2,
    27.81
  ],
  [
    2952,
    63.50753,
    10.83763,
    64.7,
    27.89
  ],
  [
    2954,
    63.50769,
    10.83656,
    65.3,
    27.98
  ],
  [
    2956,
    63.50785,
    10.83549,
    65.3,
    28.08
  ],
  [
    2958,
    63.508,
    10.83441,
    66.4,
    28.19
  ],
  [
    2960,
    63.50816,
    10.83333,
    66.8,
    28.27
  ],
  [
    2962,
    63.50831,
    10.83225,
    67.3,
    28.19
  ],
  [
    2964,
    63.50844,
    10.83115,
    67.8,
    27.98
  ],
  [
    2966,
    63.50853,
    10.83005,
    68.4,
    27.72
  ],
  [
    2969,
    63.50863,
    10.8284,
    69,
    27.53
  ],
  [
    2971,
    63.50866,
    10.82731,
    69.5,
    27.29
  ],
  [
    2973,
    63.50867,
    10.82622,
    69.6,
    27.14
  ],
  [
    2975,
    63.50867,
    10.82513,
    69.8,
    27.14
  ],
  [
    2977,
    63.50867,
    10.82405,
    69.8,
    27.14
  ],
  [
    2979,
    63.50867,
    10.82297,
    69.8,
    26.84
  ],
  [
    2981,
    63.50868,
    10.8219,
    69.8,
    26.84
  ],
  [
    2983,
    63.50868,
    10.82082,
    69.8,
    26.69
  ],
  [
    2985,
    63.50868,
    10.81975,
    69.9,
    26.69
  ],
  [
    2987,
    63.50868,
    10.81868,
    69.9,
    26.69
  ],
  [
    2989,
    63.50869,
    10.81762,
    70.2,
    26.43
  ],
  [
    2991,
    63.50869,
    10.81656,
    70.7,
    26.3
  ],
  [
    2993,
    63.50869,
    10.81551,
    71.5,
    26.09
  ],
  [
    2995,
    63.50869,
    10.81447,
    72.3,
    25.74
  ],
  [
    2997,
    63.5087,
    10.81344,
    73.1,
    25.6
  ],
  [
    2999,
    63.5087,
    10.81243,
    73.9,
    25.18
  ],
  [
    3001,
    63.5087,
    10.81142,
    74.7,
    24.91
  ],
  [
    3003,
    63.50873,
    10.81043,
    75.4,
    24.69
  ],
  [
    3005,
    63.50881,
    10.80945,
    76.2,
    24.47
  ],
  [
    3007,
    63.50893,
    10.80851,
    76.9,
    24.38
  ],
  [
    3009,
    63.5091,
    10.80761,
    77.7,
    24.25
  ],
  [
    3011,
    63.5093,
    10.80676,
    78.4,
    24.04
  ],
  [
    3013,
    63.50955,
    10.80597,
    79,
    23.94
  ],
  [
    3015,
    63.50983,
    10.80524,
    79.4,
    23.99
  ],
  [
    3017,
    63.51016,
    10.80459,
    80.8,
    24.02
  ],
  [
    3019,
    63.51051,
    10.80403,
    80.2,
    24.29
  ],
  [
    3021,
    63.51089,
    10.80353,
    79.7,
    24.56
  ],
  [
    3023,
    63.5113,
    10.80313,
    79.2,
    24.86
  ],
  [
    3025,
    63.51173,
    10.80282,
    78.6,
    25.17
  ],
  [
    3027,
    63.51218,
    10.80262,
    78.3,
    25.44
  ],
  [
    3029,
    63.51264,
    10.80248,
    78.2,
    25.59
  ],
  [
    3031,
    63.5131,
    10.80237,
    78.1,
    25.65
  ],
  [
    3033,
    63.51356,
    10.80225,
    78.1,
    25.6
  ],
  [
    3035,
    63.51402,
    10.80213,
    78,
    25.54
  ],
  [
    3037,
    63.51448,
    10.80201,
    78,
    25.47
  ],
  [
    3039,
    63.51493,
    10.80189,
    77.9,
    25.39
  ],
  [
    3041,
    63.51539,
    10.80178,
    77.7,
    25.35
  ],
  [
    3043,
    63.51584,
    10.80166,
    77.6,
    25.3
  ],
  [
    3045,
    63.51629,
    10.80154,
    77.5,
    25.27
  ],
  [
    3047,
    63.51674,
    10.80142,
    77.3,
    25.17
  ],
  [
    3049,
    63.51719,
    10.80129,
    77.3,
    25.07
  ],
  [
    3051,
    63.51764,
    10.80117,
    77.3,
    25.06
  ],
  [
    3053,
    63.51809,
    10.80105,
    77.6,
    25
  ],
  [
    3055,
    63.51853,
    10.80093,
    78.3,
    24.91
  ],
  [
    3057,
    63.51898,
    10.80081,
    79.1,
    24.77
  ],
  [
    3059,
    63.51942,
    10.8007,
    79.9,
    24.59
  ],
  [
    3061,
    63.51985,
    10.80058,
    80.8,
    24.36
  ],
  [
    3063,
    63.52029,
    10.80046,
    81.7,
    24.14
  ],
  [
    3065,
    63.52072,
    10.80034,
    82.6,
    23.95
  ],
  [
    3067,
    63.52114,
    10.80023,
    83.5,
    23.75
  ],
  [
    3069,
    63.52156,
    10.80011,
    84.3,
    23.55
  ],
  [
    3071,
    63.52198,
    10.8,
    85.1,
    23.38
  ],
  [
    3073,
    63.5224,
    10.79988,
    85.9,
    23.2
  ],
  [
    3075,
    63.52281,
    10.79977,
    86.7,
    23.04
  ],
  [
    3077,
    63.52322,
    10.79966,
    87.5,
    22.9
  ],
  [
    3079,
    63.52363,
    10.79959,
    88.2,
    22.75
  ],
  [
    3081,
    63.52403,
    10.7996,
    88.8,
    22.66
  ],
  [
    3083,
    63.52443,
    10.79971,
    89.4,
    22.51
  ],
  [
    3085,
    63.52483,
    10.79993,
    89.9,
    22.4
  ],
  [
    3087,
    63.5252,
    10.80024,
    90.4,
    22.31
  ],
  [
    3089,
    63.52556,
    10.80064,
    91,
    22.26
  ],
  [
    3091,
    63.5259,
    10.80112,
    91.5,
    22.17
  ],
  [
    3093,
    63.52622,
    10.80165,
    91.7,
    22.13
  ],
  [
    3095,
    63.52654,
    10.80217,
    91.9,
    22.2
  ],
  [
    3097,
    63.52687,
    10.8027,
    92,
    22.33
  ],
  [
    3099,
    63.52719,
    10.80323,
    92,
    22.43
  ],
  [
    3101,
    63.52752,
    10.80377,
    92,
    22.42
  ],
  [
    3103,
    63.52784,
    10.8043,
    92,
    22.38
  ],
  [
    3105,
    63.52817,
    10.80483,
    92,
    22.33
  ],
  [
    3107,
    63.52849,
    10.80536,
    91.9,
    22.3
  ],
  [
    3109,
    63.52881,
    10.80589,
    91.9,
    22.26
  ],
  [
    3111,
    63.52914,
    10.80642,
    91.8,
    22.22
  ],
  [
    3113,
    63.52946,
    10.80695,
    91.4,
    22.23
  ],
  [
    3115,
    63.52978,
    10.80748,
    90.7,
    22.25
  ],
  [
    3117,
    63.5301,
    10.80803,
    89.9,
    22.37
  ],
  [
    3119,
    63.53042,
    10.80859,
    89.4,
    22.47
  ],
  [
    3121,
    63.53073,
    10.80917,
    89.4,
    22.18
  ],
  [
    3123,
    63.53102,
    10.80977,
    89.4,
    21.86
  ],
  [
    3125,
    63.5313,
    10.81038,
    86.4,
    21.46
  ],
  [
    3127,
    63.53156,
    10.81099,
    85.7,
    20.91
  ],
  [
    3129,
    63.53181,
    10.81161,
    84.9,
    20.2
  ],
  [
    3131,
    63.53203,
    10.81224,
    84.3,
    19.61
  ],
  [
    3133,
    63.53222,
    10.8129,
    83.6,
    19.43
  ],
  [
    3135,
    63.53237,
    10.81361,
    82.9,
    19.35
  ],
  [
    3138,
    63.53251,
    10.81473,
    81.9,
    19.28
  ],
  [
    3140,
    63.53256,
    10.8155,
    81.4,
    19.17
  ],
  [
    3142,
    63.53258,
    10.81627,
    81,
    19.08
  ],
  [
    3144,
    63.53261,
    10.81704,
    80.4,
    18.97
  ],
  [
    3146,
    63.53267,
    10.81779,
    79.8,
    18.88
  ],
  [
    3148,
    63.53277,
    10.81851,
    79.1,
    18.79
  ],
  [
    3150,
    63.53292,
    10.81919,
    78.6,
    18.79
  ],
  [
    3152,
    63.5331,
    10.81982,
    78.1,
    18.79
  ],
  [
    3154,
    63.53331,
    10.8204,
    77.5,
    18.72
  ],
  [
    3156,
    63.53354,
    10.82096,
    77.1,
    18.72
  ],
  [
    3158,
    63.53377,
    10.82152,
    76.7,
    18.93
  ],
  [
    3160,
    63.53398,
    10.82214,
    76.2,
    18.93
  ],
  [
    3162,
    63.53414,
    10.82281,
    75.7,
    18.93
  ],
  [
    3164,
    63.53426,
    10.82353,
    75.2,
    19.09
  ],
  [
    3166,
    63.53432,
    10.82428,
    74.6,
    19.11
  ],
  [
    3168,
    63.53433,
    10.82505,
    73.9,
    19.14
  ],
  [
    3170,
    63.5343,
    10.82582,
    73.3,
    19.16
  ],
  [
    3172,
    63.53423,
    10.82658,
    72.6,
    19.18
  ],
  [
    3174,
    63.53416,
    10.82735,
    71.8,
    19.28
  ],
  [
    3176,
    63.53413,
    10.82813,
    71,
    19.62
  ],
  [
    3178,
    63.53414,
    10.82893,
    70.4,
    19.86
  ],
  [
    3180,
    63.5342,
    10.82972,
    70.1,
    20.22
  ],
  [
    3182,
    63.5343,
    10.8305,
    69.5,
    20.23
  ],
  [
    3184,
    63.53442,
    10.83128,
    68.8,
    20.62
  ],
  [
    3186,
    63.53455,
    10.83207,
    68.1,
    20.89
  ],
  [
    3188,
    63.53468,
    10.83286,
    67.4,
    21.19
  ],
  [
    3190,
    63.53481,
    10.83367,
    66.7,
    21.46
  ],
  [
    3192,
    63.53494,
    10.83449,
    65.9,
    21.61
  ],
  [
    3194,
    63.53507,
    10.83532,
    65.1,
    22.06
  ],
  [
    3196,
    63.53521,
    10.83616,
    64.2,
    22.36
  ],
  [
    3198,
    63.53535,
    10.83701,
    63.4,
    22.66
  ],
  [
    3200,
    63.53549,
    10.83787,
    62.7,
    22.97
  ],
  [
    3202,
    63.53563,
    10.83875,
    62,
    23.11
  ],
  [
    3204,
    63.53577,
    10.83963,
    61.2,
    23.46
  ],
  [
    3206,
    63.53592,
    10.84051,
    60.4,
    23.21
  ],
  [
    3208,
    63.53606,
    10.84139,
    59.5,
    23.02
  ],
  [
    3210,
    63.53622,
    10.84224,
    58.9,
    22.76
  ],
  [
    3212,
    63.53642,
    10.84303,
    58.2,
    22.57
  ],
  [
    3214,
    63.53667,
    10.84373,
    57.5,
    22.2
  ],
  [
    3216,
    63.53696,
    10.84433,
    56.7,
    22.24
  ],
  [
    3218,
    63.53728,
    10.84488,
    56,
    22.39
  ],
  [
    3220,
    63.5376,
    10.84543,
    55.1,
    22.64
  ],
  [
    3222,
    63.53792,
    10.84601,
    54.3,
    22.92
  ],
  [
    3224,
    63.53823,
    10.84662,
    53.5,
    23.2
  ],
  [
    3226,
    63.53854,
    10.84726,
    52.7,
    23.47
  ],
  [
    3228,
    63.53883,
    10.84793,
    51.8,
    23.79
  ],
  [
    3230,
    63.53913,
    10.84864,
    50.9,
    24.06
  ],
  [
    3232,
    63.53941,
    10.84938,
    50,
    24.33
  ],
  [
    3234,
    63.53968,
    10.85015,
    49.1,
    24.62
  ],
  [
    3236,
    63.53994,
    10.85096,
    48.2,
    24.9
  ],
  [
    3238,
    63.54019,
    10.85179,
    47.3,
    25.18
  ],
  [
    3240,
    63.54043,
    10.85266,
    46.4,
    25.18
  ],
  [
    3242,
    63.54065,
    10.85353,
    45.5,
    24.92
  ],
  [
    3244,
    63.54088,
    10.85439,
    44.6,
    24.64
  ],
  [
    3246,
    63.54113,
    10.8552,
    43.8,
    24.29
  ],
  [
    3248,
    63.54141,
    10.85592,
    42.9,
    23.81
  ],
  [
    3250,
    63.54172,
    10.85658,
    42.3,
    23.31
  ],
  [
    3252,
    63.54202,
    10.85722,
    41.7,
    22.98
  ],
  [
    3254,
    63.54229,
    10.85792,
    41,
    22.72
  ],
  [
    3256,
    63.54251,
    10.85868,
    40.3,
    22.69
  ],
  [
    3258,
    63.54268,
    10.85951,
    39.6,
    22.78
  ],
  [
    3260,
    63.54281,
    10.86038,
    38.7,
    22.97
  ],
  [
    3262,
    63.54291,
    10.86128,
    37.8,
    23.18
  ],
  [
    3264,
    63.54301,
    10.86219,
    37,
    23.44
  ],
  [
    3266,
    63.54312,
    10.86311,
    36.1,
    23.74
  ],
  [
    3268,
    63.54323,
    10.86404,
    35.3,
    24
  ],
  [
    3270,
    63.54334,
    10.86498,
    34.4,
    24.28
  ],
  [
    3272,
    63.54346,
    10.86592,
    33.6,
    24.57
  ],
  [
    3274,
    63.54359,
    10.86687,
    32.8,
    24.85
  ],
  [
    3276,
    63.54373,
    10.86782,
    32,
    25.11
  ],
  [
    3278,
    63.54389,
    10.86878,
    31.2,
    25.39
  ],
  [
    3280,
    63.54406,
    10.86973,
    30.4,
    25.66
  ],
  [
    3282,
    63.54425,
    10.87068,
    29.6,
    25.9
  ],
  [
    3284,
    63.54442,
    10.87164,
    28.8,
    25.66
  ],
  [
    3286,
    63.54458,
    10.87261,
    28,
    25.45
  ],
  [
    3288,
    63.5447,
    10.87359,
    27.3,
    25.15
  ],
  [
    3290,
    63.54479,
    10.87457,
    26.5,
    24.83
  ],
  [
    3292,
    63.54486,
    10.87555,
    25.8,
    24.54
  ],
  [
    3294,
    63.54491,
    10.87652,
    24.8,
    24.17
  ],
  [
    3296,
    63.54494,
    10.87749,
    24,
    23.86
  ],
  [
    3298,
    63.54496,
    10.87844,
    23.2,
    23.56
  ],
  [
    3300,
    63.54498,
    10.87938,
    22.4,
    23.28
  ],
  [
    3302,
    63.54499,
    10.88031,
    21.3,
    23.02
  ],
  [
    3304,
    63.545,
    10.88123,
    20.5,
    22.8
  ],
  [
    3306,
    63.545,
    10.88215,
    19.9,
    22.29
  ],
  [
    3308,
    63.54498,
    10.88304,
    19.4,
    22.23
  ],
  [
    3310,
    63.54494,
    10.88392,
    18.9,
    21.75
  ],
  [
    3312,
    63.54487,
    10.88477,
    17.5,
    21.31
  ],
  [
    3314,
    63.54477,
    10.8856,
    16.5,
    20.86
  ],
  [
    3316,
    63.54465,
    10.88638,
    15.6,
    20.37
  ],
  [
    3318,
    63.54451,
    10.88713,
    15.6,
    19.82
  ],
  [
    3320,
    63.54438,
    10.88786,
    15.6,
    19.2
  ],
  [
    3322,
    63.54427,
    10.88858,
    13.9,
    18.55
  ],
  [
    3324,
    63.5442,
    10.88929,
    13.7,
    18.01
  ],
  [
    3326,
    63.54417,
    10.89,
    13.6,
    17.53
  ],
  [
    3328,
    63.54417,
    10.8907,
    13.4,
    17.04
  ],
  [
    3330,
    63.54421,
    10.89136,
    13.2,
    16.54
  ],
  [
    3332,
    63.54428,
    10.89199,
    13,
    16.02
  ],
  [
    3334,
    63.54438,
    10.89258,
    12.6,
    15.16
  ],
  [
    3337,
    63.54455,
    10.89336,
    12.1,
    14.09
  ],
  [
    3339,
    63.54466,
    10.89381,
    11.8,
    13.08
  ],
  [
    3341,
    63.54477,
    10.89423,
    11.5,
    12.6
  ],
  [
    3343,
    63.54488,
    10.89463,
    11.2,
    11.63
  ],
  [
    3345,
    63.54498,
    10.89501,
    11,
    11.63
  ],
  [
    3347,
    63.54509,
    10.89539,
    10.8,
    11.63
  ],
  [
    3349,
    63.54519,
    10.89575,
    10.6,
    10.88
  ],
  [
    3351,
    63.54529,
    10.89612,
    10.4,
    10.88
  ],
  [
    3353,
    63.5454,
    10.89649,
    10.2,
    10.96
  ],
  [
    3355,
    63.5455,
    10.89686,
    10.2,
    10.96
  ],
  [
    3357,
    63.54561,
    10.89724,
    10.2,
    10.96
  ],
  [
    3359,
    63.54572,
    10.89762,
    10.2,
    11.33
  ],
  [
    3361,
    63.54581,
    10.89801,
    10.3,
    11.09
  ],
  [
    3363,
    63.5459,
    10.89838,
    10.3,
    10.56
  ],
  [
    3365,
    63.546,
    10.89872,
    10.3,
    10.1
  ],
  [
    3367,
    63.5461,
    10.89903,
    10.4,
    9.64
  ],
  [
    3369,
    63.5462,
    10.89933,
    10.5,
    9.26
  ],
  [
    3371,
    63.5463,
    10.8996,
    10.5,
    8.96
  ],
  [
    3373,
    63.54641,
    10.89985,
    10.7,
    8.71
  ],
  [
    3375,
    63.54652,
    10.9001,
    10.7,
    8.68
  ],
  [
    3377,
    63.54663,
    10.90034,
    10.9,
    8.71
  ],
  [
    3379,
    63.54675,
    10.90057,
    11.1,
    8.72
  ],
  [
    3381,
    63.54687,
    10.90078,
    11.1,
    8.69
  ],
  [
    3383,
    63.54699,
    10.90099,
    11.1,
    8.7
  ],
  [
    3385,
    63.54712,
    10.9012,
    11,
    8.5
  ],
  [
    3387,
    63.54723,
    10.90139,
    11,
    8.16
  ],
  [
    3389,
    63.54735,
    10.90156,
    11,
    7.28
  ],
  [
    3391,
    63.54745,
    10.90171,
    10.9,
    6.61
  ],
  [
    3393,
    63.54754,
    10.90185,
    10.8,
    5.91
  ],
  [
    3395,
    63.54762,
    10.90196,
    10.7,
    5.09
  ],
  [
    3397,
    63.54769,
    10.90206,
    10.6,
    4.62
  ],
  [
    3399,
    63.54775,
    10.90213,
    10.5,
    3.09
  ],
  [
    3401,
    63.54779,
    10.90218,
    10.4,
    2.03
  ],
  [
    3403,
    63.54781,
    10.9022,
    10.3,
    1.11
  ],
  [
    3405,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3407,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3409,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3411,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3413,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3415,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3417,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3419,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3421,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3423,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3425,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3427,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3429,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3431,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3433,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3435,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3437,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3439,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3441,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3443,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3445,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3447,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3449,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3451,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3453,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3455,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3457,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3459,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3461,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3463,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3465,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3467,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3469,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3471,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3473,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3475,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3477,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3479,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3481,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3483,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3485,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3487,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3489,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3491,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3493,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3495,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3497,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3499,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3501,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3503,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3505,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3507,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3509,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3511,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3513,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3515,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3517,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3519,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3521,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3523,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3525,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3527,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3529,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3531,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3533,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3535,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3537,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3539,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3541,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3543,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3545,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3547,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3550,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3552,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3554,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3556,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3558,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3560,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3562,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3564,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3566,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3568,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3570,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3572,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3574,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3576,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3578,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3580,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3582,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3584,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3586,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3588,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3590,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3592,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3594,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3596,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3598,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3600,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3602,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3604,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3606,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3608,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3610,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3612,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3614,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3616,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3618,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3620,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3622,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3624,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3626,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3628,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3630,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3632,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3634,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3636,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3638,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3640,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3642,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3644,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3646,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3648,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3650,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3652,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3654,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3656,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3658,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3660,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3662,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3664,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3666,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3668,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3670,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3672,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3674,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3676,
    63.54782,
    10.90222,
    10.2,
    0
  ],
  [
    3678,
    63.54788,
    10.90221,
    8.1,
    1.94
  ],
  [
    3680,
    63.54792,
    10.90225,
    8.1,
    2.6
  ],
  [
    3682,
    63.54797,
    10.9023,
    8.2,
    3.31
  ],
  [
    3684,
    63.54803,
    10.90236,
    8.2,
    4.05
  ],
  [
    3686,
    63.5481,
    10.90242,
    8.3,
    4.62
  ],
  [
    3688,
    63.54818,
    10.90249,
    8.4,
    5.13
  ],
  [
    3690,
    63.54828,
    10.90255,
    8.6,
    5.55
  ],
  [
    3692,
    63.54837,
    10.90263,
    8.7,
    6.01
  ],
  [
    3694,
    63.54848,
    10.90272,
    8.8,
    6.4
  ],
  [
    3696,
    63.54859,
    10.90283,
    8.9,
    6.79
  ],
  [
    3698,
    63.5487,
    10.90294,
    9.1,
    7.15
  ],
  [
    3700,
    63.54882,
    10.90307,
    9.3,
    7.51
  ],
  [
    3702,
    63.54894,
    10.9032,
    9.5,
    7.79
  ],
  [
    3704,
    63.54907,
    10.90334,
    9.8,
    8.07
  ],
  [
    3706,
    63.5492,
    10.90349,
    10.1,
    8.36
  ],
  [
    3708,
    63.54934,
    10.90366,
    10.4,
    8.6
  ],
  [
    3710,
    63.54947,
    10.90385,
    10.7,
    8.85
  ],
  [
    3712,
    63.5496,
    10.90406,
    11,
    9.18
  ],
  [
    3714,
    63.54973,
    10.90431,
    11.3,
    9.64
  ],
  [
    3716,
    63.54986,
    10.90458,
    11.6,
    10.12
  ],
  [
    3718,
    63.54999,
    10.90488,
    11.9,
    10.62
  ],
  [
    3720,
    63.55012,
    10.9052,
    12.2,
    11.1
  ],
  [
    3722,
    63.55026,
    10.90554,
    12.6,
    11.59
  ],
  [
    3724,
    63.55041,
    10.90588,
    13.1,
    12.08
  ],
  [
    3726,
    63.55057,
    10.90622,
    13.5,
    12.54
  ],
  [
    3728,
    63.55075,
    10.90653,
    13.8,
    12.97
  ],
  [
    3730,
    63.55095,
    10.90683,
    14.1,
    13.37
  ],
  [
    3732,
    63.55116,
    10.90709,
    14.8,
    13.81
  ],
  [
    3734,
    63.55139,
    10.90732,
    15.4,
    14.08
  ],
  [
    3736,
    63.55163,
    10.90754,
    16,
    14.41
  ],
  [
    3738,
    63.55187,
    10.90778,
    16.6,
    14.74
  ],
  [
    3740,
    63.55211,
    10.90805,
    17.2,
    15.05
  ],
  [
    3742,
    63.55234,
    10.90837,
    17.4,
    15.35
  ],
  [
    3744,
    63.55256,
    10.90874,
    17.4,
    15.64
  ],
  [
    3746,
    63.55277,
    10.90918,
    17.4,
    15.95
  ],
  [
    3748,
    63.55296,
    10.90967,
    18.6,
    16.26
  ],
  [
    3750,
    63.55313,
    10.91021,
    18.9,
    16.64
  ],
  [
    3752,
    63.55328,
    10.9108,
    19,
    17
  ],
  [
    3754,
    63.55341,
    10.91143,
    19.2,
    17.4
  ],
  [
    3756,
    63.55353,
    10.91209,
    19.1,
    17.84
  ],
  [
    3758,
    63.55366,
    10.91276,
    19,
    18.28
  ],
  [
    3761,
    63.55387,
    10.91378,
    18.9,
    18.75
  ],
  [
    3763,
    63.55403,
    10.91448,
    18.8,
    19.21
  ],
  [
    3765,
    63.55418,
    10.91519,
    18.8,
    19.67
  ],
  [
    3767,
    63.55435,
    10.91591,
    18.8,
    20.1
  ],
  [
    3769,
    63.55451,
    10.91665,
    18.7,
    20.32
  ],
  [
    3771,
    63.55468,
    10.9174,
    18.7,
    20.32
  ],
  [
    3773,
    63.55486,
    10.91816,
    18.8,
    21.32
  ],
  [
    3775,
    63.55506,
    10.91892,
    18.9,
    21.32
  ],
  [
    3777,
    63.55527,
    10.91967,
    19.2,
    21.32
  ],
  [
    3779,
    63.55548,
    10.92044,
    19.5,
    22.18
  ],
  [
    3781,
    63.55568,
    10.92123,
    19.7,
    22.18
  ],
  [
    3783,
    63.55589,
    10.92204,
    20.1,
    22.99
  ],
  [
    3785,
    63.5561,
    10.92284,
    20.5,
    22.99
  ],
  [
    3787,
    63.55633,
    10.92363,
    21,
    22.99
  ],
  [
    3789,
    63.55659,
    10.92437,
    21.6,
    23.53
  ],
  [
    3791,
    63.55687,
    10.92511,
    22.1,
    23.69
  ],
  [
    3793,
    63.55714,
    10.92584,
    22.7,
    23.84
  ],
  [
    3795,
    63.55742,
    10.92658,
    23,
    24.03
  ],
  [
    3797,
    63.5577,
    10.92733,
    23.1,
    24.24
  ],
  [
    3799,
    63.55799,
    10.92809,
    23.3,
    24.49
  ],
  [
    3801,
    63.55827,
    10.92885,
    23.3,
    24.92
  ],
  [
    3803,
    63.55856,
    10.92963,
    23.4,
    25.23
  ],
  [
    3805,
    63.55886,
    10.93041,
    23.3,
    25.53
  ],
  [
    3807,
    63.55916,
    10.9312,
    23.1,
    25.71
  ],
  [
    3809,
    63.55946,
    10.93201,
    22.9,
    26.25
  ],
  [
    3811,
    63.55977,
    10.93282,
    22.7,
    26.59
  ],
  [
    3813,
    63.56009,
    10.93362,
    22.5,
    26.91
  ],
  [
    3815,
    63.56042,
    10.93441,
    22.3,
    27.26
  ],
  [
    3817,
    63.56078,
    10.93518,
    22,
    27.44
  ],
  [
    3819,
    63.56115,
    10.93593,
    21.7,
    28
  ],
  [
    3821,
    63.56154,
    10.93666,
    21.2,
    28.42
  ],
  [
    3823,
    63.56195,
    10.93736,
    20.7,
    28.8
  ],
  [
    3825,
    63.56236,
    10.93806,
    20.1,
    29.05
  ],
  [
    3827,
    63.56277,
    10.93879,
    19.4,
    29.1
  ],
  [
    3829,
    63.56316,
    10.93959,
    18.9,
    29.3
  ],
  [
    3831,
    63.56351,
    10.94046,
    18.3,
    29.37
  ],
  [
    3833,
    63.56383,
    10.94141,
    18.1,
    29.46
  ],
  [
    3835,
    63.56412,
    10.94241,
    18,
    29.49
  ],
  [
    3837,
    63.56436,
    10.94346,
    17.6,
    29.51
  ],
  [
    3839,
    63.56458,
    10.94455,
    17.4,
    29.6
  ],
  [
    3841,
    63.5648,
    10.94564,
    17.5,
    29.61
  ],
  [
    3843,
    63.56502,
    10.94673,
    17.9,
    29.6
  ],
  [
    3845,
    63.56525,
    10.9478,
    18.6,
    29.49
  ],
  [
    3847,
    63.56549,
    10.94885,
    19.6,
    29.29
  ],
  [
    3849,
    63.56573,
    10.9499,
    20.7,
    29.02
  ],
  [
    3851,
    63.56597,
    10.95092,
    21.7,
    28.73
  ],
  [
    3853,
    63.56621,
    10.95195,
    22.7,
    28.46
  ],
  [
    3855,
    63.56641,
    10.95299,
    23.7,
    28.22
  ],
  [
    3857,
    63.56658,
    10.95406,
    24.8,
    28
  ],
  [
    3859,
    63.56674,
    10.95512,
    25.9,
    27.79
  ],
  [
    3861,
    63.56693,
    10.95615,
    26.9,
    27.61
  ],
  [
    3863,
    63.56716,
    10.95714,
    27.9,
    27.42
  ],
  [
    3865,
    63.56741,
    10.95808,
    28.8,
    27.05
  ],
  [
    3867,
    63.56769,
    10.95896,
    29.8,
    26.67
  ],
  [
    3869,
    63.56798,
    10.9598,
    30.8,
    26.3
  ],
  [
    3871,
    63.56825,
    10.96065,
    31.8,
    25.95
  ],
  [
    3873,
    63.56851,
    10.96152,
    32.9,
    25.58
  ],
  [
    3875,
    63.56873,
    10.9624,
    33.7,
    25.2
  ],
  [
    3877,
    63.56895,
    10.96329,
    34.5,
    24.81
  ],
  [
    3879,
    63.56918,
    10.96413,
    35.5,
    24.43
  ],
  [
    3881,
    63.56943,
    10.96492,
    36.4,
    24.09
  ],
  [
    3883,
    63.56969,
    10.96568,
    37.3,
    23.68
  ],
  [
    3885,
    63.56992,
    10.96647,
    38,
    23.37
  ],
  [
    3887,
    63.57013,
    10.96727,
    38.8,
    23.09
  ],
  [
    3889,
    63.57032,
    10.9681,
    39.7,
    22.83
  ],
  [
    3891,
    63.57048,
    10.96893,
    40.5,
    22.65
  ],
  [
    3893,
    63.57063,
    10.96977,
    41.2,
    22.37
  ],
  [
    3895,
    63.57081,
    10.97057,
    41.9,
    22.17
  ],
  [
    3897,
    63.57102,
    10.97132,
    42.7,
    22.03
  ],
  [
    3899,
    63.57127,
    10.97199,
    43.7,
    21.97
  ],
  [
    3901,
    63.57154,
    10.97264,
    44.6,
    21.9
  ],
  [
    3903,
    63.57178,
    10.97332,
    45.1,
    21.89
  ],
  [
    3905,
    63.57199,
    10.97407,
    45.6,
    21.93
  ],
  [
    3907,
    63.57214,
    10.97489,
    46.1,
    22.01
  ],
  [
    3909,
    63.57224,
    10.97574,
    46.5,
    22.07
  ],
  [
    3911,
    63.57231,
    10.97662,
    47.2,
    22.13
  ],
  [
    3913,
    63.57238,
    10.97749,
    47.8,
    22.18
  ],
  [
    3915,
    63.57249,
    10.97836,
    48.6,
    22.25
  ],
  [
    3917,
    63.57263,
    10.97919,
    49.3,
    22.32
  ],
  [
    3919,
    63.57282,
    10.97998,
    50.1,
    22.38
  ],
  [
    3921,
    63.57304,
    10.98073,
    50.9,
    22.45
  ],
  [
    3923,
    63.57328,
    10.98146,
    51.7,
    22.52
  ],
  [
    3925,
    63.57352,
    10.98218,
    52.6,
    22.55
  ],
  [
    3927,
    63.57377,
    10.9829,
    53,
    22.59
  ],
  [
    3929,
    63.57402,
    10.98361,
    53,
    22.63
  ],
  [
    3931,
    63.57427,
    10.98432,
    53,
    22.68
  ],
  [
    3933,
    63.57453,
    10.98503,
    53,
    22.7
  ],
  [
    3935,
    63.57479,
    10.98573,
    56.8,
    22.73
  ],
  [
    3937,
    63.57506,
    10.98642,
    57.4,
    22.73
  ],
  [
    3939,
    63.57534,
    10.98708,
    58,
    22.76
  ],
  [
    3941,
    63.57563,
    10.98772,
    58.4,
    22.84
  ],
  [
    3943,
    63.57591,
    10.98839,
    58.7,
    22.88
  ],
  [
    3945,
    63.57615,
    10.98914,
    58.8,
    22.97
  ],
  [
    3948,
    63.57641,
    10.99039,
    59.2,
    23.03
  ],
  [
    3950,
    63.57653,
    10.99129,
    59.4,
    23.13
  ],
  [
    3952,
    63.57662,
    10.99219,
    59.6,
    23.16
  ],
  [
    3954,
    63.57674,
    10.99309,
    59.9,
    23.25
  ],
  [
    3956,
    63.57689,
    10.99397,
    60.1,
    23.25
  ],
  [
    3958,
    63.57709,
    10.99481,
    60.3,
    23.62
  ],
  [
    3960,
    63.57733,
    10.9956,
    60.5,
    23.62
  ],
  [
    3962,
    63.5776,
    10.99635,
    60.7,
    23.62
  ],
  [
    3964,
    63.57789,
    10.99709,
    60.8,
    24.11
  ],
  [
    3966,
    63.57817,
    10.99783,
    61,
    24.11
  ],
  [
    3968,
    63.57847,
    10.99858,
    61.2,
    24.73
  ],
  [
    3970,
    63.57876,
    10.99934,
    61.5,
    24.73
  ],
  [
    3972,
    63.57906,
    11.0001,
    61.9,
    25.2
  ],
  [
    3974,
    63.57935,
    11.00087,
    62.3,
    25.37
  ],
  [
    3976,
    63.57966,
    11.00164,
    62.8,
    25.53
  ],
  [
    3978,
    63.57998,
    11.00239,
    63.2,
    25.65
  ],
  [
    3980,
    63.58032,
    11.00308,
    63.8,
    25.77
  ],
  [
    3982,
    63.58068,
    11.00373,
    64.2,
    25.88
  ],
  [
    3984,
    63.58106,
    11.00434,
    64.7,
    25.98
  ],
  [
    3986,
    63.58144,
    11.00495,
    65.4,
    26.06
  ],
  [
    3988,
    63.58183,
    11.00555,
    66.1,
    26.13
  ],
  [
    3990,
    63.58221,
    11.00616,
    66.8,
    26.15
  ],
  [
    3992,
    63.58259,
    11.00677,
    67.7,
    26.16
  ],
  [
    3994,
    63.58298,
    11.00737,
    68.5,
    26.14
  ],
  [
    3996,
    63.58336,
    11.00798,
    69.4,
    26.14
  ],
  [
    3998,
    63.58374,
    11.00859,
    70.3,
    26.13
  ],
  [
    4000,
    63.58413,
    11.00919,
    71.1,
    26.13
  ],
  [
    4002,
    63.58451,
    11.0098,
    71.9,
    26.13
  ],
  [
    4004,
    63.58489,
    11.01041,
    72.6,
    26.15
  ],
  [
    4006,
    63.58528,
    11.01102,
    73.3,
    26.18
  ],
  [
    4008,
    63.58566,
    11.01163,
    73.9,
    26.22
  ],
  [
    4010,
    63.58604,
    11.01226,
    74.7,
    26.28
  ],
  [
    4012,
    63.58639,
    11.01295,
    75.5,
    26.28
  ],
  [
    4014,
    63.58671,
    11.01373,
    76.4,
    26.29
  ],
  [
    4016,
    63.58699,
    11.01457,
    77.2,
    26.21
  ],
  [
    4018,
    63.58723,
    11.01547,
    78.3,
    25.93
  ],
  [
    4020,
    63.58742,
    11.01641,
    79.2,
    25.48
  ],
  [
    4022,
    63.58759,
    11.01736,
    80.1,
    25.27
  ],
  [
    4024,
    63.58775,
    11.0183,
    80.9,
    24.67
  ],
  [
    4026,
    63.58792,
    11.0192,
    81.7,
    24.3
  ],
  [
    4028,
    63.58813,
    11.02006,
    82.5,
    23.92
  ],
  [
    4030,
    63.58835,
    11.02087,
    83.3,
    23.54
  ],
  [
    4032,
    63.5886,
    11.02162,
    84.1,
    23.39
  ],
  [
    4034,
    63.58887,
    11.02233,
    84.8,
    22.99
  ],
  [
    4036,
    63.58916,
    11.02299,
    85.5,
    22.72
  ],
  [
    4038,
    63.58946,
    11.02359,
    86.2,
    22.46
  ],
  [
    4040,
    63.58978,
    11.02413,
    86.9,
    22.21
  ],
  [
    4042,
    63.59011,
    11.02462,
    87.6,
    21.97
  ],
  [
    4044,
    63.59045,
    11.02505,
    88.1,
    21.76
  ],
  [
    4046,
    63.59079,
    11.02548,
    88.8,
    21.6
  ],
  [
    4048,
    63.59111,
    11.02594,
    89.4,
    21.45
  ],
  [
    4050,
    63.59141,
    11.02649,
    90,
    21.28
  ],
  [
    4052,
    63.59166,
    11.02713,
    90.6,
    21.12
  ],
  [
    4054,
    63.59187,
    11.02783,
    91.2,
    20.97
  ],
  [
    4056,
    63.59205,
    11.02857,
    91.7,
    20.85
  ],
  [
    4058,
    63.5922,
    11.02933,
    92.2,
    20.76
  ],
  [
    4060,
    63.59233,
    11.03011,
    92.7,
    20.68
  ],
  [
    4062,
    63.59245,
    11.0309,
    93.4,
    20.6
  ],
  [
    4064,
    63.59258,
    11.03168,
    93.9,
    20.49
  ],
  [
    4066,
    63.59273,
    11.03242,
    94.5,
    20.35
  ],
  [
    4068,
    63.59292,
    11.03312,
    95.1,
    20.22
  ],
  [
    4070,
    63.59313,
    11.03378,
    95.7,
    20.12
  ],
  [
    4072,
    63.59335,
    11.03442,
    96.2,
    20.06
  ],
  [
    4074,
    63.59356,
    11.03507,
    96.8,
    19.98
  ],
  [
    4076,
    63.59376,
    11.03574,
    97.5,
    19.88
  ],
  [
    4078,
    63.59393,
    11.03643,
    98.1,
    19.82
  ],
  [
    4080,
    63.59408,
    11.03715,
    98.6,
    19.71
  ],
  [
    4082,
    63.59422,
    11.03788,
    99.2,
    19.59
  ],
  [
    4084,
    63.59435,
    11.0386,
    99.8,
    19.48
  ],
  [
    4086,
    63.59451,
    11.0393,
    100.3,
    19.37
  ],
  [
    4088,
    63.5947,
    11.03994,
    100.9,
    19.27
  ],
  [
    4090,
    63.59494,
    11.0405,
    101.3,
    19.22
  ],
  [
    4092,
    63.5952,
    11.041,
    101.8,
    18.81
  ],
  [
    4094,
    63.59546,
    11.04149,
    102.3,
    18.81
  ],
  [
    4096,
    63.59579,
    11.04173,
    102.9,
    19.56
  ],
  [
    4098,
    63.59614,
    11.04185,
    101.1,
    19.36
  ],
  [
    4100,
    63.59649,
    11.04195,
    100.2,
    19.51
  ],
  [
    4102,
    63.59684,
    11.04205,
    99.3,
    19.69
  ],
  [
    4104,
    63.5972,
    11.04211,
    98.3,
    19.94
  ],
  [
    4106,
    63.59756,
    11.04214,
    97.4,
    20.22
  ],
  [
    4108,
    63.59793,
    11.04213,
    96.6,
    20.5
  ],
  [
    4110,
    63.5983,
    11.04208,
    95.8,
    20.82
  ],
  [
    4112,
    63.59868,
    11.04199,
    95,
    21.09
  ],
  [
    4114,
    63.59905,
    11.04184,
    94.3,
    21.42
  ],
  [
    4116,
    63.59943,
    11.04165,
    93.7,
    21.67
  ],
  [
    4118,
    63.59981,
    11.04141,
    92.9,
    21.91
  ],
  [
    4120,
    63.60019,
    11.04113,
    92.1,
    22.18
  ],
  [
    4122,
    63.60056,
    11.04079,
    91.3,
    22.46
  ],
  [
    4124,
    63.60093,
    11.0404,
    90.6,
    22.73
  ],
  [
    4126,
    63.6013,
    11.04,
    90.6,
    23
  ],
  [
    4128,
    63.60168,
    11.03959,
    90.6,
    23.29
  ],
  [
    4130,
    63.60207,
    11.03923,
    90.6,
    23.47
  ],
  [
    4132,
    63.60247,
    11.039,
    87.8,
    23.15
  ],
  [
    4134,
    63.60288,
    11.03888,
    87.2,
    23.01
  ],
  [
    4136,
    63.60329,
    11.03889,
    85.9,
    22.41
  ],
  [
    4138,
    63.60369,
    11.03903,
    85.1,
    22.31
  ],
  [
    4140,
    63.60407,
    11.03928,
    84.3,
    22.08
  ],
  [
    4142,
    63.60444,
    11.03963,
    83.6,
    22.07
  ],
  [
    4144,
    63.60478,
    11.04009,
    82.8,
    22.18
  ],
  [
    4147,
    63.60524,
    11.04096,
    81.6,
    22.36
  ],
  [
    4149,
    63.60552,
    11.04163,
    80.8,
    22.58
  ],
  [
    4151,
    63.60578,
    11.04235,
    79.9,
    22.83
  ],
  [
    4153,
    63.60605,
    11.04307,
    79,
    23.11
  ],
  [
    4155,
    63.60631,
    11.0438,
    78.2,
    23.11
  ],
  [
    4157,
    63.60659,
    11.04455,
    77.4,
    23.11
  ],
  [
    4159,
    63.60686,
    11.0453,
    76.5,
    23.85
  ],
  [
    4161,
    63.60714,
    11.04606,
    75.7,
    23.85
  ],
  [
    4163,
    63.60742,
    11.04682,
    74.7,
    24.58
  ],
  [
    4165,
    63.60771,
    11.0476,
    73.7,
    24.58
  ],
  [
    4167,
    63.60799,
    11.04838,
    72.8,
    24.58
  ],
  [
    4169,
    63.60829,
    11.04918,
    72.1,
    25.32
  ],
  [
    4171,
    63.60858,
    11.04998,
    71.7,
    25.32
  ],
  [
    4173,
    63.60888,
    11.05078,
    71.5,
    25.88
  ],
  [
    4175,
    63.60918,
    11.05159,
    71.5,
    25.96
  ],
  [
    4177,
    63.60948,
    11.0524,
    71.6,
    25.96
  ],
  [
    4179,
    63.60978,
    11.05321,
    71.6,
    25.92
  ],
  [
    4181,
    63.61007,
    11.05401,
    71.7,
    25.86
  ],
  [
    4183,
    63.61037,
    11.05481,
    71.9,
    25.79
  ],
  [
    4185,
    63.61066,
    11.0556,
    71.3,
    25.67
  ],
  [
    4187,
    63.61096,
    11.0564,
    71.3,
    25.64
  ],
  [
    4189,
    63.61125,
    11.05719,
    71.1,
    25.39
  ],
  [
    4191,
    63.61154,
    11.05797,
    70.8,
    24.87
  ],
  [
    4193,
    63.61182,
    11.05874,
    70.4,
    24.47
  ],
  [
    4195,
    63.6121,
    11.05948,
    70,
    24.02
  ],
  [
    4197,
    63.61239,
    11.06019,
    69.6,
    23.79
  ],
  [
    4199,
    63.6127,
    11.06083,
    69.1,
    23.28
  ],
  [
    4201,
    63.61304,
    11.06138,
    68.5,
    23.24
  ],
  [
    4203,
    63.6134,
    11.06185,
    67.9,
    23.31
  ],
  [
    4205,
    63.61378,
    11.06222,
    67.2,
    23.43
  ],
  [
    4207,
    63.61419,
    11.06251,
    66.7,
    23.46
  ],
  [
    4209,
    63.61459,
    11.06276,
    66.3,
    23.35
  ],
  [
    4211,
    63.61499,
    11.06305,
    65.8,
    23.3
  ],
  [
    4213,
    63.61537,
    11.06346,
    65.1,
    23.31
  ],
  [
    4215,
    63.61573,
    11.06393,
    64.4,
    23.24
  ],
  [
    4217,
    63.61608,
    11.06442,
    63.7,
    23.27
  ],
  [
    4219,
    63.61643,
    11.06493,
    63,
    23.33
  ],
  [
    4221,
    63.61678,
    11.06545,
    62.4,
    23.37
  ],
  [
    4223,
    63.61713,
    11.06598,
    61.8,
    23.41
  ],
  [
    4225,
    63.61747,
    11.06653,
    61.2,
    23.45
  ],
  [
    4227,
    63.61779,
    11.06714,
    60.7,
    23.48
  ],
  [
    4229,
    63.61807,
    11.06785,
    60.4,
    23.4
  ],
  [
    4231,
    63.61829,
    11.06866,
    59.8,
    23.59
  ],
  [
    4233,
    63.61848,
    11.06952,
    59.4,
    23.72
  ],
  [
    4235,
    63.61865,
    11.0704,
    59.1,
    23.84
  ],
  [
    4237,
    63.61883,
    11.07127,
    58.6,
    23.95
  ],
  [
    4239,
    63.61903,
    11.07214,
    58,
    24.08
  ],
  [
    4241,
    63.61924,
    11.07298,
    57.7,
    24.19
  ],
  [
    4243,
    63.61947,
    11.07381,
    57.4,
    24.29
  ],
  [
    4245,
    63.61973,
    11.07461,
    57.3,
    24.31
  ],
  [
    4247,
    63.61999,
    11.07538,
    57.4,
    24.31
  ],
  [
    4249,
    63.62028,
    11.07613,
    57.4,
    24.26
  ],
  [
    4251,
    63.62058,
    11.07683,
    57.4,
    24.21
  ],
  [
    4253,
    63.62089,
    11.07751,
    57.3,
    24.15
  ],
  [
    4255,
    63.62121,
    11.07818,
    57.4,
    24.14
  ],
  [
    4257,
    63.62151,
    11.07886,
    57.2,
    24.17
  ],
  [
    4259,
    63.6218,
    11.0796,
    56.8,
    24.23
  ],
  [
    4261,
    63.62206,
    11.08038,
    56.5,
    24.3
  ],
  [
    4263,
    63.62231,
    11.08118,
    55.9,
    24.13
  ],
  [
    4265,
    63.62257,
    11.08195,
    55.3,
    23.72
  ],
  [
    4267,
    63.62285,
    11.08265,
    54.7,
    23.41
  ],
  [
    4269,
    63.62318,
    11.08324,
    54.4,
    23.27
  ],
  [
    4271,
    63.62354,
    11.0837,
    54.2,
    23.2
  ],
  [
    4273,
    63.62393,
    11.08403,
    54.2,
    23.17
  ],
  [
    4275,
    63.62433,
    11.08422,
    54.3,
    23.07
  ],
  [
    4277,
    63.62475,
    11.08431,
    54.6,
    22.96
  ],
  [
    4279,
    63.62516,
    11.08433,
    54.8,
    22.81
  ],
  [
    4281,
    63.62556,
    11.08426,
    55.2,
    22.63
  ],
  [
    4283,
    63.62597,
    11.08413,
    55.4,
    22.49
  ],
  [
    4285,
    63.62636,
    11.08392,
    55.7,
    22.36
  ],
  [
    4287,
    63.62674,
    11.08364,
    55.4,
    22.1
  ],
  [
    4289,
    63.62711,
    11.0833,
    55,
    22.11
  ],
  [
    4291,
    63.62747,
    11.08289,
    54.6,
    22.09
  ],
  [
    4293,
    63.62781,
    11.08242,
    54,
    22.1
  ],
  [
    4295,
    63.62813,
    11.08188,
    53.7,
    22.15
  ],
  [
    4297,
    63.62841,
    11.08123,
    53.3,
    22.22
  ],
  [
    4299,
    63.62865,
    11.0805,
    52.9,
    22.35
  ],
  [
    4301,
    63.62887,
    11.07973,
    52.9,
    22.48
  ],
  [
    4303,
    63.6291,
    11.07896,
    52.5,
    22.63
  ],
  [
    4305,
    63.62936,
    11.07825,
    52,
    22.77
  ],
  [
    4307,
    63.62966,
    11.07763,
    51.9,
    22.86
  ],
  [
    4309,
    63.63001,
    11.07712,
    51.9,
    22.96
  ],
  [
    4311,
    63.63038,
    11.07673,
    51.5,
    23.14
  ],
  [
    4313,
    63.63077,
    11.07641,
    51.4,
    23.01
  ],
  [
    4315,
    63.63117,
    11.07611,
    51.6,
    23.08
  ],
  [
    4317,
    63.63156,
    11.07582,
    51.9,
    23.11
  ],
  [
    4319,
    63.63196,
    11.07552,
    52.4,
    23.1
  ],
  [
    4321,
    63.63235,
    11.07522,
    52.7,
    23.03
  ],
  [
    4323,
    63.63274,
    11.07493,
    52.7,
    22.88
  ],
  [
    4325,
    63.63313,
    11.07464,
    52.7,
    22.7
  ],
  [
    4327,
    63.63351,
    11.07432,
    55.2,
    22.47
  ],
  [
    4329,
    63.63387,
    11.0739,
    56,
    22.26
  ],
  [
    4331,
    63.63419,
    11.07336,
    56.7,
    22.07
  ],
  [
    4334,
    63.63459,
    11.07239,
    57.7,
    21.97
  ],
  [
    4336,
    63.6348,
    11.07164,
    58.5,
    21.97
  ],
  [
    4338,
    63.63497,
    11.07085,
    59.3,
    21.53
  ],
  [
    4340,
    63.63511,
    11.07004,
    60.1,
    21.53
  ],
  [
    4342,
    63.63527,
    11.06925,
    60.9,
    21.53
  ],
  [
    4344,
    63.63547,
    11.06851,
    61.5,
    21.39
  ],
  [
    4346,
    63.63571,
    11.06784,
    62.1,
    21.4
  ],
  [
    4348,
    63.636,
    11.06725,
    62.6,
    21.42
  ],
  [
    4350,
    63.63632,
    11.06676,
    63.4,
    21.47
  ],
  [
    4352,
    63.63667,
    11.06638,
    64,
    21.59
  ],
  [
    4354,
    63.63704,
    11.06612,
    64.6,
    21.69
  ],
  [
    4356,
    63.63742,
    11.06598,
    64.9,
    21.86
  ],
  [
    4358,
    63.63782,
    11.06596,
    65.2,
    22.03
  ],
  [
    4360,
    63.63821,
    11.06608,
    65.5,
    22.15
  ],
  [
    4362,
    63.6386,
    11.06633,
    65.7,
    22.29
  ],
  [
    4364,
    63.63897,
    11.06672,
    65.5,
    22.55
  ],
  [
    4366,
    63.63931,
    11.06723,
    65.8,
    22.79
  ],
  [
    4368,
    63.63963,
    11.06782,
    65.9,
    23.02
  ],
  [
    4370,
    63.63994,
    11.06844,
    66,
    23.26
  ],
  [
    4372,
    63.64026,
    11.06907,
    66.1,
    23.38
  ],
  [
    4374,
    63.64058,
    11.0697,
    66.3,
    23.79
  ],
  [
    4376,
    63.6409,
    11.07034,
    66.7,
    24.06
  ],
  [
    4378,
    63.64124,
    11.07097,
    66.7,
    24.25
  ],
  [
    4380,
    63.64158,
    11.07159,
    66.9,
    24.5
  ],
  [
    4382,
    63.64194,
    11.07217,
    67.1,
    24.61
  ],
  [
    4384,
    63.64232,
    11.07268,
    67.3,
    24.95
  ],
  [
    4386,
    63.64274,
    11.07308,
    67.4,
    25.16
  ],
  [
    4388,
    63.64318,
    11.07337,
    67.5,
    25.39
  ],
  [
    4390,
    63.64363,
    11.07358,
    67.5,
    25.68
  ],
  [
    4392,
    63.64408,
    11.07378,
    67.6,
    25.8
  ],
  [
    4394,
    63.64454,
    11.07402,
    67.8,
    26.22
  ],
  [
    4396,
    63.64499,
    11.07432,
    67.9,
    26.49
  ],
  [
    4398,
    63.64545,
    11.07467,
    68.1,
    26.75
  ],
  [
    4400,
    63.64589,
    11.07509,
    68.1,
    27.01
  ],
  [
    4402,
    63.64634,
    11.07555,
    68.1,
    27.14
  ],
  [
    4404,
    63.64678,
    11.07604,
    68.1,
    27.54
  ],
  [
    4406,
    63.64722,
    11.07656,
    68,
    27.83
  ],
  [
    4408,
    63.64766,
    11.0771,
    67.9,
    28.13
  ],
  [
    4410,
    63.64811,
    11.07766,
    67.8,
    28.44
  ],
  [
    4412,
    63.64855,
    11.07826,
    67.6,
    28.77
  ],
  [
    4414,
    63.64899,
    11.07888,
    67.4,
    29.09
  ],
  [
    4416,
    63.64943,
    11.07953,
    67.3,
    29.42
  ],
  [
    4418,
    63.64985,
    11.08025,
    67.1,
    29.76
  ],
  [
    4420,
    63.65025,
    11.08106,
    66.8,
    30.05
  ],
  [
    4422,
    63.65062,
    11.08197,
    66.6,
    30.35
  ],
  [
    4424,
    63.65096,
    11.08294,
    66.2,
    30.67
  ],
  [
    4426,
    63.65128,
    11.08396,
    65.9,
    30.98
  ],
  [
    4428,
    63.65161,
    11.08498,
    65.9,
    31.28
  ],
  [
    4430,
    63.65194,
    11.08601,
    65.9,
    31.54
  ],
  [
    4432,
    63.65227,
    11.08705,
    66,
    31.75
  ],
  [
    4434,
    63.65261,
    11.0881,
    66.1,
    31.95
  ],
  [
    4436,
    63.65294,
    11.08915,
    66.2,
    32.16
  ],
  [
    4438,
    63.65328,
    11.09021,
    66.3,
    32.35
  ],
  [
    4440,
    63.65362,
    11.09127,
    66.7,
    32.53
  ],
  [
    4442,
    63.65397,
    11.09234,
    67.1,
    32.57
  ],
  [
    4444,
    63.65431,
    11.09341,
    67.6,
    32.42
  ],
  [
    4446,
    63.65466,
    11.09446,
    68.1,
    32.2
  ],
  [
    4448,
    63.65502,
    11.09546,
    68.6,
    32
  ],
  [
    4450,
    63.6554,
    11.09643,
    69,
    31.8
  ],
  [
    4452,
    63.6558,
    11.09735,
    69.3,
    31.62
  ],
  [
    4454,
    63.6562,
    11.09824,
    69.7,
    31.45
  ],
  [
    4456,
    63.65662,
    11.09908,
    70,
    31.27
  ],
  [
    4458,
    63.65705,
    11.0999,
    70.4,
    31.07
  ],
  [
    4460,
    63.65747,
    11.10072,
    70.9,
    30.85
  ],
  [
    4462,
    63.65789,
    11.10153,
    71.4,
    30.61
  ],
  [
    4464,
    63.6583,
    11.10234,
    71.7,
    30.39
  ],
  [
    4466,
    63.65871,
    11.10314,
    71.8,
    30.2
  ],
  [
    4468,
    63.65912,
    11.10394,
    71.6,
    30.1
  ],
  [
    4470,
    63.65953,
    11.10473,
    71.1,
    30.11
  ],
  [
    4472,
    63.65994,
    11.10552,
    70.4,
    30.17
  ],
  [
    4474,
    63.66036,
    11.10629,
    69.6,
    30.34
  ],
  [
    4476,
    63.6608,
    11.10702,
    68.5,
    30.5
  ],
  [
    4478,
    63.66125,
    11.10772,
    67.4,
    30.7
  ],
  [
    4480,
    63.66172,
    11.10838,
    66.5,
    30.91
  ],
  [
    4482,
    63.66221,
    11.109,
    65.6,
    31.09
  ],
  [
    4484,
    63.6627,
    11.10958,
    64.7,
    31.25
  ],
  [
    4486,
    63.66321,
    11.11011,
    64.1,
    31.37
  ],
  [
    4488,
    63.66373,
    11.11061,
    63.9,
    31.46
  ],
  [
    4490,
    63.66425,
    11.11111,
    63.7,
    31.46
  ],
  [
    4492,
    63.66477,
    11.1116,
    63.7,
    31.42
  ],
  [
    4494,
    63.66529,
    11.1121,
    63.7,
    31.35
  ],
  [
    4496,
    63.66581,
    11.11259,
    63.6,
    31.28
  ],
  [
    4498,
    63.66633,
    11.11308,
    63.6,
    31.19
  ],
  [
    4500,
    63.66684,
    11.11357,
    63.6,
    31.12
  ],
  [
    4502,
    63.66736,
    11.11406,
    63.6,
    31.06
  ],
  [
    4504,
    63.66787,
    11.11454,
    63.6,
    30.97
  ],
  [
    4506,
    63.66838,
    11.11503,
    63.5,
    30.9
  ],
  [
    4508,
    63.66889,
    11.11551,
    63.4,
    30.81
  ],
  [
    4510,
    63.6694,
    11.116,
    63.4,
    30.73
  ],
  [
    4512,
    63.6699,
    11.11651,
    63.4,
    30.66
  ],
  [
    4514,
    63.6704,
    11.11702,
    63.3,
    30.54
  ],
  [
    4516,
    63.67089,
    11.11754,
    63.1,
    29.93
  ],
  [
    4518,
    63.67137,
    11.11806,
    62.9,
    29.33
  ],
  [
    4521,
    63.67207,
    11.11885,
    62.2,
    28.66
  ],
  [
    4523,
    63.67251,
    11.11937,
    61.7,
    27.9
  ],
  [
    4525,
    63.67294,
    11.11988,
    61.1,
    27.01
  ],
  [
    4527,
    63.67335,
    11.12038,
    60.6,
    26.6
  ],
  [
    4529,
    63.67374,
    11.12088,
    60,
    25.71
  ],
  [
    4531,
    63.67412,
    11.12137,
    59.4,
    25.71
  ],
  [
    4533,
    63.67449,
    11.12184,
    58.9,
    23.65
  ],
  [
    4535,
    63.67484,
    11.12231,
    58.4,
    23.65
  ],
  [
    4537,
    63.67519,
    11.12278,
    58,
    23.65
  ],
  [
    4539,
    63.67553,
    11.12325,
    57.5,
    22.39
  ],
  [
    4541,
    63.67587,
    11.12373,
    57.1,
    22.39
  ],
  [
    4543,
    63.6762,
    11.12423,
    56.7,
    22.46
  ],
  [
    4545,
    63.67652,
    11.1248,
    56.3,
    22.6
  ],
  [
    4547,
    63.67679,
    11.12548,
    55.2,
    22.69
  ],
  [
    4549,
    63.67702,
    11.12625,
    54.3,
    22.84
  ],
  [
    4551,
    63.67719,
    11.12709,
    53.5,
    23.06
  ],
  [
    4553,
    63.67731,
    11.12798,
    53,
    23.09
  ],
  [
    4555,
    63.67736,
    11.12892,
    52.6,
    23.33
  ],
  [
    4557,
    63.67736,
    11.12987,
    52,
    23.44
  ],
  [
    4559,
    63.67735,
    11.13083,
    51.2,
    23.84
  ],
  [
    4561,
    63.67736,
    11.1318,
    50.4,
    24.2
  ],
  [
    4563,
    63.67741,
    11.13278,
    49.8,
    24.58
  ],
  [
    4565,
    63.67751,
    11.13376,
    49.2,
    24.95
  ],
  [
    4567,
    63.67765,
    11.13472,
    48.6,
    25.15
  ],
  [
    4569,
    63.67782,
    11.13568,
    47.9,
    25.82
  ],
  [
    4571,
    63.67801,
    11.13665,
    47.1,
    26.29
  ],
  [
    4573,
    63.67821,
    11.13762,
    46.3,
    26.8
  ],
  [
    4575,
    63.67841,
    11.13861,
    45.5,
    27.34
  ],
  [
    4577,
    63.67863,
    11.13962,
    44.7,
    27.61
  ],
  [
    4579,
    63.67886,
    11.14063,
    43.8,
    28.44
  ],
  [
    4581,
    63.6791,
    11.14166,
    42.9,
    29
  ],
  [
    4583,
    63.67935,
    11.1427,
    42.1,
    29.53
  ],
  [
    4585,
    63.67961,
    11.14376,
    41.1,
    30.04
  ],
  [
    4587,
    63.67988,
    11.14482,
    40.2,
    30.3
  ],
  [
    4589,
    63.68016,
    11.14589,
    39.2,
    31.08
  ],
  [
    4591,
    63.68046,
    11.14696,
    38.2,
    31.57
  ],
  [
    4593,
    63.68077,
    11.14804,
    37.3,
    32.06
  ],
  [
    4595,
    63.68109,
    11.14913,
    36.5,
    32.5
  ],
  [
    4597,
    63.68143,
    11.15022,
    36,
    32.88
  ],
  [
    4599,
    63.68177,
    11.1513,
    35.7,
    33.04
  ],
  [
    4601,
    63.68212,
    11.15238,
    35.4,
    33.07
  ],
  [
    4603,
    63.68249,
    11.15344,
    35.3,
    33.03
  ],
  [
    4605,
    63.68286,
    11.15448,
    35.3,
    32.98
  ],
  [
    4607,
    63.68323,
    11.15551,
    35.3,
    32.91
  ],
  [
    4609,
    63.68361,
    11.15652,
    35.3,
    32.84
  ],
  [
    4611,
    63.684,
    11.15752,
    35.3,
    32.76
  ],
  [
    4613,
    63.6844,
    11.1585,
    35.2,
    32.72
  ],
  [
    4615,
    63.6848,
    11.15946,
    35.1,
    32.58
  ],
  [
    4617,
    63.68521,
    11.1604,
    34.9,
    32.29
  ],
  [
    4619,
    63.68561,
    11.16131,
    34.7,
    31.96
  ],
  [
    4621,
    63.68603,
    11.16221,
    34.5,
    31.63
  ],
  [
    4623,
    63.68644,
    11.16307,
    34.4,
    31.32
  ],
  [
    4625,
    63.68685,
    11.16392,
    33.9,
    31.14
  ],
  [
    4627,
    63.68727,
    11.16475,
    33.4,
    31.05
  ],
  [
    4629,
    63.68769,
    11.16557,
    32.7,
    31.14
  ],
  [
    4631,
    63.68811,
    11.16642,
    32.1,
    31.26
  ],
  [
    4633,
    63.6885,
    11.16734,
    31.3,
    31.38
  ],
  [
    4635,
    63.68884,
    11.16835,
    30.4,
    31.52
  ],
  [
    4637,
    63.68913,
    11.16944,
    29.5,
    31.66
  ],
  [
    4639,
    63.68938,
    11.1706,
    28.5,
    31.84
  ],
  [
    4641,
    63.6896,
    11.17179,
    27.6,
    32
  ],
  [
    4643,
    63.68981,
    11.17299,
    26.6,
    32.12
  ],
  [
    4645,
    63.69003,
    11.17419,
    25.7,
    32.17
  ],
  [
    4647,
    63.69025,
    11.1754,
    24.9,
    32.21
  ],
  [
    4649,
    63.69046,
    11.17661,
    24,
    32.23
  ],
  [
    4651,
    63.69068,
    11.17782,
    23.5,
    32.21
  ],
  [
    4653,
    63.6909,
    11.17902,
    23.4,
    32.12
  ],
  [
    4655,
    63.69111,
    11.18022,
    23.8,
    31.98
  ],
  [
    4657,
    63.69133,
    11.18142,
    24.8,
    31.75
  ],
  [
    4659,
    63.69154,
    11.1826,
    26,
    31.43
  ],
  [
    4661,
    63.69177,
    11.18375,
    26.9,
    31.01
  ],
  [
    4663,
    63.69205,
    11.18482,
    28,
    30.61
  ],
  [
    4665,
    63.69239,
    11.18579,
    29,
    30.3
  ],
  [
    4667,
    63.69277,
    11.18666,
    30,
    30.03
  ],
  [
    4669,
    63.69319,
    11.18741,
    30.4,
    29.77
  ],
  [
    4671,
    63.69364,
    11.18803,
    30.4,
    29.54
  ],
  [
    4673,
    63.69412,
    11.18853,
    33,
    29.31
  ],
  [
    4675,
    63.69462,
    11.1889,
    34.1,
    29.13
  ],
  [
    4677,
    63.69513,
    11.18912,
    35.2,
    28.99
  ],
  [
    4679,
    63.69565,
    11.18921,
    36.2,
    28.87
  ],
  [
    4682,
    63.69642,
    11.18912,
    37.7,
    28.78
  ],
  [
    4684,
    63.69694,
    11.189,
    38.7,
    28.74
  ],
  [
    4686,
    63.69745,
    11.18889,
    39.7,
    28.69
  ],
  [
    4688,
    63.69797,
    11.18885,
    40.9,
    28.65
  ],
  [
    4690,
    63.69848,
    11.1889,
    41.9,
    28.65
  ],
  [
    4692,
    63.69899,
    11.18907,
    42.9,
    28.65
  ],
  [
    4694,
    63.69948,
    11.18935,
    44.1,
    28.46
  ],
  [
    4696,
    63.69997,
    11.18972,
    45,
    28.46
  ],
  [
    4698,
    63.70044,
    11.19014,
    45.8,
    28.23
  ],
  [
    4700,
    63.70091,
    11.19056,
    46.6,
    28.23
  ],
  [
    4702,
    63.70137,
    11.19099,
    47.6,
    28.13
  ],
  [
    4704,
    63.70184,
    11.19142,
    48.6,
    28.06
  ],
  [
    4706,
    63.70231,
    11.19184,
    49.6,
    27.99
  ],
  [
    4708,
    63.70277,
    11.19226,
    50.5,
    27.93
  ],
  [
    4710,
    63.70324,
    11.19269,
    51.3,
    27.89
  ],
  [
    4712,
    63.7037,
    11.19311,
    51.6,
    27.9
  ],
  [
    4714,
    63.70417,
    11.19354,
    51.7,
    28.06
  ],
  [
    4716,
    63.70463,
    11.19397,
    51.7,
    28.28
  ],
  [
    4718,
    63.70511,
    11.1944,
    51.6,
    28.53
  ],
  [
    4720,
    63.70558,
    11.19483,
    51.6,
    28.77
  ],
  [
    4722,
    63.70606,
    11.19527,
    51.6,
    28.91
  ],
  [
    4724,
    63.70655,
    11.19572,
    51.5,
    29.18
  ],
  [
    4726,
    63.70703,
    11.19616,
    51.5,
    29.17
  ],
  [
    4728,
    63.70752,
    11.19661,
    51.3,
    29.09
  ],
  [
    4730,
    63.708,
    11.19705,
    51.1,
    29.05
  ],
  [
    4732,
    63.70848,
    11.19749,
    50.9,
    29.02
  ],
  [
    4734,
    63.70897,
    11.19793,
    50.7,
    29.01
  ],
  [
    4736,
    63.70946,
    11.19834,
    50.5,
    28.99
  ],
  [
    4738,
    63.70995,
    11.19871,
    50.3,
    28.47
  ],
  [
    4740,
    63.71043,
    11.19903,
    50.1,
    27.9
  ],
  [
    4742,
    63.71091,
    11.1993,
    49.9,
    27.56
  ],
  [
    4744,
    63.71138,
    11.19953,
    49.8,
    26.42
  ],
  [
    4746,
    63.71184,
    11.19974,
    49.6,
    25.4
  ],
  [
    4748,
    63.71227,
    11.19995,
    49.5,
    24.42
  ],
  [
    4750,
    63.71268,
    11.20025,
    49.5,
    23.68
  ],
  [
    4752,
    63.71306,
    11.20066,
    49.4,
    23.46
  ],
  [
    4754,
    63.7134,
    11.20119,
    49.3,
    23.12
  ],
  [
    4756,
    63.7137,
    11.20183,
    49.2,
    22.98
  ],
  [
    4758,
    63.71396,
    11.20255,
    49.3,
    22.96
  ],
  [
    4760,
    63.71417,
    11.20335,
    49.2,
    23.06
  ],
  [
    4762,
    63.71433,
    11.20421,
    49.1,
    23.02
  ],
  [
    4764,
    63.71444,
    11.20511,
    49.2,
    23.12
  ],
  [
    4766,
    63.71454,
    11.20602,
    49.3,
    23.19
  ],
  [
    4768,
    63.71467,
    11.20692,
    49.4,
    23.26
  ],
  [
    4770,
    63.71486,
    11.20776,
    49.5,
    23.3
  ],
  [
    4772,
    63.7151,
    11.20853,
    49.5,
    23.34
  ],
  [
    4774,
    63.71536,
    11.20927,
    49.6,
    23.39
  ],
  [
    4776,
    63.7156,
    11.21004,
    49.7,
    23.45
  ],
  [
    4778,
    63.71581,
    11.21087,
    49.6,
    23.46
  ],
  [
    4780,
    63.71596,
    11.21175,
    49.7,
    23.56
  ],
  [
    4782,
    63.71607,
    11.21268,
    49.7,
    23.59
  ],
  [
    4784,
    63.71614,
    11.21362,
    49.7,
    23.67
  ],
  [
    4786,
    63.71621,
    11.21457,
    49.7,
    23.79
  ],
  [
    4788,
    63.71627,
    11.21553,
    49.7,
    23.96
  ],
  [
    4790,
    63.71632,
    11.2165,
    49.7,
    24.14
  ],
  [
    4792,
    63.71637,
    11.21747,
    49.8,
    24.24
  ],
  [
    4794,
    63.71642,
    11.21846,
    49.8,
    24.57
  ],
  [
    4796,
    63.71646,
    11.21946,
    49.9,
    24.85
  ],
  [
    4798,
    63.71649,
    11.22047,
    50.4,
    25.18
  ],
  [
    4800,
    63.71652,
    11.22149,
    50.6,
    25.46
  ],
  [
    4802,
    63.71655,
    11.22253,
    50.6,
    25.61
  ],
  [
    4804,
    63.71657,
    11.22358,
    50.6,
    26.06
  ],
  [
    4806,
    63.71658,
    11.22464,
    50.6,
    26.34
  ],
  [
    4808,
    63.71659,
    11.22572,
    50.6,
    26.63
  ],
  [
    4810,
    63.71659,
    11.2268,
    50.5,
    26.92
  ],
  [
    4812,
    63.71659,
    11.2279,
    50.5,
    27.07
  ],
  [
    4814,
    63.71658,
    11.22901,
    50.6,
    27.49
  ],
  [
    4816,
    63.71656,
    11.23013,
    50.7,
    27.74
  ],
  [
    4818,
    63.71653,
    11.23125,
    50.8,
    27.83
  ],
  [
    4820,
    63.71648,
    11.23238,
    50.9,
    27.81
  ],
  [
    4822,
    63.71643,
    11.2335,
    51,
    27.71
  ],
  [
    4824,
    63.71637,
    11.23461,
    51.1,
    27.6
  ],
  [
    4826,
    63.71629,
    11.23571,
    51.2,
    27.51
  ],
  [
    4828,
    63.71621,
    11.2368,
    51.3,
    27.42
  ],
  [
    4830,
    63.71611,
    11.23789,
    51.4,
    27.33
  ],
  [
    4832,
    63.716,
    11.23897,
    51.3,
    27.24
  ],
  [
    4834,
    63.71589,
    11.24002,
    51.1,
    26.69
  ],
  [
    4836,
    63.71576,
    11.24104,
    51,
    26.16
  ],
  [
    4838,
    63.71563,
    11.24204,
    50.8,
    25.7
  ],
  [
    4840,
    63.71551,
    11.24303,
    50.6,
    25.32
  ],
  [
    4842,
    63.71538,
    11.24401,
    50.3,
    25.18
  ],
  [
    4844,
    63.71529,
    11.245,
    49.9,
    25.15
  ],
  [
    4846,
    63.71526,
    11.24602,
    49.5,
    25.17
  ],
  [
    4848,
    63.7153,
    11.24704,
    49,
    25.25
  ],
  [
    4850,
    63.7154,
    11.24803,
    48.5,
    25.38
  ],
  [
    4852,
    63.71557,
    11.249,
    48,
    25.58
  ],
  [
    4854,
    63.71579,
    11.24991,
    47.4,
    25.76
  ],
  [
    4856,
    63.71606,
    11.25077,
    46.8,
    25.96
  ],
  [
    4858,
    63.71636,
    11.25158,
    46.2,
    26.15
  ],
  [
    4860,
    63.71669,
    11.25234,
    45.6,
    26.3
  ],
  [
    4862,
    63.71705,
    11.25303,
    44.9,
    26.38
  ],
  [
    4864,
    63.71744,
    11.25365,
    44.3,
    26.5
  ],
  [
    4866,
    63.71786,
    11.25419,
    43.5,
    26.61
  ],
  [
    4868,
    63.71829,
    11.25464,
    42.8,
    26.75
  ],
  [
    4870,
    63.71875,
    11.25501,
    42.1,
    26.88
  ],
  [
    4872,
    63.71922,
    11.2553,
    41.3,
    27.04
  ],
  [
    4874,
    63.7197,
    11.25554,
    40.7,
    27.19
  ],
  [
    4876,
    63.72018,
    11.25577,
    40,
    27.34
  ],
  [
    4878,
    63.72066,
    11.25601,
    39.4,
    27.49
  ],
  [
    4880,
    63.72115,
    11.25625,
    38.8,
    27.62
  ],
  [
    4882,
    63.72163,
    11.25654,
    38.2,
    27.7
  ],
  [
    4884,
    63.7221,
    11.25694,
    37.5,
    27.87
  ],
  [
    4886,
    63.72255,
    11.25745,
    36.6,
    27.87
  ],
  [
    4888,
    63.72298,
    11.25808,
    35.9,
    28.55
  ],
  [
    4890,
    63.72338,
    11.25878,
    35.3,
    28.55
  ],
  [
    4892,
    63.72376,
    11.25953,
    34.6,
    28.55
  ],
  [
    4894,
    63.72413,
    11.26034,
    34,
    28.48
  ],
  [
    4896,
    63.72447,
    11.2612,
    33.4,
    28.48
  ],
  [
    4898,
    63.7248,
    11.2621,
    32.8,
    28.72
  ],
  [
    4900,
    63.7251,
    11.26305,
    32.2,
    28.72
  ],
  [
    4902,
    63.72538,
    11.26404,
    31.4,
    28.72
  ],
  [
    4904,
    63.72563,
    11.26508,
    30.5,
    29.03
  ],
  [
    4906,
    63.72586,
    11.26615,
    29.7,
    29.03
  ],
  [
    4908,
    63.72606,
    11.26725,
    28.8,
    29.48
  ],
  [
    4910,
    63.72624,
    11.26838,
    27.9,
    29.48
  ],
  [
    4912,
    63.72642,
    11.26951,
    27,
    29.48
  ],
  [
    4914,
    63.72661,
    11.27062,
    26.2,
    29.41
  ],
  [
    4916,
    63.7268,
    11.2717,
    25.5,
    29.41
  ],
  [
    4918,
    63.72701,
    11.27274,
    24.7,
    28.29
  ],
  [
    4920,
    63.72723,
    11.27374,
    23.9,
    28.29
  ],
  [
    4922,
    63.72746,
    11.27469,
    23.2,
    28.29
  ],
  [
    4924,
    63.7277,
    11.2756,
    22.7,
    26.41
  ],
  [
    4926,
    63.72795,
    11.27644,
    22.3,
    26.41
  ],
  [
    4928,
    63.72824,
    11.27722,
    22.1,
    24.99
  ],
  [
    4930,
    63.72854,
    11.27793,
    21.8,
    24.99
  ],
  [
    4932,
    63.72887,
    11.27857,
    21.5,
    24.99
  ],
  [
    4934,
    63.7292,
    11.27914,
    21.2,
    23.74
  ],
  [
    4936,
    63.72954,
    11.27968,
    21,
    23.74
  ],
  [
    4938,
    63.72985,
    11.28025,
    20.8,
    22.53
  ],
  [
    4940,
    63.73012,
    11.28091,
    20.5,
    22.53
  ],
  [
    4942,
    63.73035,
    11.28164,
    20,
    22.53
  ],
  [
    4944,
    63.73056,
    11.28241,
    19.4,
    22.34
  ],
  [
    4946,
    63.73075,
    11.28321,
    19,
    22.53
  ],
  [
    4948,
    63.73093,
    11.28404,
    18.5,
    22.75
  ],
  [
    4950,
    63.7311,
    11.28488,
    18.2,
    22.94
  ],
  [
    4952,
    63.73126,
    11.28574,
    17.8,
    23.08
  ],
  [
    4954,
    63.73144,
    11.28659,
    17.5,
    23.18
  ],
  [
    4956,
    63.73165,
    11.28739,
    17.1,
    23.26
  ],
  [
    4958,
    63.73191,
    11.28813,
    16.4,
    23.37
  ],
  [
    4960,
    63.73222,
    11.28878,
    15.8,
    23.48
  ],
  [
    4962,
    63.73256,
    11.28934,
    15.1,
    23.64
  ],
  [
    4964,
    63.73294,
    11.2898,
    14.6,
    23.69
  ],
  [
    4966,
    63.73335,
    11.29014,
    14.7,
    24.19
  ],
  [
    4968,
    63.73377,
    11.29033,
    14.1,
    24.14
  ],
  [
    4970,
    63.73421,
    11.29041,
    13.5,
    24.26
  ],
  [
    4972,
    63.73465,
    11.29042,
    12.9,
    24.41
  ],
  [
    4973,
    63.73487,
    11.29042,
    12.6,
    24.56
  ],
  [
    4975,
    63.73531,
    11.29042,
    11.9,
    24.78
  ],
  [
    4977,
    63.73576,
    11.29042,
    11.4,
    24.9
  ],
  [
    4979,
    63.73621,
    11.29041,
    10.9,
    25.11
  ],
  [
    4981,
    63.73666,
    11.29041,
    10.3,
    24.76
  ],
  [
    4983,
    63.7371,
    11.29041,
    9.6,
    24.44
  ],
  [
    4985,
    63.73754,
    11.29041,
    9,
    24.09
  ],
  [
    4987,
    63.73797,
    11.29043,
    8.3,
    23.72
  ],
  [
    4989,
    63.73839,
    11.29049,
    7.6,
    23.33
  ],
  [
    4991,
    63.7388,
    11.29061,
    6.9,
    22.92
  ],
  [
    4993,
    63.7392,
    11.2908,
    6.2,
    22.54
  ],
  [
    4995,
    63.73959,
    11.29106,
    5.7,
    22.38
  ],
  [
    4997,
    63.73997,
    11.29136,
    5.2,
    22.4
  ],
  [
    4999,
    63.74033,
    11.29173,
    4.9,
    22.43
  ],
  [
    5001,
    63.74069,
    11.29215,
    4.8,
    22.48
  ],
  [
    5003,
    63.74103,
    11.29263,
    4.8,
    22.35
  ],
  [
    5005,
    63.74135,
    11.29316,
    4.8,
    22.11
  ],
  [
    5007,
    63.74166,
    11.29373,
    4.6,
    21.67
  ],
  [
    5009,
    63.74193,
    11.29432,
    4.6,
    21.17
  ],
  [
    5011,
    63.7422,
    11.29492,
    4.6,
    20.65
  ],
  [
    5013,
    63.74246,
    11.2955,
    4.6,
    20.11
  ],
  [
    5015,
    63.74271,
    11.29607,
    4.8,
    19.63
  ],
  [
    5017,
    63.74295,
    11.29662,
    4.8,
    19.13
  ],
  [
    5019,
    63.74319,
    11.29716,
    4.7,
    18.63
  ],
  [
    5021,
    63.74342,
    11.29769,
    4.7,
    18.12
  ],
  [
    5023,
    63.74365,
    11.2982,
    4.7,
    17.32
  ],
  [
    5025,
    63.74387,
    11.29869,
    4.6,
    17.08
  ],
  [
    5027,
    63.74408,
    11.29917,
    4.6,
    16.15
  ],
  [
    5029,
    63.74427,
    11.29961,
    4.6,
    15.07
  ],
  [
    5031,
    63.74446,
    11.30003,
    4.6,
    14.01
  ],
  [
    5033,
    63.74463,
    11.30041,
    4.6,
    13.02
  ],
  [
    5035,
    63.74478,
    11.30077,
    4.6,
    12.08
  ],
  [
    5037,
    63.74493,
    11.3011,
    4.5,
    11.18
  ],
  [
    5039,
    63.74507,
    11.3014,
    4.5,
    10.37
  ],
  [
    5041,
    63.74519,
    11.30168,
    4.4,
    9.44
  ],
  [
    5043,
    63.7453,
    11.30194,
    4.4,
    8.44
  ],
  [
    5045,
    63.7454,
    11.30216,
    4.5,
    7.38
  ],
  [
    5047,
    63.74548,
    11.30235,
    4.5,
    6.34
  ],
  [
    5049,
    63.74556,
    11.30252,
    4.5,
    5.44
  ],
  [
    5051,
    63.74562,
    11.30266,
    4.6,
    4.56
  ],
  [
    5053,
    63.74567,
    11.30277,
    4.7,
    3.85
  ],
  [
    5055,
    63.74571,
    11.30287,
    4.7,
    3.13
  ],
  [
    5057,
    63.74575,
    11.30295,
    4.8,
    2.47
  ],
  [
    5059,
    63.74577,
    11.303,
    4.8,
    1.8
  ],
  [
    5061,
    63.74579,
    11.30304,
    4.8,
    1.06
  ],
  [
    5063,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5065,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5067,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5069,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5071,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5073,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5075,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5077,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5079,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5081,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5083,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5085,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5087,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5089,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5091,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5093,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5095,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5097,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5099,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5101,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5103,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5105,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5107,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5109,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5111,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5113,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5115,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5117,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5119,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5121,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5123,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5125,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5127,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5129,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5131,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5133,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5135,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5137,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5139,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5141,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5143,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5145,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5147,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5149,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5151,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5153,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5155,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5157,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5159,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5161,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5163,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5165,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5167,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5169,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5171,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5173,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5175,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5177,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5179,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5181,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5183,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5185,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5187,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5189,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5191,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5193,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5195,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5197,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5199,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5201,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5203,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5205,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5207,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5209,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5211,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5213,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5215,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5217,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5219,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5221,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5223,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5225,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5227,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5229,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5231,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5233,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5235,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5237,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5239,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5241,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5243,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5245,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5247,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5249,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5251,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5253,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5255,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5257,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5259,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5261,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5263,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5265,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5267,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5269,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5271,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5273,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5275,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5277,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5279,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5281,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5283,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5285,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5287,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5289,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5291,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5293,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5295,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5297,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5299,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5301,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5303,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5305,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5307,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5309,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5311,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5313,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5315,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5317,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5319,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5321,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5323,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5325,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5327,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5329,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5331,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5333,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5335,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5337,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5339,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5341,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5343,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5345,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5347,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5349,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5351,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5353,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5355,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5357,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5359,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5361,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5363,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5365,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5367,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5369,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5371,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5373,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5375,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5377,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5379,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5381,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5383,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5385,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5387,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5389,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5391,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5393,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5394,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5396,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5398,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5400,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5402,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5404,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5406,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5408,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5410,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5412,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5414,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5416,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5418,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5420,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5422,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5424,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5426,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5428,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5430,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5432,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5434,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5436,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5438,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5440,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5442,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5444,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5446,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5448,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5450,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5452,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5454,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5456,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5458,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5460,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5462,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5464,
    63.74579,
    11.30305,
    4.8,
    0
  ],
  [
    5466,
    63.74576,
    11.30301,
    3.7,
    1.65
  ],
  [
    5468,
    63.74579,
    11.30307,
    3.6,
    2.72
  ],
  [
    5470,
    63.74583,
    11.30317,
    3.6,
    3.78
  ],
  [
    5472,
    63.74588,
    11.30329,
    3.6,
    4.66
  ],
  [
    5474,
    63.74594,
    11.30343,
    3.6,
    5.32
  ],
  [
    5476,
    63.74602,
    11.30359,
    3.5,
    5.95
  ],
  [
    5478,
    63.7461,
    11.30377,
    3.5,
    6.52
  ],
  [
    5480,
    63.74618,
    11.30396,
    3.5,
    7.08
  ],
  [
    5482,
    63.74628,
    11.30417,
    3.5,
    7.62
  ],
  [
    5484,
    63.74638,
    11.3044,
    3.5,
    8.14
  ],
  [
    5486,
    63.74648,
    11.30464,
    3.6,
    8.66
  ],
  [
    5488,
    63.7466,
    11.3049,
    3.7,
    9.17
  ],
  [
    5490,
    63.74672,
    11.30517,
    3.8,
    9.74
  ],
  [
    5492,
    63.74685,
    11.30546,
    4,
    10.4
  ],
  [
    5494,
    63.74698,
    11.30577,
    4.1,
    11.08
  ],
  [
    5496,
    63.74713,
    11.30609,
    4.1,
    11.73
  ],
  [
    5498,
    63.74728,
    11.30644,
    4.1,
    12.37
  ],
  [
    5500,
    63.74745,
    11.3068,
    4.2,
    13.05
  ],
  [
    5502,
    63.74762,
    11.30719,
    4.2,
    13.72
  ],
  [
    5504,
    63.7478,
    11.30759,
    4.2,
    14.35
  ],
  [
    5506,
    63.74798,
    11.30801,
    4.2,
    14.99
  ],
  [
    5508,
    63.74818,
    11.30845,
    4.2,
    15.59
  ],
  [
    5510,
    63.74838,
    11.3089,
    4.2,
    16.14
  ],
  [
    5512,
    63.74859,
    11.30937,
    4.4,
    16.22
  ],
  [
    5514,
    63.74881,
    11.30985,
    5.2,
    17.11
  ],
  [
    5516,
    63.74903,
    11.31035,
    5.8,
    17.49
  ],
  [
    5518,
    63.74925,
    11.31085,
    6.4,
    17.8
  ],
  [
    5520,
    63.74948,
    11.31137,
    7.1,
    18.02
  ],
  [
    5522,
    63.74971,
    11.31188,
    7.8,
    18.2
  ],
  [
    5524,
    63.74995,
    11.31241,
    8.5,
    18.32
  ],
  [
    5526,
    63.75018,
    11.31293,
    9.2,
    18.45
  ],
  [
    5528,
    63.75042,
    11.31346,
    9.9,
    18.57
  ],
  [
    5530,
    63.75066,
    11.314,
    10.6,
    18.68
  ],
  [
    5532,
    63.7509,
    11.31453,
    11.3,
    18.79
  ],
  [
    5534,
    63.75114,
    11.31507,
    12,
    18.9
  ],
  [
    5536,
    63.75138,
    11.31561,
    12.7,
    19.01
  ],
  [
    5538,
    63.75162,
    11.31616,
    13.4,
    19.13
  ],
  [
    5540,
    63.75187,
    11.31671,
    14.1,
    19.23
  ],
  [
    5542,
    63.75211,
    11.31726,
    14.8,
    19.34
  ],
  [
    5544,
    63.75236,
    11.31781,
    15.5,
    19.44
  ],
  [
    5546,
    63.75261,
    11.31837,
    16.2,
    19.54
  ],
  [
    5548,
    63.75285,
    11.31894,
    16.9,
    19.64
  ],
  [
    5550,
    63.75309,
    11.31953,
    17.6,
    19.72
  ],
  [
    5552,
    63.75332,
    11.32014,
    18.3,
    19.82
  ],
  [
    5554,
    63.75354,
    11.32078,
    19,
    19.89
  ],
  [
    5556,
    63.75375,
    11.32143,
    19.7,
    20
  ],
  [
    5558,
    63.75395,
    11.32211,
    20.6,
    20.12
  ],
  [
    5560,
    63.75414,
    11.32281,
    21.3,
    20.17
  ],
  [
    5562,
    63.75432,
    11.32352,
    22,
    20.23
  ],
  [
    5564,
    63.75448,
    11.32426,
    22.8,
    20.35
  ],
  [
    5566,
    63.75464,
    11.325,
    23.5,
    20.43
  ],
  [
    5568,
    63.7548,
    11.32576,
    24.2,
    20.52
  ],
  [
    5570,
    63.75494,
    11.32652,
    24.9,
    20.6
  ],
  [
    5572,
    63.75508,
    11.3273,
    25.6,
    20.7
  ],
  [
    5574,
    63.75522,
    11.32809,
    26.3,
    20.78
  ],
  [
    5576,
    63.75534,
    11.32888,
    27,
    20.88
  ],
  [
    5578,
    63.75546,
    11.32969,
    27.6,
    20.96
  ],
  [
    5580,
    63.75558,
    11.3305,
    28.4,
    21.03
  ],
  [
    5582,
    63.75568,
    11.33132,
    29.1,
    21.12
  ],
  [
    5584,
    63.75578,
    11.33215,
    29.9,
    21.24
  ],
  [
    5586,
    63.75587,
    11.33299,
    30.6,
    21.28
  ],
  [
    5588,
    63.75596,
    11.33383,
    31.4,
    21.36
  ],
  [
    5590,
    63.75603,
    11.33468,
    32.2,
    21.44
  ],
  [
    5592,
    63.7561,
    11.33554,
    33,
    21.53
  ],
  [
    5594,
    63.75616,
    11.3364,
    33.8,
    21.62
  ],
  [
    5596,
    63.75621,
    11.33728,
    34.5,
    21.7
  ],
  [
    5598,
    63.75626,
    11.33815,
    35.2,
    21.78
  ],
  [
    5600,
    63.75629,
    11.33903,
    35.9,
    21.84
  ],
  [
    5602,
    63.75632,
    11.33992,
    36.7,
    21.88
  ],
  [
    5604,
    63.75634,
    11.34081,
    37.6,
    21.92
  ],
  [
    5606,
    63.75636,
    11.3417,
    38.5,
    21.94
  ],
  [
    5608,
    63.75638,
    11.34258,
    39.4,
    21.95
  ],
  [
    5610,
    63.75639,
    11.34348,
    40.2,
    21.96
  ],
  [
    5612,
    63.75639,
    11.34437,
    41,
    21.98
  ],
  [
    5614,
    63.75639,
    11.34526,
    41.8,
    22.01
  ],
  [
    5616,
    63.75639,
    11.34615,
    42.5,
    22.04
  ],
  [
    5618,
    63.75638,
    11.34705,
    43.1,
    22.1
  ],
  [
    5620,
    63.75637,
    11.34795,
    43.8,
    22.17
  ],
  [
    5622,
    63.75635,
    11.34884,
    44.5,
    22.24
  ],
  [
    5624,
    63.75633,
    11.34975,
    45.2,
    22.31
  ],
  [
    5626,
    63.75631,
    11.35065,
    45.9,
    22.38
  ],
  [
    5628,
    63.75628,
    11.35156,
    46.6,
    22.43
  ],
  [
    5630,
    63.75624,
    11.35247,
    47.3,
    22.49
  ],
  [
    5632,
    63.75621,
    11.35337,
    47.8,
    22.59
  ],
  [
    5634,
    63.75616,
    11.35429,
    48.2,
    22.72
  ],
  [
    5636,
    63.75611,
    11.35521,
    48.6,
    22.9
  ],
  [
    5638,
    63.75606,
    11.35613,
    49,
    23.11
  ],
  [
    5640,
    63.756,
    11.35706,
    49.4,
    23.33
  ],
  [
    5642,
    63.75594,
    11.358,
    49.7,
    23.44
  ],
  [
    5644,
    63.75587,
    11.35895,
    49.9,
    23.78
  ],
  [
    5646,
    63.75579,
    11.3599,
    50.2,
    24
  ],
  [
    5648,
    63.75571,
    11.36086,
    50.5,
    24.23
  ],
  [
    5650,
    63.75562,
    11.36183,
    50.8,
    24.47
  ],
  [
    5652,
    63.75553,
    11.3628,
    51.1,
    24.55
  ],
  [
    5654,
    63.75543,
    11.36378,
    51.3,
    24.89
  ],
  [
    5656,
    63.75532,
    11.36477,
    51.7,
    25.11
  ],
  [
    5658,
    63.75521,
    11.36576,
    52.1,
    25.32
  ],
  [
    5660,
    63.75509,
    11.36675,
    52.5,
    25.52
  ],
  [
    5662,
    63.75497,
    11.36775,
    53,
    25.61
  ],
  [
    5664,
    63.75485,
    11.36876,
    53.3,
    25.87
  ],
  [
    5666,
    63.75472,
    11.36978,
    53.8,
    26.05
  ],
  [
    5668,
    63.7546,
    11.3708,
    54.5,
    26.18
  ],
  [
    5670,
    63.75449,
    11.37183,
    55.2,
    26.25
  ],
  [
    5672,
    63.7544,
    11.37288,
    56,
    26.26
  ],
  [
    5674,
    63.75435,
    11.37394,
    56.7,
    26.31
  ],
  [
    5676,
    63.75435,
    11.375,
    57.4,
    26.35
  ],
  [
    5678,
    63.75438,
    11.37607,
    58.1,
    26.39
  ],
  [
    5680,
    63.75446,
    11.37713,
    58.9,
    26.41
  ],
  [
    5682,
    63.75457,
    11.37817,
    59.6,
    26.43
  ],
  [
    5684,
    63.75471,
    11.37919,
    60.2,
    26.46
  ],
  [
    5686,
    63.75486,
    11.3802,
    60.9,
    26.2
  ],
  [
    5688,
    63.75503,
    11.38119,
    61.2,
    25.94
  ],
  [
    5690,
    63.75522,
    11.38215,
    61.3,
    25.73
  ],
  [
    5692,
    63.75542,
    11.38309,
    61.2,
    25.68
  ],
  [
    5694,
    63.75563,
    11.38401,
    61.2,
    25.52
  ],
  [
    5696,
    63.75585,
    11.38491,
    61.1,
    25.47
  ],
  [
    5698,
    63.7561,
    11.38579,
    61,
    25.44
  ],
  [
    5700,
    63.75635,
    11.38664,
    61.1,
    25.37
  ],
  [
    5702,
    63.75662,
    11.38747,
    60.9,
    25.4
  ],
  [
    5704,
    63.7569,
    11.38828,
    60.4,
    25.38
  ],
  [
    5706,
    63.75719,
    11.38908,
    59.6,
    25.5
  ],
  [
    5708,
    63.75749,
    11.38987,
    58.7,
    25.72
  ],
  [
    5710,
    63.75778,
    11.39068,
    57.7,
    25.67
  ],
  [
    5712,
    63.75806,
    11.39151,
    56.7,
    25.49
  ],
  [
    5714,
    63.75829,
    11.39239,
    55.7,
    25.16
  ],
  [
    5716,
    63.75846,
    11.39333,
    54.9,
    24.98
  ],
  [
    5718,
    63.75858,
    11.3943,
    54,
    25.04
  ],
  [
    5720,
    63.75868,
    11.39529,
    53.1,
    25.19
  ],
  [
    5722,
    63.75877,
    11.3963,
    52.2,
    25.32
  ],
  [
    5724,
    63.75889,
    11.39729,
    51.2,
    25.57
  ],
  [
    5726,
    63.75904,
    11.39827,
    50.2,
    25.58
  ],
  [
    5728,
    63.75922,
    11.39922,
    49.2,
    25.54
  ],
  [
    5730,
    63.75942,
    11.40015,
    48.3,
    25.51
  ],
  [
    5732,
    63.75963,
    11.40107,
    47.3,
    25.51
  ],
  [
    5734,
    63.75982,
    11.40201,
    46.3,
    25.47
  ],
  [
    5736,
    63.76,
    11.40296,
    45.5,
    25.44
  ],
  [
    5738,
    63.76016,
    11.40393,
    44.4,
    25.4
  ],
  [
    5740,
    63.76031,
    11.4049,
    43.5,
    25.38
  ],
  [
    5742,
    63.76045,
    11.40587,
    42.6,
    25.36
  ],
  [
    5744,
    63.76059,
    11.40684,
    41.7,
    24.91
  ],
  [
    5746,
    63.76072,
    11.40779,
    40.8,
    24.29
  ],
  [
    5748,
    63.76084,
    11.40872,
    39.9,
    23.62
  ],
  [
    5750,
    63.76095,
    11.40963,
    39.1,
    22.91
  ],
  [
    5752,
    63.76104,
    11.41052,
    38.4,
    22.53
  ],
  [
    5754,
    63.7611,
    11.41139,
    37.7,
    21.62
  ],
  [
    5756,
    63.76112,
    11.41225,
    37.1,
    21.17
  ],
  [
    5758,
    63.76113,
    11.4131,
    36.6,
    20.71
  ],
  [
    5760,
    63.76111,
    11.41393,
    36,
    20.2
  ],
  [
    5762,
    63.76108,
    11.41473,
    35.5,
    19.95
  ],
  [
    5764,
    63.76107,
    11.41553,
    34.8,
    19.68
  ],
  [
    5766,
    63.7611,
    11.41632,
    34.1,
    19.83
  ],
  [
    5768,
    63.76117,
    11.41711,
    33.5,
    19.95
  ],
  [
    5770,
    63.76129,
    11.41787,
    32.8,
    20.17
  ],
  [
    5772,
    63.76146,
    11.4186,
    32.1,
    20.41
  ],
  [
    5774,
    63.76168,
    11.41928,
    31.5,
    20.64
  ],
  [
    5776,
    63.76193,
    11.4199,
    31,
    20.86
  ],
  [
    5778,
    63.76221,
    11.42048,
    30.4,
    21.11
  ],
  [
    5780,
    63.76247,
    11.4211,
    29.8,
    21.34
  ],
  [
    5782,
    63.7627,
    11.4218,
    29.1,
    21.6
  ],
  [
    5784,
    63.76287,
    11.42257,
    28.3,
    21.32
  ],
  [
    5786,
    63.76298,
    11.42338,
    27.5,
    20.97
  ],
  [
    5788,
    63.76303,
    11.4242,
    26.8,
    20.57
  ],
  [
    5790,
    63.76301,
    11.42502,
    26.2,
    20.16
  ],
  [
    5792,
    63.76294,
    11.4258,
    25.6,
    19.79
  ],
  [
    5794,
    63.76282,
    11.42655,
    25,
    19.74
  ],
  [
    5796,
    63.76264,
    11.42725,
    24.5,
    19.82
  ],
  [
    5798,
    63.76241,
    11.42788,
    23.9,
    19.96
  ],
  [
    5800,
    63.76214,
    11.42843,
    23.3,
    20.12
  ],
  [
    5802,
    63.76184,
    11.42891,
    22.6,
    20.34
  ],
  [
    5804,
    63.76153,
    11.42937,
    22.2,
    20.51
  ],
  [
    5806,
    63.76123,
    11.42986,
    21.8,
    20.63
  ],
  [
    5808,
    63.76095,
    11.43044,
    21.5,
    20.98
  ],
  [
    5810,
    63.76071,
    11.4311,
    20.9,
    20.98
  ],
  [
    5812,
    63.76051,
    11.43185,
    20.3,
    20.98
  ],
  [
    5814,
    63.76036,
    11.43265,
    19.8,
    21.57
  ],
  [
    5816,
    63.76026,
    11.43351,
    19.5,
    21.57
  ],
  [
    5818,
    63.76022,
    11.43439,
    19.4,
    21.92
  ],
  [
    5820,
    63.76023,
    11.43528,
    19.3,
    21.92
  ],
  [
    5822,
    63.76029,
    11.43616,
    19.3,
    21.92
  ],
  [
    5824,
    63.76036,
    11.43703,
    19.2,
    21.94
  ],
  [
    5826,
    63.76043,
    11.4379,
    19.2,
    21.94
  ],
  [
    5828,
    63.7605,
    11.43877,
    19.3,
    21.84
  ],
  [
    5830,
    63.76057,
    11.43964,
    19.2,
    21.84
  ],
  [
    5832,
    63.76064,
    11.44051,
    19.2,
    21.84
  ],
  [
    5834,
    63.76071,
    11.44138,
    19.3,
    21.74
  ],
  [
    5836,
    63.76078,
    11.44224,
    19.2,
    21.74
  ],
  [
    5838,
    63.76085,
    11.4431,
    19,
    21.62
  ],
  [
    5840,
    63.76092,
    11.44396,
    18.8,
    21.62
  ],
  [
    5842,
    63.76099,
    11.44482,
    18.3,
    21.62
  ],
  [
    5844,
    63.76106,
    11.44569,
    17.5,
    21.63
  ],
  [
    5846,
    63.76113,
    11.44656,
    16.7,
    21.63
  ],
  [
    5848,
    63.7612,
    11.44743,
    15.8,
    21.8
  ],
  [
    5850,
    63.76127,
    11.44828,
    15.1,
    21.8
  ],
  [
    5852,
    63.76134,
    11.44913,
    14.2,
    21.8
  ],
  [
    5854,
    63.76141,
    11.44998,
    13.5,
    21.26
  ],
  [
    5856,
    63.76147,
    11.45081,
    13,
    21.02
  ],
  [
    5858,
    63.76155,
    11.45164,
    13,
    20.97
  ],
  [
    5860,
    63.76167,
    11.45245,
    13.1,
    20.87
  ],
  [
    5862,
    63.76184,
    11.45321,
    -4.6,
    20.8
  ],
  [
    5864,
    63.76208,
    11.45388,
    -12.7,
    21.15
  ],
  [
    5866,
    63.7623,
    11.45455,
    -14.5,
    20.6
  ],
  [
    5868,
    63.76253,
    11.45522,
    -15.4,
    20.91
  ],
  [
    5870,
    63.76276,
    11.45589,
    -15.4,
    20.92
  ],
  [
    5872,
    63.76301,
    11.45655,
    -14.8,
    21.44
  ],
  [
    5874,
    63.76326,
    11.45722,
    -14.3,
    21.75
  ],
  [
    5875,
    63.76339,
    11.45756,
    -14.1,
    22.07
  ],
  [
    5877,
    63.76365,
    11.45824,
    -13.4,
    22.47
  ],
  [
    5879,
    63.76393,
    11.45892,
    -12.7,
    22.91
  ],
  [
    5881,
    63.76421,
    11.4596,
    -12.1,
    23.37
  ],
  [
    5883,
    63.7645,
    11.4603,
    -11.4,
    23.82
  ],
  [
    5885,
    63.7648,
    11.461,
    -10.8,
    24.3
  ],
  [
    5887,
    63.76511,
    11.46171,
    -10.3,
    24.77
  ],
  [
    5889,
    63.76543,
    11.46242,
    -9.7,
    25.19
  ],
  [
    5891,
    63.76576,
    11.46313,
    -9.1,
    25.6
  ],
  [
    5893,
    63.76609,
    11.46385,
    -8.5,
    25.98
  ],
  [
    5895,
    63.76644,
    11.46455,
    -7.8,
    26.29
  ],
  [
    5897,
    63.76681,
    11.46525,
    -7,
    26.61
  ],
  [
    5899,
    63.76718,
    11.46593,
    -6.3,
    26.85
  ],
  [
    5901,
    63.76756,
    11.4666,
    -5.6,
    27.09
  ],
  [
    5903,
    63.76796,
    11.46724,
    -4.8,
    27.32
  ],
  [
    5905,
    63.76837,
    11.46787,
    -4.1,
    27.54
  ],
  [
    5907,
    63.76878,
    11.46848,
    -3.3,
    27.74
  ],
  [
    5909,
    63.76921,
    11.46906,
    -2.5,
    27.94
  ],
  [
    5911,
    63.76965,
    11.46962,
    -1.7,
    28.13
  ],
  [
    5913,
    63.7701,
    11.47016,
    -1.2,
    28.32
  ],
  [
    5915,
    63.77056,
    11.47067,
    -0.8,
    28.53
  ],
  [
    5917,
    63.77102,
    11.47116,
    -0.3,
    28.78
  ],
  [
    5919,
    63.7715,
    11.47164,
    -0.1,
    29.03
  ],
  [
    5921,
    63.77198,
    11.47208,
    0,
    29.2
  ],
  [
    5923,
    63.77247,
    11.47251,
    0,
    29.28
  ],
  [
    5925,
    63.77297,
    11.4729,
    0.2,
    29.49
  ],
  [
    5927,
    63.77348,
    11.47327,
    0.4,
    29.51
  ],
  [
    5929,
    63.77399,
    11.4736,
    0.5,
    29.53
  ],
  [
    5931,
    63.7745,
    11.47392,
    0.5,
    29.58
  ],
  [
    5933,
    63.77502,
    11.4742,
    0.5,
    29.63
  ],
  [
    5935,
    63.77554,
    11.47446,
    0.5,
    29.67
  ],
  [
    5937,
    63.77607,
    11.47467,
    0.5,
    29.72
  ],
  [
    5939,
    63.7766,
    11.47483,
    0.5,
    29.77
  ],
  [
    5941,
    63.77713,
    11.47493,
    0.4,
    29.81
  ],
  [
    5943,
    63.77767,
    11.47497,
    0.5,
    29.82
  ],
  [
    5945,
    63.7782,
    11.47495,
    0.9,
    29.77
  ],
  [
    5947,
    63.77874,
    11.47487,
    1.4,
    29.69
  ],
  [
    5949,
    63.77927,
    11.47474,
    2.2,
    29.53
  ],
  [
    5951,
    63.7798,
    11.4746,
    3,
    29.15
  ],
  [
    5953,
    63.78031,
    11.47445,
    3.8,
    28.29
  ],
  [
    5955,
    63.78081,
    11.47431,
    4.3,
    27.37
  ],
  [
    5957,
    63.78129,
    11.47418,
    4.3,
    26.39
  ],
  [
    5959,
    63.78175,
    11.47405,
    5.7,
    25.28
  ],
  [
    5961,
    63.78218,
    11.47393,
    5.7,
    23.85
  ],
  [
    5963,
    63.78259,
    11.47382,
    5.7,
    22.2
  ],
  [
    5965,
    63.78297,
    11.47371,
    5.7,
    20.63
  ],
  [
    5967,
    63.78333,
    11.47361,
    6,
    19.39
  ],
  [
    5969,
    63.78367,
    11.47353,
    5.8,
    18.74
  ],
  [
    5971,
    63.784,
    11.47351,
    5.7,
    18.41
  ],
  [
    5973,
    63.78432,
    11.47359,
    5.6,
    18.19
  ],
  [
    5975,
    63.78463,
    11.47377,
    5.5,
    18.06
  ],
  [
    5977,
    63.78493,
    11.47406,
    5.5,
    18.01
  ],
  [
    5979,
    63.7852,
    11.47444,
    5.5,
    18.05
  ],
  [
    5981,
    63.78545,
    11.47491,
    5.5,
    18.06
  ],
  [
    5983,
    63.78567,
    11.47545,
    5.4,
    18.08
  ],
  [
    5985,
    63.78584,
    11.47607,
    5.4,
    18.11
  ],
  [
    5987,
    63.78599,
    11.47673,
    5.3,
    18.09
  ],
  [
    5989,
    63.78611,
    11.47741,
    5.3,
    18.07
  ],
  [
    5991,
    63.78624,
    11.47808,
    5.5,
    18.08
  ],
  [
    5993,
    63.78636,
    11.47876,
    5.6,
    18.08
  ],
  [
    5995,
    63.78649,
    11.47944,
    5.7,
    18.05
  ],
  [
    5997,
    63.78661,
    11.48012,
    5.7,
    18.01
  ],
  [
    5999,
    63.78673,
    11.48079,
    5.8,
    17.97
  ],
  [
    6001,
    63.78686,
    11.48146,
    5.9,
    17.93
  ],
  [
    6003,
    63.78699,
    11.48213,
    6,
    17.86
  ],
  [
    6005,
    63.78715,
    11.48275,
    6.2,
    17.77
  ],
  [
    6007,
    63.78734,
    11.48331,
    6.4,
    17.66
  ],
  [
    6009,
    63.78757,
    11.4838,
    6.6,
    17.53
  ],
  [
    6011,
    63.78783,
    11.48421,
    6.8,
    17.4
  ],
  [
    6013,
    63.7881,
    11.48453,
    7.1,
    17.24
  ],
  [
    6015,
    63.78839,
    11.48476,
    7.4,
    17.08
  ],
  [
    6017,
    63.78868,
    11.48489,
    7.7,
    16.46
  ],
  [
    6019,
    63.78897,
    11.48493,
    7.9,
    15.71
  ],
  [
    6021,
    63.78925,
    11.4849,
    8.1,
    14.94
  ],
  [
    6023,
    63.7895,
    11.48483,
    8.3,
    14.06
  ],
  [
    6025,
    63.78974,
    11.48476,
    8.6,
    13.3
  ],
  [
    6027,
    63.78997,
    11.48469,
    8.6,
    12.63
  ],
  [
    6029,
    63.79019,
    11.48463,
    8.6,
    12.1
  ],
  [
    6031,
    63.7904,
    11.48456,
    8.6,
    11.23
  ],
  [
    6033,
    63.79059,
    11.48451,
    8.8,
    10.65
  ],
  [
    6035,
    63.79078,
    11.48445,
    8.8,
    10.17
  ],
  [
    6037,
    63.79096,
    11.4844,
    9,
    9.9
  ],
  [
    6039,
    63.79113,
    11.48435,
    9.2,
    9.75
  ],
  [
    6041,
    63.7913,
    11.4843,
    9.5,
    9.7
  ],
  [
    6043,
    63.79147,
    11.48425,
    9.6,
    9.28
  ],
  [
    6045,
    63.79163,
    11.4842,
    9.9,
    8.67
  ],
  [
    6047,
    63.79178,
    11.48416,
    10,
    7.79
  ],
  [
    6049,
    63.79191,
    11.48412,
    10.2,
    6.8
  ],
  [
    6051,
    63.79202,
    11.48409,
    10.3,
    5.62
  ],
  [
    6053,
    63.7921,
    11.48407,
    10.4,
    4.32
  ],
  [
    6055,
    63.79217,
    11.48405,
    10.6,
    3.02
  ],
  [
    6057,
    63.79221,
    11.48403,
    10.8,
    1.82
  ],
  [
    6059,
    63.79223,
    11.48402,
    11,
    0.9
  ],
  [
    6061,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6063,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6065,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6067,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6069,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6071,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6073,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6075,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6077,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6079,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6081,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6083,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6085,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6087,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6089,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6091,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6093,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6095,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6097,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6099,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6101,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6103,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6105,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6107,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6109,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6111,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6113,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6115,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6117,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6119,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6121,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6123,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6125,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6127,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6129,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6131,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6133,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6135,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6137,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6139,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6141,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6143,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6145,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6147,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6149,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6151,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6153,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6155,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6157,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6159,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6161,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6163,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6165,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6167,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6169,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6171,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6173,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6175,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6177,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6179,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6181,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6183,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6185,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6187,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6189,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6191,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6193,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6195,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6197,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6199,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6201,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6203,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6205,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6207,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6209,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6211,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6213,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6215,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6217,
    63.79224,
    11.48402,
    11.1,
    0
  ],
  [
    6219,
    63.79227,
    11.48396,
    15.4,
    1.93
  ],
  [
    6221,
    63.79231,
    11.48395,
    15.4,
    2.63
  ],
  [
    6223,
    63.79237,
    11.48394,
    15.4,
    3.45
  ],
  [
    6225,
    63.79244,
    11.48392,
    15.3,
    4.19
  ],
  [
    6227,
    63.79252,
    11.4839,
    15.3,
    4.52
  ],
  [
    6229,
    63.79261,
    11.48388,
    15.1,
    5.4
  ],
  [
    6231,
    63.79271,
    11.48385,
    14.9,
    6.01
  ],
  [
    6233,
    63.79282,
    11.48382,
    14.6,
    6.55
  ],
  [
    6235,
    63.79294,
    11.48378,
    14.6,
    7.09
  ],
  [
    6237,
    63.79307,
    11.48375,
    14.6,
    7.38
  ],
  [
    6239,
    63.79321,
    11.48371,
    14.4,
    8.1
  ],
  [
    6241,
    63.79336,
    11.48367,
    14.1,
    8.62
  ],
  [
    6243,
    63.79352,
    11.48363,
    14.1,
    9.17
  ],
  [
    6245,
    63.79369,
    11.48358,
    14.1,
    9.61
  ],
  [
    6247,
    63.79386,
    11.48353,
    14.1,
    9.88
  ],
  [
    6249,
    63.79405,
    11.48347,
    14.4,
    10.95
  ],
  [
    6251,
    63.79425,
    11.4834,
    14.7,
    11.36
  ],
  [
    6253,
    63.79445,
    11.48333,
    15.1,
    11.62
  ],
  [
    6255,
    63.79467,
    11.48324,
    15.6,
    12.4
  ],
  [
    6257,
    63.79489,
    11.48313,
    15.9,
    12.63
  ],
  [
    6259,
    63.79512,
    11.483,
    16.1,
    13.25
  ],
  [
    6261,
    63.79535,
    11.48286,
    16.4,
    13.62
  ],
  [
    6263,
    63.79559,
    11.4827,
    16.7,
    13.95
  ],
  [
    6265,
    63.79583,
    11.48252,
    17,
    14.26
  ],
  [
    6267,
    63.79607,
    11.48232,
    17.4,
    14.41
  ],
  [
    6269,
    63.79632,
    11.48209,
    17.7,
    14.87
  ],
  [
    6271,
    63.79656,
    11.48185,
    18.1,
    15.13
  ],
  [
    6273,
    63.79681,
    11.48159,
    18.5,
    15.39
  ],
  [
    6275,
    63.79706,
    11.48131,
    19,
    15.62
  ],
  [
    6277,
    63.79732,
    11.48101,
    19.7,
    15.73
  ],
  [
    6279,
    63.79757,
    11.48071,
    20.3,
    16.07
  ],
  [
    6281,
    63.79783,
    11.48041,
    20.9,
    16.26
  ],
  [
    6283,
    63.79809,
    11.4801,
    21.4,
    16.61
  ],
  [
    6285,
    63.79836,
    11.47976,
    21.9,
    16.63
  ],
  [
    6287,
    63.79863,
    11.47944,
    22.4,
    16.69
  ],
  [
    6289,
    63.7989,
    11.47912,
    22.9,
    16.99
  ],
  [
    6291,
    63.79917,
    11.4788,
    23.2,
    17.14
  ],
  [
    6293,
    63.79944,
    11.47848,
    23.5,
    17.26
  ],
  [
    6295,
    63.79972,
    11.47815,
    24,
    17.46
  ],
  [
    6297,
    63.8,
    11.47782,
    24.4,
    17.57
  ],
  [
    6299,
    63.80028,
    11.47748,
    24.7,
    17.81
  ],
  [
    6301,
    63.80056,
    11.47715,
    24.8,
    17.93
  ],
  [
    6303,
    63.80085,
    11.4768,
    24.9,
    18.19
  ],
  [
    6305,
    63.80113,
    11.47645,
    25.1,
    18.4
  ],
  [
    6307,
    63.80142,
    11.47608,
    25.2,
    18.55
  ],
  [
    6309,
    63.80171,
    11.47569,
    25.4,
    18.94
  ],
  [
    6311,
    63.80201,
    11.47529,
    25.5,
    19.21
  ],
  [
    6313,
    63.8023,
    11.47487,
    25.5,
    19.54
  ],
  [
    6315,
    63.8026,
    11.47443,
    25.5,
    19.88
  ],
  [
    6317,
    63.8029,
    11.47397,
    25.4,
    20.02
  ],
  [
    6319,
    63.8032,
    11.47349,
    25,
    20.66
  ],
  [
    6321,
    63.8035,
    11.47299,
    24.6,
    21.12
  ],
  [
    6323,
    63.8038,
    11.47246,
    24.2,
    21.61
  ],
  [
    6325,
    63.80411,
    11.4719,
    23.7,
    22.16
  ],
  [
    6327,
    63.80442,
    11.47132,
    23.2,
    22.42
  ],
  [
    6329,
    63.80473,
    11.47071,
    22.5,
    23.24
  ],
  [
    6331,
    63.80505,
    11.47007,
    21.7,
    23.86
  ],
  [
    6333,
    63.80536,
    11.4694,
    20.8,
    24.43
  ],
  [
    6335,
    63.80568,
    11.46869,
    20,
    25.05
  ],
  [
    6337,
    63.806,
    11.46795,
    19.1,
    25.34
  ],
  [
    6339,
    63.80632,
    11.46718,
    18.2,
    26.08
  ],
  [
    6341,
    63.80663,
    11.46638,
    17.4,
    26.47
  ],
  [
    6343,
    63.80695,
    11.46556,
    17,
    26.82
  ],
  [
    6345,
    63.80725,
    11.4647,
    16.8,
    27.06
  ],
  [
    6347,
    63.80755,
    11.46382,
    16.8,
    27.17
  ],
  [
    6349,
    63.80784,
    11.46292,
    17.4,
    27.33
  ],
  [
    6351,
    63.80812,
    11.46201,
    18.1,
    27.3
  ],
  [
    6353,
    63.80839,
    11.46108,
    18.9,
    27.22
  ],
  [
    6355,
    63.80865,
    11.46014,
    19.8,
    27.14
  ],
  [
    6357,
    63.8089,
    11.45919,
    20.6,
    27.11
  ],
  [
    6359,
    63.80914,
    11.45823,
    21.4,
    27.04
  ],
  [
    6361,
    63.80938,
    11.45728,
    22.1,
    27.06
  ],
  [
    6363,
    63.80964,
    11.45635,
    22.7,
    27.11
  ],
  [
    6365,
    63.80994,
    11.45546,
    23.1,
    27.21
  ],
  [
    6367,
    63.81026,
    11.45462,
    23.2,
    27.35
  ],
  [
    6369,
    63.81061,
    11.45384,
    23.2,
    27.55
  ],
  [
    6371,
    63.811,
    11.45312,
    23.2,
    27.79
  ],
  [
    6373,
    63.81141,
    11.45246,
    23.1,
    28.07
  ],
  [
    6375,
    63.81185,
    11.45188,
    22.9,
    28.35
  ],
  [
    6377,
    63.81231,
    11.45137,
    22.7,
    28.64
  ],
  [
    6379,
    63.8128,
    11.45095,
    22.4,
    28.96
  ],
  [
    6381,
    63.8133,
    11.45062,
    22.4,
    29.18
  ],
  [
    6383,
    63.81382,
    11.45039,
    22.7,
    29.47
  ],
  [
    6385,
    63.81435,
    11.45026,
    23.3,
    29.64
  ],
  [
    6387,
    63.81489,
    11.45022,
    24.2,
    29.79
  ],
  [
    6389,
    63.81543,
    11.45023,
    25.1,
    29.8
  ],
  [
    6391,
    63.81596,
    11.45026,
    26.1,
    29.78
  ],
  [
    6393,
    63.8165,
    11.4503,
    26.9,
    29.77
  ],
  [
    6395,
    63.81703,
    11.45032,
    27.7,
    29.76
  ],
  [
    6397,
    63.81757,
    11.45034,
    28.6,
    29.76
  ],
  [
    6399,
    63.8181,
    11.45033,
    29.5,
    29.76
  ],
  [
    6401,
    63.81864,
    11.4503,
    30.5,
    29.73
  ],
  [
    6403,
    63.81917,
    11.45025,
    31.5,
    29.7
  ],
  [
    6405,
    63.8197,
    11.45019,
    32.2,
    29.71
  ],
  [
    6407,
    63.82023,
    11.4501,
    32.6,
    29.72
  ],
  [
    6409,
    63.82077,
    11.44999,
    32.7,
    29.82
  ],
  [
    6411,
    63.8213,
    11.44986,
    32.6,
    29.98
  ],
  [
    6413,
    63.82184,
    11.44971,
    32.5,
    30.21
  ],
  [
    6415,
    63.82238,
    11.44954,
    32.4,
    30.36
  ],
  [
    6417,
    63.82293,
    11.44935,
    33.7,
    30.35
  ],
  [
    6419,
    63.82347,
    11.44913,
    34,
    30.5
  ],
  [
    6421,
    63.82401,
    11.44891,
    34.2,
    30.5
  ],
  [
    6423,
    63.82454,
    11.44869,
    34.2,
    30.27
  ],
  [
    6425,
    63.82508,
    11.44847,
    34.3,
    30.27
  ],
  [
    6427,
    63.82561,
    11.44825,
    34.4,
    30.27
  ],
  [
    6429,
    63.82613,
    11.44803,
    34.4,
    30.07
  ],
  [
    6431,
    63.82665,
    11.44781,
    34.2,
    30.07
  ],
  [
    6433,
    63.82715,
    11.4476,
    34.1,
    28.49
  ],
  [
    6435,
    63.82764,
    11.4474,
    34,
    28.49
  ],
  [
    6437,
    63.82811,
    11.4472,
    33.9,
    28.49
  ],
  [
    6439,
    63.82857,
    11.44701,
    34,
    26.41
  ],
  [
    6441,
    63.82901,
    11.44683,
    34,
    26.41
  ],
  [
    6443,
    63.82943,
    11.44665,
    34.1,
    24.19
  ],
  [
    6445,
    63.82985,
    11.44648,
    34.3,
    24.19
  ],
  [
    6447,
    63.83027,
    11.4463,
    34.5,
    24.19
  ],
  [
    6449,
    63.83068,
    11.44613,
    34.9,
    23.46
  ],
  [
    6451,
    63.83109,
    11.44593,
    35.3,
    23.46
  ],
  [
    6453,
    63.83148,
    11.44563,
    35.6,
    23.14
  ],
  [
    6455,
    63.83184,
    11.44519,
    35.9,
    23.14
  ],
  [
    6457,
    63.83217,
    11.44464,
    36.1,
    23.14
  ],
  [
    6459,
    63.83245,
    11.44398,
    36.4,
    22.79
  ],
  [
    6461,
    63.83269,
    11.44323,
    36.6,
    22.79
  ],
  [
    6463,
    63.83289,
    11.44243,
    36.9,
    22.59
  ],
  [
    6465,
    63.83308,
    11.44161,
    37.4,
    22.59
  ],
  [
    6467,
    63.83326,
    11.44079,
    37.7,
    22.59
  ],
  [
    6469,
    63.83342,
    11.43995,
    36.9,
    22.47
  ],
  [
    6471,
    63.83359,
    11.43912,
    37,
    22.47
  ],
  [
    6473,
    63.83375,
    11.43829,
    37.2,
    22.35
  ],
  [
    6475,
    63.83391,
    11.43745,
    37.4,
    22.33
  ],
  [
    6477,
    63.83406,
    11.43661,
    37.8,
    22.32
  ],
  [
    6479,
    63.83422,
    11.43577,
    38.1,
    22.34
  ],
  [
    6481,
    63.83439,
    11.43494,
    38.3,
    22.38
  ],
  [
    6483,
    63.83457,
    11.43412,
    38.3,
    22.44
  ],
  [
    6485,
    63.83477,
    11.43332,
    38.4,
    22.51
  ],
  [
    6487,
    63.83498,
    11.43254,
    38.5,
    22.58
  ],
  [
    6489,
    63.83519,
    11.43175,
    38.5,
    22.6
  ],
  [
    6491,
    63.83538,
    11.43094,
    38.4,
    22.57
  ],
  [
    6493,
    63.83553,
    11.43009,
    38.4,
    22.49
  ],
  [
    6495,
    63.83564,
    11.42921,
    38.3,
    22.42
  ],
  [
    6497,
    63.83572,
    11.42832,
    38.2,
    22.37
  ],
  [
    6499,
    63.83575,
    11.42741,
    38.3,
    22.32
  ],
  [
    6501,
    63.83577,
    11.42651,
    38.4,
    22.29
  ],
  [
    6503,
    63.83579,
    11.42563,
    38.7,
    21.73
  ],
  [
    6505,
    63.83563,
    11.42464,
    45.7,
    21.73
  ],
  [
    6507,
    63.83556,
    11.42374,
    43.8,
    22.2
  ],
  [
    6509,
    63.83547,
    11.42286,
    43.7,
    22.05
  ],
  [
    6511,
    63.8354,
    11.42197,
    43.5,
    22.01
  ],
  [
    6513,
    63.83538,
    11.42108,
    43.3,
    22
  ],
  [
    6515,
    63.83542,
    11.42019,
    43,
    21.93
  ],
  [
    6517,
    63.83552,
    11.41933,
    42.8,
    21.85
  ],
  [
    6518,
    63.83559,
    11.41891,
    42.8,
    21.82
  ],
  [
    6521,
    63.83587,
    11.41774,
    43.1,
    21.86
  ],
  [
    6523,
    63.83612,
    11.41707,
    43,
    21.6
  ],
  [
    6524,
    63.83626,
    11.41676,
    43,
    21.5
  ],
  [
    6526,
    63.83655,
    11.4162,
    43.2,
    21.43
  ],
  [
    6528,
    63.83687,
    11.4157,
    43.7,
    21.34
  ],
  [
    6530,
    63.8372,
    11.41526,
    44.4,
    21.2
  ],
  [
    6532,
    63.83754,
    11.41487,
    45.1,
    21.03
  ],
  [
    6534,
    63.83789,
    11.41456,
    45.7,
    20.89
  ],
  [
    6536,
    63.83825,
    11.41433,
    46.1,
    20.74
  ],
  [
    6538,
    63.83862,
    11.41415,
    46.8,
    20.59
  ],
  [
    6540,
    63.83899,
    11.41404,
    47.5,
    20.54
  ],
  [
    6542,
    63.83936,
    11.41396,
    48.6,
    20.4
  ],
  [
    6544,
    63.83972,
    11.41387,
    49.3,
    20.29
  ],
  [
    6546,
    63.84009,
    11.41374,
    50.2,
    20.26
  ],
  [
    6548,
    63.84044,
    11.41356,
    50.7,
    20.29
  ],
  [
    6550,
    63.84079,
    11.41333,
    51.3,
    20.31
  ],
  [
    6552,
    63.84114,
    11.41306,
    51.9,
    20.32
  ],
  [
    6554,
    63.84149,
    11.41282,
    52.5,
    20.43
  ],
  [
    6556,
    63.84185,
    11.41265,
    53.1,
    20.56
  ],
  [
    6558,
    63.84222,
    11.41256,
    54,
    20.76
  ],
  [
    6560,
    63.84259,
    11.41255,
    55,
    20.78
  ],
  [
    6562,
    63.84297,
    11.41261,
    56,
    20.82
  ],
  [
    6564,
    63.84334,
    11.41268,
    56.4,
    20.84
  ],
  [
    6566,
    63.84372,
    11.41271,
    57,
    20.94
  ],
  [
    6568,
    63.84409,
    11.41267,
    57.7,
    21.03
  ],
  [
    6570,
    63.84447,
    11.41256,
    58.3,
    21.08
  ],
  [
    6572,
    63.84484,
    11.41239,
    59,
    21.16
  ],
  [
    6574,
    63.84521,
    11.41218,
    60,
    21.25
  ],
  [
    6576,
    63.84558,
    11.41197,
    60.9,
    21.33
  ],
  [
    6578,
    63.84596,
    11.41177,
    61.8,
    21.43
  ],
  [
    6580,
    63.84634,
    11.41161,
    62.7,
    21.51
  ],
  [
    6582,
    63.84672,
    11.41147,
    63.5,
    21.61
  ],
  [
    6584,
    63.8471,
    11.41136,
    64.1,
    21.74
  ],
  [
    6586,
    63.84749,
    11.41129,
    64.6,
    21.88
  ],
  [
    6588,
    63.84789,
    11.41124,
    64.6,
    22.03
  ],
  [
    6590,
    63.84828,
    11.41123,
    64.6,
    22.16
  ],
  [
    6592,
    63.84868,
    11.41123,
    64.6,
    22.37
  ],
  [
    6594,
    63.84908,
    11.41123,
    63.5,
    22.5
  ],
  [
    6596,
    63.84949,
    11.41114,
    63.5,
    22.64
  ],
  [
    6598,
    63.84988,
    11.41092,
    63.5,
    22.7
  ],
  [
    6600,
    63.85026,
    11.41057,
    63.7,
    22.63
  ],
  [
    6602,
    63.8506,
    11.41009,
    64.4,
    22.49
  ],
  [
    6604,
    63.85091,
    11.4095,
    65.1,
    22.27
  ],
  [
    6606,
    63.85117,
    11.40882,
    65.4,
    22
  ],
  [
    6608,
    63.8514,
    11.40809,
    65.8,
    21.74
  ],
  [
    6610,
    63.85162,
    11.40736,
    66.1,
    21.48
  ],
  [
    6612,
    63.85184,
    11.40665,
    66.2,
    21.27
  ],
  [
    6614,
    63.85205,
    11.40594,
    65.9,
    21.09
  ],
  [
    6616,
    63.85227,
    11.40523,
    65.6,
    21.01
  ],
  [
    6618,
    63.8525,
    11.40456,
    65.1,
    21.02
  ],
  [
    6620,
    63.85277,
    11.40395,
    64.3,
    21.05
  ],
  [
    6622,
    63.85307,
    11.40344,
    63.7,
    21.21
  ],
  [
    6624,
    63.8534,
    11.40302,
    63,
    21.31
  ],
  [
    6626,
    63.85376,
    11.40272,
    62.5,
    21.54
  ],
  [
    6628,
    63.85414,
    11.40253,
    61.7,
    21.72
  ],
  [
    6630,
    63.85452,
    11.4024,
    60.9,
    21.85
  ],
  [
    6632,
    63.85491,
    11.40227,
    60.2,
    22.07
  ],
  [
    6634,
    63.85531,
    11.40215,
    59.7,
    22.26
  ],
  [
    6636,
    63.8557,
    11.40202,
    59.5,
    22.44
  ],
  [
    6638,
    63.8561,
    11.4019,
    59.4,
    22.52
  ],
  [
    6640,
    63.85651,
    11.40177,
    59.4,
    22.51
  ],
  [
    6642,
    63.85691,
    11.40165,
    59.6,
    22.61
  ],
  [
    6644,
    63.85731,
    11.40152,
    59.8,
    22.59
  ],
  [
    6646,
    63.85771,
    11.40139,
    60,
    22.63
  ],
  [
    6648,
    63.85811,
    11.40126,
    60.1,
    22.64
  ],
  [
    6650,
    63.85852,
    11.40114,
    60,
    22.65
  ],
  [
    6652,
    63.85892,
    11.40101,
    60,
    22.67
  ],
  [
    6654,
    63.85932,
    11.40088,
    60,
    22.72
  ],
  [
    6656,
    63.85973,
    11.40079,
    60.3,
    22.81
  ],
  [
    6658,
    63.86014,
    11.40077,
    60.6,
    22.83
  ],
  [
    6660,
    63.86055,
    11.40083,
    61.1,
    22.82
  ],
  [
    6662,
    63.86095,
    11.40096,
    61.7,
    22.76
  ],
  [
    6664,
    63.86135,
    11.40115,
    62.4,
    22.74
  ],
  [
    6666,
    63.86175,
    11.40137,
    63,
    22.73
  ],
  [
    6668,
    63.86215,
    11.40158,
    63.4,
    22.79
  ],
  [
    6670,
    63.86255,
    11.40179,
    64,
    22.88
  ],
  [
    6672,
    63.86295,
    11.40198,
    64.5,
    23.01
  ],
  [
    6674,
    63.86336,
    11.40215,
    65,
    23.18
  ],
  [
    6676,
    63.86377,
    11.40232,
    65.3,
    23.33
  ],
  [
    6678,
    63.86418,
    11.40247,
    65.6,
    23.52
  ],
  [
    6680,
    63.8646,
    11.40261,
    65.8,
    23.69
  ],
  [
    6682,
    63.86502,
    11.40273,
    66.1,
    23.89
  ],
  [
    6684,
    63.86545,
    11.40283,
    66.5,
    24.07
  ],
  [
    6686,
    63.86588,
    11.40292,
    67.1,
    24.2
  ],
  [
    6688,
    63.86632,
    11.403,
    67.9,
    24.37
  ],
  [
    6690,
    63.86676,
    11.40307,
    68.6,
    24.49
  ],
  [
    6692,
    63.86719,
    11.40315,
    69.1,
    24.6
  ],
  [
    6694,
    63.86764,
    11.40322,
    69.4,
    24.73
  ],
  [
    6696,
    63.86808,
    11.40329,
    69.7,
    24.89
  ],
  [
    6698,
    63.86853,
    11.40336,
    70,
    25.1
  ],
  [
    6700,
    63.86898,
    11.40343,
    70.2,
    25.36
  ],
  [
    6702,
    63.86943,
    11.40351,
    70.2,
    25.61
  ],
  [
    6704,
    63.86989,
    11.40359,
    70.2,
    25.9
  ],
  [
    6706,
    63.87036,
    11.40368,
    70.1,
    26.19
  ],
  [
    6708,
    63.87083,
    11.40379,
    69.9,
    26.51
  ],
  [
    6710,
    63.8713,
    11.40392,
    69.8,
    26.85
  ],
  [
    6712,
    63.87178,
    11.40406,
    69.6,
    27.17
  ],
  [
    6714,
    63.87227,
    11.40421,
    69.4,
    27.49
  ],
  [
    6716,
    63.87276,
    11.40439,
    69.1,
    27.81
  ],
  [
    6718,
    63.87325,
    11.40458,
    69.1,
    28.12
  ],
  [
    6720,
    63.87375,
    11.40479,
    69,
    28.37
  ],
  [
    6722,
    63.87425,
    11.40502,
    69.1,
    28.59
  ],
  [
    6724,
    63.87476,
    11.40527,
    69.6,
    28.78
  ],
  [
    6726,
    63.87526,
    11.40553,
    70.2,
    28.89
  ],
  [
    6728,
    63.87576,
    11.40583,
    71.1,
    28.9
  ],
  [
    6730,
    63.87625,
    11.4062,
    72,
    28.87
  ],
  [
    6732,
    63.87673,
    11.40665,
    72.8,
    28.8
  ],
  [
    6734,
    63.87719,
    11.40717,
    73.6,
    28.72
  ],
  [
    6736,
    63.87763,
    11.40777,
    74.5,
    28.64
  ],
  [
    6738,
    63.87805,
    11.40844,
    75.4,
    28.57
  ],
  [
    6740,
    63.87845,
    11.40917,
    76.2,
    28.34
  ],
  [
    6742,
    63.87882,
    11.40995,
    77.2,
    27.91
  ],
  [
    6744,
    63.87916,
    11.41077,
    78.1,
    27.49
  ],
  [
    6746,
    63.8795,
    11.41157,
    78.9,
    27.09
  ],
  [
    6748,
    63.87986,
    11.41232,
    79.7,
    26.71
  ],
  [
    6750,
    63.88023,
    11.41299,
    80.5,
    26.32
  ],
  [
    6752,
    63.88061,
    11.41359,
    81.4,
    25.94
  ],
  [
    6754,
    63.88101,
    11.41411,
    82.5,
    25.17
  ],
  [
    6756,
    63.8814,
    11.41461,
    83.5,
    25.17
  ],
  [
    6758,
    63.8818,
    11.41511,
    84.6,
    25.17
  ],
  [
    6774,
    63.88489,
    11.41627,
    93,
    21.1
  ],
  [
    6776,
    63.88525,
    11.41657,
    89.3,
    21.26
  ],
  [
    6778,
    63.88558,
    11.417,
    85.5,
    21.37
  ],
  [
    6780,
    63.88587,
    11.41754,
    81.1,
    21.33
  ],
  [
    6782,
    63.88613,
    11.41819,
    81.1,
    21.49
  ],
  [
    6784,
    63.88634,
    11.41892,
    81.1,
    21.69
  ],
  [
    6786,
    63.88651,
    11.41972,
    81.1,
    21.9
  ],
  [
    6788,
    63.88666,
    11.42056,
    81.1,
    22.11
  ],
  [
    6790,
    63.88682,
    11.42138,
    81.1,
    22.33
  ],
  [
    6792,
    63.88704,
    11.42215,
    81.1,
    22.6
  ],
  [
    6794,
    63.88731,
    11.42284,
    72.2,
    22.83
  ],
  [
    6796,
    63.88763,
    11.42344,
    71.6,
    23.09
  ],
  [
    6798,
    63.88798,
    11.42395,
    71,
    23.32
  ],
  [
    6800,
    63.88834,
    11.42444,
    70.2,
    23.55
  ],
  [
    6802,
    63.88871,
    11.42492,
    69.5,
    23.83
  ],
  [
    6804,
    63.88908,
    11.4254,
    68.8,
    24.18
  ],
  [
    6806,
    63.88947,
    11.42586,
    68,
    24.52
  ],
  [
    6808,
    63.88986,
    11.42632,
    67.3,
    24.87
  ],
  [
    6810,
    63.89027,
    11.42677,
    66.6,
    25.23
  ],
  [
    6812,
    63.89068,
    11.42723,
    65.6,
    25.65
  ],
  [
    6814,
    63.89109,
    11.42769,
    64.8,
    26.08
  ],
  [
    6816,
    63.89152,
    11.42816,
    64.1,
    26.56
  ],
  [
    6818,
    63.89194,
    11.42866,
    63.3,
    27.09
  ],
  [
    6820,
    63.89238,
    11.42918,
    62.3,
    27.56
  ],
  [
    6822,
    63.89282,
    11.42972,
    61.5,
    28.07
  ],
  [
    6824,
    63.89326,
    11.43028,
    60.7,
    28.54
  ],
  [
    6826,
    63.89372,
    11.43081,
    60.3,
    28.94
  ],
  [
    6828,
    63.8942,
    11.4313,
    60.1,
    29.25
  ],
  [
    6830,
    63.8947,
    11.43172,
    60,
    29.44
  ],
  [
    6832,
    63.8952,
    11.43209,
    60.2,
    29.56
  ],
  [
    6834,
    63.89571,
    11.43245,
    60.3,
    29.55
  ],
  [
    6836,
    63.89622,
    11.43281,
    60.5,
    29.53
  ],
  [
    6838,
    63.89672,
    11.43316,
    60.5,
    29.49
  ],
  [
    6840,
    63.89723,
    11.43352,
    60.5,
    29.43
  ],
  [
    6842,
    63.89773,
    11.43388,
    60.7,
    29.37
  ],
  [
    6844,
    63.89823,
    11.43423,
    61,
    29.31
  ],
  [
    6846,
    63.89874,
    11.43458,
    61.2,
    29.18
  ],
  [
    6848,
    63.89923,
    11.43493,
    61.5,
    29.06
  ],
  [
    6850,
    63.89973,
    11.43528,
    61.9,
    28.91
  ],
  [
    6852,
    63.90023,
    11.43563,
    62.3,
    28.76
  ],
  [
    6854,
    63.90072,
    11.43598,
    62.6,
    28.58
  ],
  [
    6856,
    63.9012,
    11.43632,
    62.9,
    28.4
  ],
  [
    6858,
    63.90169,
    11.43663,
    63.4,
    28.23
  ],
  [
    6860,
    63.90219,
    11.43689,
    64,
    28.03
  ],
  [
    6862,
    63.90268,
    11.43708,
    64.5,
    27.84
  ],
  [
    6864,
    63.90317,
    11.43721,
    64.9,
    27.65
  ],
  [
    6866,
    63.90367,
    11.43728,
    65.1,
    27.48
  ],
  [
    6868,
    63.90416,
    11.43728,
    65.2,
    27.37
  ],
  [
    6870,
    63.90465,
    11.43723,
    65.1,
    27.34
  ],
  [
    6872,
    63.90514,
    11.43712,
    64.9,
    27.31
  ],
  [
    6874,
    63.90562,
    11.43695,
    64.6,
    27.39
  ],
  [
    6876,
    63.90611,
    11.43675,
    63.9,
    27.52
  ],
  [
    6878,
    63.9066,
    11.43656,
    63,
    27.7
  ],
  [
    6880,
    63.90709,
    11.43638,
    62,
    27.93
  ],
  [
    6882,
    63.90759,
    11.43621,
    61.1,
    28.2
  ],
  [
    6884,
    63.90809,
    11.43606,
    60.1,
    28.51
  ],
  [
    6886,
    63.9086,
    11.43592,
    59.1,
    28.77
  ],
  [
    6888,
    63.90912,
    11.4358,
    58.1,
    29.06
  ],
  [
    6890,
    63.90964,
    11.4357,
    57.2,
    29.06
  ],
  [
    6892,
    63.91016,
    11.43558,
    56.3,
    28.78
  ],
  [
    6894,
    63.91067,
    11.43542,
    55.4,
    28.57
  ],
  [
    6896,
    63.91117,
    11.4352,
    54.4,
    28.29
  ],
  [
    6898,
    63.91166,
    11.43492,
    53.4,
    28.01
  ],
  [
    6900,
    63.91213,
    11.43458,
    52.5,
    27.68
  ],
  [
    6902,
    63.9126,
    11.4342,
    51.7,
    27.48
  ],
  [
    6904,
    63.91306,
    11.43381,
    50.7,
    27.25
  ],
  [
    6906,
    63.91352,
    11.43342,
    49.9,
    27.13
  ],
  [
    6908,
    63.91397,
    11.43303,
    49,
    27.21
  ],
  [
    6910,
    63.91443,
    11.43264,
    48.1,
    27.44
  ],
  [
    6912,
    63.9149,
    11.43225,
    46.9,
    27.7
  ],
  [
    6914,
    63.91536,
    11.43185,
    45.7,
    28.01
  ],
  [
    6916,
    63.91584,
    11.43145,
    44.6,
    28.34
  ],
  [
    6918,
    63.91632,
    11.43104,
    43.4,
    28.55
  ],
  [
    6920,
    63.91679,
    11.43064,
    42.3,
    28.27
  ],
  [
    6922,
    63.91727,
    11.43023,
    41.6,
    27.83
  ],
  [
    6924,
    63.91773,
    11.42984,
    41.3,
    27.01
  ],
  [
    6926,
    63.91817,
    11.42946,
    41.1,
    26.12
  ],
  [
    6928,
    63.9186,
    11.4291,
    40.9,
    25.1
  ],
  [
    6930,
    63.91901,
    11.42875,
    40.7,
    24.18
  ],
  [
    6932,
    63.91941,
    11.42841,
    40.6,
    23.46
  ],
  [
    6934,
    63.9198,
    11.42808,
    40.4,
    22.95
  ],
  [
    6936,
    63.92018,
    11.42775,
    40.1,
    22.5
  ],
  [
    6938,
    63.92056,
    11.42744,
    39.9,
    22.22
  ],
  [
    6940,
    63.92093,
    11.42712,
    39.7,
    22.06
  ],
  [
    6942,
    63.9213,
    11.42681,
    39.2,
    22.03
  ],
  [
    6944,
    63.92167,
    11.4265,
    38.6,
    22.17
  ],
  [
    6946,
    63.92204,
    11.42621,
    37.8,
    22.26
  ],
  [
    6948,
    63.92242,
    11.42592,
    37,
    22.44
  ],
  [
    6950,
    63.92281,
    11.42566,
    36.1,
    22.26
  ],
  [
    6952,
    63.92319,
    11.42541,
    35.3,
    21.98
  ],
  [
    6954,
    63.92356,
    11.42518,
    34.5,
    21.63
  ],
  [
    6956,
    63.92394,
    11.42498,
    33.7,
    21.19
  ],
  [
    6958,
    63.9243,
    11.42479,
    33,
    20.57
  ],
  [
    6960,
    63.92465,
    11.42462,
    32.3,
    19.91
  ],
  [
    6962,
    63.925,
    11.42446,
    31.6,
    19.22
  ],
  [
    6964,
    63.92533,
    11.42433,
    31,
    18.56
  ],
  [
    6966,
    63.92565,
    11.42421,
    30.5,
    17.86
  ],
  [
    6968,
    63.92596,
    11.4241,
    30.1,
    17.17
  ],
  [
    6970,
    63.92626,
    11.424,
    29.7,
    16.52
  ],
  [
    6972,
    63.92654,
    11.4239,
    29.4,
    15.98
  ],
  [
    6974,
    63.92682,
    11.4238,
    29.1,
    15.46
  ],
  [
    6976,
    63.92709,
    11.42371,
    28.9,
    14.96
  ],
  [
    6978,
    63.92735,
    11.42362,
    28.7,
    14.47
  ],
  [
    6980,
    63.9276,
    11.42354,
    28.5,
    13.96
  ],
  [
    6982,
    63.92784,
    11.42345,
    28.5,
    13.44
  ],
  [
    6984,
    63.92807,
    11.42337,
    28.4,
    12.87
  ],
  [
    6986,
    63.92829,
    11.4233,
    28.4,
    12.18
  ],
  [
    6988,
    63.9285,
    11.42323,
    28.4,
    11.3
  ],
  [
    6990,
    63.92869,
    11.42316,
    28.5,
    10.29
  ],
  [
    6992,
    63.92886,
    11.4231,
    28.5,
    9.56
  ],
  [
    6994,
    63.92903,
    11.42305,
    28.5,
    9.2
  ],
  [
    6996,
    63.92919,
    11.42299,
    28.5,
    9.03
  ],
  [
    6998,
    63.92934,
    11.42294,
    28.5,
    8.95
  ],
  [
    7000,
    63.9295,
    11.42289,
    28.5,
    8.95
  ],
  [
    7002,
    63.92966,
    11.42283,
    28.5,
    8.97
  ],
  [
    7004,
    63.92981,
    11.42276,
    28.6,
    9
  ],
  [
    7006,
    63.92997,
    11.42267,
    28.6,
    9.12
  ],
  [
    7008,
    63.93013,
    11.42258,
    28.6,
    9.35
  ],
  [
    7010,
    63.93029,
    11.42251,
    28.7,
    9.47
  ],
  [
    7012,
    63.93046,
    11.42246,
    28.6,
    9.57
  ],
  [
    7014,
    63.93063,
    11.4224,
    28.6,
    9.75
  ],
  [
    7016,
    63.9308,
    11.42234,
    28.6,
    9.92
  ],
  [
    7018,
    63.93098,
    11.42228,
    28.5,
    10.06
  ],
  [
    7020,
    63.93116,
    11.42222,
    28.5,
    10.18
  ],
  [
    7022,
    63.93134,
    11.42216,
    28.5,
    10.22
  ],
  [
    7024,
    63.93152,
    11.42209,
    28.6,
    10.25
  ],
  [
    7026,
    63.9317,
    11.42203,
    28.5,
    10.25
  ],
  [
    7028,
    63.93188,
    11.42197,
    28.4,
    10.25
  ],
  [
    7030,
    63.93206,
    11.42191,
    28.4,
    10.26
  ],
  [
    7032,
    63.93224,
    11.42185,
    28.4,
    10.26
  ],
  [
    7034,
    63.93242,
    11.42178,
    28.4,
    10.25
  ],
  [
    7036,
    63.9326,
    11.42172,
    28.4,
    10.27
  ],
  [
    7038,
    63.93278,
    11.42166,
    28.3,
    10.28
  ],
  [
    7040,
    63.93296,
    11.4216,
    28.2,
    10.3
  ],
  [
    7042,
    63.93315,
    11.42154,
    28.2,
    10.29
  ],
  [
    7044,
    63.93333,
    11.42147,
    28.2,
    10.29
  ],
  [
    7046,
    63.93351,
    11.42141,
    28.2,
    10.29
  ],
  [
    7048,
    63.93369,
    11.42135,
    28.1,
    10.27
  ],
  [
    7050,
    63.93387,
    11.42129,
    28.2,
    10.27
  ],
  [
    7052,
    63.93405,
    11.42122,
    28.1,
    10.26
  ],
  [
    7054,
    63.93423,
    11.42116,
    28,
    9.92
  ],
  [
    7056,
    63.9344,
    11.4211,
    28,
    9.31
  ],
  [
    7058,
    63.93456,
    11.42105,
    28,
    8.8
  ],
  [
    7060,
    63.93471,
    11.421,
    28,
    8.3
  ],
  [
    7062,
    63.93485,
    11.42095,
    28,
    7.89
  ],
  [
    7064,
    63.93499,
    11.4209,
    28.1,
    7.55
  ],
  [
    7066,
    63.93512,
    11.42086,
    28.1,
    7.29
  ],
  [
    7068,
    63.93524,
    11.42081,
    28.1,
    6.89
  ],
  [
    7070,
    63.93535,
    11.42077,
    28.1,
    5.81
  ],
  [
    7072,
    63.93544,
    11.42074,
    28.2,
    4.52
  ],
  [
    7074,
    63.93551,
    11.42072,
    28.2,
    3.27
  ],
  [
    7076,
    63.93556,
    11.42071,
    28.3,
    2.12
  ],
  [
    7078,
    63.93558,
    11.4207,
    28.3,
    1.07
  ],
  [
    7080,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7082,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7084,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7086,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7088,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7090,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7092,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7094,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7096,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7098,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7100,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7102,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7104,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7106,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7108,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7110,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7112,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7114,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7116,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7118,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7120,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7122,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7124,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7126,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7128,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7130,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7132,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7134,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7136,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7138,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7140,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7142,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7144,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7146,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7148,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7150,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7152,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7154,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7156,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7158,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7160,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7162,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7164,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7166,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7168,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7170,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7172,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7174,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7176,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7178,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7180,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7182,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7184,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7186,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7188,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7190,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7192,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7194,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7196,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7198,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7200,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7202,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7204,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7206,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7208,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7210,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7212,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7214,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7216,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7218,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7220,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7222,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7224,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7226,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7228,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7230,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7232,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7234,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7236,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7238,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7240,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7242,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7244,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7246,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7248,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7250,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7252,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7254,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7256,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7258,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7260,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7262,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7264,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7266,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7268,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7270,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7272,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7274,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7276,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7278,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7280,
    63.93559,
    11.4207,
    28.3,
    0
  ],
  [
    7282,
    63.93562,
    11.42068,
    29.5,
    1.87
  ],
  [
    7284,
    63.93568,
    11.42066,
    29.6,
    3.42
  ],
  [
    7286,
    63.93574,
    11.42063,
    29.6,
    4.28
  ],
  [
    7288,
    63.93583,
    11.42061,
    29.6,
    4.97
  ],
  [
    7290,
    63.93592,
    11.42058,
    29.5,
    5.56
  ],
  [
    7292,
    63.93602,
    11.42056,
    29.5,
    5.83
  ],
  [
    7294,
    63.93614,
    11.42055,
    29.5,
    6.55
  ],
  [
    7296,
    63.93626,
    11.42053,
    29.5,
    6.97
  ],
  [
    7298,
    63.93639,
    11.4205,
    29.5,
    7.42
  ],
  [
    7300,
    63.93652,
    11.42046,
    29.4,
    7.9
  ],
  [
    7302,
    63.93667,
    11.4204,
    29.2,
    8.3
  ],
  [
    7304,
    63.93682,
    11.42035,
    29.1,
    8.93
  ],
  [
    7306,
    63.93698,
    11.42029,
    29,
    9.43
  ],
  [
    7308,
    63.93716,
    11.42023,
    28.9,
    9.88
  ],
  [
    7310,
    63.93733,
    11.42017,
    28.9,
    10.18
  ],
  [
    7312,
    63.93752,
    11.4201,
    28.8,
    10.35
  ],
  [
    7314,
    63.93771,
    11.42004,
    28.7,
    10.75
  ],
  [
    7316,
    63.9379,
    11.41997,
    28.7,
    11.02
  ],
  [
    7318,
    63.9381,
    11.4199,
    28.7,
    11.31
  ],
  [
    7320,
    63.9383,
    11.41983,
    28.7,
    11.61
  ],
  [
    7322,
    63.93851,
    11.41976,
    28.7,
    11.78
  ],
  [
    7324,
    63.93873,
    11.41969,
    28.7,
    12.3
  ],
  [
    7326,
    63.93895,
    11.41961,
    28.6,
    12.68
  ],
  [
    7328,
    63.93918,
    11.41953,
    28.5,
    13.09
  ],
  [
    7330,
    63.93942,
    11.41945,
    28.5,
    13.6
  ],
  [
    7332,
    63.93966,
    11.41937,
    28.4,
    13.85
  ],
  [
    7334,
    63.93992,
    11.41928,
    28.4,
    14.75
  ],
  [
    7336,
    63.94019,
    11.41918,
    28.4,
    15.31
  ],
  [
    7338,
    63.94046,
    11.41909,
    28.4,
    15.6
  ],
  [
    7340,
    63.94075,
    11.41899,
    28.4,
    16.2
  ],
  [
    7342,
    63.94105,
    11.41889,
    28.3,
    16.79
  ],
  [
    7344,
    63.94136,
    11.41878,
    28.3,
    17.33
  ],
  [
    7346,
    63.94168,
    11.41867,
    28.3,
    17.86
  ],
  [
    7348,
    63.942,
    11.41856,
    28.3,
    18.36
  ],
  [
    7350,
    63.94234,
    11.41844,
    28.3,
    18.84
  ],
  [
    7352,
    63.94268,
    11.41832,
    28.3,
    19.3
  ],
  [
    7354,
    63.94303,
    11.4182,
    28.3,
    19.75
  ],
  [
    7356,
    63.94339,
    11.41808,
    28.3,
    20.17
  ],
  [
    7358,
    63.94376,
    11.41795,
    28.3,
    20.57
  ],
  [
    7360,
    63.94413,
    11.41782,
    28.4,
    20.95
  ],
  [
    7362,
    63.94451,
    11.41769,
    28.4,
    21.33
  ],
  [
    7364,
    63.94489,
    11.41756,
    28.5,
    21.69
  ],
  [
    7366,
    63.94528,
    11.41742,
    28.5,
    22.04
  ],
  [
    7368,
    63.94568,
    11.41728,
    28.5,
    22.39
  ],
  [
    7370,
    63.94609,
    11.41714,
    28.6,
    22.73
  ],
  [
    7372,
    63.9465,
    11.417,
    28.6,
    23.08
  ],
  [
    7374,
    63.94691,
    11.41686,
    28.6,
    23.38
  ],
  [
    7376,
    63.94734,
    11.41671,
    28.6,
    23.76
  ],
  [
    7378,
    63.94776,
    11.41657,
    28.6,
    24.1
  ],
  [
    7380,
    63.9482,
    11.41642,
    28.6,
    24.42
  ],
  [
    7382,
    63.94864,
    11.41626,
    28.6,
    24.76
  ],
  [
    7384,
    63.94908,
    11.41611,
    28.7,
    25.07
  ],
  [
    7386,
    63.94953,
    11.41596,
    28.8,
    25.37
  ],
  [
    7388,
    63.94999,
    11.4158,
    28.9,
    25.66
  ],
  [
    7390,
    63.95045,
    11.41564,
    29,
    25.92
  ],
  [
    7392,
    63.95092,
    11.41548,
    29.2,
    26.17
  ],
  [
    7394,
    63.95138,
    11.41532,
    29.3,
    26.45
  ],
  [
    7396,
    63.95186,
    11.41515,
    29.4,
    26.69
  ],
  [
    7398,
    63.95234,
    11.41499,
    29.4,
    26.95
  ],
  [
    7400,
    63.95282,
    11.41482,
    29.4,
    27.21
  ],
  [
    7402,
    63.95331,
    11.41465,
    29.4,
    27.48
  ],
  [
    7404,
    63.9538,
    11.41448,
    29.3,
    27.75
  ],
  [
    7406,
    63.9543,
    11.41431,
    29.4,
    28.03
  ],
  [
    7408,
    63.9548,
    11.41413,
    29.5,
    28.29
  ],
  [
    7410,
    63.95531,
    11.41396,
    29.6,
    28.52
  ],
  [
    7412,
    63.95582,
    11.41378,
    29.7,
    28.75
  ],
  [
    7414,
    63.95633,
    11.41361,
    29.7,
    28.98
  ],
  [
    7416,
    63.95685,
    11.41343,
    29.6,
    29.22
  ],
  [
    7418,
    63.95737,
    11.41325,
    29.7,
    29.44
  ],
  [
    7420,
    63.9579,
    11.41306,
    29.8,
    29.44
  ],
  [
    7422,
    63.95843,
    11.41288,
    30,
    29.44
  ],
  [
    7424,
    63.95897,
    11.4127,
    30.1,
    30.09
  ],
  [
    7426,
    63.9595,
    11.41251,
    30.3,
    30.28
  ],
  [
    7428,
    63.96004,
    11.41232,
    30.5,
    30.46
  ],
  [
    7430,
    63.96059,
    11.41214,
    30.7,
    30.63
  ],
  [
    7432,
    63.96114,
    11.41195,
    30.8,
    30.81
  ],
  [
    7434,
    63.96168,
    11.41176,
    31.1,
    30.96
  ],
  [
    7436,
    63.96224,
    11.41157,
    31.2,
    31.14
  ],
  [
    7438,
    63.9628,
    11.41142,
    31.1,
    31.34
  ],
  [
    7440,
    63.96336,
    11.41139,
    31,
    31.54
  ],
  [
    7442,
    63.96393,
    11.4115,
    30.9,
    31.8
  ],
  [
    7444,
    63.9645,
    11.41173,
    30.7,
    32.06
  ],
  [
    7446,
    63.96506,
    11.41203,
    30.6,
    32.28
  ],
  [
    7448,
    63.96563,
    11.41236,
    30.4,
    32.55
  ],
  [
    7450,
    63.9662,
    11.41272,
    30.2,
    32.82
  ],
  [
    7452,
    63.96677,
    11.41312,
    30,
    33.06
  ],
  [
    7454,
    63.96733,
    11.41354,
    29.8,
    33.34
  ],
  [
    7456,
    63.9679,
    11.41401,
    29.6,
    33.59
  ],
  [
    7458,
    63.96847,
    11.4145,
    29.5,
    33.78
  ],
  [
    7460,
    63.96903,
    11.41502,
    29.4,
    33.8
  ],
  [
    7462,
    63.96959,
    11.41554,
    29.4,
    33.74
  ],
  [
    7464,
    63.97015,
    11.41607,
    29.4,
    33.72
  ],
  [
    7466,
    63.97071,
    11.41659,
    29.4,
    33.72
  ],
  [
    7468,
    63.97126,
    11.41712,
    29.5,
    33.5
  ],
  [
    7470,
    63.97182,
    11.41764,
    29.4,
    33.47
  ],
  [
    7472,
    63.97237,
    11.41816,
    29.1,
    33.47
  ],
  [
    7474,
    63.97292,
    11.41868,
    28.6,
    33.33
  ],
  [
    7476,
    63.97348,
    11.4192,
    28,
    33.33
  ],
  [
    7478,
    63.97403,
    11.41971,
    27.5,
    33.25
  ],
  [
    7480,
    63.97457,
    11.42022,
    27,
    33.25
  ],
  [
    7482,
    63.9751,
    11.42072,
    26.4,
    33.25
  ],
  [
    7484,
    63.97562,
    11.42121,
    25.8,
    31.83
  ],
  [
    7486,
    63.97613,
    11.42168,
    25.3,
    31.83
  ],
  [
    7488,
    63.97663,
    11.42212,
    24.8,
    29.91
  ],
  [
    7490,
    63.97713,
    11.42251,
    24.4,
    28.67
  ],
  [
    7492,
    63.97761,
    11.42286,
    24.1,
    28.22
  ],
  [
    7494,
    63.97808,
    11.42316,
    23.9,
    26.95
  ],
  [
    7496,
    63.97854,
    11.42343,
    23.7,
    26.18
  ],
  [
    7498,
    63.97899,
    11.42368,
    23.6,
    25.79
  ],
  [
    7500,
    63.97944,
    11.42391,
    23.4,
    25.7
  ],
  [
    7502,
    63.97988,
    11.42414,
    23.2,
    25.56
  ],
  [
    7504,
    63.98033,
    11.42437,
    23.1,
    25.49
  ],
  [
    7506,
    63.98077,
    11.42464,
    23,
    25.49
  ],
  [
    7508,
    63.98119,
    11.425,
    22.8,
    25.52
  ],
  [
    7510,
    63.9816,
    11.42549,
    22.5,
    25.55
  ],
  [
    7512,
    63.98197,
    11.42609,
    22.2,
    25.57
  ],
  [
    7514,
    63.98231,
    11.42679,
    21.9,
    25.57
  ],
  [
    7516,
    63.98263,
    11.42753,
    21.7,
    25.6
  ],
  [
    7518,
    63.98295,
    11.42831,
    21.1,
    25.67
  ],
  [
    7520,
    63.98324,
    11.4291,
    20.5,
    25.74
  ],
  [
    7522,
    63.98353,
    11.42993,
    20.1,
    25.77
  ],
  [
    7524,
    63.9838,
    11.43078,
    19.7,
    25.82
  ],
  [
    7526,
    63.98404,
    11.43169,
    19.5,
    25.86
  ],
  [
    7528,
    63.98423,
    11.43265,
    19,
    25.85
  ],
  [
    7530,
    63.98439,
    11.43365,
    18.6,
    25.82
  ],
  [
    7532,
    63.98452,
    11.43466,
    18.2,
    25.8
  ],
  [
    7534,
    63.98468,
    11.43565,
    18.1,
    25.78
  ],
  [
    7536,
    63.9849,
    11.43658,
    18,
    25.78
  ],
  [
    7538,
    63.98517,
    11.43744,
    17.7,
    25.78
  ],
  [
    7540,
    63.98548,
    11.4382,
    17.4,
    25.78
  ],
  [
    7542,
    63.98583,
    11.43888,
    17,
    25.79
  ],
  [
    7544,
    63.9862,
    11.43953,
    16.7,
    25.82
  ],
  [
    7546,
    63.98656,
    11.44018,
    16.4,
    25.86
  ],
  [
    7548,
    63.98693,
    11.44083,
    16.2,
    25.87
  ],
  [
    7551,
    63.98746,
    11.44185,
    15.7,
    25.93
  ],
  [
    7553,
    63.9878,
    11.44258,
    15.4,
    25.94
  ],
  [
    7555,
    63.98811,
    11.44335,
    14.9,
    26.02
  ],
  [
    7557,
    63.98842,
    11.44417,
    14.5,
    26.12
  ],
  [
    7559,
    63.98871,
    11.44501,
    14.2,
    26.21
  ],
  [
    7561,
    63.98899,
    11.44587,
    14,
    26.3
  ],
  [
    7563,
    63.98928,
    11.44672,
    13.7,
    26.38
  ],
  [
    7565,
    63.98957,
    11.44756,
    13.5,
    26.43
  ],
  [
    7567,
    63.98988,
    11.44839,
    13.4,
    26.43
  ],
  [
    7569,
    63.99019,
    11.4492,
    13.3,
    26.41
  ],
  [
    7571,
    63.99051,
    11.45,
    13.2,
    26.34
  ],
  [
    7573,
    63.99082,
    11.45079,
    13.1,
    26.28
  ],
  [
    7575,
    63.99113,
    11.4516,
    13,
    26.22
  ],
  [
    7577,
    63.99143,
    11.45242,
    12.9,
    26.15
  ],
  [
    7580,
    63.99185,
    11.45369,
    12.8,
    26.06
  ],
  [
    7582,
    63.99212,
    11.45454,
    12.6,
    25.88
  ],
  [
    7584,
    63.99237,
    11.45539,
    12.5,
    25.16
  ],
  [
    7586,
    63.99261,
    11.45623,
    12.4,
    24.46
  ],
  [
    7588,
    63.99284,
    11.45705,
    12.4,
    23.77
  ],
  [
    7590,
    63.99307,
    11.45785,
    12.5,
    23.39
  ],
  [
    7592,
    63.99333,
    11.45858,
    12.7,
    23.12
  ],
  [
    7594,
    63.99362,
    11.45923,
    12.8,
    22.96
  ],
  [
    7596,
    63.99394,
    11.4598,
    12.8,
    22.82
  ],
  [
    7598,
    63.99429,
    11.46029,
    12.9,
    22.77
  ],
  [
    7600,
    63.99464,
    11.46076,
    12.8,
    22.75
  ],
  [
    7602,
    63.99499,
    11.46123,
    12.7,
    22.75
  ],
  [
    7604,
    63.99534,
    11.4617,
    12.8,
    22.73
  ],
  [
    7606,
    63.99567,
    11.46223,
    13,
    22.72
  ],
  [
    7608,
    63.99597,
    11.46285,
    13.1,
    22.69
  ],
  [
    7610,
    63.99623,
    11.46357,
    13,
    22.67
  ],
  [
    7612,
    63.99644,
    11.46436,
    12.7,
    22.64
  ],
  [
    7614,
    63.99661,
    11.46519,
    12.4,
    22.64
  ],
  [
    7616,
    63.99678,
    11.46603,
    12.2,
    22.68
  ],
  [
    7618,
    63.99698,
    11.46685,
    12.1,
    22.75
  ],
  [
    7620,
    63.99719,
    11.46764,
    12.2,
    22.85
  ],
  [
    7622,
    63.99743,
    11.4684,
    12.3,
    22.93
  ],
  [
    7624,
    63.99768,
    11.46915,
    12.4,
    23.04
  ],
  [
    7626,
    63.99793,
    11.4699,
    12.5,
    23.12
  ],
  [
    7628,
    63.99819,
    11.47064,
    12.6,
    23.22
  ],
  [
    7630,
    63.99846,
    11.47137,
    12.6,
    23.35
  ],
  [
    7632,
    63.99873,
    11.47209,
    12.7,
    23.57
  ],
  [
    7634,
    63.99901,
    11.47283,
    12.7,
    23.82
  ],
  [
    7636,
    63.9993,
    11.47357,
    12.7,
    24.11
  ],
  [
    7638,
    63.99958,
    11.47432,
    12.7,
    24.41
  ],
  [
    7640,
    63.99987,
    11.47509,
    12.8,
    24.73
  ],
  [
    7642,
    64.00016,
    11.47587,
    12.7,
    25.05
  ],
  [
    7644,
    64.00043,
    11.47671,
    12.7,
    25.41
  ],
  [
    7646,
    64.00068,
    11.47759,
    12.7,
    25.71
  ],
  [
    7648,
    64.0009,
    11.47853,
    12.8,
    25.99
  ],
  [
    7650,
    64.00111,
    11.47949,
    12.7,
    26.29
  ],
  [
    7652,
    64.00131,
    11.48047,
    12.5,
    26.38
  ],
  [
    7654,
    64.00151,
    11.48144,
    12.3,
    26.37
  ],
  [
    7656,
    64.00171,
    11.48242,
    12,
    26.36
  ],
  [
    7658,
    64.00192,
    11.48338,
    11.8,
    26.38
  ],
  [
    7660,
    64.00214,
    11.48434,
    11.6,
    26.39
  ],
  [
    7662,
    64.00236,
    11.48528,
    11.3,
    26.4
  ],
  [
    7664,
    64.00259,
    11.48623,
    11.1,
    26.41
  ],
  [
    7666,
    64.00282,
    11.48717,
    10.8,
    26.41
  ],
  [
    7668,
    64.00305,
    11.48809,
    10.5,
    25.57
  ],
  [
    7670,
    64.00327,
    11.48899,
    10.3,
    24.89
  ],
  [
    7672,
    64.00348,
    11.48987,
    10,
    24.51
  ],
  [
    7674,
    64.00369,
    11.49071,
    9.7,
    23.21
  ],
  [
    7676,
    64.00388,
    11.49152,
    9.4,
    22.17
  ],
  [
    7678,
    64.00407,
    11.49229,
    9.2,
    21.05
  ],
  [
    7680,
    64.00425,
    11.49302,
    9,
    19.88
  ],
  [
    7682,
    64.00442,
    11.4937,
    8.8,
    19.34
  ],
  [
    7684,
    64.00458,
    11.49436,
    8.7,
    18.01
  ],
  [
    7686,
    64.00473,
    11.49498,
    8.6,
    17.27
  ],
  [
    7688,
    64.00488,
    11.49558,
    8.5,
    16.54
  ],
  [
    7690,
    64.00502,
    11.49615,
    8.5,
    15.96
  ],
  [
    7692,
    64.00518,
    11.49668,
    8.4,
    15.8
  ],
  [
    7694,
    64.00536,
    11.49717,
    8.3,
    15.58
  ],
  [
    7696,
    64.00556,
    11.4976,
    8.2,
    15.53
  ],
  [
    7698,
    64.00578,
    11.49799,
    8.2,
    15.53
  ],
  [
    7700,
    64.006,
    11.49836,
    8.3,
    15.55
  ],
  [
    7702,
    64.00623,
    11.49873,
    8.4,
    15.52
  ],
  [
    7704,
    64.00645,
    11.4991,
    8.4,
    15.49
  ],
  [
    7706,
    64.00668,
    11.49944,
    8.4,
    15.43
  ],
  [
    7708,
    64.00693,
    11.49972,
    8.4,
    15.38
  ],
  [
    7710,
    64.00719,
    11.49993,
    8.4,
    15.35
  ],
  [
    7712,
    64.00746,
    11.50007,
    8.3,
    15.32
  ],
  [
    7714,
    64.00773,
    11.50013,
    8.2,
    15.28
  ],
  [
    7716,
    64.008,
    11.50013,
    8,
    15.28
  ],
  [
    7718,
    64.00828,
    11.5001,
    7.8,
    15.29
  ],
  [
    7720,
    64.00855,
    11.50001,
    7.7,
    15.29
  ],
  [
    7722,
    64.00881,
    11.49986,
    7.6,
    15.32
  ],
  [
    7724,
    64.00907,
    11.49963,
    6,
    14.68
  ],
  [
    7726,
    64.00931,
    11.49931,
    5.6,
    15.27
  ],
  [
    7728,
    64.00954,
    11.49896,
    5.3,
    15.25
  ],
  [
    7730,
    64.00977,
    11.49861,
    5,
    15.29
  ],
  [
    7732,
    64.01,
    11.49826,
    4.9,
    15.28
  ],
  [
    7734,
    64.01023,
    11.49792,
    4.9,
    15.26
  ],
  [
    7736,
    64.01046,
    11.49757,
    4.9,
    15.24
  ],
  [
    7738,
    64.01069,
    11.49723,
    5,
    14.79
  ],
  [
    7740,
    64.01091,
    11.4969,
    4.9,
    14.2
  ],
  [
    7742,
    64.01112,
    11.49658,
    4.9,
    13.97
  ],
  [
    7744,
    64.01132,
    11.49628,
    5,
    13.35
  ],
  [
    7746,
    64.01152,
    11.49598,
    5,
    12.97
  ],
  [
    7748,
    64.01171,
    11.4957,
    5,
    12.56
  ],
  [
    7750,
    64.0119,
    11.49542,
    4.9,
    12.15
  ],
  [
    7752,
    64.01208,
    11.49516,
    4.7,
    11.91
  ],
  [
    7754,
    64.01225,
    11.4949,
    4.5,
    11.29
  ],
  [
    7756,
    64.01242,
    11.49465,
    4.5,
    10.73
  ],
  [
    7758,
    64.01257,
    11.49442,
    4.6,
    9.76
  ],
  [
    7760,
    64.01271,
    11.49422,
    4.8,
    8.47
  ],
  [
    7762,
    64.01283,
    11.49405,
    4.9,
    7.8
  ],
  [
    7764,
    64.01292,
    11.4939,
    5,
    5.77
  ],
  [
    7766,
    64.013,
    11.49379,
    5.1,
    4.49
  ],
  [
    7768,
    64.01306,
    11.4937,
    5.3,
    3.35
  ],
  [
    7770,
    64.0131,
    11.49364,
    5.4,
    2.08
  ],
  [
    7772,
    64.01312,
    11.49361,
    5.5,
    1.34
  ],
  [
    7774,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7776,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7778,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7780,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7782,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7784,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7786,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7788,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7790,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7792,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7794,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7796,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7798,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7800,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7802,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7804,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7806,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7808,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7810,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7812,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7814,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7816,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7818,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7820,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7822,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7824,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7826,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7828,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7830,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7832,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7834,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7836,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7838,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7840,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7842,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7844,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7846,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7848,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7850,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7852,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7854,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7856,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7858,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7860,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7862,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7864,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7866,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7868,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7870,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7872,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7874,
    64.01312,
    11.49361,
    5.6,
    0
  ],
  [
    7876,
    64.01313,
    11.49353,
    6.7,
    1.66
  ],
  [
    7878,
    64.01316,
    11.49349,
    6.7,
    2.39
  ],
  [
    7880,
    64.0132,
    11.49343,
    6.7,
    3.06
  ],
  [
    7882,
    64.01325,
    11.49335,
    6.7,
    3.4
  ],
  [
    7884,
    64.01331,
    11.49326,
    6.6,
    4.39
  ],
  [
    7886,
    64.01338,
    11.49316,
    6.7,
    4.97
  ],
  [
    7888,
    64.01346,
    11.49305,
    6.7,
    5.53
  ],
  [
    7890,
    64.01355,
    11.49292,
    6.8,
    6.06
  ],
  [
    7892,
    64.01365,
    11.49278,
    6.8,
    6.34
  ],
  [
    7894,
    64.01375,
    11.49263,
    6.7,
    7.12
  ],
  [
    7896,
    64.01386,
    11.49246,
    6.6,
    7.62
  ],
  [
    7898,
    64.01398,
    11.49229,
    6.5,
    8.12
  ],
  [
    7900,
    64.01411,
    11.49211,
    6.5,
    8.67
  ],
  [
    7902,
    64.01425,
    11.49192,
    6.7,
    8.93
  ],
  [
    7904,
    64.0144,
    11.49175,
    7,
    9.68
  ],
  [
    7906,
    64.01457,
    11.4916,
    7.4,
    10.08
  ],
  [
    7908,
    64.01475,
    11.49147,
    7.8,
    10.53
  ],
  [
    7910,
    64.01493,
    11.49137,
    8.3,
    10.88
  ],
  [
    7912,
    64.01513,
    11.49129,
    8.9,
    11.12
  ],
  [
    7914,
    64.01534,
    11.49124,
    9.5,
    11.83
  ],
  [
    7916,
    64.01556,
    11.49122,
    10.1,
    12.27
  ],
  [
    7918,
    64.01578,
    11.49126,
    10.7,
    12.69
  ],
  [
    7920,
    64.01601,
    11.49135,
    11.2,
    13.15
  ],
  [
    7922,
    64.01625,
    11.49147,
    11.5,
    13.3
  ],
  [
    7924,
    64.01649,
    11.49159,
    11.7,
    14.1
  ],
  [
    7926,
    64.01674,
    11.49172,
    12.1,
    14.33
  ],
  [
    7928,
    64.017,
    11.49186,
    12.6,
    14.5
  ],
  [
    7930,
    64.01725,
    11.49199,
    12.7,
    14.6
  ],
  [
    7932,
    64.01751,
    11.49212,
    12.9,
    14.74
  ],
  [
    7934,
    64.01777,
    11.49227,
    13.1,
    15.05
  ],
  [
    7936,
    64.01804,
    11.49243,
    13.3,
    15.33
  ],
  [
    7938,
    64.0183,
    11.49261,
    13.5,
    15.56
  ],
  [
    7940,
    64.01857,
    11.49281,
    13.8,
    15.81
  ],
  [
    7942,
    64.01884,
    11.49302,
    14,
    15.97
  ],
  [
    7944,
    64.01912,
    11.49326,
    14.1,
    16.43
  ],
  [
    7946,
    64.01939,
    11.49352,
    14.1,
    16.8
  ],
  [
    7948,
    64.01967,
    11.4938,
    14.3,
    17.2
  ],
  [
    7950,
    64.01996,
    11.4941,
    14.7,
    17.56
  ],
  [
    7952,
    64.02024,
    11.49443,
    15.4,
    17.72
  ],
  [
    7954,
    64.02053,
    11.49477,
    16.2,
    18.19
  ],
  [
    7956,
    64.02082,
    11.49512,
    17.1,
    18.4
  ],
  [
    7958,
    64.02111,
    11.49548,
    17.9,
    18.52
  ],
  [
    7960,
    64.02141,
    11.49586,
    18.7,
    18.63
  ],
  [
    7962,
    64.0217,
    11.49624,
    19.8,
    18.68
  ],
  [
    7964,
    64.02199,
    11.49663,
    21.3,
    18.84
  ],
  [
    7966,
    64.02228,
    11.49704,
    22.3,
    18.89
  ],
  [
    7968,
    64.02257,
    11.49745,
    23,
    18.94
  ],
  [
    7970,
    64.02285,
    11.49788,
    23.8,
    19.05
  ],
  [
    7972,
    64.02314,
    11.49831,
    24.5,
    19.11
  ],
  [
    7974,
    64.02342,
    11.49876,
    24.6,
    19.28
  ],
  [
    7976,
    64.0237,
    11.49922,
    24.9,
    19.51
  ],
  [
    7978,
    64.02398,
    11.49972,
    24.7,
    19.82
  ],
  [
    7980,
    64.02426,
    11.50023,
    24.2,
    20.1
  ],
  [
    7982,
    64.02454,
    11.50075,
    23.6,
    20.29
  ],
  [
    7984,
    64.02483,
    11.50129,
    23,
    20.94
  ],
  [
    7986,
    64.02513,
    11.50184,
    22.5,
    21.45
  ],
  [
    7988,
    64.02543,
    11.50239,
    22.2,
    21.93
  ],
  [
    7990,
    64.02574,
    11.50296,
    21.7,
    22.39
  ],
  [
    7992,
    64.02606,
    11.50355,
    21.2,
    22.65
  ],
  [
    7994,
    64.02638,
    11.50415,
    20.8,
    23.37
  ],
  [
    7996,
    64.02671,
    11.50475,
    20.4,
    23.84
  ],
  [
    7998,
    64.02705,
    11.50537,
    20,
    24.3
  ],
  [
    8000,
    64.0274,
    11.50601,
    19.5,
    24.75
  ],
  [
    8002,
    64.02774,
    11.50665,
    19.1,
    24.98
  ],
  [
    8004,
    64.0281,
    11.5073,
    18.7,
    25.62
  ],
  [
    8006,
    64.02846,
    11.50797,
    18.3,
    26.05
  ],
  [
    8008,
    64.02883,
    11.50864,
    18,
    26.48
  ],
  [
    8010,
    64.0292,
    11.50933,
    17.7,
    26.88
  ],
  [
    8012,
    64.02958,
    11.51003,
    17.5,
    27.13
  ],
  [
    8014,
    64.02997,
    11.51073,
    17.3,
    27.67
  ],
  [
    8016,
    64.03036,
    11.51145,
    17.2,
    28.01
  ],
  [
    8018,
    64.03075,
    11.51219,
    17,
    28.34
  ],
  [
    8020,
    64.03112,
    11.51298,
    16.9,
    28.45
  ],
  [
    8022,
    64.03147,
    11.51384,
    16.8,
    28.48
  ],
  [
    8024,
    64.03179,
    11.51475,
    16.8,
    28.47
  ],
  [
    8026,
    64.03208,
    11.51571,
    16.9,
    28.48
  ],
  [
    8028,
    64.03235,
    11.5167,
    17.1,
    28.42
  ],
  [
    8030,
    64.03261,
    11.51771,
    17.2,
    28.39
  ],
  [
    8032,
    64.03285,
    11.51872,
    17.3,
    28.36
  ],
  [
    8034,
    64.03309,
    11.51974,
    17.5,
    28.22
  ],
  [
    8036,
    64.03332,
    11.52077,
    17.9,
    28.09
  ],
  [
    8038,
    64.03354,
    11.5218,
    18.4,
    27.92
  ],
  [
    8040,
    64.03375,
    11.52284,
    19,
    27.72
  ],
  [
    8042,
    64.03395,
    11.52388,
    19.5,
    27.61
  ],
  [
    8044,
    64.03414,
    11.52492,
    20,
    27.29
  ],
  [
    8046,
    64.03431,
    11.52595,
    20.6,
    27.04
  ],
  [
    8048,
    64.03448,
    11.52699,
    21.1,
    26.8
  ],
  [
    8050,
    64.03464,
    11.52802,
    21.6,
    26.55
  ],
  [
    8052,
    64.03478,
    11.52905,
    22.2,
    26.42
  ],
  [
    8054,
    64.03492,
    11.53008,
    22.6,
    26.04
  ],
  [
    8056,
    64.03505,
    11.5311,
    22.7,
    25.81
  ],
  [
    8058,
    64.03516,
    11.53212,
    22.7,
    25.66
  ],
  [
    8060,
    64.03527,
    11.53314,
    22.4,
    25.56
  ],
  [
    8062,
    64.03537,
    11.53415,
    22.1,
    25.45
  ],
  [
    8064,
    64.03546,
    11.53515,
    21.7,
    24.62
  ],
  [
    8066,
    64.03554,
    11.53613,
    21.4,
    24.13
  ],
  [
    8068,
    64.03561,
    11.53709,
    21.1,
    23.65
  ],
  [
    8070,
    64.03568,
    11.53804,
    20.9,
    23.48
  ],
  [
    8072,
    64.03575,
    11.53899,
    21,
    23.41
  ],
  [
    8074,
    64.03582,
    11.53993,
    21.2,
    23.26
  ],
  [
    8076,
    64.03589,
    11.54086,
    21.6,
    23.12
  ],
  [
    8078,
    64.03596,
    11.54179,
    22,
    22.91
  ],
  [
    8080,
    64.03603,
    11.54271,
    22.4,
    22.69
  ],
  [
    8082,
    64.0361,
    11.54362,
    22.7,
    22.57
  ],
  [
    8084,
    64.03619,
    11.54451,
    23.1,
    22.19
  ],
  [
    8086,
    64.03634,
    11.54534,
    23.6,
    22.18
  ],
  [
    8088,
    64.03654,
    11.54612,
    23.9,
    21.94
  ],
  [
    8090,
    64.03679,
    11.5468,
    24.3,
    21.83
  ],
  [
    8092,
    64.03708,
    11.54741,
    24.5,
    21.8
  ],
  [
    8094,
    64.03738,
    11.54797,
    24.9,
    21.79
  ],
  [
    8096,
    64.03769,
    11.54852,
    25.3,
    21.79
  ],
  [
    8098,
    64.03799,
    11.54908,
    25.9,
    21.95
  ],
  [
    8100,
    64.0383,
    11.54964,
    26.3,
    21.92
  ],
  [
    8102,
    64.03861,
    11.5502,
    26.8,
    21.99
  ],
  [
    8104,
    64.03891,
    11.5508,
    27.2,
    22.25
  ],
  [
    8106,
    64.03917,
    11.55148,
    27.6,
    22.39
  ],
  [
    8108,
    64.0394,
    11.55223,
    28.1,
    22.56
  ],
  [
    8110,
    64.03959,
    11.55305,
    28.5,
    22.76
  ],
  [
    8112,
    64.03974,
    11.55392,
    28.7,
    22.84
  ],
  [
    8114,
    64.03986,
    11.55481,
    28.7,
    23.18
  ],
  [
    8116,
    64.03998,
    11.55573,
    28.6,
    23.47
  ],
  [
    8118,
    64.04009,
    11.55666,
    28.6,
    23.81
  ],
  [
    8120,
    64.04019,
    11.55762,
    28.6,
    24.15
  ],
  [
    8122,
    64.04029,
    11.55859,
    28.7,
    24.31
  ],
  [
    8124,
    64.04037,
    11.55958,
    28.8,
    24.78
  ],
  [
    8126,
    64.04044,
    11.56059,
    29,
    25.06
  ],
  [
    8128,
    64.04048,
    11.56161,
    29.2,
    25.29
  ],
  [
    8130,
    64.04049,
    11.56265,
    29.5,
    25.4
  ],
  [
    8132,
    64.04048,
    11.5637,
    29.8,
    25.45
  ],
  [
    8134,
    64.04047,
    11.56474,
    30.1,
    25.54
  ],
  [
    8136,
    64.04047,
    11.56579,
    30.4,
    25.57
  ],
  [
    8138,
    64.04046,
    11.56684,
    30.9,
    25.57
  ],
  [
    8140,
    64.04046,
    11.56788,
    31.3,
    25.56
  ],
  [
    8142,
    64.0405,
    11.56893,
    31.6,
    25.51
  ],
  [
    8144,
    64.04059,
    11.56995,
    32.1,
    25.46
  ],
  [
    8146,
    64.04074,
    11.57093,
    32.5,
    25.46
  ],
  [
    8148,
    64.04094,
    11.57187,
    32.9,
    25.5
  ],
  [
    8150,
    64.04115,
    11.57279,
    33.2,
    25.55
  ],
  [
    8152,
    64.04137,
    11.57371,
    33.5,
    25.57
  ],
  [
    8154,
    64.0416,
    11.57463,
    33.8,
    25.7
  ],
  [
    8156,
    64.04183,
    11.57554,
    34.1,
    25.73
  ],
  [
    8158,
    64.04207,
    11.57643,
    34.3,
    25.81
  ],
  [
    8160,
    64.04233,
    11.57731,
    34.4,
    25.9
  ],
  [
    8162,
    64.04261,
    11.57816,
    34.4,
    25.95
  ],
  [
    8164,
    64.04291,
    11.57898,
    34.3,
    26.13
  ],
  [
    8166,
    64.04322,
    11.57978,
    34.3,
    26.25
  ],
  [
    8168,
    64.04355,
    11.58055,
    34.2,
    26.39
  ],
  [
    8170,
    64.0439,
    11.5813,
    34.2,
    26.51
  ],
  [
    8172,
    64.04426,
    11.58201,
    34.2,
    26.63
  ],
  [
    8174,
    64.04464,
    11.58268,
    34.2,
    26.76
  ],
  [
    8176,
    64.04503,
    11.58332,
    34.2,
    26.82
  ],
  [
    8178,
    64.04543,
    11.58392,
    34.2,
    26.92
  ],
  [
    8180,
    64.04585,
    11.58447,
    34.1,
    26.91
  ],
  [
    8182,
    64.04628,
    11.58499,
    34,
    26.9
  ],
  [
    8184,
    64.04671,
    11.58548,
    33.8,
    26.96
  ],
  [
    8186,
    64.04714,
    11.58597,
    33.7,
    26.96
  ],
  [
    8188,
    64.04758,
    11.58646,
    33.7,
    26.9
  ],
  [
    8190,
    64.04801,
    11.58694,
    33.6,
    26.84
  ],
  [
    8192,
    64.04844,
    11.58743,
    33.6,
    26.77
  ],
  [
    8194,
    64.04887,
    11.58792,
    33.7,
    26.7
  ],
  [
    8196,
    64.0493,
    11.5884,
    33.8,
    26.6
  ],
  [
    8198,
    64.04972,
    11.58889,
    34,
    26.51
  ],
  [
    8200,
    64.05014,
    11.58942,
    34.1,
    26.43
  ],
  [
    8202,
    64.05054,
    11.59001,
    34.2,
    26.33
  ],
  [
    8204,
    64.05091,
    11.59066,
    34.4,
    26.21
  ],
  [
    8206,
    64.05126,
    11.59137,
    34.7,
    26.08
  ],
  [
    8208,
    64.05158,
    11.59214,
    35.1,
    25.58
  ],
  [
    8210,
    64.05187,
    11.59293,
    35.7,
    24.85
  ],
  [
    8212,
    64.05212,
    11.59375,
    36.3,
    24.12
  ],
  [
    8214,
    64.05234,
    11.59458,
    36.9,
    23.48
  ],
  [
    8216,
    64.05253,
    11.59542,
    37.7,
    23.05
  ],
  [
    8218,
    64.0527,
    11.59628,
    38.6,
    22.67
  ],
  [
    8220,
    64.05284,
    11.59714,
    39.5,
    22.32
  ],
  [
    8222,
    64.05297,
    11.59799,
    40.1,
    21.99
  ],
  [
    8224,
    64.05312,
    11.59882,
    40.4,
    21.71
  ],
  [
    8226,
    64.05332,
    11.59957,
    40.3,
    21.49
  ],
  [
    8228,
    64.05356,
    11.60025,
    40,
    21.4
  ],
  [
    8230,
    64.05384,
    11.60085,
    39.5,
    21.44
  ],
  [
    8232,
    64.05414,
    11.60139,
    38.9,
    21.54
  ],
  [
    8234,
    64.05445,
    11.60192,
    38.3,
    21.71
  ],
  [
    8236,
    64.05477,
    11.60243,
    37.6,
    21.88
  ],
  [
    8238,
    64.05509,
    11.60294,
    37,
    22.09
  ],
  [
    8240,
    64.05543,
    11.60342,
    36.4,
    22.14
  ],
  [
    8242,
    64.05578,
    11.6038,
    35.9,
    21.69
  ],
  [
    8244,
    64.05615,
    11.60405,
    35.5,
    21.39
  ],
  [
    8246,
    64.05653,
    11.60419,
    35.4,
    21.07
  ],
  [
    8248,
    64.0569,
    11.6042,
    35,
    20.75
  ],
  [
    8250,
    64.05727,
    11.60409,
    33.8,
    20.84
  ],
  [
    8252,
    64.05763,
    11.60386,
    32.7,
    20.79
  ],
  [
    8254,
    64.05798,
    11.6035,
    32.5,
    20.93
  ],
  [
    8256,
    64.0583,
    11.60303,
    31.9,
    21.04
  ],
  [
    8258,
    64.0586,
    11.60249,
    31.4,
    21.21
  ],
  [
    8260,
    64.05891,
    11.60195,
    30.9,
    21.41
  ],
  [
    8262,
    64.05924,
    11.60148,
    30.6,
    21.55
  ],
  [
    8264,
    64.0596,
    11.60112,
    30.4,
    21.67
  ],
  [
    8266,
    64.05998,
    11.60089,
    30.3,
    21.76
  ],
  [
    8268,
    64.06036,
    11.60079,
    30.3,
    21.77
  ],
  [
    8270,
    64.06075,
    11.60082,
    30.3,
    21.76
  ],
  [
    8272,
    64.06114,
    11.60099,
    30.4,
    21.72
  ],
  [
    8274,
    64.0615,
    11.60128,
    30.5,
    21.76
  ],
  [
    8276,
    64.06185,
    11.60168,
    30.5,
    21.79
  ],
  [
    8278,
    64.06219,
    11.60213,
    30.6,
    21.84
  ],
  [
    8280,
    64.06252,
    11.60262,
    30.8,
    21.95
  ],
  [
    8282,
    64.06284,
    11.60316,
    30.8,
    22.06
  ],
  [
    8284,
    64.06315,
    11.60374,
    31.1,
    22.21
  ],
  [
    8286,
    64.06345,
    11.60434,
    31.2,
    22.34
  ],
  [
    8288,
    64.06376,
    11.60493,
    31.3,
    22.56
  ],
  [
    8290,
    64.06408,
    11.6055,
    31.4,
    22.83
  ],
  [
    8292,
    64.06442,
    11.60603,
    31.5,
    23.02
  ],
  [
    8294,
    64.06477,
    11.60652,
    31.6,
    23.08
  ],
  [
    8296,
    64.06513,
    11.60701,
    31.7,
    23.07
  ],
  [
    8298,
    64.06547,
    11.60753,
    31.8,
    23.08
  ],
  [
    8300,
    64.06579,
    11.60812,
    32,
    23.03
  ],
  [
    8302,
    64.0661,
    11.60876,
    32.1,
    23
  ],
  [
    8304,
    64.06637,
    11.60946,
    32.3,
    22.92
  ],
  [
    8306,
    64.06662,
    11.61022,
    32.5,
    22.86
  ],
  [
    8308,
    64.06683,
    11.61101,
    32.7,
    22.79
  ],
  [
    8310,
    64.06702,
    11.61184,
    32.9,
    22.71
  ],
  [
    8312,
    64.06719,
    11.61268,
    33.1,
    22.61
  ],
  [
    8314,
    64.06736,
    11.61353,
    33.3,
    22.53
  ],
  [
    8316,
    64.06753,
    11.61436,
    33.4,
    22.45
  ],
  [
    8318,
    64.06773,
    11.61516,
    33.6,
    22.36
  ],
  [
    8320,
    64.06798,
    11.61587,
    33.6,
    22.27
  ],
  [
    8322,
    64.06827,
    11.61649,
    33.3,
    22.19
  ],
  [
    8324,
    64.0686,
    11.617,
    33.4,
    22.02
  ],
  [
    8326,
    64.06895,
    11.6174,
    33.7,
    21.94
  ],
  [
    8328,
    64.06933,
    11.61767,
    33.5,
    22.32
  ],
  [
    8330,
    64.06972,
    11.61785,
    32.9,
    22.33
  ],
  [
    8332,
    64.07012,
    11.61802,
    32.4,
    22.54
  ],
  [
    8334,
    64.07052,
    11.61819,
    32,
    22.74
  ],
  [
    8336,
    64.07092,
    11.61838,
    31.6,
    22.96
  ],
  [
    8338,
    64.07132,
    11.61863,
    31.2,
    23.18
  ],
  [
    8340,
    64.07171,
    11.61897,
    31,
    23.37
  ],
  [
    8342,
    64.07209,
    11.61939,
    30.8,
    23.6
  ],
  [
    8344,
    64.07245,
    11.61991,
    30.7,
    23.83
  ],
  [
    8346,
    64.0728,
    11.62048,
    30.7,
    24.06
  ],
  [
    8348,
    64.07315,
    11.62108,
    30.8,
    24.25
  ],
  [
    8350,
    64.0735,
    11.62168,
    31.2,
    24.47
  ],
  [
    8352,
    64.07386,
    11.62226,
    31.6,
    24.69
  ],
  [
    8354,
    64.07422,
    11.62284,
    32.1,
    24.79
  ],
  [
    8356,
    64.07459,
    11.6234,
    32.6,
    24.63
  ],
  [
    8358,
    64.07496,
    11.62396,
    33.2,
    24.43
  ],
  [
    8360,
    64.07532,
    11.6245,
    33.7,
    24.14
  ],
  [
    8362,
    64.07568,
    11.62504,
    34.3,
    23.87
  ],
  [
    8364,
    64.07604,
    11.62557,
    34.9,
    23.58
  ],
  [
    8366,
    64.07639,
    11.6261,
    35.5,
    23.29
  ],
  [
    8368,
    64.07674,
    11.62662,
    36.2,
    23.03
  ],
  [
    8370,
    64.07708,
    11.62713,
    36.9,
    22.71
  ],
  [
    8372,
    64.07742,
    11.62764,
    37.5,
    22.38
  ],
  [
    8374,
    64.07775,
    11.62814,
    38,
    22.06
  ],
  [
    8376,
    64.07808,
    11.62863,
    38.2,
    21.64
  ],
  [
    8378,
    64.0784,
    11.6291,
    38.3,
    20.87
  ],
  [
    8380,
    64.07871,
    11.62956,
    38.3,
    20.28
  ],
  [
    8382,
    64.07901,
    11.63001,
    38.3,
    19.83
  ],
  [
    8384,
    64.0793,
    11.63045,
    38.3,
    19.46
  ],
  [
    8386,
    64.07959,
    11.63088,
    38.3,
    19.01
  ],
  [
    8388,
    64.07986,
    11.6313,
    38.2,
    18.27
  ],
  [
    8390,
    64.08013,
    11.6317,
    38.1,
    17.43
  ],
  [
    8392,
    64.08038,
    11.63208,
    38.1,
    16.44
  ],
  [
    8394,
    64.08062,
    11.63244,
    38,
    15.43
  ],
  [
    8396,
    64.08085,
    11.63277,
    37.9,
    14.6
  ],
  [
    8398,
    64.08106,
    11.63309,
    37.8,
    13.84
  ],
  [
    8400,
    64.08126,
    11.63339,
    37.7,
    13.07
  ],
  [
    8402,
    64.08145,
    11.63366,
    37.5,
    12.31
  ],
  [
    8404,
    64.08163,
    11.63391,
    37.3,
    11.81
  ],
  [
    8406,
    64.08181,
    11.63414,
    37,
    11.61
  ],
  [
    8408,
    64.082,
    11.63436,
    36.7,
    11.54
  ],
  [
    8410,
    64.08218,
    11.63457,
    36.5,
    11.62
  ],
  [
    8412,
    64.08237,
    11.63477,
    36.3,
    11.79
  ],
  [
    8414,
    64.08256,
    11.63497,
    36.1,
    12.01
  ],
  [
    8416,
    64.08276,
    11.63517,
    36.6,
    11.89
  ],
  [
    8418,
    64.08295,
    11.63537,
    36.7,
    12.24
  ],
  [
    8420,
    64.08316,
    11.63556,
    36.6,
    12.36
  ],
  [
    8422,
    64.08336,
    11.63574,
    36.4,
    12.45
  ],
  [
    8424,
    64.08357,
    11.63591,
    36.2,
    12.55
  ],
  [
    8426,
    64.08379,
    11.63607,
    35.9,
    12.66
  ],
  [
    8428,
    64.084,
    11.63621,
    35.7,
    12.74
  ],
  [
    8430,
    64.08423,
    11.63632,
    35.5,
    12.84
  ],
  [
    8432,
    64.08445,
    11.63642,
    35.4,
    12.91
  ],
  [
    8434,
    64.08468,
    11.63649,
    35.4,
    12.94
  ],
  [
    8436,
    64.08491,
    11.63654,
    35.4,
    12.94
  ],
  [
    8438,
    64.08514,
    11.63657,
    35.4,
    12.91
  ],
  [
    8440,
    64.08537,
    11.63657,
    35.5,
    12.93
  ],
  [
    8442,
    64.0856,
    11.63656,
    35.6,
    13.03
  ],
  [
    8444,
    64.08584,
    11.63652,
    35.7,
    13.16
  ],
  [
    8446,
    64.08607,
    11.63646,
    36,
    13.19
  ],
  [
    8448,
    64.08631,
    11.63638,
    36,
    13.46
  ],
  [
    8450,
    64.08655,
    11.6363,
    36.7,
    13.42
  ],
  [
    8452,
    64.08679,
    11.63621,
    37.5,
    13.61
  ],
  [
    8454,
    64.08703,
    11.63612,
    38.4,
    13.99
  ],
  [
    8456,
    64.08728,
    11.63601,
    39,
    14.28
  ],
  [
    8458,
    64.08754,
    11.6359,
    38.8,
    14.85
  ],
  [
    8460,
    64.0878,
    11.63577,
    38.2,
    15.22
  ],
  [
    8462,
    64.08807,
    11.63563,
    37.4,
    15.7
  ],
  [
    8464,
    64.08835,
    11.63547,
    36.5,
    16.23
  ],
  [
    8466,
    64.08864,
    11.6353,
    35.8,
    16.78
  ],
  [
    8468,
    64.08893,
    11.63511,
    35.3,
    17.21
  ],
  [
    8470,
    64.08924,
    11.63489,
    35.1,
    17.72
  ],
  [
    8472,
    64.08954,
    11.63463,
    35,
    18.2
  ],
  [
    8474,
    64.08985,
    11.63433,
    35.1,
    18.56
  ],
  [
    8476,
    64.09015,
    11.63399,
    35.3,
    19.06
  ],
  [
    8478,
    64.09046,
    11.63363,
    36,
    18.88
  ],
  [
    8480,
    64.09074,
    11.63322,
    36.7,
    18.94
  ],
  [
    8482,
    64.09103,
    11.63281,
    37.2,
    18.94
  ],
  [
    8484,
    64.09144,
    11.63267,
    36.8,
    22.6
  ],
  [
    8486,
    64.09183,
    11.63259,
    35.7,
    20.92
  ],
  [
    8488,
    64.09221,
    11.63263,
    35.4,
    21.15
  ],
  [
    8490,
    64.09258,
    11.63272,
    35.5,
    20.64
  ],
  [
    8492,
    64.09295,
    11.63307,
    34.8,
    21.95
  ],
  [
    8494,
    64.09332,
    11.63356,
    35.3,
    22.5
  ],
  [
    8496,
    64.09367,
    11.6341,
    34.7,
    22.87
  ],
  [
    8498,
    64.09401,
    11.63464,
    34.3,
    22.94
  ],
  [
    8500,
    64.09437,
    11.63511,
    34,
    22.94
  ],
  [
    8502,
    64.09476,
    11.63546,
    33.8,
    22.92
  ],
  [
    8504,
    64.09516,
    11.63565,
    33.8,
    22.94
  ],
  [
    8506,
    64.09558,
    11.63571,
    33.9,
    22.94
  ],
  [
    8508,
    64.09599,
    11.63566,
    33.9,
    22.96
  ],
  [
    8510,
    64.0964,
    11.6356,
    33.9,
    22.95
  ],
  [
    8512,
    64.09681,
    11.63565,
    33.9,
    22.96
  ],
  [
    8514,
    64.09722,
    11.63584,
    33.8,
    22.95
  ],
  [
    8516,
    64.0976,
    11.63618,
    33.7,
    22.88
  ],
  [
    8518,
    64.09796,
    11.63664,
    33.6,
    22.87
  ],
  [
    8520,
    64.09828,
    11.63723,
    33.5,
    22.81
  ],
  [
    8522,
    64.09855,
    11.63791,
    33.5,
    22.73
  ],
  [
    8524,
    64.09879,
    11.63868,
    33.7,
    22.69
  ],
  [
    8526,
    64.09901,
    11.63947,
    33.7,
    22.69
  ],
  [
    8528,
    64.09924,
    11.64024,
    33.7,
    22.7
  ],
  [
    8530,
    64.09949,
    11.64097,
    33.7,
    22.73
  ],
  [
    8532,
    64.09977,
    11.64165,
    33.8,
    22.78
  ],
  [
    8534,
    64.10007,
    11.64227,
    34,
    22.88
  ],
  [
    8536,
    64.10041,
    11.64283,
    34.1,
    23
  ],
  [
    8538,
    64.10076,
    11.64331,
    34.4,
    23.16
  ],
  [
    8540,
    64.10114,
    11.64371,
    34.6,
    23.36
  ],
  [
    8542,
    64.10154,
    11.64405,
    34.9,
    23.55
  ],
  [
    8544,
    64.10194,
    11.64432,
    35.3,
    23.8
  ],
  [
    8546,
    64.10236,
    11.64459,
    35.6,
    24.06
  ],
  [
    8548,
    64.10277,
    11.64493,
    36,
    24.3
  ],
  [
    8550,
    64.10316,
    11.64535,
    36.2,
    24.49
  ],
  [
    8552,
    64.10354,
    11.64587,
    36.5,
    24.75
  ],
  [
    8554,
    64.1039,
    11.64648,
    36.6,
    24.98
  ],
  [
    8556,
    64.10423,
    11.64717,
    36.6,
    25.26
  ],
  [
    8558,
    64.10454,
    11.64794,
    36.3,
    25.57
  ],
  [
    8560,
    64.10483,
    11.64876,
    36,
    25.86
  ],
  [
    8562,
    64.10513,
    11.64959,
    35.8,
    26.15
  ],
  [
    8564,
    64.10542,
    11.65043,
    35.7,
    26.42
  ],
  [
    8566,
    64.10572,
    11.65128,
    35.7,
    26.64
  ],
  [
    8568,
    64.10603,
    11.65213,
    35.7,
    26.86
  ],
  [
    8570,
    64.10633,
    11.65299,
    35.8,
    27.08
  ],
  [
    8572,
    64.10664,
    11.65386,
    36,
    27.29
  ],
  [
    8574,
    64.10695,
    11.65473,
    36.1,
    27.47
  ],
  [
    8576,
    64.10727,
    11.6556,
    36.2,
    27.69
  ],
  [
    8578,
    64.1076,
    11.65645,
    36.4,
    27.88
  ],
  [
    8580,
    64.10796,
    11.65726,
    36.4,
    28.09
  ],
  [
    8582,
    64.10833,
    11.65805,
    36.6,
    28.3
  ],
  [
    8584,
    64.10872,
    11.6588,
    36.8,
    28.5
  ],
  [
    8586,
    64.10913,
    11.65951,
    37,
    28.68
  ],
  [
    8588,
    64.10955,
    11.6602,
    37.3,
    28.87
  ],
  [
    8590,
    64.10997,
    11.66088,
    37.6,
    29.02
  ],
  [
    8592,
    64.1104,
    11.66159,
    38,
    29.2
  ],
  [
    8594,
    64.11081,
    11.66234,
    38.3,
    29.39
  ],
  [
    8596,
    64.1112,
    11.66314,
    38.5,
    29.54
  ],
  [
    8598,
    64.11159,
    11.66399,
    38.7,
    29.69
  ],
  [
    8600,
    64.11195,
    11.66488,
    38.9,
    29.93
  ],
  [
    8602,
    64.1123,
    11.66582,
    38.8,
    30.14
  ],
  [
    8604,
    64.11263,
    11.66681,
    38.7,
    30.38
  ],
  [
    8606,
    64.11294,
    11.66784,
    38.3,
    30.68
  ],
  [
    8608,
    64.11324,
    11.66891,
    38,
    30.95
  ],
  [
    8610,
    64.11351,
    11.67003,
    37.8,
    31.22
  ],
  [
    8612,
    64.11376,
    11.67119,
    37.8,
    31.48
  ],
  [
    8614,
    64.11398,
    11.67238,
    37.7,
    31.74
  ],
  [
    8616,
    64.11418,
    11.67361,
    37.5,
    31.93
  ],
  [
    8618,
    64.11436,
    11.67486,
    37.4,
    32.19
  ],
  [
    8620,
    64.11453,
    11.67613,
    37.3,
    32.45
  ],
  [
    8622,
    64.11468,
    11.67743,
    37.3,
    32.69
  ],
  [
    8624,
    64.11481,
    11.67874,
    37.3,
    32.91
  ],
  [
    8626,
    64.11494,
    11.68007,
    37.3,
    33.11
  ],
  [
    8628,
    64.11507,
    11.6814,
    37.3,
    33.32
  ],
  [
    8630,
    64.1152,
    11.68274,
    37.4,
    33.5
  ],
  [
    8632,
    64.11533,
    11.68409,
    37.5,
    33.68
  ],
  [
    8634,
    64.11546,
    11.68545,
    37.6,
    33.85
  ],
  [
    8636,
    64.11559,
    11.68681,
    37.7,
    34
  ],
  [
    8638,
    64.11572,
    11.68818,
    37.9,
    34.05
  ],
  [
    8640,
    64.11585,
    11.68954,
    37.8,
    33.99
  ],
  [
    8642,
    64.11598,
    11.6909,
    37.4,
    33.93
  ],
  [
    8644,
    64.11612,
    11.69226,
    36.9,
    33.93
  ],
  [
    8646,
    64.11625,
    11.69363,
    36.3,
    33.98
  ],
  [
    8648,
    64.11638,
    11.69499,
    35.6,
    34.05
  ],
  [
    8650,
    64.11651,
    11.69636,
    35,
    34.16
  ],
  [
    8652,
    64.11664,
    11.69773,
    34.1,
    34.27
  ],
  [
    8654,
    64.11678,
    11.69911,
    33.3,
    34.37
  ],
  [
    8656,
    64.11691,
    11.70049,
    32.5,
    34.47
  ],
  [
    8658,
    64.11704,
    11.70188,
    31.9,
    34.53
  ],
  [
    8660,
    64.11718,
    11.70326,
    31.7,
    34.58
  ],
  [
    8662,
    64.11731,
    11.70465,
    31.5,
    34.54
  ],
  [
    8664,
    64.11744,
    11.70603,
    31.3,
    34.5
  ],
  [
    8666,
    64.11758,
    11.70742,
    31.2,
    34.43
  ],
  [
    8668,
    64.11771,
    11.7088,
    31.3,
    34.43
  ],
  [
    8670,
    64.11785,
    11.71018,
    31.5,
    34.36
  ],
  [
    8672,
    64.11798,
    11.71155,
    32,
    34.28
  ],
  [
    8674,
    64.11811,
    11.71293,
    32.8,
    34.14
  ],
  [
    8676,
    64.11824,
    11.71429,
    33.4,
    34
  ],
  [
    8678,
    64.11838,
    11.71565,
    34.1,
    33.86
  ],
  [
    8680,
    64.11851,
    11.71701,
    34.8,
    33.73
  ],
  [
    8682,
    64.11864,
    11.71836,
    35.5,
    33.61
  ],
  [
    8684,
    64.11877,
    11.7197,
    36.2,
    33.56
  ],
  [
    8686,
    64.1189,
    11.72104,
    36.9,
    33.51
  ],
  [
    8688,
    64.11903,
    11.72239,
    37.6,
    33.47
  ],
  [
    8690,
    64.11916,
    11.72373,
    38.3,
    33.46
  ],
  [
    8692,
    64.11929,
    11.72507,
    39,
    33.46
  ],
  [
    8694,
    64.11942,
    11.72641,
    39.6,
    33.48
  ],
  [
    8696,
    64.11955,
    11.72775,
    40.3,
    33.48
  ],
  [
    8698,
    64.11968,
    11.72909,
    40.9,
    33.51
  ],
  [
    8700,
    64.11981,
    11.73044,
    41.2,
    33.59
  ],
  [
    8702,
    64.11994,
    11.73178,
    41.4,
    33.66
  ],
  [
    8704,
    64.12007,
    11.73313,
    41.4,
    33.79
  ],
  [
    8706,
    64.1202,
    11.73449,
    41.4,
    33.98
  ],
  [
    8708,
    64.12034,
    11.73586,
    41.4,
    34.15
  ],
  [
    8710,
    64.12047,
    11.73723,
    41.4,
    34.32
  ],
  [
    8712,
    64.1206,
    11.73861,
    41.4,
    34.49
  ],
  [
    8714,
    64.12075,
    11.73998,
    41.5,
    34.64
  ],
  [
    8716,
    64.12094,
    11.74134,
    41.6,
    34.79
  ],
  [
    8718,
    64.12117,
    11.74267,
    41.5,
    34.85
  ],
  [
    8720,
    64.12145,
    11.74396,
    41.5,
    34.93
  ],
  [
    8722,
    64.12174,
    11.74522,
    41.6,
    34.9
  ],
  [
    8724,
    64.12204,
    11.74648,
    42.1,
    34.87
  ],
  [
    8726,
    64.12233,
    11.74774,
    42.8,
    34.77
  ],
  [
    8728,
    64.12263,
    11.74899,
    43.5,
    34.64
  ],
  [
    8730,
    64.12293,
    11.75024,
    44.2,
    34.45
  ],
  [
    8732,
    64.12322,
    11.75148,
    44.9,
    34.31
  ],
  [
    8734,
    64.12351,
    11.75272,
    45.6,
    34.18
  ],
  [
    8736,
    64.1238,
    11.75395,
    46.2,
    34.1
  ],
  [
    8738,
    64.12409,
    11.75518,
    46.8,
    34.05
  ],
  [
    8740,
    64.12438,
    11.75641,
    47.4,
    34.02
  ],
  [
    8742,
    64.12467,
    11.75764,
    48,
    33.99
  ],
  [
    8744,
    64.12496,
    11.75887,
    48.6,
    34.01
  ],
  [
    8746,
    64.12525,
    11.7601,
    49.2,
    34.02
  ],
  [
    8748,
    64.12554,
    11.76133,
    49.5,
    34.09
  ],
  [
    8750,
    64.12583,
    11.76256,
    49.4,
    34.16
  ],
  [
    8752,
    64.12612,
    11.7638,
    49.3,
    34.35
  ],
  [
    8754,
    64.12641,
    11.76504,
    48.8,
    34.6
  ],
  [
    8756,
    64.12671,
    11.76629,
    48.1,
    34.85
  ],
  [
    8758,
    64.12701,
    11.76756,
    47.4,
    34.99
  ],
  [
    8760,
    64.1273,
    11.76882,
    46.7,
    35.06
  ],
  [
    8762,
    64.1276,
    11.77009,
    45.9,
    35.16
  ],
  [
    8764,
    64.1279,
    11.77136,
    45.1,
    35.25
  ],
  [
    8766,
    64.1282,
    11.77263,
    44.4,
    34.9
  ],
  [
    8768,
    64.1285,
    11.77389,
    43.7,
    34.53
  ],
  [
    8770,
    64.12878,
    11.77513,
    43.1,
    34.11
  ],
  [
    8772,
    64.12904,
    11.77639,
    42.7,
    33.61
  ],
  [
    8774,
    64.12926,
    11.77767,
    42.6,
    33.04
  ],
  [
    8776,
    64.12944,
    11.77895,
    42.5,
    32.5
  ],
  [
    8778,
    64.12958,
    11.78023,
    42.4,
    31.89
  ],
  [
    8780,
    64.12968,
    11.7815,
    42.2,
    31.3
  ],
  [
    8782,
    64.12974,
    11.78277,
    42.1,
    30.73
  ],
  [
    8784,
    64.12977,
    11.78402,
    42.1,
    30.18
  ],
  [
    8786,
    64.12976,
    11.78524,
    42.1,
    29.59
  ],
  [
    8788,
    64.12972,
    11.78644,
    42.3,
    29.01
  ],
  [
    8790,
    64.12966,
    11.78761,
    42.3,
    28.54
  ],
  [
    8792,
    64.12956,
    11.78876,
    42.2,
    28.3
  ],
  [
    8794,
    64.12944,
    11.78988,
    42,
    28.17
  ],
  [
    8796,
    64.1293,
    11.79099,
    41.7,
    28.15
  ],
  [
    8798,
    64.12914,
    11.79209,
    41.4,
    28.2
  ],
  [
    8800,
    64.12899,
    11.7932,
    40.9,
    28.24
  ],
  [
    8802,
    64.1289,
    11.79434,
    40.5,
    28.28
  ],
  [
    8804,
    64.12886,
    11.79549,
    40.2,
    28.31
  ],
  [
    8806,
    64.12889,
    11.79665,
    40,
    28.33
  ],
  [
    8808,
    64.12898,
    11.79779,
    39.9,
    28.33
  ],
  [
    8810,
    64.12914,
    11.7989,
    39.8,
    28.28
  ],
  [
    8812,
    64.12935,
    11.79995,
    39.9,
    28.26
  ],
  [
    8814,
    64.1296,
    11.80095,
    39.9,
    28.2
  ],
  [
    8816,
    64.12986,
    11.80195,
    40,
    28.21
  ],
  [
    8818,
    64.13009,
    11.80298,
    39.8,
    28.23
  ],
  [
    8820,
    64.13028,
    11.80405,
    39.5,
    28.26
  ],
  [
    8822,
    64.13044,
    11.80515,
    39.1,
    28.29
  ],
  [
    8824,
    64.13058,
    11.80627,
    38.7,
    28.34
  ],
  [
    8826,
    64.13072,
    11.80738,
    38.3,
    28.41
  ],
  [
    8828,
    64.13091,
    11.80847,
    37.9,
    28.5
  ],
  [
    8830,
    64.13114,
    11.80951,
    37.5,
    28.55
  ],
  [
    8832,
    64.13141,
    11.8105,
    37,
    28.63
  ],
  [
    8834,
    64.13171,
    11.81147,
    36.5,
    28.74
  ],
  [
    8836,
    64.132,
    11.81244,
    36.1,
    28.85
  ],
  [
    8838,
    64.13228,
    11.81344,
    36.1,
    28.93
  ],
  [
    8840,
    64.13253,
    11.81449,
    35.5,
    29.01
  ],
  [
    8842,
    64.13275,
    11.81556,
    35.3,
    29.09
  ],
  [
    8844,
    64.13296,
    11.81666,
    34.6,
    29.16
  ],
  [
    8846,
    64.13316,
    11.81777,
    34.3,
    29.23
  ],
  [
    8848,
    64.13336,
    11.81887,
    33.7,
    29.31
  ],
  [
    8850,
    64.13357,
    11.81998,
    33.7,
    29.36
  ],
  [
    8852,
    64.13379,
    11.82107,
    33.7,
    29.41
  ],
  [
    8854,
    64.13403,
    11.82215,
    33.7,
    29.33
  ],
  [
    8856,
    64.13428,
    11.8232,
    33.7,
    28.78
  ],
  [
    8858,
    64.13452,
    11.82422,
    33.7,
    28.29
  ],
  [
    8860,
    64.13477,
    11.82523,
    33.7,
    27.73
  ],
  [
    8862,
    64.13501,
    11.82621,
    33.7,
    27.04
  ],
  [
    8864,
    64.13524,
    11.82716,
    33.7,
    26.37
  ],
  [
    8866,
    64.13547,
    11.8281,
    33.7,
    25.94
  ],
  [
    8868,
    64.13569,
    11.82903,
    33.7,
    25.78
  ],
  [
    8870,
    64.13592,
    11.82995,
    33.7,
    25.68
  ],
  [
    8872,
    64.13614,
    11.83087,
    33.7,
    25.66
  ],
  [
    8874,
    64.13635,
    11.8318,
    33.7,
    25.65
  ],
  [
    8876,
    64.13652,
    11.83278,
    33.7,
    25.61
  ],
  [
    8878,
    64.13664,
    11.83379,
    31,
    25.59
  ],
  [
    8880,
    64.1367,
    11.83483,
    31,
    25.61
  ],
  [
    8882,
    64.13673,
    11.83588,
    31,
    25.59
  ],
  [
    8884,
    64.13675,
    11.83693,
    31,
    25.6
  ],
  [
    8886,
    64.13677,
    11.83798,
    31,
    25.61
  ],
  [
    8888,
    64.13683,
    11.83902,
    31,
    25.58
  ],
  [
    8890,
    64.1369,
    11.84005,
    31,
    25.56
  ],
  [
    8892,
    64.13701,
    11.84107,
    31,
    25.52
  ],
  [
    8894,
    64.13714,
    11.84208,
    31,
    25.48
  ],
  [
    8896,
    64.13729,
    11.84306,
    30.5,
    25.44
  ],
  [
    8898,
    64.13746,
    11.84402,
    30.5,
    25.38
  ],
  [
    8900,
    64.13766,
    11.84496,
    30.7,
    25.33
  ],
  [
    8902,
    64.13789,
    11.84586,
    30.7,
    25.26
  ],
  [
    8904,
    64.13812,
    11.84675,
    30.7,
    25.19
  ],
  [
    8906,
    64.13834,
    11.84765,
    30.7,
    25.15
  ],
  [
    8908,
    64.13851,
    11.8486,
    30.7,
    25.09
  ],
  [
    8910,
    64.13862,
    11.8496,
    30.6,
    25.05
  ],
  [
    8912,
    64.13868,
    11.85061,
    30.5,
    25.06
  ],
  [
    8914,
    64.1387,
    11.85164,
    30.4,
    25.04
  ],
  [
    8916,
    64.13871,
    11.85267,
    30.3,
    25.08
  ],
  [
    8918,
    64.13872,
    11.8537,
    30.2,
    25.13
  ],
  [
    8920,
    64.13871,
    11.85473,
    30.2,
    25.19
  ],
  [
    8922,
    64.1387,
    11.85577,
    30.2,
    25.24
  ],
  [
    8924,
    64.13868,
    11.8568,
    30.2,
    25.3
  ],
  [
    8926,
    64.13864,
    11.85784,
    30.2,
    25.37
  ],
  [
    8928,
    64.1386,
    11.85888,
    30.1,
    25.41
  ],
  [
    8930,
    64.13855,
    11.85992,
    30,
    25.46
  ],
  [
    8932,
    64.13848,
    11.86096,
    29.9,
    25.51
  ],
  [
    8934,
    64.13841,
    11.86199,
    29.9,
    25.57
  ],
  [
    8936,
    64.13832,
    11.86302,
    29.8,
    25.63
  ],
  [
    8938,
    64.13823,
    11.86405,
    29.8,
    25.67
  ],
  [
    8940,
    64.13812,
    11.86508,
    29.8,
    25.63
  ],
  [
    8942,
    64.13803,
    11.86611,
    29.7,
    25.56
  ],
  [
    8944,
    64.13799,
    11.86716,
    29.8,
    25.48
  ],
  [
    8946,
    64.13799,
    11.8682,
    29.9,
    25.37
  ],
  [
    8948,
    64.13804,
    11.86923,
    30,
    25.3
  ],
  [
    8950,
    64.13812,
    11.87025,
    30.1,
    25.23
  ],
  [
    8952,
    64.13821,
    11.87126,
    30.2,
    25.14
  ],
  [
    8955,
    64.13831,
    11.87279,
    30.5,
    25.07
  ],
  [
    8957,
    64.13833,
    11.87382,
    30.7,
    24.97
  ],
  [
    8959,
    64.13832,
    11.87484,
    30.8,
    24.95
  ],
  [
    8961,
    64.13825,
    11.87585,
    30.9,
    24.93
  ],
  [
    8963,
    64.13813,
    11.87683,
    31,
    24.89
  ],
  [
    8965,
    64.13796,
    11.87778,
    30.9,
    24.89
  ],
  [
    8967,
    64.13773,
    11.87866,
    30.9,
    24.89
  ],
  [
    8969,
    64.13746,
    11.87948,
    30.8,
    24.91
  ],
  [
    8971,
    64.13715,
    11.88022,
    30.8,
    24.91
  ],
  [
    8973,
    64.13682,
    11.88092,
    30.8,
    24.95
  ],
  [
    8975,
    64.1365,
    11.88164,
    30.9,
    24.95
  ],
  [
    8977,
    64.13622,
    11.88244,
    30.8,
    24.95
  ],
  [
    8979,
    64.13598,
    11.88331,
    30.8,
    25
  ],
  [
    8981,
    64.1358,
    11.88425,
    30.8,
    25
  ],
  [
    8983,
    64.13567,
    11.88524,
    30.7,
    25.13
  ],
  [
    8985,
    64.13559,
    11.88625,
    30.7,
    25.13
  ],
  [
    8987,
    64.13553,
    11.88729,
    30.7,
    25.13
  ],
  [
    8989,
    64.13548,
    11.88832,
    30.6,
    25.34
  ],
  [
    8991,
    64.13542,
    11.88936,
    30.6,
    25.34
  ],
  [
    8993,
    64.13534,
    11.8904,
    30.7,
    25.63
  ],
  [
    8995,
    64.13523,
    11.89143,
    30.8,
    25.63
  ],
  [
    8997,
    64.13511,
    11.89245,
    31,
    25.63
  ],
  [
    8999,
    64.13498,
    11.89346,
    31.2,
    25.79
  ],
  [
    9001,
    64.13485,
    11.89448,
    31.6,
    25.79
  ],
  [
    9003,
    64.13471,
    11.8955,
    32.1,
    25.84
  ],
  [
    9005,
    64.13458,
    11.89652,
    32.5,
    25.84
  ],
  [
    9007,
    64.13445,
    11.89753,
    33,
    25.84
  ],
  [
    9009,
    64.13432,
    11.89854,
    33.5,
    25.69
  ],
  [
    9011,
    64.13419,
    11.89955,
    34.1,
    25.69
  ],
  [
    9013,
    64.13406,
    11.90056,
    34.6,
    25.55
  ],
  [
    9015,
    64.13393,
    11.90156,
    35.1,
    25.55
  ],
  [
    9017,
    64.1338,
    11.90257,
    35.5,
    25.55
  ],
  [
    9019,
    64.13368,
    11.90357,
    36,
    25.46
  ],
  [
    9021,
    64.13358,
    11.90459,
    36.5,
    25.46
  ],
  [
    9023,
    64.13353,
    11.90562,
    36.9,
    25.36
  ],
  [
    9025,
    64.13354,
    11.90666,
    37.3,
    25.36
  ],
  [
    9027,
    64.13361,
    11.90769,
    37.7,
    25.36
  ],
  [
    9029,
    64.13373,
    11.90869,
    38.1,
    25.33
  ],
  [
    9031,
    64.13391,
    11.90965,
    38.5,
    25.33
  ],
  [
    9033,
    64.13414,
    11.91055,
    38.7,
    25.38
  ],
  [
    9035,
    64.13443,
    11.91137,
    38.8,
    25.38
  ],
  [
    9037,
    64.13475,
    11.91211,
    38.8,
    25.38
  ],
  [
    9039,
    64.13511,
    11.91278,
    38.8,
    25.64
  ],
  [
    9041,
    64.13548,
    11.91343,
    39.1,
    25.64
  ],
  [
    9043,
    64.13585,
    11.91408,
    39.1,
    26.09
  ],
  [
    9045,
    64.13623,
    11.91474,
    39.2,
    26.09
  ],
  [
    9047,
    64.13661,
    11.9154,
    39.3,
    26.09
  ],
  [
    9049,
    64.13699,
    11.91607,
    39.4,
    26.52
  ],
  [
    9051,
    64.13737,
    11.91674,
    39.5,
    26.52
  ],
  [
    9053,
    64.13775,
    11.91741,
    39.6,
    26.8
  ],
  [
    9055,
    64.13813,
    11.91808,
    39.7,
    26.8
  ],
  [
    9057,
    64.13851,
    11.91876,
    40.1,
    26.8
  ],
  [
    9059,
    64.13888,
    11.91947,
    40.6,
    26.6
  ],
  [
    9061,
    64.13921,
    11.92025,
    41.2,
    26.6
  ],
  [
    9063,
    64.1395,
    11.9211,
    42,
    26.11
  ],
  [
    9065,
    64.13974,
    11.922,
    42.7,
    26.11
  ],
  [
    9067,
    64.13996,
    11.92293,
    43.1,
    26.11
  ],
  [
    9069,
    64.14017,
    11.92385,
    43.5,
    25.46
  ],
  [
    9071,
    64.14039,
    11.92475,
    43.9,
    25.46
  ],
  [
    9073,
    64.14064,
    11.92561,
    44.4,
    24.9
  ],
  [
    9075,
    64.14092,
    11.9264,
    44.4,
    24.9
  ],
  [
    9077,
    64.14123,
    11.92711,
    44.4,
    24.9
  ],
  [
    9079,
    64.14157,
    11.92774,
    44.2,
    24.41
  ],
  [
    9081,
    64.14193,
    11.9283,
    44.1,
    24.3
  ],
  [
    9083,
    64.1423,
    11.92885,
    44.1,
    24.26
  ],
  [
    9085,
    64.14266,
    11.92939,
    44.2,
    24.22
  ],
  [
    9087,
    64.14302,
    11.92996,
    44.5,
    24.2
  ],
  [
    9089,
    64.14337,
    11.93055,
    44.5,
    24.18
  ],
  [
    9091,
    64.1437,
    11.93119,
    44.6,
    24.15
  ],
  [
    9093,
    64.14402,
    11.93186,
    45,
    24.12
  ],
  [
    9095,
    64.14432,
    11.93257,
    45.5,
    24.1
  ],
  [
    9097,
    64.1446,
    11.93334,
    46,
    24.04
  ],
  [
    9099,
    64.14484,
    11.93415,
    46.3,
    23.96
  ],
  [
    9101,
    64.14506,
    11.93499,
    46.6,
    23.84
  ],
  [
    9103,
    64.14528,
    11.93582,
    46.7,
    23.76
  ],
  [
    9105,
    64.14554,
    11.9366,
    46.7,
    23.71
  ],
  [
    9107,
    64.14584,
    11.93728,
    46.9,
    23.68
  ],
  [
    9109,
    64.14618,
    11.93787,
    47.2,
    23.68
  ],
  [
    9111,
    64.14655,
    11.93833,
    47.8,
    23.66
  ],
  [
    9113,
    64.14695,
    11.93869,
    48.4,
    23.69
  ],
  [
    9115,
    64.14736,
    11.939,
    49,
    23.68
  ],
  [
    9117,
    64.14775,
    11.93936,
    49.5,
    23.72
  ],
  [
    9119,
    64.14813,
    11.93983,
    49.8,
    23.72
  ],
  [
    9121,
    64.14846,
    11.94043,
    50.1,
    23.71
  ],
  [
    9123,
    64.14877,
    11.94111,
    50.4,
    23.74
  ],
  [
    9125,
    64.14906,
    11.94182,
    50.6,
    23.79
  ],
  [
    9127,
    64.14936,
    11.94253,
    50.8,
    23.9
  ],
  [
    9129,
    64.14967,
    11.9432,
    51.1,
    24.07
  ],
  [
    9131,
    64.15,
    11.94386,
    51.5,
    24.26
  ],
  [
    9133,
    64.15033,
    11.94451,
    51.8,
    24.46
  ],
  [
    9135,
    64.15067,
    11.94519,
    52.1,
    24.66
  ],
  [
    9137,
    64.15098,
    11.9459,
    52.6,
    24.88
  ],
  [
    9139,
    64.15129,
    11.94666,
    53,
    25.12
  ],
  [
    9141,
    64.15158,
    11.94746,
    53.4,
    25.28
  ],
  [
    9143,
    64.15185,
    11.94829,
    53.8,
    25.44
  ],
  [
    9145,
    64.15211,
    11.94915,
    54.2,
    25.56
  ],
  [
    9147,
    64.15235,
    11.95005,
    54.7,
    25.64
  ],
  [
    9149,
    64.15259,
    11.95096,
    55.2,
    25.85
  ],
  [
    9151,
    64.1528,
    11.95191,
    55.6,
    25.98
  ],
  [
    9153,
    64.15301,
    11.95286,
    56,
    26.09
  ],
  [
    9155,
    64.15322,
    11.95383,
    56.5,
    26.18
  ],
  [
    9157,
    64.15343,
    11.9548,
    57.1,
    26.23
  ],
  [
    9159,
    64.15364,
    11.95577,
    57.5,
    26.41
  ],
  [
    9161,
    64.15386,
    11.95673,
    57.7,
    26.54
  ],
  [
    9163,
    64.1541,
    11.95767,
    57.7,
    26.75
  ],
  [
    9165,
    64.15437,
    11.95859,
    57.6,
    26.98
  ],
  [
    9167,
    64.15466,
    11.95948,
    57.5,
    27.14
  ],
  [
    9169,
    64.15498,
    11.96035,
    57.4,
    27.58
  ],
  [
    9171,
    64.15532,
    11.96118,
    57.3,
    27.85
  ],
  [
    9173,
    64.15568,
    11.96198,
    57,
    27.94
  ],
  [
    9175,
    64.15604,
    11.96277,
    56.8,
    27.95
  ],
  [
    9177,
    64.15639,
    11.96359,
    56.5,
    28.01
  ],
  [
    9179,
    64.15671,
    11.96448,
    56.1,
    28.05
  ],
  [
    9181,
    64.157,
    11.96543,
    55.6,
    28.12
  ],
  [
    9183,
    64.15725,
    11.96644,
    54.9,
    28.23
  ],
  [
    9185,
    64.15749,
    11.96746,
    54.2,
    28.29
  ],
  [
    9187,
    64.15773,
    11.96849,
    53.7,
    28.34
  ],
  [
    9189,
    64.15797,
    11.96952,
    53.1,
    28.51
  ],
  [
    9191,
    64.15821,
    11.97055,
    52.5,
    28.03
  ],
  [
    9193,
    64.15846,
    11.97154,
    52.1,
    27.64
  ],
  [
    9195,
    64.15874,
    11.97247,
    51.7,
    27.18
  ],
  [
    9197,
    64.15904,
    11.97334,
    51.1,
    26.94
  ],
  [
    9199,
    64.15936,
    11.97414,
    50.5,
    26.21
  ],
  [
    9201,
    64.1597,
    11.97487,
    50,
    25.74
  ],
  [
    9203,
    64.16006,
    11.97553,
    49.6,
    25.4
  ],
  [
    9205,
    64.16042,
    11.97615,
    49.2,
    25.34
  ],
  [
    9207,
    64.1608,
    11.97675,
    48.6,
    25.34
  ],
  [
    9209,
    64.16117,
    11.97734,
    47.9,
    25.43
  ],
  [
    9211,
    64.16154,
    11.97795,
    47.2,
    25.53
  ],
  [
    9213,
    64.16191,
    11.97859,
    46.7,
    25.63
  ],
  [
    9215,
    64.16226,
    11.97928,
    46.5,
    25.75
  ],
  [
    9217,
    64.16259,
    11.98001,
    46,
    25.77
  ],
  [
    9219,
    64.16291,
    11.98077,
    45.6,
    25.44
  ],
  [
    9221,
    64.1632,
    11.98156,
    45.3,
    25
  ],
  [
    9223,
    64.16347,
    11.98236,
    45,
    24.55
  ],
  [
    9225,
    64.16373,
    11.98318,
    44.7,
    24.06
  ],
  [
    9227,
    64.16397,
    11.98398,
    44.4,
    23.86
  ],
  [
    9229,
    64.16422,
    11.98477,
    44.1,
    23.58
  ],
  [
    9231,
    64.16449,
    11.98551,
    43.9,
    23.54
  ],
  [
    9233,
    64.16481,
    11.98615,
    43.6,
    23.55
  ],
  [
    9235,
    64.16515,
    11.9867,
    43.5,
    23.66
  ],
  [
    9237,
    64.16552,
    11.98721,
    43.3,
    23.74
  ],
  [
    9239,
    64.16587,
    11.98778,
    43.2,
    23.97
  ],
  [
    9241,
    64.16618,
    11.98845,
    42.9,
    24.1
  ],
  [
    9243,
    64.16645,
    11.98922,
    42.7,
    24.22
  ],
  [
    9245,
    64.16668,
    11.99007,
    42.3,
    24.35
  ],
  [
    9247,
    64.16686,
    11.99098,
    41.9,
    24.41
  ],
  [
    9249,
    64.16702,
    11.99193,
    41.5,
    24.69
  ],
  [
    9251,
    64.16715,
    11.99291,
    41,
    24.95
  ],
  [
    9253,
    64.16724,
    11.99392,
    40.6,
    25.21
  ],
  [
    9255,
    64.1673,
    11.99495,
    40.2,
    25.49
  ],
  [
    9257,
    64.16734,
    11.996,
    39.7,
    25.64
  ],
  [
    9259,
    64.16738,
    11.99707,
    39.2,
    26.18
  ],
  [
    9261,
    64.16742,
    11.99815,
    38.7,
    26.57
  ],
  [
    9263,
    64.16749,
    11.99925,
    38.3,
    26.94
  ],
  [
    9265,
    64.16759,
    12.00034,
    37.8,
    27.37
  ],
  [
    9267,
    64.16774,
    12.00142,
    37.3,
    27.55
  ],
  [
    9269,
    64.16794,
    12.00247,
    36.9,
    28.15
  ],
  [
    9271,
    64.16819,
    12.00349,
    36.6,
    28.55
  ],
  [
    9273,
    64.16846,
    12.00449,
    36.2,
    28.95
  ],
  [
    9275,
    64.16875,
    12.00551,
    35.8,
    29.36
  ],
  [
    9277,
    64.16901,
    12.00656,
    35.4,
    29.59
  ],
  [
    9279,
    64.16924,
    12.00767,
    35.3,
    29.89
  ],
  [
    9281,
    64.16944,
    12.00881,
    35,
    29.95
  ],
  [
    9283,
    64.16964,
    12.00996,
    34.9,
    29.97
  ],
  [
    9285,
    64.16982,
    12.01112,
    34.9,
    29.96
  ],
  [
    9287,
    64.17,
    12.01228,
    34.9,
    29.96
  ],
  [
    9289,
    64.17018,
    12.01345,
    35.1,
    29.9
  ],
  [
    9291,
    64.17034,
    12.01462,
    35.2,
    29.85
  ],
  [
    9293,
    64.1705,
    12.01579,
    35.4,
    29.79
  ],
  [
    9295,
    64.17064,
    12.01697,
    35.4,
    29.73
  ],
  [
    9297,
    64.17074,
    12.01817,
    35.4,
    29.71
  ],
  [
    9299,
    64.17077,
    12.01939,
    35.3,
    29.56
  ],
  [
    9301,
    64.17072,
    12.0206,
    35.2,
    29.48
  ],
  [
    9303,
    64.17061,
    12.02179,
    35.1,
    29.37
  ],
  [
    9305,
    64.17045,
    12.02294,
    35,
    29.27
  ],
  [
    9307,
    64.17028,
    12.02408,
    34.8,
    29.19
  ],
  [
    9309,
    64.17014,
    12.02524,
    34.7,
    29.01
  ],
  [
    9311,
    64.17005,
    12.02641,
    34.7,
    28.91
  ],
  [
    9313,
    64.17002,
    12.0276,
    34.6,
    28.81
  ],
  [
    9315,
    64.17003,
    12.02878,
    34.5,
    28.73
  ],
  [
    9317,
    64.17009,
    12.02995,
    34.4,
    28.67
  ],
  [
    9319,
    64.17021,
    12.0311,
    34.3,
    28.73
  ],
  [
    9321,
    64.17037,
    12.03223,
    34.1,
    28.79
  ],
  [
    9323,
    64.17056,
    12.03333,
    33.9,
    28.88
  ],
  [
    9325,
    64.17076,
    12.03443,
    33.8,
    28.94
  ],
  [
    9327,
    64.17096,
    12.03553,
    33.7,
    29.01
  ],
  [
    9329,
    64.17116,
    12.03663,
    33.6,
    29.16
  ],
  [
    9331,
    64.17136,
    12.03774,
    33.5,
    29.29
  ],
  [
    9333,
    64.17156,
    12.03886,
    33.3,
    29.43
  ],
  [
    9335,
    64.17177,
    12.03998,
    33.2,
    29.52
  ],
  [
    9337,
    64.17197,
    12.04111,
    33.2,
    29.59
  ],
  [
    9339,
    64.17217,
    12.04223,
    33.1,
    29.72
  ],
  [
    9341,
    64.17238,
    12.04336,
    32.9,
    29.69
  ],
  [
    9343,
    64.17259,
    12.04448,
    32.6,
    29.67
  ],
  [
    9345,
    64.17283,
    12.04557,
    32.4,
    29.65
  ],
  [
    9347,
    64.1731,
    12.04662,
    32.5,
    29.62
  ],
  [
    9349,
    64.17338,
    12.04766,
    32.5,
    29.61
  ],
  [
    9351,
    64.17364,
    12.04872,
    32.5,
    29.6
  ],
  [
    9353,
    64.17385,
    12.04985,
    32.4,
    29.63
  ],
  [
    9355,
    64.17399,
    12.05102,
    32.3,
    29.61
  ],
  [
    9357,
    64.17407,
    12.05222,
    32.3,
    29.6
  ],
  [
    9359,
    64.17409,
    12.05344,
    32.3,
    29.58
  ],
  [
    9361,
    64.17405,
    12.05465,
    32.2,
    29.55
  ],
  [
    9363,
    64.174,
    12.05586,
    32.1,
    29.49
  ],
  [
    9365,
    64.17399,
    12.05708,
    32.1,
    29.5
  ],
  [
    9367,
    64.17402,
    12.05829,
    32.1,
    29.5
  ],
  [
    9369,
    64.17409,
    12.05949,
    32.1,
    29.53
  ],
  [
    9371,
    64.17416,
    12.0607,
    32.1,
    29.55
  ],
  [
    9373,
    64.17424,
    12.0619,
    32,
    29.59
  ],
  [
    9375,
    64.17432,
    12.0631,
    32,
    29.61
  ],
  [
    9377,
    64.17441,
    12.06431,
    32,
    29.63
  ],
  [
    9379,
    64.17449,
    12.06551,
    32.1,
    29.68
  ],
  [
    9381,
    64.17458,
    12.06672,
    32,
    29.69
  ],
  [
    9383,
    64.17468,
    12.06792,
    32,
    29.64
  ],
  [
    9385,
    64.17479,
    12.06911,
    31.9,
    29.54
  ],
  [
    9387,
    64.17493,
    12.07028,
    31.8,
    29.51
  ],
  [
    9389,
    64.1751,
    12.07142,
    31.7,
    29.43
  ],
  [
    9391,
    64.17528,
    12.07256,
    31.6,
    29.42
  ],
  [
    9393,
    64.17543,
    12.07372,
    31.5,
    29.42
  ],
  [
    9395,
    64.17554,
    12.07491,
    31.4,
    29.38
  ],
  [
    9397,
    64.1756,
    12.0761,
    31.4,
    29.33
  ],
  [
    9399,
    64.17562,
    12.07731,
    31.4,
    29.25
  ],
  [
    9401,
    64.17559,
    12.07851,
    31.3,
    29.17
  ],
  [
    9403,
    64.17552,
    12.0797,
    31.2,
    29.09
  ],
  [
    9405,
    64.17544,
    12.08088,
    31,
    29.09
  ],
  [
    9407,
    64.17537,
    12.08207,
    30.9,
    29.1
  ],
  [
    9409,
    64.17534,
    12.08326,
    30.9,
    29.1
  ],
  [
    9411,
    64.17536,
    12.08446,
    30.8,
    29.11
  ],
  [
    9413,
    64.17543,
    12.08564,
    30.9,
    29.14
  ],
  [
    9415,
    64.17553,
    12.08682,
    31,
    29.18
  ],
  [
    9417,
    64.17564,
    12.088,
    30.9,
    29.19
  ],
  [
    9419,
    64.17577,
    12.08916,
    30.8,
    29.2
  ],
  [
    9421,
    64.17592,
    12.09031,
    30.8,
    29.3
  ],
  [
    9423,
    64.1761,
    12.09145,
    30.9,
    29.3
  ],
  [
    9425,
    64.1763,
    12.09256,
    30.9,
    29.34
  ],
  [
    9427,
    64.17654,
    12.09363,
    30.9,
    29.32
  ],
  [
    9429,
    64.17682,
    12.09466,
    30.9,
    29.36
  ],
  [
    9431,
    64.17711,
    12.09566,
    30.9,
    29.41
  ],
  [
    9433,
    64.17741,
    12.09666,
    31,
    29.44
  ],
  [
    9435,
    64.17772,
    12.09765,
    31.3,
    29.51
  ],
  [
    9437,
    64.17802,
    12.09865,
    31.8,
    29.56
  ],
  [
    9439,
    64.1783,
    12.09968,
    32.4,
    29.64
  ],
  [
    9441,
    64.17854,
    12.10078,
    32.7,
    29.69
  ],
  [
    9443,
    64.17871,
    12.10193,
    33,
    29.73
  ],
  [
    9445,
    64.17883,
    12.10312,
    33.3,
    29.83
  ],
  [
    9447,
    64.17889,
    12.10434,
    33.4,
    29.91
  ],
  [
    9449,
    64.17895,
    12.10557,
    33.6,
    29.99
  ],
  [
    9451,
    64.17901,
    12.1068,
    33.8,
    30.13
  ],
  [
    9453,
    64.17908,
    12.10803,
    34,
    30.25
  ],
  [
    9455,
    64.17916,
    12.10926,
    34,
    30.36
  ],
  [
    9457,
    64.17925,
    12.1105,
    34,
    30.5
  ],
  [
    9459,
    64.17935,
    12.11174,
    34,
    30.54
  ],
  [
    9461,
    64.17945,
    12.11297,
    34,
    30.46
  ],
  [
    9463,
    64.17955,
    12.1142,
    34.1,
    30.39
  ],
  [
    9465,
    64.17966,
    12.11542,
    34,
    30.32
  ],
  [
    9467,
    64.17976,
    12.11665,
    33.7,
    30.3
  ],
  [
    9469,
    64.17986,
    12.11787,
    33.5,
    30.27
  ],
  [
    9471,
    64.17996,
    12.1191,
    33.4,
    30.25
  ],
  [
    9473,
    64.18006,
    12.12032,
    33.4,
    30.23
  ],
  [
    9475,
    64.18013,
    12.12155,
    33.5,
    30.15
  ],
  [
    9477,
    64.18015,
    12.12279,
    33.5,
    30.05
  ],
  [
    9479,
    64.18012,
    12.12402,
    33.5,
    29.94
  ],
  [
    9481,
    64.18004,
    12.12524,
    33.5,
    29.85
  ],
  [
    9483,
    64.17993,
    12.12644,
    33.4,
    29.74
  ],
  [
    9485,
    64.17981,
    12.12763,
    33.3,
    29.66
  ],
  [
    9487,
    64.1797,
    12.12882,
    33.2,
    29.57
  ],
  [
    9489,
    64.17963,
    12.13003,
    33.2,
    29.49
  ],
  [
    9491,
    64.1796,
    12.13123,
    33.2,
    29.39
  ],
  [
    9493,
    64.1796,
    12.13243,
    33.2,
    28.87
  ],
  [
    9495,
    64.17963,
    12.13361,
    33.2,
    28.27
  ],
  [
    9497,
    64.17966,
    12.13476,
    33.1,
    27.8
  ],
  [
    9499,
    64.17969,
    12.13589,
    33.1,
    27.56
  ],
  [
    9501,
    64.17971,
    12.13702,
    33.1,
    27.4
  ],
  [
    9503,
    64.17974,
    12.13814,
    33,
    27.34
  ],
  [
    9505,
    64.17976,
    12.13927,
    33,
    27.3
  ],
  [
    9507,
    64.17973,
    12.14039,
    32.9,
    27.34
  ],
  [
    9509,
    64.17965,
    12.14149,
    32.7,
    27.35
  ],
  [
    9511,
    64.17949,
    12.14256,
    32.4,
    27.39
  ],
  [
    9513,
    64.17927,
    12.14357,
    32,
    27.41
  ],
  [
    9515,
    64.179,
    12.14451,
    31.5,
    27.55
  ],
  [
    9517,
    64.1787,
    12.14543,
    31,
    27.67
  ],
  [
    9519,
    64.1784,
    12.14634,
    30.6,
    27.82
  ],
  [
    9521,
    64.17812,
    12.1473,
    30.1,
    27.96
  ],
  [
    9523,
    64.17787,
    12.1483,
    29.8,
    28.12
  ],
  [
    9525,
    64.17765,
    12.14934,
    29.7,
    28.25
  ],
  [
    9527,
    64.17746,
    12.15042,
    29.8,
    28.35
  ],
  [
    9529,
    64.17732,
    12.15154,
    29.9,
    28.35
  ],
  [
    9531,
    64.17721,
    12.15268,
    30,
    28.28
  ],
  [
    9533,
    64.17714,
    12.15383,
    30.3,
    28.19
  ],
  [
    9535,
    64.1771,
    12.15498,
    30.5,
    28.06
  ],
  [
    9537,
    64.1771,
    12.15613,
    30.7,
    27.91
  ],
  [
    9539,
    64.17713,
    12.15728,
    30.9,
    27.83
  ],
  [
    9541,
    64.17716,
    12.15842,
    30.9,
    27.79
  ],
  [
    9543,
    64.17719,
    12.15956,
    30.8,
    27.81
  ],
  [
    9545,
    64.17722,
    12.1607,
    30.7,
    27.87
  ],
  [
    9547,
    64.17725,
    12.16185,
    30.6,
    27.94
  ],
  [
    9549,
    64.17728,
    12.163,
    30.5,
    28.04
  ],
  [
    9551,
    64.17732,
    12.16415,
    30.4,
    28.14
  ],
  [
    9553,
    64.17735,
    12.16531,
    30.3,
    28.21
  ],
  [
    9555,
    64.17738,
    12.16647,
    30.2,
    28.28
  ],
  [
    9557,
    64.17742,
    12.16763,
    30.1,
    28.35
  ],
  [
    9559,
    64.17749,
    12.16879,
    30,
    28.43
  ],
  [
    9561,
    64.17757,
    12.16995,
    29.9,
    28.51
  ],
  [
    9563,
    64.17767,
    12.1711,
    30,
    28.57
  ],
  [
    9565,
    64.17779,
    12.17224,
    30,
    28.62
  ],
  [
    9567,
    64.17793,
    12.17337,
    30,
    28.65
  ],
  [
    9569,
    64.17809,
    12.1745,
    29.9,
    28.65
  ],
  [
    9571,
    64.17827,
    12.1756,
    29.9,
    28.62
  ],
  [
    9573,
    64.17847,
    12.17669,
    29.8,
    28.56
  ],
  [
    9575,
    64.17869,
    12.17775,
    29.8,
    28.56
  ],
  [
    9577,
    64.17893,
    12.17879,
    29.7,
    28.56
  ],
  [
    9579,
    64.17921,
    12.17977,
    29.5,
    28.46
  ],
  [
    9581,
    64.17953,
    12.18068,
    29.7,
    28.42
  ],
  [
    9583,
    64.17989,
    12.18152,
    29.8,
    28.38
  ],
  [
    9585,
    64.18027,
    12.18228,
    29.7,
    28.35
  ],
  [
    9587,
    64.18067,
    12.18301,
    29.7,
    28.37
  ],
  [
    9589,
    64.18107,
    12.18374,
    29.8,
    28.4
  ],
  [
    9591,
    64.18147,
    12.18447,
    30,
    28.39
  ],
  [
    9593,
    64.18187,
    12.1852,
    30.2,
    28.37
  ],
  [
    9595,
    64.18227,
    12.18593,
    30.5,
    28.34
  ],
  [
    9597,
    64.18267,
    12.18666,
    30.7,
    28.36
  ],
  [
    9599,
    64.18307,
    12.18738,
    30.8,
    28.38
  ],
  [
    9601,
    64.18347,
    12.1881,
    30.9,
    28.43
  ],
  [
    9603,
    64.18389,
    12.18879,
    31,
    28.52
  ],
  [
    9605,
    64.18432,
    12.18942,
    31,
    28.59
  ],
  [
    9607,
    64.18477,
    12.19,
    31,
    28.72
  ],
  [
    9609,
    64.18524,
    12.19051,
    30.9,
    28.81
  ],
  [
    9611,
    64.18572,
    12.19097,
    30.9,
    28.92
  ],
  [
    9613,
    64.18621,
    12.19135,
    30.8,
    28.97
  ],
  [
    9615,
    64.18671,
    12.19167,
    30.8,
    28.94
  ],
  [
    9617,
    64.18722,
    12.19193,
    30.6,
    28.88
  ],
  [
    9619,
    64.18774,
    12.19212,
    30.3,
    28.83
  ],
  [
    9621,
    64.18825,
    12.19224,
    30,
    28.8
  ],
  [
    9623,
    64.18877,
    12.19231,
    29.7,
    28.8
  ],
  [
    9625,
    64.18929,
    12.19236,
    29.5,
    28.79
  ],
  [
    9627,
    64.18981,
    12.1924,
    29.5,
    28.8
  ],
  [
    9629,
    64.19032,
    12.19244,
    29.4,
    28.72
  ],
  [
    9631,
    64.19084,
    12.19248,
    29.3,
    28.63
  ],
  [
    9633,
    64.19136,
    12.19252,
    29.1,
    28.57
  ],
  [
    9635,
    64.19187,
    12.19256,
    29.1,
    28.53
  ],
  [
    9637,
    64.19238,
    12.1926,
    29,
    28.45
  ],
  [
    9639,
    64.19289,
    12.19264,
    29.1,
    28.37
  ],
  [
    9641,
    64.1934,
    12.19269,
    29.3,
    28.3
  ],
  [
    9643,
    64.19391,
    12.19273,
    29.6,
    28.12
  ],
  [
    9645,
    64.19441,
    12.19277,
    30.1,
    27.94
  ],
  [
    9647,
    64.19491,
    12.19285,
    30.6,
    27.76
  ],
  [
    9649,
    64.1954,
    12.19303,
    31,
    27.58
  ],
  [
    9651,
    64.19588,
    12.19333,
    31.3,
    27.45
  ],
  [
    9653,
    64.19634,
    12.19375,
    31.7,
    27.31
  ],
  [
    9655,
    64.19677,
    12.19427,
    32.2,
    27.21
  ],
  [
    9657,
    64.19717,
    12.19491,
    32.7,
    27.09
  ],
  [
    9659,
    64.19754,
    12.19563,
    33.1,
    26.99
  ],
  [
    9661,
    64.19787,
    12.19644,
    33.5,
    26.94
  ],
  [
    9663,
    64.19816,
    12.19733,
    33.8,
    26.96
  ],
  [
    9665,
    64.19841,
    12.19828,
    34.1,
    26.99
  ],
  [
    9667,
    64.19864,
    12.19926,
    34.6,
    27.05
  ],
  [
    9669,
    64.19885,
    12.20027,
    35.2,
    27.15
  ],
  [
    9671,
    64.19905,
    12.2013,
    35.8,
    27.26
  ],
  [
    9673,
    64.19922,
    12.20236,
    36.4,
    27.34
  ],
  [
    9675,
    64.19938,
    12.20343,
    36.9,
    27.45
  ],
  [
    9677,
    64.19953,
    12.20451,
    37.5,
    27.54
  ],
  [
    9679,
    64.19969,
    12.20559,
    38,
    27.65
  ],
  [
    9681,
    64.19984,
    12.20668,
    38.5,
    27.73
  ],
  [
    9683,
    64.19999,
    12.20777,
    39,
    27.85
  ],
  [
    9685,
    64.20015,
    12.20887,
    39.6,
    27.93
  ],
  [
    9687,
    64.20031,
    12.20996,
    40.1,
    28.02
  ],
  [
    9689,
    64.20046,
    12.21107,
    40.6,
    28.12
  ],
  [
    9691,
    64.20062,
    12.21217,
    41.1,
    28.2
  ],
  [
    9693,
    64.20078,
    12.21328,
    41.7,
    28.26
  ],
  [
    9695,
    64.20094,
    12.21439,
    42.4,
    28.34
  ],
  [
    9697,
    64.20109,
    12.2155,
    42.9,
    28.43
  ],
  [
    9699,
    64.20125,
    12.21662,
    43.2,
    28.49
  ],
  [
    9701,
    64.20141,
    12.21774,
    43.4,
    28.63
  ],
  [
    9703,
    64.20157,
    12.21886,
    43.7,
    28.78
  ],
  [
    9705,
    64.20174,
    12.21999,
    44.1,
    28.92
  ],
  [
    9707,
    64.20191,
    12.22112,
    44.5,
    29.05
  ],
  [
    9709,
    64.2021,
    12.22224,
    44.8,
    29.17
  ],
  [
    9711,
    64.20228,
    12.22337,
    45.2,
    29.27
  ],
  [
    9713,
    64.20248,
    12.22449,
    45.8,
    29.24
  ],
  [
    9715,
    64.20269,
    12.22559,
    46.3,
    29.03
  ],
  [
    9717,
    64.2029,
    12.22668,
    47,
    28.78
  ],
  [
    9719,
    64.20311,
    12.22776,
    47.6,
    28.51
  ],
  [
    9721,
    64.20333,
    12.22881,
    48.2,
    28.24
  ],
  [
    9723,
    64.20355,
    12.22985,
    48.9,
    27.94
  ],
  [
    9725,
    64.20378,
    12.23087,
    49.6,
    27.68
  ],
  [
    9727,
    64.20401,
    12.23188,
    50.3,
    27.39
  ],
  [
    9729,
    64.20425,
    12.23286,
    50.9,
    27.14
  ],
  [
    9731,
    64.20448,
    12.23382,
    51.5,
    26.54
  ],
  [
    9733,
    64.20472,
    12.23476,
    52.2,
    25.79
  ],
  [
    9735,
    64.20495,
    12.23565,
    52.7,
    25.08
  ],
  [
    9737,
    64.20519,
    12.23652,
    53.1,
    24.47
  ],
  [
    9739,
    64.20542,
    12.23736,
    53.5,
    24.18
  ],
  [
    9741,
    64.20566,
    12.2382,
    53.6,
    23.98
  ],
  [
    9743,
    64.20589,
    12.23902,
    53.7,
    23.88
  ],
  [
    9745,
    64.20612,
    12.23984,
    53.8,
    23.88
  ],
  [
    9747,
    64.20636,
    12.24067,
    53.9,
    23.92
  ],
  [
    9749,
    64.20659,
    12.24149,
    54,
    23.99
  ],
  [
    9751,
    64.20683,
    12.24232,
    54.1,
    24.15
  ],
  [
    9753,
    64.20707,
    12.24316,
    54.1,
    24.27
  ],
  [
    9755,
    64.2073,
    12.244,
    54.2,
    24.45
  ],
  [
    9757,
    64.20754,
    12.24485,
    54.3,
    24.68
  ],
  [
    9759,
    64.20779,
    12.24571,
    54.5,
    24.93
  ],
  [
    9761,
    64.20803,
    12.24657,
    54.5,
    25.2
  ],
  [
    9763,
    64.20829,
    12.24743,
    54.6,
    25.44
  ],
  [
    9765,
    64.20856,
    12.24828,
    54.8,
    25.71
  ],
  [
    9767,
    64.20884,
    12.24913,
    55.1,
    25.97
  ],
  [
    9769,
    64.20914,
    12.24996,
    55.5,
    26.15
  ],
  [
    9771,
    64.20944,
    12.25079,
    56,
    26.34
  ],
  [
    9773,
    64.20975,
    12.25161,
    56.5,
    26.46
  ],
  [
    9775,
    64.21006,
    12.25243,
    57.1,
    26.57
  ],
  [
    9777,
    64.21038,
    12.25326,
    57.7,
    26.67
  ],
  [
    9779,
    64.21069,
    12.25409,
    58.3,
    26.76
  ],
  [
    9781,
    64.21101,
    12.25492,
    58.9,
    26.82
  ],
  [
    9783,
    64.21133,
    12.25576,
    59.5,
    26.92
  ],
  [
    9785,
    64.21164,
    12.2566,
    60.1,
    26.99
  ],
  [
    9787,
    64.21196,
    12.25744,
    60.7,
    27.07
  ],
  [
    9789,
    64.21228,
    12.25828,
    61.3,
    27.17
  ],
  [
    9791,
    64.2126,
    12.25913,
    61.8,
    27.27
  ],
  [
    9793,
    64.21292,
    12.25998,
    62.3,
    27.36
  ],
  [
    9795,
    64.21325,
    12.26083,
    62.7,
    27.48
  ],
  [
    9797,
    64.21357,
    12.26169,
    63,
    27.63
  ],
  [
    9799,
    64.2139,
    12.26255,
    63.3,
    27.79
  ],
  [
    9801,
    64.21423,
    12.26342,
    63.4,
    27.98
  ],
  [
    9803,
    64.21456,
    12.26429,
    63.6,
    28.18
  ],
  [
    9805,
    64.21489,
    12.26517,
    63.8,
    28.4
  ],
  [
    9807,
    64.21523,
    12.26606,
    63.9,
    28.62
  ],
  [
    9809,
    64.21557,
    12.26695,
    64.1,
    28.82
  ],
  [
    9811,
    64.21591,
    12.26785,
    64.3,
    29.02
  ],
  [
    9813,
    64.21625,
    12.26876,
    64.6,
    29.25
  ],
  [
    9815,
    64.2166,
    12.26967,
    64.9,
    29.4
  ],
  [
    9817,
    64.21694,
    12.27059,
    65.1,
    29.59
  ],
  [
    9819,
    64.21729,
    12.27151,
    65.4,
    29.76
  ],
  [
    9821,
    64.21764,
    12.27244,
    65.6,
    29.94
  ],
  [
    9823,
    64.218,
    12.27338,
    65.7,
    30.12
  ],
  [
    9825,
    64.21835,
    12.27432,
    65.9,
    30.3
  ],
  [
    9827,
    64.21871,
    12.27527,
    66.2,
    30.48
  ],
  [
    9829,
    64.21906,
    12.27624,
    66.4,
    30.66
  ],
  [
    9831,
    64.2194,
    12.27724,
    66.6,
    30.84
  ],
  [
    9833,
    64.21973,
    12.27827,
    66.8,
    31.02
  ],
  [
    9835,
    64.22005,
    12.27933,
    67,
    31.2
  ],
  [
    9837,
    64.22035,
    12.28042,
    67.3,
    31.39
  ],
  [
    9839,
    64.22063,
    12.28153,
    67.6,
    31.51
  ],
  [
    9841,
    64.22091,
    12.28268,
    67.9,
    31.68
  ],
  [
    9843,
    64.22117,
    12.28384,
    68.1,
    31.83
  ],
  [
    9845,
    64.22143,
    12.28502,
    68.2,
    31.97
  ],
  [
    9847,
    64.22168,
    12.2862,
    68.3,
    32.13
  ],
  [
    9849,
    64.22194,
    12.28739,
    68.3,
    32.31
  ],
  [
    9851,
    64.2222,
    12.28859,
    68.5,
    32.48
  ],
  [
    9853,
    64.22246,
    12.28979,
    68.6,
    32.66
  ],
  [
    9855,
    64.22273,
    12.291,
    68.7,
    32.81
  ],
  [
    9857,
    64.22299,
    12.29221,
    68.9,
    32.97
  ],
  [
    9859,
    64.22325,
    12.29343,
    69,
    33.08
  ],
  [
    9861,
    64.22352,
    12.29465,
    69.2,
    33.23
  ],
  [
    9863,
    64.22379,
    12.29588,
    69.4,
    33.36
  ],
  [
    9865,
    64.22406,
    12.29712,
    69.6,
    33.44
  ],
  [
    9867,
    64.22433,
    12.29835,
    69.9,
    33.46
  ],
  [
    9869,
    64.22459,
    12.29958,
    70.2,
    33.44
  ],
  [
    9871,
    64.22486,
    12.30082,
    70.6,
    33.38
  ],
  [
    9873,
    64.22513,
    12.30204,
    70.9,
    33.31
  ],
  [
    9876,
    64.22555,
    12.30386,
    71.3,
    33.22
  ],
  [
    9877,
    64.22569,
    12.30446,
    71.3,
    33.18
  ],
  [
    9880,
    64.22613,
    12.30625,
    71.2,
    33.16
  ],
  [
    9881,
    64.22628,
    12.30684,
    71.1,
    33.18
  ],
  [
    9884,
    64.22672,
    12.30863,
    70.8,
    33.21
  ],
  [
    9885,
    64.22687,
    12.30922,
    70.6,
    33.27
  ],
  [
    9888,
    64.22732,
    12.31101,
    70.2,
    33.37
  ],
  [
    9890,
    64.22761,
    12.31221,
    69.8,
    33.47
  ],
  [
    9892,
    64.22791,
    12.31341,
    69.5,
    33.56
  ],
  [
    9894,
    64.22821,
    12.31462,
    69.3,
    33.65
  ],
  [
    9896,
    64.22851,
    12.31582,
    69.1,
    33.72
  ],
  [
    9898,
    64.22881,
    12.31703,
    69,
    33.77
  ],
  [
    9900,
    64.22911,
    12.31824,
    68.9,
    33.73
  ],
  [
    9902,
    64.2294,
    12.31946,
    68.9,
    33.65
  ],
  [
    9904,
    64.22968,
    12.32069,
    68.8,
    33.6
  ],
  [
    9906,
    64.22995,
    12.32193,
    68.6,
    33.52
  ],
  [
    9908,
    64.23021,
    12.32317,
    68.3,
    33.46
  ],
  [
    9910,
    64.23047,
    12.32441,
    68.1,
    33.4
  ],
  [
    9912,
    64.23075,
    12.32562,
    67.9,
    33.36
  ],
  [
    9914,
    64.23108,
    12.32677,
    67.7,
    33.34
  ],
  [
    9916,
    64.23146,
    12.32784,
    67.4,
    33.25
  ],
  [
    9918,
    64.23186,
    12.32885,
    67.1,
    33.26
  ],
  [
    9920,
    64.23227,
    12.32985,
    66.8,
    33.25
  ],
  [
    9922,
    64.23266,
    12.33088,
    66.4,
    33.27
  ],
  [
    9924,
    64.23305,
    12.33192,
    66.1,
    33.25
  ],
  [
    9926,
    64.23342,
    12.33299,
    65.8,
    33.27
  ],
  [
    9928,
    64.23378,
    12.33409,
    65.5,
    33.25
  ],
  [
    9930,
    64.23413,
    12.3352,
    65.2,
    33.25
  ],
  [
    9932,
    64.23446,
    12.33634,
    65,
    33.23
  ],
  [
    9934,
    64.23479,
    12.33748,
    64.9,
    33.21
  ],
  [
    9936,
    64.23511,
    12.33864,
    64.9,
    33.21
  ],
  [
    9938,
    64.23541,
    12.33981,
    65.3,
    33.02
  ],
  [
    9940,
    64.2357,
    12.34099,
    65.8,
    33.02
  ],
  [
    9942,
    64.23597,
    12.34219,
    66.6,
    33.02
  ],
  [
    9944,
    64.23621,
    12.34341,
    67.3,
    32.55
  ],
  [
    9946,
    64.23643,
    12.34463,
    67.9,
    32.55
  ],
  [
    9948,
    64.23665,
    12.34584,
    68.5,
    31.88
  ],
  [
    9950,
    64.23687,
    12.34704,
    68.6,
    31.88
  ],
  [
    9952,
    64.23709,
    12.34823,
    69.3,
    31.88
  ],
  [
    9954,
    64.2373,
    12.34942,
    70,
    31.15
  ],
  [
    9956,
    64.23752,
    12.35059,
    70.7,
    31.15
  ],
  [
    9958,
    64.23773,
    12.35174,
    71.4,
    30.47
  ],
  [
    9960,
    64.23794,
    12.35289,
    72,
    30.47
  ],
  [
    9962,
    64.23814,
    12.35403,
    72.6,
    30.47
  ],
  [
    9964,
    64.23835,
    12.35516,
    73.1,
    29.81
  ],
  [
    9966,
    64.23855,
    12.35628,
    73.6,
    29.81
  ],
  [
    9968,
    64.23876,
    12.35739,
    74.3,
    29.24
  ],
  [
    9970,
    64.23896,
    12.3585,
    74.9,
    29.24
  ],
  [
    9972,
    64.23916,
    12.35962,
    75.5,
    29.24
  ],
  [
    9978,
    64.23973,
    12.36276,
    77.2,
    27.8
  ],
  [
    9980,
    64.23992,
    12.3638,
    76.7,
    27.1
  ],
  [
    9982,
    64.24011,
    12.36481,
    76.8,
    26.41
  ],
  [
    9984,
    64.24028,
    12.3658,
    76.8,
    25.69
  ],
  [
    9986,
    64.24046,
    12.36676,
    76.9,
    24.85
  ],
  [
    9988,
    64.24063,
    12.36768,
    76.9,
    23.88
  ],
  [
    9990,
    64.24079,
    12.36856,
    76.9,
    22.82
  ],
  [
    9992,
    64.24095,
    12.3694,
    76.9,
    21.86
  ],
  [
    9994,
    64.24112,
    12.37019,
    76.8,
    21.31
  ],
  [
    9996,
    64.24132,
    12.37092,
    76.7,
    21.05
  ],
  [
    9998,
    64.24155,
    12.37161,
    76.5,
    20.87
  ],
  [
    10000,
    64.2418,
    12.37225,
    76.3,
    20.81
  ],
  [
    10002,
    64.24207,
    12.37284,
    76.1,
    20.82
  ],
  [
    10004,
    64.24236,
    12.37339,
    76,
    20.82
  ],
  [
    10006,
    64.24265,
    12.37392,
    76,
    20.89
  ],
  [
    10008,
    64.24294,
    12.37446,
    75.9,
    20.79
  ],
  [
    10010,
    64.24323,
    12.37498,
    75.8,
    20.11
  ],
  [
    10012,
    64.24351,
    12.37548,
    75.8,
    19.03
  ],
  [
    10014,
    64.24376,
    12.37595,
    75.6,
    17.84
  ],
  [
    10016,
    64.24401,
    12.37639,
    75.5,
    16.62
  ],
  [
    10018,
    64.24423,
    12.37679,
    75.5,
    15.36
  ],
  [
    10020,
    64.24444,
    12.37717,
    75.7,
    14.21
  ],
  [
    10022,
    64.24463,
    12.37754,
    74.2,
    13.53
  ],
  [
    10024,
    64.24481,
    12.37787,
    74.1,
    12.54
  ],
  [
    10026,
    64.24499,
    12.37818,
    74.3,
    11.93
  ],
  [
    10028,
    64.24515,
    12.37847,
    74.3,
    11.19
  ],
  [
    10030,
    64.2453,
    12.37874,
    74.2,
    10.79
  ],
  [
    10032,
    64.24545,
    12.37901,
    74.2,
    10.62
  ],
  [
    10034,
    64.2456,
    12.37928,
    74.2,
    10.52
  ],
  [
    10036,
    64.24575,
    12.37954,
    74.1,
    10.39
  ],
  [
    10038,
    64.24589,
    12.37981,
    74.1,
    10.21
  ],
  [
    10040,
    64.24603,
    12.38006,
    74,
    9.81
  ],
  [
    10042,
    64.24616,
    12.3803,
    73.9,
    9.13
  ],
  [
    10044,
    64.24629,
    12.38052,
    73.9,
    8.34
  ],
  [
    10046,
    64.24639,
    12.38072,
    73.9,
    7.31
  ],
  [
    10048,
    64.24649,
    12.38089,
    73.8,
    6
  ],
  [
    10050,
    64.24656,
    12.38102,
    73.8,
    4.64
  ],
  [
    10052,
    64.24661,
    12.38112,
    73.8,
    3.25
  ],
  [
    10054,
    64.24665,
    12.38118,
    73.8,
    1.8
  ],
  [
    10056,
    64.24666,
    12.3812,
    73.5,
    0.65
  ],
  [
    10058,
    64.24666,
    12.3812,
    73.5,
    0
  ],
  [
    10060,
    64.24666,
    12.3812,
    73.5,
    0
  ],
  [
    10062,
    64.24666,
    12.3812,
    73.5,
    0
  ],
  [
    10064,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10066,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10068,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10070,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10072,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10074,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10076,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10078,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10080,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10082,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10084,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10086,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10088,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10090,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10092,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10094,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10096,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10098,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10100,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10102,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10104,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10106,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10108,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10110,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10112,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10114,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10116,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10118,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10120,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10122,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10124,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10126,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10129,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10131,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10133,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10135,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10137,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10139,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10141,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10143,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10145,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10147,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10149,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10151,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10153,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10155,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10157,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10159,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10161,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10163,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10165,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10167,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10169,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10171,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10173,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10175,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10177,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10179,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10181,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10183,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10185,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10187,
    64.24666,
    12.3812,
    73.4,
    0
  ],
  [
    10189,
    64.24666,
    12.38116,
    73.5,
    0
  ],
  [
    10191,
    64.24669,
    12.38122,
    73.5,
    0
  ],
  [
    10193,
    64.24673,
    12.38131,
    73.5,
    3.27
  ],
  [
    10195,
    64.24679,
    12.38142,
    73.6,
    3.27
  ],
  [
    10197,
    64.24687,
    12.38155,
    73.6,
    3.27
  ],
  [
    10199,
    64.24695,
    12.38169,
    73.7,
    5.35
  ],
  [
    10201,
    64.24703,
    12.38186,
    73.7,
    5.35
  ],
  [
    10203,
    64.24713,
    12.38204,
    73.8,
    6.92
  ],
  [
    10205,
    64.24724,
    12.38223,
    73.8,
    6.92
  ],
  [
    10207,
    64.24735,
    12.38244,
    73.9,
    6.92
  ],
  [
    10209,
    64.24748,
    12.38267,
    74,
    8.4
  ],
  [
    10211,
    64.24761,
    12.3829,
    74.1,
    8.4
  ],
  [
    10213,
    64.24775,
    12.38314,
    74.2,
    9.85
  ],
  [
    10215,
    64.24792,
    12.38337,
    74.4,
    9.85
  ],
  [
    10217,
    64.24809,
    12.38359,
    74.7,
    9.85
  ],
  [
    10219,
    64.24829,
    12.38378,
    74.9,
    11.47
  ],
  [
    10221,
    64.2485,
    12.38394,
    75.1,
    11.47
  ],
  [
    10223,
    64.24873,
    12.38406,
    75.4,
    12.91
  ],
  [
    10225,
    64.24897,
    12.38415,
    75.7,
    12.91
  ],
  [
    10227,
    64.24922,
    12.38418,
    76.1,
    12.91
  ],
  [
    10229,
    64.24948,
    12.38416,
    76.5,
    14.12
  ],
  [
    10231,
    64.24974,
    12.38411,
    76.8,
    14.12
  ],
  [
    10233,
    64.25001,
    12.38404,
    77.6,
    15.12
  ],
  [
    10235,
    64.25029,
    12.38398,
    78.3,
    15.12
  ],
  [
    10237,
    64.25058,
    12.38391,
    78.8,
    15.12
  ],
  [
    10239,
    64.25086,
    12.38383,
    79.2,
    16.09
  ],
  [
    10241,
    64.25115,
    12.38375,
    79.8,
    16.18
  ],
  [
    10243,
    64.25144,
    12.38367,
    80.3,
    16.18
  ],
  [
    10267,
    64.255,
    12.38778,
    84.6,
    16.18
  ],
  [
    10269,
    64.25525,
    12.3884,
    83.2,
    18.9
  ],
  [
    10271,
    64.25542,
    12.38917,
    84.1,
    18.9
  ],
  [
    10273,
    64.25556,
    12.38997,
    84.6,
    20.94
  ],
  [
    10275,
    64.25567,
    12.3908,
    83.4,
    20.94
  ],
  [
    10277,
    64.25579,
    12.39164,
    83.1,
    20.94
  ],
  [
    10279,
    64.25592,
    12.39248,
    83.4,
    21.4
  ],
  [
    10281,
    64.25604,
    12.39333,
    83.7,
    21.4
  ],
  [
    10283,
    64.25617,
    12.39419,
    84.2,
    21.83
  ],
  [
    10285,
    64.25629,
    12.39505,
    85,
    21.83
  ],
  [
    10287,
    64.25642,
    12.39591,
    85.4,
    21.83
  ],
  [
    10289,
    64.25655,
    12.39678,
    85.9,
    22.21
  ],
  [
    10291,
    64.25668,
    12.39766,
    86.1,
    22.21
  ],
  [
    10293,
    64.25682,
    12.39853,
    86.4,
    22.57
  ],
  [
    10295,
    64.25699,
    12.39939,
    86.6,
    22.57
  ],
  [
    10297,
    64.25719,
    12.40021,
    86.8,
    22.57
  ],
  [
    10299,
    64.25742,
    12.401,
    87,
    22.9
  ],
  [
    10301,
    64.25766,
    12.40178,
    87.1,
    22.9
  ],
  [
    10303,
    64.25788,
    12.40258,
    87.4,
    23.3
  ],
  [
    10305,
    64.25809,
    12.40343,
    87.6,
    23.3
  ],
  [
    10307,
    64.25827,
    12.40431,
    88.3,
    23.3
  ],
  [
    10309,
    64.25843,
    12.40522,
    88.6,
    23.73
  ],
  [
    10311,
    64.25858,
    12.40614,
    89,
    23.73
  ],
  [
    10313,
    64.25873,
    12.40706,
    89.4,
    23.96
  ],
  [
    10315,
    64.25889,
    12.40799,
    89.9,
    23.96
  ],
  [
    10317,
    64.25904,
    12.40893,
    90.3,
    23.96
  ],
  [
    10319,
    64.2592,
    12.40987,
    90.6,
    24.32
  ],
  [
    10321,
    64.25936,
    12.41081,
    91.2,
    24.32
  ],
  [
    10323,
    64.25952,
    12.41175,
    91.8,
    24.57
  ],
  [
    10325,
    64.25968,
    12.4127,
    92.3,
    24.57
  ],
  [
    10327,
    64.25984,
    12.41366,
    92.8,
    24.71
  ],
  [
    10329,
    64.26,
    12.41461,
    93.4,
    24.89
  ],
  [
    10331,
    64.26018,
    12.41556,
    93.8,
    24.99
  ],
  [
    10333,
    64.26036,
    12.4165,
    94.3,
    25.08
  ],
  [
    10335,
    64.26056,
    12.41744,
    94.7,
    25.2
  ],
  [
    10337,
    64.26077,
    12.41837,
    95.1,
    25.3
  ],
  [
    10339,
    64.26097,
    12.41932,
    95.4,
    25.43
  ],
  [
    10341,
    64.26116,
    12.42027,
    95.8,
    25.49
  ],
  [
    10343,
    64.26136,
    12.42123,
    96.2,
    25.61
  ],
  [
    10345,
    64.26154,
    12.4222,
    96.7,
    25.71
  ],
  [
    10347,
    64.26173,
    12.42318,
    97.1,
    25.8
  ],
  [
    10349,
    64.26191,
    12.42416,
    97.5,
    25.87
  ],
  [
    10351,
    64.26209,
    12.42515,
    98,
    25.95
  ],
  [
    10353,
    64.26227,
    12.42614,
    98.5,
    26.05
  ],
  [
    10355,
    64.26245,
    12.42713,
    99.1,
    26.14
  ],
  [
    10357,
    64.26264,
    12.42813,
    99.6,
    26.22
  ],
  [
    10359,
    64.26282,
    12.42913,
    100.4,
    26.29
  ],
  [
    10361,
    64.26301,
    12.43013,
    101.7,
    26.38
  ],
  [
    10363,
    64.2632,
    12.43113,
    102.7,
    26.48
  ],
  [
    10365,
    64.26338,
    12.43215,
    103.3,
    26.56
  ],
  [
    10367,
    64.26354,
    12.43318,
    103.7,
    26.63
  ],
  [
    10369,
    64.2637,
    12.43423,
    104.2,
    26.73
  ],
  [
    10371,
    64.26384,
    12.43528,
    104.9,
    26.79
  ],
  [
    10373,
    64.26397,
    12.43635,
    105.5,
    26.93
  ],
  [
    10375,
    64.26409,
    12.43743,
    106.1,
    26.94
  ],
  [
    10377,
    64.2642,
    12.43852,
    106.9,
    26.97
  ],
  [
    10379,
    64.2643,
    12.4396,
    107.6,
    26.87
  ],
  [
    10381,
    64.26439,
    12.44069,
    108.1,
    26.71
  ],
  [
    10383,
    64.26448,
    12.44176,
    108.3,
    26.55
  ],
  [
    10385,
    64.26457,
    12.44283,
    108.9,
    26.38
  ],
  [
    10387,
    64.26466,
    12.44389,
    109.5,
    26.22
  ],
  [
    10389,
    64.26475,
    12.44495,
    110.2,
    26
  ],
  [
    10391,
    64.26484,
    12.446,
    110.8,
    25.84
  ],
  [
    10393,
    64.26493,
    12.44704,
    111.5,
    25.7
  ],
  [
    10395,
    64.26502,
    12.44808,
    112.1,
    25.55
  ],
  [
    10397,
    64.26509,
    12.44912,
    112.8,
    25.5
  ],
  [
    10399,
    64.26511,
    12.45017,
    113.3,
    25.36
  ],
  [
    10401,
    64.26507,
    12.45121,
    113.8,
    25.31
  ],
  [
    10403,
    64.26498,
    12.45223,
    114,
    25.29
  ],
  [
    10405,
    64.26482,
    12.45321,
    114.2,
    25.29
  ],
  [
    10407,
    64.26462,
    12.45415,
    114.7,
    25.31
  ],
  [
    10409,
    64.26439,
    12.45504,
    115.4,
    25.22
  ],
  [
    10411,
    64.26415,
    12.45594,
    116,
    25.7
  ],
  [
    10413,
    64.26389,
    12.45684,
    116.7,
    25.74
  ],
  [
    10415,
    64.26373,
    12.45785,
    116.7,
    25.28
  ],
  [
    10417,
    64.26366,
    12.45891,
    117.7,
    25.69
  ],
  [
    10419,
    64.26364,
    12.45997,
    118.2,
    25.76
  ],
  [
    10421,
    64.26368,
    12.46104,
    118.6,
    25.86
  ],
  [
    10423,
    64.26378,
    12.46209,
    119.2,
    25.94
  ],
  [
    10425,
    64.26393,
    12.46312,
    119.9,
    26.05
  ],
  [
    10427,
    64.2641,
    12.46412,
    120.3,
    26.11
  ],
  [
    10429,
    64.26428,
    12.46513,
    121.1,
    26.26
  ],
  [
    10431,
    64.26444,
    12.46616,
    121.7,
    26.35
  ],
  [
    10433,
    64.26458,
    12.4672,
    122.3,
    26.41
  ],
  [
    10435,
    64.2647,
    12.46825,
    122.8,
    26.48
  ],
  [
    10437,
    64.2648,
    12.46933,
    123.5,
    26.52
  ],
  [
    10439,
    64.26487,
    12.47041,
    124,
    26.64
  ],
  [
    10441,
    64.26492,
    12.47151,
    124.6,
    26.7
  ],
  [
    10443,
    64.26495,
    12.47261,
    125.2,
    26.77
  ],
  [
    10445,
    64.26498,
    12.47372,
    125.6,
    26.87
  ],
  [
    10447,
    64.26501,
    12.47482,
    126,
    26.85
  ],
  [
    10449,
    64.26506,
    12.47593,
    126.4,
    27
  ],
  [
    10451,
    64.26513,
    12.47704,
    126.9,
    27.1
  ],
  [
    10453,
    64.26522,
    12.47814,
    127.5,
    27.18
  ],
  [
    10455,
    64.26534,
    12.47923,
    128.1,
    27.23
  ],
  [
    10457,
    64.26549,
    12.4803,
    128.7,
    27.26
  ],
  [
    10459,
    64.26565,
    12.48137,
    129.4,
    27.34
  ],
  [
    10461,
    64.26583,
    12.48242,
    130.1,
    27.38
  ],
  [
    10463,
    64.26602,
    12.48347,
    130.8,
    27.43
  ],
  [
    10465,
    64.26621,
    12.48451,
    131.5,
    27.42
  ],
  [
    10467,
    64.26642,
    12.48553,
    132.4,
    27.44
  ],
  [
    10469,
    64.26665,
    12.48654,
    132.9,
    27.48
  ],
  [
    10471,
    64.2669,
    12.48752,
    133.4,
    27.51
  ],
  [
    10473,
    64.26717,
    12.48847,
    134,
    27.61
  ],
  [
    10475,
    64.26747,
    12.48939,
    134.8,
    27.7
  ],
  [
    10477,
    64.26778,
    12.49029,
    135.6,
    27.72
  ],
  [
    10479,
    64.2681,
    12.49117,
    136.3,
    27.81
  ],
  [
    10481,
    64.26842,
    12.49205,
    137.1,
    27.86
  ],
  [
    10483,
    64.26874,
    12.49293,
    137.8,
    27.9
  ],
  [
    10485,
    64.26907,
    12.49381,
    138.5,
    27.98
  ],
  [
    10487,
    64.26939,
    12.4947,
    138.9,
    28
  ],
  [
    10489,
    64.26971,
    12.49559,
    139.2,
    28.13
  ],
  [
    10491,
    64.27004,
    12.49648,
    139.5,
    28.3
  ],
  [
    10493,
    64.27037,
    12.49738,
    139.9,
    28.43
  ],
  [
    10495,
    64.2707,
    12.49828,
    140.5,
    28.57
  ],
  [
    10497,
    64.27103,
    12.49919,
    141.1,
    28.61
  ],
  [
    10499,
    64.27136,
    12.5001,
    141.8,
    28.7
  ],
  [
    10501,
    64.27169,
    12.50101,
    142.4,
    28.73
  ],
  [
    10503,
    64.27202,
    12.50192,
    143.2,
    28.76
  ],
  [
    10505,
    64.27235,
    12.50283,
    143.9,
    28.79
  ],
  [
    10507,
    64.27269,
    12.50374,
    144.6,
    28.78
  ],
  [
    10509,
    64.27302,
    12.50466,
    145.4,
    28.81
  ],
  [
    10511,
    64.27335,
    12.50557,
    146,
    28.81
  ],
  [
    10513,
    64.27368,
    12.50648,
    146.7,
    28.84
  ],
  [
    10515,
    64.27402,
    12.5074,
    147.4,
    28.88
  ],
  [
    10517,
    64.27435,
    12.50831,
    148,
    28.91
  ],
  [
    10519,
    64.27468,
    12.50923,
    148.7,
    28.93
  ],
  [
    10521,
    64.27501,
    12.51015,
    149.3,
    28.96
  ],
  [
    10523,
    64.27535,
    12.51107,
    150,
    28.99
  ],
  [
    10525,
    64.27568,
    12.51199,
    150.7,
    29.02
  ],
  [
    10527,
    64.27602,
    12.51291,
    151.4,
    29.04
  ],
  [
    10529,
    64.27635,
    12.51383,
    152,
    29.07
  ],
  [
    10531,
    64.27668,
    12.51475,
    152.8,
    29.09
  ],
  [
    10533,
    64.27702,
    12.51567,
    153.5,
    29.1
  ],
  [
    10535,
    64.27735,
    12.5166,
    154.2,
    29.13
  ],
  [
    10537,
    64.27769,
    12.51752,
    154.9,
    29.15
  ],
  [
    10539,
    64.27803,
    12.51845,
    155.5,
    29.17
  ],
  [
    10541,
    64.27836,
    12.51937,
    156.3,
    29.22
  ],
  [
    10543,
    64.2787,
    12.52029,
    156.9,
    29.2
  ],
  [
    10545,
    64.27905,
    12.52119,
    157.5,
    29.24
  ],
  [
    10547,
    64.2794,
    12.52209,
    158,
    29.27
  ],
  [
    10549,
    64.27976,
    12.52298,
    158.4,
    29.32
  ],
  [
    10551,
    64.28011,
    12.52388,
    158.9,
    29.37
  ],
  [
    10553,
    64.28047,
    12.52477,
    159.6,
    29.44
  ],
  [
    10555,
    64.28083,
    12.52567,
    160.3,
    29.46
  ],
  [
    10557,
    64.28118,
    12.52657,
    161.1,
    29.48
  ],
  [
    10559,
    64.28154,
    12.52747,
    161.6,
    29.5
  ],
  [
    10561,
    64.2819,
    12.52836,
    161.7,
    29.56
  ],
  [
    10563,
    64.28226,
    12.52927,
    161.8,
    29.71
  ],
  [
    10565,
    64.28262,
    12.53017,
    161.8,
    29.87
  ],
  [
    10567,
    64.28298,
    12.53109,
    161.8,
    30.07
  ],
  [
    10569,
    64.28335,
    12.53201,
    161.9,
    30.38
  ],
  [
    10571,
    64.28372,
    12.53294,
    161.9,
    30.51
  ],
  [
    10573,
    64.28409,
    12.53387,
    161.9,
    30.72
  ],
  [
    10575,
    64.28446,
    12.53481,
    162,
    30.89
  ],
  [
    10577,
    64.28484,
    12.53575,
    162,
    31.09
  ],
  [
    10579,
    64.28522,
    12.5367,
    162.2,
    31.07
  ],
  [
    10581,
    64.28559,
    12.53765,
    162.5,
    30.92
  ],
  [
    10583,
    64.28596,
    12.5386,
    162.8,
    30.8
  ],
  [
    10585,
    64.2863,
    12.5396,
    163,
    30.61
  ],
  [
    10587,
    64.28661,
    12.54064,
    162.9,
    30.45
  ],
  [
    10589,
    64.2869,
    12.54171,
    162.8,
    30.37
  ],
  [
    10591,
    64.28716,
    12.54281,
    162.8,
    30.31
  ],
  [
    10593,
    64.28743,
    12.54389,
    162.8,
    30.25
  ],
  [
    10595,
    64.28771,
    12.54496,
    162.9,
    30.12
  ],
  [
    10597,
    64.288,
    12.54601,
    163,
    30.06
  ],
  [
    10599,
    64.28829,
    12.54705,
    162.8,
    29.94
  ],
  [
    10601,
    64.2886,
    12.54805,
    163,
    29.33
  ],
  [
    10603,
    64.28891,
    12.54902,
    163.1,
    28.6
  ],
  [
    10605,
    64.28921,
    12.54995,
    163.2,
    27.81
  ],
  [
    10607,
    64.28952,
    12.55084,
    163.3,
    27
  ],
  [
    10609,
    64.28982,
    12.55169,
    163.4,
    26.54
  ],
  [
    10611,
    64.29013,
    12.55252,
    163.4,
    26.37
  ],
  [
    10613,
    64.29044,
    12.55333,
    163.6,
    26.11
  ],
  [
    10615,
    64.29076,
    12.55412,
    163.8,
    26.02
  ],
  [
    10617,
    64.29108,
    12.55489,
    163.9,
    25.9
  ],
  [
    10619,
    64.29141,
    12.55564,
    164.1,
    25.79
  ],
  [
    10621,
    64.29175,
    12.55636,
    164.3,
    25.64
  ],
  [
    10623,
    64.29209,
    12.55706,
    164.5,
    25.5
  ],
  [
    10625,
    64.29244,
    12.55774,
    164.7,
    25.32
  ],
  [
    10627,
    64.29279,
    12.55839,
    164.7,
    25.23
  ],
  [
    10629,
    64.29315,
    12.55902,
    165,
    25.19
  ],
  [
    10631,
    64.29352,
    12.55963,
    165.3,
    25.14
  ],
  [
    10633,
    64.2939,
    12.56019,
    165.5,
    25.12
  ],
  [
    10635,
    64.2943,
    12.56064,
    165.4,
    25.1
  ],
  [
    10637,
    64.29473,
    12.56097,
    165.2,
    25.11
  ],
  [
    10639,
    64.29517,
    12.56118,
    165.1,
    25.18
  ],
  [
    10641,
    64.29563,
    12.56125,
    164.9,
    25.26
  ],
  [
    10643,
    64.29608,
    12.56119,
    164.6,
    25.37
  ],
  [
    10645,
    64.29653,
    12.561,
    164.4,
    25.49
  ],
  [
    10647,
    64.29698,
    12.5607,
    164.1,
    25.65
  ],
  [
    10649,
    64.29742,
    12.56037,
    163.9,
    25.78
  ],
  [
    10651,
    64.29786,
    12.56004,
    163.7,
    25.95
  ],
  [
    10653,
    64.29831,
    12.55973,
    163.6,
    26.14
  ],
  [
    10655,
    64.29877,
    12.55942,
    163.5,
    26.35
  ],
  [
    10657,
    64.29923,
    12.55912,
    163.6,
    26.51
  ],
  [
    10659,
    64.29969,
    12.55884,
    164,
    26.7
  ],
  [
    10661,
    64.30016,
    12.55856,
    164.6,
    26.82
  ],
  [
    10663,
    64.30063,
    12.55827,
    164.9,
    26.89
  ],
  [
    10665,
    64.30109,
    12.55799,
    165.3,
    27
  ],
  [
    10667,
    64.30157,
    12.55771,
    165.8,
    27.08
  ],
  [
    10669,
    64.30204,
    12.55743,
    166.4,
    27.17
  ],
  [
    10671,
    64.30251,
    12.55715,
    166.9,
    27.23
  ],
  [
    10673,
    64.30299,
    12.55686,
    167.5,
    27.27
  ],
  [
    10675,
    64.30346,
    12.55658,
    167.9,
    27.33
  ],
  [
    10677,
    64.30394,
    12.5563,
    168.4,
    27.42
  ],
  [
    10679,
    64.30442,
    12.55602,
    168.9,
    27.51
  ],
  [
    10681,
    64.3049,
    12.55573,
    169.4,
    27.57
  ],
  [
    10683,
    64.30538,
    12.55545,
    169.8,
    27.81
  ],
  [
    10685,
    64.30586,
    12.55516,
    169.6,
    27.89
  ],
  [
    10687,
    64.30635,
    12.55487,
    169.4,
    28.07
  ],
  [
    10689,
    64.30684,
    12.55458,
    169.2,
    28.26
  ],
  [
    10691,
    64.30733,
    12.55429,
    169.1,
    28.43
  ],
  [
    10693,
    64.30783,
    12.554,
    169.3,
    28.56
  ],
  [
    10695,
    64.30833,
    12.5537,
    169.5,
    28.67
  ],
  [
    10697,
    64.30883,
    12.55341,
    169.9,
    28.81
  ],
  [
    10699,
    64.30933,
    12.55311,
    170.3,
    28.92
  ],
  [
    10701,
    64.30984,
    12.55281,
    170.6,
    29.03
  ],
  [
    10703,
    64.31034,
    12.55251,
    171,
    29.17
  ],
  [
    10705,
    64.31085,
    12.55222,
    171.6,
    29.31
  ],
  [
    10707,
    64.31136,
    12.55192,
    172.1,
    29.37
  ],
  [
    10709,
    64.31187,
    12.55161,
    172.4,
    29.48
  ],
  [
    10711,
    64.31239,
    12.55131,
    172.7,
    29.63
  ],
  [
    10713,
    64.3129,
    12.55101,
    173.3,
    29.73
  ],
  [
    10715,
    64.31342,
    12.5507,
    174,
    29.81
  ],
  [
    10717,
    64.31394,
    12.5504,
    174.6,
    29.83
  ],
  [
    10719,
    64.31446,
    12.55009,
    175.3,
    29.85
  ],
  [
    10721,
    64.31498,
    12.54979,
    176.1,
    29.89
  ],
  [
    10723,
    64.3155,
    12.54949,
    176.7,
    29.97
  ],
  [
    10725,
    64.31603,
    12.54921,
    177.5,
    29.85
  ],
  [
    10727,
    64.31655,
    12.54897,
    178,
    29.97
  ],
  [
    10729,
    64.31708,
    12.54877,
    178.6,
    29.98
  ],
  [
    10731,
    64.31762,
    12.54858,
    179,
    30.08
  ],
  [
    10733,
    64.31815,
    12.5484,
    179.6,
    30.27
  ],
  [
    10735,
    64.31869,
    12.5482,
    179.7,
    30.43
  ],
  [
    10737,
    64.31923,
    12.54798,
    180,
    30.6
  ],
  [
    10739,
    64.31977,
    12.54773,
    180.4,
    30.74
  ],
  [
    10741,
    64.32031,
    12.54743,
    181,
    30.83
  ],
  [
    10743,
    64.32085,
    12.54711,
    181.7,
    30.86
  ],
  [
    10745,
    64.32138,
    12.54679,
    182.2,
    30.86
  ],
  [
    10747,
    64.32192,
    12.54648,
    182.7,
    30.83
  ],
  [
    10749,
    64.32245,
    12.54615,
    183.2,
    30.78
  ],
  [
    10751,
    64.32299,
    12.54584,
    183.7,
    30.67
  ],
  [
    10753,
    64.32352,
    12.54552,
    184.2,
    30.55
  ],
  [
    10755,
    64.32405,
    12.5452,
    184.7,
    30.41
  ],
  [
    10757,
    64.32458,
    12.54489,
    185.1,
    30.26
  ],
  [
    10759,
    64.3251,
    12.54458,
    185.6,
    30.13
  ],
  [
    10761,
    64.32563,
    12.54427,
    186.2,
    29.99
  ],
  [
    10763,
    64.32615,
    12.54396,
    186.9,
    29.82
  ],
  [
    10765,
    64.32666,
    12.54365,
    187.4,
    29.68
  ],
  [
    10767,
    64.32718,
    12.54335,
    187.9,
    29.54
  ],
  [
    10769,
    64.32769,
    12.54305,
    188.7,
    29.42
  ],
  [
    10771,
    64.3282,
    12.54275,
    189.6,
    29.29
  ],
  [
    10773,
    64.32871,
    12.54245,
    190.3,
    29.13
  ],
  [
    10775,
    64.32921,
    12.54214,
    191,
    28.99
  ],
  [
    10777,
    64.32971,
    12.5418,
    191.7,
    28.88
  ],
  [
    10779,
    64.33019,
    12.54136,
    192.3,
    28.81
  ],
  [
    10781,
    64.33065,
    12.5408,
    192.8,
    28.76
  ],
  [
    10783,
    64.33107,
    12.54012,
    193.3,
    28.79
  ],
  [
    10785,
    64.33146,
    12.53934,
    194,
    28.79
  ],
  [
    10787,
    64.33181,
    12.53846,
    194.5,
    28.84
  ],
  [
    10789,
    64.33212,
    12.53749,
    194.4,
    28.94
  ],
  [
    10791,
    64.33238,
    12.53645,
    194.5,
    29.01
  ],
  [
    10793,
    64.33259,
    12.53535,
    195.5,
    29.09
  ],
  [
    10795,
    64.33274,
    12.53419,
    196.1,
    29.15
  ],
  [
    10797,
    64.33284,
    12.533,
    196.1,
    29.21
  ],
  [
    10799,
    64.33292,
    12.53179,
    196.1,
    29.24
  ],
  [
    10801,
    64.33302,
    12.5306,
    198.5,
    29.27
  ],
  [
    10803,
    64.33317,
    12.52944,
    198.5,
    29.28
  ],
  [
    10805,
    64.33338,
    12.52832,
    198.5,
    29.33
  ],
  [
    10807,
    64.33363,
    12.52725,
    198.5,
    29.37
  ],
  [
    10809,
    64.33392,
    12.52624,
    198.5,
    29.43
  ],
  [
    10811,
    64.33426,
    12.5253,
    201.5,
    29.45
  ],
  [
    10813,
    64.33463,
    12.52444,
    202.1,
    29.46
  ],
  [
    10815,
    64.33504,
    12.52367,
    202.7,
    29.51
  ],
  [
    10817,
    64.33549,
    12.52301,
    203.1,
    29.55
  ],
  [
    10819,
    64.33597,
    12.52247,
    203.3,
    29.61
  ],
  [
    10821,
    64.33647,
    12.52204,
    203.6,
    29.69
  ],
  [
    10823,
    64.33699,
    12.52175,
    204.4,
    29.77
  ],
  [
    10825,
    64.33752,
    12.52158,
    205,
    29.82
  ],
  [
    10827,
    64.33805,
    12.52154,
    205.7,
    29.81
  ],
  [
    10829,
    64.33859,
    12.52165,
    206.2,
    29.82
  ],
  [
    10831,
    64.33912,
    12.52188,
    206.7,
    29.84
  ],
  [
    10833,
    64.33964,
    12.52216,
    207.1,
    29.88
  ],
  [
    10835,
    64.34017,
    12.52245,
    207.7,
    29.92
  ],
  [
    10837,
    64.34069,
    12.52274,
    208.4,
    29.97
  ],
  [
    10839,
    64.34122,
    12.52303,
    209.2,
    30
  ],
  [
    10841,
    64.34175,
    12.52332,
    210,
    30.04
  ],
  [
    10843,
    64.34227,
    12.5236,
    210.6,
    30.01
  ],
  [
    10845,
    64.3428,
    12.52389,
    211.1,
    30.04
  ],
  [
    10847,
    64.34333,
    12.52418,
    211.6,
    30.11
  ],
  [
    10849,
    64.34385,
    12.52447,
    212.1,
    30.12
  ],
  [
    10852,
    64.34465,
    12.5249,
    212.6,
    30.18
  ],
  [
    10854,
    64.34518,
    12.52519,
    212.8,
    30.28
  ],
  [
    10856,
    64.34571,
    12.52547,
    213,
    30.42
  ],
  [
    10858,
    64.34625,
    12.52576,
    213,
    30.59
  ],
  [
    10860,
    64.34679,
    12.52606,
    213,
    30.76
  ],
  [
    10862,
    64.34733,
    12.52634,
    213,
    31
  ],
  [
    10864,
    64.34788,
    12.52659,
    213.3,
    31.16
  ],
  [
    10866,
    64.34843,
    12.52683,
    214.2,
    31.33
  ],
  [
    10868,
    64.34899,
    12.52703,
    214.9,
    31.42
  ],
  [
    10870,
    64.34955,
    12.5272,
    215.7,
    31.22
  ],
  [
    10872,
    64.3501,
    12.52735,
    216.6,
    30.94
  ],
  [
    10874,
    64.35065,
    12.52747,
    217.3,
    30.89
  ],
  [
    10876,
    64.3512,
    12.52756,
    217.8,
    30.89
  ],
  [
    10878,
    64.35174,
    12.52762,
    217.9,
    30.25
  ],
  [
    10880,
    64.35228,
    12.52766,
    217.7,
    30.25
  ],
  [
    10882,
    64.35282,
    12.52768,
    217.5,
    30.25
  ],
  [
    10884,
    64.35336,
    12.52768,
    217.3,
    30.08
  ],
  [
    10886,
    64.35388,
    12.52768,
    217.2,
    30.08
  ],
  [
    10888,
    64.3544,
    12.52768,
    216.9,
    28.9
  ],
  [
    10890,
    64.35491,
    12.52768,
    216.2,
    28.9
  ],
  [
    10892,
    64.35542,
    12.52771,
    215.5,
    28.9
  ],
  [
    10894,
    64.35592,
    12.52776,
    214.9,
    28.11
  ],
  [
    10896,
    64.35642,
    12.52782,
    214.3,
    28.11
  ],
  [
    10898,
    64.35692,
    12.52791,
    213.7,
    27.9
  ],
  [
    10900,
    64.35741,
    12.52801,
    213.1,
    27.9
  ],
  [
    10902,
    64.3579,
    12.52809,
    212.9,
    27.9
  ],
  [
    10904,
    64.35837,
    12.52807,
    212.4,
    26.7
  ],
  [
    10906,
    64.35884,
    12.52791,
    212.1,
    26.7
  ],
  [
    10908,
    64.35929,
    12.52764,
    211.8,
    26.31
  ],
  [
    10910,
    64.35975,
    12.52732,
    211.4,
    26.31
  ],
  [
    10912,
    64.3602,
    12.527,
    210.9,
    26.31
  ],
  [
    10914,
    64.36065,
    12.52661,
    210.6,
    26.5
  ],
  [
    10916,
    64.36107,
    12.52611,
    210.2,
    26.5
  ],
  [
    10918,
    64.36146,
    12.52547,
    209.5,
    26.63
  ],
  [
    10920,
    64.36183,
    12.52476,
    208.7,
    26.63
  ],
  [
    10922,
    64.36221,
    12.52407,
    208.1,
    26.63
  ],
  [
    10924,
    64.36262,
    12.52348,
    207.6,
    26.88
  ],
  [
    10926,
    64.36307,
    12.52303,
    207,
    26.88
  ],
  [
    10928,
    64.36353,
    12.52272,
    206.4,
    27.04
  ],
  [
    10930,
    64.36401,
    12.5225,
    206,
    27.04
  ],
  [
    10932,
    64.36449,
    12.52229,
    205.7,
    27.04
  ],
  [
    10934,
    64.36497,
    12.52208,
    205.3,
    27.22
  ],
  [
    10936,
    64.36544,
    12.52183,
    204.9,
    27.29
  ],
  [
    10938,
    64.36591,
    12.52149,
    204.5,
    27.34
  ],
  [
    10940,
    64.36637,
    12.52106,
    204.1,
    27.38
  ],
  [
    10942,
    64.36681,
    12.52058,
    203.8,
    27.45
  ],
  [
    10944,
    64.36726,
    12.52009,
    203.5,
    27.53
  ],
  [
    10946,
    64.3677,
    12.51959,
    203.1,
    27.07
  ],
  [
    10948,
    64.36815,
    12.51911,
    202.7,
    27.55
  ],
  [
    10950,
    64.36861,
    12.51869,
    202.1,
    27.59
  ],
  [
    10952,
    64.36908,
    12.51835,
    201.4,
    27.64
  ],
  [
    10954,
    64.36957,
    12.51807,
    201,
    27.7
  ],
  [
    10956,
    64.37005,
    12.51781,
    200.5,
    27.8
  ],
  [
    10958,
    64.37054,
    12.51752,
    200,
    27.92
  ],
  [
    10960,
    64.37102,
    12.51716,
    199.4,
    27.98
  ],
  [
    10962,
    64.37148,
    12.51672,
    198.7,
    28.09
  ],
  [
    10964,
    64.37195,
    12.51624,
    198,
    28.21
  ],
  [
    10966,
    64.37241,
    12.51576,
    197.3,
    28.33
  ],
  [
    10968,
    64.37288,
    12.51528,
    196.5,
    28.46
  ],
  [
    10970,
    64.37335,
    12.51479,
    195.8,
    28.57
  ],
  [
    10972,
    64.37382,
    12.5143,
    195.1,
    28.66
  ],
  [
    10974,
    64.37428,
    12.51381,
    194.5,
    28.65
  ],
  [
    10976,
    64.37475,
    12.51333,
    193.8,
    28.49
  ],
  [
    10978,
    64.37521,
    12.51285,
    193.2,
    28.28
  ],
  [
    10980,
    64.37567,
    12.51237,
    192.6,
    28.13
  ],
  [
    10982,
    64.37613,
    12.51186,
    192.1,
    28.06
  ],
  [
    10984,
    64.37656,
    12.51128,
    191.5,
    27.98
  ],
  [
    10986,
    64.37697,
    12.51062,
    191,
    27.63
  ],
  [
    10988,
    64.37735,
    12.50992,
    190.3,
    26.84
  ],
  [
    10990,
    64.37767,
    12.50899,
    189.1,
    27.91
  ],
  [
    10992,
    64.37797,
    12.50806,
    189.2,
    27.86
  ],
  [
    10994,
    64.37824,
    12.50707,
    188.7,
    27.67
  ],
  [
    10996,
    64.37846,
    12.50605,
    188.3,
    27.61
  ],
  [
    10998,
    64.37867,
    12.505,
    187.8,
    27.6
  ],
  [
    11000,
    64.37886,
    12.50395,
    187.3,
    27.65
  ],
  [
    11002,
    64.37904,
    12.50289,
    187,
    26.32
  ],
  [
    11004,
    64.37924,
    12.50183,
    186.8,
    27.48
  ],
  [
    11006,
    64.37944,
    12.50079,
    186.4,
    27.31
  ],
  [
    11008,
    64.37965,
    12.49977,
    186.2,
    27.15
  ],
  [
    11010,
    64.37987,
    12.49877,
    185.8,
    27.02
  ],
  [
    11012,
    64.38009,
    12.49777,
    185.3,
    27.02
  ],
  [
    11018,
    64.38101,
    12.49519,
    184.6,
    27.02
  ],
  [
    11020,
    64.38141,
    12.49451,
    185,
    27.02
  ],
  [
    11022,
    64.38181,
    12.49398,
    184.8,
    27.02
  ],
  [
    11024,
    64.38223,
    12.49349,
    184.7,
    25.97
  ],
  [
    11026,
    64.38264,
    12.493,
    184.7,
    25.97
  ],
  [
    11028,
    64.38302,
    12.49242,
    183,
    25.97
  ],
  [
    11030,
    64.3834,
    12.4918,
    182.6,
    25.55
  ],
  [
    11032,
    64.38373,
    12.49103,
    181.9,
    25.55
  ],
  [
    11034,
    64.38402,
    12.49019,
    181.6,
    25.87
  ],
  [
    11036,
    64.38424,
    12.48927,
    182,
    25.87
  ],
  [
    11038,
    64.38443,
    12.48831,
    181.4,
    25.38
  ],
  [
    11040,
    64.38462,
    12.48735,
    180.9,
    25.38
  ],
  [
    11041,
    64.38471,
    12.48687,
    180.7,
    25.38
  ],
  [
    11048,
    64.38525,
    12.48323,
    178.7,
    26.3
  ],
  [
    11050,
    64.38542,
    12.48221,
    178.5,
    26.4
  ],
  [
    11052,
    64.38561,
    12.4812,
    177.9,
    26.57
  ],
  [
    11054,
    64.38581,
    12.4802,
    177.6,
    26.66
  ],
  [
    11056,
    64.38602,
    12.47919,
    177.6,
    26.8
  ],
  [
    11058,
    64.38624,
    12.4782,
    177,
    26.8
  ],
  [
    11060,
    64.38645,
    12.47721,
    176.6,
    26.8
  ],
  [
    11065,
    64.3868,
    12.47457,
    175.7,
    27.27
  ],
  [
    11067,
    64.38692,
    12.47346,
    176.9,
    27.3
  ],
  [
    11069,
    64.38694,
    12.47233,
    177.2,
    27.39
  ],
  [
    11071,
    64.38691,
    12.4712,
    176.6,
    27.5
  ],
  [
    11073,
    64.38682,
    12.47007,
    176.2,
    27.63
  ],
  [
    11075,
    64.38667,
    12.46897,
    176.2,
    27.73
  ],
  [
    11077,
    64.3865,
    12.46789,
    175.6,
    27.86
  ],
  [
    11079,
    64.38633,
    12.4668,
    175.2,
    27.99
  ],
  [
    11081,
    64.38615,
    12.46571,
    174.6,
    28.13
  ],
  [
    11083,
    64.38597,
    12.46461,
    174.1,
    28.24
  ],
  [
    11085,
    64.3858,
    12.46352,
    173.5,
    27.87
  ],
  [
    11087,
    64.38563,
    12.46244,
    172.9,
    27.57
  ],
  [
    11089,
    64.38546,
    12.46137,
    172.3,
    27.31
  ],
  [
    11091,
    64.38532,
    12.46029,
    171.7,
    27.16
  ],
  [
    11093,
    64.38521,
    12.4592,
    171,
    27.1
  ],
  [
    11095,
    64.38515,
    12.45808,
    170.5,
    27.22
  ],
  [
    11097,
    64.38514,
    12.45695,
    170.1,
    27.33
  ],
  [
    11099,
    64.38517,
    12.45582,
    169.6,
    27.41
  ],
  [
    11101,
    64.38523,
    12.45469,
    169.2,
    27.48
  ],
  [
    11103,
    64.38528,
    12.45356,
    168.6,
    27.48
  ],
  [
    11105,
    64.38536,
    12.45244,
    168,
    27.41
  ],
  [
    11107,
    64.38525,
    12.45132,
    167.2,
    27.69
  ],
  [
    11109,
    64.3851,
    12.45022,
    166.8,
    27.69
  ],
  [
    11111,
    64.38489,
    12.44916,
    166.2,
    27.63
  ],
  [
    11113,
    64.38466,
    12.44815,
    165.7,
    27.63
  ],
  [
    11115,
    64.38442,
    12.44714,
    165.2,
    27.63
  ],
  [
    11117,
    64.38421,
    12.4461,
    164.7,
    27.59
  ],
  [
    11119,
    64.38405,
    12.44502,
    164.1,
    27.54
  ],
  [
    11121,
    64.38395,
    12.4439,
    163.3,
    27.5
  ],
  [
    11123,
    64.3839,
    12.44277,
    162.7,
    27.44
  ],
  [
    11125,
    64.38391,
    12.44163,
    162.2,
    27.4
  ],
  [
    11127,
    64.38397,
    12.4405,
    161.7,
    27.35
  ],
  [
    11129,
    64.38409,
    12.4394,
    162.4,
    27.2
  ],
  [
    11131,
    64.38421,
    12.43831,
    162.5,
    27.2
  ],
  [
    11133,
    64.38434,
    12.43722,
    162.6,
    27.2
  ],
  [
    11146,
    64.38516,
    12.4304,
    163.7,
    27
  ],
  [
    11148,
    64.38524,
    12.42916,
    160.4,
    27.22
  ],
  [
    11150,
    64.38521,
    12.42802,
    158.9,
    27.33
  ],
  [
    11152,
    64.38513,
    12.4269,
    157,
    27.38
  ],
  [
    11154,
    64.385,
    12.42579,
    156,
    27.54
  ],
  [
    11156,
    64.38482,
    12.42472,
    155,
    27.62
  ],
  [
    11158,
    64.38459,
    12.4237,
    154.3,
    27.7
  ],
  [
    11160,
    64.38434,
    12.42271,
    153.8,
    27.71
  ],
  [
    11162,
    64.38407,
    12.42173,
    153.4,
    27.71
  ],
  [
    11164,
    64.38382,
    12.42075,
    152.8,
    27.71
  ],
  [
    11166,
    64.38358,
    12.41974,
    152,
    27.72
  ],
  [
    11168,
    64.38335,
    12.41872,
    151.2,
    27.72
  ],
  [
    11170,
    64.38315,
    12.41767,
    150.6,
    27.71
  ],
  [
    11172,
    64.38296,
    12.41661,
    150,
    27.7
  ],
  [
    11174,
    64.38279,
    12.41553,
    149.3,
    27.66
  ],
  [
    11176,
    64.38263,
    12.41444,
    148.7,
    27.64
  ],
  [
    11178,
    64.3825,
    12.41334,
    148.2,
    27.62
  ],
  [
    11180,
    64.38239,
    12.41222,
    147.7,
    27.6
  ],
  [
    11182,
    64.38232,
    12.41109,
    146.9,
    27.54
  ],
  [
    11184,
    64.38231,
    12.40995,
    146.5,
    27.47
  ],
  [
    11186,
    64.38234,
    12.40881,
    145.7,
    27.39
  ],
  [
    11188,
    64.38238,
    12.40768,
    145.1,
    27.37
  ],
  [
    11190,
    64.38242,
    12.40655,
    144.5,
    27.35
  ],
  [
    11192,
    64.38247,
    12.40542,
    143.7,
    27.34
  ],
  [
    11194,
    64.38254,
    12.4043,
    143.4,
    27.32
  ],
  [
    11196,
    64.38267,
    12.4032,
    142.9,
    27.3
  ],
  [
    11198,
    64.38286,
    12.40215,
    142.7,
    27.25
  ],
  [
    11200,
    64.38309,
    12.40116,
    142.3,
    27.15
  ],
  [
    11202,
    64.38334,
    12.40019,
    141.8,
    27.11
  ],
  [
    11204,
    64.38359,
    12.39922,
    141.3,
    27.48
  ],
  [
    11206,
    64.38381,
    12.39817,
    141.1,
    27.44
  ],
  [
    11208,
    64.38395,
    12.39711,
    140.6,
    26.91
  ],
  [
    11210,
    64.38404,
    12.39601,
    140.3,
    26.87
  ],
  [
    11212,
    64.38406,
    12.39491,
    139.9,
    26.8
  ],
  [
    11214,
    64.38403,
    12.3938,
    139.5,
    26.77
  ],
  [
    11216,
    64.38395,
    12.39271,
    139.6,
    26.69
  ],
  [
    11218,
    64.38381,
    12.39165,
    138.7,
    26.67
  ],
  [
    11220,
    64.38365,
    12.3906,
    137.7,
    26.63
  ],
  [
    11222,
    64.3835,
    12.38956,
    137.1,
    26.11
  ],
  [
    11224,
    64.38335,
    12.38855,
    136.6,
    25.68
  ],
  [
    11226,
    64.38319,
    12.38747,
    136,
    30.58
  ],
  [
    11228,
    64.38304,
    12.38649,
    135.4,
    24.65
  ],
  [
    11230,
    64.3829,
    12.38556,
    134.9,
    23.87
  ],
  [
    11232,
    64.38277,
    12.38466,
    134.4,
    22.96
  ],
  [
    11234,
    64.38264,
    12.38379,
    133.9,
    22.15
  ],
  [
    11236,
    64.38251,
    12.38294,
    133.5,
    21.76
  ],
  [
    11238,
    64.38237,
    12.3821,
    132.7,
    21.62
  ],
  [
    11240,
    64.38223,
    12.38127,
    131.7,
    21.63
  ],
  [
    11242,
    64.38208,
    12.38044,
    131,
    21.7
  ],
  [
    11244,
    64.38193,
    12.37961,
    130.5,
    21.84
  ],
  [
    11246,
    64.38178,
    12.37878,
    130.1,
    22
  ],
  [
    11248,
    64.38162,
    12.37794,
    129.6,
    22.15
  ],
  [
    11250,
    64.38147,
    12.37709,
    129.3,
    22.3
  ],
  [
    11252,
    64.38135,
    12.37621,
    129,
    22.42
  ],
  [
    11254,
    64.38128,
    12.3753,
    128.5,
    22.49
  ],
  [
    11256,
    64.38127,
    12.37437,
    128.1,
    22.55
  ],
  [
    11258,
    64.38133,
    12.37344,
    127.9,
    22.63
  ],
  [
    11260,
    64.38144,
    12.37254,
    126.9,
    22.69
  ],
  [
    11262,
    64.38162,
    12.37169,
    126.9,
    22.8
  ],
  [
    11264,
    64.38186,
    12.3709,
    127,
    22.89
  ],
  [
    11266,
    64.38214,
    12.37019,
    127.1,
    22.97
  ],
  [
    11268,
    64.38247,
    12.3696,
    127.3,
    23.06
  ],
  [
    11270,
    64.38283,
    12.36913,
    127.4,
    23.16
  ],
  [
    11272,
    64.38323,
    12.36879,
    127.2,
    23.31
  ],
  [
    11274,
    64.38364,
    12.36856,
    126.8,
    23.47
  ],
  [
    11276,
    64.38406,
    12.36836,
    126.6,
    23.62
  ],
  [
    11278,
    64.38448,
    12.36816,
    126.2,
    23.82
  ],
  [
    11280,
    64.3849,
    12.36793,
    125.8,
    23.95
  ],
  [
    11282,
    64.38532,
    12.36769,
    125.2,
    23.95
  ],
  [
    11284,
    64.38576,
    12.3674,
    125.2,
    24.77
  ],
  [
    11286,
    64.3862,
    12.36707,
    125.7,
    24.12
  ],
  [
    11288,
    64.38661,
    12.36666,
    124.7,
    23.63
  ],
  [
    11290,
    64.38699,
    12.36621,
    124.3,
    23.44
  ],
  [
    11292,
    64.38735,
    12.36569,
    123.8,
    23.45
  ],
  [
    11294,
    64.3877,
    12.36512,
    123.1,
    23.5
  ],
  [
    11296,
    64.38802,
    12.36448,
    122.6,
    23.65
  ],
  [
    11298,
    64.38832,
    12.36378,
    122,
    23.8
  ],
  [
    11300,
    64.38861,
    12.36306,
    121.4,
    23.97
  ],
  [
    11302,
    64.3889,
    12.3623,
    120.9,
    23.89
  ],
  [
    11304,
    64.38916,
    12.36154,
    120.4,
    23.45
  ],
  [
    11306,
    64.38942,
    12.36078,
    119.9,
    23.06
  ],
  [
    11308,
    64.38965,
    12.36,
    119.1,
    22.64
  ],
  [
    11310,
    64.38986,
    12.3592,
    118.7,
    22.29
  ],
  [
    11312,
    64.39002,
    12.35837,
    118.3,
    22.19
  ],
  [
    11314,
    64.39015,
    12.35749,
    118.2,
    22.2
  ],
  [
    11316,
    64.39025,
    12.3566,
    118,
    22.31
  ],
  [
    11318,
    64.39034,
    12.35569,
    117.9,
    22.38
  ],
  [
    11320,
    64.39044,
    12.35479,
    117.9,
    22.44
  ],
  [
    11322,
    64.39057,
    12.35391,
    118.1,
    22.43
  ],
  [
    11324,
    64.39073,
    12.35306,
    118.4,
    22.42
  ],
  [
    11326,
    64.39094,
    12.35226,
    118.6,
    22.42
  ],
  [
    11328,
    64.39116,
    12.35149,
    118.9,
    22.41
  ],
  [
    11330,
    64.3914,
    12.35075,
    118.5,
    22.34
  ],
  [
    11332,
    64.39164,
    12.35002,
    118,
    22.4
  ],
  [
    11334,
    64.39191,
    12.34932,
    117.9,
    22.47
  ],
  [
    11336,
    64.3922,
    12.34868,
    117.4,
    22.44
  ],
  [
    11338,
    64.39253,
    12.34813,
    116.9,
    22.44
  ],
  [
    11340,
    64.39288,
    12.34767,
    116.4,
    22.45
  ],
  [
    11342,
    64.39325,
    12.34731,
    116,
    22.52
  ],
  [
    11344,
    64.39364,
    12.34707,
    115.6,
    22.6
  ],
  [
    11346,
    64.39405,
    12.34694,
    115,
    22.63
  ],
  [
    11348,
    64.39446,
    12.34693,
    114.5,
    22.68
  ],
  [
    11350,
    64.39487,
    12.34705,
    114,
    22.7
  ],
  [
    11352,
    64.39527,
    12.34724,
    113.5,
    22.75
  ],
  [
    11354,
    64.39567,
    12.3474,
    113.2,
    22.82
  ],
  [
    11356,
    64.39608,
    12.34747,
    113.1,
    22.8
  ],
  [
    11358,
    64.39649,
    12.3474,
    113.2,
    22.78
  ],
  [
    11360,
    64.39689,
    12.34718,
    113.1,
    22.78
  ],
  [
    11362,
    64.39727,
    12.34682,
    113.3,
    22.73
  ],
  [
    11364,
    64.39762,
    12.34635,
    113.6,
    22.75
  ],
  [
    11366,
    64.39797,
    12.34584,
    114.1,
    22.77
  ],
  [
    11368,
    64.39831,
    12.34533,
    114.5,
    22.81
  ],
  [
    11370,
    64.39865,
    12.34481,
    114.8,
    22.85
  ],
  [
    11372,
    64.39899,
    12.34429,
    115,
    22.88
  ],
  [
    11374,
    64.39934,
    12.34377,
    115.3,
    22.9
  ],
  [
    11376,
    64.39968,
    12.34324,
    115.5,
    22.93
  ],
  [
    11378,
    64.40003,
    12.34272,
    115.7,
    22.97
  ],
  [
    11380,
    64.40037,
    12.3422,
    115.8,
    23.02
  ],
  [
    11382,
    64.40072,
    12.34167,
    115.9,
    23.1
  ],
  [
    11384,
    64.40106,
    12.34114,
    116.1,
    23.18
  ],
  [
    11386,
    64.40141,
    12.34061,
    116.2,
    23.28
  ],
  [
    11388,
    64.40176,
    12.34009,
    116.3,
    23.37
  ],
  [
    11390,
    64.40213,
    12.33959,
    116.3,
    23.46
  ],
  [
    11392,
    64.40251,
    12.3392,
    116.3,
    23.57
  ],
  [
    11394,
    64.40292,
    12.33894,
    115.7,
    23.62
  ],
  [
    11396,
    64.40334,
    12.33883,
    115,
    23.68
  ],
  [
    11398,
    64.40377,
    12.33888,
    114.6,
    23.79
  ],
  [
    11400,
    64.40419,
    12.33905,
    114.2,
    23.9
  ],
  [
    11402,
    64.40461,
    12.33926,
    114,
    24.06
  ],
  [
    11404,
    64.40504,
    12.33948,
    113.9,
    24.18
  ],
  [
    11406,
    64.40547,
    12.33967,
    113.6,
    24.31
  ],
  [
    11408,
    64.4059,
    12.33985,
    113.3,
    24.43
  ],
  [
    11410,
    64.40634,
    12.34002,
    113.2,
    24.63
  ],
  [
    11412,
    64.40678,
    12.34019,
    112.9,
    24.89
  ],
  [
    11414,
    64.40722,
    12.34042,
    112.5,
    25.08
  ],
  [
    11416,
    64.40765,
    12.34072,
    112.1,
    25.31
  ],
  [
    11418,
    64.40808,
    12.3411,
    111.7,
    25.59
  ],
  [
    11420,
    64.4085,
    12.34155,
    111.3,
    25.86
  ],
  [
    11422,
    64.40892,
    12.34208,
    111,
    26.25
  ],
  [
    11424,
    64.40933,
    12.34264,
    110.5,
    26.6
  ],
  [
    11426,
    64.40975,
    12.34318,
    110.1,
    26.97
  ],
  [
    11428,
    64.4102,
    12.34365,
    109.6,
    27.37
  ],
  [
    11430,
    64.41067,
    12.34402,
    109.1,
    27.78
  ],
  [
    11432,
    64.41116,
    12.34429,
    108.6,
    28.24
  ],
  [
    11434,
    64.41166,
    12.34445,
    108,
    28.56
  ],
  [
    11436,
    64.41218,
    12.3445,
    107.5,
    29.07
  ],
  [
    11438,
    64.41271,
    12.34442,
    106.6,
    29.46
  ],
  [
    11440,
    64.41324,
    12.34423,
    105.7,
    29.82
  ],
  [
    11442,
    64.41376,
    12.34391,
    104.6,
    29.89
  ],
  [
    11444,
    64.41426,
    12.34347,
    103.6,
    29.96
  ],
  [
    11446,
    64.41474,
    12.34291,
    102.6,
    30.06
  ],
  [
    11448,
    64.4152,
    12.34224,
    101.5,
    30.18
  ],
  [
    11450,
    64.41563,
    12.34147,
    101,
    30.24
  ],
  [
    11452,
    64.41604,
    12.34061,
    100.8,
    30.26
  ],
  [
    11454,
    64.41643,
    12.33974,
    100.7,
    30.19
  ],
  [
    11456,
    64.41684,
    12.33892,
    100.6,
    30.11
  ],
  [
    11458,
    64.41729,
    12.33822,
    100.5,
    29.96
  ],
  [
    11460,
    64.41777,
    12.33765,
    100.3,
    29.86
  ],
  [
    11462,
    64.41827,
    12.33723,
    100.2,
    29.74
  ],
  [
    11464,
    64.41879,
    12.33695,
    100.2,
    29.67
  ],
  [
    11466,
    64.41932,
    12.33682,
    99.5,
    29.64
  ],
  [
    11468,
    64.41985,
    12.33684,
    98.5,
    29.66
  ],
  [
    11470,
    64.42038,
    12.33701,
    98.3,
    29.68
  ],
  [
    11472,
    64.4209,
    12.3373,
    98.3,
    29.74
  ],
  [
    11474,
    64.42141,
    12.33765,
    98.3,
    29.77
  ],
  [
    11476,
    64.42193,
    12.33796,
    98.3,
    29.82
  ],
  [
    11478,
    64.42246,
    12.33818,
    98.3,
    29.89
  ],
  [
    11480,
    64.423,
    12.33829,
    98.3,
    30
  ],
  [
    11482,
    64.42354,
    12.33831,
    98.3,
    30.15
  ],
  [
    11484,
    64.42408,
    12.33828,
    98.3,
    30.09
  ],
  [
    11486,
    64.42462,
    12.33829,
    98.3,
    30.34
  ],
  [
    11488,
    64.42517,
    12.33838,
    98.3,
    30.35
  ],
  [
    11490,
    64.42571,
    12.33854,
    98.3,
    30.39
  ],
  [
    11492,
    64.42625,
    12.33878,
    93.6,
    30.36
  ],
  [
    11494,
    64.42678,
    12.33909,
    93.4,
    30.35
  ],
  [
    11496,
    64.4273,
    12.33944,
    93.2,
    30.35
  ],
  [
    11498,
    64.42783,
    12.33979,
    92.9,
    30.38
  ],
  [
    11500,
    64.42836,
    12.34008,
    92.4,
    30.42
  ],
  [
    11502,
    64.4289,
    12.3403,
    91.9,
    30.52
  ],
  [
    11504,
    64.42944,
    12.34044,
    91.2,
    30.59
  ],
  [
    11506,
    64.42999,
    12.34049,
    90.7,
    30.67
  ],
  [
    11508,
    64.43054,
    12.34047,
    90.1,
    30.76
  ],
  [
    11510,
    64.4311,
    12.34037,
    89.3,
    30.75
  ],
  [
    11512,
    64.43164,
    12.3402,
    88.8,
    30.27
  ],
  [
    11514,
    64.43217,
    12.33995,
    88.4,
    29.9
  ],
  [
    11516,
    64.43268,
    12.33963,
    87.8,
    29.5
  ],
  [
    11519,
    64.43343,
    12.33907,
    86.6,
    29.09
  ],
  [
    11520,
    64.43367,
    12.33888,
    86.3,
    28.7
  ],
  [
    11522,
    64.43416,
    12.33851,
    85.6,
    28.43
  ],
  [
    11525,
    64.4349,
    12.33814,
    84.9,
    28.18
  ],
  [
    11527,
    64.4354,
    12.33806,
    84.3,
    28.07
  ],
  [
    11529,
    64.4359,
    12.33811,
    83.7,
    27.91
  ],
  [
    11531,
    64.4364,
    12.33822,
    83.2,
    27.83
  ],
  [
    11533,
    64.4369,
    12.33835,
    82.7,
    27.82
  ],
  [
    11535,
    64.4374,
    12.33847,
    82.2,
    27.8
  ],
  [
    11537,
    64.43789,
    12.3386,
    81.4,
    27.78
  ],
  [
    11539,
    64.43839,
    12.33872,
    80.6,
    27.78
  ],
  [
    11541,
    64.43888,
    12.33884,
    80,
    27.69
  ],
  [
    11543,
    64.43937,
    12.33896,
    79.4,
    27.52
  ],
  [
    11545,
    64.43986,
    12.33908,
    78.7,
    27.29
  ],
  [
    11547,
    64.44033,
    12.3392,
    78.1,
    26.51
  ],
  [
    11549,
    64.44079,
    12.33926,
    77.6,
    26.14
  ],
  [
    11551,
    64.44124,
    12.33923,
    77,
    26.14
  ],
  [
    11553,
    64.44167,
    12.3391,
    76.4,
    24.14
  ],
  [
    11555,
    64.44208,
    12.33888,
    76,
    23.3
  ],
  [
    11557,
    64.44246,
    12.33858,
    75.4,
    22.87
  ],
  [
    11559,
    64.44284,
    12.33821,
    74.9,
    22.65
  ],
  [
    11561,
    64.4432,
    12.33781,
    74.5,
    22.52
  ],
  [
    11563,
    64.44357,
    12.33742,
    73.9,
    22.48
  ],
  [
    11565,
    64.44394,
    12.33709,
    73.3,
    22.46
  ],
  [
    11567,
    64.44433,
    12.33684,
    72.8,
    22.42
  ],
  [
    11569,
    64.44473,
    12.33669,
    72.3,
    22.37
  ],
  [
    11571,
    64.44513,
    12.33662,
    71.7,
    22.31
  ],
  [
    11573,
    64.44553,
    12.33666,
    71.2,
    22.27
  ],
  [
    11575,
    64.44592,
    12.33678,
    70.7,
    22.37
  ],
  [
    11577,
    64.44631,
    12.337,
    70,
    22.46
  ],
  [
    11579,
    64.4467,
    12.33729,
    69.5,
    22.57
  ],
  [
    11581,
    64.44709,
    12.33759,
    68.9,
    22.68
  ],
  [
    11583,
    64.44749,
    12.3378,
    68.3,
    22.78
  ],
  [
    11585,
    64.44789,
    12.33787,
    67.4,
    22.76
  ],
  [
    11587,
    64.4483,
    12.3378,
    66.9,
    22.99
  ],
  [
    11589,
    64.4487,
    12.33757,
    66.4,
    23.03
  ],
  [
    11591,
    64.44908,
    12.33719,
    66.2,
    22.99
  ],
  [
    11593,
    64.44944,
    12.33671,
    65.9,
    22.96
  ],
  [
    11595,
    64.44978,
    12.33617,
    65.4,
    22.99
  ],
  [
    11597,
    64.45012,
    12.33562,
    64.6,
    22.99
  ],
  [
    11599,
    64.45047,
    12.3351,
    63.8,
    23.29
  ],
  [
    11601,
    64.45084,
    12.33464,
    62.9,
    23.41
  ],
  [
    11603,
    64.45122,
    12.33426,
    62.2,
    23.5
  ],
  [
    11605,
    64.45162,
    12.33397,
    61.6,
    23.02
  ],
  [
    11607,
    64.45202,
    12.33378,
    61.1,
    22.55
  ],
  [
    11609,
    64.4524,
    12.33368,
    60.6,
    21.07
  ],
  [
    11611,
    64.45277,
    12.33365,
    60,
    20.07
  ],
  [
    11613,
    64.45312,
    12.33369,
    59.7,
    19.51
  ],
  [
    11615,
    64.45347,
    12.3338,
    59.4,
    19.27
  ],
  [
    11617,
    64.4538,
    12.33397,
    59.1,
    19.21
  ],
  [
    11619,
    64.45414,
    12.33417,
    58.8,
    19.21
  ],
  [
    11621,
    64.45447,
    12.33439,
    58.5,
    19.31
  ],
  [
    11623,
    64.4548,
    12.33462,
    58.3,
    19.45
  ],
  [
    11625,
    64.45514,
    12.33484,
    58,
    19.23
  ],
  [
    11627,
    64.45546,
    12.33506,
    57.7,
    19.1
  ],
  [
    11629,
    64.45579,
    12.33528,
    57.5,
    18.62
  ],
  [
    11631,
    64.4561,
    12.33549,
    57.2,
    18.28
  ],
  [
    11633,
    64.45641,
    12.3357,
    57,
    17.9
  ],
  [
    11635,
    64.45672,
    12.3359,
    56.8,
    17.4
  ],
  [
    11637,
    64.45701,
    12.3361,
    56.6,
    17.02
  ],
  [
    11639,
    64.45728,
    12.33628,
    56.5,
    15.43
  ],
  [
    11641,
    64.45754,
    12.33646,
    56.5,
    14.33
  ],
  [
    11643,
    64.45778,
    12.33662,
    56.5,
    13.42
  ],
  [
    11645,
    64.458,
    12.33677,
    56.5,
    12.72
  ],
  [
    11647,
    64.45821,
    12.33691,
    56.5,
    12.39
  ],
  [
    11649,
    64.45841,
    12.33704,
    56.5,
    11.31
  ],
  [
    11651,
    64.4586,
    12.33717,
    56.5,
    10.54
  ],
  [
    11653,
    64.45877,
    12.33728,
    56.6,
    9.59
  ],
  [
    11655,
    64.45892,
    12.33739,
    56.6,
    8.51
  ],
  [
    11657,
    64.45906,
    12.33748,
    56.7,
    7.96
  ],
  [
    11659,
    64.45918,
    12.33756,
    56.7,
    6.8
  ],
  [
    11661,
    64.45929,
    12.33764,
    56.8,
    6.17
  ],
  [
    11663,
    64.45939,
    12.3377,
    56.8,
    5.39
  ],
  [
    11665,
    64.45947,
    12.33776,
    56.8,
    4.32
  ],
  [
    11667,
    64.45953,
    12.3378,
    56.8,
    3.64
  ],
  [
    11669,
    64.45957,
    12.33783,
    56.8,
    1.72
  ],
  [
    11671,
    64.45959,
    12.33784,
    56.7,
    1.03
  ],
  [
    11673,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11675,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11677,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11679,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11681,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11683,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11685,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11687,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11689,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11691,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11693,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11695,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11697,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11699,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11701,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11703,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11705,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11707,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11709,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11711,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11713,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11715,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11717,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11719,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11721,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11723,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11725,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11727,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11729,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11731,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11733,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11735,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11737,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11739,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11741,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11743,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11745,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11747,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11749,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11751,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11753,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11755,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11757,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11759,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11761,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11763,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11765,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11767,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11769,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11771,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11773,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11775,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11777,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11779,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11781,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11783,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11785,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11787,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11789,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11791,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11793,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11795,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11797,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11799,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11801,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11803,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11805,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11807,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11809,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11811,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11813,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11815,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11817,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11819,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11821,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11823,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11825,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11827,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11829,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11831,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11833,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11835,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11837,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11839,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11841,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11843,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11845,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11847,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11849,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11851,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11853,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11855,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11857,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11859,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11861,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11863,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11865,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11867,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11869,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11871,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11873,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11875,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11877,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11879,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11881,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11883,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11885,
    64.4596,
    12.33785,
    56.7,
    0
  ],
  [
    11887,
    64.45959,
    12.33789,
    55.4,
    1.83
  ],
  [
    11889,
    64.45963,
    12.33792,
    55.4,
    2.89
  ],
  [
    11891,
    64.45969,
    12.33796,
    55.4,
    3.95
  ],
  [
    11893,
    64.45976,
    12.33801,
    55.4,
    4.75
  ],
  [
    11895,
    64.45985,
    12.33807,
    55.4,
    5.39
  ],
  [
    11897,
    64.45995,
    12.33813,
    55.3,
    6
  ],
  [
    11899,
    64.46006,
    12.3382,
    55.3,
    6.61
  ],
  [
    11901,
    64.46018,
    12.33828,
    55.3,
    7.21
  ],
  [
    11903,
    64.46031,
    12.33837,
    55.3,
    7.79
  ],
  [
    11905,
    64.46045,
    12.33846,
    55.2,
    8.37
  ],
  [
    11907,
    64.4606,
    12.33856,
    55.2,
    8.93
  ],
  [
    11909,
    64.46076,
    12.33867,
    55.1,
    9.49
  ],
  [
    11911,
    64.46093,
    12.33878,
    55.1,
    10.12
  ],
  [
    11913,
    64.46111,
    12.3389,
    55.1,
    10.88
  ],
  [
    11915,
    64.4613,
    12.33903,
    55.2,
    11.57
  ],
  [
    11917,
    64.4615,
    12.33916,
    55.2,
    11.63
  ],
  [
    11919,
    64.46171,
    12.33929,
    55.1,
    11.63
  ],
  [
    11921,
    64.46191,
    12.33941,
    55.1,
    11.63
  ],
  [
    12038,
    64.48466,
    12.34604,
    51.7,
    22.4
  ],
  [
    12040,
    64.48503,
    12.34604,
    50.6,
    22.3
  ],
  [
    12042,
    64.48543,
    12.34605,
    51.4,
    22.36
  ],
  [
    12044,
    64.48582,
    12.34607,
    51,
    22.35
  ],
  [
    12046,
    64.48622,
    12.34607,
    49.6,
    22.37
  ],
  [
    12048,
    64.48663,
    12.34607,
    48.8,
    22.33
  ],
  [
    12050,
    64.48703,
    12.34607,
    48.1,
    22.25
  ],
  [
    12052,
    64.48743,
    12.34608,
    47.7,
    22.19
  ],
  [
    12054,
    64.48782,
    12.34616,
    47.7,
    22.13
  ],
  [
    12056,
    64.48821,
    12.34631,
    47.7,
    21.95
  ],
  [
    12058,
    64.48859,
    12.34652,
    48.1,
    21.78
  ],
  [
    12060,
    64.48899,
    12.34687,
    48,
    23.58
  ],
  [
    12062,
    64.48935,
    12.34716,
    47.8,
    21.45
  ],
  [
    12064,
    64.48972,
    12.34743,
    48.7,
    21.37
  ],
  [
    12066,
    64.49008,
    12.34771,
    49.8,
    21.25
  ],
  [
    12068,
    64.49044,
    12.34798,
    50.6,
    21.14
  ],
  [
    12070,
    64.4908,
    12.34824,
    51.5,
    20.98
  ],
  [
    12073,
    64.49134,
    12.34864,
    52.6,
    20.93
  ],
  [
    12075,
    64.4917,
    12.3489,
    53.3,
    21.06
  ],
  [
    12077,
    64.49206,
    12.34916,
    53.8,
    21.06
  ],
  [
    12081,
    64.49278,
    12.34964,
    55,
    21.21
  ],
  [
    12083,
    64.49313,
    12.34988,
    56.5,
    21.16
  ],
  [
    12085,
    64.49349,
    12.35006,
    57,
    21.23
  ],
  [
    12087,
    64.49387,
    12.35018,
    57.6,
    21.34
  ],
  [
    12089,
    64.49425,
    12.35026,
    58,
    21.53
  ],
  [
    12091,
    64.49464,
    12.35033,
    58.3,
    21.74
  ],
  [
    12093,
    64.49503,
    12.35042,
    58.6,
    21.96
  ],
  [
    12095,
    64.49542,
    12.35058,
    58.9,
    22.15
  ],
  [
    12097,
    64.4958,
    12.35083,
    59.3,
    22.32
  ],
  [
    12099,
    64.49618,
    12.35115,
    59.6,
    22.48
  ],
  [
    12101,
    64.49656,
    12.35149,
    60,
    22.65
  ],
  [
    12103,
    64.49694,
    12.35184,
    60.3,
    22.85
  ],
  [
    12105,
    64.49732,
    12.35221,
    60.7,
    23.04
  ],
  [
    12107,
    64.4977,
    12.35258,
    61,
    23.25
  ],
  [
    12109,
    64.49809,
    12.35297,
    61.4,
    23.41
  ],
  [
    12111,
    64.49847,
    12.35338,
    61.4,
    23.56
  ],
  [
    12113,
    64.49885,
    12.35379,
    61.4,
    23.58
  ],
  [
    12115,
    64.49924,
    12.35422,
    61.4,
    23.52
  ],
  [
    12117,
    64.49961,
    12.35464,
    61.4,
    23.37
  ],
  [
    12119,
    64.49999,
    12.35507,
    64,
    23.17
  ],
  [
    12121,
    64.50036,
    12.35549,
    64,
    23.04
  ],
  [
    12123,
    64.50074,
    12.35587,
    64,
    22.88
  ],
  [
    12125,
    64.50112,
    12.3562,
    65.4,
    22.79
  ],
  [
    12127,
    64.50151,
    12.35647,
    65.8,
    22.68
  ],
  [
    12129,
    64.50191,
    12.35669,
    66.2,
    22.61
  ],
  [
    12131,
    64.50231,
    12.35687,
    66.6,
    22.59
  ],
  [
    12133,
    64.50271,
    12.35703,
    66.9,
    22.53
  ],
  [
    12135,
    64.50311,
    12.35718,
    67.3,
    22.55
  ],
  [
    12137,
    64.5035,
    12.35734,
    66.4,
    22
  ],
  [
    12139,
    64.5039,
    12.35749,
    66.8,
    22.6
  ],
  [
    12141,
    64.5043,
    12.35765,
    67,
    22.67
  ],
  [
    12143,
    64.5047,
    12.3578,
    67.4,
    22.72
  ],
  [
    12145,
    64.50511,
    12.35795,
    67.8,
    22.76
  ],
  [
    12147,
    64.50551,
    12.3581,
    68,
    22.81
  ],
  [
    12149,
    64.50592,
    12.35826,
    68.3,
    22.87
  ],
  [
    12151,
    64.50632,
    12.35841,
    68.7,
    22.94
  ],
  [
    12153,
    64.50673,
    12.35858,
    69.3,
    22.9
  ],
  [
    12155,
    64.50713,
    12.35881,
    69.8,
    22.9
  ],
  [
    12157,
    64.50751,
    12.35916,
    70.4,
    22.78
  ],
  [
    12159,
    64.50786,
    12.35963,
    71,
    22.64
  ],
  [
    12161,
    64.50818,
    12.36022,
    71.6,
    22.57
  ],
  [
    12163,
    64.50846,
    12.36089,
    72.3,
    22.43
  ],
  [
    12165,
    64.50873,
    12.3616,
    73,
    22.57
  ],
  [
    12167,
    64.50901,
    12.36228,
    73.3,
    22.32
  ],
  [
    12170,
    64.50947,
    12.36318,
    74.3,
    22.28
  ],
  [
    12172,
    64.50982,
    12.36362,
    75,
    22.26
  ],
  [
    12174,
    64.51019,
    12.36392,
    75.7,
    22.25
  ],
  [
    12176,
    64.51058,
    12.36412,
    76.4,
    22.26
  ],
  [
    12178,
    64.51098,
    12.36427,
    76.8,
    22.3
  ],
  [
    12180,
    64.51137,
    12.36444,
    77.4,
    22.37
  ],
  [
    12182,
    64.51176,
    12.36468,
    78.1,
    22.46
  ],
  [
    12184,
    64.51214,
    12.36501,
    78.8,
    22.55
  ],
  [
    12186,
    64.51251,
    12.36541,
    79.4,
    22.5
  ],
  [
    12188,
    64.51285,
    12.36588,
    80,
    22.51
  ],
  [
    12190,
    64.51319,
    12.36641,
    80.4,
    22.51
  ],
  [
    12192,
    64.51352,
    12.36698,
    80.9,
    22.51
  ],
  [
    12194,
    64.51383,
    12.36759,
    81.5,
    22.77
  ],
  [
    12196,
    64.51413,
    12.36825,
    82.3,
    22.77
  ],
  [
    12198,
    64.51442,
    12.36895,
    82.9,
    23.13
  ],
  [
    12200,
    64.5147,
    12.36967,
    83.5,
    23.13
  ],
  [
    12202,
    64.51498,
    12.37041,
    84.1,
    23.13
  ],
  [
    12204,
    64.51526,
    12.37114,
    84.9,
    24.54
  ],
  [
    12206,
    64.51557,
    12.37187,
    85.8,
    24.54
  ],
  [
    12208,
    64.5159,
    12.37251,
    86.2,
    24.04
  ],
  [
    12210,
    64.51625,
    12.37308,
    86.7,
    24.04
  ],
  [
    12212,
    64.51662,
    12.3736,
    87.4,
    24.04
  ],
  [
    12214,
    64.51701,
    12.37405,
    87.8,
    24.12
  ],
  [
    12216,
    64.5174,
    12.37443,
    88.3,
    24.12
  ],
  [
    12218,
    64.51781,
    12.37478,
    88.7,
    24.26
  ],
  [
    12220,
    64.51822,
    12.37514,
    89.1,
    24.26
  ],
  [
    12222,
    64.51862,
    12.37555,
    89.6,
    24.26
  ],
  [
    12224,
    64.519,
    12.37601,
    89.8,
    24.28
  ],
  [
    12226,
    64.51937,
    12.37654,
    90.1,
    24.28
  ],
  [
    12228,
    64.51972,
    12.37711,
    90.4,
    23.98
  ],
  [
    12230,
    64.52006,
    12.37769,
    90.8,
    23.75
  ],
  [
    12232,
    64.5204,
    12.37827,
    91.1,
    23.56
  ],
  [
    12234,
    64.52074,
    12.37884,
    91.5,
    23.34
  ],
  [
    12236,
    64.52108,
    12.37939,
    92,
    23.15
  ],
  [
    12238,
    64.52142,
    12.37993,
    92.4,
    22.96
  ],
  [
    12240,
    64.52176,
    12.38045,
    92.8,
    22.72
  ],
  [
    12242,
    64.52211,
    12.38093,
    93.3,
    22.53
  ],
  [
    12244,
    64.52246,
    12.38137,
    93.7,
    22.34
  ],
  [
    12246,
    64.52283,
    12.38174,
    94.2,
    22.25
  ],
  [
    12248,
    64.52321,
    12.38204,
    94.6,
    22.19
  ],
  [
    12250,
    64.52359,
    12.38228,
    95.1,
    22.12
  ],
  [
    12252,
    64.52398,
    12.38245,
    95.6,
    22.08
  ],
  [
    12254,
    64.52437,
    12.38254,
    96.1,
    22.09
  ],
  [
    12256,
    64.52477,
    12.38251,
    96.7,
    22.1
  ],
  [
    12258,
    64.52516,
    12.38233,
    97.1,
    22.2
  ],
  [
    12260,
    64.52554,
    12.38204,
    97.5,
    22.26
  ],
  [
    12262,
    64.52592,
    12.3817,
    97.6,
    22.37
  ],
  [
    12264,
    64.52631,
    12.3814,
    97.9,
    22.61
  ],
  [
    12266,
    64.52671,
    12.38121,
    98,
    22.86
  ],
  [
    12268,
    64.52713,
    12.38119,
    98.3,
    22.96
  ],
  [
    12270,
    64.52754,
    12.3813,
    98.2,
    23.1
  ],
  [
    12272,
    64.52794,
    12.38154,
    98.3,
    23.19
  ],
  [
    12274,
    64.52834,
    12.38184,
    98.6,
    23.33
  ],
  [
    12276,
    64.52873,
    12.38216,
    99,
    23.46
  ],
  [
    12278,
    64.52913,
    12.3825,
    99.6,
    23.55
  ],
  [
    12280,
    64.52952,
    12.38286,
    100.1,
    23.48
  ],
  [
    12282,
    64.52992,
    12.38321,
    100.3,
    23.39
  ],
  [
    12284,
    64.5303,
    12.38359,
    100.7,
    23.45
  ],
  [
    12286,
    64.53069,
    12.38399,
    100.9,
    23.53
  ],
  [
    12288,
    64.53106,
    12.38447,
    101.4,
    23.63
  ],
  [
    12290,
    64.53141,
    12.38503,
    101.9,
    23.74
  ],
  [
    12292,
    64.53172,
    12.3857,
    102.2,
    23.79
  ],
  [
    12294,
    64.53202,
    12.38643,
    102.6,
    23.96
  ],
  [
    12296,
    64.5323,
    12.38719,
    103,
    24.19
  ],
  [
    12298,
    64.53259,
    12.38795,
    103.2,
    24.42
  ],
  [
    12300,
    64.53288,
    12.38872,
    103.4,
    24.69
  ],
  [
    12302,
    64.53318,
    12.3895,
    103.6,
    24.83
  ],
  [
    12304,
    64.53348,
    12.39029,
    103.8,
    25.25
  ],
  [
    12306,
    64.53378,
    12.39108,
    104,
    25.54
  ],
  [
    12308,
    64.53408,
    12.39189,
    104.1,
    25.84
  ],
  [
    12310,
    64.53439,
    12.39272,
    104.3,
    26.12
  ],
  [
    12312,
    64.53466,
    12.39362,
    104.3,
    26.94
  ],
  [
    12314,
    64.53491,
    12.39457,
    104.5,
    26.59
  ],
  [
    12316,
    64.53514,
    12.39555,
    104.9,
    26.69
  ],
  [
    12318,
    64.53532,
    12.39659,
    105.4,
    26.58
  ],
  [
    12320,
    64.53546,
    12.39764,
    106.1,
    26.31
  ],
  [
    12322,
    64.53559,
    12.39869,
    106.7,
    26.31
  ],
  [
    12364,
    64.53672,
    12.41992,
    117.2,
    23.94
  ],
  [
    12366,
    64.53676,
    12.42112,
    123.5,
    23.83
  ],
  [
    12368,
    64.53684,
    12.42213,
    123.6,
    23.61
  ],
  [
    12370,
    64.5369,
    12.4231,
    120.9,
    23.49
  ],
  [
    12372,
    64.53695,
    12.42407,
    120.2,
    23.46
  ],
  [
    12374,
    64.53698,
    12.42503,
    120.5,
    23.36
  ],
  [
    12376,
    64.53699,
    12.42599,
    119.8,
    23.27
  ],
  [
    12378,
    64.53695,
    12.42695,
    119.9,
    23.19
  ],
  [
    12380,
    64.53685,
    12.42789,
    119.8,
    23.27
  ],
  [
    12382,
    64.5367,
    12.42878,
    119.6,
    23.11
  ],
  [
    12384,
    64.53651,
    12.42964,
    119.6,
    23.04
  ],
  [
    12386,
    64.53631,
    12.43048,
    119.7,
    23.02
  ],
  [
    12388,
    64.53611,
    12.43133,
    119.7,
    23.02
  ],
  [
    12390,
    64.53592,
    12.43219,
    119.8,
    23.04
  ],
  [
    12392,
    64.53576,
    12.43307,
    119.8,
    23.06
  ],
  [
    12394,
    64.53562,
    12.43398,
    119.9,
    23.09
  ],
  [
    12396,
    64.53551,
    12.43491,
    119.9,
    23.11
  ],
  [
    12398,
    64.53543,
    12.43585,
    119.8,
    23.12
  ],
  [
    12400,
    64.53537,
    12.4368,
    119.7,
    23.12
  ],
  [
    12402,
    64.53535,
    12.43776,
    119.5,
    23.11
  ],
  [
    12404,
    64.53535,
    12.43873,
    119.3,
    23.1
  ],
  [
    12406,
    64.53541,
    12.43968,
    119.3,
    23.09
  ],
  [
    12408,
    64.53553,
    12.4406,
    119.3,
    23.04
  ],
  [
    12410,
    64.53568,
    12.44148,
    119.2,
    22.54
  ],
  [
    12412,
    64.53583,
    12.44235,
    119.4,
    22.54
  ],
  [
    12416,
    64.53645,
    12.44354,
    119.4,
    22.95
  ],
  [
    12418,
    64.53695,
    12.44404,
    119.9,
    22.92
  ],
  [
    12420,
    64.53738,
    12.44434,
    119.3,
    22.93
  ],
  [
    12422,
    64.53778,
    12.44465,
    119.1,
    22.93
  ],
  [
    12424,
    64.53819,
    12.44484,
    119.6,
    22.96
  ],
  [
    12426,
    64.5386,
    12.44495,
    119.9,
    22.95
  ],
  [
    12428,
    64.53902,
    12.44502,
    120.1,
    23.03
  ],
  [
    12430,
    64.53944,
    12.44512,
    120.4,
    23.1
  ],
  [
    12432,
    64.53984,
    12.44534,
    120.2,
    23.11
  ],
  [
    12434,
    64.54022,
    12.4457,
    120,
    23.1
  ],
  [
    12436,
    64.54058,
    12.44621,
    120.3,
    23.13
  ],
  [
    12438,
    64.54089,
    12.44683,
    120.3,
    23.13
  ],
  [
    12440,
    64.54117,
    12.44755,
    120.6,
    23.11
  ],
  [
    12442,
    64.54143,
    12.44829,
    120.7,
    23.08
  ],
  [
    12444,
    64.54171,
    12.44901,
    120.8,
    23.11
  ],
  [
    12446,
    64.54202,
    12.44966,
    121,
    23.14
  ],
  [
    12448,
    64.54235,
    12.45022,
    121.2,
    23.23
  ],
  [
    12450,
    64.54272,
    12.45069,
    121.3,
    23.3
  ],
  [
    12452,
    64.5431,
    12.45109,
    121.5,
    23.38
  ],
  [
    12454,
    64.54349,
    12.45146,
    121.7,
    23.52
  ],
  [
    12456,
    64.54389,
    12.45178,
    121.6,
    23.63
  ],
  [
    12458,
    64.5443,
    12.45208,
    121.6,
    23.74
  ],
  [
    12460,
    64.54471,
    12.45235,
    121.7,
    23.81
  ],
  [
    12462,
    64.54512,
    12.45258,
    121.8,
    23.76
  ],
  [
    12464,
    64.54554,
    12.45277,
    121.9,
    23.67
  ],
  [
    12466,
    64.54596,
    12.45293,
    121.8,
    23.61
  ],
  [
    12468,
    64.54638,
    12.45305,
    121.5,
    23.54
  ],
  [
    12470,
    64.5468,
    12.45313,
    121.2,
    23.55
  ],
  [
    12472,
    64.54722,
    12.45319,
    121.8,
    22.94
  ],
  [
    12474,
    64.54762,
    12.45326,
    122.2,
    22.49
  ],
  [
    12476,
    64.54802,
    12.45333,
    122.3,
    22.49
  ],
  [
    12480,
    64.54884,
    12.45314,
    122.7,
    23.05
  ],
  [
    12482,
    64.54931,
    12.45311,
    122.6,
    23.2
  ],
  [
    12484,
    64.54973,
    12.45327,
    121.9,
    23.04
  ],
  [
    12486,
    64.55012,
    12.4536,
    121.4,
    22.98
  ],
  [
    12488,
    64.55047,
    12.45407,
    121.3,
    22.92
  ],
  [
    12490,
    64.55079,
    12.45467,
    121.3,
    22.9
  ],
  [
    12492,
    64.55108,
    12.45537,
    121.4,
    22.91
  ],
  [
    12494,
    64.55135,
    12.45609,
    121.3,
    22.95
  ],
  [
    12496,
    64.55163,
    12.45681,
    121.2,
    23.04
  ],
  [
    12498,
    64.55191,
    12.45751,
    121.4,
    23.12
  ],
  [
    12500,
    64.5522,
    12.4582,
    121.6,
    23.27
  ],
  [
    12502,
    64.55251,
    12.45886,
    121.6,
    23.28
  ],
  [
    12504,
    64.55282,
    12.45951,
    121.5,
    23.47
  ],
  [
    12506,
    64.55314,
    12.46015,
    121.3,
    23.64
  ],
  [
    12508,
    64.55347,
    12.46079,
    121.3,
    23.81
  ],
  [
    12510,
    64.55379,
    12.46144,
    121.1,
    24.06
  ],
  [
    12512,
    64.55413,
    12.46209,
    120.8,
    24.19
  ],
  [
    12514,
    64.55446,
    12.46275,
    120.6,
    24.68
  ],
  [
    12516,
    64.55481,
    12.46341,
    120.4,
    25.03
  ],
  [
    12518,
    64.55516,
    12.46407,
    120.1,
    25.39
  ],
  [
    12520,
    64.55552,
    12.46475,
    119.9,
    25.78
  ],
  [
    12522,
    64.55588,
    12.46543,
    119.6,
    25.91
  ],
  [
    12524,
    64.55624,
    12.4661,
    119.4,
    25.92
  ],
  [
    12526,
    64.55661,
    12.46677,
    119,
    25.93
  ],
  [
    12528,
    64.55698,
    12.46743,
    118.7,
    25.93
  ],
  [
    12530,
    64.55734,
    12.4681,
    118.1,
    25.97
  ],
  [
    12532,
    64.55772,
    12.46876,
    117.5,
    26.04
  ],
  [
    12534,
    64.55809,
    12.46941,
    117,
    26.15
  ],
  [
    12536,
    64.55847,
    12.47007,
    116.3,
    26.27
  ],
  [
    12538,
    64.55885,
    12.47073,
    115.9,
    26.39
  ],
  [
    12540,
    64.55923,
    12.47138,
    115.4,
    26.54
  ],
  [
    12542,
    64.55961,
    12.47203,
    114.8,
    26.63
  ],
  [
    12544,
    64.56,
    12.47268,
    114.3,
    26.78
  ],
  [
    12546,
    64.5604,
    12.47331,
    113.6,
    26.87
  ],
  [
    12548,
    64.5608,
    12.47393,
    113,
    27
  ],
  [
    12550,
    64.56122,
    12.47452,
    112.4,
    27.13
  ],
  [
    12552,
    64.56165,
    12.47505,
    112,
    27.26
  ],
  [
    12554,
    64.56209,
    12.47554,
    111.5,
    27.35
  ],
  [
    12556,
    64.56255,
    12.47598,
    111,
    27.41
  ],
  [
    12558,
    64.56301,
    12.47639,
    110.7,
    27.56
  ],
  [
    12560,
    64.56347,
    12.47682,
    110.1,
    27.5
  ],
  [
    12562,
    64.5639,
    12.47735,
    109.6,
    27.03
  ],
  [
    12564,
    64.56429,
    12.47801,
    108.9,
    26.66
  ],
  [
    12566,
    64.56463,
    12.47877,
    108.3,
    26.47
  ],
  [
    12568,
    64.56492,
    12.47964,
    107.7,
    26.4
  ],
  [
    12570,
    64.56517,
    12.48058,
    107.1,
    26.43
  ],
  [
    12572,
    64.56538,
    12.48157,
    106.6,
    26.46
  ],
  [
    12574,
    64.56559,
    12.48256,
    106,
    26.55
  ],
  [
    12576,
    64.56581,
    12.48355,
    105.6,
    26.65
  ],
  [
    12578,
    64.56602,
    12.48455,
    105.1,
    26.5
  ],
  [
    12580,
    64.56623,
    12.48553,
    104.5,
    26
  ],
  [
    12582,
    64.56644,
    12.4865,
    104.3,
    25.6
  ],
  [
    12584,
    64.56664,
    12.48744,
    104.1,
    25.11
  ],
  [
    12586,
    64.56684,
    12.48837,
    104.1,
    24.57
  ],
  [
    12588,
    64.56704,
    12.48926,
    104.1,
    23.98
  ],
  [
    12590,
    64.56727,
    12.4901,
    104.1,
    23.37
  ],
  [
    12592,
    64.56752,
    12.49086,
    104,
    22.72
  ],
  [
    12594,
    64.56779,
    12.49156,
    103.8,
    22.25
  ],
  [
    12596,
    64.56806,
    12.49223,
    103.7,
    21.99
  ],
  [
    12598,
    64.56834,
    12.49288,
    103.7,
    21.85
  ],
  [
    12600,
    64.56861,
    12.49352,
    103.7,
    21.8
  ],
  [
    12602,
    64.56889,
    12.49417,
    103.7,
    21.8
  ],
  [
    12604,
    64.56916,
    12.49481,
    103.6,
    21.86
  ],
  [
    12606,
    64.56944,
    12.49546,
    103.6,
    21.98
  ],
  [
    12608,
    64.56972,
    12.49611,
    103.5,
    22.14
  ],
  [
    12610,
    64.57,
    12.49677,
    103.5,
    22.33
  ],
  [
    12612,
    64.57028,
    12.49743,
    103.6,
    22.56
  ],
  [
    12614,
    64.57057,
    12.49811,
    103.6,
    22.82
  ],
  [
    12616,
    64.57086,
    12.49879,
    104,
    23.05
  ],
  [
    12618,
    64.57115,
    12.49946,
    104.5,
    23.25
  ],
  [
    12620,
    64.57146,
    12.50013,
    105.2,
    23.41
  ],
  [
    12622,
    64.57177,
    12.50077,
    105.7,
    23.54
  ],
  [
    12624,
    64.5721,
    12.50138,
    106.3,
    23.67
  ],
  [
    12626,
    64.57245,
    12.50198,
    106.8,
    23.81
  ],
  [
    12628,
    64.5728,
    12.50254,
    107.4,
    23.93
  ],
  [
    12630,
    64.57316,
    12.50308,
    108,
    24.06
  ],
  [
    12632,
    64.57353,
    12.50361,
    108.7,
    24.17
  ],
  [
    12634,
    64.57389,
    12.50415,
    109.4,
    24.28
  ],
  [
    12636,
    64.57426,
    12.50472,
    110.1,
    24.38
  ],
  [
    12638,
    64.57461,
    12.50533,
    110.7,
    24.46
  ],
  [
    12640,
    64.57495,
    12.50597,
    111.3,
    24.55
  ],
  [
    12642,
    64.57528,
    12.50665,
    111.9,
    24.63
  ],
  [
    12644,
    64.5756,
    12.50737,
    112.5,
    24.71
  ],
  [
    12646,
    64.5759,
    12.50813,
    113.1,
    24.79
  ],
  [
    12648,
    64.57619,
    12.50891,
    113.6,
    24.88
  ],
  [
    12650,
    64.57648,
    12.50971,
    114.1,
    24.96
  ],
  [
    12652,
    64.57677,
    12.51049,
    114.6,
    25.08
  ],
  [
    12654,
    64.57708,
    12.51126,
    115.2,
    25.17
  ],
  [
    12656,
    64.57741,
    12.51199,
    115.9,
    25.27
  ],
  [
    12658,
    64.57775,
    12.51268,
    116.6,
    25.35
  ],
  [
    12660,
    64.57811,
    12.51334,
    117.3,
    25.43
  ],
  [
    12662,
    64.57848,
    12.51394,
    117.9,
    25.5
  ],
  [
    12664,
    64.57889,
    12.51445,
    118.2,
    25.58
  ],
  [
    12666,
    64.57931,
    12.51486,
    118.6,
    25.68
  ],
  [
    12668,
    64.57976,
    12.51516,
    118.9,
    25.77
  ],
  [
    12670,
    64.58021,
    12.51536,
    119.3,
    25.88
  ],
  [
    12672,
    64.58068,
    12.51543,
    119.3,
    25.94
  ],
  [
    12674,
    64.58115,
    12.51538,
    119.3,
    26.04
  ],
  [
    12676,
    64.58161,
    12.51522,
    119.3,
    26.14
  ],
  [
    12678,
    64.58207,
    12.51493,
    119.3,
    26.22
  ],
  [
    12680,
    64.58251,
    12.51453,
    122.1,
    26.3
  ],
  [
    12682,
    64.58293,
    12.51401,
    122.4,
    26.4
  ],
  [
    12684,
    64.58333,
    12.5134,
    122.5,
    26.51
  ],
  [
    12686,
    64.58373,
    12.51277,
    122.7,
    26.63
  ],
  [
    12688,
    64.58414,
    12.51219,
    122.9,
    26.74
  ],
  [
    12690,
    64.58458,
    12.51172,
    123.6,
    26.83
  ],
  [
    12692,
    64.58504,
    12.51136,
    124.1,
    26.89
  ],
  [
    12694,
    64.58551,
    12.51113,
    124.5,
    26.97
  ],
  [
    12697,
    64.58623,
    12.51097,
    125.1,
    27.04
  ],
  [
    12699,
    64.58672,
    12.51095,
    125.6,
    27.09
  ],
  [
    12701,
    64.58721,
    12.51097,
    126.1,
    27.14
  ],
  [
    12703,
    64.5877,
    12.51102,
    126.6,
    27.22
  ],
  [
    12705,
    64.58819,
    12.5111,
    127.1,
    27.26
  ],
  [
    12707,
    64.58867,
    12.51121,
    127.7,
    27.26
  ],
  [
    12709,
    64.58916,
    12.51135,
    128.2,
    27.35
  ],
  [
    12711,
    64.58965,
    12.51153,
    128.6,
    27.35
  ],
  [
    12713,
    64.59013,
    12.51174,
    128.9,
    27.51
  ],
  [
    12715,
    64.59062,
    12.51198,
    129.3,
    27.51
  ],
  [
    12717,
    64.5911,
    12.51227,
    129.5,
    27.51
  ],
  [
    12719,
    64.59158,
    12.5126,
    129.8,
    27.86
  ],
  [
    12721,
    64.59206,
    12.51297,
    130.1,
    27.86
  ],
  [
    12723,
    64.59254,
    12.51339,
    130.4,
    28.24
  ],
  [
    12725,
    64.59301,
    12.51385,
    130.7,
    28.24
  ],
  [
    12727,
    64.59347,
    12.51436,
    130.9,
    28.24
  ],
  [
    12729,
    64.59393,
    12.5149,
    131.2,
    28.63
  ],
  [
    12731,
    64.59438,
    12.5155,
    131.4,
    28.89
  ],
  [
    12733,
    64.59483,
    12.51613,
    131.5,
    29.09
  ],
  [
    12735,
    64.59527,
    12.51679,
    131.5,
    29.25
  ],
  [
    12737,
    64.59571,
    12.51746,
    131.5,
    29.45
  ],
  [
    12739,
    64.59616,
    12.51815,
    131.4,
    29.69
  ],
  [
    12741,
    64.5966,
    12.51886,
    131.4,
    29.83
  ],
  [
    12743,
    64.59704,
    12.51958,
    131.5,
    29.86
  ],
  [
    12745,
    64.59747,
    12.52031,
    131.9,
    29.86
  ],
  [
    12747,
    64.5979,
    12.52106,
    132.3,
    29.83
  ],
  [
    12749,
    64.59833,
    12.5218,
    132.8,
    29.74
  ],
  [
    12751,
    64.59876,
    12.52254,
    133.3,
    29.5
  ],
  [
    12753,
    64.59918,
    12.52326,
    133.7,
    29.26
  ],
  [
    12755,
    64.59962,
    12.52394,
    134,
    29.06
  ],
  [
    12757,
    64.60006,
    12.52455,
    134.2,
    28.94
  ],
  [
    12759,
    64.60052,
    12.52511,
    134.5,
    28.67
  ],
  [
    12761,
    64.60099,
    12.52561,
    134.9,
    28.53
  ],
  [
    12763,
    64.60146,
    12.52606,
    135.1,
    28.38
  ],
  [
    12765,
    64.60193,
    12.52651,
    135.1,
    28.21
  ],
  [
    12767,
    64.60239,
    12.52696,
    135.2,
    27.92
  ],
  [
    12769,
    64.60283,
    12.52745,
    135.2,
    27.15
  ],
  [
    12771,
    64.60325,
    12.528,
    135.3,
    26.85
  ],
  [
    12773,
    64.60365,
    12.52862,
    135.6,
    26.63
  ],
  [
    12775,
    64.60404,
    12.52927,
    135.9,
    26.45
  ],
  [
    12777,
    64.60441,
    12.52993,
    136.3,
    26.31
  ],
  [
    12779,
    64.60478,
    12.53059,
    136.6,
    25.41
  ],
  [
    12781,
    64.60513,
    12.53123,
    137,
    24.69
  ],
  [
    12783,
    64.60547,
    12.53186,
    137.4,
    23.89
  ],
  [
    12785,
    64.6058,
    12.53247,
    137.7,
    23.39
  ],
  [
    12787,
    64.60613,
    12.53308,
    138,
    23.21
  ],
  [
    12789,
    64.60645,
    12.53368,
    138,
    22.85
  ],
  [
    12791,
    64.60676,
    12.53429,
    137.9,
    22.74
  ],
  [
    12793,
    64.60708,
    12.53489,
    137.9,
    22.67
  ],
  [
    12795,
    64.60739,
    12.5355,
    137.8,
    22.6
  ],
  [
    12797,
    64.6077,
    12.53611,
    137.7,
    22.58
  ],
  [
    12799,
    64.608,
    12.53673,
    137.7,
    22.54
  ],
  [
    12801,
    64.60828,
    12.53739,
    137.6,
    22.54
  ],
  [
    12803,
    64.60855,
    12.53811,
    137.5,
    22.51
  ],
  [
    12805,
    64.60877,
    12.53888,
    137.3,
    22.47
  ],
  [
    12807,
    64.60897,
    12.5397,
    137.3,
    22.47
  ],
  [
    12809,
    64.60915,
    12.54055,
    137.2,
    22.47
  ],
  [
    12811,
    64.60932,
    12.5414,
    137,
    22.54
  ],
  [
    12813,
    64.60951,
    12.54223,
    136.8,
    22.6
  ],
  [
    12815,
    64.60973,
    12.54302,
    136.6,
    22.67
  ],
  [
    12817,
    64.61,
    12.54374,
    136.6,
    22.75
  ],
  [
    12819,
    64.6103,
    12.54437,
    136.6,
    22.81
  ],
  [
    12821,
    64.61063,
    12.54492,
    136.5,
    22.85
  ],
  [
    12823,
    64.611,
    12.54537,
    136.5,
    22.9
  ],
  [
    12825,
    64.61138,
    12.54571,
    136.5,
    22.94
  ],
  [
    12827,
    64.61178,
    12.54593,
    136.7,
    22.97
  ],
  [
    12829,
    64.61219,
    12.54604,
    136.7,
    23
  ],
  [
    12831,
    64.61261,
    12.54607,
    136.7,
    23.02
  ],
  [
    12833,
    64.61302,
    12.54609,
    136.9,
    23.04
  ],
  [
    12835,
    64.61343,
    12.5462,
    137.1,
    23.07
  ],
  [
    12837,
    64.61383,
    12.54643,
    137.3,
    23.02
  ],
  [
    12839,
    64.61421,
    12.54681,
    137.2,
    22.95
  ],
  [
    12841,
    64.61455,
    12.54732,
    137.2,
    22.88
  ],
  [
    12843,
    64.61486,
    12.54794,
    137.4,
    22.81
  ],
  [
    12845,
    64.61513,
    12.54867,
    137.7,
    22.71
  ],
  [
    12847,
    64.61534,
    12.54947,
    137.9,
    22.59
  ],
  [
    12849,
    64.6155,
    12.55034,
    137.9,
    22.45
  ],
  [
    12851,
    64.61562,
    12.55124,
    137.9,
    22.38
  ],
  [
    12853,
    64.61573,
    12.55214,
    138,
    22.37
  ],
  [
    12855,
    64.61584,
    12.55304,
    138.1,
    22.39
  ],
  [
    12857,
    64.61595,
    12.55395,
    138.3,
    22.42
  ],
  [
    12859,
    64.61606,
    12.55485,
    138.3,
    22.48
  ],
  [
    12861,
    64.61617,
    12.55576,
    138.3,
    22.59
  ],
  [
    12863,
    64.61628,
    12.55667,
    138.2,
    22.75
  ],
  [
    12865,
    64.6164,
    12.55759,
    137.9,
    22.99
  ],
  [
    12867,
    64.61654,
    12.5585,
    137.6,
    23.32
  ],
  [
    12869,
    64.6167,
    12.55942,
    137.4,
    23.83
  ],
  [
    12871,
    64.61687,
    12.56033,
    136.8,
    24.21
  ],
  [
    12873,
    64.61708,
    12.56124,
    136.4,
    24.71
  ],
  [
    12875,
    64.6173,
    12.56215,
    135.9,
    25.21
  ],
  [
    12877,
    64.61755,
    12.56304,
    135.4,
    25.71
  ],
  [
    12879,
    64.61783,
    12.56392,
    134.9,
    26.2
  ],
  [
    12881,
    64.61813,
    12.56478,
    134.4,
    26.68
  ],
  [
    12883,
    64.61845,
    12.56561,
    134,
    27.13
  ],
  [
    12885,
    64.6188,
    12.56642,
    133.5,
    27.56
  ],
  [
    12887,
    64.61917,
    12.5672,
    133,
    28.01
  ],
  [
    12889,
    64.61955,
    12.56799,
    132.5,
    28.44
  ],
  [
    12891,
    64.61993,
    12.56878,
    132.1,
    28.86
  ],
  [
    12893,
    64.62032,
    12.56959,
    131.5,
    29.28
  ],
  [
    12895,
    64.62072,
    12.57042,
    130.9,
    29.69
  ],
  [
    12897,
    64.62112,
    12.57125,
    130.3,
    30.12
  ],
  [
    12899,
    64.62152,
    12.57209,
    129.6,
    30.55
  ],
  [
    12901,
    64.62194,
    12.57295,
    129,
    30.95
  ],
  [
    12903,
    64.62235,
    12.57382,
    128.8,
    31.32
  ],
  [
    12905,
    64.62277,
    12.5747,
    128.8,
    31.61
  ],
  [
    12907,
    64.6232,
    12.57558,
    128.9,
    31.84
  ],
  [
    12909,
    64.62363,
    12.57647,
    128.8,
    32.03
  ],
  [
    12911,
    64.62406,
    12.57737,
    128.8,
    32.1
  ],
  [
    12913,
    64.62449,
    12.57826,
    128.8,
    32.07
  ],
  [
    12915,
    64.62492,
    12.57915,
    128.8,
    31.98
  ],
  [
    12917,
    64.62535,
    12.58004,
    128.8,
    31.9
  ],
  [
    12919,
    64.62579,
    12.58089,
    128.7,
    31.79
  ],
  [
    12921,
    64.62625,
    12.58165,
    128.6,
    31.73
  ],
  [
    12923,
    64.62674,
    12.58234,
    128.6,
    31.64
  ],
  [
    12925,
    64.62724,
    12.58295,
    128.6,
    31.57
  ],
  [
    12927,
    64.62776,
    12.58346,
    128.6,
    31.46
  ],
  [
    12929,
    64.6283,
    12.58391,
    128.8,
    31.38
  ],
  [
    12931,
    64.62883,
    12.58433,
    128.9,
    31.29
  ],
  [
    12933,
    64.62936,
    12.58475,
    129,
    31.21
  ],
  [
    12935,
    64.62989,
    12.58518,
    128.9,
    31.13
  ],
  [
    12937,
    64.63041,
    12.58564,
    128.9,
    31.09
  ],
  [
    12939,
    64.63092,
    12.58617,
    128.8,
    31.05
  ],
  [
    12941,
    64.63141,
    12.58678,
    128.6,
    31.01
  ],
  [
    12943,
    64.63188,
    12.58746,
    128.2,
    31.01
  ],
  [
    12945,
    64.63234,
    12.5882,
    127.6,
    31.05
  ],
  [
    12947,
    64.63278,
    12.58901,
    127.1,
    31.12
  ],
  [
    12949,
    64.63319,
    12.58989,
    126.4,
    31.19
  ],
  [
    12951,
    64.6336,
    12.59078,
    126.4,
    31.28
  ],
  [
    12953,
    64.63401,
    12.59168,
    126.4,
    31.37
  ],
  [
    12955,
    64.63443,
    12.59255,
    126.4,
    31.45
  ],
  [
    12957,
    64.63487,
    12.59337,
    126.4,
    31.54
  ],
  [
    12959,
    64.63533,
    12.59414,
    123.8,
    31.64
  ],
  [
    12961,
    64.63581,
    12.59486,
    123.4,
    31.69
  ],
  [
    12963,
    64.63631,
    12.59551,
    123,
    31.78
  ],
  [
    12965,
    64.63682,
    12.59609,
    122.6,
    31.86
  ],
  [
    12967,
    64.63735,
    12.59662,
    122.2,
    31.95
  ],
  [
    12969,
    64.63788,
    12.5971,
    121.8,
    32.04
  ],
  [
    12971,
    64.63842,
    12.59758,
    121.2,
    32.14
  ],
  [
    12973,
    64.63896,
    12.59807,
    120.6,
    32.23
  ],
  [
    12975,
    64.63949,
    12.59858,
    120,
    32.32
  ],
  [
    12978,
    64.64029,
    12.59941,
    119,
    32.41
  ],
  [
    12980,
    64.64081,
    12.60001,
    118.5,
    32.5
  ],
  [
    12982,
    64.64133,
    12.60061,
    118,
    32.48
  ],
  [
    12984,
    64.64184,
    12.60122,
    117.4,
    31.98
  ],
  [
    12986,
    64.64236,
    12.60176,
    116.8,
    31.58
  ],
  [
    12988,
    64.64289,
    12.60221,
    116,
    31.16
  ],
  [
    12990,
    64.64342,
    12.60254,
    115.3,
    30.55
  ],
  [
    12992,
    64.64394,
    12.60278,
    114.8,
    30.55
  ],
  [
    12994,
    64.64445,
    12.60293,
    114.3,
    29.03
  ],
  [
    12996,
    64.64494,
    12.60299,
    113.7,
    29.03
  ],
  [
    12998,
    64.64543,
    12.60298,
    112.9,
    27.1
  ],
  [
    13000,
    64.64591,
    12.60293,
    112.6,
    27.1
  ],
  [
    13002,
    64.64639,
    12.60289,
    112.4,
    27.1
  ],
  [
    13004,
    64.64687,
    12.6029,
    111.8,
    26.81
  ],
  [
    13006,
    64.64735,
    12.60299,
    111.3,
    26.81
  ],
  [
    13008,
    64.6478,
    12.60314,
    111,
    25.89
  ],
  [
    13010,
    64.64825,
    12.60336,
    110.8,
    25.42
  ],
  [
    13012,
    64.64868,
    12.60369,
    110.9,
    25.17
  ],
  [
    13014,
    64.64908,
    12.60414,
    110.9,
    24.96
  ],
  [
    13016,
    64.64944,
    12.60473,
    111,
    24.81
  ],
  [
    13018,
    64.64978,
    12.60541,
    110.8,
    24.72
  ],
  [
    13020,
    64.6501,
    12.60612,
    110.8,
    24.72
  ],
  [
    13022,
    64.65041,
    12.60685,
    110.9,
    24.73
  ],
  [
    13024,
    64.65072,
    12.60759,
    111,
    24.87
  ],
  [
    13026,
    64.65103,
    12.60834,
    111.2,
    24.96
  ],
  [
    13028,
    64.65133,
    12.60911,
    111.4,
    25.03
  ],
  [
    13030,
    64.65163,
    12.60989,
    111.6,
    25.11
  ],
  [
    13032,
    64.65193,
    12.61069,
    111.8,
    25.15
  ],
  [
    13034,
    64.65221,
    12.6115,
    111.9,
    25.31
  ],
  [
    13036,
    64.65249,
    12.61234,
    112,
    25.37
  ],
  [
    13038,
    64.65275,
    12.61322,
    112.2,
    25.47
  ],
  [
    13040,
    64.653,
    12.61412,
    112.2,
    25.53
  ],
  [
    13042,
    64.65324,
    12.61503,
    112.4,
    25.56
  ],
  [
    13044,
    64.65349,
    12.61593,
    112.6,
    25.65
  ],
  [
    13046,
    64.65375,
    12.61682,
    112.9,
    25.72
  ],
  [
    13048,
    64.65402,
    12.61769,
    113.2,
    25.79
  ],
  [
    13050,
    64.6543,
    12.61854,
    113.4,
    25.88
  ],
  [
    13052,
    64.65459,
    12.61938,
    113.8,
    25.91
  ],
  [
    13054,
    64.65489,
    12.62022,
    114,
    26
  ],
  [
    13056,
    64.65519,
    12.62105,
    114.2,
    26.07
  ],
  [
    13058,
    64.65549,
    12.62188,
    114.4,
    26.04
  ],
  [
    13060,
    64.65579,
    12.62272,
    114.6,
    25.95
  ],
  [
    13062,
    64.65609,
    12.62355,
    114.8,
    25.92
  ],
  [
    13064,
    64.65639,
    12.62437,
    114.9,
    25.8
  ],
  [
    13066,
    64.65668,
    12.6252,
    115.1,
    25.75
  ],
  [
    13068,
    64.65698,
    12.62602,
    115.2,
    25.66
  ],
  [
    13070,
    64.65728,
    12.62684,
    115.4,
    25.56
  ],
  [
    13072,
    64.65757,
    12.62765,
    115.4,
    25.47
  ],
  [
    13074,
    64.65787,
    12.62845,
    115.7,
    25.33
  ],
  [
    13076,
    64.65818,
    12.62923,
    115.8,
    25.24
  ],
  [
    13078,
    64.65849,
    12.62998,
    115.9,
    25.13
  ],
  [
    13080,
    64.65882,
    12.6307,
    116,
    25.06
  ],
  [
    13082,
    64.65915,
    12.6314,
    116.1,
    25
  ],
  [
    13084,
    64.65949,
    12.63208,
    116.2,
    25
  ],
  [
    13086,
    64.65984,
    12.63274,
    116.3,
    25
  ],
  [
    13088,
    64.6602,
    12.63337,
    116.5,
    25
  ],
  [
    13090,
    64.66056,
    12.63399,
    116.6,
    25
  ],
  [
    13092,
    64.66093,
    12.6346,
    116.6,
    24.97
  ],
  [
    13094,
    64.66129,
    12.6352,
    116.5,
    24.98
  ],
  [
    13096,
    64.66166,
    12.63579,
    116.5,
    24.97
  ],
  [
    13098,
    64.66203,
    12.63637,
    116.3,
    24.98
  ],
  [
    13100,
    64.66241,
    12.63695,
    116.1,
    25.01
  ],
  [
    13102,
    64.66278,
    12.63752,
    115.9,
    25.09
  ],
  [
    13104,
    64.66316,
    12.63808,
    115.6,
    25.18
  ],
  [
    13106,
    64.66355,
    12.63864,
    115.3,
    25.3
  ],
  [
    13108,
    64.66394,
    12.63919,
    115.1,
    25.42
  ],
  [
    13110,
    64.66433,
    12.63973,
    114.8,
    25.52
  ],
  [
    13112,
    64.66472,
    12.64028,
    114.5,
    25.65
  ],
  [
    13114,
    64.66512,
    12.64082,
    113.9,
    25.73
  ],
  [
    13116,
    64.66551,
    12.64141,
    113.4,
    25.84
  ],
  [
    13118,
    64.66587,
    12.64208,
    113,
    25.9
  ],
  [
    13120,
    64.6662,
    12.64286,
    112.8,
    25.91
  ],
  [
    13122,
    64.66648,
    12.64372,
    112.6,
    25.88
  ],
  [
    13124,
    64.66672,
    12.64465,
    112.4,
    25.86
  ],
  [
    13126,
    64.66691,
    12.64564,
    112.4,
    25.79
  ],
  [
    13128,
    64.66705,
    12.64667,
    112.7,
    25.72
  ],
  [
    13130,
    64.66713,
    12.64773,
    112.7,
    25.61
  ],
  [
    13132,
    64.66717,
    12.6488,
    112.6,
    25.5
  ],
  [
    13134,
    64.66718,
    12.64987,
    112.5,
    25.39
  ],
  [
    13136,
    64.66721,
    12.65094,
    112.4,
    25.35
  ],
  [
    13138,
    64.66727,
    12.65199,
    112.3,
    25.34
  ],
  [
    13140,
    64.66738,
    12.65302,
    112.3,
    25.34
  ],
  [
    13142,
    64.66754,
    12.65401,
    112.5,
    25.33
  ],
  [
    13144,
    64.66774,
    12.65496,
    112.9,
    25.28
  ],
  [
    13146,
    64.66793,
    12.65592,
    113.3,
    25.21
  ],
  [
    13148,
    64.66811,
    12.65689,
    113.6,
    25.08
  ],
  [
    13150,
    64.66826,
    12.65788,
    113.9,
    24.96
  ],
  [
    13152,
    64.66838,
    12.65888,
    114.1,
    24.86
  ],
  [
    13154,
    64.66849,
    12.65989,
    114.2,
    24.78
  ],
  [
    13156,
    64.6686,
    12.6609,
    114.3,
    24.73
  ],
  [
    13158,
    64.66871,
    12.6619,
    114.5,
    24.69
  ],
  [
    13160,
    64.66887,
    12.66286,
    114.6,
    24.65
  ],
  [
    13162,
    64.66907,
    12.66378,
    114.7,
    24.63
  ],
  [
    13164,
    64.66932,
    12.66462,
    114.7,
    24.62
  ],
  [
    13166,
    64.66962,
    12.66539,
    115,
    24.6
  ],
  [
    13168,
    64.66995,
    12.66607,
    115.3,
    24.57
  ],
  [
    13170,
    64.67031,
    12.66664,
    115.3,
    24.54
  ],
  [
    13172,
    64.6707,
    12.66713,
    115.3,
    24.55
  ],
  [
    13174,
    64.67109,
    12.66761,
    115.3,
    24.55
  ],
  [
    13176,
    64.67149,
    12.66808,
    115.4,
    24.58
  ],
  [
    13178,
    64.67188,
    12.66855,
    115.4,
    24.6
  ],
  [
    13180,
    64.67227,
    12.66902,
    115.3,
    24.62
  ],
  [
    13182,
    64.67266,
    12.66951,
    115.4,
    24.66
  ],
  [
    13184,
    64.67305,
    12.66999,
    115.5,
    24.71
  ],
  [
    13186,
    64.67345,
    12.67047,
    115.7,
    24.78
  ],
  [
    13188,
    64.67384,
    12.67096,
    116,
    24.86
  ],
  [
    13190,
    64.67423,
    12.67144,
    116.2,
    24.89
  ],
  [
    13192,
    64.67463,
    12.67192,
    116.6,
    24.92
  ],
  [
    13194,
    64.67503,
    12.6724,
    117,
    24.91
  ],
  [
    13196,
    64.67543,
    12.67288,
    117.5,
    24.9
  ],
  [
    13198,
    64.67582,
    12.67336,
    118.1,
    24.95
  ],
  [
    13200,
    64.67622,
    12.67385,
    118.7,
    25.03
  ],
  [
    13202,
    64.67662,
    12.67433,
    119.2,
    25.09
  ],
  [
    13204,
    64.67702,
    12.67482,
    119.6,
    25.2
  ],
  [
    13206,
    64.67742,
    12.67531,
    120.1,
    25.31
  ],
  [
    13208,
    64.67782,
    12.67582,
    120.7,
    25.3
  ],
  [
    13210,
    64.67821,
    12.67639,
    121.3,
    25.22
  ],
  [
    13212,
    64.67857,
    12.67701,
    121.8,
    25.09
  ],
  [
    13214,
    64.67892,
    12.67768,
    122.4,
    24.97
  ],
  [
    13216,
    64.67924,
    12.67839,
    122.6,
    24.83
  ],
  [
    13218,
    64.67954,
    12.67915,
    122.6,
    24.71
  ],
  [
    13220,
    64.67983,
    12.67995,
    122.6,
    24.57
  ],
  [
    13222,
    64.68009,
    12.68077,
    122.6,
    24.44
  ],
  [
    13224,
    64.68035,
    12.68159,
    122.6,
    24.31
  ],
  [
    13226,
    64.68061,
    12.68241,
    125.4,
    24.19
  ],
  [
    13228,
    64.68087,
    12.68322,
    125.8,
    24.06
  ],
  [
    13230,
    64.68113,
    12.68401,
    126.2,
    23.94
  ],
  [
    13232,
    64.6814,
    12.68479,
    126.9,
    23.82
  ],
  [
    13234,
    64.68168,
    12.68555,
    127.5,
    23.62
  ],
  [
    13236,
    64.68196,
    12.68628,
    128,
    23.35
  ],
  [
    13238,
    64.68224,
    12.68699,
    128.5,
    23.14
  ],
  [
    13241,
    64.68267,
    12.68803,
    129,
    22.95
  ],
  [
    13243,
    64.68295,
    12.68872,
    129.4,
    22.7
  ],
  [
    13245,
    64.68323,
    12.68939,
    129.8,
    22.37
  ],
  [
    13247,
    64.6835,
    12.69005,
    130.3,
    21.92
  ],
  [
    13249,
    64.68376,
    12.6907,
    130.8,
    21.43
  ],
  [
    13251,
    64.68402,
    12.69133,
    131.2,
    21.16
  ],
  [
    13253,
    64.68428,
    12.69194,
    131.5,
    20.62
  ],
  [
    13255,
    64.68455,
    12.69252,
    131.8,
    20.62
  ],
  [
    13257,
    64.68483,
    12.69306,
    132.2,
    20.62
  ],
  [
    13259,
    64.68512,
    12.69353,
    132.8,
    20.04
  ],
  [
    13261,
    64.68543,
    12.69394,
    133.3,
    20.04
  ],
  [
    13263,
    64.68575,
    12.69429,
    133.6,
    19.67
  ],
  [
    13265,
    64.68608,
    12.69457,
    133.7,
    19.67
  ],
  [
    13267,
    64.68642,
    12.69479,
    133.9,
    19.67
  ],
  [
    13269,
    64.68676,
    12.69498,
    134,
    19.65
  ],
  [
    13271,
    64.68711,
    12.69516,
    134.3,
    19.65
  ],
  [
    13273,
    64.68746,
    12.69536,
    134.6,
    19.93
  ],
  [
    13275,
    64.68781,
    12.69557,
    135,
    19.93
  ],
  [
    13277,
    64.68816,
    12.6958,
    135.4,
    20.25
  ],
  [
    13279,
    64.68851,
    12.69604,
    135.7,
    20.41
  ],
  [
    13281,
    64.68886,
    12.69632,
    136.1,
    20.6
  ],
  [
    13283,
    64.68922,
    12.69661,
    136.5,
    20.82
  ],
  [
    13285,
    64.68956,
    12.69692,
    137,
    20.96
  ],
  [
    13287,
    64.68991,
    12.69726,
    137.4,
    21.04
  ],
  [
    13289,
    64.69026,
    12.69762,
    137.6,
    21.1
  ],
  [
    13291,
    64.6906,
    12.698,
    137.8,
    21.16
  ],
  [
    13293,
    64.69093,
    12.69843,
    138,
    21.26
  ],
  [
    13295,
    64.69126,
    12.69889,
    138.1,
    21.37
  ],
  [
    13297,
    64.69158,
    12.69939,
    138.2,
    21.51
  ],
  [
    13299,
    64.6919,
    12.69992,
    138.2,
    21.72
  ],
  [
    13301,
    64.6922,
    12.70049,
    138.2,
    21.92
  ],
  [
    13303,
    64.6925,
    12.70109,
    138.1,
    22.11
  ],
  [
    13305,
    64.69279,
    12.70174,
    138,
    22.2
  ],
  [
    13307,
    64.69306,
    12.70241,
    138,
    22.19
  ],
  [
    13309,
    64.69333,
    12.70311,
    137.9,
    22.12
  ],
  [
    13311,
    64.69358,
    12.70382,
    137.9,
    22.07
  ],
  [
    13313,
    64.69382,
    12.70455,
    137.9,
    22.01
  ],
  [
    13315,
    64.69406,
    12.70528,
    137.8,
    21.95
  ],
  [
    13317,
    64.69429,
    12.70603,
    137.7,
    21.94
  ],
  [
    13319,
    64.69451,
    12.70678,
    137.5,
    21.8
  ],
  [
    13321,
    64.69473,
    12.70754,
    137.4,
    21.19
  ],
  [
    13323,
    64.69493,
    12.70828,
    137.4,
    20.69
  ],
  [
    13325,
    64.69512,
    12.70901,
    137.3,
    20.11
  ],
  [
    13327,
    64.6953,
    12.70972,
    137.4,
    19.81
  ],
  [
    13329,
    64.69547,
    12.71042,
    137.5,
    18.9
  ],
  [
    13331,
    64.69562,
    12.71111,
    137.4,
    18.29
  ],
  [
    13333,
    64.69576,
    12.71179,
    137.3,
    17.69
  ],
  [
    13335,
    64.69589,
    12.71245,
    137.2,
    17.12
  ],
  [
    13337,
    64.69602,
    12.71309,
    137.1,
    16.77
  ],
  [
    13339,
    64.69614,
    12.71371,
    137.1,
    15.65
  ],
  [
    13341,
    64.69625,
    12.71428,
    137.1,
    14.63
  ],
  [
    13343,
    64.69635,
    12.71482,
    137.1,
    13.35
  ],
  [
    13345,
    64.69644,
    12.71529,
    137.1,
    11.37
  ],
  [
    13347,
    64.69651,
    12.71568,
    137.2,
    10.26
  ],
  [
    13349,
    64.69657,
    12.71599,
    137.3,
    7.22
  ],
  [
    13351,
    64.69662,
    12.71623,
    137.3,
    5.65
  ],
  [
    13353,
    64.69665,
    12.71641,
    137.4,
    4.22
  ],
  [
    13355,
    64.69668,
    12.71654,
    137.4,
    2.93
  ],
  [
    13357,
    64.69669,
    12.71663,
    137.4,
    2.38
  ],
  [
    13359,
    64.6967,
    12.71668,
    137.4,
    0.82
  ],
  [
    13361,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13363,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13365,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13367,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13369,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13371,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13373,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13375,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13377,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13379,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13381,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13383,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13385,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13387,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13389,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13391,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13393,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13395,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13397,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13399,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13401,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13403,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13405,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13407,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13409,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13411,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13413,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13415,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13417,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13419,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13421,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13423,
    64.6967,
    12.71669,
    137.5,
    0
  ],
  [
    13425,
    64.69668,
    12.71668,
    138.4,
    2.02
  ],
  [
    13427,
    64.6967,
    12.71678,
    138.4,
    3.06
  ],
  [
    13429,
    64.69672,
    12.71692,
    138.4,
    3.98
  ],
  [
    13431,
    64.69675,
    12.71708,
    138.4,
    4.7
  ],
  [
    13433,
    64.69679,
    12.71727,
    138.4,
    5.27
  ],
  [
    13435,
    64.69683,
    12.71748,
    138.5,
    5.78
  ],
  [
    13437,
    64.69688,
    12.71771,
    138.5,
    6.25
  ],
  [
    13439,
    64.69692,
    12.71796,
    138.5,
    6.66
  ],
  [
    13441,
    64.69697,
    12.71822,
    138.6,
    7.06
  ],
  [
    13443,
    64.69703,
    12.7185,
    138.6,
    7.46
  ],
  [
    13445,
    64.69708,
    12.71879,
    138.6,
    7.87
  ],
  [
    13447,
    64.69714,
    12.7191,
    138.7,
    8.38
  ],
  [
    13449,
    64.69721,
    12.71944,
    138.7,
    8.98
  ],
  [
    13451,
    64.69728,
    12.71979,
    138.8,
    9.62
  ],
  [
    13453,
    64.69735,
    12.72017,
    138.8,
    10.27
  ],
  [
    13455,
    64.69743,
    12.72058,
    138.8,
    10.91
  ],
  [
    13457,
    64.69751,
    12.72101,
    138.8,
    11.52
  ],
  [
    13459,
    64.6976,
    12.72147,
    138.8,
    12.19
  ],
  [
    13461,
    64.6977,
    12.72195,
    138.9,
    12.89
  ],
  [
    13463,
    64.69779,
    12.72245,
    139,
    13.58
  ],
  [
    13465,
    64.6979,
    12.72299,
    139,
    14.22
  ],
  [
    13467,
    64.69801,
    12.72354,
    139,
    14.84
  ],
  [
    13469,
    64.69812,
    12.72412,
    138.9,
    15.45
  ],
  [
    13471,
    64.69824,
    12.72473,
    138.7,
    16.03
  ],
  [
    13473,
    64.69836,
    12.72535,
    138.5,
    16.65
  ],
  [
    13475,
    64.69848,
    12.726,
    138.2,
    17.26
  ],
  [
    13477,
    64.69862,
    12.72668,
    137.9,
    17.89
  ],
  [
    13479,
    64.69875,
    12.72738,
    137.5,
    18.52
  ],
  [
    13481,
    64.69889,
    12.7281,
    137.2,
    19.14
  ],
  [
    13483,
    64.69905,
    12.72883,
    136.8,
    19.74
  ],
  [
    13485,
    64.69922,
    12.72957,
    136.5,
    20.32
  ],
  [
    13487,
    64.69941,
    12.73032,
    136.2,
    20.87
  ],
  [
    13489,
    64.69962,
    12.73107,
    136,
    21.4
  ],
  [
    13491,
    64.69984,
    12.73181,
    136,
    21.88
  ],
  [
    13493,
    64.70009,
    12.73254,
    136,
    22.3
  ],
  [
    13495,
    64.70035,
    12.73326,
    136,
    22.68
  ],
  [
    13497,
    64.70063,
    12.73396,
    136,
    23.05
  ],
  [
    13499,
    64.70093,
    12.73465,
    136,
    23.42
  ],
  [
    13501,
    64.70123,
    12.73535,
    136,
    23.77
  ],
  [
    13503,
    64.70154,
    12.73605,
    136,
    24.11
  ],
  [
    13505,
    64.70185,
    12.73677,
    136,
    24.44
  ],
  [
    13507,
    64.70217,
    12.73749,
    136,
    24.7
  ],
  [
    13509,
    64.70249,
    12.73822,
    136.8,
    24.95
  ],
  [
    13511,
    64.70281,
    12.73896,
    137.1,
    25.18
  ],
  [
    13513,
    64.70314,
    12.7397,
    137.3,
    25.39
  ],
  [
    13515,
    64.70347,
    12.74045,
    137.6,
    25.61
  ],
  [
    13517,
    64.7038,
    12.7412,
    137.9,
    25.81
  ],
  [
    13519,
    64.70413,
    12.74196,
    138.2,
    26.02
  ],
  [
    13521,
    64.70447,
    12.74273,
    138.5,
    26.22
  ],
  [
    13523,
    64.7048,
    12.74351,
    138.7,
    26.42
  ],
  [
    13525,
    64.70515,
    12.74429,
    139,
    26.63
  ],
  [
    13527,
    64.70549,
    12.74507,
    139.2,
    26.83
  ],
  [
    13530,
    64.70601,
    12.74626,
    139.7,
    27.04
  ],
  [
    13532,
    64.70636,
    12.74706,
    139.8,
    27.26
  ],
  [
    13534,
    64.70671,
    12.74787,
    140,
    27.48
  ],
  [
    13536,
    64.70707,
    12.74869,
    140.1,
    27.71
  ],
  [
    13538,
    64.70743,
    12.74951,
    140.2,
    27.93
  ],
  [
    13540,
    64.70779,
    12.75033,
    140.3,
    27.93
  ],
  [
    13542,
    64.70816,
    12.75117,
    140.4,
    27.93
  ],
  [
    13544,
    64.70852,
    12.75201,
    140.5,
    28.49
  ],
  [
    13546,
    64.70889,
    12.75286,
    140.6,
    28.49
  ],
  [
    13548,
    64.70927,
    12.75371,
    140.8,
    29.01
  ],
  [
    13550,
    64.70964,
    12.75457,
    140.9,
    29.01
  ],
  [
    13552,
    64.71002,
    12.75543,
    141.1,
    29.01
  ],
  [
    13554,
    64.7104,
    12.7563,
    141.2,
    29.47
  ],
  [
    13556,
    64.71078,
    12.75718,
    141.4,
    29.47
  ],
  [
    13558,
    64.71117,
    12.75806,
    141.5,
    29.95
  ],
  [
    13560,
    64.71155,
    12.75894,
    141.7,
    29.97
  ],
  [
    13562,
    64.71193,
    12.75981,
    141.8,
    29.88
  ],
  [
    13564,
    64.71232,
    12.76069,
    141.9,
    29.74
  ],
  [
    13566,
    64.7127,
    12.76156,
    142.1,
    29.62
  ],
  [
    13568,
    64.71308,
    12.76243,
    142.3,
    29.51
  ],
  [
    13570,
    64.71345,
    12.76329,
    142.4,
    29.39
  ],
  [
    13572,
    64.71383,
    12.76415,
    142.5,
    29.27
  ],
  [
    13574,
    64.7142,
    12.76501,
    142.5,
    29.15
  ],
  [
    13576,
    64.71458,
    12.76586,
    142.5,
    29.06
  ],
  [
    13578,
    64.71495,
    12.76671,
    142.4,
    28.98
  ],
  [
    13580,
    64.71531,
    12.76755,
    142.5,
    28.17
  ],
  [
    13582,
    64.71567,
    12.76837,
    142.5,
    27.88
  ],
  [
    13584,
    64.71602,
    12.76917,
    142.5,
    26.97
  ],
  [
    13586,
    64.71636,
    12.76995,
    142.5,
    26.25
  ],
  [
    13588,
    64.71669,
    12.77071,
    142.6,
    25.55
  ],
  [
    13590,
    64.71702,
    12.77145,
    142.6,
    25.11
  ],
  [
    13592,
    64.71734,
    12.77219,
    142.7,
    24.98
  ],
  [
    13594,
    64.71765,
    12.77291,
    142.6,
    24.7
  ],
  [
    13596,
    64.71797,
    12.77364,
    142.6,
    24.63
  ],
  [
    13598,
    64.71828,
    12.77436,
    142.5,
    24.58
  ],
  [
    13600,
    64.7186,
    12.77507,
    142.5,
    24.51
  ],
  [
    13602,
    64.71893,
    12.77576,
    142.5,
    24.49
  ],
  [
    13604,
    64.71928,
    12.77638,
    142.4,
    24.42
  ],
  [
    13606,
    64.71964,
    12.77693,
    142.4,
    24.44
  ],
  [
    13608,
    64.72003,
    12.77741,
    142.5,
    24.47
  ],
  [
    13610,
    64.72043,
    12.77783,
    142.6,
    24.5
  ],
  [
    13612,
    64.72084,
    12.77822,
    142.6,
    24.52
  ],
  [
    13614,
    64.72125,
    12.77861,
    142.6,
    24.57
  ],
  [
    13616,
    64.72166,
    12.77899,
    142.6,
    24.6
  ],
  [
    13618,
    64.72206,
    12.77941,
    142.8,
    24.61
  ],
  [
    13620,
    64.72245,
    12.77989,
    143.1,
    24.47
  ],
  [
    13622,
    64.72283,
    12.7804,
    143.4,
    24.47
  ],
  [
    13624,
    64.72321,
    12.78091,
    143.8,
    24.47
  ],
  [
    13628,
    64.72373,
    12.78263,
    145.2,
    23.79
  ],
  [
    13630,
    64.72399,
    12.78347,
    144.9,
    24.04
  ],
  [
    13632,
    64.72427,
    12.78425,
    144.8,
    24
  ],
  [
    13634,
    64.72455,
    12.78501,
    144.7,
    23.92
  ],
  [
    13636,
    64.72484,
    12.78576,
    144.6,
    23.88
  ],
  [
    13638,
    64.72512,
    12.78651,
    144.3,
    23.85
  ],
  [
    13640,
    64.7254,
    12.78726,
    144.3,
    23.84
  ],
  [
    13642,
    64.72569,
    12.78801,
    144.3,
    23.83
  ],
  [
    13644,
    64.72597,
    12.78876,
    144.2,
    23.77
  ],
  [
    13646,
    64.72624,
    12.78952,
    144.2,
    23.75
  ],
  [
    13648,
    64.72649,
    12.79032,
    144.2,
    23.73
  ],
  [
    13650,
    64.72672,
    12.79116,
    144.3,
    23.7
  ],
  [
    13652,
    64.72693,
    12.79203,
    144.6,
    23.68
  ],
  [
    13654,
    64.72711,
    12.79292,
    145,
    23.6
  ],
  [
    13656,
    64.72728,
    12.79383,
    145.4,
    23.52
  ],
  [
    13658,
    64.72746,
    12.79472,
    145.8,
    23.38
  ],
  [
    13660,
    64.72767,
    12.79557,
    146.4,
    23.28
  ],
  [
    13662,
    64.72791,
    12.79637,
    146.9,
    23.24
  ],
  [
    13664,
    64.72818,
    12.7971,
    147.4,
    23.09
  ],
  [
    13666,
    64.72847,
    12.79779,
    147.9,
    23.03
  ],
  [
    13668,
    64.72877,
    12.79845,
    148.4,
    22.95
  ],
  [
    13670,
    64.72907,
    12.79911,
    149,
    22.88
  ],
  [
    13672,
    64.72938,
    12.79975,
    149.6,
    22.82
  ],
  [
    13674,
    64.72968,
    12.80039,
    150.1,
    22.73
  ],
  [
    13676,
    64.72999,
    12.80103,
    150.6,
    22.64
  ],
  [
    13678,
    64.73029,
    12.80166,
    151.2,
    22.55
  ],
  [
    13680,
    64.73059,
    12.80228,
    151.8,
    22.45
  ],
  [
    13682,
    64.73089,
    12.8029,
    152.3,
    22.38
  ],
  [
    13684,
    64.7312,
    12.80351,
    152.6,
    22.33
  ],
  [
    13686,
    64.73152,
    12.80406,
    153,
    22.3
  ],
  [
    13688,
    64.73186,
    12.80456,
    153.3,
    22.3
  ],
  [
    13690,
    64.73221,
    12.805,
    153.5,
    22.3
  ],
  [
    13692,
    64.73258,
    12.80538,
    153.8,
    22.31
  ],
  [
    13694,
    64.73295,
    12.80569,
    154.2,
    22.3
  ],
  [
    13696,
    64.73334,
    12.80594,
    154.3,
    22.32
  ],
  [
    13698,
    64.73373,
    12.80612,
    154.4,
    22.33
  ],
  [
    13700,
    64.73413,
    12.80626,
    154.5,
    22.33
  ],
  [
    13702,
    64.73453,
    12.80643,
    154.7,
    22.34
  ],
  [
    13704,
    64.73491,
    12.80671,
    154.8,
    22.31
  ],
  [
    13706,
    64.73526,
    12.80713,
    155.1,
    22.23
  ],
  [
    13708,
    64.73559,
    12.80766,
    155.3,
    22.13
  ],
  [
    13710,
    64.73589,
    12.80827,
    155.6,
    22.06
  ],
  [
    13712,
    64.73617,
    12.8089,
    155.9,
    21.98
  ],
  [
    13714,
    64.73647,
    12.8095,
    156.2,
    21.91
  ],
  [
    13716,
    64.7368,
    12.81001,
    156.6,
    21.9
  ],
  [
    13718,
    64.73715,
    12.81043,
    157,
    21.88
  ],
  [
    13720,
    64.73751,
    12.81077,
    157.4,
    21.89
  ],
  [
    13722,
    64.73789,
    12.811,
    157.6,
    21.89
  ],
  [
    13724,
    64.73828,
    12.81114,
    157.8,
    21.94
  ],
  [
    13726,
    64.73867,
    12.81124,
    158.1,
    21.98
  ],
  [
    13728,
    64.73906,
    12.81136,
    158.5,
    22.07
  ],
  [
    13730,
    64.73945,
    12.81152,
    158.9,
    22.13
  ],
  [
    13732,
    64.73984,
    12.81172,
    159.3,
    22.21
  ],
  [
    13734,
    64.74023,
    12.81194,
    159.6,
    22.4
  ],
  [
    13736,
    64.74062,
    12.8122,
    159.8,
    22.57
  ],
  [
    13738,
    64.741,
    12.8125,
    160,
    22.77
  ],
  [
    13740,
    64.74139,
    12.81282,
    160.2,
    23.03
  ],
  [
    13742,
    64.74178,
    12.81318,
    160.4,
    23.26
  ],
  [
    13744,
    64.74216,
    12.81358,
    160.7,
    23.47
  ],
  [
    13746,
    64.74254,
    12.81401,
    161,
    23.62
  ],
  [
    13748,
    64.74291,
    12.81448,
    161.1,
    23.75
  ],
  [
    13750,
    64.74329,
    12.81497,
    161.3,
    23.91
  ],
  [
    13752,
    64.74365,
    12.8155,
    161.6,
    24.06
  ],
  [
    13754,
    64.74401,
    12.81606,
    161.8,
    24.21
  ],
  [
    13756,
    64.74437,
    12.81665,
    162.1,
    24.34
  ],
  [
    13758,
    64.74471,
    12.81728,
    162,
    24.32
  ],
  [
    13760,
    64.74505,
    12.81794,
    162.1,
    24.34
  ],
  [
    13762,
    64.74537,
    12.81861,
    162.3,
    24.35
  ],
  [
    13764,
    64.74569,
    12.81933,
    162.5,
    24.4
  ],
  [
    13766,
    64.74598,
    12.82009,
    162.6,
    24.45
  ],
  [
    13768,
    64.74624,
    12.82091,
    162.6,
    24.51
  ],
  [
    13770,
    64.74648,
    12.82178,
    162.5,
    24.55
  ],
  [
    13772,
    64.74671,
    12.82266,
    162.4,
    24.6
  ],
  [
    13774,
    64.74696,
    12.82352,
    162.6,
    24.68
  ],
  [
    13776,
    64.74723,
    12.82434,
    162.6,
    24.79
  ],
  [
    13778,
    64.74753,
    12.82512,
    162.7,
    24.83
  ],
  [
    13780,
    64.74784,
    12.82585,
    162.8,
    24.8
  ],
  [
    13782,
    64.74818,
    12.82654,
    163,
    24.72
  ],
  [
    13784,
    64.74853,
    12.82716,
    163.1,
    24.66
  ],
  [
    13786,
    64.7489,
    12.82773,
    163.2,
    24.59
  ],
  [
    13788,
    64.74928,
    12.82824,
    163.3,
    24.54
  ],
  [
    13790,
    64.74967,
    12.82873,
    163.3,
    24.49
  ],
  [
    13792,
    64.75005,
    12.82922,
    163.3,
    24.46
  ],
  [
    13794,
    64.75043,
    12.82975,
    163.3,
    24.43
  ],
  [
    13796,
    64.75077,
    12.83039,
    163.3,
    24.41
  ],
  [
    13798,
    64.75107,
    12.83112,
    163.3,
    24.32
  ],
  [
    13800,
    64.75133,
    12.83195,
    163.3,
    24.25
  ],
  [
    13802,
    64.75154,
    12.83284,
    163.3,
    24.17
  ],
  [
    13804,
    64.75172,
    12.83376,
    163,
    24.1
  ],
  [
    13806,
    64.75189,
    12.83468,
    162.9,
    24.06
  ],
  [
    13808,
    64.75207,
    12.8356,
    162.7,
    24.02
  ],
  [
    13810,
    64.75226,
    12.83651,
    162.6,
    23.97
  ],
  [
    13812,
    64.75247,
    12.83739,
    162.6,
    23.92
  ],
  [
    13814,
    64.7527,
    12.83823,
    162.6,
    23.93
  ],
  [
    13816,
    64.75295,
    12.83904,
    162.7,
    23.95
  ],
  [
    13818,
    64.75323,
    12.83981,
    162.8,
    23.99
  ],
  [
    13820,
    64.75352,
    12.84055,
    162.9,
    24.06
  ],
  [
    13822,
    64.75382,
    12.84128,
    163.1,
    24.11
  ],
  [
    13824,
    64.75412,
    12.842,
    163,
    24.16
  ],
  [
    13826,
    64.75442,
    12.84273,
    163,
    24.2
  ],
  [
    13829,
    64.75486,
    12.84387,
    162.9,
    24.28
  ],
  [
    13831,
    64.75512,
    12.84468,
    162.9,
    24.34
  ],
  [
    13833,
    64.75537,
    12.84553,
    162.8,
    24.39
  ],
  [
    13835,
    64.75559,
    12.84641,
    163,
    24.45
  ],
  [
    13837,
    64.75579,
    12.84732,
    163.1,
    24.49
  ],
  [
    13839,
    64.75598,
    12.84826,
    163.1,
    24.52
  ],
  [
    13841,
    64.75616,
    12.84919,
    163,
    24.52
  ],
  [
    13843,
    64.75635,
    12.85012,
    162.8,
    24.45
  ],
  [
    13845,
    64.75655,
    12.85102,
    162.6,
    24.45
  ],
  [
    13847,
    64.75677,
    12.85188,
    162.4,
    24.45
  ],
  [
    13849,
    64.757,
    12.85269,
    162.1,
    23.55
  ],
  [
    13851,
    64.75724,
    12.85347,
    161.9,
    23.55
  ],
  [
    13853,
    64.75749,
    12.85419,
    161.7,
    22.27
  ],
  [
    13855,
    64.75775,
    12.85488,
    161.5,
    22.27
  ],
  [
    13857,
    64.75802,
    12.85553,
    161.4,
    22.27
  ],
  [
    13859,
    64.7583,
    12.85615,
    161.4,
    21.46
  ],
  [
    13861,
    64.75861,
    12.85668,
    161.3,
    21.41
  ],
  [
    13863,
    64.75894,
    12.85709,
    161.1,
    21.37
  ],
  [
    13865,
    64.75931,
    12.85739,
    161,
    21.35
  ],
  [
    13867,
    64.75968,
    12.85757,
    161,
    21.32
  ],
  [
    13869,
    64.76006,
    12.85762,
    160.9,
    21.33
  ],
  [
    13871,
    64.76045,
    12.85759,
    160.7,
    21.33
  ],
  [
    13873,
    64.76083,
    12.85757,
    160.6,
    21.35
  ],
  [
    13875,
    64.76122,
    12.85764,
    160.5,
    21.39
  ],
  [
    13877,
    64.76159,
    12.85785,
    160.3,
    21.4
  ],
  [
    13879,
    64.76195,
    12.85818,
    160.2,
    21.4
  ],
  [
    13881,
    64.76228,
    12.85862,
    160.1,
    21.41
  ],
  [
    13883,
    64.76258,
    12.85917,
    159.9,
    21.41
  ],
  [
    13885,
    64.76285,
    12.85982,
    159.6,
    21.39
  ],
  [
    13887,
    64.76308,
    12.86052,
    159.5,
    21.37
  ],
  [
    13889,
    64.7633,
    12.86123,
    158.8,
    20.64
  ],
  [
    13891,
    64.76352,
    12.86197,
    158.8,
    21.29
  ],
  [
    13893,
    64.76374,
    12.86269,
    159,
    21.4
  ],
  [
    13895,
    64.76398,
    12.86342,
    159.2,
    21.46
  ],
  [
    13897,
    64.76421,
    12.86414,
    159.4,
    21.51
  ],
  [
    13899,
    64.76444,
    12.86486,
    159.6,
    21.59
  ],
  [
    13901,
    64.76468,
    12.86557,
    159.9,
    21.65
  ],
  [
    13903,
    64.76494,
    12.86626,
    160.1,
    21.77
  ],
  [
    13905,
    64.76521,
    12.86692,
    160.3,
    21.93
  ],
  [
    13907,
    64.7655,
    12.86755,
    160.7,
    22.01
  ],
  [
    13909,
    64.76581,
    12.86814,
    161.3,
    22.24
  ],
  [
    13911,
    64.76613,
    12.86869,
    162,
    22.39
  ],
  [
    13913,
    64.76646,
    12.86922,
    162.4,
    22.59
  ],
  [
    13915,
    64.76681,
    12.86973,
    162.5,
    22.81
  ],
  [
    13917,
    64.76715,
    12.87025,
    162.6,
    22.91
  ],
  [
    13919,
    64.7675,
    12.87077,
    162.7,
    23.38
  ],
  [
    13921,
    64.76785,
    12.87131,
    162.7,
    23.28
  ],
  [
    13923,
    64.76821,
    12.87186,
    163,
    24.03
  ],
  [
    13925,
    64.76857,
    12.8724,
    163.2,
    24.34
  ],
  [
    13927,
    64.76894,
    12.87295,
    163.4,
    24.5
  ],
  [
    13929,
    64.76932,
    12.87351,
    163.5,
    24.97
  ],
  [
    13931,
    64.7697,
    12.87408,
    163.6,
    25.28
  ],
  [
    13933,
    64.77008,
    12.87466,
    163.9,
    25.58
  ],
  [
    13935,
    64.77047,
    12.87524,
    164.3,
    25.84
  ],
  [
    13937,
    64.77086,
    12.87583,
    164.8,
    25.96
  ],
  [
    13939,
    64.77125,
    12.87642,
    165.3,
    26.23
  ],
  [
    13941,
    64.77165,
    12.87702,
    165.7,
    26.4
  ],
  [
    13943,
    64.77205,
    12.87762,
    166.1,
    26.58
  ],
  [
    13945,
    64.77245,
    12.87822,
    166.4,
    26.76
  ],
  [
    13947,
    64.77286,
    12.87883,
    166.9,
    26.94
  ],
  [
    13949,
    64.77327,
    12.87945,
    167.3,
    27.11
  ],
  [
    13951,
    64.77368,
    12.88007,
    167.7,
    27.28
  ],
  [
    13953,
    64.77409,
    12.8807,
    168.1,
    27.45
  ],
  [
    13955,
    64.77449,
    12.88135,
    168.4,
    27.62
  ],
  [
    13957,
    64.7749,
    12.88201,
    168.5,
    27.8
  ],
  [
    13959,
    64.77531,
    12.88269,
    168.6,
    28.03
  ],
  [
    13961,
    64.77572,
    12.88339,
    168.7,
    28.28
  ],
  [
    13963,
    64.77613,
    12.8841,
    168.7,
    28.52
  ],
  [
    13965,
    64.77654,
    12.88483,
    168.7,
    28.77
  ],
  [
    13967,
    64.77694,
    12.88558,
    168.7,
    29.02
  ],
  [
    13969,
    64.77735,
    12.88635,
    168.7,
    29.27
  ],
  [
    13971,
    64.77776,
    12.88713,
    168.8,
    29.51
  ],
  [
    13973,
    64.77817,
    12.88794,
    168.8,
    29.73
  ],
  [
    13975,
    64.77857,
    12.88876,
    168.9,
    29.96
  ],
  [
    13977,
    64.77898,
    12.88959,
    169,
    30.19
  ],
  [
    13979,
    64.77938,
    12.89045,
    169.1,
    30.39
  ],
  [
    13981,
    64.77978,
    12.89132,
    169.2,
    30.58
  ],
  [
    13983,
    64.78018,
    12.89221,
    169.2,
    30.8
  ],
  [
    13985,
    64.78058,
    12.89312,
    169.1,
    31.04
  ],
  [
    13987,
    64.78097,
    12.89405,
    169.2,
    31.27
  ],
  [
    13989,
    64.78136,
    12.895,
    169.3,
    31.47
  ],
  [
    13991,
    64.78175,
    12.89596,
    169.5,
    31.65
  ],
  [
    13993,
    64.78214,
    12.89693,
    169.8,
    31.79
  ],
  [
    13995,
    64.78253,
    12.89791,
    170.1,
    31.92
  ],
  [
    13997,
    64.78293,
    12.8989,
    170.5,
    32.03
  ],
  [
    13999,
    64.78332,
    12.89988,
    170.6,
    32.16
  ],
  [
    14001,
    64.78372,
    12.90087,
    170.7,
    32.31
  ],
  [
    14003,
    64.78411,
    12.90187,
    170.7,
    32.48
  ],
  [
    14005,
    64.78451,
    12.90287,
    170.8,
    32.68
  ],
  [
    14007,
    64.78491,
    12.90387,
    170.8,
    32.86
  ],
  [
    14009,
    64.78532,
    12.90489,
    171,
    33.05
  ],
  [
    14011,
    64.78573,
    12.9059,
    171.3,
    33.18
  ],
  [
    14013,
    64.78613,
    12.90693,
    171.6,
    33.3
  ],
  [
    14015,
    64.78654,
    12.90795,
    171.8,
    33.38
  ],
  [
    14017,
    64.78695,
    12.90898,
    172.1,
    33.49
  ],
  [
    14019,
    64.78736,
    12.91001,
    172.2,
    33.55
  ],
  [
    14021,
    64.78777,
    12.91104,
    172.4,
    33.6
  ],
  [
    14023,
    64.78819,
    12.91208,
    172.5,
    33.65
  ],
  [
    14025,
    64.7886,
    12.91311,
    172.7,
    33.7
  ],
  [
    14027,
    64.78901,
    12.91415,
    172.8,
    33.74
  ],
  [
    14029,
    64.78943,
    12.91518,
    173,
    33.78
  ],
  [
    14031,
    64.78984,
    12.91622,
    173.2,
    33.82
  ],
  [
    14033,
    64.79026,
    12.91726,
    173.4,
    33.86
  ],
  [
    14035,
    64.79067,
    12.9183,
    173.5,
    33.9
  ],
  [
    14037,
    64.7911,
    12.91933,
    173.8,
    33.93
  ],
  [
    14039,
    64.79152,
    12.92035,
    174.2,
    33.95
  ],
  [
    14041,
    64.79195,
    12.92137,
    174.5,
    33.96
  ],
  [
    14043,
    64.79238,
    12.92238,
    174.7,
    33.95
  ],
  [
    14045,
    64.79281,
    12.92338,
    174.8,
    34.03
  ],
  [
    14047,
    64.79325,
    12.92438,
    174.9,
    34.08
  ],
  [
    14049,
    64.7937,
    12.92537,
    174.9,
    34.13
  ],
  [
    14051,
    64.79414,
    12.92636,
    174.9,
    34.21
  ],
  [
    14053,
    64.79459,
    12.92734,
    174.9,
    34.25
  ],
  [
    14055,
    64.79504,
    12.92832,
    174.9,
    34.28
  ],
  [
    14057,
    64.7955,
    12.9293,
    174.9,
    34.31
  ],
  [
    14059,
    64.79595,
    12.93028,
    174.8,
    34.31
  ],
  [
    14061,
    64.7964,
    12.93125,
    175.1,
    34.29
  ],
  [
    14063,
    64.79685,
    12.93223,
    175.5,
    34.25
  ],
  [
    14065,
    64.7973,
    12.93321,
    175.8,
    34.2
  ],
  [
    14067,
    64.79775,
    12.93419,
    176,
    34.15
  ],
  [
    14069,
    64.7982,
    12.93516,
    176.3,
    34.12
  ],
  [
    14071,
    64.79865,
    12.93613,
    176.5,
    34.11
  ],
  [
    14073,
    64.7991,
    12.93711,
    176.6,
    34.09
  ],
  [
    14076,
    64.79978,
    12.93856,
    176.8,
    34.09
  ],
  [
    14078,
    64.80023,
    12.93954,
    177,
    34.09
  ],
  [
    14080,
    64.80068,
    12.94051,
    177.2,
    34.07
  ],
  [
    14082,
    64.80113,
    12.94148,
    177.4,
    34.05
  ],
  [
    14084,
    64.80158,
    12.94245,
    177.7,
    34.03
  ],
  [
    14086,
    64.80202,
    12.94342,
    177.8,
    34.01
  ],
  [
    14088,
    64.80247,
    12.94439,
    177.8,
    34.01
  ],
  [
    14090,
    64.80292,
    12.94536,
    177.8,
    34.01
  ],
  [
    14092,
    64.80337,
    12.94633,
    177.9,
    34.01
  ],
  [
    14094,
    64.80382,
    12.94731,
    178.4,
    34.03
  ],
  [
    14096,
    64.80426,
    12.94828,
    178.8,
    34.03
  ],
  [
    14098,
    64.80471,
    12.94925,
    179.2,
    33.86
  ],
  [
    14100,
    64.80515,
    12.95021,
    179.6,
    33.86
  ],
  [
    14102,
    64.8056,
    12.95117,
    180,
    33.86
  ],
  [
    14104,
    64.80604,
    12.95212,
    180.4,
    33.49
  ],
  [
    14106,
    64.80647,
    12.95307,
    180.8,
    33.49
  ],
  [
    14108,
    64.80691,
    12.95402,
    181.2,
    33.1
  ],
  [
    14110,
    64.80734,
    12.95496,
    181.6,
    33.1
  ],
  [
    14112,
    64.80778,
    12.9559,
    181.9,
    32.79
  ],
  [
    14114,
    64.80821,
    12.95683,
    182,
    32.67
  ],
  [
    14116,
    64.80864,
    12.95776,
    182,
    32.6
  ],
  [
    14118,
    64.80906,
    12.95869,
    181.8,
    32.58
  ],
  [
    14120,
    64.80949,
    12.95963,
    181.5,
    32.58
  ],
  [
    14122,
    64.80992,
    12.96056,
    181.3,
    32.65
  ],
  [
    14124,
    64.81035,
    12.96149,
    181,
    32.72
  ],
  [
    14126,
    64.81079,
    12.96243,
    180.7,
    32.81
  ],
  [
    14128,
    64.81122,
    12.96337,
    180.4,
    32.89
  ],
  [
    14130,
    64.81165,
    12.96431,
    180.1,
    32.97
  ],
  [
    14132,
    64.81209,
    12.96526,
    179.8,
    33.07
  ],
  [
    14134,
    64.81253,
    12.96621,
    179.6,
    33.2
  ],
  [
    14136,
    64.81296,
    12.96716,
    179.4,
    33.26
  ],
  [
    14138,
    64.8134,
    12.96811,
    179.3,
    33.33
  ],
  [
    14140,
    64.81385,
    12.96906,
    179.3,
    33.37
  ],
  [
    14142,
    64.81429,
    12.97,
    179.3,
    33.38
  ],
  [
    14144,
    64.81474,
    12.97093,
    179.3,
    33.39
  ],
  [
    14146,
    64.81519,
    12.97186,
    179.2,
    33.4
  ],
  [
    14148,
    64.81564,
    12.97278,
    179.1,
    33.41
  ],
  [
    14150,
    64.8161,
    12.97369,
    179.1,
    33.43
  ],
  [
    14152,
    64.81656,
    12.97459,
    179.1,
    33.42
  ],
  [
    14154,
    64.81702,
    12.97549,
    179.1,
    33.38
  ],
  [
    14156,
    64.81748,
    12.97638,
    179.1,
    33.3
  ],
  [
    14158,
    64.81795,
    12.97726,
    179.1,
    33.21
  ],
  [
    14160,
    64.81841,
    12.97813,
    179.1,
    33.14
  ],
  [
    14162,
    64.81888,
    12.97899,
    179,
    33.09
  ],
  [
    14164,
    64.81935,
    12.97984,
    179,
    32.98
  ],
  [
    14166,
    64.81981,
    12.98069,
    178.9,
    32.9
  ],
  [
    14168,
    64.82028,
    12.98152,
    178.9,
    32.49
  ],
  [
    14170,
    64.82075,
    12.98233,
    178.9,
    31.88
  ],
  [
    14172,
    64.8212,
    12.98312,
    178.8,
    31.59
  ],
  [
    14174,
    64.82165,
    12.98388,
    178.8,
    30.71
  ],
  [
    14176,
    64.8221,
    12.98463,
    178.7,
    30.12
  ],
  [
    14178,
    64.82253,
    12.98535,
    178.7,
    29.52
  ],
  [
    14180,
    64.82296,
    12.98605,
    178.7,
    28.87
  ],
  [
    14182,
    64.82338,
    12.98674,
    178.9,
    28.6
  ],
  [
    14184,
    64.8238,
    12.9874,
    179.4,
    28.04
  ],
  [
    14186,
    64.82421,
    12.98806,
    179.9,
    27.72
  ],
  [
    14188,
    64.82463,
    12.9887,
    180.4,
    27.45
  ],
  [
    14190,
    64.82504,
    12.98933,
    180.9,
    27.21
  ],
  [
    14192,
    64.82545,
    12.98993,
    181.4,
    27.04
  ],
  [
    14194,
    64.82587,
    12.99047,
    181.8,
    26.91
  ],
  [
    14196,
    64.82631,
    12.99093,
    182.3,
    26.79
  ],
  [
    14198,
    64.82676,
    12.99133,
    182.8,
    26.71
  ],
  [
    14200,
    64.82722,
    12.99165,
    183.3,
    26.68
  ],
  [
    14202,
    64.82769,
    12.9919,
    183.7,
    26.7
  ],
  [
    14204,
    64.82816,
    12.99211,
    184.1,
    26.75
  ],
  [
    14206,
    64.82863,
    12.99232,
    184.6,
    26.82
  ],
  [
    14208,
    64.8291,
    12.99257,
    185.1,
    26.93
  ],
  [
    14210,
    64.82955,
    12.99293,
    185.5,
    27.02
  ],
  [
    14212,
    64.82999,
    12.99341,
    186,
    27.11
  ],
  [
    14214,
    64.83041,
    12.99399,
    186.5,
    27.19
  ],
  [
    14216,
    64.8308,
    12.99469,
    186.8,
    27.28
  ],
  [
    14218,
    64.83116,
    12.99547,
    187.1,
    27.38
  ],
  [
    14220,
    64.8315,
    12.99631,
    187.3,
    27.51
  ],
  [
    14222,
    64.83185,
    12.99712,
    187.4,
    27.66
  ],
  [
    14224,
    64.83224,
    12.99785,
    187.7,
    27.84
  ],
  [
    14226,
    64.83266,
    12.99847,
    187.9,
    28
  ],
  [
    14228,
    64.83311,
    12.999,
    188.1,
    28.19
  ],
  [
    14230,
    64.83358,
    12.99948,
    188.4,
    28.34
  ],
  [
    14232,
    64.83404,
    13,
    188.7,
    28.52
  ],
  [
    14234,
    64.83449,
    13.00058,
    189.2,
    28.67
  ],
  [
    14236,
    64.83492,
    13.00123,
    189.9,
    28.76
  ],
  [
    14238,
    64.83534,
    13.00193,
    190.4,
    28.83
  ],
  [
    14240,
    64.83575,
    13.00266,
    190.9,
    28.87
  ],
  [
    14242,
    64.83617,
    13.00339,
    191.4,
    28.91
  ],
  [
    14244,
    64.8366,
    13.00408,
    191.9,
    28.95
  ],
  [
    14246,
    64.83705,
    13.00469,
    192.4,
    29
  ],
  [
    14248,
    64.83752,
    13.00522,
    193,
    29.05
  ],
  [
    14250,
    64.838,
    13.00567,
    193.5,
    29.1
  ],
  [
    14252,
    64.83849,
    13.00607,
    194,
    29.18
  ],
  [
    14254,
    64.83899,
    13.00647,
    194.6,
    29.25
  ],
  [
    14256,
    64.83947,
    13.00694,
    195.1,
    29.31
  ],
  [
    14258,
    64.83994,
    13.00749,
    195.6,
    29.29
  ],
  [
    14260,
    64.84039,
    13.00813,
    196.1,
    29.05
  ],
  [
    14262,
    64.84083,
    13.00879,
    196.5,
    28.79
  ],
  [
    14264,
    64.84125,
    13.00947,
    197,
    28.55
  ],
  [
    14266,
    64.84167,
    13.01015,
    197.5,
    28.31
  ],
  [
    14268,
    64.84209,
    13.01082,
    198,
    28.06
  ],
  [
    14270,
    64.8425,
    13.0115,
    198.6,
    27.81
  ],
  [
    14272,
    64.8429,
    13.01218,
    199.2,
    27.57
  ],
  [
    14274,
    64.84329,
    13.01288,
    199.7,
    27.32
  ],
  [
    14276,
    64.84367,
    13.01361,
    200.3,
    26.96
  ],
  [
    14278,
    64.84401,
    13.01438,
    200.6,
    26.23
  ],
  [
    14280,
    64.84434,
    13.01515,
    200.7,
    25.56
  ],
  [
    14282,
    64.84466,
    13.01591,
    200.7,
    25
  ],
  [
    14292,
    64.84616,
    13.01951,
    200.4,
    23.23
  ],
  [
    14294,
    64.84645,
    13.02021,
    200.3,
    23.15
  ],
  [
    14296,
    64.84675,
    13.0209,
    200.2,
    23.18
  ],
  [
    14298,
    64.84704,
    13.0216,
    200.1,
    23.23
  ],
  [
    14300,
    64.84733,
    13.0223,
    200.1,
    23.33
  ],
  [
    14302,
    64.84762,
    13.02301,
    200.2,
    23.41
  ],
  [
    14304,
    64.84787,
    13.02379,
    200.5,
    23.41
  ],
  [
    14306,
    64.84809,
    13.02463,
    200.6,
    23.27
  ],
  [
    14308,
    64.84825,
    13.02553,
    200.8,
    23.17
  ],
  [
    14310,
    64.84839,
    13.02645,
    200.6,
    23.09
  ],
  [
    14312,
    64.84853,
    13.02737,
    200.5,
    23.04
  ],
  [
    14314,
    64.84867,
    13.02828,
    200.5,
    23.03
  ],
  [
    14316,
    64.84881,
    13.02919,
    200.4,
    22.99
  ],
  [
    14318,
    64.84895,
    13.0301,
    200.4,
    22.94
  ],
  [
    14320,
    64.84911,
    13.031,
    200.5,
    22.89
  ],
  [
    14322,
    64.84928,
    13.03188,
    200.6,
    22.89
  ],
  [
    14324,
    64.84944,
    13.03276,
    200.8,
    22.89
  ],
  [
    14349,
    64.85364,
    13.03827,
    206.2,
    20.89
  ],
  [
    14351,
    64.85394,
    13.03881,
    207.7,
    20.83
  ],
  [
    14353,
    64.85421,
    13.03947,
    207.1,
    20.85
  ],
  [
    14355,
    64.85445,
    13.04015,
    206.3,
    20.91
  ],
  [
    14357,
    64.85468,
    13.04086,
    205.4,
    20.97
  ],
  [
    14359,
    64.85491,
    13.04157,
    204.8,
    21.03
  ],
  [
    14361,
    64.85516,
    13.04225,
    204.7,
    21.13
  ],
  [
    14363,
    64.85542,
    13.0429,
    204.8,
    21.22
  ],
  [
    14365,
    64.8557,
    13.0435,
    204.7,
    21.32
  ],
  [
    14367,
    64.856,
    13.04408,
    204.3,
    21.4
  ],
  [
    14369,
    64.85631,
    13.04461,
    204.4,
    21.51
  ],
  [
    14371,
    64.85662,
    13.04512,
    204.2,
    21.54
  ],
  [
    14373,
    64.85695,
    13.0456,
    204.1,
    21.67
  ],
  [
    14375,
    64.85729,
    13.04605,
    204,
    21.78
  ],
  [
    14377,
    64.85763,
    13.0465,
    204,
    21.87
  ],
  [
    14379,
    64.85796,
    13.047,
    204.2,
    21.97
  ],
  [
    14381,
    64.85826,
    13.04761,
    204.2,
    22
  ],
  [
    14383,
    64.85852,
    13.04831,
    204.2,
    22.06
  ],
  [
    14385,
    64.85873,
    13.0491,
    203.9,
    22.04
  ],
  [
    14387,
    64.85889,
    13.04995,
    203.7,
    22.06
  ],
  [
    14389,
    64.85903,
    13.05082,
    203.7,
    22.03
  ],
  [
    14391,
    64.85917,
    13.05168,
    203.7,
    21.75
  ],
  [
    14393,
    64.85931,
    13.05253,
    203.7,
    21.75
  ],
  [
    14398,
    64.85992,
    13.05424,
    203.3,
    19.12
  ],
  [
    14400,
    64.86031,
    13.05465,
    205.2,
    21.93
  ],
  [
    14402,
    64.86072,
    13.05496,
    204.9,
    21.9
  ],
  [
    14404,
    64.86109,
    13.05536,
    204.9,
    21.98
  ],
  [
    14406,
    64.86145,
    13.05577,
    204.7,
    22.1
  ],
  [
    14408,
    64.86181,
    13.05618,
    204.4,
    22.25
  ],
  [
    14410,
    64.86218,
    13.05656,
    204.2,
    22.41
  ],
  [
    14412,
    64.86256,
    13.05693,
    203.9,
    22.55
  ],
  [
    14414,
    64.86294,
    13.05728,
    203.8,
    22.69
  ],
  [
    14416,
    64.86333,
    13.05759,
    203.7,
    22.83
  ],
  [
    14418,
    64.86372,
    13.05787,
    203.6,
    22.95
  ],
  [
    14420,
    64.86413,
    13.05812,
    203.5,
    23.12
  ],
  [
    14422,
    64.86453,
    13.05836,
    203.3,
    23.29
  ],
  [
    14424,
    64.86494,
    13.0586,
    203.1,
    23.53
  ],
  [
    14426,
    64.86535,
    13.05885,
    202.9,
    23.76
  ],
  [
    14428,
    64.86576,
    13.0591,
    202.8,
    24.02
  ],
  [
    14430,
    64.86618,
    13.05935,
    202.8,
    24.26
  ],
  [
    14432,
    64.86661,
    13.05961,
    202.8,
    24.5
  ],
  [
    14434,
    64.86704,
    13.05986,
    202.9,
    24.74
  ],
  [
    14436,
    64.86747,
    13.06012,
    202.9,
    24.98
  ],
  [
    14438,
    64.86791,
    13.06038,
    202.9,
    25.21
  ],
  [
    14440,
    64.86835,
    13.06065,
    202.9,
    25.43
  ],
  [
    14442,
    64.86879,
    13.06091,
    202.9,
    25.67
  ],
  [
    14444,
    64.86924,
    13.06118,
    203,
    25.9
  ],
  [
    14446,
    64.8697,
    13.06145,
    203.1,
    26.11
  ],
  [
    14448,
    64.87015,
    13.06173,
    203.2,
    26.33
  ],
  [
    14450,
    64.87061,
    13.06201,
    203.3,
    26.52
  ],
  [
    14452,
    64.87107,
    13.06228,
    203.3,
    26.74
  ],
  [
    14454,
    64.87154,
    13.06257,
    203.3,
    26.95
  ],
  [
    14456,
    64.87201,
    13.06285,
    203.3,
    27.19
  ],
  [
    14458,
    64.87249,
    13.06314,
    203.3,
    27.46
  ],
  [
    14460,
    64.87297,
    13.06343,
    202.9,
    27.74
  ],
  [
    14462,
    64.87345,
    13.06372,
    202.9,
    28.01
  ],
  [
    14464,
    64.87394,
    13.06402,
    203,
    28.25
  ],
  [
    14466,
    64.87443,
    13.06431,
    203,
    28.51
  ],
  [
    14468,
    64.87493,
    13.0646,
    203,
    28.75
  ],
  [
    14470,
    64.87544,
    13.06488,
    203.1,
    28.96
  ],
  [
    14472,
    64.87595,
    13.06515,
    203.3,
    29.17
  ],
  [
    14474,
    64.87646,
    13.0654,
    203.4,
    29.36
  ],
  [
    14477,
    64.87724,
    13.06576,
    203.8,
    29.5
  ],
  [
    14478,
    64.8775,
    13.06587,
    204,
    29.67
  ],
  [
    14481,
    64.87829,
    13.06619,
    204.2,
    29.84
  ],
  [
    14483,
    64.87882,
    13.0664,
    204.2,
    30.02
  ],
  [
    14485,
    64.87936,
    13.06661,
    204.1,
    30.02
  ],
  [
    14487,
    64.8799,
    13.06682,
    204.1,
    30.02
  ],
  [
    14489,
    64.88044,
    13.06705,
    204,
    30.56
  ],
  [
    14491,
    64.88098,
    13.0673,
    204,
    30.56
  ],
  [
    14493,
    64.88153,
    13.06756,
    204,
    31.05
  ],
  [
    14495,
    64.88208,
    13.06785,
    204,
    31.05
  ],
  [
    14497,
    64.88262,
    13.06815,
    204,
    31.05
  ],
  [
    14499,
    64.88316,
    13.06847,
    204,
    31.27
  ],
  [
    14501,
    64.88371,
    13.06879,
    204.1,
    31.27
  ],
  [
    14503,
    64.88425,
    13.06911,
    204.1,
    31.14
  ],
  [
    14505,
    64.88479,
    13.06943,
    204.2,
    31.14
  ],
  [
    14507,
    64.88533,
    13.06976,
    204.1,
    31.03
  ],
  [
    14509,
    64.88587,
    13.07008,
    204,
    30.99
  ],
  [
    14511,
    64.88641,
    13.0704,
    203.9,
    30.95
  ],
  [
    14513,
    64.88694,
    13.07072,
    203.9,
    30.91
  ],
  [
    14515,
    64.88748,
    13.07104,
    203.9,
    30.88
  ],
  [
    14517,
    64.88802,
    13.07136,
    203.8,
    30.86
  ],
  [
    14519,
    64.88855,
    13.0717,
    203.8,
    30.81
  ],
  [
    14521,
    64.88908,
    13.07211,
    203.9,
    30.79
  ],
  [
    14523,
    64.88959,
    13.07259,
    203.9,
    30.75
  ],
  [
    14525,
    64.89008,
    13.07316,
    204,
    30.71
  ],
  [
    14527,
    64.89056,
    13.07379,
    203.8,
    30.69
  ],
  [
    14529,
    64.89104,
    13.07445,
    203.6,
    30.63
  ],
  [
    14531,
    64.89151,
    13.0751,
    203.4,
    30.61
  ],
  [
    14533,
    64.89199,
    13.07575,
    203.1,
    30.56
  ],
  [
    14535,
    64.89246,
    13.0764,
    202.9,
    30.54
  ],
  [
    14537,
    64.89294,
    13.07704,
    202.5,
    30.54
  ],
  [
    14539,
    64.89342,
    13.07763,
    202.2,
    30.5
  ],
  [
    14541,
    64.89392,
    13.07816,
    202.1,
    30.45
  ],
  [
    14543,
    64.89443,
    13.07864,
    202.1,
    30.42
  ],
  [
    14545,
    64.89494,
    13.07906,
    202,
    30.39
  ],
  [
    14547,
    64.89547,
    13.07942,
    201.8,
    30.37
  ],
  [
    14549,
    64.89599,
    13.07972,
    201.6,
    30.31
  ],
  [
    14551,
    64.89653,
    13.07996,
    201.5,
    30.27
  ],
  [
    14553,
    64.89706,
    13.08015,
    201.4,
    30.22
  ],
  [
    14555,
    64.8976,
    13.08027,
    201.2,
    30.19
  ],
  [
    14557,
    64.89815,
    13.08033,
    201.1,
    30.18
  ],
  [
    14559,
    64.89869,
    13.08033,
    201.2,
    30.11
  ],
  [
    14561,
    64.89923,
    13.08027,
    201.3,
    30.04
  ],
  [
    14563,
    64.89977,
    13.08014,
    201.2,
    29.95
  ],
  [
    14565,
    64.9003,
    13.07997,
    201.3,
    29.85
  ],
  [
    14567,
    64.90083,
    13.07974,
    201.3,
    29.81
  ],
  [
    14569,
    64.90135,
    13.07945,
    201.4,
    29.68
  ],
  [
    14571,
    64.90186,
    13.07911,
    201.4,
    29.52
  ],
  [
    14573,
    64.90237,
    13.07874,
    201.4,
    29.29
  ],
  [
    14575,
    64.90287,
    13.07837,
    201.2,
    29.11
  ],
  [
    14577,
    64.90336,
    13.07801,
    201,
    28.98
  ],
  [
    14579,
    64.90386,
    13.07764,
    201,
    28.93
  ],
  [
    14581,
    64.90436,
    13.07728,
    201,
    28.89
  ],
  [
    14583,
    64.90485,
    13.07691,
    200.9,
    28.83
  ],
  [
    14585,
    64.90534,
    13.07655,
    200.9,
    28.78
  ],
  [
    14587,
    64.90584,
    13.07619,
    201,
    28.76
  ],
  [
    14589,
    64.90633,
    13.07583,
    201,
    28.73
  ],
  [
    14591,
    64.90682,
    13.07547,
    201.1,
    28.7
  ],
  [
    14593,
    64.90732,
    13.07513,
    201.2,
    28.66
  ],
  [
    14595,
    64.90782,
    13.07489,
    201.2,
    28.64
  ],
  [
    14597,
    64.90833,
    13.07478,
    201.4,
    28.6
  ],
  [
    14599,
    64.90884,
    13.07481,
    201.4,
    28.51
  ],
  [
    14601,
    64.90935,
    13.07498,
    201.5,
    28.43
  ],
  [
    14603,
    64.90984,
    13.07529,
    201.7,
    28.4
  ],
  [
    14605,
    64.91031,
    13.07573,
    201.6,
    28.36
  ],
  [
    14607,
    64.91076,
    13.0763,
    201.6,
    28.32
  ],
  [
    14609,
    64.91118,
    13.07698,
    201.6,
    28.29
  ],
  [
    14611,
    64.91156,
    13.07777,
    201.7,
    28.28
  ],
  [
    14613,
    64.91192,
    13.07863,
    201.7,
    28.27
  ],
  [
    14615,
    64.91226,
    13.07951,
    201.6,
    28.29
  ],
  [
    14617,
    64.91261,
    13.0804,
    201.5,
    28.33
  ],
  [
    14619,
    64.91295,
    13.08128,
    201.5,
    28.39
  ],
  [
    14621,
    64.9133,
    13.08216,
    201.5,
    28.43
  ],
  [
    14623,
    64.91365,
    13.08304,
    201.5,
    28.48
  ],
  [
    14625,
    64.914,
    13.08393,
    201.5,
    28.54
  ],
  [
    14627,
    64.91435,
    13.08481,
    201.4,
    28.6
  ],
  [
    14629,
    64.9147,
    13.0857,
    201.4,
    28.66
  ],
  [
    14631,
    64.91506,
    13.08659,
    201.3,
    28.73
  ],
  [
    14633,
    64.91541,
    13.08748,
    201.5,
    28.81
  ],
  [
    14635,
    64.91576,
    13.08837,
    201.7,
    28.83
  ],
  [
    14637,
    64.91612,
    13.08926,
    201.8,
    28.85
  ],
  [
    14639,
    64.91647,
    13.09015,
    202,
    28.86
  ],
  [
    14641,
    64.91683,
    13.09105,
    202.2,
    28.88
  ],
  [
    14643,
    64.91718,
    13.09194,
    202.4,
    28.9
  ],
  [
    14645,
    64.91754,
    13.09283,
    202.6,
    28.93
  ],
  [
    14647,
    64.91789,
    13.09372,
    202.8,
    28.96
  ],
  [
    14649,
    64.91825,
    13.09462,
    203,
    28.98
  ],
  [
    14651,
    64.91861,
    13.09551,
    203.2,
    28.98
  ],
  [
    14653,
    64.91896,
    13.0964,
    203.5,
    28.98
  ],
  [
    14655,
    64.91932,
    13.0973,
    203.8,
    28.98
  ],
  [
    14657,
    64.91968,
    13.09819,
    204,
    28.95
  ],
  [
    14659,
    64.92004,
    13.09908,
    204.1,
    28.97
  ],
  [
    14661,
    64.92039,
    13.09997,
    204.2,
    29.01
  ],
  [
    14663,
    64.92075,
    13.10086,
    204.3,
    28.96
  ],
  [
    14665,
    64.92111,
    13.10175,
    204.3,
    28.88
  ],
  [
    14667,
    64.92146,
    13.10264,
    204.4,
    28.78
  ],
  [
    14669,
    64.92182,
    13.10353,
    204.5,
    28.7
  ],
  [
    14671,
    64.92217,
    13.10441,
    204.5,
    28.6
  ],
  [
    14673,
    64.92252,
    13.10529,
    204.5,
    28.55
  ],
  [
    14675,
    64.92287,
    13.10617,
    204.3,
    28.48
  ],
  [
    14677,
    64.92322,
    13.10704,
    204.2,
    28.46
  ],
  [
    14679,
    64.92357,
    13.10792,
    204,
    28.46
  ],
  [
    14681,
    64.92392,
    13.1088,
    203.9,
    28.44
  ],
  [
    14683,
    64.92427,
    13.10967,
    204,
    28.4
  ],
  [
    14685,
    64.92462,
    13.11054,
    204,
    28.37
  ],
  [
    14687,
    64.92497,
    13.11141,
    204,
    28.3
  ],
  [
    14689,
    64.92532,
    13.11228,
    204,
    28.24
  ],
  [
    14691,
    64.92567,
    13.11315,
    204,
    28.01
  ],
  [
    14693,
    64.92601,
    13.114,
    204,
    27.41
  ],
  [
    14695,
    64.92634,
    13.11483,
    204,
    26.86
  ],
  [
    14697,
    64.92667,
    13.11565,
    204,
    26.19
  ],
  [
    14699,
    64.92699,
    13.11644,
    204,
    25.38
  ],
  [
    14701,
    64.92729,
    13.1172,
    204.3,
    24.41
  ],
  [
    14703,
    64.92759,
    13.11793,
    204.7,
    23.32
  ],
  [
    14705,
    64.92787,
    13.11864,
    205.2,
    22.54
  ],
  [
    14707,
    64.92814,
    13.11932,
    205.7,
    21.99
  ],
  [
    14709,
    64.92841,
    13.11998,
    206.2,
    21.59
  ],
  [
    14711,
    64.92869,
    13.12061,
    206.7,
    21.23
  ],
  [
    14713,
    64.92897,
    13.12121,
    207.2,
    21.08
  ],
  [
    14715,
    64.92925,
    13.12179,
    207.5,
    20.94
  ],
  [
    14718,
    64.92969,
    13.1226,
    208,
    20.86
  ],
  [
    14720,
    64.92999,
    13.12312,
    208.5,
    20.81
  ],
  [
    14722,
    64.9303,
    13.12363,
    208.9,
    20.8
  ],
  [
    14724,
    64.93059,
    13.12417,
    209.4,
    20.8
  ],
  [
    14726,
    64.93086,
    13.12477,
    209.9,
    20.8
  ],
  [
    14728,
    64.93111,
    13.12545,
    210.3,
    21.02
  ],
  [
    14730,
    64.93131,
    13.1262,
    210.7,
    21.02
  ],
  [
    14732,
    64.93148,
    13.12701,
    211.1,
    21.02
  ],
  [
    14734,
    64.9316,
    13.12787,
    211.5,
    21.42
  ],
  [
    14736,
    64.93168,
    13.12877,
    211.8,
    21.42
  ],
  [
    14738,
    64.93171,
    13.12969,
    212.2,
    21.93
  ],
  [
    14740,
    64.93169,
    13.13063,
    212.7,
    21.93
  ],
  [
    14742,
    64.93163,
    13.13156,
    213.6,
    21.93
  ],
  [
    14744,
    64.93151,
    13.13247,
    214,
    22.32
  ],
  [
    14746,
    64.93133,
    13.13334,
    213.8,
    22.32
  ],
  [
    14748,
    64.93111,
    13.13416,
    213.9,
    22.75
  ],
  [
    14750,
    64.93085,
    13.13491,
    213.9,
    22.92
  ],
  [
    14752,
    64.93054,
    13.13558,
    214.1,
    23.06
  ],
  [
    14754,
    64.93021,
    13.13619,
    214.8,
    23.21
  ],
  [
    14756,
    64.92988,
    13.1368,
    215.6,
    23.22
  ],
  [
    14758,
    64.92955,
    13.13739,
    215.6,
    23.13
  ],
  [
    14760,
    64.92921,
    13.13799,
    214.8,
    23.07
  ],
  [
    14762,
    64.92887,
    13.13859,
    212,
    23.09
  ],
  [
    14764,
    64.92853,
    13.13918,
    210.1,
    23.16
  ],
  [
    14766,
    64.9282,
    13.13978,
    209.8,
    23.04
  ],
  [
    14768,
    64.92786,
    13.14037,
    209.3,
    22.94
  ],
  [
    14770,
    64.92754,
    13.14096,
    208.6,
    22.4
  ],
  [
    14772,
    64.92724,
    13.14157,
    208.4,
    22.06
  ],
  [
    14774,
    64.92698,
    13.14224,
    208,
    21.01
  ],
  [
    14776,
    64.92676,
    13.14294,
    208.3,
    20.16
  ],
  [
    14778,
    64.92658,
    13.14366,
    208.5,
    19.17
  ],
  [
    14780,
    64.92643,
    13.14437,
    208.8,
    18.08
  ],
  [
    14782,
    64.92633,
    13.14507,
    209.4,
    17.57
  ],
  [
    14784,
    64.92625,
    13.14576,
    209.9,
    16.52
  ],
  [
    14786,
    64.92619,
    13.14643,
    210.3,
    16
  ],
  [
    14788,
    64.92614,
    13.14708,
    210.8,
    15.45
  ],
  [
    14790,
    64.9261,
    13.14771,
    211.6,
    14.73
  ],
  [
    14792,
    64.92605,
    13.1483,
    212,
    14.34
  ],
  [
    14794,
    64.926,
    13.14886,
    212.3,
    12.96
  ],
  [
    14796,
    64.92596,
    13.14937,
    212.7,
    11.96
  ],
  [
    14798,
    64.92593,
    13.14985,
    213.1,
    11.23
  ],
  [
    14800,
    64.92589,
    13.1503,
    213.3,
    10.67
  ],
  [
    14802,
    64.92586,
    13.15073,
    213.6,
    10.4
  ],
  [
    14804,
    64.92582,
    13.15114,
    213.8,
    9.59
  ],
  [
    14806,
    64.92579,
    13.15152,
    214,
    9.04
  ],
  [
    14808,
    64.92577,
    13.15188,
    214.2,
    8.47
  ],
  [
    14810,
    64.92574,
    13.15221,
    214.4,
    7.89
  ],
  [
    14812,
    64.92571,
    13.15252,
    214.6,
    7.05
  ],
  [
    14814,
    64.92569,
    13.15278,
    214.7,
    5.8
  ],
  [
    14816,
    64.92568,
    13.15299,
    214.9,
    4.48
  ],
  [
    14818,
    64.92566,
    13.15314,
    215,
    3.15
  ],
  [
    14820,
    64.92566,
    13.15325,
    215.2,
    2.2
  ],
  [
    14822,
    64.92565,
    13.15332,
    215.3,
    1.69
  ],
  [
    14824,
    64.92565,
    13.15338,
    215.4,
    1.29
  ],
  [
    14826,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14828,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14830,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14832,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14834,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14836,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14838,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14840,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14842,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14844,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14846,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14848,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14850,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14852,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14854,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14856,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14858,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14860,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14862,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14864,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14866,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14868,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14870,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14872,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14874,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14876,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14878,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14880,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14882,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14884,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14886,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14888,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14890,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14892,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14894,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14896,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14898,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14900,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14902,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14904,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14906,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14908,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14910,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14912,
    64.92564,
    13.15341,
    215.5,
    0
  ],
  [
    14914,
    64.92565,
    13.15343,
    219.1,
    2.34
  ],
  [
    14916,
    64.92564,
    13.15355,
    219.1,
    3.37
  ],
  [
    14918,
    64.92562,
    13.15371,
    219.2,
    4.23
  ],
  [
    14920,
    64.92561,
    13.1539,
    219.2,
    4.92
  ],
  [
    14922,
    64.92559,
    13.15412,
    219.2,
    5.5
  ],
  [
    14924,
    64.92557,
    13.15436,
    219.2,
    6.09
  ],
  [
    14926,
    64.92555,
    13.15463,
    219.2,
    6.71
  ],
  [
    14928,
    64.92553,
    13.15492,
    219.7,
    7.3
  ],
  [
    14930,
    64.92551,
    13.15524,
    219.8,
    7.88
  ],
  [
    14932,
    64.92548,
    13.15558,
    220.1,
    8.42
  ],
  [
    14934,
    64.92546,
    13.15594,
    220.2,
    8.94
  ],
  [
    14936,
    64.92545,
    13.15633,
    220.4,
    9.47
  ],
  [
    14938,
    64.92545,
    13.15675,
    220.6,
    10.11
  ],
  [
    14940,
    64.92546,
    13.15719,
    220.9,
    10.81
  ],
  [
    14943,
    64.9255,
    13.15791,
    221.2,
    11.5
  ],
  [
    14945,
    64.92554,
    13.15841,
    221.4,
    12.15
  ],
  [
    14947,
    64.92561,
    13.15893,
    221.4,
    12.81
  ],
  [
    14949,
    64.9257,
    13.15946,
    221.5,
    13.47
  ],
  [
    14951,
    64.92581,
    13.16,
    221.7,
    14.1
  ],
  [
    14953,
    64.92595,
    13.16053,
    221.8,
    14.67
  ],
  [
    14955,
    64.92612,
    13.16105,
    221.9,
    14.93
  ],
  [
    14957,
    64.92631,
    13.16154,
    222,
    14.93
  ],
  [
    14959,
    64.92652,
    13.16202,
    221.9,
    15.94
  ],
  [
    14961,
    64.92674,
    13.1625,
    221.9,
    15.94
  ],
  [
    14963,
    64.92697,
    13.16299,
    221.8,
    17.23
  ],
  [
    14965,
    64.92721,
    13.16349,
    221.8,
    17.23
  ],
  [
    14967,
    64.92745,
    13.16401,
    221.7,
    17.23
  ],
  [
    14969,
    64.92771,
    13.16454,
    221.7,
    18.49
  ],
  [
    14971,
    64.92796,
    13.16509,
    221.7,
    18.49
  ],
  [
    14973,
    64.92823,
    13.16565,
    221.7,
    19.64
  ],
  [
    14975,
    64.9285,
    13.16622,
    221.8,
    20.06
  ],
  [
    14977,
    64.92877,
    13.1668,
    221.8,
    20.45
  ],
  [
    14979,
    64.92905,
    13.16739,
    221.9,
    20.83
  ],
  [
    14981,
    64.92933,
    13.16799,
    222,
    21.17
  ],
  [
    14983,
    64.92962,
    13.1686,
    222.1,
    21.52
  ],
  [
    14985,
    64.92991,
    13.16922,
    222.1,
    21.85
  ],
  [
    14987,
    64.93021,
    13.16985,
    222.1,
    22.19
  ],
  [
    14989,
    64.93051,
    13.17049,
    222.1,
    22.53
  ],
  [
    14991,
    64.93082,
    13.17114,
    222,
    23.05
  ],
  [
    14993,
    64.93113,
    13.17179,
    222.1,
    23.38
  ],
  [
    14995,
    64.93147,
    13.17241,
    222,
    23.68
  ],
  [
    14997,
    64.93183,
    13.17296,
    221.9,
    23.84
  ],
  [
    14999,
    64.93221,
    13.17342,
    221.8,
    24.3
  ],
  [
    15001,
    64.93262,
    13.1738,
    221.7,
    24.62
  ],
  [
    15003,
    64.93305,
    13.17409,
    221.5,
    24.93
  ],
  [
    15005,
    64.9335,
    13.17429,
    221.4,
    25.27
  ],
  [
    15007,
    64.93395,
    13.17447,
    221.3,
    25.44
  ],
  [
    15009,
    64.93441,
    13.17465,
    221.2,
    25.91
  ],
  [
    15011,
    64.93487,
    13.17483,
    221.1,
    26.2
  ],
  [
    15013,
    64.93534,
    13.17502,
    221.1,
    26.49
  ],
  [
    15015,
    64.93581,
    13.1752,
    221.2,
    26.76
  ],
  [
    15017,
    64.93629,
    13.17539,
    221.3,
    26.89
  ],
  [
    15019,
    64.93677,
    13.17557,
    221.2,
    27.23
  ],
  [
    15021,
    64.93726,
    13.17576,
    221.3,
    27.48
  ],
  [
    15023,
    64.93775,
    13.17597,
    221.3,
    27.73
  ],
  [
    15025,
    64.93823,
    13.17623,
    221.1,
    27.99
  ],
  [
    15027,
    64.93872,
    13.17659,
    221,
    28.12
  ],
  [
    15029,
    64.93919,
    13.17705,
    221,
    28.46
  ],
  [
    15031,
    64.93964,
    13.17761,
    220.9,
    28.69
  ],
  [
    15033,
    64.94008,
    13.17827,
    221,
    28.87
  ],
  [
    15035,
    64.94051,
    13.17897,
    221,
    29.06
  ],
  [
    15037,
    64.94093,
    13.17969,
    221,
    29.1
  ],
  [
    15039,
    64.94137,
    13.18038,
    221.2,
    29.07
  ],
  [
    15041,
    64.94181,
    13.18104,
    221.4,
    28.99
  ],
  [
    15043,
    64.94225,
    13.18166,
    221.7,
    28.88
  ],
  [
    15045,
    64.94271,
    13.18224,
    221.9,
    28.75
  ],
  [
    15047,
    64.94317,
    13.18278,
    222.1,
    28.69
  ],
  [
    15049,
    64.94364,
    13.18328,
    222.2,
    28.56
  ],
  [
    15051,
    64.94411,
    13.18374,
    222.1,
    28.51
  ],
  [
    15053,
    64.94459,
    13.18417,
    222.1,
    28.5
  ],
  [
    15055,
    64.94508,
    13.18456,
    222,
    28.55
  ],
  [
    15057,
    64.94557,
    13.18492,
    221.9,
    28.61
  ],
  [
    15059,
    64.94606,
    13.18525,
    221.8,
    28.69
  ],
  [
    15061,
    64.94656,
    13.18561,
    221.8,
    28.79
  ],
  [
    15063,
    64.94704,
    13.18605,
    221.7,
    28.88
  ],
  [
    15065,
    64.9475,
    13.1866,
    221.6,
    28.96
  ],
  [
    15067,
    64.94794,
    13.18726,
    221.6,
    29.01
  ],
  [
    15069,
    64.94835,
    13.18802,
    221.6,
    29.09
  ],
  [
    15071,
    64.94873,
    13.18886,
    221.5,
    29.12
  ],
  [
    15073,
    64.9491,
    13.18974,
    221.4,
    29.19
  ],
  [
    15075,
    64.94949,
    13.19057,
    221.3,
    29.29
  ],
  [
    15077,
    64.94991,
    13.19131,
    221.3,
    29.35
  ],
  [
    15079,
    64.95037,
    13.19195,
    221.4,
    29.34
  ],
  [
    15081,
    64.95083,
    13.19252,
    221.4,
    29.32
  ],
  [
    15083,
    64.9513,
    13.19311,
    221.4,
    29.29
  ],
  [
    15085,
    64.95174,
    13.19375,
    221.5,
    29.25
  ],
  [
    15087,
    64.95217,
    13.19448,
    221.4,
    29.22
  ],
  [
    15089,
    64.95257,
    13.19526,
    221.3,
    29.24
  ],
  [
    15091,
    64.95296,
    13.1961,
    221.2,
    29.26
  ],
  [
    15093,
    64.95333,
    13.19698,
    220.9,
    29.33
  ],
  [
    15095,
    64.95369,
    13.1979,
    220.7,
    29.43
  ],
  [
    15097,
    64.95403,
    13.19885,
    220.5,
    29.54
  ],
  [
    15099,
    64.95436,
    13.19983,
    220.2,
    29.66
  ],
  [
    15101,
    64.95469,
    13.20083,
    219.8,
    29.8
  ],
  [
    15103,
    64.95502,
    13.20183,
    219.5,
    29.98
  ],
  [
    15105,
    64.95536,
    13.20282,
    219.2,
    30.21
  ],
  [
    15107,
    64.9557,
    13.20381,
    218.9,
    30.45
  ],
  [
    15109,
    64.95607,
    13.20479,
    218.6,
    30.71
  ],
  [
    15111,
    64.95644,
    13.20575,
    218.3,
    30.98
  ],
  [
    15113,
    64.95682,
    13.20671,
    218,
    31.3
  ],
  [
    15115,
    64.95721,
    13.20767,
    217.8,
    31.58
  ],
  [
    15117,
    64.95761,
    13.20863,
    217.6,
    31.86
  ],
  [
    15119,
    64.95801,
    13.20961,
    217.5,
    32.12
  ],
  [
    15121,
    64.95841,
    13.21059,
    217.5,
    32.34
  ],
  [
    15123,
    64.95882,
    13.21157,
    217.5,
    32.55
  ],
  [
    15125,
    64.95923,
    13.21257,
    217.5,
    32.75
  ],
  [
    15127,
    64.95964,
    13.21357,
    217.5,
    32.93
  ],
  [
    15129,
    64.96005,
    13.21457,
    217.5,
    33.11
  ],
  [
    15131,
    64.96047,
    13.21558,
    217.4,
    33.32
  ],
  [
    15133,
    64.96088,
    13.2166,
    217.4,
    33.5
  ],
  [
    15135,
    64.9613,
    13.21762,
    217.3,
    33.7
  ],
  [
    15137,
    64.96172,
    13.21865,
    217.1,
    33.85
  ],
  [
    15139,
    64.96215,
    13.21968,
    217.1,
    33.95
  ],
  [
    15141,
    64.96257,
    13.22071,
    217,
    34.01
  ],
  [
    15143,
    64.963,
    13.22175,
    217,
    34.04
  ],
  [
    15145,
    64.96342,
    13.22278,
    217,
    34.05
  ],
  [
    15147,
    64.96385,
    13.22382,
    217.2,
    34.05
  ],
  [
    15149,
    64.96428,
    13.22485,
    217.3,
    34.05
  ],
  [
    15151,
    64.9647,
    13.22589,
    217.5,
    34.06
  ],
  [
    15153,
    64.96514,
    13.2269,
    217.5,
    33.98
  ],
  [
    15155,
    64.96558,
    13.22788,
    217.6,
    33.98
  ],
  [
    15157,
    64.96605,
    13.22882,
    217.7,
    34.05
  ],
  [
    15159,
    64.96652,
    13.22972,
    217.6,
    33.98
  ],
  [
    15161,
    64.96701,
    13.23059,
    217.7,
    34
  ],
  [
    15163,
    64.96751,
    13.23141,
    217.7,
    34.04
  ],
  [
    15165,
    64.96802,
    13.23219,
    217.6,
    34.06
  ],
  [
    15167,
    64.96855,
    13.23293,
    217.6,
    34.1
  ],
  [
    15169,
    64.96908,
    13.23364,
    217.5,
    34.14
  ],
  [
    15171,
    64.96963,
    13.23429,
    217.5,
    34.17
  ],
  [
    15173,
    64.97018,
    13.23491,
    217.5,
    34.21
  ],
  [
    15175,
    64.97074,
    13.23551,
    217.5,
    34.24
  ],
  [
    15177,
    64.9713,
    13.23611,
    217.5,
    34.28
  ],
  [
    15179,
    64.97186,
    13.23671,
    217.5,
    34.28
  ],
  [
    15181,
    64.97242,
    13.23731,
    217.7,
    34.27
  ],
  [
    15183,
    64.97298,
    13.23791,
    217.9,
    34.24
  ],
  [
    15185,
    64.97354,
    13.23851,
    218.2,
    34.25
  ],
  [
    15187,
    64.9741,
    13.23911,
    218.4,
    34.24
  ],
  [
    15189,
    64.97466,
    13.23971,
    218.6,
    34.24
  ],
  [
    15191,
    64.97522,
    13.24031,
    218.7,
    34.23
  ],
  [
    15193,
    64.97578,
    13.24091,
    218.7,
    34.24
  ],
  [
    15195,
    64.97634,
    13.24151,
    218.6,
    34.24
  ],
  [
    15197,
    64.9769,
    13.24212,
    218.7,
    34.25
  ],
  [
    15200,
    64.97774,
    13.24301,
    218.7,
    34.25
  ],
  [
    15202,
    64.9783,
    13.24361,
    218.8,
    34.26
  ],
  [
    15204,
    64.97886,
    13.24421,
    218.8,
    34.27
  ],
  [
    15206,
    64.97942,
    13.24481,
    218.9,
    34.27
  ],
  [
    15208,
    64.97998,
    13.24541,
    219.1,
    34.25
  ],
  [
    15210,
    64.98054,
    13.24601,
    219.1,
    34.25
  ],
  [
    15212,
    64.9811,
    13.24661,
    219.2,
    34.25
  ],
  [
    15214,
    64.98166,
    13.24721,
    219.7,
    34.22
  ],
  [
    15216,
    64.98222,
    13.24781,
    219.9,
    34.22
  ],
  [
    15218,
    64.98277,
    13.24841,
    220.2,
    34.11
  ],
  [
    15220,
    64.98333,
    13.24901,
    220.5,
    34.11
  ],
  [
    15222,
    64.98389,
    13.2496,
    220.6,
    34.11
  ],
  [
    15224,
    64.98444,
    13.2502,
    220.7,
    33.92
  ],
  [
    15226,
    64.98499,
    13.25079,
    220.7,
    33.9
  ],
  [
    15228,
    64.98555,
    13.25139,
    220.7,
    33.9
  ],
  [
    15230,
    64.9861,
    13.25198,
    220.8,
    33.89
  ],
  [
    15232,
    64.98665,
    13.25258,
    220.8,
    33.89
  ],
  [
    15234,
    64.98721,
    13.25318,
    220.9,
    33.89
  ],
  [
    15236,
    64.98776,
    13.25377,
    220.9,
    33.88
  ],
  [
    15238,
    64.98831,
    13.25437,
    221,
    33.89
  ],
  [
    15240,
    64.98886,
    13.25496,
    221,
    33.88
  ],
  [
    15242,
    64.98942,
    13.25556,
    221.2,
    33.88
  ],
  [
    15244,
    64.98997,
    13.25616,
    221.4,
    33.85
  ],
  [
    15246,
    64.99052,
    13.25675,
    221.8,
    33.8
  ],
  [
    15248,
    64.99107,
    13.25735,
    222.1,
    33.74
  ],
  [
    15250,
    64.99162,
    13.25794,
    222.4,
    33.69
  ],
  [
    15252,
    64.99217,
    13.25853,
    222.8,
    33.67
  ],
  [
    15254,
    64.99272,
    13.25912,
    223.1,
    33.56
  ],
  [
    15256,
    64.99327,
    13.2597,
    223.5,
    33.49
  ],
  [
    15258,
    64.99382,
    13.26024,
    223.8,
    33.43
  ],
  [
    15260,
    64.99438,
    13.26073,
    223.9,
    33.37
  ],
  [
    15262,
    64.99495,
    13.26118,
    223.8,
    33.37
  ],
  [
    15264,
    64.99553,
    13.26158,
    223.7,
    33.41
  ],
  [
    15266,
    64.99611,
    13.26194,
    223.7,
    33.44
  ],
  [
    15268,
    64.99669,
    13.26225,
    223.7,
    33.47
  ],
  [
    15270,
    64.99728,
    13.26251,
    223.5,
    33.51
  ],
  [
    15272,
    64.99788,
    13.26273,
    223.2,
    33.54
  ],
  [
    15274,
    64.99847,
    13.26291,
    222.9,
    33.67
  ],
  [
    15276,
    64.99907,
    13.26308,
    222.7,
    33.77
  ],
  [
    15278,
    64.99968,
    13.26326,
    222.6,
    33.86
  ],
  [
    15280,
    65.00028,
    13.26344,
    222.5,
    33.92
  ],
  [
    15282,
    65.00088,
    13.26361,
    222.5,
    33.95
  ],
  [
    15284,
    65.00149,
    13.26379,
    222.4,
    34.03
  ],
  [
    15286,
    65.00209,
    13.26397,
    222.2,
    34.03
  ],
  [
    15288,
    65.0027,
    13.26414,
    222.2,
    33.96
  ],
  [
    15290,
    65.0033,
    13.26432,
    222.2,
    33.88
  ],
  [
    15292,
    65.0039,
    13.26449,
    222.3,
    33.83
  ],
  [
    15294,
    65.00451,
    13.26463,
    222.4,
    33.65
  ],
  [
    15296,
    65.00511,
    13.2647,
    222.8,
    33.07
  ],
  [
    15298,
    65.00569,
    13.26468,
    223.2,
    32.41
  ],
  [
    15300,
    65.00627,
    13.26459,
    223.6,
    31.77
  ],
  [
    15302,
    65.00683,
    13.26444,
    223.9,
    31.42
  ],
  [
    15304,
    65.00737,
    13.26421,
    223.9,
    30.17
  ],
  [
    15306,
    65.00789,
    13.26394,
    223.9,
    29.21
  ],
  [
    15308,
    65.00839,
    13.26366,
    223.8,
    28.18
  ],
  [
    15310,
    65.00887,
    13.26338,
    223.8,
    27.16
  ],
  [
    15312,
    65.00933,
    13.26311,
    223.9,
    26.16
  ],
  [
    15314,
    65.00978,
    13.26285,
    224,
    25.39
  ],
  [
    15316,
    65.01021,
    13.2626,
    224.2,
    24.74
  ],
  [
    15318,
    65.01064,
    13.26235,
    224.5,
    24.11
  ],
  [
    15320,
    65.01105,
    13.26211,
    224.7,
    23.48
  ],
  [
    15322,
    65.01146,
    13.26188,
    224.9,
    23.06
  ],
  [
    15324,
    65.01185,
    13.26164,
    225.1,
    22.81
  ],
  [
    15326,
    65.01224,
    13.26138,
    225.2,
    22.65
  ],
  [
    15328,
    65.01263,
    13.26107,
    225.3,
    22.49
  ],
  [
    15330,
    65.013,
    13.26072,
    225.5,
    22.37
  ],
  [
    15332,
    65.01336,
    13.26033,
    225.5,
    22.33
  ],
  [
    15334,
    65.01372,
    13.25991,
    225.5,
    22.35
  ],
  [
    15336,
    65.01408,
    13.2595,
    225.4,
    22.44
  ],
  [
    15338,
    65.01446,
    13.25917,
    225.7,
    22.53
  ],
  [
    15340,
    65.01485,
    13.25897,
    225.8,
    22.6
  ],
  [
    15342,
    65.01526,
    13.25892,
    225.8,
    22.66
  ],
  [
    15344,
    65.01566,
    13.25902,
    225.8,
    22.73
  ],
  [
    15346,
    65.01606,
    13.25924,
    225.7,
    22.79
  ],
  [
    15348,
    65.01645,
    13.2595,
    225.8,
    22.85
  ],
  [
    15350,
    65.01684,
    13.25977,
    225.8,
    22.88
  ],
  [
    15352,
    65.01724,
    13.26003,
    225.8,
    22.91
  ],
  [
    15354,
    65.01763,
    13.2603,
    225.7,
    22.93
  ],
  [
    15356,
    65.01803,
    13.26057,
    225.6,
    22.94
  ],
  [
    15358,
    65.01842,
    13.26084,
    225.5,
    22.96
  ],
  [
    15360,
    65.01882,
    13.2611,
    225.5,
    22.97
  ],
  [
    15362,
    65.01921,
    13.26137,
    225.4,
    22.99
  ],
  [
    15364,
    65.01961,
    13.26164,
    225.3,
    23
  ],
  [
    15366,
    65.02,
    13.26191,
    225.3,
    23.01
  ],
  [
    15368,
    65.0204,
    13.26219,
    225.6,
    23.03
  ],
  [
    15370,
    65.02079,
    13.26251,
    225.9,
    23.02
  ],
  [
    15372,
    65.02116,
    13.2629,
    226.4,
    22.95
  ],
  [
    15374,
    65.02153,
    13.26334,
    226.9,
    22.84
  ],
  [
    15376,
    65.02188,
    13.26383,
    227.4,
    22.72
  ],
  [
    15378,
    65.02222,
    13.26434,
    227.9,
    22.61
  ],
  [
    15380,
    65.02257,
    13.26484,
    228.4,
    22.45
  ],
  [
    15382,
    65.02293,
    13.26526,
    228.8,
    22.33
  ],
  [
    15384,
    65.02331,
    13.26557,
    229.2,
    22.25
  ],
  [
    15386,
    65.02369,
    13.26578,
    229.7,
    22.16
  ],
  [
    15388,
    65.02409,
    13.26591,
    230.1,
    22.11
  ],
  [
    15390,
    65.02448,
    13.26604,
    230.5,
    22.07
  ],
  [
    15392,
    65.02486,
    13.26626,
    230.9,
    22.05
  ],
  [
    15394,
    65.02523,
    13.26661,
    231.3,
    22
  ],
  [
    15396,
    65.02557,
    13.26708,
    231.7,
    21.96
  ],
  [
    15398,
    65.02588,
    13.26765,
    232.2,
    21.96
  ],
  [
    15400,
    65.02617,
    13.26827,
    232.8,
    21.99
  ],
  [
    15402,
    65.02646,
    13.26891,
    233.2,
    22.03
  ],
  [
    15404,
    65.02675,
    13.26956,
    233.9,
    22.12
  ],
  [
    15406,
    65.02703,
    13.27022,
    234.4,
    22.19
  ],
  [
    15408,
    65.02732,
    13.27088,
    234.9,
    22.27
  ],
  [
    15410,
    65.0276,
    13.27156,
    234.9,
    22.41
  ],
  [
    15412,
    65.02787,
    13.27226,
    234.9,
    22.52
  ],
  [
    15414,
    65.02814,
    13.27298,
    234.9,
    22.57
  ],
  [
    15416,
    65.02839,
    13.27373,
    234.9,
    22.58
  ],
  [
    15418,
    65.02863,
    13.2745,
    237.2,
    22.57
  ],
  [
    15420,
    65.02888,
    13.27526,
    237.6,
    22.54
  ],
  [
    15422,
    65.02915,
    13.27597,
    238.3,
    22.55
  ],
  [
    15424,
    65.02945,
    13.2766,
    238.7,
    22.51
  ],
  [
    15426,
    65.02978,
    13.27713,
    239.2,
    22.49
  ],
  [
    15428,
    65.03014,
    13.27758,
    239.5,
    22.48
  ],
  [
    15430,
    65.03051,
    13.27796,
    239.9,
    22.53
  ],
  [
    15432,
    65.03088,
    13.27836,
    240.4,
    22.55
  ],
  [
    15434,
    65.03122,
    13.27885,
    240.8,
    22.55
  ],
  [
    15437,
    65.03168,
    13.2798,
    241.5,
    22.58
  ],
  [
    15439,
    65.03192,
    13.28056,
    241.6,
    22.63
  ],
  [
    15441,
    65.03213,
    13.28139,
    242.1,
    22.68
  ],
  [
    15443,
    65.03232,
    13.28224,
    242.6,
    22.71
  ],
  [
    15445,
    65.03254,
    13.28307,
    243,
    22.71
  ],
  [
    15447,
    65.03279,
    13.28383,
    243.5,
    22.71
  ],
  [
    15449,
    65.03308,
    13.28451,
    243.8,
    22.88
  ],
  [
    15451,
    65.03342,
    13.28508,
    244.2,
    22.88
  ],
  [
    15453,
    65.03378,
    13.28555,
    244.5,
    23.08
  ],
  [
    15455,
    65.03416,
    13.28594,
    244.8,
    23.08
  ],
  [
    15457,
    65.03455,
    13.28632,
    245.2,
    23.08
  ],
  [
    15459,
    65.03494,
    13.28672,
    245.7,
    23.59
  ],
  [
    15461,
    65.03531,
    13.28719,
    246.2,
    23.76
  ],
  [
    15463,
    65.03568,
    13.28771,
    246.7,
    23.88
  ],
  [
    15465,
    65.03603,
    13.2883,
    247.3,
    24.02
  ],
  [
    15467,
    65.03636,
    13.28898,
    247.8,
    24.18
  ],
  [
    15469,
    65.03664,
    13.28975,
    247.9,
    24.3
  ],
  [
    15471,
    65.0369,
    13.29059,
    248.3,
    24.45
  ],
  [
    15473,
    65.03715,
    13.29146,
    248.6,
    24.59
  ],
  [
    15475,
    65.0374,
    13.29233,
    249,
    24.78
  ],
  [
    15477,
    65.03765,
    13.29321,
    249.3,
    24.98
  ],
  [
    15479,
    65.03791,
    13.2941,
    249.8,
    25.17
  ],
  [
    15481,
    65.03816,
    13.29499,
    250.1,
    25.5
  ],
  [
    15483,
    65.03842,
    13.29588,
    250.5,
    25.63
  ],
  [
    15485,
    65.0387,
    13.29676,
    250.9,
    25.84
  ],
  [
    15487,
    65.03899,
    13.29762,
    251.5,
    25.89
  ],
  [
    15489,
    65.03929,
    13.29846,
    251.8,
    26.14
  ],
  [
    15491,
    65.03961,
    13.29928,
    251.9,
    26.3
  ],
  [
    15493,
    65.03994,
    13.30008,
    252.1,
    26.5
  ],
  [
    15495,
    65.04028,
    13.30086,
    252.3,
    26.69
  ],
  [
    15497,
    65.04064,
    13.30163,
    252.6,
    26.8
  ],
  [
    15499,
    65.041,
    13.30239,
    252.9,
    27.05
  ],
  [
    15501,
    65.04137,
    13.30315,
    253.1,
    27.22
  ],
  [
    15503,
    65.04173,
    13.30393,
    253.5,
    27.37
  ],
  [
    15505,
    65.04209,
    13.30473,
    254,
    27.53
  ],
  [
    15507,
    65.04244,
    13.30556,
    254.3,
    27.62
  ],
  [
    15509,
    65.04278,
    13.30642,
    254.7,
    27.81
  ],
  [
    15511,
    65.04311,
    13.3073,
    255,
    27.96
  ],
  [
    15513,
    65.04344,
    13.30821,
    255.2,
    28.11
  ],
  [
    15515,
    65.04376,
    13.30914,
    255.3,
    28.2
  ],
  [
    15517,
    65.04406,
    13.3101,
    255.4,
    28.21
  ],
  [
    15519,
    65.04436,
    13.31106,
    255.3,
    28.09
  ],
  [
    15521,
    65.04466,
    13.31202,
    255.4,
    28.05
  ],
  [
    15523,
    65.04496,
    13.31298,
    255.5,
    27.97
  ],
  [
    15525,
    65.04526,
    13.31393,
    255.5,
    27.91
  ],
  [
    15527,
    65.04557,
    13.31486,
    255.7,
    27.89
  ],
  [
    15529,
    65.04588,
    13.31578,
    255.8,
    27.77
  ],
  [
    15531,
    65.0462,
    13.31668,
    255.8,
    27.7
  ],
  [
    15533,
    65.04653,
    13.31756,
    255.7,
    27.62
  ],
  [
    15535,
    65.04686,
    13.31843,
    255.5,
    27.57
  ],
  [
    15537,
    65.0472,
    13.31928,
    255.5,
    27.54
  ],
  [
    15539,
    65.04755,
    13.32011,
    255.4,
    27.46
  ],
  [
    15541,
    65.04791,
    13.3209,
    255.1,
    27.41
  ],
  [
    15543,
    65.04829,
    13.32164,
    254.8,
    27.39
  ],
  [
    15545,
    65.04869,
    13.32231,
    254.4,
    27.44
  ],
  [
    15547,
    65.04911,
    13.32294,
    254,
    27.5
  ],
  [
    15549,
    65.04954,
    13.3235,
    253.7,
    27.66
  ],
  [
    15551,
    65.04999,
    13.32399,
    253.4,
    27.77
  ],
  [
    15553,
    65.05046,
    13.32443,
    253.4,
    27.86
  ],
  [
    15555,
    65.05093,
    13.32479,
    253.3,
    27.93
  ],
  [
    15557,
    65.05142,
    13.32511,
    253.2,
    27.98
  ],
  [
    15559,
    65.0519,
    13.32542,
    253.2,
    28
  ],
  [
    15561,
    65.05239,
    13.32573,
    253.4,
    28
  ],
  [
    15563,
    65.05287,
    13.32604,
    253.8,
    27.97
  ],
  [
    15565,
    65.05336,
    13.32634,
    254.3,
    27.87
  ],
  [
    15567,
    65.05384,
    13.32665,
    254.9,
    27.71
  ],
  [
    15569,
    65.05432,
    13.32698,
    255.5,
    27.55
  ],
  [
    15571,
    65.05479,
    13.32733,
    256,
    27.36
  ],
  [
    15573,
    65.05525,
    13.32771,
    256.6,
    27.18
  ],
  [
    15575,
    65.0557,
    13.32811,
    257.1,
    26.94
  ],
  [
    15577,
    65.05615,
    13.32854,
    257.7,
    26.81
  ],
  [
    15579,
    65.05659,
    13.32897,
    258.4,
    26.59
  ],
  [
    15581,
    65.05704,
    13.32936,
    259,
    26.39
  ],
  [
    15583,
    65.0575,
    13.32962,
    259.2,
    26.17
  ],
  [
    15585,
    65.05797,
    13.32975,
    259.6,
    26
  ],
  [
    15587,
    65.05843,
    13.32979,
    259.9,
    25.93
  ],
  [
    15589,
    65.0589,
    13.32981,
    260.3,
    25.75
  ],
  [
    15591,
    65.05936,
    13.32983,
    260.6,
    25.81
  ],
  [
    15593,
    65.05982,
    13.32985,
    260.8,
    25.8
  ],
  [
    15595,
    65.06028,
    13.32987,
    261,
    25.75
  ],
  [
    15597,
    65.06075,
    13.32989,
    261.2,
    25.71
  ],
  [
    15599,
    65.06121,
    13.3299,
    261.4,
    25.62
  ],
  [
    15601,
    65.06167,
    13.32992,
    261.4,
    25.5
  ],
  [
    15603,
    65.06212,
    13.32994,
    261.4,
    25.39
  ],
  [
    15605,
    65.06258,
    13.32996,
    261.3,
    25.32
  ],
  [
    15607,
    65.06303,
    13.32998,
    261.3,
    25.26
  ],
  [
    15609,
    65.06348,
    13.33,
    261.2,
    25.21
  ],
  [
    15611,
    65.06393,
    13.33002,
    261.2,
    25.06
  ],
  [
    15613,
    65.06438,
    13.33004,
    261.1,
    24.44
  ],
  [
    15615,
    65.06481,
    13.33005,
    261,
    23.97
  ],
  [
    15617,
    65.06524,
    13.33007,
    260.9,
    23.58
  ],
  [
    15619,
    65.06566,
    13.33009,
    260.9,
    23.17
  ],
  [
    15621,
    65.06607,
    13.33011,
    260.9,
    22.72
  ],
  [
    15623,
    65.06647,
    13.33013,
    260.9,
    22.25
  ],
  [
    15625,
    65.06687,
    13.33015,
    260.9,
    21.96
  ],
  [
    15627,
    65.06726,
    13.33017,
    261,
    21.75
  ],
  [
    15629,
    65.06765,
    13.33019,
    261.1,
    21.59
  ],
  [
    15631,
    65.06803,
    13.33018,
    261.6,
    21.46
  ],
  [
    15633,
    65.06841,
    13.3301,
    261.8,
    21.34
  ],
  [
    15635,
    65.06879,
    13.32989,
    262.1,
    21.25
  ],
  [
    15637,
    65.06914,
    13.32956,
    262.5,
    21.2
  ],
  [
    15639,
    65.06947,
    13.32911,
    262.9,
    21.17
  ],
  [
    15642,
    65.06991,
    13.32824,
    263.6,
    21.17
  ],
  [
    15644,
    65.07016,
    13.32754,
    264.1,
    21.23
  ],
  [
    15646,
    65.07036,
    13.32676,
    264.7,
    21.33
  ],
  [
    15648,
    65.07051,
    13.32592,
    265.3,
    21.46
  ],
  [
    15650,
    65.07061,
    13.32502,
    265.9,
    21.46
  ],
  [
    15652,
    65.07066,
    13.32409,
    266.6,
    21.46
  ],
  [
    15654,
    65.07065,
    13.32315,
    267.2,
    21.82
  ],
  [
    15656,
    65.0706,
    13.32221,
    267.7,
    21.82
  ],
  [
    15658,
    65.07053,
    13.32126,
    268.7,
    22.29
  ],
  [
    15660,
    65.07048,
    13.3203,
    269.8,
    22.29
  ],
  [
    15662,
    65.07048,
    13.31933,
    270.1,
    22.29
  ],
  [
    15664,
    65.07055,
    13.31837,
    270.2,
    22.7
  ],
  [
    15666,
    65.07068,
    13.31744,
    270.2,
    22.89
  ],
  [
    15668,
    65.07087,
    13.31657,
    270.4,
    23.03
  ],
  [
    15670,
    65.07111,
    13.31577,
    270.7,
    23.22
  ],
  [
    15672,
    65.07141,
    13.31507,
    271,
    23.33
  ],
  [
    15674,
    65.07175,
    13.31448,
    271.2,
    23.48
  ],
  [
    15676,
    65.07213,
    13.31403,
    271.4,
    23.59
  ],
  [
    15678,
    65.07253,
    13.31372,
    271.6,
    23.81
  ],
  [
    15680,
    65.07296,
    13.31357,
    271.7,
    23.86
  ],
  [
    15682,
    65.07338,
    13.31358,
    271.9,
    23.83
  ],
  [
    15684,
    65.0738,
    13.31376,
    272.3,
    23.72
  ],
  [
    15686,
    65.07421,
    13.31407,
    272.8,
    23.63
  ],
  [
    15688,
    65.0746,
    13.31446,
    273.2,
    23.5
  ],
  [
    15690,
    65.07499,
    13.31485,
    273.6,
    23.42
  ],
  [
    15692,
    65.07538,
    13.31525,
    274,
    23.38
  ],
  [
    15694,
    65.07576,
    13.31564,
    274.4,
    23.28
  ],
  [
    15696,
    65.07615,
    13.31603,
    274.8,
    23.2
  ],
  [
    15698,
    65.07653,
    13.31642,
    275.5,
    23.13
  ],
  [
    15700,
    65.07691,
    13.31681,
    276.2,
    23.12
  ],
  [
    15702,
    65.0773,
    13.31719,
    276.8,
    23.12
  ],
  [
    15704,
    65.07768,
    13.31758,
    277.3,
    23.18
  ],
  [
    15706,
    65.07806,
    13.31797,
    277.8,
    23.24
  ],
  [
    15708,
    65.07845,
    13.31836,
    278.4,
    23.27
  ],
  [
    15710,
    65.07884,
    13.31875,
    278.9,
    23.3
  ],
  [
    15712,
    65.07922,
    13.31914,
    279.3,
    23.34
  ],
  [
    15714,
    65.07961,
    13.31953,
    279.5,
    23.44
  ],
  [
    15716,
    65.08,
    13.31992,
    279.5,
    23.59
  ],
  [
    15718,
    65.08038,
    13.32034,
    279.5,
    23.8
  ],
  [
    15720,
    65.08076,
    13.32085,
    279.4,
    24.02
  ],
  [
    15722,
    65.0811,
    13.32146,
    279.5,
    24.12
  ],
  [
    15724,
    65.08142,
    13.32217,
    279.8,
    24.17
  ],
  [
    15726,
    65.0817,
    13.32297,
    280.2,
    24.17
  ],
  [
    15728,
    65.08196,
    13.3238,
    280.6,
    24.05
  ],
  [
    15730,
    65.08221,
    13.32463,
    281,
    23.86
  ],
  [
    15732,
    65.08246,
    13.32546,
    281.5,
    23.7
  ],
  [
    15734,
    65.0827,
    13.32628,
    281.9,
    23.5
  ],
  [
    15736,
    65.08295,
    13.32709,
    282.3,
    23.33
  ],
  [
    15738,
    65.0832,
    13.32788,
    282.7,
    23.12
  ],
  [
    15740,
    65.08347,
    13.32863,
    283.2,
    22.99
  ],
  [
    15742,
    65.08376,
    13.32931,
    283.7,
    22.87
  ],
  [
    15744,
    65.08408,
    13.32992,
    284.1,
    22.83
  ],
  [
    15746,
    65.08443,
    13.33045,
    284.5,
    22.77
  ],
  [
    15748,
    65.08479,
    13.3309,
    284.9,
    22.72
  ],
  [
    15750,
    65.08517,
    13.33127,
    285.2,
    22.69
  ],
  [
    15752,
    65.08555,
    13.33159,
    285.6,
    22.73
  ],
  [
    15754,
    65.08594,
    13.3319,
    286,
    22.82
  ],
  [
    15756,
    65.08632,
    13.33223,
    286.3,
    22.96
  ],
  [
    15758,
    65.08671,
    13.33259,
    286.6,
    23.13
  ],
  [
    15760,
    65.08709,
    13.33299,
    286.8,
    23.32
  ],
  [
    15762,
    65.08747,
    13.33342,
    286.9,
    23.57
  ],
  [
    15764,
    65.08785,
    13.33386,
    287.2,
    23.83
  ],
  [
    15766,
    65.08824,
    13.33429,
    287.4,
    24.07
  ],
  [
    15768,
    65.08865,
    13.33468,
    287.7,
    24.32
  ],
  [
    15770,
    65.08906,
    13.335,
    288,
    24.54
  ],
  [
    15772,
    65.08949,
    13.33525,
    288.5,
    24.74
  ],
  [
    15774,
    65.08993,
    13.33546,
    289,
    24.92
  ],
  [
    15776,
    65.09037,
    13.33567,
    289.3,
    25.1
  ],
  [
    15778,
    65.09082,
    13.33591,
    289.6,
    25.3
  ],
  [
    15780,
    65.09126,
    13.33619,
    289.5,
    25.53
  ],
  [
    15782,
    65.0917,
    13.33652,
    289.5,
    25.8
  ],
  [
    15784,
    65.09213,
    13.3369,
    289.4,
    26.09
  ],
  [
    15786,
    65.09257,
    13.33732,
    289.5,
    26.39
  ],
  [
    15788,
    65.093,
    13.33779,
    289.5,
    26.65
  ],
  [
    15790,
    65.09343,
    13.33831,
    289.5,
    26.94
  ],
  [
    15792,
    65.09386,
    13.33887,
    289.7,
    27.21
  ],
  [
    15794,
    65.09428,
    13.33948,
    289.6,
    27.46
  ],
  [
    15796,
    65.09469,
    13.3401,
    289.7,
    27.45
  ],
  [
    15798,
    65.09511,
    13.34072,
    289.8,
    27.35
  ],
  [
    15800,
    65.09553,
    13.34133,
    289.9,
    27.24
  ],
  [
    15802,
    65.09596,
    13.34188,
    289.9,
    27.09
  ],
  [
    15804,
    65.0964,
    13.34237,
    289.9,
    26.94
  ],
  [
    15806,
    65.09684,
    13.34279,
    291.1,
    26.77
  ],
  [
    15808,
    65.0973,
    13.34315,
    291.7,
    26.52
  ],
  [
    15810,
    65.09775,
    13.34349,
    292.3,
    26.27
  ],
  [
    15813,
    65.09842,
    13.344,
    293.1,
    26.03
  ],
  [
    15815,
    65.09886,
    13.34433,
    293.7,
    25.76
  ],
  [
    15817,
    65.09929,
    13.34466,
    294.2,
    25.49
  ],
  [
    15819,
    65.09973,
    13.34498,
    294.8,
    25.24
  ],
  [
    15821,
    65.10015,
    13.3453,
    295.4,
    25.24
  ],
  [
    15823,
    65.10058,
    13.34562,
    295.8,
    24.71
  ],
  [
    15825,
    65.10099,
    13.34593,
    296,
    24.71
  ],
  [
    15827,
    65.10141,
    13.34624,
    296.1,
    24.71
  ],
  [
    15829,
    65.10183,
    13.34652,
    296.1,
    24.21
  ],
  [
    15831,
    65.10225,
    13.34672,
    296.1,
    24.21
  ],
  [
    15833,
    65.10268,
    13.34683,
    296,
    24.07
  ],
  [
    15835,
    65.10311,
    13.34682,
    296.1,
    24.07
  ],
  [
    15837,
    65.10354,
    13.34672,
    296,
    24.07
  ],
  [
    15839,
    65.10397,
    13.34651,
    295.8,
    24.16
  ],
  [
    15841,
    65.10439,
    13.34621,
    295.7,
    24.27
  ],
  [
    15843,
    65.10479,
    13.34581,
    295.7,
    24.36
  ],
  [
    15845,
    65.10518,
    13.34531,
    296,
    24.45
  ],
  [
    15847,
    65.10555,
    13.34473,
    296.4,
    24.54
  ],
  [
    15849,
    65.1059,
    13.34407,
    296.7,
    24.68
  ],
  [
    15851,
    65.10624,
    13.34339,
    297.1,
    24.77
  ],
  [
    15853,
    65.10658,
    13.3427,
    297.5,
    24.85
  ],
  [
    15855,
    65.10693,
    13.34203,
    297.9,
    24.95
  ],
  [
    15857,
    65.10731,
    13.34143,
    298.2,
    25.04
  ],
  [
    15859,
    65.10771,
    13.34096,
    298.4,
    25.11
  ],
  [
    15861,
    65.10813,
    13.3406,
    298.7,
    25.17
  ],
  [
    15863,
    65.10858,
    13.34038,
    298.9,
    25.26
  ],
  [
    15865,
    65.10903,
    13.34029,
    299.1,
    25.31
  ],
  [
    15867,
    65.10948,
    13.34031,
    299.4,
    25.32
  ],
  [
    15869,
    65.10993,
    13.34038,
    299.8,
    25.32
  ],
  [
    15871,
    65.11039,
    13.34037,
    300,
    25.32
  ],
  [
    15873,
    65.11084,
    13.34024,
    300.4,
    25.28
  ],
  [
    15875,
    65.11128,
    13.33998,
    300.8,
    25.26
  ],
  [
    15877,
    65.11171,
    13.33963,
    301.3,
    25.24
  ],
  [
    15879,
    65.11214,
    13.33929,
    301.7,
    25.23
  ],
  [
    15881,
    65.11258,
    13.33903,
    302.2,
    25.25
  ],
  [
    15883,
    65.11304,
    13.3389,
    302.8,
    27.49
  ],
  [
    15885,
    65.1135,
    13.3389,
    303.1,
    25.14
  ],
  [
    15887,
    65.11394,
    13.33897,
    303.6,
    25.06
  ],
  [
    15889,
    65.11439,
    13.339,
    304,
    25.02
  ],
  [
    15891,
    65.11484,
    13.3389,
    304.3,
    25.01
  ],
  [
    15893,
    65.11527,
    13.33867,
    304.5,
    25.02
  ],
  [
    15895,
    65.1157,
    13.33832,
    304.7,
    25.04
  ],
  [
    15897,
    65.1161,
    13.33783,
    304.9,
    25.05
  ],
  [
    15899,
    65.11647,
    13.33723,
    304.9,
    25.08
  ],
  [
    15901,
    65.11682,
    13.33655,
    305.1,
    25.15
  ],
  [
    15903,
    65.11717,
    13.33587,
    305.2,
    25.2
  ],
  [
    15905,
    65.11754,
    13.33526,
    305.4,
    25.32
  ],
  [
    15907,
    65.11795,
    13.33477,
    305.6,
    25.36
  ],
  [
    15909,
    65.11837,
    13.33441,
    306,
    25.35
  ],
  [
    15911,
    65.11881,
    13.33414,
    306.3,
    25.35
  ],
  [
    15913,
    65.11925,
    13.33389,
    306.5,
    25.32
  ],
  [
    15915,
    65.11969,
    13.33359,
    306.5,
    25.34
  ],
  [
    15917,
    65.12012,
    13.33325,
    306.7,
    25.39
  ],
  [
    15919,
    65.12055,
    13.33285,
    306.8,
    25.47
  ],
  [
    15921,
    65.12096,
    13.33241,
    307.1,
    25.52
  ],
  [
    15923,
    65.12139,
    13.33198,
    307.5,
    25.59
  ],
  [
    15925,
    65.12182,
    13.33166,
    307.8,
    25.64
  ],
  [
    15927,
    65.12228,
    13.33147,
    308,
    25.68
  ],
  [
    15929,
    65.12274,
    13.33142,
    308.2,
    25.7
  ],
  [
    15931,
    65.12319,
    13.33152,
    308.3,
    25.76
  ],
  [
    15933,
    65.12365,
    13.33173,
    308.4,
    25.79
  ],
  [
    15935,
    65.1241,
    13.33198,
    308.7,
    25.86
  ],
  [
    15937,
    65.12455,
    13.3322,
    308.9,
    25.92
  ],
  [
    15939,
    65.12501,
    13.3324,
    309.2,
    26.02
  ],
  [
    15941,
    65.12548,
    13.33257,
    309.4,
    26.17
  ],
  [
    15943,
    65.12594,
    13.3327,
    309.7,
    26.33
  ],
  [
    15945,
    65.12641,
    13.33281,
    309.9,
    26.5
  ],
  [
    15947,
    65.12689,
    13.33289,
    310.1,
    26.68
  ],
  [
    15949,
    65.12737,
    13.33295,
    310.1,
    26.93
  ],
  [
    15951,
    65.12786,
    13.33301,
    310.3,
    27.17
  ],
  [
    15953,
    65.12834,
    13.33312,
    310.5,
    27.42
  ],
  [
    15955,
    65.12883,
    13.3333,
    310.6,
    27.64
  ],
  [
    15957,
    65.12932,
    13.33353,
    310.8,
    27.85
  ],
  [
    15959,
    65.12981,
    13.33379,
    311,
    28.05
  ],
  [
    15961,
    65.1303,
    13.33408,
    311.4,
    28.24
  ],
  [
    15963,
    65.13079,
    13.33438,
    311.9,
    28.38
  ],
  [
    15965,
    65.13129,
    13.33469,
    312.5,
    28.48
  ],
  [
    15967,
    65.13178,
    13.33501,
    312.5,
    28.54
  ],
  [
    15969,
    65.13227,
    13.33536,
    313.5,
    28.6
  ],
  [
    15971,
    65.13276,
    13.33577,
    314.6,
    28.66
  ],
  [
    15973,
    65.13322,
    13.3363,
    315.2,
    28.69
  ],
  [
    15975,
    65.13366,
    13.33695,
    315.7,
    28.78
  ],
  [
    15977,
    65.13406,
    13.33773,
    315.9,
    28.76
  ],
  [
    15979,
    65.13442,
    13.3386,
    316.1,
    28.76
  ],
  [
    15981,
    65.13474,
    13.33958,
    316.4,
    28.83
  ],
  [
    15984,
    65.13513,
    13.34118,
    316.5,
    28.95
  ],
  [
    15986,
    65.13537,
    13.34229,
    316.4,
    29.04
  ],
  [
    15988,
    65.13562,
    13.34339,
    316.3,
    29.19
  ],
  [
    15990,
    65.1359,
    13.34445,
    316.2,
    29.19
  ],
  [
    15992,
    65.13621,
    13.34548,
    316.2,
    29.19
  ],
  [
    15994,
    65.13654,
    13.34646,
    316.3,
    29.42
  ],
  [
    15996,
    65.1369,
    13.34738,
    316.3,
    29.33
  ],
  [
    15998,
    65.13728,
    13.34825,
    316.4,
    29.24
  ],
  [
    16000,
    65.13769,
    13.34901,
    316.3,
    29.14
  ],
  [
    16002,
    65.13813,
    13.34965,
    316.3,
    29.07
  ],
  [
    16004,
    65.13861,
    13.35017,
    316.2,
    28.98
  ],
  [
    16006,
    65.1391,
    13.35054,
    316.4,
    28.9
  ],
  [
    16008,
    65.13961,
    13.35078,
    316.8,
    28.7
  ],
  [
    16010,
    65.14012,
    13.35087,
    317.5,
    28.47
  ],
  [
    16012,
    65.14063,
    13.35082,
    318.1,
    28.35
  ],
  [
    16014,
    65.14113,
    13.35068,
    318.6,
    28
  ],
  [
    16016,
    65.14162,
    13.35052,
    319.1,
    27.81
  ],
  [
    16018,
    65.14212,
    13.35041,
    319.7,
    27.66
  ],
  [
    16020,
    65.14261,
    13.35039,
    320.2,
    27.51
  ],
  [
    16022,
    65.14311,
    13.35044,
    320.8,
    27.44
  ],
  [
    16024,
    65.1436,
    13.35053,
    321.4,
    27.28
  ],
  [
    16026,
    65.14408,
    13.35065,
    321.9,
    27.21
  ],
  [
    16028,
    65.14457,
    13.35082,
    322.4,
    27.18
  ],
  [
    16030,
    65.14505,
    13.35102,
    322.9,
    27.17
  ],
  [
    16032,
    65.14552,
    13.3513,
    323.3,
    27.16
  ],
  [
    16034,
    65.14598,
    13.35169,
    323.4,
    27.19
  ],
  [
    16036,
    65.14643,
    13.35213,
    323.4,
    27.24
  ],
  [
    16038,
    65.14689,
    13.35254,
    323.4,
    27.36
  ],
  [
    16040,
    65.14736,
    13.35288,
    323.4,
    27.51
  ],
  [
    16042,
    65.14784,
    13.35314,
    323.5,
    27.57
  ],
  [
    16044,
    65.14833,
    13.35332,
    323.5,
    27.71
  ],
  [
    16046,
    65.14883,
    13.35342,
    323.6,
    27.67
  ],
  [
    16048,
    65.14932,
    13.3535,
    323.8,
    27.62
  ],
  [
    16050,
    65.14982,
    13.35363,
    323.9,
    27.54
  ],
  [
    16052,
    65.1503,
    13.35385,
    324.1,
    27.45
  ],
  [
    16054,
    65.15077,
    13.35418,
    324.3,
    27.35
  ],
  [
    16056,
    65.15123,
    13.35461,
    324.4,
    27.22
  ],
  [
    16058,
    65.15167,
    13.3551,
    324.4,
    27.11
  ],
  [
    16060,
    65.15211,
    13.3556,
    324.2,
    26.99
  ],
  [
    16062,
    65.15254,
    13.35607,
    323.8,
    26.45
  ],
  [
    16064,
    65.15298,
    13.35651,
    323.6,
    26.07
  ],
  [
    16066,
    65.1534,
    13.35693,
    323.3,
    25.68
  ],
  [
    16068,
    65.15382,
    13.35738,
    323,
    25.44
  ],
  [
    16070,
    65.15422,
    13.35791,
    322.6,
    25.35
  ],
  [
    16072,
    65.15459,
    13.35853,
    322.3,
    25.38
  ],
  [
    16074,
    65.15493,
    13.35924,
    322,
    25.45
  ],
  [
    16076,
    65.15525,
    13.36002,
    321.7,
    25.44
  ],
  [
    16078,
    65.15557,
    13.3608,
    321.6,
    25.46
  ],
  [
    16080,
    65.15591,
    13.36153,
    321.5,
    25.44
  ],
  [
    16082,
    65.15627,
    13.36218,
    321.5,
    25.42
  ],
  [
    16084,
    65.15666,
    13.36274,
    321.5,
    25.36
  ],
  [
    16086,
    65.15707,
    13.36319,
    321.5,
    25.28
  ],
  [
    16088,
    65.1575,
    13.36354,
    321.5,
    25.23
  ],
  [
    16090,
    65.15794,
    13.36379,
    321.6,
    25.15
  ],
  [
    16092,
    65.15838,
    13.36399,
    321.7,
    24.98
  ],
  [
    16094,
    65.15881,
    13.3642,
    322,
    24.38
  ],
  [
    16096,
    65.15923,
    13.36448,
    322.1,
    23.81
  ],
  [
    16098,
    65.15962,
    13.36485,
    322.2,
    23.21
  ],
  [
    16100,
    65.15999,
    13.36528,
    322.4,
    22.55
  ],
  [
    16102,
    65.16034,
    13.36571,
    322.4,
    21.95
  ],
  [
    16104,
    65.16069,
    13.36612,
    322.5,
    21.42
  ],
  [
    16106,
    65.16104,
    13.36647,
    322.5,
    20.92
  ],
  [
    16108,
    65.16139,
    13.36677,
    322.6,
    20.43
  ],
  [
    16110,
    65.16173,
    13.367,
    322.9,
    19.87
  ],
  [
    16112,
    65.16207,
    13.36717,
    323.2,
    19.2
  ],
  [
    16114,
    65.16241,
    13.36728,
    323.4,
    18.52
  ],
  [
    16116,
    65.16273,
    13.36734,
    323.7,
    18.02
  ],
  [
    16118,
    65.16305,
    13.36739,
    323.8,
    17.74
  ],
  [
    16120,
    65.16337,
    13.36744,
    323.8,
    17.56
  ],
  [
    16122,
    65.16368,
    13.36748,
    323.8,
    17.53
  ],
  [
    16124,
    65.164,
    13.36752,
    323.8,
    17.59
  ],
  [
    16126,
    65.16431,
    13.36757,
    324.1,
    17.75
  ],
  [
    16128,
    65.16463,
    13.36761,
    324.2,
    17.97
  ],
  [
    16130,
    65.16495,
    13.36766,
    324.3,
    18.19
  ],
  [
    16132,
    65.16528,
    13.3677,
    324.4,
    18.4
  ],
  [
    16134,
    65.16561,
    13.36775,
    324.5,
    18.61
  ],
  [
    16137,
    65.16611,
    13.36782,
    324.6,
    18.8
  ],
  [
    16139,
    65.16645,
    13.36787,
    324.7,
    19.01
  ],
  [
    16141,
    65.1668,
    13.36792,
    324.8,
    19.19
  ],
  [
    16143,
    65.16714,
    13.36797,
    324.8,
    19.36
  ],
  [
    16145,
    65.16749,
    13.36802,
    324.9,
    19.54
  ],
  [
    16147,
    65.16785,
    13.36807,
    325,
    19.54
  ],
  [
    16149,
    65.1682,
    13.36812,
    325,
    19.79
  ],
  [
    16151,
    65.16856,
    13.36817,
    325,
    19.79
  ],
  [
    16153,
    65.16892,
    13.36822,
    325,
    20.18
  ],
  [
    16155,
    65.16929,
    13.36828,
    325.1,
    20.18
  ],
  [
    16157,
    65.16965,
    13.36833,
    325.3,
    20.18
  ],
  [
    16159,
    65.17002,
    13.36837,
    325.3,
    20.55
  ],
  [
    16161,
    65.17039,
    13.36837,
    325.4,
    20.55
  ],
  [
    16163,
    65.17077,
    13.36828,
    325.2,
    21.05
  ],
  [
    16165,
    65.17115,
    13.36813,
    325.1,
    21.31
  ],
  [
    16167,
    65.17153,
    13.36792,
    325,
    21.65
  ],
  [
    16169,
    65.17191,
    13.36767,
    324.9,
    22.21
  ],
  [
    16171,
    65.17229,
    13.36738,
    324.7,
    22.61
  ],
  [
    16173,
    65.17268,
    13.36704,
    324.7,
    22.99
  ],
  [
    16175,
    65.17306,
    13.36664,
    324.7,
    23.29
  ],
  [
    16177,
    65.17344,
    13.3662,
    324.6,
    23.5
  ],
  [
    16179,
    65.17382,
    13.36574,
    324.7,
    23.93
  ],
  [
    16181,
    65.17421,
    13.36528,
    324.9,
    24.22
  ],
  [
    16183,
    65.1746,
    13.36481,
    325.3,
    24.45
  ],
  [
    16185,
    65.17499,
    13.36434,
    325.5,
    24.63
  ],
  [
    16187,
    65.17539,
    13.36386,
    325.8,
    24.71
  ],
  [
    16189,
    65.17579,
    13.36338,
    326.1,
    24.96
  ],
  [
    16191,
    65.17619,
    13.3629,
    326.3,
    25.14
  ],
  [
    16193,
    65.1766,
    13.36242,
    326.5,
    25.32
  ],
  [
    16195,
    65.17701,
    13.36193,
    326.6,
    25.49
  ],
  [
    16197,
    65.17742,
    13.36144,
    326.9,
    25.6
  ],
  [
    16199,
    65.17783,
    13.36094,
    327.3,
    25.87
  ],
  [
    16201,
    65.17825,
    13.36045,
    327.6,
    26.11
  ],
  [
    16203,
    65.17867,
    13.35995,
    328.2,
    26.55
  ],
  [
    16205,
    65.17912,
    13.35948,
    329.2,
    26.53
  ],
  [
    16207,
    65.17952,
    13.35887,
    328.7,
    26.62
  ],
  [
    16209,
    65.17991,
    13.35818,
    328.6,
    26.88
  ],
  [
    16211,
    65.18027,
    13.35741,
    328.8,
    27.09
  ],
  [
    16213,
    65.18062,
    13.35658,
    329,
    27.27
  ],
  [
    16215,
    65.18093,
    13.35568,
    329.4,
    27.4
  ],
  [
    16217,
    65.18123,
    13.35473,
    329.9,
    27.53
  ],
  [
    16219,
    65.18149,
    13.35373,
    330.5,
    27.62
  ],
  [
    16221,
    65.18173,
    13.35268,
    330.8,
    27.71
  ],
  [
    16223,
    65.18194,
    13.35159,
    331.2,
    27.8
  ],
  [
    16225,
    65.18212,
    13.35048,
    331.7,
    27.93
  ],
  [
    16227,
    65.18232,
    13.34936,
    332.4,
    28.07
  ],
  [
    16229,
    65.18255,
    13.34828,
    333.1,
    28.21
  ],
  [
    16231,
    65.18282,
    13.34726,
    333.4,
    28.27
  ],
  [
    16233,
    65.18313,
    13.34631,
    333.4,
    28.38
  ],
  [
    16235,
    65.18348,
    13.34543,
    333.2,
    28.46
  ],
  [
    16237,
    65.18387,
    13.34463,
    332.6,
    28.57
  ],
  [
    16239,
    65.18429,
    13.34392,
    332.1,
    28.81
  ],
  [
    16241,
    65.18474,
    13.3433,
    331.6,
    29.03
  ],
  [
    16243,
    65.18522,
    13.3428,
    331,
    29.2
  ],
  [
    16245,
    65.18571,
    13.34238,
    330.4,
    29.43
  ],
  [
    16247,
    65.18622,
    13.34199,
    329.8,
    29.61
  ],
  [
    16249,
    65.18673,
    13.3416,
    329.5,
    29.84
  ],
  [
    16251,
    65.18724,
    13.34122,
    329.4,
    29.95
  ],
  [
    16253,
    65.18776,
    13.34086,
    329.7,
    30.08
  ],
  [
    16255,
    65.18829,
    13.34058,
    330,
    30.14
  ],
  [
    16257,
    65.18882,
    13.34042,
    330,
    30.09
  ],
  [
    16259,
    65.18936,
    13.34038,
    330.1,
    30.08
  ],
  [
    16261,
    65.1899,
    13.34045,
    329.9,
    30.08
  ],
  [
    16263,
    65.19044,
    13.34063,
    329.9,
    30.12
  ],
  [
    16265,
    65.19097,
    13.34091,
    329.8,
    30.18
  ],
  [
    16267,
    65.1915,
    13.34125,
    329.7,
    30.23
  ],
  [
    16269,
    65.19203,
    13.34154,
    329.8,
    30.25
  ],
  [
    16271,
    65.19257,
    13.3417,
    329.8,
    30.25
  ],
  [
    16273,
    65.19311,
    13.34175,
    329.8,
    30.25
  ],
  [
    16275,
    65.19366,
    13.34175,
    329.7,
    30.27
  ],
  [
    16277,
    65.1942,
    13.34179,
    329.6,
    30.32
  ],
  [
    16279,
    65.19475,
    13.34194,
    329.6,
    30.31
  ],
  [
    16281,
    65.19528,
    13.34216,
    329.8,
    30.3
  ],
  [
    16283,
    65.19582,
    13.34239,
    329.9,
    30.32
  ],
  [
    16285,
    65.19636,
    13.34262,
    329.9,
    30.3
  ],
  [
    16287,
    65.1969,
    13.34285,
    330.4,
    30.32
  ],
  [
    16289,
    65.19743,
    13.34308,
    330.7,
    30.32
  ],
  [
    16291,
    65.19797,
    13.34332,
    331.2,
    30.31
  ],
  [
    16293,
    65.1985,
    13.34357,
    331.7,
    30.28
  ],
  [
    16295,
    65.19903,
    13.34387,
    332.1,
    30.23
  ],
  [
    16298,
    65.19982,
    13.34441,
    332.2,
    30.2
  ],
  [
    16300,
    65.20033,
    13.34482,
    332.4,
    30.18
  ],
  [
    16302,
    65.20085,
    13.34523,
    332.5,
    30.22
  ],
  [
    16304,
    65.20136,
    13.34563,
    332.7,
    30.25
  ],
  [
    16306,
    65.20188,
    13.34604,
    332.8,
    30.25
  ],
  [
    16308,
    65.2024,
    13.34645,
    332.9,
    30.2
  ],
  [
    16310,
    65.20291,
    13.34685,
    333,
    30.2
  ],
  [
    16312,
    65.20342,
    13.34725,
    333.1,
    30.2
  ],
  [
    16314,
    65.20394,
    13.34766,
    333.2,
    30
  ],
  [
    16316,
    65.20445,
    13.34806,
    333.3,
    30
  ],
  [
    16318,
    65.20495,
    13.34846,
    333.4,
    29.82
  ],
  [
    16320,
    65.20546,
    13.34886,
    333.5,
    29.75
  ],
  [
    16322,
    65.20597,
    13.34924,
    333.7,
    29.66
  ],
  [
    16324,
    65.20648,
    13.34958,
    334,
    29.57
  ],
  [
    16326,
    65.207,
    13.34983,
    334.4,
    29.42
  ],
  [
    16328,
    65.20752,
    13.35002,
    334.7,
    29.28
  ],
  [
    16330,
    65.20804,
    13.35017,
    335.1,
    29.22
  ],
  [
    16332,
    65.20856,
    13.35032,
    335.3,
    29.14
  ],
  [
    16334,
    65.20908,
    13.35046,
    335.4,
    29.06
  ],
  [
    16336,
    65.20959,
    13.35061,
    335.4,
    29.01
  ],
  [
    16338,
    65.21011,
    13.35076,
    335.2,
    28.95
  ],
  [
    16340,
    65.21062,
    13.3509,
    335,
    28.37
  ],
  [
    16342,
    65.21112,
    13.35104,
    335,
    28.1
  ],
  [
    16344,
    65.21161,
    13.35118,
    335,
    27.11
  ],
  [
    16346,
    65.21209,
    13.35132,
    335.1,
    26.26
  ],
  [
    16348,
    65.21255,
    13.35145,
    335.2,
    25.43
  ],
  [
    16350,
    65.213,
    13.35157,
    335.4,
    24.95
  ],
  [
    16352,
    65.21344,
    13.3517,
    335.4,
    24.67
  ],
  [
    16354,
    65.21387,
    13.35184,
    335.5,
    24.48
  ],
  [
    16356,
    65.2143,
    13.35203,
    335.5,
    24.41
  ],
  [
    16358,
    65.21472,
    13.35232,
    335.3,
    24.37
  ],
  [
    16360,
    65.21513,
    13.35272,
    335,
    24.37
  ],
  [
    16362,
    65.21551,
    13.3532,
    334.7,
    24.43
  ],
  [
    16364,
    65.21589,
    13.35374,
    334.3,
    24.43
  ],
  [
    16366,
    65.21626,
    13.35431,
    334,
    24.49
  ],
  [
    16368,
    65.21663,
    13.35487,
    333.7,
    24.56
  ],
  [
    16370,
    65.217,
    13.35543,
    333.2,
    24.61
  ],
  [
    16372,
    65.21737,
    13.356,
    332.7,
    24.74
  ],
  [
    16374,
    65.21775,
    13.35657,
    332.1,
    24.85
  ],
  [
    16376,
    65.21812,
    13.35714,
    331.6,
    24.96
  ],
  [
    16378,
    65.2185,
    13.35771,
    331.1,
    25.06
  ],
  [
    16380,
    65.21889,
    13.35825,
    330.7,
    25.15
  ],
  [
    16382,
    65.2193,
    13.35869,
    330.2,
    25.21
  ],
  [
    16384,
    65.21974,
    13.35899,
    329.8,
    25.31
  ],
  [
    16386,
    65.22019,
    13.35917,
    329.4,
    25.4
  ],
  [
    16388,
    65.22064,
    13.3592,
    329,
    25.5
  ],
  [
    16390,
    65.2211,
    13.35909,
    328.4,
    25.6
  ],
  [
    16392,
    65.22155,
    13.35885,
    327.7,
    25.69
  ],
  [
    16394,
    65.22199,
    13.35853,
    327,
    25.85
  ],
  [
    16396,
    65.22244,
    13.35822,
    326.4,
    25.99
  ],
  [
    16398,
    65.2229,
    13.35803,
    326,
    26.09
  ],
  [
    16400,
    65.22336,
    13.35798,
    325.8,
    25.98
  ],
  [
    16402,
    65.22383,
    13.35807,
    325.7,
    26.12
  ],
  [
    16404,
    65.2243,
    13.35819,
    325.7,
    26.07
  ],
  [
    16406,
    65.22476,
    13.35824,
    325.6,
    26
  ],
  [
    16408,
    65.22523,
    13.35815,
    325.7,
    25.93
  ],
  [
    16410,
    65.22568,
    13.35792,
    325.6,
    25.83
  ],
  [
    16412,
    65.22612,
    13.35755,
    325.5,
    25.75
  ],
  [
    16414,
    65.22653,
    13.35705,
    325.6,
    25.69
  ],
  [
    16416,
    65.22691,
    13.35643,
    325.7,
    25.6
  ],
  [
    16418,
    65.22726,
    13.35572,
    326,
    25.44
  ],
  [
    16420,
    65.2276,
    13.35499,
    326.3,
    25.36
  ],
  [
    16422,
    65.22794,
    13.35427,
    326.4,
    25.22
  ],
  [
    16424,
    65.22828,
    13.35357,
    326.4,
    25.05
  ],
  [
    16426,
    65.22863,
    13.3529,
    326.4,
    24.87
  ],
  [
    16428,
    65.22899,
    13.35226,
    326.8,
    24.79
  ],
  [
    16430,
    65.22935,
    13.35163,
    326.8,
    24.71
  ],
  [
    16432,
    65.22971,
    13.35104,
    326.7,
    24.68
  ],
  [
    16434,
    65.2301,
    13.35051,
    326.7,
    24.73
  ],
  [
    16436,
    65.2305,
    13.35009,
    326.7,
    24.71
  ],
  [
    16439,
    65.23115,
    13.3497,
    326.3,
    24.72
  ],
  [
    16441,
    65.23158,
    13.34956,
    325.8,
    24.77
  ],
  [
    16443,
    65.23203,
    13.34943,
    325.5,
    24.82
  ],
  [
    16445,
    65.23246,
    13.3492,
    325.1,
    24.84
  ],
  [
    16447,
    65.23288,
    13.34884,
    324.8,
    24.84
  ],
  [
    16449,
    65.23328,
    13.34836,
    324.7,
    25.01
  ],
  [
    16451,
    65.23367,
    13.34779,
    324.9,
    25.01
  ],
  [
    16453,
    65.23404,
    13.3472,
    325.2,
    25.05
  ],
  [
    16455,
    65.23442,
    13.34662,
    325.7,
    25.05
  ],
  [
    16457,
    65.23479,
    13.34604,
    326,
    25.05
  ],
  [
    16459,
    65.23516,
    13.34546,
    326.5,
    24.73
  ],
  [
    16461,
    65.23553,
    13.34489,
    327,
    24.51
  ],
  [
    16463,
    65.23589,
    13.34432,
    327.4,
    24.35
  ],
  [
    16465,
    65.23626,
    13.34375,
    327.7,
    24.22
  ],
  [
    16467,
    65.23661,
    13.34317,
    328.2,
    24.08
  ],
  [
    16469,
    65.23695,
    13.34254,
    328.5,
    23.8
  ],
  [
    16471,
    65.23726,
    13.34184,
    329,
    23.8
  ],
  [
    16473,
    65.23755,
    13.34109,
    329.3,
    23.94
  ],
  [
    16475,
    65.23785,
    13.34035,
    329.5,
    23.94
  ],
  [
    16484,
    65.2391,
    13.33685,
    329.9,
    24.05
  ],
  [
    16486,
    65.23939,
    13.33621,
    329.9,
    24.24
  ],
  [
    16488,
    65.23976,
    13.33562,
    329.9,
    24.31
  ],
  [
    16490,
    65.24012,
    13.33503,
    325.9,
    24.55
  ],
  [
    16492,
    65.24049,
    13.33444,
    324.8,
    24.75
  ],
  [
    16494,
    65.24086,
    13.33385,
    323.9,
    24.95
  ],
  [
    16496,
    65.24123,
    13.33325,
    323.1,
    25.05
  ],
  [
    16498,
    65.2416,
    13.33265,
    322.5,
    25.14
  ],
  [
    16500,
    65.24196,
    13.332,
    321.8,
    25.22
  ],
  [
    16503,
    65.24246,
    13.33089,
    320.7,
    25.28
  ],
  [
    16505,
    65.24277,
    13.33009,
    320.2,
    25.42
  ],
  [
    16507,
    65.24308,
    13.32929,
    320,
    25.51
  ],
  [
    16509,
    65.24339,
    13.32849,
    320,
    25.54
  ],
  [
    16511,
    65.24372,
    13.32771,
    319.9,
    25.54
  ],
  [
    16513,
    65.24404,
    13.32695,
    319.9,
    25.51
  ],
  [
    16515,
    65.24438,
    13.3262,
    319.9,
    25.51
  ],
  [
    16517,
    65.24472,
    13.32548,
    319.7,
    25.51
  ],
  [
    16519,
    65.24506,
    13.32477,
    319.6,
    25.39
  ],
  [
    16521,
    65.24541,
    13.32408,
    319.6,
    25.39
  ],
  [
    16523,
    65.24577,
    13.32341,
    319.5,
    25.27
  ],
  [
    16525,
    65.24614,
    13.32278,
    319.1,
    25.26
  ],
  [
    16527,
    65.24653,
    13.32223,
    318.3,
    25.28
  ],
  [
    16529,
    65.24694,
    13.32177,
    317.7,
    25.35
  ],
  [
    16531,
    65.24738,
    13.32142,
    317,
    25.46
  ],
  [
    16533,
    65.24782,
    13.32117,
    316.4,
    25.66
  ],
  [
    16535,
    65.24828,
    13.321,
    315.7,
    25.72
  ],
  [
    16537,
    65.24874,
    13.32085,
    315.1,
    25.81
  ],
  [
    16539,
    65.2492,
    13.32071,
    314.7,
    26.02
  ],
  [
    16541,
    65.24967,
    13.32058,
    314.1,
    26.14
  ],
  [
    16543,
    65.25014,
    13.32047,
    313.6,
    26.26
  ],
  [
    16545,
    65.25061,
    13.32038,
    313.2,
    26.38
  ],
  [
    16547,
    65.25108,
    13.32034,
    313,
    26.42
  ],
  [
    16549,
    65.25156,
    13.32037,
    313.1,
    26.47
  ],
  [
    16551,
    65.25204,
    13.32044,
    313.2,
    26.45
  ],
  [
    16553,
    65.25251,
    13.3205,
    313.3,
    26.26
  ],
  [
    16555,
    65.25298,
    13.32046,
    313.4,
    26.03
  ],
  [
    16557,
    65.25344,
    13.32029,
    313.4,
    25.92
  ],
  [
    16559,
    65.25388,
    13.31999,
    313.1,
    25.63
  ],
  [
    16561,
    65.25431,
    13.31957,
    312.7,
    25.55
  ],
  [
    16563,
    65.25471,
    13.31905,
    312.2,
    25.63
  ],
  [
    16565,
    65.25508,
    13.31841,
    311.7,
    25.72
  ],
  [
    16567,
    65.25543,
    13.31767,
    311.2,
    25.78
  ],
  [
    16569,
    65.25577,
    13.3169,
    310.8,
    25.98
  ],
  [
    16571,
    65.25611,
    13.31613,
    310.3,
    26.11
  ],
  [
    16573,
    65.25645,
    13.31536,
    309.9,
    26.25
  ],
  [
    16575,
    65.25682,
    13.31466,
    309.7,
    26.35
  ],
  [
    16577,
    65.25722,
    13.31406,
    309.5,
    26.38
  ],
  [
    16579,
    65.25765,
    13.31356,
    309.5,
    26.34
  ],
  [
    16581,
    65.25808,
    13.31311,
    309.4,
    26.28
  ],
  [
    16583,
    65.25851,
    13.31267,
    309.4,
    26.24
  ],
  [
    16585,
    65.25895,
    13.31226,
    309.2,
    26.21
  ],
  [
    16587,
    65.25939,
    13.31187,
    309.3,
    26.16
  ],
  [
    16589,
    65.25984,
    13.31152,
    309.3,
    26.1
  ],
  [
    16591,
    65.26029,
    13.31124,
    309.3,
    26.05
  ],
  [
    16593,
    65.26075,
    13.31101,
    309.3,
    25.99
  ],
  [
    16595,
    65.26121,
    13.31083,
    309,
    25.96
  ],
  [
    16597,
    65.26167,
    13.31065,
    308.5,
    25.94
  ],
  [
    16599,
    65.26212,
    13.31041,
    307.9,
    25.98
  ],
  [
    16601,
    65.26256,
    13.31005,
    307.3,
    26.09
  ],
  [
    16603,
    65.26299,
    13.30959,
    306.8,
    26.2
  ],
  [
    16605,
    65.2634,
    13.30901,
    306.2,
    26.32
  ],
  [
    16607,
    65.26377,
    13.30833,
    305.7,
    26.45
  ],
  [
    16609,
    65.26412,
    13.30756,
    305.3,
    26.56
  ],
  [
    16611,
    65.26445,
    13.30673,
    304.8,
    26.72
  ],
  [
    16613,
    65.26479,
    13.30591,
    304.4,
    26.48
  ],
  [
    16615,
    65.26514,
    13.30517,
    303.9,
    26.15
  ],
  [
    16617,
    65.26553,
    13.30454,
    303.3,
    25.83
  ],
  [
    16619,
    65.26593,
    13.30401,
    302.8,
    25.59
  ],
  [
    16621,
    65.26635,
    13.30358,
    302.2,
    25.45
  ],
  [
    16623,
    65.26679,
    13.30327,
    301.8,
    25.55
  ],
  [
    16625,
    65.26724,
    13.30306,
    301.1,
    25.65
  ],
  [
    16627,
    65.2677,
    13.30296,
    300.4,
    25.79
  ],
  [
    16629,
    65.26816,
    13.30298,
    299.9,
    25.89
  ],
  [
    16631,
    65.26862,
    13.30311,
    299.4,
    26.01
  ],
  [
    16633,
    65.26908,
    13.30334,
    299,
    26.12
  ],
  [
    16635,
    65.26954,
    13.30362,
    298.5,
    26.23
  ],
  [
    16637,
    65.27,
    13.30391,
    298,
    26.35
  ],
  [
    16639,
    65.27046,
    13.30419,
    297.7,
    26.48
  ],
  [
    16641,
    65.27092,
    13.30448,
    297.3,
    26.62
  ],
  [
    16643,
    65.27138,
    13.3048,
    296.9,
    26.77
  ],
  [
    16645,
    65.27183,
    13.30517,
    296.4,
    26.8
  ],
  [
    16647,
    65.27228,
    13.3056,
    296.4,
    26.79
  ],
  [
    16649,
    65.27272,
    13.30609,
    296.4,
    26.75
  ],
  [
    16651,
    65.27314,
    13.30663,
    295.1,
    26.73
  ],
  [
    16653,
    65.27355,
    13.30723,
    294.7,
    26.67
  ],
  [
    16656,
    65.27415,
    13.30818,
    293.9,
    26.64
  ],
  [
    16658,
    65.27454,
    13.30883,
    293.3,
    26.57
  ],
  [
    16660,
    65.27494,
    13.30946,
    292.7,
    26.39
  ],
  [
    16662,
    65.27533,
    13.3101,
    292.2,
    26.22
  ],
  [
    16664,
    65.27572,
    13.31073,
    291.6,
    26.16
  ],
  [
    16666,
    65.2761,
    13.31136,
    291.1,
    26.16
  ],
  [
    16668,
    65.27649,
    13.31199,
    290.7,
    26.1
  ],
  [
    16670,
    65.27688,
    13.31261,
    290.6,
    26.1
  ],
  [
    16672,
    65.27728,
    13.3132,
    290.6,
    26.1
  ],
  [
    16674,
    65.27769,
    13.31372,
    290.6,
    26.07
  ],
  [
    16676,
    65.27813,
    13.31413,
    290.6,
    26.07
  ],
  [
    16678,
    65.27858,
    13.31442,
    290.7,
    25.91
  ],
  [
    16680,
    65.27903,
    13.3146,
    290.8,
    25.91
  ],
  [
    16682,
    65.27949,
    13.31466,
    290.8,
    25.74
  ],
  [
    16684,
    65.27995,
    13.31461,
    290.7,
    25.67
  ],
  [
    16686,
    65.28041,
    13.31444,
    290.3,
    25.63
  ],
  [
    16688,
    65.28086,
    13.31417,
    289.9,
    25.64
  ],
  [
    16690,
    65.28129,
    13.3138,
    289.5,
    25.73
  ],
  [
    16692,
    65.28172,
    13.31346,
    288.9,
    25.25
  ],
  [
    16694,
    65.28216,
    13.31295,
    288.2,
    26.08
  ],
  [
    16696,
    65.28259,
    13.31248,
    288,
    26.25
  ],
  [
    16698,
    65.28303,
    13.31205,
    287.2,
    26.36
  ],
  [
    16700,
    65.28347,
    13.31161,
    286.4,
    26.52
  ],
  [
    16702,
    65.28392,
    13.31119,
    285.7,
    26.6
  ],
  [
    16704,
    65.28437,
    13.31076,
    284.9,
    26.83
  ],
  [
    16706,
    65.28481,
    13.31033,
    284.1,
    27
  ],
  [
    16708,
    65.28527,
    13.30994,
    283.6,
    27.07
  ],
  [
    16710,
    65.28574,
    13.30963,
    283.1,
    27.05
  ],
  [
    16712,
    65.28622,
    13.30945,
    282.5,
    27.01
  ],
  [
    16714,
    65.2867,
    13.3094,
    281.9,
    26.93
  ],
  [
    16716,
    65.28718,
    13.30948,
    281.3,
    26.9
  ],
  [
    16718,
    65.28766,
    13.30967,
    280.8,
    26.84
  ],
  [
    16720,
    65.28813,
    13.30994,
    280.5,
    26.65
  ],
  [
    16722,
    65.28859,
    13.31018,
    279.9,
    26.56
  ],
  [
    16724,
    65.28906,
    13.31032,
    279.4,
    26.24
  ],
  [
    16726,
    65.28953,
    13.31033,
    278.9,
    26.05
  ],
  [
    16728,
    65.28999,
    13.31025,
    278.4,
    25.88
  ],
  [
    16730,
    65.29046,
    13.31013,
    277.8,
    25.79
  ],
  [
    16732,
    65.29091,
    13.31,
    277.2,
    25.79
  ],
  [
    16734,
    65.29137,
    13.30986,
    276.6,
    25.8
  ],
  [
    16736,
    65.29183,
    13.3097,
    275.9,
    25.8
  ],
  [
    16738,
    65.29229,
    13.30954,
    275.3,
    25.81
  ],
  [
    16740,
    65.29275,
    13.30936,
    274.8,
    25.82
  ],
  [
    16742,
    65.2932,
    13.30917,
    274.2,
    25.8
  ],
  [
    16744,
    65.29366,
    13.30897,
    273.6,
    25.79
  ],
  [
    16746,
    65.29411,
    13.30877,
    273,
    25.79
  ],
  [
    16748,
    65.29457,
    13.30857,
    272.4,
    25.78
  ],
  [
    16750,
    65.29502,
    13.30839,
    271.8,
    25.81
  ],
  [
    16752,
    65.29549,
    13.30828,
    271.3,
    25.8
  ],
  [
    16754,
    65.29595,
    13.3083,
    270.8,
    25.76
  ],
  [
    16756,
    65.29641,
    13.30842,
    270.4,
    25.74
  ],
  [
    16758,
    65.29686,
    13.30861,
    269.9,
    25.72
  ],
  [
    16760,
    65.29731,
    13.30882,
    269.5,
    25.71
  ],
  [
    16762,
    65.29777,
    13.309,
    269.1,
    25.7
  ],
  [
    16764,
    65.29822,
    13.30916,
    268.7,
    25.68
  ],
  [
    16766,
    65.29868,
    13.30928,
    268.2,
    25.68
  ],
  [
    16768,
    65.29914,
    13.30938,
    267.7,
    25.69
  ],
  [
    16770,
    65.2996,
    13.30945,
    267.2,
    25.68
  ],
  [
    16772,
    65.30006,
    13.30948,
    266.7,
    25.65
  ],
  [
    16774,
    65.30052,
    13.30941,
    266.2,
    25.61
  ],
  [
    16776,
    65.30097,
    13.30924,
    265.8,
    25.58
  ],
  [
    16778,
    65.30142,
    13.309,
    265.3,
    25.56
  ],
  [
    16780,
    65.30187,
    13.30876,
    264.7,
    25.58
  ],
  [
    16782,
    65.30232,
    13.30858,
    264,
    25.54
  ],
  [
    16784,
    65.30277,
    13.30845,
    263.5,
    25.55
  ],
  [
    16786,
    65.30323,
    13.30839,
    262.9,
    25.54
  ],
  [
    16788,
    65.30369,
    13.30839,
    262.3,
    25.54
  ],
  [
    16790,
    65.30414,
    13.30845,
    261.8,
    25.56
  ],
  [
    16792,
    65.3046,
    13.30858,
    261.4,
    25.67
  ],
  [
    16794,
    65.30506,
    13.30876,
    260.9,
    25.77
  ],
  [
    16796,
    65.30551,
    13.30898,
    260.7,
    25.87
  ],
  [
    16798,
    65.30596,
    13.30922,
    260.5,
    25.93
  ],
  [
    16800,
    65.30641,
    13.3095,
    260.5,
    25.97
  ],
  [
    16802,
    65.30686,
    13.3098,
    260.5,
    25.98
  ],
  [
    16804,
    65.30731,
    13.31013,
    260.4,
    25.95
  ],
  [
    16806,
    65.30775,
    13.31047,
    260.4,
    25.95
  ],
  [
    16808,
    65.3082,
    13.31081,
    260.4,
    25.93
  ],
  [
    16810,
    65.30864,
    13.31114,
    260.4,
    25.93
  ],
  [
    16812,
    65.30909,
    13.31143,
    260.2,
    25.92
  ],
  [
    16814,
    65.30954,
    13.31164,
    259.8,
    25.93
  ],
  [
    16817,
    65.31024,
    13.31171,
    259,
    26
  ],
  [
    16819,
    65.31071,
    13.31161,
    258.5,
    26.11
  ],
  [
    16821,
    65.31117,
    13.31142,
    257.8,
    26.23
  ],
  [
    16823,
    65.31164,
    13.31122,
    257.2,
    26.38
  ],
  [
    16825,
    65.31211,
    13.31108,
    256.7,
    26.46
  ],
  [
    16827,
    65.31259,
    13.31106,
    256.1,
    26.46
  ],
  [
    16829,
    65.31306,
    13.31116,
    255.5,
    26.69
  ],
  [
    16831,
    65.31353,
    13.31131,
    255,
    26.69
  ],
  [
    16833,
    65.314,
    13.31148,
    254.4,
    26.33
  ],
  [
    16835,
    65.31447,
    13.31164,
    253.8,
    26.18
  ],
  [
    16837,
    65.31493,
    13.31179,
    253.1,
    26.05
  ],
  [
    16839,
    65.31539,
    13.31193,
    252.5,
    25.9
  ],
  [
    16841,
    65.31585,
    13.31206,
    251.9,
    25.89
  ],
  [
    16843,
    65.31631,
    13.31217,
    251.3,
    25.9
  ],
  [
    16845,
    65.31677,
    13.31228,
    250.7,
    25.92
  ],
  [
    16847,
    65.31724,
    13.3124,
    250.2,
    25.92
  ],
  [
    16849,
    65.3177,
    13.31253,
    249.7,
    25.94
  ],
  [
    16851,
    65.31815,
    13.31275,
    249,
    25.93
  ],
  [
    16853,
    65.3186,
    13.31308,
    248.4,
    25.9
  ],
  [
    16855,
    65.31902,
    13.31352,
    248,
    25.86
  ],
  [
    16857,
    65.31943,
    13.31406,
    247.5,
    25.84
  ],
  [
    16859,
    65.31982,
    13.31465,
    247.1,
    25.8
  ],
  [
    16861,
    65.32021,
    13.31523,
    246.5,
    25.77
  ],
  [
    16863,
    65.32061,
    13.31581,
    246.1,
    25.79
  ],
  [
    16865,
    65.32101,
    13.31638,
    245.6,
    25.81
  ],
  [
    16867,
    65.32141,
    13.31693,
    245.1,
    25.8
  ],
  [
    16869,
    65.32181,
    13.31748,
    244.6,
    25.79
  ],
  [
    16871,
    65.32222,
    13.31801,
    244.1,
    25.78
  ],
  [
    16873,
    65.32262,
    13.31853,
    243.6,
    25.79
  ],
  [
    16875,
    65.32304,
    13.319,
    242.9,
    25.76
  ],
  [
    16877,
    65.32347,
    13.31939,
    242.6,
    25.7
  ],
  [
    16879,
    65.32392,
    13.31967,
    242.2,
    25.71
  ],
  [
    16881,
    65.32437,
    13.31983,
    241.7,
    25.63
  ],
  [
    16883,
    65.32483,
    13.31988,
    240.9,
    25.61
  ],
  [
    16885,
    65.32529,
    13.31983,
    240.4,
    25.58
  ],
  [
    16887,
    65.32575,
    13.31974,
    240,
    25.59
  ],
  [
    16889,
    65.32621,
    13.3197,
    239.4,
    25.57
  ],
  [
    16891,
    65.32666,
    13.31977,
    238.9,
    25.57
  ],
  [
    16893,
    65.32712,
    13.31995,
    238.4,
    25.49
  ],
  [
    16895,
    65.32756,
    13.32025,
    237.9,
    25.59
  ],
  [
    16897,
    65.32798,
    13.32065,
    237.5,
    25.71
  ],
  [
    16899,
    65.32839,
    13.32116,
    237.1,
    25.83
  ],
  [
    16901,
    65.32878,
    13.32177,
    236.7,
    26.01
  ],
  [
    16903,
    65.32914,
    13.32248,
    236.3,
    26.09
  ],
  [
    16905,
    65.32949,
    13.32325,
    235.8,
    26.17
  ],
  [
    16907,
    65.32983,
    13.32401,
    235.1,
    26.3
  ],
  [
    16909,
    65.33021,
    13.3247,
    234.5,
    26.31
  ],
  [
    16911,
    65.33061,
    13.3253,
    234.1,
    26.31
  ],
  [
    16913,
    65.33103,
    13.32579,
    233.7,
    26.28
  ],
  [
    16915,
    65.33147,
    13.32618,
    233.3,
    26.22
  ],
  [
    16917,
    65.33193,
    13.32645,
    232.8,
    26.18
  ],
  [
    16919,
    65.33239,
    13.3266,
    232.5,
    26.15
  ],
  [
    16921,
    65.33286,
    13.32664,
    232.1,
    26.1
  ],
  [
    16923,
    65.33333,
    13.32661,
    231.5,
    26.1
  ],
  [
    16925,
    65.3338,
    13.32659,
    230.8,
    26.08
  ],
  [
    16927,
    65.33426,
    13.32667,
    230.2,
    26.07
  ],
  [
    16929,
    65.33472,
    13.32687,
    229.6,
    26
  ],
  [
    16931,
    65.33517,
    13.32718,
    228.8,
    25.94
  ],
  [
    16933,
    65.33561,
    13.32754,
    228.4,
    25.9
  ],
  [
    16935,
    65.33599,
    13.32814,
    227.9,
    25.57
  ],
  [
    16937,
    65.33638,
    13.32876,
    227.6,
    25.89
  ],
  [
    16939,
    65.33676,
    13.32941,
    226.8,
    25.82
  ],
  [
    16941,
    65.33715,
    13.33,
    225.8,
    25.79
  ],
  [
    16943,
    65.33756,
    13.33051,
    225.2,
    25.76
  ],
  [
    16945,
    65.33798,
    13.33095,
    224.5,
    25.88
  ],
  [
    16947,
    65.33842,
    13.33137,
    223.8,
    25.99
  ],
  [
    16949,
    65.33885,
    13.33178,
    223.1,
    26.16
  ],
  [
    16951,
    65.33929,
    13.33219,
    222.4,
    26.34
  ],
  [
    16953,
    65.33973,
    13.33261,
    221.7,
    26.5
  ],
  [
    16955,
    65.34017,
    13.33303,
    221.2,
    26.67
  ],
  [
    16957,
    65.34061,
    13.33348,
    220.6,
    26.84
  ],
  [
    16959,
    65.34105,
    13.33394,
    220.3,
    26.77
  ],
  [
    16961,
    65.34148,
    13.33444,
    220.3,
    26.62
  ],
  [
    16963,
    65.34191,
    13.33496,
    220.3,
    26.48
  ],
  [
    16965,
    65.34232,
    13.3355,
    220.3,
    26.36
  ],
  [
    16967,
    65.34273,
    13.33607,
    217.3,
    26.22
  ],
  [
    16969,
    65.34313,
    13.33664,
    216.6,
    26.08
  ],
  [
    16971,
    65.34354,
    13.3372,
    216,
    25.92
  ],
  [
    16973,
    65.34393,
    13.33777,
    215.4,
    25.77
  ],
  [
    16975,
    65.34433,
    13.33833,
    214.7,
    25.62
  ],
  [
    16978,
    65.34493,
    13.33915,
    213.7,
    25.62
  ],
  [
    16980,
    65.34534,
    13.33962,
    213.1,
    25.6
  ],
  [
    16982,
    65.34577,
    13.33999,
    212.4,
    25.58
  ],
  [
    16984,
    65.34622,
    13.34026,
    211.8,
    25.57
  ],
  [
    16986,
    65.34667,
    13.34042,
    211.2,
    25.57
  ],
  [
    16988,
    65.34713,
    13.34048,
    210.5,
    25.85
  ],
  [
    16990,
    65.3476,
    13.34049,
    209.7,
    25.85
  ],
  [
    16992,
    65.34807,
    13.34046,
    208.9,
    25.85
  ],
  [
    16994,
    65.34854,
    13.34039,
    208.3,
    26.19
  ],
  [
    16996,
    65.34901,
    13.34027,
    207.7,
    26.19
  ],
  [
    16998,
    65.34948,
    13.34011,
    207,
    26.58
  ],
  [
    17000,
    65.34995,
    13.33991,
    206.1,
    26.58
  ],
  [
    17002,
    65.35042,
    13.33966,
    205.5,
    26.62
  ],
  [
    17004,
    65.35089,
    13.3394,
    204.7,
    26.58
  ],
  [
    17006,
    65.35135,
    13.33919,
    204,
    26.43
  ],
  [
    17008,
    65.35182,
    13.33907,
    203.4,
    26.26
  ],
  [
    17010,
    65.35229,
    13.33905,
    202.8,
    26.01
  ],
  [
    17012,
    65.35275,
    13.33912,
    202.2,
    25.93
  ],
  [
    17014,
    65.35321,
    13.33924,
    201.6,
    25.64
  ],
  [
    17016,
    65.35367,
    13.33936,
    201.1,
    25.49
  ],
  [
    17018,
    65.35412,
    13.33948,
    200.6,
    25.34
  ],
  [
    17020,
    65.35457,
    13.3396,
    199.9,
    25.3
  ],
  [
    17022,
    65.35502,
    13.33975,
    199.3,
    25.31
  ],
  [
    17024,
    65.35547,
    13.33995,
    198.6,
    25.33
  ],
  [
    17026,
    65.35591,
    13.3402,
    198,
    25.32
  ],
  [
    17028,
    65.35635,
    13.34047,
    197.4,
    25.3
  ],
  [
    17030,
    65.35679,
    13.3407,
    196.9,
    25.28
  ],
  [
    17032,
    65.35724,
    13.34084,
    196.5,
    25.26
  ],
  [
    17034,
    65.35769,
    13.34089,
    195.9,
    25.23
  ],
  [
    17036,
    65.35814,
    13.34084,
    195.3,
    25.21
  ],
  [
    17038,
    65.35859,
    13.34073,
    194.9,
    25.23
  ],
  [
    17040,
    65.35904,
    13.34061,
    194.3,
    25.22
  ],
  [
    17042,
    65.35949,
    13.34051,
    193.6,
    25.23
  ],
  [
    17044,
    65.35994,
    13.34043,
    192.9,
    25.22
  ],
  [
    17046,
    65.3604,
    13.34038,
    192.2,
    25.24
  ],
  [
    17048,
    65.36085,
    13.34036,
    191.6,
    25.24
  ],
  [
    17050,
    65.3613,
    13.34037,
    191.1,
    25.26
  ],
  [
    17052,
    65.36175,
    13.34044,
    190.6,
    25.28
  ],
  [
    17054,
    65.3622,
    13.3406,
    190.2,
    24.87
  ],
  [
    17056,
    65.36264,
    13.34082,
    190,
    24.87
  ],
  [
    17058,
    65.36307,
    13.34104,
    189.8,
    24.87
  ],
  [
    17069,
    65.36501,
    13.34451,
    188.2,
    24.87
  ],
  [
    17071,
    65.36538,
    13.34504,
    188.1,
    23.75
  ],
  [
    17073,
    65.36577,
    13.34547,
    187,
    23.7
  ],
  [
    17075,
    65.36616,
    13.34579,
    186.4,
    23.65
  ],
  [
    17077,
    65.36657,
    13.34605,
    186.2,
    23.62
  ],
  [
    17079,
    65.367,
    13.34622,
    185.8,
    23.73
  ],
  [
    17081,
    65.36742,
    13.34637,
    185.7,
    23.89
  ],
  [
    17083,
    65.36784,
    13.34655,
    185.3,
    24.05
  ],
  [
    17085,
    65.36827,
    13.34678,
    185,
    24.19
  ],
  [
    17087,
    65.36869,
    13.34707,
    184.8,
    24.29
  ],
  [
    17089,
    65.36911,
    13.3474,
    184.5,
    24.53
  ],
  [
    17091,
    65.36952,
    13.34779,
    184.2,
    24.7
  ],
  [
    17093,
    65.36992,
    13.34823,
    183.7,
    24.86
  ],
  [
    17095,
    65.37032,
    13.34872,
    183.3,
    25.01
  ],
  [
    17097,
    65.37071,
    13.34926,
    183,
    25.1
  ],
  [
    17099,
    65.37109,
    13.34985,
    182.5,
    25.32
  ],
  [
    17101,
    65.37146,
    13.35049,
    181.9,
    25.44
  ],
  [
    17103,
    65.37181,
    13.35118,
    181.2,
    25.58
  ],
  [
    17105,
    65.37216,
    13.35192,
    180.6,
    25.72
  ],
  [
    17107,
    65.3725,
    13.35267,
    180,
    25.8
  ],
  [
    17109,
    65.37285,
    13.35339,
    179.3,
    25.97
  ],
  [
    17111,
    65.37324,
    13.35402,
    178.7,
    26.1
  ],
  [
    17113,
    65.37365,
    13.35456,
    178.2,
    26.24
  ],
  [
    17115,
    65.37409,
    13.35498,
    177.7,
    26.36
  ],
  [
    17117,
    65.37454,
    13.35534,
    177.2,
    26.52
  ],
  [
    17119,
    65.37499,
    13.35567,
    176.8,
    26.66
  ],
  [
    17121,
    65.37545,
    13.35601,
    176.7,
    26.83
  ],
  [
    17123,
    65.37591,
    13.35634,
    176.3,
    26.73
  ],
  [
    17125,
    65.37637,
    13.35668,
    176.2,
    26.58
  ],
  [
    17127,
    65.37683,
    13.35701,
    176.1,
    26.37
  ],
  [
    17129,
    65.37728,
    13.35734,
    176,
    26.13
  ],
  [
    17131,
    65.37772,
    13.35766,
    175.7,
    25.8
  ],
  [
    17133,
    65.37816,
    13.35798,
    175.4,
    25.46
  ],
  [
    17135,
    65.37859,
    13.3583,
    175.2,
    25.13
  ],
  [
    17137,
    65.37902,
    13.35863,
    175,
    24.85
  ],
  [
    17139,
    65.37944,
    13.35897,
    174.7,
    24.67
  ],
  [
    17141,
    65.37985,
    13.35932,
    174.6,
    24.54
  ],
  [
    17143,
    65.38026,
    13.35968,
    174.3,
    24.43
  ],
  [
    17145,
    65.38067,
    13.36005,
    174,
    24.34
  ],
  [
    17147,
    65.38108,
    13.36042,
    173.6,
    24.32
  ],
  [
    17149,
    65.38149,
    13.36079,
    173,
    24.3
  ],
  [
    17151,
    65.38189,
    13.36116,
    172.3,
    24.32
  ],
  [
    17153,
    65.3823,
    13.36153,
    171.7,
    24.31
  ],
  [
    17155,
    65.38271,
    13.36189,
    171.2,
    24.33
  ],
  [
    17157,
    65.38312,
    13.36221,
    170.6,
    24.18
  ],
  [
    17159,
    65.38354,
    13.3625,
    170.1,
    23.95
  ],
  [
    17161,
    65.38395,
    13.36276,
    169.3,
    23.78
  ],
  [
    17163,
    65.38436,
    13.36298,
    168.5,
    23.63
  ],
  [
    17165,
    65.38478,
    13.36316,
    167.8,
    23.47
  ],
  [
    17167,
    65.38519,
    13.36329,
    167.3,
    23.28
  ],
  [
    17169,
    65.38561,
    13.3633,
    166.9,
    23.08
  ],
  [
    17171,
    65.38602,
    13.3632,
    166.7,
    22.9
  ],
  [
    17173,
    65.38642,
    13.36299,
    166.8,
    22.74
  ],
  [
    17175,
    65.38681,
    13.36268,
    166.9,
    22.83
  ],
  [
    17177,
    65.38719,
    13.36229,
    167,
    23.35
  ],
  [
    17179,
    65.38757,
    13.36181,
    167,
    24.47
  ],
  [
    17181,
    65.38792,
    13.3616,
    168.2,
    18.08
  ],
  [
    17183,
    65.38834,
    13.36139,
    168.1,
    23.39
  ],
  [
    17185,
    65.38878,
    13.36129,
    168.1,
    23.51
  ],
  [
    17187,
    65.38921,
    13.36132,
    168.1,
    23.58
  ],
  [
    17189,
    65.38964,
    13.36148,
    168.1,
    23.7
  ],
  [
    17191,
    65.39005,
    13.36176,
    167.8,
    23.78
  ],
  [
    17193,
    65.39045,
    13.36217,
    166.8,
    23.84
  ],
  [
    17195,
    65.39082,
    13.36269,
    165.2,
    23.98
  ],
  [
    17197,
    65.39117,
    13.36333,
    163.4,
    24.11
  ],
  [
    17200,
    65.39165,
    13.3644,
    162,
    24.14
  ],
  [
    17202,
    65.39196,
    13.36514,
    161.2,
    24.14
  ],
  [
    17204,
    65.39227,
    13.36589,
    160.1,
    24.3
  ],
  [
    17206,
    65.39258,
    13.36663,
    159.2,
    24.3
  ],
  [
    17208,
    65.3929,
    13.36737,
    158.3,
    24.51
  ],
  [
    17210,
    65.39321,
    13.36811,
    157.3,
    24.51
  ],
  [
    17212,
    65.39353,
    13.36886,
    156.3,
    24.51
  ],
  [
    17214,
    65.39385,
    13.36962,
    155.3,
    24.83
  ],
  [
    17216,
    65.39418,
    13.37035,
    154.4,
    24.83
  ],
  [
    17218,
    65.39453,
    13.37103,
    153.9,
    25.14
  ],
  [
    17220,
    65.39491,
    13.37161,
    153.3,
    25.22
  ],
  [
    17222,
    65.39532,
    13.3721,
    152.7,
    25.28
  ],
  [
    17224,
    65.39574,
    13.37252,
    152.3,
    25.38
  ],
  [
    17226,
    65.39616,
    13.37293,
    151.9,
    25.49
  ],
  [
    17228,
    65.39658,
    13.37335,
    151.6,
    25.58
  ],
  [
    17230,
    65.39701,
    13.37376,
    151.2,
    25.68
  ],
  [
    17232,
    65.39744,
    13.37418,
    150.8,
    25.8
  ],
  [
    17234,
    65.39787,
    13.3746,
    150.4,
    25.96
  ],
  [
    17236,
    65.39831,
    13.37502,
    150.1,
    26.1
  ],
  [
    17238,
    65.39874,
    13.37545,
    150.1,
    26.16
  ],
  [
    17240,
    65.39918,
    13.37587,
    150.3,
    26.23
  ],
  [
    17242,
    65.39961,
    13.3763,
    150.5,
    26.21
  ],
  [
    17244,
    65.40005,
    13.37672,
    150.7,
    26.19
  ],
  [
    17246,
    65.40048,
    13.37715,
    150.7,
    26.14
  ],
  [
    17248,
    65.40092,
    13.37757,
    150.7,
    26.13
  ],
  [
    17250,
    65.40135,
    13.37803,
    150.7,
    26.19
  ],
  [
    17252,
    65.40177,
    13.37853,
    150.5,
    26.21
  ],
  [
    17254,
    65.40218,
    13.37908,
    150.3,
    26.27
  ],
  [
    17256,
    65.40259,
    13.37964,
    149.7,
    26.32
  ],
  [
    17258,
    65.40301,
    13.38015,
    149.3,
    26.37
  ],
  [
    17260,
    65.40345,
    13.38056,
    149.1,
    26.44
  ],
  [
    17262,
    65.40391,
    13.38088,
    148.5,
    26.51
  ],
  [
    17264,
    65.40437,
    13.38116,
    147.9,
    26.63
  ],
  [
    17266,
    65.40484,
    13.3814,
    147.4,
    26.75
  ],
  [
    17268,
    65.40531,
    13.38162,
    147.1,
    26.74
  ],
  [
    17270,
    65.40578,
    13.38181,
    147,
    26.49
  ],
  [
    17272,
    65.40625,
    13.38196,
    147.2,
    26.16
  ],
  [
    17274,
    65.40671,
    13.38209,
    147.2,
    25.78
  ],
  [
    17276,
    65.40717,
    13.3822,
    147,
    25.4
  ],
  [
    17278,
    65.40762,
    13.38231,
    146.7,
    24.99
  ],
  [
    17280,
    65.40806,
    13.38241,
    146.2,
    24.63
  ],
  [
    17282,
    65.4085,
    13.38252,
    145.8,
    24.3
  ],
  [
    17284,
    65.40893,
    13.38263,
    145.2,
    24.01
  ],
  [
    17286,
    65.40935,
    13.38273,
    144.6,
    23.71
  ],
  [
    17288,
    65.40977,
    13.38283,
    144.1,
    23.44
  ],
  [
    17290,
    65.41019,
    13.38293,
    143.6,
    23.16
  ],
  [
    17292,
    65.4106,
    13.38304,
    143.2,
    22.85
  ],
  [
    17294,
    65.411,
    13.38315,
    143,
    22.52
  ],
  [
    17296,
    65.4114,
    13.38329,
    142.8,
    22.14
  ],
  [
    17298,
    65.41178,
    13.38345,
    142.8,
    21.7
  ],
  [
    17300,
    65.41216,
    13.38363,
    142.9,
    21.22
  ],
  [
    17302,
    65.41253,
    13.38383,
    143.1,
    20.73
  ],
  [
    17304,
    65.41288,
    13.38406,
    143.5,
    20.26
  ],
  [
    17306,
    65.41323,
    13.3843,
    143.8,
    19.91
  ],
  [
    17308,
    65.41357,
    13.38455,
    144,
    19.58
  ],
  [
    17310,
    65.4139,
    13.3848,
    144.1,
    19.16
  ],
  [
    17312,
    65.41422,
    13.38506,
    144.3,
    18.78
  ],
  [
    17314,
    65.41453,
    13.3853,
    144.7,
    18.38
  ],
  [
    17316,
    65.41485,
    13.38554,
    145.2,
    18.07
  ],
  [
    17318,
    65.41515,
    13.38577,
    145.6,
    17.75
  ],
  [
    17320,
    65.41545,
    13.386,
    145.9,
    17.6
  ],
  [
    17322,
    65.41575,
    13.38623,
    146.2,
    17.52
  ],
  [
    17324,
    65.41605,
    13.38645,
    146.5,
    17.45
  ],
  [
    17326,
    65.41635,
    13.38666,
    146.7,
    17.4
  ],
  [
    17328,
    65.41665,
    13.38686,
    146.9,
    17.38
  ],
  [
    17330,
    65.41695,
    13.38704,
    146.8,
    17.32
  ],
  [
    17332,
    65.41725,
    13.3872,
    146.6,
    17.27
  ],
  [
    17334,
    65.41755,
    13.38734,
    146.5,
    17.26
  ],
  [
    17336,
    65.41786,
    13.38747,
    146.5,
    17.23
  ],
  [
    17338,
    65.41816,
    13.38758,
    146.5,
    17.14
  ],
  [
    17340,
    65.41846,
    13.38766,
    146.5,
    17.06
  ],
  [
    17342,
    65.41877,
    13.38769,
    146.5,
    16.95
  ],
  [
    17344,
    65.41907,
    13.38769,
    146,
    16.81
  ],
  [
    17346,
    65.41937,
    13.38763,
    146.1,
    16.68
  ],
  [
    17348,
    65.41966,
    13.38754,
    146.6,
    16.49
  ],
  [
    17350,
    65.41995,
    13.38743,
    147.3,
    16.4
  ],
  [
    17352,
    65.42025,
    13.38733,
    147.8,
    16.31
  ],
  [
    17354,
    65.42054,
    13.38726,
    148,
    16.18
  ],
  [
    17357,
    65.42097,
    13.38724,
    147.9,
    16.09
  ],
  [
    17359,
    65.42125,
    13.38727,
    147.5,
    16.1
  ],
  [
    17361,
    65.42154,
    13.38736,
    147,
    16.13
  ],
  [
    17363,
    65.42182,
    13.3875,
    146.5,
    16.2
  ],
  [
    17365,
    65.4221,
    13.38768,
    146.3,
    16.2
  ],
  [
    17367,
    65.42238,
    13.38787,
    146.4,
    16.2
  ],
  [
    17369,
    65.42267,
    13.38807,
    146.6,
    16.45
  ],
  [
    17371,
    65.42296,
    13.38824,
    147.2,
    16.45
  ],
  [
    17373,
    65.42325,
    13.38837,
    147.9,
    16.63
  ],
  [
    17375,
    65.42355,
    13.38846,
    148.7,
    16.63
  ],
  [
    17377,
    65.42384,
    13.3885,
    149.2,
    16.5
  ],
  [
    17379,
    65.42414,
    13.38849,
    149.7,
    16.34
  ],
  [
    17381,
    65.42443,
    13.38844,
    150.3,
    16.23
  ],
  [
    17383,
    65.42472,
    13.38838,
    150.7,
    16.14
  ],
  [
    17385,
    65.425,
    13.38831,
    151,
    16.04
  ],
  [
    17387,
    65.42529,
    13.38825,
    151.5,
    16
  ],
  [
    17389,
    65.42558,
    13.38818,
    152.3,
    15.95
  ],
  [
    17391,
    65.42586,
    13.38813,
    152.5,
    15.96
  ],
  [
    17393,
    65.42615,
    13.38811,
    153.3,
    15.99
  ],
  [
    17395,
    65.42644,
    13.38813,
    153.9,
    16.02
  ],
  [
    17397,
    65.42673,
    13.38819,
    154.4,
    16
  ],
  [
    17399,
    65.42701,
    13.3883,
    154.9,
    16
  ],
  [
    17401,
    65.42729,
    13.38845,
    154.9,
    16
  ],
  [
    17403,
    65.42757,
    13.38865,
    155.2,
    16.02
  ],
  [
    17405,
    65.42784,
    13.38889,
    155.6,
    16.06
  ],
  [
    17407,
    65.4281,
    13.38917,
    156,
    16.09
  ],
  [
    17409,
    65.42836,
    13.38949,
    156.3,
    16.17
  ],
  [
    17411,
    65.42861,
    13.38985,
    156.6,
    16.19
  ],
  [
    17413,
    65.42885,
    13.39025,
    156.9,
    16.22
  ],
  [
    17415,
    65.42908,
    13.39068,
    157.1,
    16.24
  ],
  [
    17417,
    65.4293,
    13.39113,
    157.3,
    16.23
  ],
  [
    17419,
    65.42952,
    13.39159,
    157.6,
    16.21
  ],
  [
    17421,
    65.42975,
    13.39202,
    158.5,
    16.2
  ],
  [
    17423,
    65.42999,
    13.39243,
    159.2,
    16.16
  ],
  [
    17425,
    65.43024,
    13.39279,
    159.5,
    16.15
  ],
  [
    17427,
    65.43049,
    13.39312,
    159.3,
    16.13
  ],
  [
    17429,
    65.43075,
    13.39341,
    158.9,
    16.12
  ],
  [
    17431,
    65.43102,
    13.39365,
    158.6,
    16.15
  ],
  [
    17433,
    65.4313,
    13.39385,
    158.3,
    16.21
  ],
  [
    17435,
    65.43158,
    13.39401,
    158.1,
    16.3
  ],
  [
    17437,
    65.43187,
    13.39414,
    157.9,
    16.44
  ],
  [
    17439,
    65.43216,
    13.39426,
    157.7,
    16.59
  ],
  [
    17441,
    65.43246,
    13.39437,
    157.6,
    16.74
  ],
  [
    17443,
    65.43276,
    13.39448,
    157.5,
    16.89
  ],
  [
    17445,
    65.43306,
    13.3946,
    157.5,
    17.04
  ],
  [
    17447,
    65.43336,
    13.39471,
    157.6,
    17.17
  ],
  [
    17449,
    65.43367,
    13.39483,
    157.8,
    17.25
  ],
  [
    17451,
    65.43397,
    13.39495,
    158.1,
    17.3
  ],
  [
    17453,
    65.43428,
    13.39507,
    158.5,
    17.32
  ],
  [
    17455,
    65.43459,
    13.39518,
    158.7,
    17.33
  ],
  [
    17457,
    65.4349,
    13.3953,
    159,
    17.42
  ],
  [
    17459,
    65.43521,
    13.39542,
    159.3,
    17.53
  ],
  [
    17461,
    65.43552,
    13.39554,
    159.9,
    17.66
  ],
  [
    17463,
    65.43584,
    13.39566,
    160.6,
    17.78
  ],
  [
    17465,
    65.43615,
    13.39579,
    161.3,
    17.94
  ],
  [
    17467,
    65.43647,
    13.39591,
    162,
    18.12
  ],
  [
    17469,
    65.4368,
    13.39603,
    162.4,
    18.39
  ],
  [
    17471,
    65.43713,
    13.39616,
    163,
    18.66
  ],
  [
    17473,
    65.43746,
    13.39625,
    163.1,
    18.99
  ],
  [
    17475,
    65.43781,
    13.39628,
    163.3,
    19.34
  ],
  [
    17477,
    65.43816,
    13.39625,
    163.4,
    19.76
  ],
  [
    17479,
    65.43852,
    13.39615,
    163.7,
    20.21
  ],
  [
    17481,
    65.43888,
    13.39597,
    164.2,
    20.69
  ],
  [
    17483,
    65.43924,
    13.39575,
    164.5,
    21.17
  ],
  [
    17485,
    65.43962,
    13.39552,
    164.7,
    21.62
  ],
  [
    17487,
    65.44,
    13.39528,
    165,
    22.09
  ],
  [
    17489,
    65.44039,
    13.39504,
    165.3,
    22.54
  ],
  [
    17491,
    65.44078,
    13.39479,
    165.7,
    22.94
  ],
  [
    17493,
    65.44119,
    13.39453,
    165.7,
    23.37
  ],
  [
    17495,
    65.4416,
    13.39427,
    165.7,
    23.77
  ],
  [
    17497,
    65.44201,
    13.39401,
    166.1,
    24.19
  ],
  [
    17499,
    65.44244,
    13.39375,
    166,
    24.41
  ],
  [
    17502,
    65.44307,
    13.39335,
    166,
    24.48
  ],
  [
    17504,
    65.4435,
    13.39308,
    166,
    24.53
  ],
  [
    17506,
    65.44393,
    13.39281,
    166.2,
    24.56
  ],
  [
    17508,
    65.44435,
    13.39254,
    166.4,
    24.57
  ],
  [
    17510,
    65.44478,
    13.39227,
    166.6,
    24.57
  ],
  [
    17512,
    65.4452,
    13.39201,
    167.1,
    24.57
  ],
  [
    17514,
    65.44563,
    13.39174,
    167.8,
    24.36
  ],
  [
    17516,
    65.44604,
    13.39148,
    168.5,
    24.36
  ],
  [
    17518,
    65.44646,
    13.39122,
    169,
    23.86
  ],
  [
    17520,
    65.44687,
    13.39094,
    169.3,
    23.86
  ],
  [
    17522,
    65.44727,
    13.39064,
    169.4,
    23.86
  ],
  [
    17524,
    65.44766,
    13.39031,
    169.6,
    23.29
  ],
  [
    17526,
    65.44806,
    13.38999,
    169.6,
    23.23
  ],
  [
    17528,
    65.44846,
    13.38971,
    169.7,
    23.2
  ],
  [
    17530,
    65.44886,
    13.38952,
    169.7,
    23.15
  ],
  [
    17532,
    65.44927,
    13.38942,
    169.8,
    23.11
  ],
  [
    17534,
    65.44969,
    13.38937,
    169.7,
    23.07
  ],
  [
    17536,
    65.4501,
    13.38928,
    169.6,
    23.02
  ],
  [
    17538,
    65.4505,
    13.38911,
    169.6,
    23.02
  ],
  [
    17540,
    65.4509,
    13.38882,
    169.2,
    22.93
  ],
  [
    17542,
    65.45127,
    13.38842,
    169,
    22.97
  ],
  [
    17544,
    65.45163,
    13.38793,
    168.8,
    23
  ],
  [
    17546,
    65.45198,
    13.3874,
    168.5,
    23.04
  ],
  [
    17548,
    65.45234,
    13.3869,
    168.2,
    23.1
  ],
  [
    17550,
    65.45271,
    13.38649,
    168,
    23.11
  ],
  [
    17552,
    65.45311,
    13.38618,
    167.8,
    23.12
  ],
  [
    17554,
    65.45351,
    13.38598,
    165.3,
    23.1
  ],
  [
    17556,
    65.45391,
    13.38589,
    163.6,
    23.05
  ],
  [
    17558,
    65.45432,
    13.38592,
    162.4,
    23.01
  ],
  [
    17560,
    65.45473,
    13.38607,
    161.5,
    22.96
  ],
  [
    17562,
    65.45512,
    13.38633,
    160.7,
    22.93
  ],
  [
    17564,
    65.4555,
    13.3867,
    160,
    22.83
  ],
  [
    17566,
    65.45585,
    13.38717,
    159.7,
    22.78
  ],
  [
    17568,
    65.45619,
    13.38772,
    158.6,
    22.72
  ],
  [
    17570,
    65.45652,
    13.38829,
    157.5,
    22.67
  ],
  [
    17572,
    65.45685,
    13.38883,
    156.5,
    22.64
  ],
  [
    17574,
    65.4572,
    13.38931,
    155.6,
    22.64
  ],
  [
    17576,
    65.45757,
    13.38972,
    154.8,
    22.58
  ],
  [
    17578,
    65.45794,
    13.39007,
    154.1,
    22.56
  ],
  [
    17580,
    65.45833,
    13.39039,
    153.6,
    22.56
  ],
  [
    17582,
    65.45871,
    13.3907,
    153,
    22.5
  ],
  [
    17584,
    65.45909,
    13.39101,
    152.4,
    22.45
  ],
  [
    17586,
    65.45947,
    13.39132,
    151.9,
    22.43
  ],
  [
    17588,
    65.45985,
    13.39162,
    151.4,
    22.43
  ],
  [
    17590,
    65.46023,
    13.39193,
    151.3,
    22.43
  ],
  [
    17592,
    65.46061,
    13.39224,
    150.8,
    22.45
  ],
  [
    17594,
    65.46099,
    13.39255,
    150.3,
    22.47
  ],
  [
    17596,
    65.46137,
    13.39286,
    149.7,
    22.52
  ],
  [
    17598,
    65.46175,
    13.39317,
    149,
    22.59
  ],
  [
    17600,
    65.46213,
    13.39349,
    148.4,
    22.68
  ],
  [
    17602,
    65.4625,
    13.39387,
    147.7,
    22.77
  ],
  [
    17604,
    65.46287,
    13.39431,
    147,
    22.86
  ],
  [
    17606,
    65.46322,
    13.39482,
    146.2,
    22.93
  ],
  [
    17608,
    65.46356,
    13.39536,
    145.4,
    23.01
  ],
  [
    17610,
    65.46391,
    13.3959,
    144.7,
    23.1
  ],
  [
    17612,
    65.46425,
    13.39645,
    144.2,
    23.12
  ],
  [
    17614,
    65.4646,
    13.39699,
    143.8,
    23.15
  ],
  [
    17616,
    65.46495,
    13.39754,
    143.6,
    23.11
  ],
  [
    17618,
    65.46529,
    13.39808,
    143.4,
    23.01
  ],
  [
    17620,
    65.46564,
    13.39862,
    143.4,
    22.92
  ],
  [
    17622,
    65.46599,
    13.39911,
    143.4,
    22.77
  ],
  [
    17624,
    65.46636,
    13.39953,
    143.3,
    22.65
  ],
  [
    17626,
    65.46674,
    13.39986,
    143.1,
    22.51
  ],
  [
    17628,
    65.46713,
    13.4001,
    142.9,
    22.42
  ],
  [
    17630,
    65.46752,
    13.40025,
    142.7,
    22.37
  ],
  [
    17632,
    65.46792,
    13.40034,
    142.6,
    22.4
  ],
  [
    17634,
    65.46832,
    13.40039,
    142.5,
    22.41
  ],
  [
    17636,
    65.46872,
    13.40044,
    142.2,
    22.44
  ],
  [
    17638,
    65.46913,
    13.40049,
    142,
    22.49
  ],
  [
    17640,
    65.46953,
    13.40053,
    141.7,
    22.53
  ],
  [
    17642,
    65.46993,
    13.40058,
    141.4,
    22.55
  ],
  [
    17644,
    65.47034,
    13.40063,
    141.2,
    22.66
  ],
  [
    17646,
    65.47074,
    13.40068,
    141.1,
    22.7
  ],
  [
    17648,
    65.47115,
    13.4007,
    141.1,
    22.72
  ],
  [
    17650,
    65.47155,
    13.40067,
    141.1,
    22.72
  ],
  [
    17652,
    65.47196,
    13.40055,
    140.8,
    22.73
  ],
  [
    17654,
    65.47236,
    13.40037,
    141.5,
    22.79
  ],
  [
    17656,
    65.47277,
    13.40021,
    142.3,
    22.81
  ],
  [
    17658,
    65.47318,
    13.40015,
    142.8,
    22.77
  ],
  [
    17660,
    65.47359,
    13.40022,
    143.5,
    22.77
  ],
  [
    17663,
    65.47418,
    13.40054,
    143.4,
    22.72
  ],
  [
    17665,
    65.47456,
    13.40089,
    143.4,
    22.66
  ],
  [
    17667,
    65.47493,
    13.40124,
    143.1,
    22.57
  ],
  [
    17669,
    65.47532,
    13.40151,
    143,
    22.52
  ],
  [
    17671,
    65.47572,
    13.40165,
    142.6,
    22.52
  ],
  [
    17673,
    65.47612,
    13.40164,
    142.3,
    22.38
  ],
  [
    17675,
    65.47651,
    13.40149,
    142.8,
    22.38
  ],
  [
    17677,
    65.4769,
    13.4012,
    143.2,
    22.35
  ],
  [
    17679,
    65.47727,
    13.40085,
    143.4,
    22.42
  ],
  [
    17681,
    65.47765,
    13.40051,
    143.5,
    22.5
  ],
  [
    17683,
    65.47805,
    13.40029,
    143.6,
    22.72
  ],
  [
    17685,
    65.47845,
    13.40021,
    143.8,
    22.74
  ],
  [
    17687,
    65.47886,
    13.40028,
    143.8,
    22.78
  ],
  [
    17689,
    65.47927,
    13.40041,
    143.7,
    22.91
  ],
  [
    17691,
    65.47968,
    13.40052,
    143.5,
    23.06
  ],
  [
    17693,
    65.48009,
    13.40054,
    143.4,
    23.17
  ],
  [
    17695,
    65.48051,
    13.40044,
    143.3,
    23.32
  ],
  [
    17697,
    65.48093,
    13.4003,
    143.4,
    23.41
  ],
  [
    17699,
    65.48135,
    13.40017,
    143.4,
    23.68
  ],
  [
    17701,
    65.48177,
    13.40007,
    143.2,
    23.82
  ],
  [
    17703,
    65.4822,
    13.40001,
    142.8,
    23.96
  ],
  [
    17705,
    65.48263,
    13.39999,
    142.4,
    24.06
  ],
  [
    17707,
    65.48307,
    13.4,
    142.4,
    24.04
  ],
  [
    17709,
    65.48349,
    13.40004,
    142.5,
    23.79
  ],
  [
    17711,
    65.48392,
    13.40013,
    142.7,
    23.5
  ],
  [
    17713,
    65.48434,
    13.40024,
    142.8,
    23.26
  ],
  [
    17715,
    65.48475,
    13.40039,
    143,
    23.12
  ],
  [
    17717,
    65.48516,
    13.40057,
    143.2,
    23.05
  ],
  [
    17719,
    65.48555,
    13.40078,
    142,
    22.77
  ],
  [
    17721,
    65.48594,
    13.40102,
    141.1,
    22.63
  ],
  [
    17723,
    65.48633,
    13.40128,
    140.4,
    22.56
  ],
  [
    17725,
    65.48671,
    13.40158,
    139.7,
    22.53
  ],
  [
    17727,
    65.48709,
    13.4019,
    139.9,
    22.48
  ],
  [
    17729,
    65.48747,
    13.40221,
    139.6,
    22.4
  ],
  [
    17731,
    65.48786,
    13.40248,
    139.2,
    22.36
  ],
  [
    17733,
    65.48825,
    13.40269,
    138.8,
    22.25
  ],
  [
    17735,
    65.48864,
    13.40284,
    139.5,
    22.68
  ],
  [
    17737,
    65.48904,
    13.40298,
    139.8,
    22.68
  ],
  [
    17739,
    65.48945,
    13.40311,
    140.1,
    22.68
  ],
  [
    17757,
    65.49259,
    13.39892,
    141.6,
    23.09
  ],
  [
    17759,
    65.49286,
    13.39818,
    137.5,
    23.15
  ],
  [
    17761,
    65.49315,
    13.39746,
    135.7,
    23.33
  ],
  [
    17763,
    65.49346,
    13.3968,
    132.4,
    23.35
  ],
  [
    17765,
    65.49381,
    13.39624,
    129.4,
    23.29
  ],
  [
    17767,
    65.49419,
    13.39584,
    127.9,
    23.25
  ],
  [
    17769,
    65.4946,
    13.39558,
    127.1,
    23.19
  ],
  [
    17771,
    65.49502,
    13.39541,
    126.6,
    23.21
  ],
  [
    17773,
    65.49544,
    13.39527,
    126.3,
    23.32
  ],
  [
    17775,
    65.49585,
    13.39508,
    126.2,
    23.53
  ],
  [
    17777,
    65.49626,
    13.39479,
    126,
    23.61
  ],
  [
    17779,
    65.49665,
    13.39439,
    125.8,
    23.7
  ],
  [
    17781,
    65.49702,
    13.39388,
    125.8,
    23.95
  ],
  [
    17783,
    65.49738,
    13.39333,
    125.8,
    23.96
  ],
  [
    17785,
    65.49776,
    13.39283,
    123.6,
    23.76
  ],
  [
    17787,
    65.49815,
    13.39243,
    123.1,
    23.41
  ],
  [
    17789,
    65.49855,
    13.39215,
    122.9,
    23.28
  ],
  [
    17791,
    65.49897,
    13.39199,
    122.6,
    23.25
  ],
  [
    17793,
    65.49938,
    13.39195,
    122.3,
    23.28
  ],
  [
    17796,
    65.50001,
    13.39204,
    122,
    23.35
  ],
  [
    17798,
    65.50043,
    13.39212,
    121.8,
    23.43
  ],
  [
    17800,
    65.50085,
    13.3922,
    121.4,
    23.52
  ],
  [
    17802,
    65.50128,
    13.39224,
    121.1,
    23.57
  ],
  [
    17804,
    65.5017,
    13.39221,
    120.6,
    23.68
  ],
  [
    17806,
    65.50213,
    13.39211,
    120.4,
    23.68
  ],
  [
    17808,
    65.50255,
    13.39198,
    120.2,
    23.84
  ],
  [
    17810,
    65.50298,
    13.39191,
    120.4,
    23.84
  ],
  [
    17812,
    65.50341,
    13.39195,
    120.4,
    23.84
  ],
  [
    17814,
    65.50383,
    13.3921,
    120.9,
    23.62
  ],
  [
    17816,
    65.50424,
    13.39228,
    121.2,
    23.42
  ],
  [
    17818,
    65.50466,
    13.39245,
    121.2,
    23.2
  ],
  [
    17820,
    65.50507,
    13.39259,
    121.3,
    23.05
  ],
  [
    17822,
    65.50548,
    13.39265,
    121.4,
    23.03
  ],
  [
    17824,
    65.50589,
    13.39259,
    121.5,
    22.9
  ],
  [
    17826,
    65.50629,
    13.39242,
    121.7,
    22.84
  ],
  [
    17828,
    65.50669,
    13.39214,
    121.9,
    22.74
  ],
  [
    17830,
    65.50706,
    13.39177,
    121.5,
    22.66
  ],
  [
    17832,
    65.50742,
    13.39129,
    121.4,
    22.65
  ],
  [
    17834,
    65.50775,
    13.39073,
    120.9,
    22.55
  ],
  [
    17836,
    65.50805,
    13.39008,
    120.5,
    22.48
  ],
  [
    17838,
    65.50832,
    13.38936,
    118.4,
    22.39
  ],
  [
    17840,
    65.50855,
    13.38858,
    118.1,
    22.35
  ],
  [
    17842,
    65.50875,
    13.38773,
    117.1,
    22.3
  ],
  [
    17844,
    65.50892,
    13.38686,
    116.3,
    22.33
  ],
  [
    17846,
    65.50909,
    13.38597,
    115.2,
    22.41
  ],
  [
    17848,
    65.50925,
    13.38507,
    112.4,
    22.57
  ],
  [
    17850,
    65.50942,
    13.38417,
    110.2,
    22.72
  ],
  [
    17852,
    65.50959,
    13.38327,
    108.6,
    22.79
  ],
  [
    17854,
    65.50977,
    13.38237,
    107.4,
    23
  ],
  [
    17856,
    65.50996,
    13.38148,
    106.3,
    23.13
  ],
  [
    17858,
    65.51015,
    13.38059,
    105.6,
    23.28
  ],
  [
    17860,
    65.51035,
    13.3797,
    104.7,
    23.4
  ],
  [
    17862,
    65.51056,
    13.37882,
    104.1,
    23.45
  ],
  [
    17864,
    65.51078,
    13.37795,
    103.6,
    23.49
  ],
  [
    17866,
    65.511,
    13.37708,
    103.4,
    23.47
  ],
  [
    17868,
    65.51122,
    13.37623,
    103.5,
    23.44
  ],
  [
    17870,
    65.51145,
    13.37537,
    103.6,
    23.44
  ],
  [
    17902,
    65.51758,
    13.37203,
    102.3,
    23.44
  ],
  [
    17904,
    65.51786,
    13.37265,
    94.5,
    23.07
  ],
  [
    17906,
    65.51815,
    13.37338,
    92.8,
    22.78
  ],
  [
    17908,
    65.51839,
    13.37421,
    93,
    22.91
  ],
  [
    17910,
    65.51859,
    13.37508,
    92.5,
    22.9
  ],
  [
    17912,
    65.51878,
    13.37596,
    91.8,
    22.9
  ],
  [
    17914,
    65.51897,
    13.37685,
    91.4,
    22.99
  ],
  [
    17916,
    65.51916,
    13.37773,
    91.2,
    23.09
  ],
  [
    17918,
    65.51937,
    13.37859,
    91.3,
    23.21
  ],
  [
    17920,
    65.51962,
    13.37939,
    91.3,
    23.32
  ],
  [
    17922,
    65.51991,
    13.38013,
    91.3,
    23.4
  ],
  [
    17924,
    65.52023,
    13.38078,
    91.3,
    23.6
  ],
  [
    17926,
    65.52059,
    13.38135,
    91.5,
    23.69
  ],
  [
    17928,
    65.52096,
    13.38184,
    91.4,
    23.76
  ],
  [
    17930,
    65.52135,
    13.38229,
    91.3,
    23.79
  ],
  [
    17932,
    65.52173,
    13.38273,
    91.1,
    23.84
  ],
  [
    17934,
    65.52213,
    13.38316,
    90.7,
    23.97
  ],
  [
    17936,
    65.52252,
    13.38357,
    90.7,
    24.11
  ],
  [
    17938,
    65.52293,
    13.38396,
    90.8,
    24.24
  ],
  [
    17940,
    65.52334,
    13.3843,
    91,
    23.92
  ],
  [
    17942,
    65.52375,
    13.3846,
    91.6,
    23.78
  ],
  [
    17944,
    65.52416,
    13.38483,
    92,
    23.43
  ],
  [
    17946,
    65.52458,
    13.38502,
    92.2,
    23.23
  ],
  [
    17948,
    65.52499,
    13.38516,
    92.6,
    23.06
  ],
  [
    17950,
    65.5254,
    13.38525,
    93,
    22.95
  ],
  [
    17952,
    65.52581,
    13.38529,
    92.5,
    22.97
  ],
  [
    17954,
    65.52623,
    13.38529,
    93.1,
    23.09
  ],
  [
    17956,
    65.52665,
    13.38526,
    93.7,
    23.24
  ],
  [
    17958,
    65.52707,
    13.38518,
    94.1,
    23.28
  ],
  [
    17960,
    65.52748,
    13.3851,
    94.6,
    22.91
  ],
  [
    17962,
    65.52789,
    13.38511,
    94.8,
    22.49
  ],
  [
    17964,
    65.52829,
    13.38526,
    94.4,
    22.02
  ],
  [
    17966,
    65.52866,
    13.38553,
    93.6,
    21.66
  ],
  [
    17968,
    65.52902,
    13.38587,
    92.9,
    21.63
  ],
  [
    17970,
    65.52938,
    13.3862,
    92.4,
    21.66
  ],
  [
    17972,
    65.52976,
    13.38645,
    92.2,
    21.71
  ],
  [
    17974,
    65.53014,
    13.38657,
    92.4,
    21.74
  ],
  [
    17976,
    65.53053,
    13.38669,
    92.5,
    21.74
  ],
  [
    17985,
    65.53226,
    13.3852,
    93.5,
    21.87
  ],
  [
    17987,
    65.53257,
    13.38452,
    91.2,
    22.37
  ],
  [
    17989,
    65.53296,
    13.3843,
    90.6,
    21.89
  ],
  [
    17991,
    65.53335,
    13.38423,
    91.8,
    21.29
  ],
  [
    17993,
    65.53371,
    13.38416,
    92.7,
    21.02
  ],
  [
    17995,
    65.53409,
    13.38401,
    92.1,
    20.58
  ],
  [
    17997,
    65.53446,
    13.38407,
    91.8,
    20.26
  ],
  [
    17999,
    65.53483,
    13.38405,
    92.7,
    19.71
  ],
  [
    18001,
    65.53518,
    13.38394,
    93.3,
    19.25
  ],
  [
    18003,
    65.53551,
    13.3838,
    93.6,
    18.8
  ],
  [
    18005,
    65.53584,
    13.38364,
    93.8,
    18.37
  ],
  [
    18007,
    65.53615,
    13.38349,
    94.2,
    18.14
  ],
  [
    18009,
    65.53646,
    13.38335,
    94,
    17.18
  ],
  [
    18011,
    65.53675,
    13.38321,
    93.6,
    16.05
  ],
  [
    18013,
    65.53702,
    13.38308,
    93.3,
    14.79
  ],
  [
    18015,
    65.53726,
    13.38296,
    93.2,
    13.49
  ],
  [
    18017,
    65.53748,
    13.38285,
    93.2,
    12.09
  ],
  [
    18019,
    65.53768,
    13.38276,
    93.2,
    10.66
  ],
  [
    18021,
    65.53785,
    13.38268,
    93.3,
    9.29
  ],
  [
    18023,
    65.538,
    13.38261,
    93.4,
    8.18
  ],
  [
    18025,
    65.53814,
    13.38255,
    93.9,
    7.29
  ],
  [
    18027,
    65.53826,
    13.3825,
    94.3,
    6.4
  ],
  [
    18029,
    65.53836,
    13.38245,
    94.8,
    5.49
  ],
  [
    18031,
    65.53844,
    13.38241,
    95.2,
    4.23
  ],
  [
    18033,
    65.5385,
    13.38239,
    95.7,
    2.6
  ],
  [
    18035,
    65.53853,
    13.38238,
    96,
    0.92
  ],
  [
    18037,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18039,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18041,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18043,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18045,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18047,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18049,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18051,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18053,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18055,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18057,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18059,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18061,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18063,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18065,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18067,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18069,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18071,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18073,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18075,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18077,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18079,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18081,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18083,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18085,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18087,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18090,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18092,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18094,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18096,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18098,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18100,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18102,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18104,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18106,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18108,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18110,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18112,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18114,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18116,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18118,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18120,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18122,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18124,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18126,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18128,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18130,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18132,
    65.53853,
    13.38238,
    96.1,
    0
  ],
  [
    18134,
    65.53854,
    13.3824,
    98.8,
    2.35
  ],
  [
    18136,
    65.5386,
    13.38238,
    99,
    3.36
  ],
  [
    18138,
    65.53866,
    13.38234,
    99.2,
    4.21
  ],
  [
    18140,
    65.53874,
    13.38231,
    99.5,
    4.86
  ],
  [
    18142,
    65.53883,
    13.38226,
    99.8,
    5.15
  ],
  [
    18144,
    65.53893,
    13.38222,
    100.2,
    5.89
  ],
  [
    18146,
    65.53904,
    13.38217,
    100.5,
    6.3
  ],
  [
    18148,
    65.53916,
    13.38212,
    100.8,
    6.68
  ],
  [
    18150,
    65.53928,
    13.38206,
    101.2,
    7.06
  ],
  [
    18152,
    65.53941,
    13.382,
    101.1,
    7.41
  ],
  [
    18154,
    65.53954,
    13.38193,
    100.6,
    7.76
  ],
  [
    18156,
    65.53968,
    13.38186,
    100.1,
    8.16
  ],
  [
    18158,
    65.53983,
    13.38179,
    99.9,
    8.71
  ],
  [
    18160,
    65.53998,
    13.38172,
    100.1,
    9.21
  ],
  [
    18162,
    65.54015,
    13.38165,
    100.4,
    9.2
  ],
  [
    18164,
    65.54031,
    13.38157,
    100.8,
    9.2
  ],
  [
    18169,
    65.54079,
    13.38106,
    101.3,
    11.48
  ],
  [
    18171,
    65.54105,
    13.38119,
    102.1,
    12.46
  ],
  [
    18173,
    65.54129,
    13.38118,
    102.3,
    12.92
  ],
  [
    18175,
    65.54153,
    13.38119,
    102.6,
    13.39
  ],
  [
    18177,
    65.54178,
    13.38125,
    102.9,
    13.8
  ],
  [
    18179,
    65.54203,
    13.38137,
    103.4,
    14.29
  ],
  [
    18181,
    65.54228,
    13.38152,
    103.8,
    14.68
  ],
  [
    18183,
    65.54254,
    13.38167,
    104.4,
    15.09
  ],
  [
    18185,
    65.54281,
    13.38181,
    104.7,
    15.51
  ],
  [
    18187,
    65.54309,
    13.38188,
    105.1,
    15.77
  ],
  [
    18189,
    65.54338,
    13.38188,
    105.7,
    16.14
  ],
  [
    18191,
    65.54367,
    13.38182,
    106.6,
    16.5
  ],
  [
    18193,
    65.54397,
    13.3817,
    106.8,
    16.82
  ],
  [
    18195,
    65.54427,
    13.38155,
    106.9,
    17.17
  ],
  [
    18197,
    65.54458,
    13.38143,
    107.5,
    17.55
  ],
  [
    18199,
    65.5449,
    13.3814,
    108,
    17.85
  ],
  [
    18201,
    65.54522,
    13.38146,
    108.4,
    18.2
  ],
  [
    18203,
    65.54554,
    13.3816,
    108.9,
    18.49
  ],
  [
    18205,
    65.54587,
    13.38179,
    109.7,
    18.76
  ],
  [
    18207,
    65.5462,
    13.38199,
    110.2,
    19.14
  ],
  [
    18209,
    65.54654,
    13.38219,
    110.6,
    19.48
  ],
  [
    18211,
    65.54688,
    13.3824,
    109.3,
    19.74
  ],
  [
    18213,
    65.54722,
    13.38266,
    109.6,
    20.15
  ],
  [
    18215,
    65.54756,
    13.38297,
    109.8,
    20.49
  ],
  [
    18217,
    65.54791,
    13.38333,
    110.4,
    20.82
  ],
  [
    18219,
    65.54825,
    13.38371,
    111.1,
    21.13
  ],
  [
    18221,
    65.5486,
    13.38405,
    111.5,
    21.35
  ],
  [
    18223,
    65.54898,
    13.38428,
    111.9,
    21.67
  ],
  [
    18225,
    65.54936,
    13.38438,
    112,
    21.89
  ],
  [
    18227,
    65.54976,
    13.38438,
    112.4,
    22.14
  ],
  [
    18229,
    65.55016,
    13.38435,
    112.9,
    22.38
  ],
  [
    18231,
    65.55056,
    13.38433,
    113.4,
    22.61
  ],
  [
    18233,
    65.55097,
    13.38434,
    114,
    22.83
  ],
  [
    18235,
    65.55138,
    13.38438,
    114.6,
    22.95
  ],
  [
    18237,
    65.55179,
    13.38444,
    115.3,
    22.84
  ],
  [
    18239,
    65.5522,
    13.38451,
    116,
    22.68
  ],
  [
    18241,
    65.55261,
    13.38459,
    116.6,
    22.54
  ],
  [
    18243,
    65.55301,
    13.38467,
    116.7,
    22.4
  ],
  [
    18245,
    65.5534,
    13.38481,
    117.1,
    22.32
  ],
  [
    18247,
    65.55379,
    13.38505,
    117.1,
    22.18
  ],
  [
    18249,
    65.55416,
    13.38538,
    117.1,
    22.01
  ],
  [
    18251,
    65.55451,
    13.38578,
    116.5,
    21.94
  ],
  [
    18253,
    65.55485,
    13.38623,
    115.2,
    21.75
  ],
  [
    18255,
    65.55519,
    13.38665,
    114.5,
    21.78
  ],
  [
    18257,
    65.55555,
    13.38701,
    113.8,
    21.74
  ],
  [
    18259,
    65.55592,
    13.38728,
    113,
    21.76
  ],
  [
    18262,
    65.55649,
    13.38753,
    111.7,
    21.81
  ],
  [
    18264,
    65.55688,
    13.38757,
    111.1,
    21.89
  ],
  [
    18266,
    65.55727,
    13.38753,
    110.4,
    21.97
  ],
  [
    18268,
    65.55767,
    13.3874,
    110.8,
    22.05
  ],
  [
    18270,
    65.55806,
    13.3872,
    111.1,
    22.05
  ],
  [
    18272,
    65.55845,
    13.38697,
    111.3,
    22.05
  ],
  [
    18274,
    65.55883,
    13.38675,
    111.3,
    22.13
  ],
  [
    18276,
    65.55922,
    13.38651,
    110.3,
    22.13
  ],
  [
    18278,
    65.5596,
    13.38628,
    110.7,
    22.12
  ],
  [
    18280,
    65.55999,
    13.38604,
    111.1,
    22.18
  ],
  [
    18282,
    65.56037,
    13.38578,
    110.8,
    22.17
  ],
  [
    18284,
    65.56075,
    13.38549,
    111.1,
    22.14
  ],
  [
    18286,
    65.56112,
    13.38509,
    111.3,
    22.14
  ],
  [
    18288,
    65.56146,
    13.38459,
    111.5,
    22.12
  ],
  [
    18290,
    65.56178,
    13.38404,
    111.6,
    22.08
  ],
  [
    18292,
    65.56211,
    13.38351,
    111.1,
    22.01
  ],
  [
    18294,
    65.56247,
    13.38309,
    111.2,
    22.18
  ],
  [
    18296,
    65.56285,
    13.3828,
    111.2,
    22.14
  ],
  [
    18298,
    65.56324,
    13.38266,
    111.5,
    22.1
  ],
  [
    18300,
    65.56363,
    13.38265,
    111.6,
    22.03
  ],
  [
    18302,
    65.56402,
    13.38279,
    111.8,
    22.01
  ],
  [
    18304,
    65.5644,
    13.38304,
    111.9,
    21.87
  ],
  [
    18306,
    65.56478,
    13.38334,
    111.9,
    21.73
  ],
  [
    18308,
    65.56515,
    13.38358,
    112.1,
    21.77
  ],
  [
    18310,
    65.56554,
    13.38369,
    112.2,
    21.74
  ],
  [
    18312,
    65.56593,
    13.38368,
    112.3,
    21.75
  ],
  [
    18314,
    65.56632,
    13.38351,
    112.6,
    21.87
  ],
  [
    18316,
    65.56668,
    13.38321,
    111.7,
    21.89
  ],
  [
    18318,
    65.56703,
    13.38277,
    111.3,
    21.95
  ],
  [
    18320,
    65.56736,
    13.38222,
    111.8,
    22.03
  ],
  [
    18322,
    65.56765,
    13.38158,
    112.1,
    22.09
  ],
  [
    18324,
    65.56793,
    13.3809,
    112.8,
    22.11
  ],
  [
    18326,
    65.56822,
    13.38023,
    113.6,
    22.08
  ],
  [
    18328,
    65.56851,
    13.37958,
    114.1,
    22.06
  ],
  [
    18330,
    65.56882,
    13.37899,
    114.8,
    21.85
  ],
  [
    18332,
    65.56913,
    13.37841,
    115.5,
    21.85
  ],
  [
    18334,
    65.56947,
    13.37784,
    115.8,
    22.75
  ],
  [
    18336,
    65.5698,
    13.37727,
    116.4,
    22.75
  ],
  [
    18342,
    65.57091,
    13.37676,
    117.4,
    22.75
  ],
  [
    18344,
    65.57136,
    13.37701,
    119.3,
    21.71
  ],
  [
    18346,
    65.57176,
    13.37701,
    120.5,
    21.76
  ],
  [
    18348,
    65.57215,
    13.37696,
    121,
    21.82
  ],
  [
    18350,
    65.57254,
    13.37683,
    121.4,
    21.85
  ],
  [
    18352,
    65.57291,
    13.37654,
    121.1,
    21.98
  ],
  [
    18354,
    65.57325,
    13.37608,
    116.9,
    22.09
  ],
  [
    18356,
    65.57359,
    13.37557,
    117.6,
    22.28
  ],
  [
    18358,
    65.57392,
    13.37503,
    118.3,
    22.51
  ],
  [
    18360,
    65.57427,
    13.37454,
    119.1,
    22.7
  ],
  [
    18362,
    65.57465,
    13.37414,
    119.7,
    22.89
  ],
  [
    18364,
    65.57503,
    13.37381,
    118.9,
    23.06
  ],
  [
    18366,
    65.57543,
    13.37354,
    118.4,
    23.24
  ],
  [
    18368,
    65.57583,
    13.37327,
    118.2,
    23.4
  ],
  [
    18370,
    65.57624,
    13.373,
    118.2,
    23.62
  ],
  [
    18372,
    65.57665,
    13.37272,
    118.2,
    23.81
  ],
  [
    18374,
    65.57706,
    13.37245,
    118.2,
    24.06
  ],
  [
    18376,
    65.57748,
    13.37217,
    118.1,
    24.29
  ],
  [
    18378,
    65.5779,
    13.37188,
    118,
    24.56
  ],
  [
    18380,
    65.57832,
    13.37157,
    117.9,
    24.87
  ],
  [
    18382,
    65.57875,
    13.37125,
    117.7,
    25.2
  ],
  [
    18384,
    65.57918,
    13.37091,
    117.6,
    25.5
  ],
  [
    18386,
    65.57962,
    13.37055,
    117.6,
    25.82
  ],
  [
    18388,
    65.58006,
    13.37017,
    117.6,
    26.12
  ],
  [
    18390,
    65.5805,
    13.36978,
    117.5,
    26.41
  ],
  [
    18392,
    65.58095,
    13.36936,
    117.5,
    26.73
  ],
  [
    18394,
    65.58139,
    13.36893,
    117.3,
    27.03
  ],
  [
    18396,
    65.58184,
    13.36848,
    116.8,
    27.42
  ],
  [
    18398,
    65.5823,
    13.368,
    116,
    27.84
  ],
  [
    18400,
    65.58276,
    13.3675,
    115.3,
    28.3
  ],
  [
    18402,
    65.58322,
    13.36698,
    114.6,
    28.78
  ],
  [
    18404,
    65.58369,
    13.36643,
    113.7,
    29.24
  ],
  [
    18406,
    65.58416,
    13.36585,
    112.8,
    29.68
  ],
  [
    18408,
    65.58464,
    13.36524,
    112.3,
    30.13
  ],
  [
    18410,
    65.58511,
    13.36459,
    111.4,
    30.46
  ],
  [
    18412,
    65.58558,
    13.36391,
    110.4,
    30.64
  ],
  [
    18414,
    65.58605,
    13.3632,
    109.9,
    30.74
  ],
  [
    18416,
    65.58651,
    13.36248,
    109.9,
    30.75
  ],
  [
    18418,
    65.58697,
    13.36175,
    109.9,
    30.73
  ],
  [
    18420,
    65.58744,
    13.36102,
    105.4,
    30.82
  ],
  [
    18422,
    65.5879,
    13.36028,
    104.5,
    30.85
  ],
  [
    18424,
    65.58835,
    13.35952,
    103.8,
    30.89
  ],
  [
    18426,
    65.58879,
    13.35868,
    102.8,
    30.89
  ],
  [
    18429,
    65.58937,
    13.35723,
    101.1,
    30.91
  ],
  [
    18431,
    65.5897,
    13.35616,
    100,
    30.98
  ],
  [
    18433,
    65.59001,
    13.35504,
    98.8,
    31.11
  ],
  [
    18435,
    65.59034,
    13.35395,
    97.9,
    31.07
  ],
  [
    18437,
    65.59066,
    13.35291,
    97,
    31.07
  ],
  [
    18439,
    65.59099,
    13.35192,
    96.7,
    29.68
  ],
  [
    18441,
    65.59131,
    13.35098,
    96.2,
    29.68
  ],
  [
    18443,
    65.59162,
    13.35007,
    95.4,
    27.15
  ],
  [
    18445,
    65.59192,
    13.34919,
    94.3,
    27.15
  ],
  [
    18447,
    65.59221,
    13.34834,
    93.2,
    27.15
  ],
  [
    18449,
    65.59249,
    13.3475,
    92.2,
    25.13
  ],
  [
    18451,
    65.59277,
    13.34666,
    91.9,
    24.88
  ],
  [
    18453,
    65.59304,
    13.34581,
    91.5,
    24.84
  ],
  [
    18455,
    65.59327,
    13.34488,
    90.9,
    24.85
  ],
  [
    18457,
    65.59345,
    13.3439,
    90.3,
    24.91
  ],
  [
    18459,
    65.59358,
    13.34286,
    89.7,
    25.07
  ],
  [
    18461,
    65.59366,
    13.34178,
    89.2,
    25.16
  ],
  [
    18463,
    65.5937,
    13.34069,
    88.9,
    25.28
  ],
  [
    18465,
    65.59374,
    13.33959,
    88.4,
    25.43
  ],
  [
    18467,
    65.59379,
    13.33849,
    88,
    25.43
  ],
  [
    18469,
    65.59386,
    13.33742,
    87.8,
    24.72
  ],
  [
    18471,
    65.59395,
    13.33638,
    87.6,
    24.18
  ],
  [
    18473,
    65.59405,
    13.33538,
    87.5,
    23.56
  ],
  [
    18475,
    65.59416,
    13.33441,
    87.1,
    22.99
  ],
  [
    18477,
    65.59428,
    13.33346,
    86.7,
    22.84
  ],
  [
    18479,
    65.5944,
    13.33251,
    86.1,
    22.73
  ],
  [
    18481,
    65.59452,
    13.33157,
    85.7,
    22.81
  ],
  [
    18483,
    65.59466,
    13.33064,
    85.4,
    22.93
  ],
  [
    18485,
    65.59483,
    13.32974,
    85,
    22.97
  ],
  [
    18487,
    65.59503,
    13.32887,
    85,
    22.93
  ],
  [
    18489,
    65.59526,
    13.32804,
    84.6,
    22.95
  ],
  [
    18491,
    65.59552,
    13.32726,
    84.3,
    22.95
  ],
  [
    18493,
    65.59579,
    13.32653,
    83.9,
    22.92
  ],
  [
    18495,
    65.5961,
    13.32585,
    83.6,
    22.94
  ],
  [
    18497,
    65.59643,
    13.32527,
    83.3,
    23.06
  ],
  [
    18499,
    65.5968,
    13.32481,
    82.9,
    23.11
  ],
  [
    18501,
    65.59719,
    13.32449,
    82.5,
    23.19
  ],
  [
    18503,
    65.5976,
    13.32432,
    82.1,
    23.25
  ],
  [
    18505,
    65.59802,
    13.32428,
    81.6,
    23.32
  ],
  [
    18507,
    65.59844,
    13.32429,
    81.1,
    23.41
  ],
  [
    18509,
    65.59886,
    13.32428,
    80.7,
    23.5
  ],
  [
    18511,
    65.59928,
    13.32419,
    80.3,
    23.54
  ],
  [
    18513,
    65.5997,
    13.32403,
    80.1,
    23.42
  ],
  [
    18515,
    65.60011,
    13.32378,
    79.8,
    23.22
  ],
  [
    18517,
    65.6005,
    13.32346,
    79.6,
    23.07
  ],
  [
    18519,
    65.60087,
    13.32305,
    79.5,
    22.99
  ],
  [
    18521,
    65.60122,
    13.32252,
    79.3,
    22.92
  ],
  [
    18523,
    65.60153,
    13.32187,
    79.2,
    22.84
  ],
  [
    18525,
    65.60179,
    13.32111,
    79.1,
    22.75
  ],
  [
    18527,
    65.602,
    13.32027,
    78.8,
    22.67
  ],
  [
    18529,
    65.60217,
    13.31937,
    78.7,
    22.74
  ],
  [
    18531,
    65.6023,
    13.31842,
    77.7,
    22.85
  ],
  [
    18533,
    65.60244,
    13.31749,
    76.9,
    22.97
  ],
  [
    18535,
    65.60261,
    13.31657,
    76.2,
    23.09
  ],
  [
    18537,
    65.60282,
    13.31571,
    75.7,
    23.19
  ],
  [
    18539,
    65.60306,
    13.31489,
    75.5,
    23.15
  ],
  [
    18541,
    65.60331,
    13.31409,
    75.1,
    23.15
  ],
  [
    18543,
    65.60356,
    13.31328,
    74.8,
    23.15
  ],
  [
    18551,
    65.60518,
    13.31167,
    74.5,
    23.73
  ],
  [
    18553,
    65.6056,
    13.31137,
    73.4,
    23.72
  ],
  [
    18555,
    65.60598,
    13.31093,
    72.5,
    23.76
  ],
  [
    18557,
    65.60634,
    13.31038,
    72.7,
    23.63
  ],
  [
    18559,
    65.60667,
    13.30974,
    72.7,
    23.38
  ],
  [
    18561,
    65.60697,
    13.30903,
    72.8,
    23.24
  ],
  [
    18563,
    65.60726,
    13.30831,
    72.7,
    23.22
  ],
  [
    18565,
    65.60757,
    13.30765,
    72.5,
    23.26
  ],
  [
    18567,
    65.60792,
    13.30711,
    72.2,
    23.33
  ],
  [
    18569,
    65.6083,
    13.3067,
    71.6,
    23.14
  ],
  [
    18571,
    65.6087,
    13.30644,
    71.3,
    22.66
  ],
  [
    18573,
    65.6091,
    13.30625,
    71,
    22.45
  ],
  [
    18575,
    65.60949,
    13.30602,
    71.1,
    22.37
  ],
  [
    18577,
    65.60987,
    13.30568,
    71,
    23
  ],
  [
    18579,
    65.61023,
    13.30522,
    71,
    22.42
  ],
  [
    18581,
    65.61058,
    13.30473,
    71,
    22.42
  ],
  [
    18583,
    65.61092,
    13.30424,
    71,
    22.42
  ],
  [
    18587,
    65.61155,
    13.30309,
    75.1,
    22.1
  ],
  [
    18589,
    65.61196,
    13.30289,
    76.3,
    21.85
  ],
  [
    18591,
    65.61234,
    13.30277,
    74.5,
    21.69
  ],
  [
    18593,
    65.61273,
    13.30279,
    73.9,
    21.68
  ],
  [
    18595,
    65.61312,
    13.30296,
    73.6,
    21.59
  ],
  [
    18597,
    65.61349,
    13.30323,
    73.2,
    21.61
  ],
  [
    18599,
    65.61385,
    13.30353,
    72.3,
    21.5
  ],
  [
    18601,
    65.61422,
    13.30378,
    71.2,
    21.67
  ],
  [
    18603,
    65.6146,
    13.30392,
    70.4,
    21.45
  ],
  [
    18605,
    65.61499,
    13.30391,
    69.2,
    21.44
  ],
  [
    18607,
    65.61536,
    13.30376,
    68.4,
    21.42
  ],
  [
    18609,
    65.61573,
    13.30348,
    67.7,
    21.39
  ],
  [
    18611,
    65.61608,
    13.30309,
    66.9,
    21.44
  ],
  [
    18613,
    65.61641,
    13.30265,
    65.8,
    21.49
  ],
  [
    18615,
    65.61676,
    13.30221,
    65.4,
    21.57
  ],
  [
    18617,
    65.61711,
    13.30181,
    65.6,
    21.7
  ],
  [
    18619,
    65.61748,
    13.30148,
    65.5,
    21.82
  ],
  [
    18621,
    65.61785,
    13.30121,
    65.3,
    21.95
  ],
  [
    18623,
    65.61824,
    13.30099,
    64.4,
    22.09
  ],
  [
    18625,
    65.61862,
    13.30076,
    63.5,
    22.22
  ],
  [
    18627,
    65.619,
    13.30046,
    62.9,
    22.38
  ],
  [
    18629,
    65.61936,
    13.30003,
    63.1,
    22.43
  ],
  [
    18631,
    65.61969,
    13.29948,
    63.2,
    22.55
  ],
  [
    18633,
    65.61998,
    13.29879,
    61.3,
    22.59
  ],
  [
    18635,
    65.62023,
    13.298,
    59.9,
    22.66
  ],
  [
    18637,
    65.62042,
    13.29713,
    59.9,
    22.73
  ],
  [
    18639,
    65.62056,
    13.29619,
    58,
    22.82
  ],
  [
    18642,
    65.62074,
    13.29475,
    57.2,
    22.91
  ],
  [
    18644,
    65.62085,
    13.29379,
    57,
    22.97
  ],
  [
    18646,
    65.62097,
    13.29282,
    56.8,
    22.97
  ],
  [
    18648,
    65.62108,
    13.29186,
    56.6,
    23.02
  ],
  [
    18650,
    65.6212,
    13.29089,
    56.4,
    23.02
  ],
  [
    18652,
    65.62132,
    13.28993,
    56.3,
    23.02
  ],
  [
    18654,
    65.62143,
    13.28897,
    56.2,
    23.02
  ],
  [
    18656,
    65.62155,
    13.288,
    56.1,
    23.02
  ],
  [
    18658,
    65.62167,
    13.28704,
    56.3,
    23.02
  ],
  [
    18660,
    65.62178,
    13.28608,
    56.7,
    23.02
  ],
  [
    18662,
    65.6219,
    13.28513,
    56.9,
    22.99
  ],
  [
    18664,
    65.62202,
    13.28417,
    57,
    22.99
  ],
  [
    18683,
    65.62383,
    13.27526,
    58.1,
    22.83
  ],
  [
    18685,
    65.62415,
    13.27482,
    55.7,
    23.95
  ],
  [
    18687,
    65.62446,
    13.27411,
    53,
    23.94
  ],
  [
    18689,
    65.62479,
    13.27344,
    52.2,
    23.67
  ],
  [
    18691,
    65.62512,
    13.27281,
    51.4,
    23.48
  ],
  [
    18693,
    65.62546,
    13.27221,
    51.1,
    23.24
  ],
  [
    18695,
    65.6258,
    13.27163,
    50.6,
    23.05
  ],
  [
    18697,
    65.62614,
    13.27108,
    50.4,
    23
  ],
  [
    18699,
    65.62649,
    13.27053,
    50.2,
    22.93
  ],
  [
    18701,
    65.62683,
    13.26999,
    50.1,
    22.84
  ],
  [
    18703,
    65.62718,
    13.26945,
    50.1,
    22.77
  ],
  [
    18705,
    65.62752,
    13.26891,
    50.4,
    22.88
  ],
  [
    18707,
    65.62787,
    13.26838,
    50.5,
    22.92
  ],
  [
    18709,
    65.62823,
    13.26789,
    50.7,
    22.94
  ],
  [
    18711,
    65.62861,
    13.2675,
    50.9,
    22.97
  ],
  [
    18713,
    65.62901,
    13.26726,
    51.3,
    22.9
  ],
  [
    18715,
    65.62942,
    13.26717,
    51.2,
    22.87
  ],
  [
    18717,
    65.62983,
    13.26723,
    51.3,
    22.87
  ],
  [
    18719,
    65.63023,
    13.26745,
    51.9,
    22.83
  ],
  [
    18721,
    65.63063,
    13.26775,
    52.8,
    22.92
  ],
  [
    18723,
    65.63103,
    13.26804,
    53.4,
    22.96
  ],
  [
    18725,
    65.63143,
    13.26824,
    53.7,
    22.98
  ],
  [
    18727,
    65.63184,
    13.26829,
    54.1,
    22.97
  ],
  [
    18729,
    65.63226,
    13.26819,
    54.4,
    23
  ],
  [
    18731,
    65.63266,
    13.26796,
    54.9,
    23.1
  ],
  [
    18733,
    65.63305,
    13.26764,
    55.3,
    23.16
  ],
  [
    18735,
    65.63344,
    13.26726,
    54.8,
    23.33
  ],
  [
    18737,
    65.63382,
    13.26682,
    54.4,
    23.56
  ],
  [
    18739,
    65.63418,
    13.26631,
    52.6,
    23.81
  ],
  [
    18741,
    65.63455,
    13.26578,
    52.8,
    24.12
  ],
  [
    18743,
    65.63493,
    13.26522,
    53.1,
    24.5
  ],
  [
    18745,
    65.6353,
    13.26465,
    53.2,
    24.86
  ],
  [
    18747,
    65.63569,
    13.26408,
    53.3,
    25.24
  ],
  [
    18749,
    65.63608,
    13.26352,
    53.4,
    25.61
  ],
  [
    18751,
    65.63648,
    13.26295,
    53.4,
    25.95
  ],
  [
    18753,
    65.63689,
    13.2624,
    53.5,
    26.25
  ],
  [
    18755,
    65.6373,
    13.26184,
    52.5,
    26.43
  ],
  [
    18757,
    65.63772,
    13.26129,
    51,
    26.56
  ],
  [
    18759,
    65.63815,
    13.26079,
    50.2,
    26.66
  ],
  [
    18761,
    65.6386,
    13.26041,
    49.9,
    26.61
  ],
  [
    18763,
    65.63907,
    13.26018,
    50,
    26.57
  ],
  [
    18765,
    65.63954,
    13.26011,
    50.2,
    26.49
  ],
  [
    18767,
    65.64002,
    13.26014,
    50.3,
    26.43
  ],
  [
    18769,
    65.64049,
    13.26019,
    50.5,
    26.34
  ],
  [
    18771,
    65.64097,
    13.26022,
    50.8,
    26.3
  ],
  [
    18773,
    65.64144,
    13.26021,
    51.3,
    26.24
  ],
  [
    18775,
    65.64191,
    13.26015,
    51.4,
    26.14
  ],
  [
    18777,
    65.64237,
    13.26006,
    51.4,
    26.02
  ],
  [
    18779,
    65.64284,
    13.25995,
    51.7,
    25.88
  ],
  [
    18781,
    65.6433,
    13.25984,
    51.8,
    25.75
  ],
  [
    18784,
    65.64399,
    13.25979,
    52.3,
    25.61
  ],
  [
    18786,
    65.64444,
    13.25988,
    52.5,
    25.49
  ],
  [
    18788,
    65.64489,
    13.26009,
    52.8,
    25.31
  ],
  [
    18790,
    65.64532,
    13.2604,
    53.1,
    25.14
  ],
  [
    18792,
    65.64574,
    13.26081,
    53.3,
    25.14
  ],
  [
    18794,
    65.64614,
    13.26128,
    53.5,
    24.93
  ],
  [
    18796,
    65.64654,
    13.26174,
    53.6,
    24.93
  ],
  [
    18798,
    65.64694,
    13.26213,
    53.6,
    24.09
  ],
  [
    18800,
    65.64734,
    13.26243,
    53.7,
    24.09
  ],
  [
    18802,
    65.64774,
    13.26265,
    53.6,
    24.09
  ],
  [
    18804,
    65.64815,
    13.26278,
    53.7,
    22.94
  ],
  [
    18806,
    65.64856,
    13.26282,
    53.5,
    22.94
  ],
  [
    18808,
    65.64896,
    13.26278,
    53.6,
    22.63
  ],
  [
    18810,
    65.64936,
    13.26269,
    53.7,
    22.59
  ],
  [
    18812,
    65.64976,
    13.26257,
    53.1,
    22.57
  ],
  [
    18814,
    65.65017,
    13.26252,
    52.7,
    22.56
  ],
  [
    18816,
    65.65057,
    13.2626,
    52.4,
    22.64
  ],
  [
    18818,
    65.65097,
    13.26282,
    52.2,
    22.7
  ],
  [
    18820,
    65.65135,
    13.26317,
    51.9,
    22.76
  ],
  [
    18822,
    65.65172,
    13.26358,
    51.5,
    22.76
  ],
  [
    18824,
    65.6521,
    13.26399,
    51.4,
    22.97
  ],
  [
    18826,
    65.65248,
    13.26436,
    50.4,
    23.18
  ],
  [
    18828,
    65.65287,
    13.26471,
    49.4,
    23.43
  ],
  [
    18830,
    65.65327,
    13.26501,
    48.7,
    23.61
  ],
  [
    18832,
    65.65368,
    13.26528,
    48,
    23.7
  ],
  [
    18834,
    65.6541,
    13.2655,
    47.3,
    23.95
  ],
  [
    18836,
    65.65452,
    13.26568,
    46.6,
    24.11
  ],
  [
    18838,
    65.65495,
    13.26581,
    46,
    24.16
  ],
  [
    18840,
    65.65538,
    13.2659,
    45.4,
    24.07
  ],
  [
    18842,
    65.65581,
    13.26594,
    44.9,
    24.01
  ],
  [
    18844,
    65.65624,
    13.26593,
    44.5,
    23.96
  ],
  [
    18846,
    65.65667,
    13.2659,
    44.2,
    23.91
  ],
  [
    18848,
    65.6571,
    13.26585,
    44.1,
    23.8
  ],
  [
    18850,
    65.65752,
    13.26581,
    44.1,
    23.65
  ],
  [
    18852,
    65.65795,
    13.26576,
    44.1,
    23.48
  ],
  [
    18854,
    65.65836,
    13.26571,
    44.1,
    23.21
  ],
  [
    18856,
    65.65878,
    13.26568,
    44.1,
    22.88
  ],
  [
    18858,
    65.65919,
    13.26566,
    43.9,
    22.72
  ],
  [
    18860,
    65.65959,
    13.26566,
    43.7,
    22.69
  ],
  [
    18862,
    65.66,
    13.26569,
    43.5,
    22.68
  ],
  [
    18864,
    65.6604,
    13.26573,
    43.3,
    22.67
  ],
  [
    18866,
    65.66081,
    13.26572,
    43.1,
    22.67
  ],
  [
    18868,
    65.66121,
    13.26558,
    42.8,
    22.66
  ],
  [
    18870,
    65.6616,
    13.26529,
    42.3,
    22.69
  ],
  [
    18872,
    65.66197,
    13.26489,
    41.7,
    22.75
  ],
  [
    18874,
    65.66233,
    13.26443,
    40.8,
    22.86
  ],
  [
    18876,
    65.6627,
    13.26398,
    40.9,
    23
  ],
  [
    18878,
    65.66307,
    13.26355,
    40.9,
    23.15
  ],
  [
    18880,
    65.66346,
    13.26313,
    40.8,
    23.29
  ],
  [
    18882,
    65.66384,
    13.26274,
    40.8,
    23.43
  ],
  [
    18884,
    65.66423,
    13.26236,
    40.7,
    23.47
  ],
  [
    18886,
    65.66462,
    13.26197,
    40.6,
    23.43
  ],
  [
    18888,
    65.665,
    13.26154,
    40.6,
    23.35
  ],
  [
    18890,
    65.66537,
    13.26104,
    40.7,
    23.14
  ],
  [
    18892,
    65.66571,
    13.26047,
    41,
    22.83
  ],
  [
    18894,
    65.66603,
    13.25986,
    41.3,
    22.49
  ],
  [
    18896,
    65.66633,
    13.25923,
    41.6,
    22.17
  ],
  [
    18898,
    65.66664,
    13.25863,
    41.9,
    22.04
  ],
  [
    18900,
    65.66697,
    13.25811,
    42.3,
    21.95
  ],
  [
    18902,
    65.66733,
    13.25772,
    42.5,
    21.89
  ],
  [
    18904,
    65.6677,
    13.25744,
    42.7,
    21.63
  ],
  [
    18906,
    65.66808,
    13.25721,
    42.9,
    21.49
  ],
  [
    18908,
    65.66845,
    13.25695,
    43.3,
    21.38
  ],
  [
    18910,
    65.66881,
    13.25663,
    43.7,
    21.37
  ],
  [
    18912,
    65.66915,
    13.25624,
    43.8,
    21.38
  ],
  [
    18914,
    65.66949,
    13.25578,
    43.8,
    21.47
  ],
  [
    18916,
    65.66981,
    13.25528,
    43.8,
    21.65
  ],
  [
    18918,
    65.67014,
    13.25478,
    43.8,
    21.7
  ],
  [
    18920,
    65.67049,
    13.25435,
    43.8,
    21.9
  ],
  [
    18922,
    65.67086,
    13.25402,
    43.6,
    22.02
  ],
  [
    18924,
    65.67124,
    13.2538,
    43.7,
    22.14
  ],
  [
    18926,
    65.67163,
    13.2536,
    43.4,
    22.25
  ],
  [
    18928,
    65.67202,
    13.25334,
    43.4,
    22.34
  ],
  [
    18931,
    65.67256,
    13.25272,
    43.4,
    22.43
  ],
  [
    18933,
    65.67289,
    13.25215,
    43.4,
    22.5
  ],
  [
    18935,
    65.6732,
    13.25152,
    43.5,
    22.5
  ],
  [
    18937,
    65.67352,
    13.2509,
    43.6,
    22.5
  ],
  [
    18939,
    65.67385,
    13.25033,
    43.6,
    22.69
  ],
  [
    18941,
    65.6742,
    13.2498,
    43.6,
    22.69
  ],
  [
    18943,
    65.67456,
    13.24933,
    43.6,
    22.91
  ],
  [
    18945,
    65.67494,
    13.24891,
    43.7,
    22.91
  ],
  [
    18947,
    65.67532,
    13.24856,
    43.9,
    22.91
  ],
  [
    18949,
    65.67572,
    13.24827,
    44.1,
    23.2
  ],
  [
    18951,
    65.67613,
    13.24804,
    44.3,
    23.2
  ],
  [
    18953,
    65.67655,
    13.24786,
    43.9,
    23.57
  ],
  [
    18955,
    65.67697,
    13.24771,
    43.6,
    23.79
  ],
  [
    18957,
    65.67739,
    13.24756,
    44,
    23.79
  ],
  [
    18965,
    65.67914,
    13.24707,
    45.3,
    25.54
  ],
  [
    18967,
    65.67961,
    13.24682,
    45,
    25.5
  ],
  [
    18969,
    65.68007,
    13.24669,
    45,
    25.81
  ],
  [
    18971,
    65.68052,
    13.24652,
    45,
    25.99
  ],
  [
    18973,
    65.68098,
    13.24633,
    41.6,
    26
  ],
  [
    18975,
    65.68143,
    13.24609,
    41.1,
    25.87
  ],
  [
    18977,
    65.68187,
    13.24576,
    42.1,
    25.07
  ],
  [
    18979,
    65.68228,
    13.2454,
    42.6,
    24.45
  ],
  [
    18982,
    65.6829,
    13.24487,
    43.5,
    24.45
  ],
  [
    18985,
    65.68343,
    13.24406,
    44.5,
    24.44
  ],
  [
    18987,
    65.68386,
    13.24331,
    44.5,
    24.59
  ],
  [
    18989,
    65.68426,
    13.24286,
    44.5,
    24.43
  ],
  [
    18991,
    65.68469,
    13.24256,
    45,
    24.34
  ],
  [
    18993,
    65.68512,
    13.2424,
    44.9,
    24.31
  ],
  [
    18995,
    65.68556,
    13.24238,
    44.8,
    24.3
  ],
  [
    18997,
    65.68599,
    13.24243,
    44.2,
    23.92
  ],
  [
    18999,
    65.68642,
    13.24252,
    43.2,
    23.35
  ],
  [
    19001,
    65.68683,
    13.24263,
    42.2,
    23.1
  ],
  [
    19004,
    65.68744,
    13.24286,
    40.6,
    23.02
  ],
  [
    19006,
    65.68784,
    13.2431,
    40,
    23.07
  ],
  [
    19008,
    65.68822,
    13.24348,
    39.2,
    23.12
  ],
  [
    19010,
    65.68858,
    13.24399,
    38.4,
    23.12
  ],
  [
    19012,
    65.68893,
    13.24455,
    37.6,
    23.12
  ],
  [
    19014,
    65.68928,
    13.24507,
    36.9,
    23.12
  ],
  [
    19016,
    65.68966,
    13.24548,
    36.6,
    23.12
  ],
  [
    19018,
    65.69006,
    13.24574,
    36.6,
    23.17
  ],
  [
    19020,
    65.69044,
    13.24595,
    37.1,
    23.17
  ],
  [
    19022,
    65.6908,
    13.24617,
    37.4,
    20.5
  ],
  [
    19024,
    65.69115,
    13.2464,
    37.7,
    20.5
  ],
  [
    19026,
    65.69167,
    13.24623,
    37.3,
    20.5
  ],
  [
    19028,
    65.69208,
    13.24502,
    34.9,
    23.47
  ],
  [
    19030,
    65.6925,
    13.2447,
    35,
    23.68
  ],
  [
    19032,
    65.69291,
    13.24444,
    34.7,
    23.77
  ],
  [
    19034,
    65.69333,
    13.24417,
    34.5,
    23.98
  ],
  [
    19036,
    65.69375,
    13.24393,
    34.6,
    24.13
  ],
  [
    19038,
    65.69418,
    13.24376,
    34.3,
    24.26
  ],
  [
    19040,
    65.69462,
    13.2437,
    34.1,
    24.35
  ],
  [
    19042,
    65.69506,
    13.24373,
    33.9,
    24.37
  ],
  [
    19044,
    65.69549,
    13.24387,
    33.6,
    24.45
  ],
  [
    19046,
    65.69592,
    13.24406,
    33.2,
    24.46
  ],
  [
    19048,
    65.69636,
    13.24426,
    33.2,
    24.56
  ],
  [
    19050,
    65.69679,
    13.24447,
    33.1,
    24.64
  ],
  [
    19052,
    65.69723,
    13.24465,
    33.3,
    24.72
  ],
  [
    19054,
    65.69767,
    13.24477,
    33.3,
    24.87
  ],
  [
    19056,
    65.69812,
    13.24482,
    33.2,
    25.02
  ],
  [
    19058,
    65.69857,
    13.24479,
    33.2,
    25.16
  ],
  [
    19060,
    65.69902,
    13.24468,
    33.2,
    25.37
  ],
  [
    19062,
    65.69947,
    13.24448,
    33.2,
    25.49
  ],
  [
    19064,
    65.69992,
    13.24421,
    33.1,
    25.9
  ],
  [
    19066,
    65.70036,
    13.24385,
    32.8,
    26.21
  ],
  [
    19068,
    65.7008,
    13.24345,
    32.5,
    26.39
  ],
  [
    19070,
    65.70125,
    13.24304,
    32.5,
    26.51
  ],
  [
    19072,
    65.7017,
    13.24263,
    32.4,
    26.64
  ],
  [
    19074,
    65.70214,
    13.24221,
    32.5,
    26.74
  ],
  [
    19076,
    65.70259,
    13.2418,
    32.7,
    26.77
  ],
  [
    19078,
    65.70304,
    13.24138,
    32.9,
    26.78
  ],
  [
    19080,
    65.70349,
    13.24096,
    32.2,
    26.75
  ],
  [
    19082,
    65.70394,
    13.24054,
    32.1,
    26.69
  ],
  [
    19084,
    65.70439,
    13.24012,
    31.6,
    26.63
  ],
  [
    19086,
    65.70483,
    13.23971,
    31.3,
    26.6
  ],
  [
    19088,
    65.70528,
    13.23929,
    31.1,
    26.52
  ],
  [
    19090,
    65.70571,
    13.23885,
    31.3,
    26.43
  ],
  [
    19092,
    65.70614,
    13.23833,
    31.7,
    26.33
  ],
  [
    19094,
    65.70655,
    13.23779,
    32.1,
    26.33
  ],
  [
    19099,
    65.7076,
    13.23636,
    32.7,
    27
  ],
  [
    19101,
    65.70782,
    13.23519,
    31.8,
    26.38
  ],
  [
    19103,
    65.70819,
    13.23447,
    31.9,
    26.4
  ],
  [
    19105,
    65.70857,
    13.23376,
    32,
    26.39
  ],
  [
    19107,
    65.70892,
    13.23302,
    32,
    26.32
  ],
  [
    19109,
    65.70925,
    13.2322,
    31.7,
    26.24
  ],
  [
    19111,
    65.70955,
    13.23133,
    31.7,
    26.18
  ],
  [
    19113,
    65.70984,
    13.23043,
    31.8,
    26.13
  ],
  [
    19115,
    65.71015,
    13.22956,
    31.8,
    26.08
  ],
  [
    19117,
    65.71048,
    13.22876,
    31.8,
    26.02
  ],
  [
    19119,
    65.71082,
    13.228,
    31.8,
    25.93
  ],
  [
    19121,
    65.71117,
    13.22725,
    31.8,
    25.84
  ],
  [
    19123,
    65.71148,
    13.22644,
    31.8,
    25.76
  ],
  [
    19125,
    65.71176,
    13.22554,
    31.8,
    25.67
  ],
  [
    19127,
    65.71199,
    13.22458,
    31.9,
    25.59
  ],
  [
    19129,
    65.71218,
    13.22356,
    31.8,
    25.34
  ],
  [
    19131,
    65.71231,
    13.22251,
    31.2,
    24.73
  ],
  [
    19133,
    65.71239,
    13.22146,
    30.5,
    24.26
  ],
  [
    19135,
    65.71247,
    13.22042,
    30.7,
    24.1
  ],
  [
    19137,
    65.71258,
    13.21941,
    30.7,
    23.91
  ],
  [
    19139,
    65.71274,
    13.21845,
    30.5,
    23.84
  ],
  [
    19141,
    65.71296,
    13.21756,
    30.3,
    23.75
  ],
  [
    19143,
    65.71322,
    13.21674,
    30.2,
    23.7
  ],
  [
    19145,
    65.71352,
    13.21602,
    29.9,
    23.66
  ],
  [
    19147,
    65.71385,
    13.21538,
    29.6,
    23.56
  ],
  [
    19149,
    65.7142,
    13.21479,
    29.4,
    23.53
  ],
  [
    19151,
    65.71456,
    13.21428,
    29.2,
    23.53
  ],
  [
    19153,
    65.71495,
    13.21389,
    28.9,
    23.56
  ],
  [
    19155,
    65.71537,
    13.21364,
    28.6,
    23.59
  ],
  [
    19157,
    65.71579,
    13.2135,
    28.4,
    23.65
  ],
  [
    19159,
    65.71621,
    13.2134,
    28.4,
    23.72
  ],
  [
    19161,
    65.71664,
    13.21328,
    27.7,
    23.83
  ],
  [
    19163,
    65.71706,
    13.21312,
    27.7,
    24.01
  ],
  [
    19165,
    65.71749,
    13.21291,
    27.7,
    24.22
  ],
  [
    19167,
    65.71791,
    13.21267,
    27.6,
    24.46
  ],
  [
    19170,
    65.71855,
    13.21226,
    27.6,
    24.69
  ],
  [
    19172,
    65.71898,
    13.21199,
    27.6,
    24.69
  ],
  [
    19185,
    65.72203,
    13.21157,
    26.3,
    26.74
  ],
  [
    19187,
    65.72254,
    13.21184,
    28.7,
    26.95
  ],
  [
    19189,
    65.72304,
    13.21194,
    30.1,
    27.26
  ],
  [
    19191,
    65.72352,
    13.21216,
    30.4,
    27.48
  ],
  [
    19193,
    65.72399,
    13.21253,
    32.2,
    27.64
  ],
  [
    19195,
    65.72446,
    13.21289,
    32.1,
    27.66
  ],
  [
    19197,
    65.72494,
    13.21315,
    31.1,
    27.77
  ],
  [
    19199,
    65.72544,
    13.21329,
    30.8,
    27.91
  ],
  [
    19201,
    65.72594,
    13.21331,
    29.5,
    27.96
  ],
  [
    19203,
    65.72644,
    13.21328,
    27.4,
    28.18
  ],
  [
    19205,
    65.72695,
    13.21326,
    26.3,
    28.26
  ],
  [
    19207,
    65.72745,
    13.21325,
    25.5,
    28.35
  ],
  [
    19209,
    65.72796,
    13.21323,
    24.6,
    28.45
  ],
  [
    19211,
    65.72847,
    13.21323,
    23.8,
    28.58
  ],
  [
    19213,
    65.72899,
    13.21322,
    23.3,
    28.71
  ],
  [
    19215,
    65.7295,
    13.21321,
    22.9,
    28.81
  ],
  [
    19217,
    65.73002,
    13.21321,
    22.7,
    28.89
  ],
  [
    19219,
    65.73054,
    13.2132,
    22.5,
    28.96
  ],
  [
    19221,
    65.73106,
    13.21321,
    22.5,
    28.98
  ],
  [
    19223,
    65.73158,
    13.21329,
    22.6,
    29.04
  ],
  [
    19225,
    65.73209,
    13.21351,
    22.6,
    29.04
  ],
  [
    19227,
    65.73259,
    13.21388,
    22.1,
    29.04
  ],
  [
    19229,
    65.73307,
    13.21438,
    22,
    29.15
  ],
  [
    19231,
    65.73355,
    13.2149,
    22,
    29.31
  ],
  [
    19233,
    65.73405,
    13.21534,
    21.3,
    29.41
  ],
  [
    19235,
    65.73456,
    13.21567,
    21.3,
    29.54
  ],
  [
    19237,
    65.73508,
    13.21595,
    21.5,
    29.66
  ],
  [
    19239,
    65.73559,
    13.21628,
    21.5,
    29.73
  ],
  [
    19242,
    65.73635,
    13.21694,
    21.2,
    29.84
  ],
  [
    19244,
    65.73684,
    13.21743,
    20.7,
    29.97
  ],
  [
    19246,
    65.73734,
    13.21795,
    20.1,
    30.06
  ],
  [
    19248,
    65.73784,
    13.21851,
    19.5,
    30.43
  ],
  [
    19250,
    65.73833,
    13.2191,
    18.8,
    30.43
  ],
  [
    19252,
    65.73882,
    13.21973,
    18.1,
    30.43
  ],
  [
    19254,
    65.73931,
    13.22039,
    17.3,
    31.02
  ],
  [
    19256,
    65.73979,
    13.22108,
    16.6,
    31.02
  ],
  [
    19258,
    65.74027,
    13.22179,
    16,
    31.35
  ],
  [
    19260,
    65.74075,
    13.22251,
    15.6,
    31.44
  ],
  [
    19262,
    65.74124,
    13.22323,
    15.4,
    31.52
  ],
  [
    19264,
    65.74172,
    13.22395,
    15.3,
    31.51
  ],
  [
    19266,
    65.7422,
    13.22466,
    15.3,
    31.48
  ],
  [
    19268,
    65.74268,
    13.22538,
    15.3,
    31.38
  ],
  [
    19270,
    65.74316,
    13.22609,
    15.3,
    31.3
  ],
  [
    19272,
    65.74364,
    13.2268,
    15.3,
    31.26
  ],
  [
    19274,
    65.74411,
    13.2275,
    15.3,
    30.61
  ],
  [
    19276,
    65.74457,
    13.22822,
    15.5,
    29.86
  ],
  [
    19278,
    65.74499,
    13.22897,
    15.7,
    28.8
  ],
  [
    19280,
    65.74538,
    13.22974,
    16.1,
    27.48
  ],
  [
    19282,
    65.74575,
    13.23049,
    16.4,
    26.11
  ],
  [
    19284,
    65.74611,
    13.23119,
    16.9,
    24.83
  ],
  [
    19286,
    65.74646,
    13.23182,
    17.3,
    23.91
  ],
  [
    19288,
    65.74681,
    13.23235,
    17.7,
    23.13
  ],
  [
    19290,
    65.74718,
    13.23278,
    18,
    22.46
  ],
  [
    19292,
    65.74755,
    13.23311,
    18.3,
    21.85
  ],
  [
    19294,
    65.74792,
    13.23337,
    18.6,
    21.52
  ],
  [
    19296,
    65.74829,
    13.2336,
    19,
    21.36
  ],
  [
    19298,
    65.74866,
    13.23386,
    19.4,
    21.32
  ],
  [
    19300,
    65.74901,
    13.23419,
    19.9,
    21.25
  ],
  [
    19302,
    65.74934,
    13.23464,
    20.2,
    21.22
  ],
  [
    19304,
    65.74964,
    13.23521,
    20.4,
    21.21
  ],
  [
    19306,
    65.74991,
    13.23587,
    20.4,
    21.28
  ],
  [
    19308,
    65.75016,
    13.23657,
    20.3,
    21.39
  ],
  [
    19310,
    65.75041,
    13.23729,
    20.3,
    21.66
  ],
  [
    19312,
    65.75066,
    13.23801,
    20.2,
    21.97
  ],
  [
    19314,
    65.75092,
    13.23874,
    20.3,
    22.23
  ],
  [
    19316,
    65.75119,
    13.23945,
    20.5,
    22.4
  ],
  [
    19318,
    65.7515,
    13.24007,
    20.7,
    22.52
  ],
  [
    19320,
    65.75184,
    13.24057,
    20.9,
    22.65
  ],
  [
    19322,
    65.75222,
    13.24094,
    21.1,
    22.71
  ],
  [
    19324,
    65.75262,
    13.24116,
    21.3,
    22.82
  ],
  [
    19326,
    65.75302,
    13.24123,
    21.3,
    22.93
  ],
  [
    19328,
    65.75344,
    13.24118,
    21.4,
    23.13
  ],
  [
    19330,
    65.75385,
    13.2411,
    21.5,
    23.37
  ],
  [
    19332,
    65.75427,
    13.24101,
    21.6,
    23.63
  ],
  [
    19334,
    65.7547,
    13.24093,
    21.7,
    23.9
  ],
  [
    19336,
    65.75513,
    13.24084,
    21.7,
    24.21
  ],
  [
    19338,
    65.75556,
    13.24075,
    21.7,
    24.54
  ],
  [
    19340,
    65.75601,
    13.24066,
    21.9,
    24.89
  ],
  [
    19342,
    65.75646,
    13.2406,
    22,
    25.23
  ],
  [
    19345,
    65.75714,
    13.24054,
    22.3,
    25.52
  ],
  [
    19347,
    65.75761,
    13.24052,
    22.7,
    25.82
  ],
  [
    19349,
    65.75807,
    13.24054,
    23.2,
    25.95
  ],
  [
    19351,
    65.75855,
    13.24057,
    23.7,
    25.95
  ],
  [
    19353,
    65.75902,
    13.24064,
    24.2,
    26.43
  ],
  [
    19355,
    65.75949,
    13.24072,
    24.6,
    26.43
  ],
  [
    19357,
    65.75997,
    13.2408,
    24.9,
    26.43
  ],
  [
    19359,
    65.76045,
    13.24089,
    25.2,
    26.76
  ],
  [
    19361,
    65.76093,
    13.24098,
    25.3,
    27.05
  ],
  [
    19363,
    65.76142,
    13.24107,
    25.4,
    27.28
  ],
  [
    19365,
    65.76192,
    13.24116,
    25.4,
    27.56
  ],
  [
    19367,
    65.76241,
    13.24125,
    25.5,
    27.84
  ],
  [
    19369,
    65.76292,
    13.24134,
    25.6,
    28.2
  ],
  [
    19371,
    65.76342,
    13.24143,
    25.7,
    28.47
  ],
  [
    19373,
    65.76393,
    13.24146,
    25.8,
    28.66
  ],
  [
    19375,
    65.76445,
    13.24139,
    25.9,
    28.9
  ],
  [
    19377,
    65.76497,
    13.24122,
    26.1,
    28.97
  ],
  [
    19379,
    65.76548,
    13.241,
    26.1,
    29.22
  ],
  [
    19381,
    65.766,
    13.24074,
    26.1,
    29.34
  ],
  [
    19383,
    65.76651,
    13.24043,
    26.1,
    29.46
  ],
  [
    19385,
    65.76702,
    13.24009,
    25.9,
    29.56
  ],
  [
    19387,
    65.76753,
    13.23969,
    25.8,
    29.61
  ],
  [
    19389,
    65.76802,
    13.2392,
    25.6,
    29.76
  ],
  [
    19391,
    65.76851,
    13.23865,
    25.5,
    29.88
  ],
  [
    19393,
    65.76901,
    13.23815,
    25.3,
    30.05
  ],
  [
    19395,
    65.76952,
    13.23778,
    25.1,
    30.15
  ],
  [
    19397,
    65.77006,
    13.23756,
    25,
    30.27
  ],
  [
    19399,
    65.7706,
    13.2375,
    24.7,
    30.35
  ],
  [
    19401,
    65.77115,
    13.23749,
    24.4,
    30.4
  ],
  [
    19403,
    65.77169,
    13.23744,
    24.2,
    30.51
  ],
  [
    19405,
    65.77224,
    13.2373,
    24,
    30.58
  ],
  [
    19407,
    65.77278,
    13.23706,
    23.9,
    30.68
  ],
  [
    19409,
    65.77332,
    13.2368,
    23.8,
    30.74
  ],
  [
    19411,
    65.77387,
    13.23661,
    23.7,
    30.73
  ],
  [
    19413,
    65.77442,
    13.23648,
    23.4,
    30.73
  ],
  [
    19415,
    65.77496,
    13.23643,
    23.2,
    30.34
  ],
  [
    19417,
    65.7755,
    13.23643,
    23,
    29.65
  ],
  [
    19419,
    65.77603,
    13.23644,
    22.9,
    28.91
  ],
  [
    19421,
    65.77653,
    13.23638,
    22.7,
    28.07
  ],
  [
    19423,
    65.77703,
    13.2362,
    22.7,
    27.35
  ],
  [
    19425,
    65.77749,
    13.2359,
    22.7,
    26.85
  ],
  [
    19427,
    65.77794,
    13.2355,
    22.6,
    26.61
  ],
  [
    19429,
    65.77838,
    13.23506,
    22.7,
    26.41
  ],
  [
    19431,
    65.77883,
    13.23467,
    22.7,
    26.37
  ],
  [
    19433,
    65.77928,
    13.2344,
    22.7,
    26.38
  ],
  [
    19435,
    65.77975,
    13.23424,
    22.6,
    26.43
  ],
  [
    19437,
    65.78022,
    13.23412,
    22.3,
    26.49
  ],
  [
    19439,
    65.78069,
    13.23394,
    22.3,
    26.58
  ],
  [
    19441,
    65.78115,
    13.23365,
    22.1,
    26.69
  ],
  [
    19443,
    65.7816,
    13.23323,
    22,
    26.86
  ],
  [
    19445,
    65.78203,
    13.23272,
    21.9,
    26.9
  ],
  [
    19447,
    65.78246,
    13.23218,
    21.8,
    26.83
  ],
  [
    19449,
    65.7829,
    13.2317,
    21.2,
    27.09
  ],
  [
    19451,
    65.78336,
    13.23131,
    20.6,
    27.25
  ],
  [
    19453,
    65.78383,
    13.231,
    20.6,
    27.47
  ],
  [
    19455,
    65.78431,
    13.23071,
    19.2,
    27.73
  ],
  [
    19457,
    65.7848,
    13.23042,
    18.6,
    28.05
  ],
  [
    19459,
    65.78529,
    13.23012,
    18.3,
    28.35
  ],
  [
    19462,
    65.78601,
    13.22952,
    18,
    28.6
  ],
  [
    19464,
    65.78648,
    13.22898,
    17.9,
    28.83
  ],
  [
    19466,
    65.78692,
    13.22832,
    17.8,
    28.83
  ],
  [
    19468,
    65.78736,
    13.22759,
    17.8,
    29.34
  ],
  [
    19470,
    65.78779,
    13.22684,
    17.7,
    29.34
  ],
  [
    19472,
    65.78823,
    13.22609,
    17.6,
    29.34
  ],
  [
    19474,
    65.78867,
    13.22533,
    17.5,
    29.96
  ],
  [
    19476,
    65.78911,
    13.22456,
    17.5,
    29.96
  ],
  [
    19478,
    65.78956,
    13.22378,
    17.6,
    30.51
  ],
  [
    19480,
    65.79001,
    13.223,
    17.5,
    30.62
  ],
  [
    19482,
    65.79046,
    13.22223,
    17.5,
    30.7
  ],
  [
    19484,
    65.7909,
    13.22145,
    17.6,
    30.62
  ],
  [
    19486,
    65.79136,
    13.22067,
    17.4,
    30.78
  ],
  [
    19488,
    65.79182,
    13.21993,
    16.9,
    30.8
  ],
  [
    19490,
    65.7923,
    13.21926,
    16.3,
    30.88
  ],
  [
    19492,
    65.7928,
    13.21867,
    15.9,
    30.89
  ],
  [
    19494,
    65.79331,
    13.21815,
    15.6,
    30.9
  ],
  [
    19496,
    65.79382,
    13.21765,
    15.7,
    30.89
  ],
  [
    19498,
    65.79434,
    13.21713,
    15.8,
    30.85
  ],
  [
    19500,
    65.79484,
    13.21659,
    16,
    30.74
  ],
  [
    19502,
    65.79534,
    13.21603,
    16.3,
    30.65
  ],
  [
    19504,
    65.79583,
    13.21545,
    16.5,
    30.34
  ],
  [
    19506,
    65.79632,
    13.21486,
    16.5,
    30.28
  ],
  [
    19508,
    65.79681,
    13.21428,
    16.5,
    30.12
  ],
  [
    19510,
    65.7973,
    13.21374,
    16.6,
    30.04
  ],
  [
    19512,
    65.79781,
    13.21333,
    16.5,
    29.99
  ],
  [
    19514,
    65.79834,
    13.21308,
    16.6,
    29.9
  ],
  [
    19516,
    65.79887,
    13.21298,
    16.6,
    29.91
  ],
  [
    19518,
    65.79941,
    13.21304,
    16.5,
    29.91
  ],
  [
    19520,
    65.79995,
    13.21327,
    16.2,
    29.94
  ],
  [
    19522,
    65.80047,
    13.21362,
    15.8,
    29.95
  ],
  [
    19524,
    65.80099,
    13.21397,
    15.6,
    30.02
  ],
  [
    19526,
    65.80152,
    13.21428,
    15.6,
    30.08
  ],
  [
    19528,
    65.80204,
    13.21456,
    15.7,
    30.12
  ],
  [
    19530,
    65.80257,
    13.21479,
    15.8,
    30.15
  ],
  [
    19532,
    65.80311,
    13.21499,
    15.9,
    30.16
  ],
  [
    19534,
    65.80364,
    13.21514,
    15.9,
    30.18
  ],
  [
    19536,
    65.80418,
    13.21526,
    15.9,
    30.19
  ],
  [
    19538,
    65.80472,
    13.21533,
    15.9,
    30.23
  ],
  [
    19540,
    65.80526,
    13.21532,
    16,
    30.2
  ],
  [
    19542,
    65.8058,
    13.2152,
    16.3,
    30.21
  ],
  [
    19544,
    65.80634,
    13.21502,
    16.7,
    30.21
  ],
  [
    19546,
    65.80688,
    13.21491,
    17,
    30.21
  ],
  [
    19548,
    65.80742,
    13.21494,
    17.2,
    30.23
  ],
  [
    19550,
    65.80796,
    13.21513,
    17.2,
    30.26
  ],
  [
    19552,
    65.80849,
    13.21543,
    17,
    30.34
  ],
  [
    19554,
    65.80902,
    13.21572,
    17,
    30.47
  ],
  [
    19556,
    65.80956,
    13.21601,
    17,
    30.63
  ],
  [
    19558,
    65.8101,
    13.21628,
    17.1,
    30.68
  ],
  [
    19560,
    65.81065,
    13.21653,
    17.1,
    30.64
  ],
  [
    19562,
    65.81119,
    13.21676,
    17.1,
    30.56
  ],
  [
    19564,
    65.81174,
    13.21697,
    17.2,
    30.5
  ],
  [
    19566,
    65.81228,
    13.21716,
    17.3,
    30.43
  ],
  [
    19568,
    65.81282,
    13.21733,
    17.4,
    30.35
  ],
  [
    19570,
    65.81336,
    13.21749,
    17.5,
    30.29
  ],
  [
    19572,
    65.8139,
    13.21761,
    17.5,
    30.23
  ],
  [
    19574,
    65.81444,
    13.21769,
    17.5,
    30.18
  ],
  [
    19576,
    65.81499,
    13.21771,
    17.1,
    30.19
  ],
  [
    19578,
    65.81552,
    13.21761,
    16.7,
    30.2
  ],
  [
    19580,
    65.81606,
    13.21736,
    16.5,
    30.25
  ],
  [
    19583,
    65.81685,
    13.21687,
    16.4,
    30.3
  ],
  [
    19585,
    65.81738,
    13.21654,
    16.5,
    30.32
  ],
  [
    19587,
    65.8179,
    13.21621,
    16.7,
    30.32
  ],
  [
    19589,
    65.81843,
    13.21588,
    17,
    30.35
  ],
  [
    19591,
    65.81896,
    13.21555,
    17.3,
    30.35
  ],
  [
    19593,
    65.81948,
    13.21523,
    17.6,
    30.23
  ],
  [
    19595,
    65.82001,
    13.21492,
    17.9,
    30.17
  ],
  [
    19597,
    65.82053,
    13.21461,
    18.3,
    30.13
  ],
  [
    19599,
    65.82106,
    13.21432,
    18.7,
    30.1
  ],
  [
    19601,
    65.82159,
    13.21403,
    19.2,
    30.05
  ],
  [
    19603,
    65.82211,
    13.21378,
    19.7,
    30.01
  ],
  [
    19605,
    65.82265,
    13.21359,
    19.9,
    30
  ],
  [
    19607,
    65.82318,
    13.21346,
    20,
    30.01
  ],
  [
    19609,
    65.82372,
    13.21334,
    20.1,
    30.07
  ],
  [
    19611,
    65.82426,
    13.21322,
    20.2,
    30.17
  ],
  [
    19613,
    65.82479,
    13.21309,
    20.2,
    30.22
  ],
  [
    19615,
    65.82533,
    13.21297,
    20.2,
    30.21
  ],
  [
    19617,
    65.82587,
    13.21281,
    20.2,
    30.19
  ],
  [
    19619,
    65.82641,
    13.2126,
    20,
    30.15
  ],
  [
    19621,
    65.82693,
    13.21231,
    19.5,
    30.2
  ],
  [
    19623,
    65.82745,
    13.21193,
    19,
    30.25
  ],
  [
    19625,
    65.82796,
    13.21146,
    18.8,
    30.31
  ],
  [
    19627,
    65.82845,
    13.2109,
    18.6,
    30.32
  ],
  [
    19629,
    65.82893,
    13.21025,
    18.2,
    30.26
  ],
  [
    19631,
    65.82938,
    13.20953,
    17.9,
    30.2
  ],
  [
    19633,
    65.82983,
    13.20878,
    17.7,
    30.11
  ],
  [
    19635,
    65.83028,
    13.20806,
    17.4,
    30.06
  ],
  [
    19637,
    65.83075,
    13.20741,
    16.9,
    30.35
  ],
  [
    19639,
    65.83125,
    13.2069,
    17.3,
    29.93
  ],
  [
    19641,
    65.83175,
    13.20645,
    16.9,
    29.86
  ],
  [
    19643,
    65.83227,
    13.20612,
    16.4,
    29.84
  ],
  [
    19645,
    65.83279,
    13.20589,
    16.1,
    29.85
  ],
  [
    19647,
    65.83332,
    13.20578,
    15.6,
    29.89
  ],
  [
    19649,
    65.83386,
    13.20578,
    15.1,
    29.92
  ],
  [
    19651,
    65.83439,
    13.20585,
    14.6,
    29.74
  ],
  [
    19653,
    65.83492,
    13.20594,
    14.2,
    29.03
  ],
  [
    19655,
    65.83543,
    13.20603,
    13.8,
    28.16
  ],
  [
    19657,
    65.83593,
    13.20612,
    13.5,
    27.19
  ],
  [
    19659,
    65.8364,
    13.2062,
    13.1,
    26.21
  ],
  [
    19661,
    65.83686,
    13.20625,
    12.7,
    25.3
  ],
  [
    19663,
    65.83731,
    13.20625,
    12.4,
    24.69
  ],
  [
    19665,
    65.83775,
    13.2062,
    12.1,
    24.23
  ],
  [
    19667,
    65.83817,
    13.20609,
    12,
    23.85
  ],
  [
    19669,
    65.83859,
    13.20594,
    11.9,
    23.4
  ],
  [
    19671,
    65.839,
    13.20574,
    11.8,
    22.75
  ],
  [
    19673,
    65.83939,
    13.20551,
    11.8,
    21.99
  ],
  [
    19675,
    65.83976,
    13.20525,
    11.8,
    21.23
  ],
  [
    19677,
    65.84011,
    13.20495,
    11.8,
    20.52
  ],
  [
    19679,
    65.84045,
    13.20463,
    11.8,
    19.87
  ],
  [
    19681,
    65.84077,
    13.2043,
    12,
    19.52
  ],
  [
    19683,
    65.84109,
    13.20395,
    12.3,
    19.28
  ],
  [
    19685,
    65.8414,
    13.20361,
    12.5,
    19.1
  ],
  [
    19687,
    65.84171,
    13.20327,
    12.7,
    18.92
  ],
  [
    19689,
    65.84201,
    13.20293,
    12.7,
    18.41
  ],
  [
    19691,
    65.84231,
    13.20261,
    13,
    17.78
  ],
  [
    19693,
    65.84259,
    13.20229,
    12.9,
    17.16
  ],
  [
    19695,
    65.84287,
    13.20199,
    12.9,
    16.55
  ],
  [
    19698,
    65.84326,
    13.20156,
    12.8,
    15.96
  ],
  [
    19700,
    65.84351,
    13.20129,
    12.8,
    15.41
  ],
  [
    19702,
    65.84375,
    13.20102,
    12.6,
    15.13
  ],
  [
    19704,
    65.84398,
    13.20077,
    12.5,
    14.49
  ],
  [
    19706,
    65.8442,
    13.20052,
    12.3,
    14.49
  ],
  [
    19708,
    65.84441,
    13.20029,
    12.2,
    13.06
  ],
  [
    19710,
    65.84461,
    13.20007,
    12.1,
    13.06
  ],
  [
    19712,
    65.8448,
    13.19987,
    12,
    11.42
  ],
  [
    19714,
    65.84497,
    13.19968,
    11.9,
    10.33
  ],
  [
    19716,
    65.84512,
    13.19952,
    11.8,
    9.23
  ],
  [
    19718,
    65.84525,
    13.19937,
    11.8,
    8.27
  ],
  [
    19720,
    65.84537,
    13.19923,
    11.7,
    7.51
  ],
  [
    19722,
    65.84549,
    13.1991,
    11.8,
    7.41
  ],
  [
    19724,
    65.84561,
    13.19897,
    11.8,
    7.04
  ],
  [
    19726,
    65.84571,
    13.19886,
    11.8,
    6.18
  ],
  [
    19728,
    65.84581,
    13.19876,
    11.9,
    5.16
  ],
  [
    19730,
    65.84588,
    13.19868,
    11.9,
    3.99
  ],
  [
    19732,
    65.84593,
    13.19862,
    12,
    3.3
  ],
  [
    19734,
    65.84596,
    13.19859,
    12.1,
    1.22
  ],
  [
    19736,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19738,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19740,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19742,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19744,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19746,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19748,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19750,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19752,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19754,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19756,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19758,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19760,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19762,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19764,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19766,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19768,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19770,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19772,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19774,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19776,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19778,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19780,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19782,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19784,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19786,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19788,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19790,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19792,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19794,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19796,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19798,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19800,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19802,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19804,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19806,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19808,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19810,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19812,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19814,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19817,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19819,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19821,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19823,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19825,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19827,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19829,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19831,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19833,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19835,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19837,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19839,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19841,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19843,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19845,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19847,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19849,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19851,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19853,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19855,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19857,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19859,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19861,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19863,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19865,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19867,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19869,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19871,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19873,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19875,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19877,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19879,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19881,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19883,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19885,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19887,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19889,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19891,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19893,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19895,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19897,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19899,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19901,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19903,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19905,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19907,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19909,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19911,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19913,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19915,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19917,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19919,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19921,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19923,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19925,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19927,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19929,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19931,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19933,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19935,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19937,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19939,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19942,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19944,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19946,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19948,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19950,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19952,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19954,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19956,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19958,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19960,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19962,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19964,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19966,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19968,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19970,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19972,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19974,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19976,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19978,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19980,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19982,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19984,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19986,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19988,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19990,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19992,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19994,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19996,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    19998,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20000,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20002,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20004,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20006,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20008,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20010,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20012,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20014,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20016,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20018,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20020,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20022,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20024,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20026,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20028,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20030,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20032,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20034,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20036,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20038,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20040,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20042,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20044,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20046,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20048,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20050,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20052,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20054,
    65.84597,
    13.19858,
    12.2,
    0
  ],
  [
    20056,
    65.84596,
    13.19855,
    12.2,
    1.5
  ],
  [
    20058,
    65.84598,
    13.19852,
    14.2,
    1.87
  ],
  [
    20060,
    65.84602,
    13.19848,
    14.2,
    2.5
  ],
  [
    20062,
    65.84607,
    13.19843,
    14.2,
    3.22
  ],
  [
    20064,
    65.84613,
    13.19836,
    14.1,
    3.93
  ],
  [
    20067,
    65.84624,
    13.19824,
    14,
    4.58
  ],
  [
    20069,
    65.84632,
    13.19815,
    14,
    5.16
  ],
  [
    20071,
    65.84641,
    13.19805,
    14,
    5.45
  ],
  [
    20073,
    65.84652,
    13.19794,
    13.9,
    6.21
  ],
  [
    20075,
    65.84663,
    13.19782,
    13.9,
    6.21
  ],
  [
    20077,
    65.84674,
    13.19769,
    13.9,
    6.21
  ],
  [
    20079,
    65.84687,
    13.19755,
    13.9,
    7.33
  ],
  [
    20081,
    65.847,
    13.19741,
    13.8,
    7.33
  ],
  [
    20083,
    65.84714,
    13.19726,
    13.8,
    8.41
  ],
  [
    20085,
    65.84728,
    13.1971,
    13.8,
    8.85
  ],
  [
    20087,
    65.84744,
    13.19693,
    13.8,
    9.29
  ],
  [
    20089,
    65.8476,
    13.19676,
    13.8,
    9.9
  ],
  [
    20091,
    65.84776,
    13.19658,
    13.8,
    10.29
  ],
  [
    20093,
    65.84794,
    13.19641,
    13.8,
    10.7
  ],
  [
    20095,
    65.84812,
    13.19625,
    13.7,
    11.09
  ],
  [
    20097,
    65.84831,
    13.1961,
    13.5,
    11.3
  ],
  [
    20099,
    65.84852,
    13.19597,
    13.3,
    11.92
  ],
  [
    20101,
    65.84873,
    13.19588,
    13.4,
    12.33
  ],
  [
    20103,
    65.84896,
    13.19581,
    13.4,
    12.73
  ],
  [
    20105,
    65.84919,
    13.19577,
    13.5,
    13.11
  ],
  [
    20107,
    65.84943,
    13.19576,
    13.6,
    13.31
  ],
  [
    20109,
    65.84968,
    13.1958,
    13.7,
    13.86
  ],
  [
    20111,
    65.84993,
    13.19585,
    13.8,
    14.06
  ],
  [
    20113,
    65.85018,
    13.19593,
    13.9,
    14.11
  ],
  [
    20115,
    65.85043,
    13.19601,
    14,
    14.09
  ],
  [
    20117,
    65.85068,
    13.19609,
    14.2,
    14.09
  ],
  [
    20119,
    65.85093,
    13.19617,
    14.2,
    14.05
  ],
  [
    20121,
    65.85118,
    13.19624,
    14.3,
    14.02
  ],
  [
    20123,
    65.85143,
    13.1963,
    14.3,
    14.04
  ],
  [
    20125,
    65.85169,
    13.19633,
    14.2,
    14.11
  ],
  [
    20127,
    65.85194,
    13.19633,
    14,
    14.23
  ],
  [
    20129,
    65.8522,
    13.19628,
    13.8,
    14.39
  ],
  [
    20131,
    65.85245,
    13.19619,
    13.6,
    14.58
  ],
  [
    20133,
    65.85271,
    13.19606,
    13.4,
    14.77
  ],
  [
    20135,
    65.85297,
    13.19588,
    13.2,
    14.97
  ],
  [
    20137,
    65.85323,
    13.19567,
    13,
    15.2
  ],
  [
    20139,
    65.85348,
    13.19544,
    12.8,
    15.27
  ],
  [
    20141,
    65.85375,
    13.19523,
    12.8,
    15.79
  ],
  [
    20143,
    65.85402,
    13.19504,
    12.6,
    16
  ],
  [
    20145,
    65.85431,
    13.1949,
    12.4,
    16.32
  ],
  [
    20147,
    65.8546,
    13.19481,
    12.1,
    16.66
  ],
  [
    20149,
    65.8549,
    13.19477,
    11.9,
    17.01
  ],
  [
    20151,
    65.85521,
    13.19476,
    11.8,
    17.44
  ],
  [
    20153,
    65.85553,
    13.19476,
    11.7,
    17.92
  ],
  [
    20155,
    65.85586,
    13.19475,
    11.7,
    18.37
  ],
  [
    20157,
    65.85619,
    13.1947,
    11.6,
    18.8
  ],
  [
    20159,
    65.85654,
    13.19463,
    11.6,
    19.2
  ],
  [
    20161,
    65.85688,
    13.19452,
    11.6,
    19.6
  ],
  [
    20163,
    65.85723,
    13.19437,
    11.6,
    19.92
  ],
  [
    20165,
    65.85759,
    13.19419,
    11.6,
    20.27
  ],
  [
    20167,
    65.85794,
    13.19396,
    11.6,
    20.61
  ],
  [
    20169,
    65.8583,
    13.19368,
    11.5,
    20.91
  ],
  [
    20171,
    65.85865,
    13.19333,
    11.5,
    21.22
  ],
  [
    20173,
    65.85899,
    13.19292,
    11.5,
    21.52
  ],
  [
    20175,
    65.85933,
    13.19245,
    11.4,
    21.84
  ],
  [
    20178,
    65.85984,
    13.19169,
    11.6,
    22.15
  ],
  [
    20180,
    65.86019,
    13.19117,
    11.7,
    22.45
  ],
  [
    20182,
    65.86054,
    13.19069,
    11.7,
    22.54
  ],
  [
    20184,
    65.86092,
    13.19029,
    11.7,
    22.83
  ],
  [
    20186,
    65.86132,
    13.18997,
    12.2,
    22.83
  ],
  [
    20188,
    65.86173,
    13.18974,
    12.2,
    23.43
  ],
  [
    20190,
    65.86215,
    13.1896,
    12.2,
    23.43
  ],
  [
    20192,
    65.86258,
    13.18956,
    12.3,
    23.43
  ],
  [
    20194,
    65.86302,
    13.18959,
    12.3,
    24.17
  ],
  [
    20196,
    65.86345,
    13.18962,
    12.4,
    24.39
  ],
  [
    20198,
    65.8639,
    13.18959,
    12.6,
    24.6
  ],
  [
    20200,
    65.86434,
    13.18944,
    12.9,
    24.82
  ],
  [
    20202,
    65.86477,
    13.18916,
    13,
    25.02
  ],
  [
    20204,
    65.86519,
    13.18876,
    13.1,
    25.15
  ],
  [
    20206,
    65.86559,
    13.18824,
    13.2,
    25.19
  ],
  [
    20208,
    65.86596,
    13.18761,
    13.3,
    25.23
  ],
  [
    20210,
    65.86631,
    13.18688,
    13.5,
    25.25
  ],
  [
    20212,
    65.86663,
    13.1861,
    13.7,
    25.27
  ],
  [
    20214,
    65.86696,
    13.18532,
    13.9,
    25.28
  ],
  [
    20216,
    65.86728,
    13.18456,
    14.1,
    25.27
  ],
  [
    20218,
    65.86762,
    13.18382,
    14.2,
    25.26
  ],
  [
    20220,
    65.86797,
    13.1831,
    14.2,
    25.25
  ],
  [
    20222,
    65.86832,
    13.18241,
    14.3,
    25.26
  ],
  [
    20224,
    65.86867,
    13.1817,
    14.3,
    25.27
  ],
  [
    20226,
    65.86901,
    13.18096,
    14.3,
    25.26
  ],
  [
    20228,
    65.86933,
    13.18017,
    14.4,
    25.24
  ],
  [
    20230,
    65.86964,
    13.17936,
    14.4,
    25.25
  ],
  [
    20232,
    65.86995,
    13.17856,
    14.4,
    25.26
  ],
  [
    20234,
    65.87029,
    13.17782,
    14.4,
    25.26
  ],
  [
    20236,
    65.87065,
    13.17715,
    14.4,
    25.24
  ],
  [
    20238,
    65.87102,
    13.17651,
    14.4,
    25.2
  ],
  [
    20240,
    65.87138,
    13.17584,
    14.5,
    25.14
  ],
  [
    20242,
    65.87171,
    13.17509,
    14.6,
    25.04
  ],
  [
    20244,
    65.87199,
    13.17424,
    14.5,
    24.91
  ],
  [
    20246,
    65.87223,
    13.17333,
    14.5,
    24.83
  ],
  [
    20248,
    65.87245,
    13.17237,
    14.6,
    24.79
  ],
  [
    20250,
    65.87266,
    13.17141,
    15,
    24.75
  ],
  [
    20252,
    65.8729,
    13.17051,
    15.3,
    24.67
  ],
  [
    20254,
    65.87319,
    13.16968,
    15.5,
    24.53
  ],
  [
    20256,
    65.87351,
    13.16895,
    15.6,
    24.46
  ],
  [
    20258,
    65.87387,
    13.16833,
    15.4,
    24.44
  ],
  [
    20260,
    65.87425,
    13.16782,
    15.1,
    24.49
  ],
  [
    20262,
    65.87466,
    13.16741,
    14.7,
    24.6
  ],
  [
    20264,
    65.87508,
    13.16705,
    14.5,
    24.73
  ],
  [
    20266,
    65.8755,
    13.16669,
    14.5,
    24.88
  ],
  [
    20268,
    65.87592,
    13.16631,
    14.4,
    25.01
  ],
  [
    20270,
    65.87634,
    13.16592,
    14.4,
    25.1
  ],
  [
    20272,
    65.87676,
    13.16551,
    14.5,
    25.18
  ],
  [
    20274,
    65.87717,
    13.16509,
    14.6,
    25.23
  ],
  [
    20276,
    65.87759,
    13.16465,
    14.6,
    25.23
  ],
  [
    20278,
    65.87801,
    13.16422,
    14.7,
    25.26
  ],
  [
    20280,
    65.87844,
    13.16387,
    14.9,
    25.28
  ],
  [
    20282,
    65.87888,
    13.16364,
    14.8,
    25.28
  ],
  [
    20285,
    65.87955,
    13.16347,
    14.6,
    25.32
  ],
  [
    20287,
    65.88001,
    13.16338,
    14.5,
    25.34
  ],
  [
    20289,
    65.88046,
    13.16322,
    14.5,
    25.41
  ],
  [
    20291,
    65.8809,
    13.16297,
    14.5,
    25.41
  ],
  [
    20293,
    65.88134,
    13.16262,
    14.5,
    25.51
  ],
  [
    20295,
    65.88176,
    13.16218,
    14.5,
    25.51
  ],
  [
    20297,
    65.88217,
    13.16167,
    14.6,
    25.51
  ],
  [
    20299,
    65.88257,
    13.16112,
    14.7,
    25.66
  ],
  [
    20301,
    65.88298,
    13.16058,
    14.9,
    25.66
  ],
  [
    20303,
    65.8834,
    13.16011,
    15.1,
    25.74
  ],
  [
    20305,
    65.88383,
    13.15976,
    15.1,
    25.72
  ],
  [
    20307,
    65.88428,
    13.15955,
    15.1,
    25.56
  ],
  [
    20309,
    65.88474,
    13.15948,
    15.2,
    25.49
  ],
  [
    20311,
    65.88519,
    13.15947,
    15.4,
    25.49
  ],
  [
    20313,
    65.88565,
    13.15947,
    15.7,
    25.49
  ],
  [
    20354,
    65.89458,
    13.15883,
    23.2,
    25.63
  ],
  [
    20356,
    65.89493,
    13.15932,
    12.7,
    25.47
  ],
  [
    20358,
    65.89527,
    13.16003,
    11.2,
    25.39
  ],
  [
    20360,
    65.89561,
    13.16076,
    9.9,
    25.33
  ],
  [
    20362,
    65.89597,
    13.16144,
    9.9,
    25.29
  ],
  [
    20364,
    65.89636,
    13.16203,
    9.8,
    25.17
  ],
  [
    20366,
    65.89676,
    13.16254,
    9.6,
    25.11
  ],
  [
    20368,
    65.89717,
    13.16299,
    10.6,
    25.07
  ],
  [
    20370,
    65.89759,
    13.16341,
    11,
    25.02
  ],
  [
    20372,
    65.898,
    13.16384,
    11.1,
    24.95
  ],
  [
    20374,
    65.89841,
    13.16425,
    11.2,
    24.4
  ],
  [
    20376,
    65.89881,
    13.16466,
    11.3,
    23.74
  ],
  [
    20378,
    65.89919,
    13.16505,
    11.5,
    22.98
  ],
  [
    20380,
    65.89956,
    13.16544,
    11.9,
    22.17
  ],
  [
    20382,
    65.89992,
    13.16581,
    12.3,
    21.36
  ],
  [
    20384,
    65.90027,
    13.16616,
    12.9,
    20.53
  ],
  [
    20386,
    65.9006,
    13.1665,
    13.4,
    19.64
  ],
  [
    20388,
    65.90092,
    13.1668,
    13.9,
    18.78
  ],
  [
    20390,
    65.90123,
    13.16703,
    14,
    18.01
  ],
  [
    20392,
    65.90154,
    13.16719,
    14.1,
    17.31
  ],
  [
    20394,
    65.90184,
    13.16728,
    14.2,
    16.64
  ],
  [
    20396,
    65.90214,
    13.16731,
    14.6,
    16.02
  ],
  [
    20398,
    65.90242,
    13.1673,
    14.9,
    15.43
  ],
  [
    20400,
    65.90269,
    13.16725,
    15.5,
    14.89
  ],
  [
    20402,
    65.90295,
    13.16721,
    15.9,
    14.41
  ],
  [
    20404,
    65.90321,
    13.16717,
    16.4,
    13.98
  ],
  [
    20406,
    65.90346,
    13.16715,
    16.8,
    13.76
  ],
  [
    20408,
    65.9037,
    13.16716,
    17,
    13.63
  ],
  [
    20410,
    65.90394,
    13.1672,
    17,
    13.59
  ],
  [
    20412,
    65.90418,
    13.16729,
    16.8,
    13.66
  ],
  [
    20414,
    65.90442,
    13.16741,
    16.5,
    13.78
  ],
  [
    20416,
    65.90466,
    13.16758,
    16.2,
    13.94
  ],
  [
    20418,
    65.9049,
    13.16779,
    15.9,
    14.14
  ],
  [
    20420,
    65.90513,
    13.16804,
    15.6,
    14.35
  ],
  [
    20422,
    65.90536,
    13.16834,
    15.3,
    14.53
  ],
  [
    20424,
    65.90558,
    13.16869,
    15.3,
    14.66
  ],
  [
    20426,
    65.90579,
    13.16906,
    14.7,
    14.77
  ],
  [
    20428,
    65.90601,
    13.16946,
    14.4,
    14.87
  ],
  [
    20430,
    65.90622,
    13.16985,
    14.3,
    14.94
  ],
  [
    20433,
    65.90655,
    13.17041,
    14.1,
    15
  ],
  [
    20435,
    65.90678,
    13.17075,
    14,
    15.03
  ],
  [
    20437,
    65.90702,
    13.17106,
    13.8,
    15.04
  ],
  [
    20439,
    65.90726,
    13.17133,
    13.8,
    15.04
  ],
  [
    20441,
    65.90752,
    13.17156,
    13.6,
    15.04
  ],
  [
    20443,
    65.90777,
    13.17176,
    13.7,
    15.02
  ],
  [
    20445,
    65.90804,
    13.17191,
    13.8,
    15.02
  ],
  [
    20447,
    65.9083,
    13.17203,
    13.8,
    15.02
  ],
  [
    20449,
    65.90857,
    13.17211,
    13.9,
    15.03
  ],
  [
    20451,
    65.90884,
    13.17214,
    14,
    15.02
  ],
  [
    20453,
    65.90911,
    13.1721,
    14.1,
    14.99
  ],
  [
    20455,
    65.90937,
    13.17201,
    14.3,
    14.93
  ],
  [
    20457,
    65.90963,
    13.17185,
    14.5,
    14.94
  ],
  [
    20459,
    65.90988,
    13.17163,
    14.6,
    15.04
  ],
  [
    20461,
    65.91013,
    13.17134,
    14.8,
    15.13
  ],
  [
    20463,
    65.91036,
    13.17099,
    14.9,
    15.23
  ],
  [
    20465,
    65.91058,
    13.17059,
    15,
    15.37
  ],
  [
    20467,
    65.91079,
    13.17012,
    15.1,
    15.42
  ],
  [
    20469,
    65.91097,
    13.1696,
    15.2,
    15.71
  ],
  [
    20471,
    65.91113,
    13.16903,
    15.2,
    15.93
  ],
  [
    20473,
    65.91127,
    13.1684,
    15.2,
    16.18
  ],
  [
    20475,
    65.91139,
    13.16774,
    15.2,
    16.47
  ],
  [
    20477,
    65.91148,
    13.16704,
    15.2,
    16.64
  ],
  [
    20479,
    65.91157,
    13.16632,
    15.1,
    17.26
  ],
  [
    20481,
    65.91166,
    13.16557,
    15.1,
    17.69
  ],
  [
    20483,
    65.91174,
    13.16481,
    15.1,
    18.11
  ],
  [
    20485,
    65.91182,
    13.16402,
    15.2,
    18.52
  ],
  [
    20487,
    65.9119,
    13.16321,
    15.1,
    18.9
  ],
  [
    20489,
    65.91196,
    13.16238,
    15.1,
    19.27
  ],
  [
    20491,
    65.91203,
    13.16154,
    15,
    19.63
  ],
  [
    20493,
    65.91209,
    13.16067,
    15,
    19.98
  ],
  [
    20495,
    65.91214,
    13.15979,
    15,
    20.33
  ],
  [
    20497,
    65.91219,
    13.15889,
    15,
    20.65
  ],
  [
    20499,
    65.91225,
    13.15798,
    15.1,
    20.96
  ],
  [
    20501,
    65.9123,
    13.15706,
    15.3,
    21.25
  ],
  [
    20503,
    65.91239,
    13.15614,
    15.4,
    21.52
  ],
  [
    20505,
    65.91251,
    13.15524,
    15.5,
    21.77
  ],
  [
    20507,
    65.91269,
    13.15438,
    15.5,
    22.02
  ],
  [
    20509,
    65.91293,
    13.15359,
    15.5,
    22.24
  ],
  [
    20511,
    65.91321,
    13.15288,
    15.5,
    22.52
  ],
  [
    20513,
    65.91353,
    13.15228,
    15.4,
    22.78
  ],
  [
    20515,
    65.91389,
    13.1518,
    15.3,
    23.05
  ],
  [
    20517,
    65.91428,
    13.1514,
    15.1,
    23.28
  ],
  [
    20519,
    65.91467,
    13.15102,
    15,
    23.55
  ],
  [
    20521,
    65.91506,
    13.1506,
    14.9,
    23.83
  ],
  [
    20523,
    65.91544,
    13.1501,
    14.9,
    24.1
  ],
  [
    20525,
    65.91581,
    13.14954,
    14.8,
    24.33
  ],
  [
    20527,
    65.91616,
    13.1489,
    14.8,
    24.58
  ],
  [
    20529,
    65.9165,
    13.14818,
    14.9,
    24.82
  ],
  [
    20531,
    65.91682,
    13.1474,
    14.9,
    25.05
  ],
  [
    20533,
    65.9171,
    13.14655,
    14.9,
    25.26
  ],
  [
    20535,
    65.91736,
    13.14562,
    14.9,
    25.46
  ],
  [
    20537,
    65.91758,
    13.14463,
    14.8,
    25.59
  ],
  [
    20539,
    65.91776,
    13.1436,
    15.5,
    25.54
  ],
  [
    20541,
    65.91794,
    13.14257,
    15.5,
    25.54
  ],
  [
    20543,
    65.91813,
    13.14154,
    17,
    25.54
  ],
  [
    20548,
    65.91867,
    13.13912,
    18.6,
    25.95
  ],
  [
    20550,
    65.91889,
    13.13803,
    18.2,
    25.86
  ],
  [
    20552,
    65.9192,
    13.13715,
    17.6,
    25.93
  ],
  [
    20554,
    65.91954,
    13.13637,
    16.9,
    26.01
  ],
  [
    20556,
    65.91992,
    13.1357,
    16.7,
    26.05
  ],
  [
    20558,
    65.92033,
    13.13513,
    16.7,
    26.02
  ],
  [
    20560,
    65.92076,
    13.13468,
    16.1,
    25.94
  ],
  [
    20562,
    65.9212,
    13.13434,
    15.9,
    25.91
  ],
  [
    20564,
    65.92166,
    13.13412,
    15.7,
    25.8
  ],
  [
    20566,
    65.92213,
    13.13401,
    15.6,
    25.74
  ],
  [
    20569,
    65.92282,
    13.13408,
    15.5,
    25.68
  ],
  [
    20571,
    65.92328,
    13.13424,
    15.4,
    25.6
  ],
  [
    20573,
    65.92373,
    13.13443,
    15.3,
    25.53
  ],
  [
    20575,
    65.92419,
    13.13462,
    15.3,
    25.53
  ],
  [
    20577,
    65.92464,
    13.13481,
    15.3,
    25.53
  ],
  [
    20579,
    65.92509,
    13.135,
    15.2,
    25.53
  ],
  [
    20581,
    65.92555,
    13.13519,
    15.2,
    25.53
  ],
  [
    20583,
    65.926,
    13.13535,
    15.2,
    25.65
  ],
  [
    20585,
    65.92646,
    13.13541,
    15.2,
    25.65
  ],
  [
    20587,
    65.92692,
    13.13533,
    15.3,
    25.76
  ],
  [
    20589,
    65.92738,
    13.13511,
    15.5,
    25.83
  ],
  [
    20591,
    65.92782,
    13.13476,
    15.9,
    25.9
  ],
  [
    20593,
    65.92825,
    13.13432,
    16.2,
    25.97
  ],
  [
    20595,
    65.92868,
    13.13388,
    16.9,
    25.7
  ],
  [
    20597,
    65.92912,
    13.13355,
    17.5,
    25.87
  ],
  [
    20599,
    65.92958,
    13.13336,
    17.1,
    26.01
  ],
  [
    20601,
    65.93005,
    13.13334,
    17.3,
    26.07
  ],
  [
    20603,
    65.93052,
    13.13348,
    17.7,
    26.17
  ],
  [
    20605,
    65.93098,
    13.13377,
    18.1,
    26.2
  ],
  [
    20607,
    65.93142,
    13.1342,
    18.4,
    26.21
  ],
  [
    20609,
    65.93185,
    13.1347,
    18.8,
    26.27
  ],
  [
    20611,
    65.93228,
    13.1352,
    19.6,
    26.55
  ],
  [
    20613,
    65.93271,
    13.13571,
    20.2,
    26.42
  ],
  [
    20615,
    65.93314,
    13.13618,
    20.8,
    26.46
  ],
  [
    20617,
    65.93358,
    13.13662,
    21.4,
    26.52
  ],
  [
    20619,
    65.93403,
    13.13701,
    21.7,
    26.47
  ],
  [
    20621,
    65.93448,
    13.13734,
    22.5,
    26.48
  ],
  [
    20623,
    65.93495,
    13.13762,
    23.3,
    26.5
  ],
  [
    20625,
    65.93541,
    13.13784,
    24.1,
    26.5
  ],
  [
    20627,
    65.93588,
    13.13801,
    25,
    26.51
  ],
  [
    20629,
    65.93636,
    13.13812,
    25.8,
    26.52
  ],
  [
    20631,
    65.93684,
    13.1382,
    26.6,
    26.53
  ],
  [
    20633,
    65.93731,
    13.13826,
    27.4,
    26.53
  ],
  [
    20635,
    65.93779,
    13.13833,
    28.2,
    26.56
  ],
  [
    20637,
    65.93826,
    13.13845,
    28.9,
    26.56
  ],
  [
    20639,
    65.93873,
    13.13863,
    29.6,
    26.57
  ],
  [
    20641,
    65.9392,
    13.13886,
    30.4,
    26.57
  ],
  [
    20643,
    65.93966,
    13.13914,
    31.1,
    26.53
  ],
  [
    20645,
    65.94012,
    13.13948,
    31.8,
    26.52
  ],
  [
    20647,
    65.94057,
    13.13987,
    32.4,
    26.51
  ],
  [
    20649,
    65.94101,
    13.14032,
    33.2,
    26.5
  ],
  [
    20651,
    65.94144,
    13.14082,
    33.8,
    26.5
  ],
  [
    20653,
    65.94185,
    13.14137,
    34.5,
    26.48
  ],
  [
    20655,
    65.94226,
    13.14196,
    35.1,
    26.49
  ],
  [
    20657,
    65.94266,
    13.14259,
    35.7,
    26.47
  ],
  [
    20659,
    65.94305,
    13.14326,
    36.3,
    26.46
  ],
  [
    20661,
    65.94344,
    13.14394,
    36.9,
    26.45
  ],
  [
    20663,
    65.94381,
    13.14466,
    37.6,
    26.45
  ],
  [
    20665,
    65.94418,
    13.1454,
    38.1,
    26.56
  ],
  [
    20667,
    65.94454,
    13.14616,
    38.6,
    26.45
  ],
  [
    20669,
    65.94489,
    13.14694,
    39.1,
    26.43
  ],
  [
    20671,
    65.94523,
    13.14774,
    39.5,
    26.41
  ],
  [
    20673,
    65.94556,
    13.14857,
    39.5,
    26.42
  ],
  [
    20675,
    65.94589,
    13.14942,
    40.4,
    26.39
  ],
  [
    20677,
    65.9462,
    13.15029,
    41.1,
    26.43
  ],
  [
    20679,
    65.94651,
    13.15118,
    41.5,
    26.49
  ],
  [
    20681,
    65.9468,
    13.1521,
    41.6,
    26.57
  ],
  [
    20684,
    65.94722,
    13.15351,
    41.5,
    26.69
  ],
  [
    20686,
    65.9475,
    13.15449,
    41.3,
    26.88
  ],
  [
    20688,
    65.94776,
    13.15549,
    41,
    27.07
  ],
  [
    20690,
    65.94801,
    13.15652,
    40.9,
    27.07
  ],
  [
    20692,
    65.94826,
    13.15756,
    40.9,
    27.07
  ],
  [
    20694,
    65.94851,
    13.15861,
    41.1,
    27.55
  ],
  [
    20696,
    65.94877,
    13.15966,
    40.9,
    27.55
  ],
  [
    20698,
    65.94904,
    13.1607,
    40.8,
    27.9
  ],
  [
    20700,
    65.94932,
    13.16172,
    40.9,
    27.9
  ],
  [
    20702,
    65.94961,
    13.16272,
    41,
    27.86
  ],
  [
    20704,
    65.94991,
    13.16369,
    41.3,
    27.75
  ],
  [
    20706,
    65.95022,
    13.16464,
    41.6,
    27.64
  ],
  [
    20708,
    65.95054,
    13.16555,
    42.1,
    27.48
  ],
  [
    20710,
    65.95088,
    13.1664,
    42.7,
    27.3
  ],
  [
    20712,
    65.95125,
    13.16718,
    43.2,
    27.21
  ],
  [
    20714,
    65.95164,
    13.1679,
    43.8,
    27.04
  ],
  [
    20716,
    65.95203,
    13.1686,
    44.4,
    26.97
  ],
  [
    20718,
    65.95242,
    13.16929,
    44.9,
    26.85
  ],
  [
    20720,
    65.95281,
    13.16999,
    45.6,
    26.86
  ],
  [
    20722,
    65.9532,
    13.17068,
    46.1,
    26.87
  ],
  [
    20724,
    65.95359,
    13.17137,
    46.6,
    26.92
  ],
  [
    20726,
    65.95398,
    13.17207,
    47.1,
    26.97
  ],
  [
    20728,
    65.95437,
    13.17277,
    47.7,
    27.01
  ],
  [
    20730,
    65.95474,
    13.17354,
    48.3,
    27.08
  ],
  [
    20732,
    65.95508,
    13.17439,
    48.8,
    27.08
  ],
  [
    20734,
    65.95538,
    13.17533,
    49.1,
    27.17
  ],
  [
    20736,
    65.95564,
    13.17634,
    49.2,
    27.16
  ],
  [
    20738,
    65.95587,
    13.17739,
    49.4,
    27.16
  ],
  [
    20740,
    65.95611,
    13.17844,
    49.6,
    27.16
  ],
  [
    20761,
    65.95718,
    13.19077,
    52.3,
    27.33
  ],
  [
    20763,
    65.95746,
    13.19171,
    50.2,
    27.07
  ],
  [
    20765,
    65.95776,
    13.19264,
    47.9,
    27.13
  ],
  [
    20767,
    65.95805,
    13.19359,
    45.6,
    27.06
  ],
  [
    20769,
    65.95833,
    13.19455,
    45,
    27
  ],
  [
    20771,
    65.95859,
    13.19555,
    45,
    26.91
  ],
  [
    20773,
    65.95883,
    13.19657,
    44.6,
    26.82
  ],
  [
    20775,
    65.95904,
    13.19762,
    44.1,
    26.78
  ],
  [
    20777,
    65.95923,
    13.1987,
    43,
    26.81
  ],
  [
    20779,
    65.9594,
    13.1998,
    41.8,
    26.89
  ],
  [
    20781,
    65.95954,
    13.20093,
    40.9,
    27.02
  ],
  [
    20783,
    65.95967,
    13.20208,
    39.8,
    27.14
  ],
  [
    20785,
    65.9598,
    13.20323,
    38.6,
    27.26
  ],
  [
    20787,
    65.95995,
    13.20437,
    37.6,
    27.46
  ],
  [
    20789,
    65.96015,
    13.20548,
    36.8,
    27.38
  ],
  [
    20791,
    65.96038,
    13.20653,
    36.2,
    26.96
  ],
  [
    20793,
    65.96065,
    13.20751,
    35.3,
    26.46
  ],
  [
    20795,
    65.96094,
    13.2084,
    34.5,
    25.92
  ],
  [
    20797,
    65.96127,
    13.20921,
    33.9,
    25.58
  ],
  [
    20799,
    65.96161,
    13.20994,
    33.3,
    25.45
  ],
  [
    20801,
    65.96198,
    13.2106,
    32.8,
    25.41
  ],
  [
    20803,
    65.96237,
    13.21117,
    32.5,
    25.46
  ],
  [
    20805,
    65.96278,
    13.21168,
    32.5,
    25.53
  ],
  [
    20807,
    65.9632,
    13.21216,
    32.1,
    25.57
  ],
  [
    20809,
    65.96362,
    13.21264,
    32,
    25.6
  ],
  [
    20812,
    65.96424,
    13.21337,
    32,
    25.59
  ],
  [
    20814,
    65.96465,
    13.21389,
    32.1,
    25.59
  ],
  [
    20816,
    65.96504,
    13.21446,
    32.2,
    25.59
  ],
  [
    20818,
    65.96542,
    13.21511,
    32.3,
    25.48
  ],
  [
    20820,
    65.96577,
    13.21581,
    32.3,
    25.48
  ],
  [
    20822,
    65.9661,
    13.21658,
    32.5,
    25.48
  ],
  [
    20824,
    65.96641,
    13.21739,
    32.7,
    25.34
  ],
  [
    20826,
    65.96672,
    13.21821,
    32.8,
    25.34
  ],
  [
    20828,
    65.96704,
    13.21899,
    32.9,
    25.15
  ],
  [
    20830,
    65.96738,
    13.2197,
    32.9,
    25.05
  ],
  [
    20832,
    65.96775,
    13.22033,
    32.7,
    25.01
  ],
  [
    20834,
    65.96814,
    13.22087,
    32.5,
    24.97
  ],
  [
    20836,
    65.96854,
    13.22139,
    32.3,
    25.03
  ],
  [
    20838,
    65.96893,
    13.22191,
    32,
    25.1
  ],
  [
    20840,
    65.96931,
    13.2225,
    31.7,
    25.16
  ],
  [
    20842,
    65.96968,
    13.22316,
    31.5,
    25.2
  ],
  [
    20844,
    65.97002,
    13.22388,
    31.2,
    25.29
  ],
  [
    20846,
    65.97036,
    13.22463,
    30.9,
    25.35
  ],
  [
    20848,
    65.97069,
    13.22538,
    30.7,
    25.4
  ],
  [
    20850,
    65.97104,
    13.22611,
    30.5,
    25.48
  ],
  [
    20852,
    65.97139,
    13.22682,
    30.3,
    25.49
  ],
  [
    20854,
    65.97175,
    13.22752,
    30.2,
    25.55
  ],
  [
    20856,
    65.97212,
    13.2282,
    30.1,
    25.58
  ],
  [
    20858,
    65.97248,
    13.22889,
    30.1,
    25.64
  ],
  [
    20860,
    65.97285,
    13.22959,
    30,
    25.68
  ],
  [
    20862,
    65.97321,
    13.23028,
    30,
    25.7
  ],
  [
    20864,
    65.97358,
    13.23097,
    30,
    25.74
  ],
  [
    20866,
    65.97394,
    13.23166,
    29.8,
    25.76
  ],
  [
    20868,
    65.97431,
    13.23236,
    29.7,
    25.8
  ],
  [
    20870,
    65.97468,
    13.23305,
    29.6,
    25.84
  ],
  [
    20872,
    65.97504,
    13.23375,
    29.7,
    25.85
  ],
  [
    20874,
    65.97541,
    13.23444,
    30,
    25.83
  ],
  [
    20876,
    65.97578,
    13.23514,
    30.3,
    25.8
  ],
  [
    20878,
    65.97614,
    13.23583,
    30.5,
    25.75
  ],
  [
    20880,
    65.97651,
    13.23653,
    30.7,
    25.69
  ],
  [
    20882,
    65.97687,
    13.23722,
    30.9,
    25.63
  ],
  [
    20884,
    65.97723,
    13.23793,
    31.1,
    25.57
  ],
  [
    20886,
    65.97758,
    13.23867,
    31.3,
    25.55
  ],
  [
    20888,
    65.97791,
    13.23944,
    31.2,
    25.45
  ],
  [
    20890,
    65.97823,
    13.24023,
    31.2,
    25.39
  ],
  [
    20892,
    65.97856,
    13.24101,
    31.1,
    25.35
  ],
  [
    20894,
    65.97888,
    13.2418,
    31,
    25.3
  ],
  [
    20896,
    65.9792,
    13.24259,
    31.1,
    25.26
  ],
  [
    20898,
    65.97952,
    13.24337,
    30.9,
    25.2
  ],
  [
    20900,
    65.97984,
    13.24415,
    31,
    25.16
  ],
  [
    20902,
    65.98016,
    13.24493,
    31,
    25.11
  ],
  [
    20904,
    65.98048,
    13.24571,
    31.1,
    25.08
  ],
  [
    20906,
    65.9808,
    13.24648,
    31.2,
    25.02
  ],
  [
    20908,
    65.98112,
    13.24726,
    31.3,
    24.93
  ],
  [
    20910,
    65.98144,
    13.24802,
    31.5,
    24.85
  ],
  [
    20912,
    65.98178,
    13.24871,
    31.6,
    24.77
  ],
  [
    20914,
    65.98216,
    13.24929,
    31.7,
    24.79
  ],
  [
    20916,
    65.98256,
    13.24976,
    31.7,
    24.84
  ],
  [
    20918,
    65.98298,
    13.25014,
    31.7,
    24.94
  ],
  [
    20920,
    65.98341,
    13.25048,
    31.7,
    25.11
  ],
  [
    20922,
    65.98384,
    13.25078,
    31.5,
    25.29
  ],
  [
    20924,
    65.98428,
    13.25105,
    31.5,
    25.49
  ],
  [
    20927,
    65.98496,
    13.2514,
    31.6,
    25.68
  ],
  [
    20929,
    65.98542,
    13.25162,
    31.7,
    25.81
  ],
  [
    20931,
    65.98587,
    13.25184,
    32.1,
    25.87
  ],
  [
    20933,
    65.98633,
    13.25207,
    32.5,
    25.99
  ],
  [
    20935,
    65.98679,
    13.25229,
    33,
    25.99
  ],
  [
    20937,
    65.98725,
    13.25252,
    33.3,
    25.99
  ],
  [
    20939,
    65.98771,
    13.25275,
    33.7,
    26.07
  ],
  [
    20941,
    65.98817,
    13.25297,
    34.1,
    26.07
  ],
  [
    20943,
    65.98863,
    13.25319,
    34.5,
    26.06
  ],
  [
    20945,
    65.98909,
    13.25335,
    34.8,
    26.04
  ],
  [
    20947,
    65.98956,
    13.25337,
    35.2,
    25.99
  ],
  [
    20949,
    65.99002,
    13.25325,
    35.7,
    25.91
  ],
  [
    20951,
    65.99047,
    13.25298,
    36.2,
    25.85
  ],
  [
    20953,
    65.99091,
    13.2526,
    36.8,
    25.77
  ],
  [
    20955,
    65.99134,
    13.25221,
    37.5,
    25.68
  ],
  [
    20957,
    65.99179,
    13.2519,
    38.2,
    25.64
  ],
  [
    20959,
    65.99224,
    13.25172,
    38.9,
    25.45
  ],
  [
    20961,
    65.99269,
    13.25168,
    39.5,
    25.31
  ],
  [
    20963,
    65.99315,
    13.25179,
    40,
    25.19
  ],
  [
    20965,
    65.99359,
    13.25204,
    40.3,
    25.13
  ],
  [
    20967,
    65.99401,
    13.2524,
    40.5,
    25.09
  ],
  [
    20969,
    65.99442,
    13.25283,
    40.7,
    25.05
  ],
  [
    20971,
    65.99484,
    13.25328,
    40.9,
    25.09
  ],
  [
    20973,
    65.99525,
    13.25373,
    41.2,
    25.19
  ],
  [
    20975,
    65.99566,
    13.2542,
    41.4,
    25.32
  ],
  [
    20977,
    65.99607,
    13.25467,
    41.6,
    25.38
  ],
  [
    20979,
    65.99648,
    13.25518,
    41.9,
    25.64
  ],
  [
    20981,
    65.99687,
    13.25577,
    42,
    25.76
  ],
  [
    20983,
    65.99724,
    13.25647,
    42.2,
    25.89
  ],
  [
    20985,
    65.99758,
    13.25725,
    42.5,
    25.99
  ],
  [
    20987,
    65.99789,
    13.25811,
    42.8,
    26.07
  ],
  [
    20989,
    65.99819,
    13.25901,
    43,
    26.14
  ],
  [
    20991,
    65.99848,
    13.25991,
    43.3,
    26.2
  ],
  [
    20993,
    65.99878,
    13.26081,
    43.5,
    26.25
  ],
  [
    20995,
    65.99907,
    13.26171,
    43.7,
    26.31
  ],
  [
    20997,
    65.99937,
    13.26261,
    44,
    26.35
  ],
  [
    20999,
    65.99967,
    13.26351,
    44.3,
    26.41
  ],
  [
    21001,
    65.99997,
    13.26442,
    44.6,
    26.45
  ],
  [
    21003,
    66.00027,
    13.26532,
    45,
    26.46
  ],
  [
    21005,
    66.00059,
    13.26618,
    45.4,
    26.48
  ],
  [
    21007,
    66.00093,
    13.26698,
    45.8,
    26.49
  ],
  [
    21009,
    66.0013,
    13.26772,
    46.2,
    26.51
  ],
  [
    21011,
    66.00169,
    13.26839,
    46.8,
    26.53
  ],
  [
    21013,
    66.00209,
    13.26902,
    47.4,
    26.57
  ],
  [
    21015,
    66.0025,
    13.26963,
    47.9,
    26.57
  ],
  [
    21017,
    66.00291,
    13.27024,
    48.5,
    26.61
  ],
  [
    21019,
    66.00332,
    13.27086,
    49.3,
    26.68
  ],
  [
    21021,
    66.00372,
    13.27147,
    49.9,
    26.69
  ],
  [
    21023,
    66.00413,
    13.27208,
    50.5,
    26.76
  ],
  [
    21025,
    66.00454,
    13.2727,
    50.9,
    26.84
  ],
  [
    21027,
    66.00495,
    13.27332,
    51.3,
    26.93
  ],
  [
    21029,
    66.00537,
    13.27394,
    51.6,
    27.05
  ],
  [
    21031,
    66.00578,
    13.27456,
    51.8,
    27.19
  ],
  [
    21033,
    66.0062,
    13.27519,
    51.9,
    27.35
  ],
  [
    21035,
    66.00662,
    13.27582,
    51.9,
    27.5
  ],
  [
    21037,
    66.00704,
    13.27646,
    52.3,
    27.57
  ],
  [
    21039,
    66.00746,
    13.27709,
    52.6,
    27.63
  ],
  [
    21041,
    66.00789,
    13.27773,
    52.8,
    27.63
  ],
  [
    21043,
    66.00831,
    13.27837,
    53,
    27.6
  ],
  [
    21046,
    66.00892,
    13.2794,
    53.4,
    27.6
  ],
  [
    21048,
    66.00929,
    13.28021,
    53.6,
    27.58
  ],
  [
    21050,
    66.00962,
    13.28111,
    53.7,
    27.58
  ],
  [
    21052,
    66.00991,
    13.2821,
    53.6,
    27.58
  ],
  [
    21054,
    66.01015,
    13.28315,
    53.5,
    27.46
  ],
  [
    21056,
    66.01034,
    13.28427,
    53.1,
    27.46
  ],
  [
    21058,
    66.01049,
    13.28543,
    52.9,
    27.5
  ],
  [
    21060,
    66.01063,
    13.28658,
    53,
    27.65
  ],
  [
    21062,
    66.01073,
    13.2878,
    53.7,
    28.38
  ],
  [
    21064,
    66.01084,
    13.28899,
    53.4,
    27.58
  ],
  [
    21066,
    66.011,
    13.29015,
    52.9,
    27.63
  ],
  [
    21068,
    66.01119,
    13.29127,
    52.2,
    27.7
  ],
  [
    21070,
    66.01141,
    13.29237,
    51.8,
    27.75
  ],
  [
    21072,
    66.01165,
    13.29345,
    51.5,
    27.8
  ],
  [
    21074,
    66.01189,
    13.29451,
    51.3,
    27.93
  ],
  [
    21076,
    66.01215,
    13.29556,
    51,
    28.03
  ],
  [
    21078,
    66.01242,
    13.2966,
    50.7,
    28.12
  ],
  [
    21080,
    66.01271,
    13.29763,
    50.4,
    28.15
  ],
  [
    21082,
    66.013,
    13.29865,
    50,
    28.14
  ],
  [
    21084,
    66.0133,
    13.29964,
    49.8,
    28.1
  ],
  [
    21086,
    66.01361,
    13.30062,
    49.4,
    28.08
  ],
  [
    21088,
    66.01393,
    13.30158,
    49.1,
    28.04
  ],
  [
    21090,
    66.01425,
    13.30251,
    49.1,
    27.94
  ],
  [
    21092,
    66.01459,
    13.30343,
    49.2,
    27.89
  ],
  [
    21094,
    66.01493,
    13.30433,
    49.4,
    27.68
  ],
  [
    21096,
    66.01527,
    13.30521,
    49.6,
    27.5
  ],
  [
    21098,
    66.01562,
    13.30606,
    49.8,
    27.33
  ],
  [
    21100,
    66.01598,
    13.30687,
    50,
    27.16
  ],
  [
    21102,
    66.01635,
    13.30764,
    50.1,
    27.02
  ],
  [
    21104,
    66.01674,
    13.30836,
    50.1,
    26.96
  ],
  [
    21106,
    66.01714,
    13.30902,
    50.1,
    26.89
  ],
  [
    21108,
    66.01756,
    13.30961,
    49.9,
    26.86
  ],
  [
    21110,
    66.01799,
    13.31015,
    49.7,
    26.87
  ],
  [
    21112,
    66.01843,
    13.31065,
    49.5,
    26.89
  ],
  [
    21114,
    66.01887,
    13.31112,
    49.4,
    26.93
  ],
  [
    21116,
    66.01932,
    13.31158,
    49.3,
    26.98
  ],
  [
    21118,
    66.01977,
    13.31202,
    49.1,
    27.03
  ],
  [
    21120,
    66.02023,
    13.3124,
    48.9,
    27.09
  ],
  [
    21122,
    66.02071,
    13.31266,
    48.6,
    27.14
  ],
  [
    21124,
    66.02119,
    13.31279,
    48.5,
    27.16
  ],
  [
    21126,
    66.02168,
    13.3128,
    48.7,
    27.11
  ],
  [
    21128,
    66.02216,
    13.31267,
    49,
    27.03
  ],
  [
    21130,
    66.02264,
    13.31242,
    49.3,
    26.92
  ],
  [
    21132,
    66.02311,
    13.3121,
    49.7,
    26.84
  ],
  [
    21134,
    66.02357,
    13.3118,
    50.3,
    26.69
  ],
  [
    21136,
    66.02404,
    13.31157,
    50.7,
    26.52
  ],
  [
    21138,
    66.02452,
    13.31148,
    51.2,
    26.35
  ],
  [
    21140,
    66.02499,
    13.3115,
    51.6,
    26.17
  ],
  [
    21142,
    66.02545,
    13.31165,
    52.1,
    26.08
  ],
  [
    21144,
    66.02591,
    13.31191,
    52.4,
    25.98
  ],
  [
    21146,
    66.02635,
    13.31229,
    52.4,
    25.89
  ],
  [
    21148,
    66.02677,
    13.31278,
    52.4,
    25.8
  ],
  [
    21150,
    66.02716,
    13.31337,
    53.5,
    25.73
  ],
  [
    21152,
    66.02753,
    13.31405,
    53.8,
    25.69
  ],
  [
    21154,
    66.02789,
    13.31476,
    53.9,
    25.67
  ],
  [
    21156,
    66.02825,
    13.31548,
    53.9,
    25.73
  ],
  [
    21159,
    66.02878,
    13.31656,
    54,
    25.87
  ],
  [
    21161,
    66.02915,
    13.31729,
    54,
    26.08
  ],
  [
    21163,
    66.02951,
    13.31802,
    54,
    26.3
  ],
  [
    21165,
    66.02988,
    13.31876,
    54,
    26.3
  ],
  [
    21167,
    66.03025,
    13.31951,
    54,
    26.3
  ],
  [
    21169,
    66.03063,
    13.32026,
    54.1,
    26.83
  ],
  [
    21171,
    66.031,
    13.32102,
    54,
    26.83
  ],
  [
    21173,
    66.03139,
    13.32176,
    53.9,
    27.34
  ],
  [
    21175,
    66.03179,
    13.32248,
    53.7,
    27.49
  ],
  [
    21177,
    66.0322,
    13.32316,
    53.7,
    27.57
  ],
  [
    21179,
    66.03262,
    13.32379,
    54,
    27.58
  ],
  [
    21181,
    66.03305,
    13.32439,
    54.3,
    27.53
  ],
  [
    21183,
    66.03349,
    13.32494,
    54.8,
    27.42
  ],
  [
    21185,
    66.03394,
    13.32544,
    55.5,
    27.28
  ],
  [
    21187,
    66.03439,
    13.32591,
    56.1,
    27.21
  ],
  [
    21189,
    66.03484,
    13.32632,
    56.8,
    27
  ],
  [
    21191,
    66.0353,
    13.32669,
    57.4,
    26.86
  ],
  [
    21193,
    66.03577,
    13.32703,
    58.1,
    26.79
  ],
  [
    21195,
    66.03623,
    13.32734,
    58.7,
    26.67
  ],
  [
    21197,
    66.03669,
    13.32766,
    59.2,
    26.66
  ],
  [
    21199,
    66.03715,
    13.32798,
    59.9,
    26.57
  ],
  [
    21201,
    66.03761,
    13.3283,
    60.5,
    26.54
  ],
  [
    21203,
    66.03806,
    13.32867,
    61.1,
    26.57
  ],
  [
    21205,
    66.0385,
    13.32914,
    61.7,
    26.6
  ],
  [
    21207,
    66.03892,
    13.32971,
    62.2,
    26.65
  ],
  [
    21209,
    66.03931,
    13.33038,
    62.5,
    26.72
  ],
  [
    21211,
    66.03968,
    13.33115,
    62.8,
    26.84
  ],
  [
    21213,
    66.04001,
    13.332,
    62.9,
    26.99
  ],
  [
    21215,
    66.04031,
    13.33294,
    62.8,
    27.17
  ],
  [
    21217,
    66.04058,
    13.33396,
    62.3,
    27.39
  ],
  [
    21219,
    66.0408,
    13.33504,
    62.1,
    27.62
  ],
  [
    21221,
    66.04098,
    13.33619,
    61.8,
    27.84
  ],
  [
    21223,
    66.04111,
    13.33737,
    61.2,
    27.82
  ],
  [
    21225,
    66.04121,
    13.33858,
    60.3,
    27.84
  ],
  [
    21227,
    66.0413,
    13.33979,
    59.4,
    27.91
  ],
  [
    21229,
    66.04137,
    13.34102,
    58.7,
    28
  ],
  [
    21231,
    66.04144,
    13.34225,
    58.1,
    28.05
  ],
  [
    21233,
    66.04149,
    13.34349,
    57.6,
    28.07
  ],
  [
    21235,
    66.0415,
    13.34473,
    57.6,
    28.04
  ],
  [
    21237,
    66.04147,
    13.34596,
    57.1,
    27.96
  ],
  [
    21239,
    66.04139,
    13.34719,
    56.6,
    27.91
  ],
  [
    21241,
    66.0413,
    13.3484,
    56,
    27.82
  ],
  [
    21243,
    66.04123,
    13.34962,
    55.5,
    27.78
  ],
  [
    21245,
    66.0412,
    13.35085,
    55.2,
    27.72
  ],
  [
    21247,
    66.04121,
    13.35207,
    54.8,
    27.66
  ],
  [
    21249,
    66.04126,
    13.35328,
    54.4,
    27.58
  ],
  [
    21251,
    66.04134,
    13.35448,
    54.1,
    27.53
  ],
  [
    21253,
    66.04143,
    13.35568,
    53.9,
    27.52
  ],
  [
    21255,
    66.04152,
    13.35687,
    53.8,
    27.54
  ],
  [
    21257,
    66.04162,
    13.35806,
    53.8,
    27.52
  ],
  [
    21259,
    66.04171,
    13.35926,
    53.8,
    27.5
  ],
  [
    21261,
    66.0418,
    13.36045,
    53.7,
    27.47
  ],
  [
    21263,
    66.0419,
    13.36164,
    53.6,
    27.44
  ],
  [
    21265,
    66.04199,
    13.36282,
    53.6,
    27.39
  ],
  [
    21268,
    66.04213,
    13.3646,
    53.7,
    27.35
  ],
  [
    21270,
    66.04222,
    13.36579,
    53.7,
    27.36
  ],
  [
    21272,
    66.04231,
    13.36697,
    53.6,
    27.36
  ],
  [
    21274,
    66.04241,
    13.36816,
    53.3,
    27.36
  ],
  [
    21276,
    66.0425,
    13.36935,
    52.9,
    27.36
  ],
  [
    21278,
    66.04259,
    13.37054,
    52.5,
    27.6
  ],
  [
    21280,
    66.04269,
    13.37174,
    52.2,
    27.6
  ],
  [
    21282,
    66.04281,
    13.37294,
    52,
    27.85
  ],
  [
    21284,
    66.04297,
    13.3741,
    52.1,
    27.87
  ],
  [
    21286,
    66.04318,
    13.37522,
    52.1,
    27.9
  ],
  [
    21288,
    66.04344,
    13.37626,
    52.4,
    27.87
  ],
  [
    21290,
    66.04375,
    13.37723,
    52.6,
    27.84
  ],
  [
    21292,
    66.04409,
    13.37813,
    53,
    27.8
  ],
  [
    21294,
    66.04444,
    13.37901,
    53.5,
    27.77
  ],
  [
    21296,
    66.04479,
    13.37989,
    53.9,
    27.74
  ],
  [
    21298,
    66.04514,
    13.38076,
    54.3,
    27.71
  ],
  [
    21300,
    66.04549,
    13.38164,
    54.8,
    27.7
  ],
  [
    21302,
    66.04584,
    13.38251,
    55.2,
    27.67
  ],
  [
    21304,
    66.04618,
    13.38339,
    55.7,
    27.64
  ],
  [
    21306,
    66.04653,
    13.38426,
    56.2,
    27.59
  ],
  [
    21308,
    66.04687,
    13.38513,
    56.8,
    27.55
  ],
  [
    21310,
    66.04721,
    13.38603,
    57.4,
    27.53
  ],
  [
    21312,
    66.04752,
    13.38698,
    58.2,
    27.51
  ],
  [
    21314,
    66.04778,
    13.388,
    58.8,
    27.4
  ],
  [
    21316,
    66.04799,
    13.38909,
    59.4,
    27.34
  ],
  [
    21318,
    66.04816,
    13.39022,
    59.7,
    27.28
  ],
  [
    21320,
    66.04829,
    13.39137,
    59.6,
    27.21
  ],
  [
    21322,
    66.04842,
    13.39253,
    59.6,
    27.19
  ],
  [
    21324,
    66.04854,
    13.39369,
    59.9,
    27.19
  ],
  [
    21326,
    66.04867,
    13.39485,
    60.3,
    27.13
  ],
  [
    21328,
    66.0488,
    13.396,
    60.7,
    27.06
  ],
  [
    21330,
    66.04892,
    13.39715,
    61.2,
    27.01
  ],
  [
    21332,
    66.04905,
    13.3983,
    61.7,
    26.99
  ],
  [
    22073,
    66.12951,
    13.57622,
    34.5,
    23.64
  ],
  [
    22075,
    66.12995,
    13.57611,
    36.9,
    23.8
  ],
  [
    22077,
    66.13038,
    13.57614,
    37.3,
    23.88
  ],
  [
    22079,
    66.1308,
    13.57632,
    36.2,
    24.05
  ],
  [
    22081,
    66.13121,
    13.57665,
    35.7,
    24.15
  ],
  [
    22083,
    66.1316,
    13.57712,
    34.3,
    24.19
  ],
  [
    22085,
    66.13195,
    13.57772,
    32.7,
    24.35
  ],
  [
    22087,
    66.1323,
    13.5784,
    33.3,
    24.34
  ],
  [
    22089,
    66.13263,
    13.57913,
    34.3,
    24.53
  ],
  [
    22091,
    66.13295,
    13.57988,
    35.6,
    24.7
  ],
  [
    22093,
    66.13328,
    13.58066,
    37.4,
    24.89
  ],
  [
    22095,
    66.1336,
    13.58147,
    38.5,
    25.53
  ],
  [
    22097,
    66.1339,
    13.5823,
    39.1,
    24.99
  ],
  [
    22099,
    66.13421,
    13.58312,
    43,
    24.66
  ],
  [
    22101,
    66.13448,
    13.58398,
    44,
    24.11
  ],
  [
    22103,
    66.13473,
    13.58485,
    44.9,
    23.69
  ],
  [
    22105,
    66.13493,
    13.58575,
    46.5,
    22.95
  ],
  [
    22106,
    66.13518,
    13.58713,
    48,
    22.42
  ],
  [
    22108,
    66.13533,
    13.58805,
    48.1,
    22.25
  ],
  [
    22110,
    66.1355,
    13.58894,
    49,
    22.18
  ],
  [
    22112,
    66.13571,
    13.58978,
    49.2,
    22.2
  ],
  [
    22114,
    66.13596,
    13.59054,
    50.8,
    22.24
  ],
  [
    22116,
    66.13625,
    13.59123,
    51,
    22.31
  ],
  [
    22118,
    66.13657,
    13.59183,
    50.3,
    22.39
  ],
  [
    22120,
    66.13691,
    13.59233,
    49,
    22.46
  ],
  [
    22122,
    66.13728,
    13.59272,
    47.6,
    22.53
  ],
  [
    22124,
    66.13766,
    13.593,
    45,
    22.6
  ],
  [
    22126,
    66.13805,
    13.59316,
    42.6,
    22.78
  ],
  [
    22128,
    66.13845,
    13.59328,
    40.5,
    22.94
  ],
  [
    22130,
    66.13886,
    13.5934,
    38.7,
    23.19
  ],
  [
    22132,
    66.13926,
    13.59354,
    36.7,
    23.32
  ],
  [
    22134,
    66.13967,
    13.59371,
    34.6,
    23.49
  ],
  [
    22136,
    66.14008,
    13.5939,
    32.6,
    23.64
  ],
  [
    22138,
    66.14049,
    13.59409,
    30.6,
    23.83
  ],
  [
    22140,
    66.1409,
    13.59429,
    28.6,
    24.02
  ],
  [
    22142,
    66.14132,
    13.5945,
    26.9,
    23.6
  ],
  [
    22144,
    66.14172,
    13.59473,
    25.2,
    23.21
  ],
  [
    22146,
    66.14212,
    13.59497,
    24.4,
    22.77
  ],
  [
    22148,
    66.14251,
    13.59522,
    24.4,
    22.34
  ],
  [
    22150,
    66.1429,
    13.59545,
    21.3,
    23.01
  ],
  [
    22152,
    66.14329,
    13.59572,
    20.4,
    22.62
  ],
  [
    22154,
    66.14368,
    13.596,
    19.6,
    22.78
  ],
  [
    22157,
    66.14426,
    13.59645,
    18.5,
    22.96
  ],
  [
    22159,
    66.14466,
    13.59677,
    17.8,
    23.03
  ],
  [
    22161,
    66.14505,
    13.59709,
    17.3,
    23.29
  ],
  [
    22163,
    66.14545,
    13.59744,
    17,
    23.29
  ],
  [
    22165,
    66.14584,
    13.59779,
    16.7,
    23.29
  ],
  [
    22167,
    66.14622,
    13.59817,
    16.5,
    23.19
  ],
  [
    22169,
    66.14658,
    13.59862,
    16.3,
    23.19
  ],
  [
    22171,
    66.14691,
    13.59916,
    16,
    22.02
  ],
  [
    22173,
    66.14721,
    13.59978,
    15.7,
    21.81
  ],
  [
    22175,
    66.14749,
    13.60044,
    15.8,
    21.62
  ],
  [
    22177,
    66.14777,
    13.60111,
    15.6,
    21.62
  ],
  [
    22197,
    66.14915,
    13.61032,
    15.3,
    22.34
  ],
  [
    22199,
    66.14943,
    13.61113,
    16.5,
    22.68
  ],
  [
    22201,
    66.1497,
    13.61183,
    15.1,
    22.77
  ],
  [
    22203,
    66.15001,
    13.61245,
    14.9,
    22.88
  ],
  [
    22205,
    66.15035,
    13.61297,
    13.9,
    22.96
  ],
  [
    22207,
    66.15071,
    13.61342,
    12,
    23.12
  ],
  [
    22209,
    66.15108,
    13.61386,
    10.4,
    23.25
  ],
  [
    22211,
    66.15144,
    13.61433,
    8.8,
    23.78
  ],
  [
    22213,
    66.15182,
    13.61484,
    8.4,
    23.78
  ],
  [
    22215,
    66.15219,
    13.61534,
    8,
    23.78
  ],
  [
    22243,
    66.15563,
    13.62761,
    5,
    24.8
  ],
  [
    22245,
    66.15588,
    13.62851,
    5.7,
    24.78
  ],
  [
    22247,
    66.15616,
    13.62938,
    8.4,
    24.81
  ],
  [
    22249,
    66.1564,
    13.63032,
    7.4,
    25.46
  ],
  [
    22251,
    66.15662,
    13.63129,
    7.3,
    24.7
  ],
  [
    22253,
    66.15684,
    13.63225,
    9,
    24.69
  ],
  [
    22255,
    66.15705,
    13.63323,
    10.1,
    24.69
  ],
  [
    22257,
    66.15726,
    13.63419,
    10.8,
    24.75
  ],
  [
    22259,
    66.1575,
    13.63512,
    11.2,
    24.8
  ],
  [
    22261,
    66.15775,
    13.63603,
    11.5,
    24.75
  ],
  [
    22263,
    66.15802,
    13.63692,
    11.4,
    24.89
  ],
  [
    22265,
    66.15828,
    13.63781,
    11.1,
    24.89
  ],
  [
    22269,
    66.1588,
    13.63948,
    10.2,
    24.83
  ],
  [
    22271,
    66.15901,
    13.64036,
    5.4,
    24.72
  ],
  [
    22273,
    66.15928,
    13.64125,
    2.6,
    24.69
  ],
  [
    22275,
    66.1595,
    13.64217,
    -1.6,
    24.65
  ],
  [
    22277,
    66.15975,
    13.6431,
    -1.2,
    24.61
  ],
  [
    22279,
    66.15995,
    13.64406,
    -1.6,
    24.61
  ],
  [
    22281,
    66.16016,
    13.64503,
    -2.1,
    24.61
  ],
  [
    22345,
    66.15952,
    13.68068,
    -25.3,
    25.43
  ],
  [
    22347,
    66.1595,
    13.68178,
    -23.7,
    24.77
  ],
  [
    22349,
    66.15948,
    13.68286,
    -23.6,
    24.65
  ],
  [
    22351,
    66.15946,
    13.68393,
    -23.9,
    24.57
  ],
  [
    22353,
    66.15943,
    13.68499,
    -23.6,
    24.45
  ],
  [
    22355,
    66.15933,
    13.686,
    -20.8,
    24.36
  ],
  [
    22357,
    66.15919,
    13.68699,
    -17.6,
    24.07
  ],
  [
    22359,
    66.15903,
    13.68798,
    -18.1,
    24.07
  ],
  [
    22361,
    66.15882,
    13.68878,
    -7.9,
    23.93
  ],
  [
    22363,
    66.15862,
    13.68969,
    -4.7,
    23.86
  ],
  [
    22365,
    66.15847,
    13.69064,
    0,
    23.66
  ],
  [
    22367,
    66.15837,
    13.69164,
    3,
    23.61
  ],
  [
    22369,
    66.15832,
    13.69267,
    3.6,
    23.62
  ],
  [
    22371,
    66.15832,
    13.69372,
    3.8,
    23.62
  ],
  [
    22373,
    66.15837,
    13.69476,
    3.6,
    23.61
  ],
  [
    22375,
    66.15846,
    13.69578,
    4.1,
    23.68
  ],
  [
    22377,
    66.15858,
    13.6968,
    3.3,
    23.77
  ],
  [
    22379,
    66.15873,
    13.6978,
    3.5,
    23.87
  ],
  [
    22381,
    66.1589,
    13.69876,
    4.6,
    23.91
  ],
  [
    22383,
    66.1591,
    13.69969,
    5.8,
    24.03
  ],
  [
    22385,
    66.15933,
    13.70059,
    6.3,
    24.1
  ],
  [
    22387,
    66.15959,
    13.70144,
    8.2,
    24.06
  ],
  [
    22389,
    66.15987,
    13.70227,
    10,
    24.13
  ],
  [
    22391,
    66.16014,
    13.7031,
    10.1,
    24.19
  ],
  [
    22393,
    66.1604,
    13.70394,
    9.3,
    24.25
  ],
  [
    22395,
    66.16065,
    13.7048,
    8.7,
    24.3
  ],
  [
    22397,
    66.16088,
    13.70573,
    8.6,
    24.69
  ],
  [
    22399,
    66.16111,
    13.70668,
    8.6,
    24.69
  ],
  [
    22431,
    66.1637,
    13.72163,
    -5.8,
    25.18
  ],
  [
    22433,
    66.16394,
    13.72262,
    0.6,
    25.03
  ],
  [
    22435,
    66.16409,
    13.72375,
    0.6,
    25.07
  ],
  [
    22437,
    66.16417,
    13.72485,
    2.1,
    24.95
  ],
  [
    22439,
    66.16425,
    13.72596,
    2.9,
    24.88
  ],
  [
    22441,
    66.16436,
    13.72703,
    4.9,
    24.8
  ],
  [
    22443,
    66.16446,
    13.7281,
    4.4,
    24.8
  ],
  [
    22445,
    66.16457,
    13.72917,
    3.9,
    24.8
  ],
  [
    22452,
    66.16553,
    13.7322,
    0.4,
    24.76
  ],
  [
    22454,
    66.16578,
    13.73307,
    1.2,
    24.6
  ],
  [
    22456,
    66.16607,
    13.73386,
    2.7,
    24.66
  ],
  [
    22458,
    66.16639,
    13.73458,
    3,
    24.74
  ],
  [
    22460,
    66.16673,
    13.73521,
    3.4,
    24.89
  ],
  [
    22462,
    66.16709,
    13.73582,
    3.5,
    25.02
  ],
  [
    22464,
    66.16745,
    13.73647,
    3.8,
    25.18
  ],
  [
    22466,
    66.16778,
    13.73724,
    5,
    25.22
  ],
  [
    22468,
    66.16806,
    13.73813,
    6.3,
    25.27
  ],
  [
    22470,
    66.16828,
    13.73911,
    7.7,
    25.34
  ],
  [
    22472,
    66.16844,
    13.74016,
    8,
    25.29
  ],
  [
    22474,
    66.16856,
    13.74125,
    7.7,
    25.3
  ],
  [
    22476,
    66.16866,
    13.74236,
    7,
    25.39
  ],
  [
    22478,
    66.16876,
    13.74347,
    6.5,
    25.4
  ],
  [
    22480,
    66.16886,
    13.74458,
    6.1,
    25.43
  ],
  [
    22482,
    66.16896,
    13.74568,
    5.9,
    25.39
  ],
  [
    22484,
    66.16906,
    13.74678,
    5.5,
    25.39
  ],
  [
    22486,
    66.16916,
    13.74788,
    5.1,
    25.39
  ],
  [
    22503,
    66.16914,
    13.7573,
    0.9,
    25.39
  ],
  [
    22505,
    66.1689,
    13.7583,
    -1.1,
    25.39
  ],
  [
    22507,
    66.16863,
    13.75919,
    -0.9,
    23.35
  ],
  [
    22509,
    66.16833,
    13.75997,
    -0.1,
    23.35
  ],
  [
    22511,
    66.168,
    13.76065,
    1.2,
    24.45
  ],
  [
    22513,
    66.16765,
    13.76126,
    1.6,
    24.45
  ],
  [
    22515,
    66.16728,
    13.76187,
    1.9,
    24.49
  ],
  [
    22517,
    66.16694,
    13.76253,
    2.8,
    24.54
  ],
  [
    22519,
    66.16663,
    13.76329,
    3.8,
    24.6
  ],
  [
    22521,
    66.16637,
    13.76414,
    4.8,
    24.7
  ],
  [
    22523,
    66.16614,
    13.76507,
    5.6,
    25.19
  ],
  [
    22525,
    66.16596,
    13.76606,
    7.8,
    25.6
  ],
  [
    22527,
    66.16585,
    13.76711,
    8.9,
    24.83
  ],
  [
    22529,
    66.16575,
    13.76817,
    9.8,
    24.98
  ],
  [
    22531,
    66.16563,
    13.76922,
    10.6,
    24.85
  ],
  [
    22533,
    66.16546,
    13.77022,
    10.7,
    24.34
  ],
  [
    22535,
    66.16522,
    13.77115,
    10.5,
    24.1
  ],
  [
    22537,
    66.16498,
    13.77185,
    27.3,
    25.05
  ],
  [
    22539,
    66.16468,
    13.7725,
    36.3,
    25.09
  ],
  [
    22541,
    66.16431,
    13.77306,
    39.8,
    25.1
  ],
  [
    22543,
    66.1639,
    13.77349,
    41.7,
    25.19
  ],
  [
    22545,
    66.16347,
    13.77384,
    44.1,
    25.21
  ],
  [
    22547,
    66.16304,
    13.77409,
    46.9,
    25.42
  ],
  [
    22549,
    66.1626,
    13.77429,
    49.6,
    25.32
  ],
  [
    22551,
    66.16216,
    13.77445,
    51.8,
    25.22
  ],
  [
    22553,
    66.16172,
    13.77468,
    53.2,
    25.18
  ],
  [
    22555,
    66.16129,
    13.77503,
    53.6,
    25.13
  ],
  [
    22557,
    66.16088,
    13.77551,
    53.4,
    25.03
  ],
  [
    22559,
    66.1605,
    13.77612,
    53.3,
    24.95
  ],
  [
    22561,
    66.16016,
    13.77684,
    53.3,
    24.87
  ],
  [
    22563,
    66.15987,
    13.77767,
    53.6,
    24.8
  ],
  [
    22565,
    66.15961,
    13.77857,
    53.8,
    24.7
  ],
  [
    22567,
    66.15939,
    13.77952,
    54,
    24.57
  ],
  [
    22569,
    66.15916,
    13.78045,
    54.5,
    24.42
  ],
  [
    22571,
    66.15891,
    13.78135,
    54.6,
    24.33
  ],
  [
    22573,
    66.15864,
    13.7822,
    54.8,
    24.33
  ],
  [
    22575,
    66.15837,
    13.78305,
    55.1,
    24.33
  ],
  [
    22580,
    66.15737,
    13.78413,
    55.6,
    24.35
  ],
  [
    22582,
    66.15689,
    13.78442,
    53.9,
    24.49
  ],
  [
    22584,
    66.15645,
    13.78461,
    53.3,
    24.63
  ],
  [
    22586,
    66.15597,
    13.7847,
    51.1,
    24.87
  ],
  [
    22588,
    66.1555,
    13.78469,
    48.7,
    25.01
  ],
  [
    22590,
    66.15503,
    13.7846,
    47,
    25.15
  ],
  [
    22592,
    66.15457,
    13.78446,
    46,
    25.8
  ],
  [
    22594,
    66.15411,
    13.78435,
    45.8,
    25.6
  ],
  [
    22596,
    66.15365,
    13.78421,
    45,
    26.15
  ],
  [
    22598,
    66.15316,
    13.78401,
    44.1,
    25.62
  ],
  [
    22600,
    66.1527,
    13.78386,
    42.9,
    25.61
  ],
  [
    22602,
    66.15224,
    13.78385,
    42.4,
    25.69
  ],
  [
    22604,
    66.15179,
    13.78395,
    42.9,
    25.81
  ],
  [
    22606,
    66.15134,
    13.78409,
    42.7,
    25.44
  ],
  [
    22608,
    66.15092,
    13.78444,
    42.8,
    25.79
  ],
  [
    22610,
    66.15049,
    13.78481,
    42.1,
    25.99
  ],
  [
    22612,
    66.15006,
    13.78524,
    42.3,
    26.04
  ],
  [
    22614,
    66.14964,
    13.78573,
    42.9,
    26.16
  ],
  [
    22616,
    66.14923,
    13.78626,
    43.6,
    26.31
  ],
  [
    22618,
    66.14883,
    13.78685,
    44.3,
    26.46
  ],
  [
    22620,
    66.14843,
    13.78747,
    45,
    26.67
  ],
  [
    22622,
    66.14803,
    13.7881,
    45.3,
    26.87
  ],
  [
    22624,
    66.14762,
    13.78874,
    45.3,
    27.09
  ],
  [
    22626,
    66.14721,
    13.78937,
    46.3,
    27.3
  ],
  [
    22628,
    66.14679,
    13.78998,
    46.6,
    27.53
  ],
  [
    22630,
    66.14635,
    13.79055,
    46.9,
    27.72
  ],
  [
    22632,
    66.14591,
    13.79109,
    47.2,
    27.9
  ],
  [
    22635,
    66.14522,
    13.79183,
    47.5,
    28.07
  ],
  [
    22637,
    66.14474,
    13.79228,
    47.8,
    28.15
  ],
  [
    22639,
    66.14426,
    13.79268,
    47.7,
    28.15
  ],
  [
    22641,
    66.14378,
    13.79303,
    47.4,
    28.2
  ],
  [
    22643,
    66.14329,
    13.79336,
    47.1,
    28.2
  ],
  [
    22645,
    66.1428,
    13.79367,
    46.9,
    28.2
  ],
  [
    22647,
    66.14231,
    13.794,
    46.7,
    28.02
  ],
  [
    22649,
    66.14184,
    13.7944,
    46.5,
    27.9
  ],
  [
    22651,
    66.14138,
    13.79491,
    46.3,
    27.82
  ],
  [
    22653,
    66.14096,
    13.79555,
    46.1,
    27.75
  ],
  [
    22655,
    66.14056,
    13.79631,
    45.8,
    27.68
  ],
  [
    22657,
    66.14021,
    13.79717,
    45.8,
    27.65
  ],
  [
    22659,
    66.1399,
    13.79814,
    46,
    27.71
  ],
  [
    22661,
    66.13963,
    13.79918,
    46.2,
    27.73
  ],
  [
    22663,
    66.13939,
    13.80028,
    46.3,
    27.78
  ],
  [
    22665,
    66.13917,
    13.80138,
    46.4,
    27.74
  ],
  [
    22667,
    66.13894,
    13.80248,
    46.6,
    27.85
  ],
  [
    22669,
    66.13871,
    13.80358,
    46.9,
    27.86
  ],
  [
    22671,
    66.13848,
    13.80468,
    47.5,
    27.92
  ],
  [
    22673,
    66.13826,
    13.80579,
    48,
    27.93
  ],
  [
    22675,
    66.13803,
    13.8069,
    48.4,
    27.93
  ],
  [
    22677,
    66.1378,
    13.808,
    48.7,
    27.99
  ],
  [
    22679,
    66.13757,
    13.80911,
    48.9,
    28.04
  ],
  [
    22681,
    66.13735,
    13.81022,
    48.8,
    28.12
  ],
  [
    22683,
    66.13711,
    13.81133,
    48.1,
    28.21
  ],
  [
    22685,
    66.13688,
    13.81245,
    47.5,
    28.29
  ],
  [
    22687,
    66.13665,
    13.81357,
    46.5,
    28.4
  ],
  [
    22689,
    66.13643,
    13.8147,
    45.6,
    28.48
  ],
  [
    22691,
    66.13624,
    13.81588,
    44.1,
    28.58
  ],
  [
    22693,
    66.1361,
    13.8171,
    42.8,
    28.67
  ],
  [
    22695,
    66.13603,
    13.81836,
    41.5,
    28.41
  ],
  [
    22697,
    66.13601,
    13.81961,
    40.3,
    28
  ],
  [
    22699,
    66.13605,
    13.82084,
    39.1,
    27.48
  ],
  [
    22701,
    66.13614,
    13.82202,
    38.1,
    26.94
  ],
  [
    22703,
    66.13628,
    13.82315,
    37.3,
    26.35
  ],
  [
    22705,
    66.13647,
    13.82422,
    36.4,
    25.78
  ],
  [
    22707,
    66.13669,
    13.82521,
    35.7,
    25.25
  ],
  [
    22709,
    66.13695,
    13.82612,
    35,
    24.93
  ],
  [
    22711,
    66.13724,
    13.82696,
    34.5,
    24.78
  ],
  [
    22713,
    66.13756,
    13.82772,
    34,
    24.68
  ],
  [
    22715,
    66.13791,
    13.82839,
    33.4,
    24.62
  ],
  [
    22717,
    66.13829,
    13.82897,
    32.9,
    24.62
  ],
  [
    22719,
    66.13868,
    13.82948,
    32.3,
    24.68
  ],
  [
    22721,
    66.13908,
    13.82996,
    31.8,
    24.77
  ],
  [
    22723,
    66.13949,
    13.83043,
    31.4,
    24.88
  ],
  [
    22725,
    66.1399,
    13.83089,
    30.9,
    25
  ],
  [
    22727,
    66.14032,
    13.83131,
    30.4,
    25.09
  ],
  [
    22729,
    66.14075,
    13.83166,
    30,
    25.15
  ],
  [
    22731,
    66.14119,
    13.8319,
    29.6,
    25.15
  ],
  [
    22733,
    66.14164,
    13.83203,
    29.3,
    25.12
  ],
  [
    22735,
    66.14209,
    13.83205,
    29,
    25.05
  ],
  [
    22737,
    66.14254,
    13.83196,
    28.8,
    24.98
  ],
  [
    22739,
    66.14298,
    13.83175,
    28.5,
    24.9
  ],
  [
    22741,
    66.14341,
    13.83145,
    28.3,
    24.54
  ],
  [
    22744,
    66.14403,
    13.83093,
    28,
    23.78
  ],
  [
    22746,
    66.14442,
    13.83059,
    27.8,
    22.95
  ],
  [
    22748,
    66.1448,
    13.83027,
    27.6,
    22.07
  ],
  [
    22750,
    66.14516,
    13.82999,
    27.5,
    22.07
  ],
  [
    22752,
    66.14552,
    13.82978,
    27.3,
    20.72
  ],
  [
    22754,
    66.14586,
    13.82965,
    27,
    20.72
  ],
  [
    22756,
    66.14619,
    13.82959,
    26.8,
    18.52
  ],
  [
    22758,
    66.14651,
    13.82958,
    26.7,
    18.52
  ],
  [
    22760,
    66.14681,
    13.82963,
    26.5,
    16.98
  ],
  [
    22762,
    66.1471,
    13.82973,
    26.4,
    16.2
  ],
  [
    22764,
    66.14737,
    13.82987,
    26.2,
    15.57
  ],
  [
    22766,
    66.14764,
    13.83004,
    25.6,
    14.81
  ],
  [
    22768,
    66.14788,
    13.83024,
    25,
    14.31
  ],
  [
    22770,
    66.14812,
    13.83045,
    24.3,
    14.08
  ],
  [
    22772,
    66.14835,
    13.83066,
    23.7,
    13.32
  ],
  [
    22774,
    66.14856,
    13.83087,
    23.1,
    12.79
  ],
  [
    22776,
    66.14877,
    13.83108,
    22.5,
    12.17
  ],
  [
    22778,
    66.14897,
    13.83127,
    22,
    11.52
  ],
  [
    22780,
    66.14916,
    13.83145,
    21.5,
    11.2
  ],
  [
    22782,
    66.14933,
    13.83162,
    21,
    10.22
  ],
  [
    22784,
    66.1495,
    13.83178,
    20.5,
    9.55
  ],
  [
    22786,
    66.14965,
    13.83193,
    20,
    8.88
  ],
  [
    22788,
    66.14979,
    13.83207,
    19.6,
    8.2
  ],
  [
    22790,
    66.14992,
    13.8322,
    19.2,
    7.85
  ],
  [
    22792,
    66.15004,
    13.83231,
    18.8,
    6.9
  ],
  [
    22794,
    66.15015,
    13.83242,
    18.5,
    6.33
  ],
  [
    22796,
    66.15025,
    13.83252,
    18.2,
    5.76
  ],
  [
    22798,
    66.15034,
    13.8326,
    17.9,
    5.18
  ],
  [
    22800,
    66.15042,
    13.83268,
    17.6,
    4.59
  ],
  [
    22802,
    66.15049,
    13.83275,
    17.3,
    3.95
  ],
  [
    22804,
    66.15055,
    13.83281,
    17,
    3.25
  ],
  [
    22806,
    66.1506,
    13.83285,
    16.7,
    2.51
  ],
  [
    22808,
    66.15063,
    13.83288,
    16.5,
    1.67
  ],
  [
    22810,
    66.15065,
    13.8329,
    16.2,
    0.73
  ],
  [
    22812,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22814,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22816,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22818,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22820,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22822,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22824,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22826,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22828,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22830,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22832,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22834,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22836,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22838,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22840,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22842,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22844,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22846,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22848,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22850,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22852,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22854,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22856,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22859,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22861,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22863,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22865,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22867,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22869,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22871,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22873,
    66.15066,
    13.8329,
    16.1,
    0
  ],
  [
    22875,
    66.15071,
    13.83291,
    13.3,
    1.48
  ],
  [
    22877,
    66.15075,
    13.83294,
    13.3,
    2.1
  ],
  [
    22879,
    66.15079,
    13.83299,
    13.3,
    3.2
  ],
  [
    22881,
    66.15085,
    13.83305,
    13.2,
    3.9
  ],
  [
    22883,
    66.15093,
    13.83312,
    13.2,
    4.58
  ],
  [
    22885,
    66.15101,
    13.8332,
    13.2,
    4.88
  ],
  [
    22887,
    66.1511,
    13.83329,
    13.1,
    5.7
  ],
  [
    22889,
    66.1512,
    13.83339,
    13.1,
    6.23
  ],
  [
    22891,
    66.15131,
    13.8335,
    13,
    6.76
  ],
  [
    22893,
    66.15142,
    13.83362,
    13,
    7.26
  ],
  [
    22895,
    66.15155,
    13.83374,
    12.9,
    7.52
  ],
  [
    22897,
    66.15169,
    13.83388,
    12.8,
    8.28
  ],
  [
    22899,
    66.15183,
    13.83402,
    12.7,
    8.82
  ],
  [
    22901,
    66.15198,
    13.83417,
    12.7,
    9.34
  ],
  [
    22903,
    66.15214,
    13.83433,
    12.6,
    9.88
  ],
  [
    22905,
    66.15231,
    13.8345,
    12.5,
    10.43
  ],
  [
    22907,
    66.15249,
    13.83468,
    12.4,
    10.97
  ],
  [
    22909,
    66.15268,
    13.83487,
    12.4,
    11.6
  ],
  [
    22911,
    66.15288,
    13.83507,
    12.3,
    12.27
  ],
  [
    22913,
    66.15309,
    13.83528,
    12.3,
    12.92
  ],
  [
    22915,
    66.15331,
    13.8355,
    12.3,
    13.51
  ],
  [
    22917,
    66.15354,
    13.83573,
    12.2,
    14.09
  ],
  [
    22919,
    66.15378,
    13.83597,
    12.2,
    14.7
  ],
  [
    22921,
    66.15403,
    13.83622,
    12.2,
    15.32
  ],
  [
    22923,
    66.1543,
    13.83648,
    12.3,
    15.89
  ],
  [
    22925,
    66.15457,
    13.83675,
    12.3,
    16.42
  ],
  [
    22927,
    66.15484,
    13.83703,
    12.4,
    16.94
  ],
  [
    22929,
    66.15513,
    13.83731,
    12.4,
    17.42
  ],
  [
    22931,
    66.15543,
    13.83761,
    12.5,
    17.88
  ],
  [
    22933,
    66.15573,
    13.83791,
    12.6,
    18.31
  ],
  [
    22935,
    66.15604,
    13.83822,
    12.6,
    18.63
  ],
  [
    22937,
    66.15635,
    13.83853,
    12.7,
    19.09
  ],
  [
    22939,
    66.15668,
    13.83885,
    12.7,
    19.7
  ],
  [
    22941,
    66.15701,
    13.83918,
    12.9,
    19.86
  ],
  [
    22943,
    66.15734,
    13.83951,
    13.3,
    20.39
  ],
  [
    22945,
    66.15768,
    13.83986,
    13.7,
    20.15
  ],
  [
    22947,
    66.158,
    13.84021,
    14.1,
    19.73
  ],
  [
    22949,
    66.15832,
    13.84057,
    14.5,
    19.73
  ],
  [
    22951,
    66.15871,
    13.84099,
    15.7,
    21.31
  ],
  [
    22953,
    66.15906,
    13.84134,
    15.4,
    21.23
  ],
  [
    22955,
    66.15942,
    13.84168,
    15.5,
    21.54
  ],
  [
    22957,
    66.15978,
    13.84204,
    15.5,
    21.92
  ],
  [
    22959,
    66.16016,
    13.84239,
    15.5,
    22.2
  ],
  [
    22961,
    66.16053,
    13.84276,
    15.1,
    22.44
  ],
  [
    22963,
    66.16091,
    13.84313,
    14.9,
    22.84
  ],
  [
    22965,
    66.1613,
    13.84351,
    14.9,
    23.18
  ],
  [
    22967,
    66.16169,
    13.84387,
    15,
    23.49
  ],
  [
    22970,
    66.16232,
    13.84424,
    15.3,
    23.81
  ],
  [
    22972,
    66.16275,
    13.84434,
    15.6,
    24.03
  ],
  [
    22974,
    66.16318,
    13.84431,
    15.9,
    24.12
  ],
  [
    22976,
    66.16361,
    13.84414,
    16.3,
    24.41
  ],
  [
    22978,
    66.16404,
    13.84384,
    16.7,
    24.41
  ],
  [
    22980,
    66.16445,
    13.84342,
    17.2,
    24.41
  ],
  [
    22982,
    66.16483,
    13.84287,
    17.6,
    24.63
  ],
  [
    22984,
    66.16518,
    13.84221,
    18,
    24.68
  ],
  [
    22986,
    66.1655,
    13.84144,
    18.5,
    24.7
  ],
  [
    22988,
    66.16578,
    13.84058,
    18.9,
    24.75
  ],
  [
    22990,
    66.16602,
    13.83964,
    19.1,
    24.77
  ],
  [
    22992,
    66.16621,
    13.83863,
    19.3,
    24.85
  ],
  [
    22994,
    66.16636,
    13.83758,
    19.4,
    24.95
  ],
  [
    22996,
    66.16652,
    13.83653,
    19.7,
    25.02
  ],
  [
    22998,
    66.1667,
    13.83551,
    20.4,
    25.04
  ],
  [
    23000,
    66.16692,
    13.83455,
    21.1,
    24.61
  ],
  [
    23002,
    66.16715,
    13.83357,
    21.8,
    24.73
  ],
  [
    23004,
    66.16745,
    13.83276,
    21.8,
    24.97
  ],
  [
    23006,
    66.16781,
    13.83207,
    22.8,
    24.98
  ],
  [
    23008,
    66.16817,
    13.83139,
    23.4,
    24.96
  ],
  [
    23010,
    66.16853,
    13.83073,
    24.1,
    24.97
  ],
  [
    23012,
    66.16889,
    13.83007,
    24.7,
    24.92
  ],
  [
    23014,
    66.16926,
    13.82942,
    25.3,
    24.87
  ],
  [
    23016,
    66.16962,
    13.82879,
    25.9,
    24.88
  ],
  [
    23018,
    66.16999,
    13.82817,
    26.3,
    24.87
  ],
  [
    23020,
    66.17036,
    13.82756,
    26.8,
    24.92
  ],
  [
    23022,
    66.17074,
    13.82694,
    27.1,
    25.04
  ],
  [
    23024,
    66.17111,
    13.82633,
    27.2,
    25.17
  ],
  [
    23026,
    66.17149,
    13.82571,
    27.3,
    25.05
  ],
  [
    23028,
    66.17186,
    13.82508,
    27.5,
    25.54
  ],
  [
    23030,
    66.17225,
    13.82446,
    27.5,
    25.55
  ],
  [
    23032,
    66.17262,
    13.8238,
    27.3,
    25.67
  ],
  [
    23034,
    66.17296,
    13.82303,
    27.4,
    25.91
  ],
  [
    23036,
    66.17326,
    13.82214,
    27.5,
    25.76
  ],
  [
    23038,
    66.1735,
    13.82116,
    27.5,
    25.77
  ],
  [
    23040,
    66.1737,
    13.82013,
    27.4,
    25.81
  ],
  [
    23042,
    66.1739,
    13.81909,
    27.3,
    25.84
  ],
  [
    23044,
    66.17412,
    13.81808,
    27.4,
    25.92
  ],
  [
    23046,
    66.17441,
    13.81719,
    28.1,
    25.93
  ],
  [
    23048,
    66.17473,
    13.81635,
    28.2,
    25.88
  ],
  [
    23050,
    66.17505,
    13.81551,
    28.2,
    25.86
  ],
  [
    23052,
    66.17537,
    13.81466,
    28.2,
    25.98
  ],
  [
    23054,
    66.17565,
    13.81373,
    28.4,
    25.95
  ],
  [
    23056,
    66.17588,
    13.81274,
    28.4,
    25.89
  ],
  [
    23058,
    66.17606,
    13.81167,
    28.3,
    25.86
  ],
  [
    23060,
    66.1762,
    13.81057,
    28.3,
    25.87
  ],
  [
    23062,
    66.17633,
    13.80946,
    28.3,
    25.91
  ],
  [
    23064,
    66.17649,
    13.80838,
    28,
    25.92
  ],
  [
    23066,
    66.1767,
    13.80735,
    27.8,
    25.87
  ],
  [
    23069,
    66.1771,
    13.80596,
    27.8,
    25.84
  ],
  [
    23071,
    66.17742,
    13.80513,
    27.9,
    25.8
  ],
  [
    23073,
    66.17777,
    13.80437,
    28,
    25.77
  ],
  [
    23075,
    66.17813,
    13.80366,
    28,
    25.77
  ],
  [
    23077,
    66.1785,
    13.80299,
    28,
    25.63
  ],
  [
    23079,
    66.17887,
    13.80235,
    28,
    25.63
  ],
  [
    23081,
    66.17925,
    13.80172,
    28.1,
    25.48
  ],
  [
    23083,
    66.17963,
    13.80109,
    28.2,
    25.45
  ],
  [
    23085,
    66.18002,
    13.80048,
    28.5,
    25.41
  ],
  [
    23087,
    66.18041,
    13.79989,
    28.7,
    25.37
  ],
  [
    23089,
    66.18081,
    13.79938,
    29,
    25.33
  ],
  [
    23091,
    66.18122,
    13.79892,
    29.3,
    25.23
  ],
  [
    23093,
    66.18165,
    13.79854,
    29.6,
    25.18
  ],
  [
    23095,
    66.18208,
    13.7982,
    29.8,
    25.16
  ],
  [
    23097,
    66.18251,
    13.79783,
    30,
    25.12
  ],
  [
    23099,
    66.18292,
    13.79738,
    30.1,
    25.19
  ],
  [
    23101,
    66.18332,
    13.79686,
    30.2,
    25.28
  ],
  [
    23103,
    66.18371,
    13.79629,
    30.4,
    25.41
  ],
  [
    23105,
    66.18412,
    13.79576,
    30.8,
    25.5
  ],
  [
    23107,
    66.18453,
    13.79529,
    31.3,
    25.61
  ],
  [
    23109,
    66.18496,
    13.79484,
    31.9,
    25.62
  ],
  [
    23111,
    66.18538,
    13.7944,
    32.6,
    25.58
  ],
  [
    23113,
    66.1858,
    13.79395,
    33.2,
    25.52
  ],
  [
    23115,
    66.18622,
    13.79351,
    33.9,
    25.38
  ],
  [
    23117,
    66.18664,
    13.79308,
    34.5,
    25.25
  ],
  [
    23119,
    66.18707,
    13.7927,
    35.1,
    25.13
  ],
  [
    23121,
    66.18751,
    13.79246,
    35.5,
    24.98
  ],
  [
    23123,
    66.18795,
    13.79238,
    36,
    24.82
  ],
  [
    23125,
    66.1884,
    13.79245,
    36.2,
    24.69
  ],
  [
    23127,
    66.18883,
    13.79268,
    35.9,
    24.66
  ],
  [
    23129,
    66.18925,
    13.79306,
    35.4,
    24.63
  ],
  [
    23131,
    66.18965,
    13.79354,
    34.8,
    24.71
  ],
  [
    23133,
    66.19005,
    13.79404,
    34.3,
    24.68
  ],
  [
    23135,
    66.19046,
    13.79447,
    34,
    24.75
  ],
  [
    23137,
    66.19088,
    13.79477,
    33.8,
    24.75
  ],
  [
    23139,
    66.19133,
    13.79491,
    33.7,
    24.73
  ],
  [
    23141,
    66.19177,
    13.79491,
    33.8,
    24.59
  ],
  [
    23143,
    66.19221,
    13.79489,
    34,
    24.63
  ],
  [
    23145,
    66.19265,
    13.79487,
    34.2,
    24.63
  ],
  [
    23147,
    66.19302,
    13.79415,
    34.4,
    24.36
  ],
  [
    23149,
    66.19341,
    13.79357,
    35.3,
    24.45
  ],
  [
    23151,
    66.19378,
    13.79301,
    34.8,
    24.29
  ],
  [
    23153,
    66.19416,
    13.79247,
    34.9,
    24.31
  ],
  [
    23155,
    66.19452,
    13.79187,
    34.9,
    24.32
  ],
  [
    23157,
    66.19487,
    13.7912,
    34.9,
    24.38
  ],
  [
    23159,
    66.1952,
    13.7905,
    34.8,
    24.45
  ],
  [
    23162,
    66.19571,
    13.78946,
    34.8,
    24.53
  ],
  [
    23164,
    66.19609,
    13.78888,
    34.7,
    24.66
  ],
  [
    23166,
    66.19648,
    13.78841,
    34.9,
    24.58
  ],
  [
    23168,
    66.19687,
    13.78796,
    35.1,
    24.58
  ],
  [
    23170,
    66.19731,
    13.78775,
    35.2,
    24.58
  ],
  [
    23172,
    66.19783,
    13.78811,
    34.6,
    24.15
  ],
  [
    23174,
    66.19828,
    13.78833,
    34.2,
    25.11
  ],
  [
    23176,
    66.19871,
    13.78868,
    33.6,
    25.19
  ],
  [
    23178,
    66.19913,
    13.78914,
    33.2,
    25.26
  ],
  [
    23180,
    66.19955,
    13.78962,
    32.7,
    25.29
  ],
  [
    23182,
    66.19997,
    13.79005,
    32.2,
    25.34
  ],
  [
    23184,
    66.20041,
    13.79033,
    32.1,
    25.35
  ],
  [
    23186,
    66.20087,
    13.79045,
    32.2,
    25.3
  ],
  [
    23188,
    66.20132,
    13.79042,
    32.5,
    25.25
  ],
  [
    23190,
    66.20177,
    13.79026,
    32.7,
    25.24
  ],
  [
    23192,
    66.20222,
    13.79007,
    32.9,
    25.1
  ],
  [
    23194,
    66.20266,
    13.78994,
    33,
    25
  ],
  [
    23196,
    66.20311,
    13.78994,
    33.2,
    24.84
  ],
  [
    23198,
    66.20355,
    13.79007,
    33.6,
    24.79
  ],
  [
    23200,
    66.20399,
    13.79021,
    34,
    24.79
  ],
  [
    23214,
    66.2065,
    13.79375,
    37.6,
    23.57
  ],
  [
    23216,
    66.20677,
    13.79517,
    37.2,
    23.93
  ],
  [
    23218,
    66.20702,
    13.79607,
    36.5,
    23.94
  ],
  [
    23220,
    66.20726,
    13.79696,
    36.5,
    24.02
  ],
  [
    23222,
    66.20751,
    13.79783,
    35.8,
    24.14
  ],
  [
    23224,
    66.20778,
    13.79869,
    35.2,
    24.32
  ],
  [
    23226,
    66.20805,
    13.79953,
    34.7,
    24.48
  ],
  [
    23229,
    66.20849,
    13.80076,
    32.7,
    24.64
  ],
  [
    23231,
    66.20879,
    13.80156,
    32,
    24.78
  ],
  [
    23233,
    66.20909,
    13.80238,
    31.3,
    24.78
  ],
  [
    23235,
    66.20938,
    13.80324,
    30.4,
    24.78
  ],
  [
    23237,
    66.20965,
    13.80414,
    29.4,
    25.01
  ],
  [
    23239,
    66.20989,
    13.80508,
    28.6,
    25.13
  ],
  [
    23241,
    66.21012,
    13.80604,
    27.8,
    25.21
  ],
  [
    23243,
    66.21035,
    13.80702,
    27.2,
    25.36
  ],
  [
    23245,
    66.21058,
    13.80799,
    26.7,
    25.47
  ],
  [
    23247,
    66.21085,
    13.80892,
    26.4,
    25.57
  ],
  [
    23249,
    66.21116,
    13.80975,
    26.3,
    25.57
  ],
  [
    23251,
    66.21151,
    13.81049,
    26.1,
    25.45
  ],
  [
    23253,
    66.21188,
    13.81113,
    26,
    24.99
  ],
  [
    23255,
    66.21225,
    13.81174,
    26,
    24.69
  ],
  [
    23257,
    66.21259,
    13.81238,
    26.1,
    23.74
  ],
  [
    23259,
    66.2129,
    13.81307,
    26.2,
    22.98
  ],
  [
    23261,
    66.21317,
    13.81383,
    26.1,
    22.2
  ],
  [
    23263,
    66.21339,
    13.81462,
    26.1,
    21.43
  ],
  [
    23265,
    66.21358,
    13.81543,
    26.2,
    20.72
  ],
  [
    23267,
    66.21374,
    13.81625,
    26.1,
    19.98
  ],
  [
    23269,
    66.21388,
    13.81704,
    26.1,
    19.4
  ],
  [
    23271,
    66.21402,
    13.81781,
    26,
    18.78
  ],
  [
    23273,
    66.21416,
    13.81856,
    25.9,
    18.26
  ],
  [
    23275,
    66.21429,
    13.81929,
    26,
    17.71
  ],
  [
    23277,
    66.21442,
    13.81999,
    25.9,
    17.15
  ],
  [
    23279,
    66.21454,
    13.82067,
    25.9,
    16.55
  ],
  [
    23281,
    66.21466,
    13.82132,
    25.9,
    15.95
  ],
  [
    23283,
    66.21478,
    13.82195,
    25.9,
    15.26
  ],
  [
    23285,
    66.21489,
    13.82255,
    25.9,
    14.54
  ],
  [
    23287,
    66.215,
    13.82311,
    25.9,
    13.73
  ],
  [
    23289,
    66.21511,
    13.82363,
    25.9,
    12.81
  ],
  [
    23291,
    66.21521,
    13.82411,
    25.9,
    11.87
  ],
  [
    23293,
    66.21531,
    13.82456,
    25.9,
    11.22
  ],
  [
    23295,
    66.21541,
    13.82498,
    25.8,
    10.89
  ],
  [
    23297,
    66.21551,
    13.82539,
    25.7,
    10.75
  ],
  [
    23299,
    66.21561,
    13.8258,
    25.5,
    10.72
  ],
  [
    23301,
    66.21571,
    13.8262,
    25.2,
    10.78
  ],
  [
    23303,
    66.21581,
    13.8266,
    25.1,
    10.98
  ],
  [
    23305,
    66.21592,
    13.82701,
    25,
    11.2
  ],
  [
    23307,
    66.21603,
    13.82742,
    25,
    11.45
  ],
  [
    23309,
    66.21615,
    13.82784,
    25,
    11.68
  ],
  [
    23311,
    66.21627,
    13.82827,
    24.7,
    11.92
  ],
  [
    23313,
    66.2164,
    13.8287,
    24.6,
    12.16
  ],
  [
    23315,
    66.21652,
    13.82915,
    24.5,
    12.42
  ],
  [
    23318,
    66.21671,
    13.82985,
    24.2,
    12.72
  ],
  [
    23320,
    66.21683,
    13.83034,
    24,
    13.06
  ],
  [
    23322,
    66.21696,
    13.83085,
    23.6,
    13.25
  ],
  [
    23324,
    66.21708,
    13.83138,
    23.4,
    13.25
  ],
  [
    23326,
    66.2172,
    13.83193,
    23.1,
    14.22
  ],
  [
    23328,
    66.21733,
    13.8325,
    22.9,
    14.22
  ],
  [
    23330,
    66.21746,
    13.8331,
    22.6,
    14.22
  ],
  [
    23332,
    66.21759,
    13.83371,
    22.4,
    15.65
  ],
  [
    23334,
    66.21773,
    13.83434,
    22.1,
    16.14
  ],
  [
    23336,
    66.21788,
    13.83499,
    21.7,
    16.99
  ],
  [
    23338,
    66.21804,
    13.83564,
    21.3,
    17.56
  ],
  [
    23340,
    66.21822,
    13.83629,
    21,
    17.85
  ],
  [
    23342,
    66.21842,
    13.83694,
    20.6,
    18.68
  ],
  [
    23344,
    66.21864,
    13.83758,
    20.1,
    19.21
  ],
  [
    23346,
    66.21888,
    13.83821,
    19.7,
    19.76
  ],
  [
    23348,
    66.21914,
    13.83884,
    19.3,
    20.27
  ],
  [
    23350,
    66.2194,
    13.83947,
    19,
    20.52
  ],
  [
    23352,
    66.21968,
    13.84012,
    18.8,
    21.33
  ],
  [
    23354,
    66.21995,
    13.8408,
    18.5,
    21.87
  ],
  [
    23356,
    66.22023,
    13.8415,
    18.3,
    22.4
  ],
  [
    23358,
    66.2205,
    13.84225,
    18,
    22.89
  ],
  [
    23360,
    66.22076,
    13.84306,
    17.8,
    23.36
  ],
  [
    23362,
    66.221,
    13.84392,
    17.6,
    23.76
  ],
  [
    23364,
    66.22122,
    13.84484,
    17.4,
    23.98
  ],
  [
    23366,
    66.22143,
    13.84577,
    17.3,
    24.15
  ],
  [
    23368,
    66.22164,
    13.84672,
    17.2,
    24.33
  ],
  [
    23370,
    66.22185,
    13.84767,
    17,
    24.62
  ],
  [
    23372,
    66.22206,
    13.84864,
    16.8,
    24.88
  ],
  [
    23374,
    66.22228,
    13.84961,
    16.6,
    25.06
  ],
  [
    23376,
    66.22252,
    13.85057,
    16.6,
    25.17
  ],
  [
    23378,
    66.22278,
    13.85148,
    15.6,
    25.22
  ],
  [
    23380,
    66.22309,
    13.85231,
    15.3,
    25.4
  ],
  [
    23382,
    66.22343,
    13.85307,
    14.9,
    25.5
  ],
  [
    23384,
    66.2238,
    13.85373,
    14.5,
    25.64
  ],
  [
    23386,
    66.2242,
    13.85428,
    13.9,
    25.69
  ],
  [
    23388,
    66.22464,
    13.85468,
    13.3,
    25.77
  ],
  [
    23390,
    66.22509,
    13.85493,
    12.8,
    25.87
  ],
  [
    23392,
    66.22556,
    13.85505,
    12.3,
    25.95
  ],
  [
    23394,
    66.22602,
    13.85511,
    12,
    26.02
  ],
  [
    23396,
    66.22649,
    13.85524,
    11.8,
    26.05
  ],
  [
    23398,
    66.22695,
    13.85551,
    11.8,
    25.99
  ],
  [
    23400,
    66.22738,
    13.85593,
    11.9,
    25.89
  ],
  [
    23402,
    66.22779,
    13.85647,
    12,
    25.8
  ],
  [
    23404,
    66.22819,
    13.85703,
    12,
    25.8
  ],
  [
    23406,
    66.22848,
    13.85777,
    11,
    24.5
  ],
  [
    23408,
    66.22872,
    13.85884,
    11.2,
    25.36
  ],
  [
    23410,
    66.22894,
    13.85988,
    10.9,
    25.33
  ],
  [
    23413,
    66.22917,
    13.8615,
    10.8,
    25.33
  ],
  [
    23415,
    66.22926,
    13.86262,
    10.9,
    25.35
  ],
  [
    23417,
    66.22931,
    13.86374,
    11.1,
    25.38
  ],
  [
    23419,
    66.22934,
    13.86488,
    11.3,
    25.38
  ],
  [
    23421,
    66.22936,
    13.86603,
    11.4,
    25.56
  ],
  [
    23423,
    66.22941,
    13.86717,
    11.3,
    25.69
  ],
  [
    23425,
    66.22951,
    13.8683,
    11.2,
    25.82
  ],
  [
    23427,
    66.22965,
    13.8694,
    11.2,
    26.16
  ],
  [
    23429,
    66.22982,
    13.87049,
    11.3,
    26.4
  ],
  [
    23431,
    66.23,
    13.87159,
    11.5,
    26.65
  ],
  [
    23433,
    66.23017,
    13.87271,
    11.8,
    26.88
  ],
  [
    23435,
    66.23034,
    13.87383,
    12.2,
    27
  ],
  [
    23437,
    66.23052,
    13.87496,
    12.6,
    27.26
  ],
  [
    23439,
    66.23068,
    13.87611,
    13,
    27.4
  ],
  [
    23441,
    66.23084,
    13.87727,
    13.3,
    27.55
  ],
  [
    23443,
    66.23098,
    13.87845,
    13.5,
    27.73
  ],
  [
    23445,
    66.23107,
    13.87967,
    13.7,
    27.81
  ],
  [
    23447,
    66.23113,
    13.8809,
    14,
    28.03
  ],
  [
    23449,
    66.2312,
    13.88214,
    14.2,
    28.1
  ],
  [
    23451,
    66.23132,
    13.88336,
    14.4,
    28.15
  ],
  [
    23453,
    66.23148,
    13.88454,
    14.5,
    28.17
  ],
  [
    23455,
    66.23169,
    13.88568,
    14.8,
    28.19
  ],
  [
    23457,
    66.23192,
    13.8868,
    15.2,
    28.2
  ],
  [
    23459,
    66.23216,
    13.88791,
    15.5,
    28.2
  ],
  [
    23461,
    66.2324,
    13.88901,
    15.9,
    28.21
  ],
  [
    23463,
    66.23264,
    13.89011,
    16.2,
    28.19
  ],
  [
    23465,
    66.23289,
    13.89121,
    16.4,
    28.17
  ],
  [
    23467,
    66.23313,
    13.89231,
    16.4,
    28.2
  ],
  [
    23469,
    66.23338,
    13.8934,
    16.3,
    28.21
  ],
  [
    23471,
    66.23364,
    13.89448,
    16.2,
    28.25
  ],
  [
    23473,
    66.23392,
    13.89553,
    16.1,
    28.27
  ],
  [
    23475,
    66.23422,
    13.89654,
    15.9,
    28.29
  ],
  [
    23477,
    66.23454,
    13.89752,
    15.8,
    28.06
  ],
  [
    23479,
    66.23485,
    13.89848,
    15.7,
    27.47
  ],
  [
    23481,
    66.23513,
    13.89946,
    15.9,
    26.78
  ],
  [
    23483,
    66.23538,
    13.90046,
    16.1,
    26.11
  ],
  [
    23485,
    66.23559,
    13.90148,
    16.3,
    25.52
  ],
  [
    23487,
    66.23578,
    13.90251,
    16.5,
    25.45
  ],
  [
    23489,
    66.23598,
    13.90353,
    16.6,
    25.45
  ],
  [
    23493,
    66.23606,
    13.90571,
    15.5,
    24.61
  ],
  [
    23495,
    66.23603,
    13.90677,
    15.1,
    24.7
  ],
  [
    23497,
    66.236,
    13.90787,
    14.7,
    24.7
  ],
  [
    23499,
    66.23598,
    13.90897,
    14.3,
    24.7
  ],
  [
    23504,
    66.2354,
    13.91147,
    12.2,
    25.05
  ],
  [
    23506,
    66.23519,
    13.9125,
    13.1,
    25.19
  ],
  [
    23508,
    66.23501,
    13.91355,
    14.1,
    25.3
  ],
  [
    23510,
    66.23486,
    13.91461,
    14.3,
    25.39
  ],
  [
    23512,
    66.23476,
    13.9157,
    14.4,
    25.42
  ],
  [
    23514,
    66.23469,
    13.91682,
    14.7,
    25.42
  ],
  [
    23516,
    66.23466,
    13.91795,
    14.9,
    25.41
  ],
  [
    23518,
    66.23465,
    13.91908,
    15.1,
    25.37
  ],
  [
    23520,
    66.23465,
    13.9202,
    15.1,
    25.37
  ],
  [
    23522,
    66.23464,
    13.92133,
    15.2,
    25.37
  ],
  [
    23535,
    66.2357,
    13.92782,
    9.6,
    25.23
  ],
  [
    23537,
    66.23607,
    13.9286,
    10.6,
    25.24
  ],
  [
    23539,
    66.23646,
    13.92924,
    10.7,
    25.32
  ],
  [
    23541,
    66.23686,
    13.92979,
    11.4,
    25.4
  ],
  [
    23543,
    66.23728,
    13.93029,
    11.6,
    25.45
  ],
  [
    23545,
    66.23769,
    13.93082,
    11.9,
    25.52
  ],
  [
    23547,
    66.23807,
    13.93144,
    12.2,
    25.5
  ],
  [
    23549,
    66.23842,
    13.93217,
    12.6,
    25.53
  ],
  [
    23551,
    66.23872,
    13.93301,
    12.8,
    25.51
  ],
  [
    23553,
    66.23898,
    13.93395,
    13.2,
    25.53
  ],
  [
    23555,
    66.23918,
    13.93498,
    11.9,
    25.53
  ],
  [
    23557,
    66.23933,
    13.93607,
    11.6,
    25.6
  ],
  [
    23559,
    66.23943,
    13.93719,
    11.5,
    25.6
  ],
  [
    23561,
    66.23949,
    13.93832,
    11.6,
    25.58
  ],
  [
    23563,
    66.23954,
    13.93946,
    11.7,
    25.57
  ],
  [
    23565,
    66.23962,
    13.94058,
    11.8,
    25.53
  ],
  [
    23567,
    66.23975,
    13.94167,
    11.8,
    25.55
  ],
  [
    23569,
    66.23992,
    13.94273,
    12.1,
    25.57
  ],
  [
    23571,
    66.24011,
    13.94376,
    12.4,
    25.18
  ],
  [
    23573,
    66.24031,
    13.94477,
    12.6,
    25.53
  ],
  [
    23575,
    66.24046,
    13.94591,
    15.7,
    25.73
  ],
  [
    23577,
    66.24066,
    13.94694,
    15.7,
    25.57
  ],
  [
    23579,
    66.2409,
    13.94791,
    15.7,
    25.51
  ],
  [
    23581,
    66.24117,
    13.94882,
    15.7,
    25.48
  ],
  [
    23583,
    66.24149,
    13.94964,
    15.8,
    25.45
  ],
  [
    23585,
    66.24185,
    13.95035,
    15.9,
    25.43
  ],
  [
    23588,
    66.24244,
    13.95119,
    16.2,
    25.39
  ],
  [
    23590,
    66.24287,
    13.95161,
    16.1,
    25.39
  ],
  [
    23592,
    66.2433,
    13.95201,
    16.1,
    25.41
  ],
  [
    23594,
    66.24372,
    13.95241,
    16.1,
    25.41
  ],
  [
    23596,
    66.24417,
    13.95284,
    17,
    25.99
  ],
  [
    23598,
    66.24456,
    13.95344,
    17.2,
    25.99
  ],
  [
    23600,
    66.24493,
    13.95412,
    17,
    25.57
  ],
  [
    23602,
    66.24529,
    13.95482,
    16.2,
    25.59
  ],
  [
    23604,
    66.24566,
    13.95552,
    15.7,
    25.64
  ],
  [
    23606,
    66.246,
    13.95627,
    15.3,
    25.75
  ],
  [
    23608,
    66.24632,
    13.95711,
    15,
    25.81
  ],
  [
    23610,
    66.24659,
    13.95805,
    14.5,
    25.79
  ],
  [
    23612,
    66.24681,
    13.95907,
    13.9,
    25.83
  ],
  [
    23614,
    66.24698,
    13.96014,
    13.4,
    25.88
  ],
  [
    23616,
    66.24714,
    13.96122,
    13.2,
    25.93
  ],
  [
    23618,
    66.24731,
    13.96231,
    13.1,
    25.96
  ],
  [
    23620,
    66.24747,
    13.9634,
    13.1,
    25.97
  ],
  [
    23622,
    66.24763,
    13.96448,
    13.1,
    25.95
  ],
  [
    23624,
    66.24779,
    13.96556,
    13.2,
    25.9
  ],
  [
    23626,
    66.24795,
    13.96664,
    13.3,
    25.87
  ],
  [
    23628,
    66.24812,
    13.96772,
    13.4,
    25.82
  ],
  [
    23630,
    66.24828,
    13.96879,
    13.5,
    25.73
  ],
  [
    23632,
    66.24844,
    13.96986,
    13.7,
    25.68
  ],
  [
    23634,
    66.2486,
    13.97093,
    13.8,
    25.6
  ],
  [
    23636,
    66.24879,
    13.97197,
    13.8,
    25.52
  ],
  [
    23638,
    66.24902,
    13.97294,
    13.8,
    25.45
  ],
  [
    23640,
    66.24931,
    13.97383,
    13.9,
    25.38
  ],
  [
    23642,
    66.24963,
    13.97462,
    14,
    25.32
  ],
  [
    23644,
    66.24999,
    13.9753,
    14.3,
    25.27
  ],
  [
    23646,
    66.25038,
    13.97587,
    14.4,
    25.22
  ],
  [
    23648,
    66.25079,
    13.97635,
    14.6,
    25.18
  ],
  [
    23650,
    66.25121,
    13.97676,
    14.7,
    25.13
  ],
  [
    23652,
    66.25164,
    13.97709,
    14.8,
    25.1
  ],
  [
    23654,
    66.25208,
    13.97731,
    15.1,
    25.11
  ],
  [
    23656,
    66.25253,
    13.97739,
    15.3,
    25.15
  ],
  [
    23658,
    66.25298,
    13.97732,
    15.4,
    25.18
  ],
  [
    23660,
    66.25343,
    13.97711,
    15.6,
    25.21
  ],
  [
    23662,
    66.25386,
    13.97677,
    15.9,
    25.25
  ],
  [
    23664,
    66.25429,
    13.97635,
    16.4,
    25.26
  ],
  [
    23666,
    66.2547,
    13.97592,
    17.3,
    25.24
  ],
  [
    23668,
    66.25512,
    13.97549,
    17.9,
    25.24
  ],
  [
    23670,
    66.25554,
    13.97507,
    18.2,
    25.24
  ],
  [
    23675,
    66.25657,
    13.97376,
    20.4,
    25.23
  ],
  [
    23677,
    66.25698,
    13.97328,
    20,
    25.31
  ],
  [
    23679,
    66.2574,
    13.97284,
    19.6,
    25.43
  ],
  [
    23681,
    66.25782,
    13.97241,
    19.2,
    25.64
  ],
  [
    23683,
    66.25825,
    13.97197,
    19,
    25.93
  ],
  [
    23685,
    66.25868,
    13.97153,
    19,
    26.24
  ],
  [
    23687,
    66.25912,
    13.9711,
    18.2,
    26.51
  ],
  [
    23689,
    66.25958,
    13.97071,
    18.2,
    26.8
  ],
  [
    23692,
    66.26027,
    13.97022,
    18.5,
    26.56
  ],
  [
    23694,
    66.26072,
    13.96991,
    18.8,
    26.56
  ],
  [
    23695,
    66.26095,
    13.96975,
    18.9,
    26.56
  ],
  [
    23704,
    66.2632,
    13.97001,
    19.1,
    27.77
  ],
  [
    23706,
    66.26369,
    13.97031,
    19.3,
    27.87
  ],
  [
    23708,
    66.26418,
    13.97057,
    19.5,
    27.87
  ],
  [
    23710,
    66.26461,
    13.97089,
    30.9,
    24.01
  ],
  [
    23712,
    66.2651,
    13.9715,
    26.6,
    28.07
  ],
  [
    23714,
    66.26557,
    13.97212,
    24.3,
    28.04
  ],
  [
    23716,
    66.26602,
    13.9727,
    23.5,
    28.03
  ],
  [
    23718,
    66.26646,
    13.97336,
    22.7,
    28.1
  ],
  [
    23720,
    66.26688,
    13.97407,
    21.8,
    28.13
  ],
  [
    23722,
    66.2673,
    13.97478,
    21.1,
    28.16
  ],
  [
    23724,
    66.26772,
    13.97549,
    21.1,
    28.2
  ],
  [
    23726,
    66.26814,
    13.9762,
    21.1,
    28.22
  ],
  [
    23728,
    66.26856,
    13.9769,
    19.9,
    28.17
  ],
  [
    23730,
    66.26898,
    13.9776,
    19.9,
    28.01
  ],
  [
    23733,
    66.2696,
    13.97864,
    20.3,
    27.88
  ],
  [
    23735,
    66.27,
    13.97938,
    20.5,
    27.74
  ],
  [
    23737,
    66.27037,
    13.98017,
    20.6,
    27.74
  ],
  [
    23739,
    66.27073,
    13.98103,
    20.4,
    27.74
  ],
  [
    23741,
    66.27106,
    13.98195,
    20,
    27.65
  ],
  [
    23743,
    66.27137,
    13.98291,
    19.7,
    27.65
  ],
  [
    23745,
    66.27165,
    13.98392,
    19.9,
    27.63
  ],
  [
    23747,
    66.27191,
    13.98496,
    19.9,
    27.65
  ],
  [
    23749,
    66.27215,
    13.98605,
    19.8,
    27.59
  ],
  [
    23751,
    66.27237,
    13.98715,
    19.7,
    27.55
  ],
  [
    23753,
    66.27258,
    13.98826,
    19.8,
    27.54
  ],
  [
    23755,
    66.27279,
    13.98937,
    19.7,
    27.51
  ],
  [
    23757,
    66.273,
    13.99049,
    20.1,
    27.55
  ],
  [
    23759,
    66.2732,
    13.99161,
    20.4,
    27.55
  ],
  [
    23761,
    66.27337,
    13.99277,
    20.2,
    27.54
  ],
  [
    23763,
    66.27349,
    13.99396,
    20,
    27.52
  ],
  [
    23765,
    66.27356,
    13.99518,
    19.9,
    27.54
  ],
  [
    23767,
    66.27358,
    13.9964,
    19.7,
    27.53
  ],
  [
    23769,
    66.27357,
    13.99763,
    19.7,
    27.62
  ],
  [
    23771,
    66.27356,
    13.99887,
    19.7,
    27.71
  ],
  [
    23773,
    66.27354,
    14.0001,
    19.7,
    27.76
  ],
  [
    23775,
    66.2735,
    14.00134,
    19.8,
    27.85
  ],
  [
    23777,
    66.27345,
    14.00258,
    19.9,
    27.92
  ],
  [
    23779,
    66.27339,
    14.00382,
    20.1,
    27.99
  ],
  [
    23781,
    66.27332,
    14.00505,
    20.5,
    28.02
  ],
  [
    23783,
    66.27324,
    14.00629,
    20.8,
    28
  ],
  [
    23785,
    66.27315,
    14.00751,
    21,
    27.98
  ],
  [
    23787,
    66.27305,
    14.00874,
    21.2,
    27.95
  ],
  [
    23789,
    66.27296,
    14.00997,
    21.3,
    27.96
  ],
  [
    23791,
    66.27288,
    14.0112,
    21.5,
    27.94
  ],
  [
    23793,
    66.27282,
    14.01244,
    21.7,
    27.93
  ],
  [
    23795,
    66.27283,
    14.01368,
    21.8,
    27.96
  ],
  [
    23797,
    66.27288,
    14.01492,
    21.9,
    28
  ],
  [
    23799,
    66.273,
    14.01613,
    22.1,
    28.01
  ],
  [
    23801,
    66.27315,
    14.01733,
    22.4,
    28.01
  ],
  [
    23803,
    66.27332,
    14.0185,
    22.4,
    27.99
  ],
  [
    23805,
    66.27349,
    14.01967,
    22.8,
    27.94
  ],
  [
    23807,
    66.27368,
    14.02083,
    23,
    27.91
  ],
  [
    23810,
    66.27397,
    14.02254,
    23.2,
    27.85
  ],
  [
    23812,
    66.27417,
    14.02365,
    23.3,
    27.64
  ],
  [
    23814,
    66.27438,
    14.02473,
    23.4,
    27.64
  ],
  [
    23816,
    66.27459,
    14.02577,
    23.6,
    26.09
  ],
  [
    23818,
    66.2748,
    14.02677,
    23.6,
    26.09
  ],
  [
    23820,
    66.27501,
    14.02776,
    23.6,
    25.05
  ],
  [
    23822,
    66.27523,
    14.02872,
    23.7,
    24.87
  ],
  [
    23824,
    66.27545,
    14.02968,
    23.9,
    24.72
  ],
  [
    23826,
    66.27568,
    14.03061,
    24.2,
    24.58
  ],
  [
    23828,
    66.27594,
    14.0315,
    24.3,
    24.55
  ],
  [
    23830,
    66.27624,
    14.03231,
    24.1,
    24.53
  ],
  [
    23832,
    66.27657,
    14.03302,
    24.1,
    24.55
  ],
  [
    23834,
    66.27694,
    14.03363,
    24.1,
    24.56
  ],
  [
    23836,
    66.27733,
    14.03412,
    24.1,
    24.59
  ],
  [
    23838,
    66.27774,
    14.03455,
    24.3,
    24.64
  ],
  [
    23840,
    66.27814,
    14.03499,
    24.5,
    24.69
  ],
  [
    23842,
    66.27854,
    14.03547,
    24.5,
    24.89
  ],
  [
    23844,
    66.27894,
    14.03598,
    24.4,
    24.91
  ],
  [
    23846,
    66.27934,
    14.0365,
    24.5,
    24.91
  ],
  [
    23855,
    66.28101,
    14.03983,
    25.3,
    24.91
  ],
  [
    23857,
    66.281,
    14.0409,
    26.5,
    25.07
  ],
  [
    23859,
    66.28123,
    14.04184,
    24.6,
    25.13
  ],
  [
    23861,
    66.28153,
    14.04268,
    24,
    25.36
  ],
  [
    23863,
    66.28188,
    14.04342,
    24,
    25.31
  ],
  [
    23865,
    66.28227,
    14.04403,
    23.8,
    25.36
  ],
  [
    23867,
    66.28269,
    14.0445,
    23.8,
    25.4
  ],
  [
    23869,
    66.28313,
    14.04487,
    23.7,
    25.54
  ],
  [
    23871,
    66.28357,
    14.04518,
    23.8,
    25.55
  ],
  [
    23873,
    66.28401,
    14.04549,
    24,
    25.67
  ],
  [
    23875,
    66.28446,
    14.0458,
    24.2,
    25.74
  ],
  [
    23877,
    66.28491,
    14.04613,
    24.3,
    26.02
  ],
  [
    23879,
    66.28535,
    14.04649,
    24,
    26.22
  ],
  [
    23881,
    66.28579,
    14.04691,
    23.6,
    26.49
  ],
  [
    23883,
    66.28623,
    14.0474,
    23.1,
    26.71
  ],
  [
    23885,
    66.28666,
    14.04794,
    22.7,
    26.99
  ],
  [
    23887,
    66.28707,
    14.04856,
    22.3,
    27.21
  ],
  [
    23889,
    66.28746,
    14.0493,
    21.6,
    27.27
  ],
  [
    23891,
    66.28782,
    14.05013,
    20.8,
    27.31
  ],
  [
    23893,
    66.28814,
    14.05107,
    20.3,
    27.49
  ],
  [
    23895,
    66.28841,
    14.05209,
    19.2,
    27.51
  ],
  [
    23897,
    66.28863,
    14.05319,
    18.1,
    27.62
  ],
  [
    23899,
    66.28882,
    14.05435,
    17.2,
    27.71
  ],
  [
    23901,
    66.28897,
    14.05553,
    16.3,
    27.82
  ],
  [
    23903,
    66.28912,
    14.05672,
    15.5,
    27.94
  ],
  [
    23905,
    66.28927,
    14.05792,
    14.7,
    28.07
  ],
  [
    23907,
    66.28942,
    14.05912,
    14.1,
    28.19
  ],
  [
    23909,
    66.28957,
    14.06033,
    13.4,
    28.29
  ],
  [
    23911,
    66.28972,
    14.06154,
    12.9,
    28.39
  ],
  [
    23913,
    66.28988,
    14.06276,
    12.6,
    28.45
  ],
  [
    23915,
    66.29003,
    14.06397,
    12.5,
    28.48
  ],
  [
    23917,
    66.29016,
    14.0652,
    12.5,
    28.48
  ],
  [
    23919,
    66.29025,
    14.06646,
    12.6,
    28.41
  ],
  [
    23921,
    66.29028,
    14.06773,
    12.7,
    28.32
  ],
  [
    23923,
    66.29026,
    14.06899,
    12.9,
    28.23
  ],
  [
    23925,
    66.29022,
    14.07025,
    13.1,
    28.15
  ],
  [
    23927,
    66.2902,
    14.07151,
    13.2,
    28.08
  ],
  [
    23929,
    66.29021,
    14.07276,
    13.2,
    28
  ],
  [
    23931,
    66.29028,
    14.074,
    13.7,
    27.91
  ],
  [
    23933,
    66.2904,
    14.0752,
    14.1,
    27.82
  ],
  [
    23936,
    66.29068,
    14.07692,
    15,
    27.71
  ],
  [
    23938,
    66.29092,
    14.07799,
    15.5,
    27.57
  ],
  [
    23940,
    66.29119,
    14.07902,
    16.3,
    27.57
  ],
  [
    23942,
    66.29144,
    14.08007,
    17.1,
    27.34
  ],
  [
    23944,
    66.29165,
    14.08116,
    17.9,
    27.34
  ],
  [
    23946,
    66.29181,
    14.08229,
    18.1,
    26.92
  ],
  [
    23948,
    66.29196,
    14.08342,
    18.3,
    26.92
  ],
  [
    23950,
    66.29211,
    14.08455,
    18.6,
    26.71
  ],
  [
    23952,
    66.29229,
    14.08561,
    19.1,
    25.63
  ],
  [
    23954,
    66.29252,
    14.08667,
    19.2,
    26.59
  ],
  [
    23956,
    66.29275,
    14.08769,
    18,
    26.21
  ],
  [
    23958,
    66.29306,
    14.08859,
    17.7,
    26.67
  ],
  [
    23960,
    66.29342,
    14.08939,
    17.3,
    26.71
  ],
  [
    23962,
    66.29382,
    14.0901,
    16.7,
    26.91
  ],
  [
    23964,
    66.29422,
    14.09076,
    16.2,
    27.03
  ],
  [
    23966,
    66.29463,
    14.09143,
    15.8,
    27.11
  ],
  [
    23968,
    66.29504,
    14.09209,
    15.5,
    27.16
  ],
  [
    23970,
    66.29545,
    14.09275,
    15.3,
    27.17
  ],
  [
    23972,
    66.29586,
    14.09342,
    15.1,
    27.16
  ],
  [
    23974,
    66.29627,
    14.09409,
    15.1,
    26.94
  ],
  [
    23976,
    66.29667,
    14.09474,
    15,
    26.34
  ],
  [
    23978,
    66.29706,
    14.09538,
    15,
    25.67
  ],
  [
    23980,
    66.29744,
    14.096,
    14.9,
    25.01
  ],
  [
    23982,
    66.2978,
    14.09663,
    14.9,
    24.64
  ],
  [
    23984,
    66.29815,
    14.09729,
    15,
    24.46
  ],
  [
    23986,
    66.29847,
    14.09803,
    15,
    24.3
  ],
  [
    23988,
    66.29876,
    14.09884,
    14.7,
    24.21
  ],
  [
    23990,
    66.29901,
    14.09971,
    14.4,
    24.14
  ],
  [
    23992,
    66.29923,
    14.10064,
    14.1,
    24.08
  ],
  [
    23994,
    66.29941,
    14.10162,
    14,
    24.01
  ],
  [
    23996,
    66.29955,
    14.10263,
    13.7,
    23.99
  ],
  [
    23998,
    66.29968,
    14.10365,
    13.2,
    24
  ],
  [
    24000,
    66.2998,
    14.10468,
    12.7,
    24.01
  ],
  [
    24002,
    66.29992,
    14.10571,
    12.4,
    24.01
  ],
  [
    24004,
    66.30005,
    14.10674,
    12.1,
    24
  ],
  [
    24006,
    66.30017,
    14.10777,
    11.9,
    23.96
  ],
  [
    24008,
    66.30033,
    14.10876,
    11.7,
    23.92
  ],
  [
    24010,
    66.30053,
    14.10971,
    11.5,
    23.9
  ],
  [
    24012,
    66.30076,
    14.1106,
    11.4,
    23.9
  ],
  [
    24014,
    66.30104,
    14.11142,
    11.4,
    23.87
  ],
  [
    24016,
    66.30134,
    14.11216,
    11.5,
    23.86
  ],
  [
    24018,
    66.30168,
    14.11282,
    11.5,
    23.81
  ],
  [
    24020,
    66.30203,
    14.1134,
    11.7,
    23.77
  ],
  [
    24022,
    66.3024,
    14.11393,
    11.9,
    23.72
  ],
  [
    24024,
    66.30277,
    14.11445,
    12.3,
    23.64
  ],
  [
    24027,
    66.30331,
    14.11527,
    12.9,
    23.52
  ],
  [
    24029,
    66.30364,
    14.1159,
    13.2,
    23.38
  ],
  [
    24031,
    66.30394,
    14.11662,
    13.4,
    23.19
  ],
  [
    24033,
    66.3042,
    14.11741,
    13.6,
    23.19
  ],
  [
    24035,
    66.30442,
    14.11827,
    13.7,
    23.19
  ],
  [
    24037,
    66.3046,
    14.11918,
    13.9,
    22.77
  ],
  [
    24039,
    66.30474,
    14.12012,
    13.9,
    22.53
  ],
  [
    24041,
    66.30483,
    14.1211,
    13.8,
    22.42
  ],
  [
    24043,
    66.30487,
    14.12209,
    13.7,
    22.32
  ],
  [
    24045,
    66.30488,
    14.12308,
    13.7,
    22.23
  ],
  [
    24047,
    66.30485,
    14.12407,
    13.9,
    22.06
  ],
  [
    24049,
    66.30481,
    14.12503,
    14.1,
    21.5
  ],
  [
    24051,
    66.30477,
    14.12598,
    14.2,
    20.85
  ],
  [
    24053,
    66.30475,
    14.12689,
    14.2,
    20.29
  ],
  [
    24055,
    66.30474,
    14.12779,
    14.2,
    20.1
  ],
  [
    24057,
    66.30478,
    14.12867,
    14,
    19.85
  ],
  [
    24059,
    66.30486,
    14.12953,
    13.9,
    19.78
  ],
  [
    24061,
    66.30498,
    14.13035,
    13.8,
    19.72
  ],
  [
    24063,
    66.30515,
    14.13112,
    13,
    19.82
  ],
  [
    24065,
    66.30536,
    14.13183,
    12.9,
    19.86
  ],
  [
    24067,
    66.30561,
    14.13246,
    12.9,
    19.88
  ],
  [
    24069,
    66.30589,
    14.13301,
    12.9,
    19.9
  ],
  [
    24071,
    66.3062,
    14.13346,
    12.6,
    19.9
  ],
  [
    24073,
    66.30652,
    14.13379,
    12.4,
    19.31
  ],
  [
    24075,
    66.30685,
    14.13401,
    12.1,
    18.54
  ],
  [
    24077,
    66.30718,
    14.13412,
    11.8,
    17.75
  ],
  [
    24079,
    66.30749,
    14.13413,
    11.6,
    16.93
  ],
  [
    24081,
    66.30779,
    14.13406,
    11.4,
    16.08
  ],
  [
    24083,
    66.30806,
    14.13394,
    11.3,
    15.27
  ],
  [
    24085,
    66.30833,
    14.13382,
    11.3,
    14.4
  ],
  [
    24087,
    66.30857,
    14.1337,
    11.2,
    13.52
  ],
  [
    24089,
    66.3088,
    14.13359,
    11.2,
    12.62
  ],
  [
    24091,
    66.30902,
    14.13348,
    11.2,
    11.71
  ],
  [
    24093,
    66.30921,
    14.13339,
    11.1,
    10.78
  ],
  [
    24095,
    66.30939,
    14.1333,
    11.1,
    9.84
  ],
  [
    24097,
    66.30956,
    14.13322,
    11.1,
    8.93
  ],
  [
    24099,
    66.30971,
    14.13315,
    11.1,
    8.09
  ],
  [
    24101,
    66.30984,
    14.13308,
    11.1,
    7.29
  ],
  [
    24103,
    66.30996,
    14.13302,
    11.1,
    6.54
  ],
  [
    24105,
    66.31007,
    14.13297,
    11,
    5.84
  ],
  [
    24107,
    66.31016,
    14.13292,
    11,
    5.14
  ],
  [
    24109,
    66.31025,
    14.13288,
    11,
    4.44
  ],
  [
    24111,
    66.31032,
    14.13284,
    10.9,
    3.76
  ],
  [
    24113,
    66.31037,
    14.13281,
    10.9,
    3.02
  ],
  [
    24116,
    66.31043,
    14.13277,
    10.9,
    2.22
  ],
  [
    24118,
    66.31045,
    14.13276,
    10.8,
    2.22
  ],
  [
    24120,
    66.31046,
    14.13275,
    10.8,
    2.22
  ],
  [
    24122,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24124,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24126,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24128,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24130,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24132,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24134,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24136,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24138,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24140,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24142,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24144,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24146,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24148,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24150,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24152,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24154,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24156,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24158,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24160,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24162,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24164,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24166,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24168,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24170,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24172,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24174,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24176,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24178,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24180,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24182,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24184,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24186,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24188,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24190,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24192,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24194,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24196,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24198,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24200,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24202,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24204,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24206,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24208,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24211,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24213,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24215,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24217,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24219,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24221,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24223,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24225,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24227,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24229,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24231,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24233,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24235,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24237,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24239,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24241,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24243,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24245,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24247,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24249,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24251,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24253,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24255,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24257,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24259,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24261,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24263,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24265,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24267,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24269,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24271,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24273,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24275,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24277,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24279,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24281,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24283,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24285,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24287,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24289,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24291,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24293,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24295,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24297,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24299,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24301,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24303,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24306,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24308,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24310,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24312,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24314,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24316,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24318,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24320,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24322,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24324,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24326,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24328,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24330,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24332,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24334,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24336,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24338,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24340,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24342,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24344,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24346,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24348,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24350,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24352,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24354,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24356,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24358,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24360,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24362,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24364,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24366,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24368,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24370,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24372,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24374,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24376,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24378,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24380,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24382,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24384,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24386,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24388,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24390,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24392,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24394,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24396,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24399,
    66.31046,
    14.13275,
    10.8,
    0
  ],
  [
    24401,
    66.31045,
    14.1326,
    9.5,
    0
  ],
  [
    24403,
    66.31048,
    14.13259,
    9.5,
    1.49
  ],
  [
    24405,
    66.31052,
    14.13257,
    9.5,
    1.49
  ],
  [
    24407,
    66.31057,
    14.13255,
    9.6,
    2.73
  ],
  [
    24409,
    66.31064,
    14.13252,
    9.6,
    2.73
  ],
  [
    24411,
    66.31072,
    14.13248,
    9.6,
    4.5
  ],
  [
    24413,
    66.31081,
    14.13245,
    9.7,
    5.07
  ],
  [
    24415,
    66.31091,
    14.1324,
    9.7,
    5.64
  ],
  [
    24417,
    66.31102,
    14.13236,
    9.7,
    6.17
  ],
  [
    24419,
    66.31114,
    14.1323,
    9.8,
    6.94
  ],
  [
    24421,
    66.31127,
    14.13225,
    9.8,
    7.43
  ],
  [
    24423,
    66.31141,
    14.13219,
    9.9,
    7.92
  ],
  [
    24425,
    66.31155,
    14.13213,
    9.9,
    8.14
  ],
  [
    24427,
    66.3117,
    14.13206,
    10,
    8.85
  ],
  [
    24429,
    66.31186,
    14.13199,
    10.1,
    9.31
  ],
  [
    24431,
    66.31203,
    14.13192,
    10.2,
    9.77
  ],
  [
    24433,
    66.31221,
    14.13184,
    10.3,
    10.25
  ],
  [
    24435,
    66.3124,
    14.13176,
    10.4,
    10.73
  ],
  [
    24437,
    66.31259,
    14.13168,
    10.4,
    11.16
  ],
  [
    24439,
    66.3128,
    14.13159,
    10.5,
    11.61
  ],
  [
    24441,
    66.31301,
    14.1315,
    10.6,
    12.05
  ],
  [
    24443,
    66.31322,
    14.1314,
    10.7,
    12.48
  ],
  [
    24445,
    66.31345,
    14.1313,
    10.8,
    12.92
  ],
  [
    24447,
    66.31368,
    14.1312,
    10.9,
    13.45
  ],
  [
    24449,
    66.31393,
    14.1311,
    11,
    13.87
  ],
  [
    24451,
    66.31418,
    14.13099,
    11.1,
    14.14
  ],
  [
    24453,
    66.31443,
    14.13088,
    11.2,
    14.22
  ],
  [
    24455,
    66.31468,
    14.13077,
    11.4,
    14.3
  ],
  [
    24457,
    66.31493,
    14.13067,
    11.5,
    14.33
  ],
  [
    24459,
    66.31519,
    14.13059,
    11.6,
    14.37
  ],
  [
    24461,
    66.31545,
    14.13056,
    11.8,
    14.38
  ],
  [
    24463,
    66.31571,
    14.1306,
    11.8,
    14.34
  ],
  [
    24465,
    66.31596,
    14.13069,
    11.9,
    14.27
  ],
  [
    24467,
    66.31621,
    14.13084,
    11.9,
    14.21
  ],
  [
    24469,
    66.31645,
    14.13105,
    12,
    13.99
  ],
  [
    24471,
    66.31668,
    14.13131,
    12,
    14.11
  ],
  [
    24473,
    66.3169,
    14.13163,
    12.1,
    14.03
  ],
  [
    24475,
    66.31711,
    14.132,
    12.1,
    13.97
  ],
  [
    24477,
    66.3173,
    14.13241,
    12.1,
    13.93
  ],
  [
    24479,
    66.31748,
    14.13287,
    11.8,
    13.91
  ],
  [
    24481,
    66.31763,
    14.13336,
    11.8,
    13.86
  ],
  [
    24484,
    66.31783,
    14.13416,
    11.7,
    13.83
  ],
  [
    24486,
    66.31794,
    14.13472,
    11.5,
    13.82
  ],
  [
    24488,
    66.31802,
    14.13531,
    11.4,
    13.8
  ],
  [
    24490,
    66.31809,
    14.13591,
    11.2,
    13.8
  ],
  [
    24492,
    66.31814,
    14.13652,
    11.1,
    13.82
  ],
  [
    24494,
    66.31819,
    14.13713,
    11,
    14.02
  ],
  [
    24496,
    66.31824,
    14.13776,
    11,
    14.21
  ],
  [
    24498,
    66.31829,
    14.13839,
    11,
    14.42
  ],
  [
    24500,
    66.31835,
    14.13903,
    11,
    14.53
  ],
  [
    24502,
    66.3184,
    14.13968,
    11,
    14.87
  ],
  [
    24504,
    66.31845,
    14.14034,
    11,
    15.09
  ],
  [
    24506,
    66.31851,
    14.14101,
    11,
    15.36
  ],
  [
    24508,
    66.31856,
    14.14169,
    11.1,
    15.63
  ],
  [
    24510,
    66.31862,
    14.14239,
    11.1,
    15.93
  ],
  [
    24512,
    66.31867,
    14.1431,
    11.2,
    16.23
  ],
  [
    24514,
    66.31874,
    14.14382,
    11.3,
    16.57
  ],
  [
    24516,
    66.31881,
    14.14455,
    11.4,
    16.96
  ],
  [
    24518,
    66.31891,
    14.14527,
    11.5,
    17.37
  ],
  [
    24520,
    66.31904,
    14.14599,
    11.6,
    17.77
  ],
  [
    24522,
    66.31921,
    14.14668,
    11.7,
    18.15
  ],
  [
    24524,
    66.3194,
    14.14734,
    11.8,
    18.51
  ],
  [
    24526,
    66.31963,
    14.14796,
    11.8,
    18.86
  ],
  [
    24528,
    66.31989,
    14.14853,
    13.4,
    20.01
  ],
  [
    24530,
    66.32018,
    14.14903,
    13.9,
    19.5
  ],
  [
    24532,
    66.32049,
    14.14944,
    14.2,
    19.71
  ],
  [
    24534,
    66.32082,
    14.14977,
    14.4,
    19.86
  ],
  [
    24536,
    66.32116,
    14.15001,
    14.6,
    19.99
  ],
  [
    24538,
    66.32151,
    14.15017,
    14.8,
    20.05
  ],
  [
    24540,
    66.32187,
    14.1503,
    15,
    20.06
  ],
  [
    24542,
    66.32223,
    14.15043,
    15.3,
    20.02
  ],
  [
    24544,
    66.32258,
    14.15056,
    15.7,
    19.95
  ],
  [
    24546,
    66.32293,
    14.15068,
    16.2,
    19.81
  ],
  [
    24548,
    66.32328,
    14.1508,
    16.7,
    19.63
  ],
  [
    24550,
    66.32363,
    14.15093,
    17,
    19.41
  ],
  [
    24552,
    66.32397,
    14.15106,
    17,
    19.19
  ],
  [
    24554,
    66.3243,
    14.15126,
    18.1,
    19
  ],
  [
    24557,
    66.32477,
    14.15176,
    18.7,
    18.81
  ],
  [
    24559,
    66.32504,
    14.15223,
    19,
    18.67
  ],
  [
    24561,
    66.32529,
    14.15279,
    19.2,
    18.5
  ],
  [
    24563,
    66.32549,
    14.15343,
    19.3,
    18.5
  ],
  [
    24565,
    66.32566,
    14.15413,
    19.5,
    18.5
  ],
  [
    24567,
    66.32578,
    14.15487,
    19.6,
    18.14
  ],
  [
    24569,
    66.32586,
    14.15565,
    19.8,
    17.86
  ],
  [
    24571,
    66.32589,
    14.15643,
    20.3,
    17.64
  ],
  [
    24573,
    66.32588,
    14.1572,
    21.1,
    17.23
  ],
  [
    24575,
    66.32582,
    14.15795,
    22.2,
    17.13
  ],
  [
    24577,
    66.32573,
    14.15866,
    23.6,
    16.51
  ],
  [
    24579,
    66.32559,
    14.15929,
    25.4,
    15.4
  ],
  [
    24581,
    66.32543,
    14.15983,
    24.3,
    14.69
  ],
  [
    24583,
    66.32526,
    14.16032,
    22.6,
    13.86
  ],
  [
    24585,
    66.32509,
    14.16077,
    20.6,
    13.39
  ],
  [
    24587,
    66.32493,
    14.16119,
    19.5,
    12.04
  ],
  [
    24589,
    66.3248,
    14.16158,
    18.7,
    11.34
  ],
  [
    24591,
    66.32467,
    14.16196,
    18.2,
    10.95
  ],
  [
    24593,
    66.32454,
    14.16234,
    17.9,
    10.71
  ],
  [
    24595,
    66.32442,
    14.1627,
    17.6,
    10.55
  ],
  [
    24597,
    66.3243,
    14.16306,
    17.3,
    10.51
  ],
  [
    24599,
    66.32418,
    14.16342,
    17,
    10.59
  ],
  [
    24601,
    66.32406,
    14.16379,
    16.7,
    10.71
  ],
  [
    24603,
    66.32394,
    14.16416,
    16.5,
    10.88
  ],
  [
    24605,
    66.32381,
    14.16454,
    16.2,
    11.09
  ],
  [
    24607,
    66.32368,
    14.16493,
    16,
    11.3
  ],
  [
    24609,
    66.32355,
    14.16532,
    15.8,
    11.52
  ],
  [
    24611,
    66.32342,
    14.16572,
    15.6,
    11.74
  ],
  [
    24613,
    66.32329,
    14.16613,
    15.4,
    11.97
  ],
  [
    24615,
    66.32315,
    14.16654,
    15.3,
    12.19
  ],
  [
    24617,
    66.32301,
    14.16697,
    15.1,
    12.39
  ],
  [
    24619,
    66.32287,
    14.1674,
    15,
    12.58
  ],
  [
    24621,
    66.32272,
    14.16784,
    15,
    12.78
  ],
  [
    24623,
    66.32257,
    14.16828,
    15,
    12.99
  ],
  [
    24625,
    66.32243,
    14.16874,
    15.1,
    13.21
  ],
  [
    24627,
    66.32228,
    14.16921,
    15.1,
    13.42
  ],
  [
    24629,
    66.32214,
    14.1697,
    15.3,
    13.65
  ],
  [
    24631,
    66.32201,
    14.17022,
    15.4,
    13.94
  ],
  [
    24634,
    66.32183,
    14.17106,
    15.6,
    14.3
  ],
  [
    24636,
    66.32172,
    14.17166,
    15.8,
    14.67
  ],
  [
    24638,
    66.32163,
    14.17229,
    16,
    14.67
  ],
  [
    24640,
    66.32155,
    14.17295,
    16.3,
    14.67
  ],
  [
    24642,
    66.32149,
    14.17364,
    16.5,
    15.59
  ],
  [
    24644,
    66.32144,
    14.17435,
    16.6,
    15.93
  ],
  [
    24646,
    66.32142,
    14.17509,
    16.7,
    16.47
  ],
  [
    24648,
    66.3214,
    14.17584,
    16.7,
    16.86
  ],
  [
    24650,
    66.3214,
    14.17661,
    16.9,
    17.26
  ],
  [
    24652,
    66.32139,
    14.1774,
    17,
    17.85
  ],
  [
    24654,
    66.32138,
    14.17821,
    17.1,
    18.25
  ],
  [
    24656,
    66.32137,
    14.17904,
    17.2,
    18.64
  ],
  [
    24658,
    66.32137,
    14.17988,
    17.3,
    19.02
  ],
  [
    24660,
    66.32136,
    14.18074,
    17.4,
    19.2
  ],
  [
    24662,
    66.32138,
    14.18161,
    17.7,
    19.71
  ],
  [
    24664,
    66.32143,
    14.18249,
    17.7,
    20.1
  ],
  [
    24666,
    66.32151,
    14.18338,
    17.7,
    20.55
  ],
  [
    24668,
    66.32164,
    14.18425,
    17.7,
    20.99
  ],
  [
    24670,
    66.3218,
    14.18511,
    17.9,
    21.46
  ],
  [
    24672,
    66.32199,
    14.18597,
    18.2,
    21.93
  ],
  [
    24674,
    66.32218,
    14.18684,
    18.5,
    22.37
  ],
  [
    24676,
    66.32235,
    14.18777,
    18.7,
    22.79
  ],
  [
    24678,
    66.32248,
    14.18874,
    18.8,
    23.18
  ],
  [
    24680,
    66.32257,
    14.18976,
    18.7,
    23.59
  ],
  [
    24682,
    66.32265,
    14.19081,
    18.7,
    24
  ],
  [
    24684,
    66.32272,
    14.19188,
    18.6,
    24.43
  ],
  [
    24686,
    66.32279,
    14.19297,
    18.6,
    24.88
  ],
  [
    24688,
    66.32286,
    14.19409,
    18.6,
    25.28
  ],
  [
    24690,
    66.32292,
    14.19521,
    18.6,
    25.62
  ],
  [
    24692,
    66.32298,
    14.19636,
    18.6,
    25.81
  ],
  [
    24694,
    66.32304,
    14.19751,
    18.6,
    25.93
  ],
  [
    24696,
    66.3231,
    14.19866,
    18.4,
    26.02
  ],
  [
    24698,
    66.32314,
    14.19982,
    18.3,
    26.07
  ],
  [
    24700,
    66.32319,
    14.20098,
    18.3,
    26.11
  ],
  [
    24702,
    66.32323,
    14.20215,
    18.3,
    26.14
  ],
  [
    24704,
    66.32327,
    14.20331,
    18.3,
    26.14
  ],
  [
    24706,
    66.3233,
    14.20448,
    18.2,
    26.14
  ],
  [
    24708,
    66.32333,
    14.20565,
    18.3,
    26.14
  ],
  [
    24711,
    66.32336,
    14.2074,
    18.4,
    26.13
  ],
  [
    24713,
    66.32338,
    14.20856,
    18.4,
    26.09
  ],
  [
    24715,
    66.32339,
    14.20973,
    18.6,
    26.08
  ],
  [
    24717,
    66.32338,
    14.21089,
    18.7,
    26.04
  ],
  [
    24719,
    66.32334,
    14.21204,
    18.8,
    26.04
  ],
  [
    24721,
    66.32326,
    14.21318,
    19,
    25.86
  ],
  [
    24723,
    66.32317,
    14.21431,
    19.1,
    25.84
  ],
  [
    24725,
    66.32307,
    14.21544,
    19.2,
    25.77
  ],
  [
    24727,
    66.323,
    14.21658,
    19.3,
    25.75
  ],
  [
    24729,
    66.32298,
    14.21772,
    19.4,
    25.71
  ],
  [
    24731,
    66.32301,
    14.21887,
    19.6,
    25.68
  ],
  [
    24733,
    66.32308,
    14.22,
    19.6,
    25.69
  ],
  [
    24735,
    66.32316,
    14.22113,
    19.8,
    25.69
  ],
  [
    24737,
    66.32322,
    14.22227,
    19.9,
    25.7
  ],
  [
    24739,
    66.32323,
    14.22341,
    20,
    25.68
  ],
  [
    24741,
    66.32318,
    14.22455,
    20.2,
    25.62
  ],
  [
    24743,
    66.32307,
    14.22566,
    20.4,
    25.61
  ],
  [
    24745,
    66.3229,
    14.22673,
    20.3,
    25.54
  ],
  [
    24747,
    66.32271,
    14.22777,
    20.5,
    25.48
  ],
  [
    24749,
    66.32251,
    14.22879,
    19.9,
    25.46
  ],
  [
    24751,
    66.32231,
    14.22982,
    19.3,
    25.47
  ],
  [
    24753,
    66.32212,
    14.23085,
    19,
    25.45
  ],
  [
    24755,
    66.32193,
    14.23188,
    19.3,
    25.43
  ],
  [
    24757,
    66.32175,
    14.23293,
    20,
    25.4
  ],
  [
    24759,
    66.32159,
    14.23399,
    20.7,
    25.36
  ],
  [
    24761,
    66.32144,
    14.23506,
    20.6,
    25.35
  ],
  [
    24763,
    66.32131,
    14.23614,
    20.9,
    25.34
  ],
  [
    24765,
    66.32117,
    14.23722,
    21,
    25.35
  ],
  [
    24767,
    66.32102,
    14.23828,
    21.1,
    25.38
  ],
  [
    24769,
    66.32082,
    14.2393,
    21,
    25.36
  ],
  [
    24771,
    66.32057,
    14.24026,
    20.6,
    25.31
  ],
  [
    24773,
    66.3203,
    14.24118,
    20.2,
    25.31
  ],
  [
    24775,
    66.32003,
    14.24209,
    19.9,
    25.33
  ],
  [
    24777,
    66.31978,
    14.24303,
    19.6,
    25.37
  ],
  [
    24779,
    66.31957,
    14.24403,
    19.4,
    25.39
  ],
  [
    24781,
    66.31941,
    14.24509,
    19.3,
    25.4
  ],
  [
    24783,
    66.3193,
    14.24619,
    19.3,
    25.41
  ],
  [
    24785,
    66.31926,
    14.24732,
    19.5,
    25.41
  ],
  [
    24787,
    66.31927,
    14.24845,
    19.6,
    25.39
  ],
  [
    24790,
    66.3194,
    14.25012,
    19.9,
    25.37
  ],
  [
    24792,
    66.31956,
    14.25119,
    20.1,
    25.35
  ],
  [
    24794,
    66.31977,
    14.25219,
    20.3,
    25.35
  ],
  [
    24796,
    66.32002,
    14.25314,
    20.2,
    25.37
  ],
  [
    24798,
    66.32029,
    14.25406,
    20.2,
    25.37
  ],
  [
    24800,
    66.32056,
    14.25498,
    20.2,
    25.52
  ],
  [
    24802,
    66.32085,
    14.25589,
    20.2,
    25.59
  ],
  [
    24804,
    66.32114,
    14.25677,
    20.3,
    25.66
  ],
  [
    24806,
    66.32146,
    14.25759,
    20.3,
    25.68
  ],
  [
    24808,
    66.32183,
    14.2583,
    20.4,
    25.71
  ],
  [
    24810,
    66.32223,
    14.25888,
    20.5,
    25.72
  ],
  [
    24812,
    66.32266,
    14.25933,
    20.7,
    25.76
  ],
  [
    24814,
    66.3231,
    14.25965,
    20.7,
    25.83
  ],
  [
    24816,
    66.32356,
    14.2599,
    20.7,
    25.9
  ],
  [
    24818,
    66.32402,
    14.26013,
    20.7,
    25.99
  ],
  [
    24820,
    66.32447,
    14.26041,
    20.8,
    26.02
  ],
  [
    24822,
    66.32491,
    14.26079,
    21.4,
    26
  ],
  [
    24824,
    66.32533,
    14.26133,
    21.7,
    26.14
  ],
  [
    24826,
    66.32572,
    14.26198,
    21.7,
    26.09
  ],
  [
    24828,
    66.32609,
    14.26269,
    21.8,
    26.1
  ],
  [
    24830,
    66.32647,
    14.26339,
    21.9,
    26.15
  ],
  [
    24832,
    66.32684,
    14.26411,
    21.7,
    26.14
  ],
  [
    24834,
    66.32721,
    14.26481,
    21.8,
    26.11
  ],
  [
    24836,
    66.32759,
    14.26552,
    21.8,
    26.07
  ],
  [
    24838,
    66.32796,
    14.26622,
    22,
    26.07
  ],
  [
    24840,
    66.32835,
    14.26687,
    21.9,
    25.93
  ],
  [
    24842,
    66.32875,
    14.26743,
    21.9,
    25.86
  ],
  [
    24844,
    66.32918,
    14.26788,
    22,
    25.79
  ],
  [
    24846,
    66.32963,
    14.26821,
    22.1,
    25.69
  ],
  [
    24848,
    66.33008,
    14.26843,
    22.3,
    25.58
  ],
  [
    24850,
    66.33053,
    14.2686,
    22.4,
    24.96
  ],
  [
    24852,
    66.33096,
    14.26881,
    22.6,
    24.19
  ],
  [
    24854,
    66.33137,
    14.26912,
    22.7,
    23.33
  ],
  [
    24856,
    66.33174,
    14.26953,
    22.7,
    22.5
  ],
  [
    24858,
    66.33209,
    14.27002,
    22.6,
    21.83
  ],
  [
    24860,
    66.3324,
    14.27059,
    22.6,
    21.44
  ],
  [
    24862,
    66.33269,
    14.27121,
    22.6,
    21.18
  ],
  [
    24864,
    66.33296,
    14.27187,
    22.7,
    20.98
  ],
  [
    24867,
    66.3333,
    14.27298,
    22.6,
    20.87
  ],
  [
    24869,
    66.33347,
    14.2738,
    22.4,
    20.87
  ],
  [
    24871,
    66.3336,
    14.27467,
    22.1,
    20.74
  ],
  [
    24873,
    66.33367,
    14.27558,
    21.8,
    20.74
  ],
  [
    24875,
    66.3337,
    14.2765,
    22.1,
    20.74
  ],
  [
    24877,
    66.33371,
    14.27743,
    22.2,
    20.7
  ],
  [
    24879,
    66.33371,
    14.27836,
    22.3,
    20.77
  ],
  [
    24881,
    66.33371,
    14.27929,
    22.4,
    20.95
  ],
  [
    24883,
    66.33371,
    14.28023,
    22.6,
    21.1
  ],
  [
    24885,
    66.33372,
    14.28117,
    23,
    21.18
  ],
  [
    24887,
    66.33372,
    14.28213,
    23.2,
    21.38
  ],
  [
    24889,
    66.33373,
    14.28308,
    23.4,
    21.51
  ],
  [
    24891,
    66.33374,
    14.28404,
    23.6,
    21.63
  ],
  [
    24893,
    66.33376,
    14.28501,
    23.9,
    21.74
  ],
  [
    24895,
    66.33378,
    14.28598,
    24.1,
    21.81
  ],
  [
    24897,
    66.3338,
    14.28696,
    24.2,
    22.03
  ],
  [
    24899,
    66.3338,
    14.28794,
    23.5,
    22.18
  ],
  [
    24901,
    66.33378,
    14.28893,
    21.8,
    22.38
  ],
  [
    24903,
    66.33372,
    14.28992,
    21.8,
    22.52
  ],
  [
    24905,
    66.33364,
    14.29091,
    21.6,
    22.65
  ],
  [
    24907,
    66.33356,
    14.2919,
    21.8,
    22.81
  ],
  [
    24909,
    66.33348,
    14.2929,
    22.2,
    22.96
  ],
  [
    24911,
    66.3334,
    14.29392,
    22.7,
    23.09
  ],
  [
    24913,
    66.33334,
    14.29494,
    23.3,
    23.21
  ],
  [
    24915,
    66.3333,
    14.29598,
    24,
    23.32
  ],
  [
    24917,
    66.33328,
    14.29702,
    24.6,
    23.44
  ],
  [
    24919,
    66.33328,
    14.29807,
    25.2,
    23.57
  ],
  [
    24921,
    66.3333,
    14.29912,
    25.8,
    23.7
  ],
  [
    24923,
    66.33335,
    14.30018,
    26.3,
    23.81
  ],
  [
    24925,
    66.33344,
    14.30122,
    26.8,
    23.93
  ],
  [
    24927,
    66.33357,
    14.30225,
    27.5,
    24.02
  ],
  [
    24929,
    66.33375,
    14.30322,
    28.2,
    24.09
  ],
  [
    24931,
    66.33397,
    14.30415,
    28.8,
    24.19
  ],
  [
    24933,
    66.33422,
    14.30504,
    29.2,
    24.26
  ],
  [
    24935,
    66.33447,
    14.30594,
    29.3,
    24.36
  ],
  [
    24937,
    66.33472,
    14.30684,
    29.3,
    24.5
  ],
  [
    24939,
    66.33497,
    14.30774,
    30.2,
    24.61
  ],
  [
    24941,
    66.33523,
    14.30864,
    30.5,
    24.7
  ],
  [
    24944,
    66.33561,
    14.31001,
    31.1,
    24.81
  ],
  [
    24946,
    66.33587,
    14.31093,
    31.5,
    24.89
  ],
  [
    24948,
    66.33612,
    14.31184,
    31.9,
    24.89
  ],
  [
    24950,
    66.33638,
    14.31277,
    32.4,
    24.89
  ],
  [
    24952,
    66.33661,
    14.31374,
    32.8,
    25.1
  ],
  [
    24954,
    66.3368,
    14.31476,
    33.1,
    25.1
  ],
  [
    24956,
    66.33696,
    14.31582,
    33.2,
    25.23
  ],
  [
    24958,
    66.33707,
    14.31692,
    33.4,
    25.26
  ],
  [
    24960,
    66.33716,
    14.31802,
    33.6,
    25.31
  ],
  [
    24962,
    66.33727,
    14.31913,
    33.6,
    25.39
  ],
  [
    24964,
    66.33741,
    14.32021,
    33.7,
    25.49
  ],
  [
    24966,
    66.33757,
    14.32128,
    33.9,
    25.56
  ],
  [
    24968,
    66.33778,
    14.3223,
    34.3,
    25.57
  ],
  [
    24970,
    66.33801,
    14.32328,
    34.9,
    25.53
  ],
  [
    24972,
    66.33827,
    14.32421,
    35.5,
    25.41
  ],
  [
    24974,
    66.33856,
    14.32508,
    36.2,
    25.31
  ],
  [
    24976,
    66.33888,
    14.32589,
    36.7,
    25.21
  ],
  [
    24978,
    66.33922,
    14.32663,
    37.3,
    25.15
  ],
  [
    24980,
    66.33958,
    14.32731,
    37.9,
    25.13
  ],
  [
    24982,
    66.33996,
    14.32792,
    38.6,
    25.07
  ],
  [
    24984,
    66.34035,
    14.32849,
    39.4,
    25
  ],
  [
    24986,
    66.34074,
    14.32905,
    40.2,
    24.99
  ],
  [
    24988,
    66.34112,
    14.32961,
    40.9,
    24.96
  ],
  [
    24990,
    66.34151,
    14.33013,
    41.3,
    24.35
  ],
  [
    24992,
    66.3419,
    14.33063,
    41.7,
    24.35
  ],
  [
    24994,
    66.34229,
    14.33113,
    42.2,
    24.35
  ],
  [
    24998,
    66.34264,
    14.33345,
    43.2,
    25.34
  ],
  [
    25000,
    66.34286,
    14.33452,
    44,
    25.38
  ],
  [
    25002,
    66.34307,
    14.33556,
    44.7,
    25.48
  ],
  [
    25004,
    66.34328,
    14.3366,
    45.9,
    25.06
  ],
  [
    25006,
    66.34347,
    14.33761,
    46.4,
    25.05
  ],
  [
    25008,
    66.34366,
    14.33862,
    46.8,
    25.05
  ],
  [
    25030,
    66.34709,
    14.34771,
    50.9,
    25.32
  ],
  [
    25032,
    66.34753,
    14.34806,
    49.8,
    25.85
  ],
  [
    25034,
    66.34798,
    14.34826,
    49.2,
    25.98
  ],
  [
    25036,
    66.34845,
    14.34834,
    48.5,
    26.07
  ],
  [
    25038,
    66.34891,
    14.34834,
    49.3,
    25.41
  ],
  [
    25040,
    66.34936,
    14.34833,
    49.3,
    25.41
  ],
  [
    25043,
    66.34996,
    14.34769,
    50.3,
    24.59
  ],
  [
    25045,
    66.35038,
    14.34681,
    50.3,
    26.09
  ],
  [
    25047,
    66.35075,
    14.34598,
    53,
    25.89
  ],
  [
    25049,
    66.35108,
    14.34516,
    53,
    25.79
  ],
  [
    25051,
    66.35136,
    14.34425,
    53.6,
    25.68
  ],
  [
    25054,
    66.35173,
    14.34279,
    55.1,
    25.58
  ],
  [
    25056,
    66.35196,
    14.34181,
    56.3,
    25.45
  ],
  [
    25058,
    66.3522,
    14.34085,
    57.2,
    25.45
  ],
  [
    25060,
    66.35248,
    14.33998,
    57.7,
    25.45
  ],
  [
    25062,
    66.35279,
    14.3392,
    58.1,
    24.81
  ],
  [
    25064,
    66.35315,
    14.33854,
    58.1,
    24.59
  ],
  [
    25066,
    66.35352,
    14.33799,
    57.9,
    24.35
  ],
  [
    25068,
    66.35393,
    14.33756,
    57.8,
    24.25
  ],
  [
    25070,
    66.35434,
    14.33726,
    57.6,
    24.2
  ],
  [
    25072,
    66.35477,
    14.33709,
    57.5,
    24.11
  ],
  [
    25074,
    66.35521,
    14.33705,
    57.6,
    24.06
  ],
  [
    25076,
    66.35564,
    14.33713,
    57.5,
    23.96
  ],
  [
    25078,
    66.35607,
    14.33728,
    57.4,
    23.88
  ],
  [
    25080,
    66.35649,
    14.33746,
    57.5,
    23.77
  ],
  [
    25082,
    66.35691,
    14.33764,
    57.5,
    23.74
  ],
  [
    25084,
    66.35733,
    14.33783,
    57.5,
    23.7
  ],
  [
    25086,
    66.35775,
    14.33802,
    57.5,
    23.63
  ],
  [
    25088,
    66.35817,
    14.33821,
    57.4,
    23.56
  ],
  [
    25090,
    66.35859,
    14.33841,
    57.5,
    23.49
  ],
  [
    25092,
    66.359,
    14.33863,
    57.4,
    23.44
  ],
  [
    25094,
    66.35941,
    14.33892,
    57.2,
    23.42
  ],
  [
    25096,
    66.3598,
    14.33931,
    57,
    23.31
  ],
  [
    25098,
    66.36016,
    14.33981,
    56.9,
    23.25
  ],
  [
    25100,
    66.3605,
    14.34041,
    56.8,
    23.16
  ],
  [
    25102,
    66.36081,
    14.34111,
    56.6,
    23.09
  ],
  [
    25104,
    66.36109,
    14.34188,
    56.2,
    23.01
  ],
  [
    25106,
    66.36135,
    14.34268,
    55.9,
    22.93
  ],
  [
    25108,
    66.36161,
    14.34349,
    55.7,
    22.9
  ],
  [
    25110,
    66.36186,
    14.3443,
    55.5,
    22.87
  ],
  [
    25112,
    66.36211,
    14.34511,
    55.2,
    22.85
  ],
  [
    25114,
    66.36237,
    14.34592,
    55.1,
    22.89
  ],
  [
    25116,
    66.36263,
    14.34672,
    55.1,
    22.95
  ],
  [
    25118,
    66.36288,
    14.34753,
    54.7,
    23
  ],
  [
    25121,
    66.36327,
    14.34875,
    54.4,
    23.06
  ],
  [
    25123,
    66.36352,
    14.34956,
    54.3,
    23.15
  ],
  [
    25125,
    66.36378,
    14.35038,
    54.1,
    23.15
  ],
  [
    25127,
    66.36405,
    14.35121,
    53.9,
    23.34
  ],
  [
    25129,
    66.36431,
    14.35203,
    53.8,
    23.57
  ],
  [
    25131,
    66.36457,
    14.35287,
    53.7,
    23.72
  ],
  [
    25133,
    66.36484,
    14.35371,
    53.5,
    23.97
  ],
  [
    25135,
    66.36511,
    14.35455,
    53.4,
    24.04
  ],
  [
    25137,
    66.36538,
    14.3554,
    53.4,
    24.25
  ],
  [
    25139,
    66.36565,
    14.35626,
    53.3,
    24.37
  ],
  [
    25141,
    66.36592,
    14.35712,
    53.3,
    24.48
  ],
  [
    25143,
    66.3662,
    14.35798,
    53.3,
    24.59
  ],
  [
    25145,
    66.36646,
    14.35886,
    53.4,
    24.64
  ],
  [
    25147,
    66.36671,
    14.35978,
    53.5,
    24.79
  ],
  [
    25149,
    66.36692,
    14.36076,
    53.5,
    24.9
  ],
  [
    25151,
    66.3671,
    14.36179,
    53.4,
    25.02
  ],
  [
    25153,
    66.36725,
    14.36285,
    53.4,
    25.21
  ],
  [
    25155,
    66.36739,
    14.36393,
    53.4,
    25.41
  ],
  [
    25157,
    66.36753,
    14.36502,
    53.5,
    25.61
  ],
  [
    25159,
    66.36768,
    14.36611,
    53.5,
    25.81
  ],
  [
    25161,
    66.36782,
    14.36722,
    53.5,
    26.02
  ],
  [
    25163,
    66.36797,
    14.36833,
    53.5,
    26.23
  ],
  [
    25165,
    66.36812,
    14.36945,
    53.5,
    26.46
  ],
  [
    25167,
    66.36827,
    14.37058,
    53.5,
    26.68
  ],
  [
    25169,
    66.36842,
    14.37172,
    53.6,
    26.91
  ],
  [
    25171,
    66.36859,
    14.37286,
    53.6,
    27.11
  ],
  [
    25173,
    66.36875,
    14.374,
    53.6,
    27.31
  ],
  [
    25175,
    66.36893,
    14.37515,
    53.7,
    27.53
  ],
  [
    25177,
    66.3691,
    14.37631,
    53.9,
    27.72
  ],
  [
    25179,
    66.36929,
    14.37747,
    54.1,
    27.89
  ],
  [
    25181,
    66.36947,
    14.37863,
    54.3,
    28.05
  ],
  [
    25184,
    66.36976,
    14.38038,
    54.6,
    28.18
  ],
  [
    25186,
    66.36995,
    14.38155,
    55.1,
    28.26
  ],
  [
    25188,
    66.37015,
    14.38272,
    55.4,
    28.26
  ],
  [
    25190,
    66.37038,
    14.38386,
    55.6,
    28.26
  ],
  [
    25192,
    66.37063,
    14.38495,
    55.9,
    28.31
  ],
  [
    25194,
    66.37091,
    14.386,
    56.1,
    28.29
  ],
  [
    25196,
    66.37123,
    14.387,
    56.3,
    28.3
  ],
  [
    25198,
    66.37157,
    14.38794,
    56.4,
    28.34
  ],
  [
    25200,
    66.37193,
    14.38883,
    56.3,
    28.38
  ],
  [
    25202,
    66.3723,
    14.3897,
    56.3,
    28.48
  ],
  [
    25204,
    66.37268,
    14.39057,
    56.2,
    28.55
  ],
  [
    25206,
    66.37306,
    14.39143,
    56.1,
    28.57
  ],
  [
    25208,
    66.37344,
    14.39229,
    56.1,
    28.59
  ],
  [
    25210,
    66.37382,
    14.39314,
    56.1,
    28.59
  ],
  [
    25212,
    66.3742,
    14.39399,
    56.1,
    28.6
  ],
  [
    25214,
    66.37459,
    14.39482,
    56.2,
    28.57
  ],
  [
    25216,
    66.375,
    14.39561,
    56.4,
    28.52
  ],
  [
    25218,
    66.37542,
    14.39631,
    56.6,
    28.45
  ],
  [
    25220,
    66.37586,
    14.39695,
    56.8,
    28.4
  ],
  [
    25222,
    66.37632,
    14.3975,
    57,
    28.34
  ],
  [
    25224,
    66.37679,
    14.39798,
    57.1,
    28.31
  ],
  [
    25226,
    66.37727,
    14.39842,
    57.2,
    28.25
  ],
  [
    25228,
    66.37775,
    14.39886,
    57.5,
    28.23
  ],
  [
    25230,
    66.37822,
    14.39928,
    57.7,
    28.22
  ],
  [
    25232,
    66.37871,
    14.39967,
    57.8,
    28.22
  ],
  [
    25234,
    66.3792,
    14.39998,
    57.9,
    28.19
  ],
  [
    25236,
    66.3797,
    14.40019,
    58,
    28.2
  ],
  [
    25238,
    66.3802,
    14.40029,
    58.1,
    28.18
  ],
  [
    25240,
    66.38071,
    14.4003,
    58.4,
    28.2
  ],
  [
    25242,
    66.38121,
    14.4002,
    58.4,
    28.16
  ],
  [
    25244,
    66.38172,
    14.40005,
    58.4,
    28.16
  ],
  [
    25246,
    66.38222,
    14.39987,
    58.3,
    28.13
  ],
  [
    25248,
    66.38272,
    14.39969,
    58.3,
    28.09
  ],
  [
    25251,
    66.38347,
    14.39942,
    58.3,
    28.07
  ],
  [
    25253,
    66.38397,
    14.39925,
    58.7,
    28.07
  ],
  [
    25255,
    66.38448,
    14.39912,
    59,
    28.07
  ],
  [
    25257,
    66.38498,
    14.39902,
    59.2,
    28.2
  ],
  [
    25259,
    66.38548,
    14.39893,
    59.5,
    28.2
  ],
  [
    25320,
    66.40037,
    14.40713,
    74.8,
    28.2
  ],
  [
    25322,
    66.40042,
    14.40814,
    61.9,
    27.58
  ],
  [
    25324,
    66.4007,
    14.40914,
    60.4,
    27.52
  ],
  [
    25326,
    66.40091,
    14.41027,
    62.9,
    27.47
  ],
  [
    25328,
    66.40109,
    14.41141,
    63.7,
    27.38
  ],
  [
    25330,
    66.40122,
    14.41259,
    64.5,
    27.35
  ],
  [
    25332,
    66.40129,
    14.41379,
    65,
    27.23
  ],
  [
    25334,
    66.40132,
    14.41501,
    65.6,
    27.15
  ],
  [
    25336,
    66.4013,
    14.41622,
    66.1,
    27.12
  ],
  [
    25338,
    66.40123,
    14.41742,
    66.6,
    27.13
  ],
  [
    25340,
    66.40112,
    14.41861,
    67.1,
    27.12
  ],
  [
    25342,
    66.401,
    14.41978,
    67.6,
    27.17
  ],
  [
    25344,
    66.40087,
    14.42096,
    68.2,
    27.22
  ],
  [
    25346,
    66.40075,
    14.42214,
    69.1,
    27.31
  ],
  [
    25348,
    66.40062,
    14.42332,
    69.1,
    27.39
  ],
  [
    25350,
    66.40048,
    14.4245,
    68.6,
    27.47
  ],
  [
    25352,
    66.40034,
    14.42568,
    69,
    27.55
  ],
  [
    25354,
    66.40018,
    14.42684,
    68.7,
    27.62
  ],
  [
    25356,
    66.39998,
    14.42798,
    68.3,
    27.68
  ],
  [
    25358,
    66.39975,
    14.42909,
    67.9,
    27.72
  ],
  [
    25360,
    66.39953,
    14.4302,
    67.2,
    27.78
  ],
  [
    25362,
    66.39932,
    14.43132,
    66.6,
    27.81
  ],
  [
    25364,
    66.39914,
    14.43249,
    66.6,
    27.83
  ],
  [
    25366,
    66.39902,
    14.4337,
    65.9,
    27.82
  ],
  [
    25369,
    66.39895,
    14.43555,
    66.9,
    27.85
  ],
  [
    25371,
    66.39896,
    14.4368,
    67.3,
    27.86
  ],
  [
    25373,
    66.39899,
    14.43805,
    67.7,
    27.86
  ],
  [
    25375,
    66.39901,
    14.43929,
    68.1,
    27.86
  ],
  [
    25376,
    66.39903,
    14.43991,
    68.2,
    27.84
  ],
  [
    25381,
    66.39925,
    14.44302,
    68.7,
    28.1
  ],
  [
    25383,
    66.39938,
    14.44427,
    68.6,
    28.1
  ],
  [
    25385,
    66.39955,
    14.44545,
    68.8,
    28.1
  ],
  [
    25387,
    66.39972,
    14.44664,
    68.9,
    28.19
  ],
  [
    25389,
    66.39989,
    14.44782,
    69.2,
    28.25
  ],
  [
    25391,
    66.40006,
    14.44901,
    69.4,
    28.33
  ],
  [
    25393,
    66.40024,
    14.45021,
    69.5,
    28.38
  ],
  [
    25395,
    66.40041,
    14.4514,
    69.6,
    28.37
  ],
  [
    25397,
    66.40058,
    14.45259,
    69.6,
    28.23
  ],
  [
    25399,
    66.40076,
    14.45378,
    69.6,
    28.23
  ],
  [
    25401,
    66.40093,
    14.45497,
    69.6,
    28.23
  ],
  [
    25408,
    66.40171,
    14.45884,
    68.5,
    27.8
  ],
  [
    25410,
    66.40204,
    14.45978,
    68.5,
    27.71
  ],
  [
    25412,
    66.40241,
    14.4606,
    67.9,
    27.53
  ],
  [
    25414,
    66.40281,
    14.46134,
    67.6,
    27.36
  ],
  [
    25416,
    66.40322,
    14.46202,
    67.5,
    27.16
  ],
  [
    25418,
    66.40362,
    14.46269,
    67.7,
    26.94
  ],
  [
    25420,
    66.40401,
    14.46341,
    67.7,
    26.88
  ],
  [
    25422,
    66.40435,
    14.46423,
    67.8,
    26.56
  ],
  [
    25424,
    66.40465,
    14.46516,
    67.9,
    26.32
  ],
  [
    25426,
    66.40489,
    14.46617,
    67.8,
    26.1
  ],
  [
    25428,
    66.40507,
    14.46724,
    67.6,
    25.92
  ],
  [
    25430,
    66.40519,
    14.46835,
    67.4,
    25.86
  ],
  [
    25432,
    66.40526,
    14.4695,
    67.3,
    25.92
  ],
  [
    25434,
    66.40526,
    14.47066,
    67.4,
    25.92
  ],
  [
    25436,
    66.40521,
    14.47181,
    67.6,
    25.98
  ],
  [
    25438,
    66.4051,
    14.47295,
    67.9,
    26
  ],
  [
    25440,
    66.40497,
    14.47407,
    68.6,
    26.05
  ],
  [
    25442,
    66.40482,
    14.47518,
    69.4,
    26.07
  ],
  [
    25444,
    66.40466,
    14.47628,
    69.4,
    26.06
  ],
  [
    25446,
    66.4045,
    14.47738,
    69.4,
    26.07
  ],
  [
    25448,
    66.40434,
    14.47848,
    69.1,
    26.09
  ],
  [
    25450,
    66.4042,
    14.4796,
    69,
    26.09
  ],
  [
    25452,
    66.40411,
    14.48074,
    69.1,
    26.09
  ],
  [
    25454,
    66.40406,
    14.4819,
    69.5,
    26.05
  ],
  [
    25456,
    66.40407,
    14.48307,
    69.9,
    25.97
  ],
  [
    25458,
    66.40413,
    14.48422,
    70.3,
    25.87
  ],
  [
    25460,
    66.40422,
    14.48535,
    70.5,
    25.79
  ],
  [
    25462,
    66.40433,
    14.48647,
    71.4,
    25.7
  ],
  [
    25464,
    66.40443,
    14.48759,
    71.8,
    25.6
  ],
  [
    25467,
    66.40459,
    14.48925,
    72.5,
    25.51
  ],
  [
    25469,
    66.4047,
    14.49036,
    73,
    25.46
  ],
  [
    25471,
    66.40482,
    14.49146,
    73.6,
    25.34
  ],
  [
    25473,
    66.40495,
    14.49255,
    74.2,
    25.34
  ],
  [
    25475,
    66.40508,
    14.49362,
    74.7,
    25.34
  ],
  [
    25477,
    66.40521,
    14.4947,
    75.3,
    25.13
  ],
  [
    25479,
    66.40534,
    14.49578,
    75.7,
    25.12
  ],
  [
    25481,
    66.40544,
    14.49688,
    76.2,
    25.15
  ],
  [
    25483,
    66.40549,
    14.49799,
    76.6,
    25.13
  ],
  [
    25485,
    66.40549,
    14.49912,
    77,
    25.14
  ],
  [
    25487,
    66.40544,
    14.50024,
    77.5,
    25.19
  ],
  [
    25489,
    66.40534,
    14.50134,
    77.8,
    25.3
  ],
  [
    25491,
    66.40519,
    14.50241,
    77.9,
    25.41
  ],
  [
    25493,
    66.40502,
    14.50348,
    77.9,
    25.57
  ],
  [
    25495,
    66.40483,
    14.50453,
    77.8,
    25.73
  ],
  [
    25497,
    66.40462,
    14.50556,
    77.9,
    25.81
  ],
  [
    25499,
    66.40438,
    14.50655,
    78.6,
    25.84
  ],
  [
    25501,
    66.4041,
    14.50747,
    79.3,
    25.79
  ],
  [
    25503,
    66.40377,
    14.50828,
    79.7,
    25.79
  ],
  [
    25505,
    66.40339,
    14.50897,
    78.5,
    25.85
  ],
  [
    25507,
    66.40298,
    14.50954,
    78.1,
    25.63
  ],
  [
    25509,
    66.40257,
    14.51006,
    78.3,
    25.77
  ],
  [
    25511,
    66.40216,
    14.51057,
    78.3,
    25.77
  ],
  [
    25521,
    66.3997,
    14.5106,
    76.9,
    25.74
  ],
  [
    25523,
    66.39927,
    14.51062,
    76.9,
    25.78
  ],
  [
    25525,
    66.39882,
    14.51101,
    76.3,
    25.74
  ],
  [
    25527,
    66.3984,
    14.51153,
    75.7,
    25.78
  ],
  [
    25529,
    66.39801,
    14.51213,
    75.4,
    25.76
  ],
  [
    25531,
    66.39762,
    14.51276,
    75.4,
    25.74
  ],
  [
    25533,
    66.39723,
    14.51338,
    75.4,
    25.72
  ],
  [
    25535,
    66.39684,
    14.514,
    75.6,
    25.69
  ],
  [
    25537,
    66.39646,
    14.51463,
    75.7,
    25.65
  ],
  [
    25539,
    66.39607,
    14.51525,
    75.9,
    25.58
  ],
  [
    25541,
    66.39569,
    14.51587,
    76,
    25.59
  ],
  [
    25543,
    66.3953,
    14.51649,
    76.1,
    25.57
  ],
  [
    25545,
    66.39492,
    14.51711,
    76.2,
    25.56
  ],
  [
    25547,
    66.39454,
    14.51777,
    76.1,
    25.52
  ],
  [
    25549,
    66.3942,
    14.51852,
    75.9,
    25.48
  ],
  [
    25551,
    66.3939,
    14.51938,
    75.8,
    25.41
  ],
  [
    25553,
    66.39364,
    14.52032,
    75.4,
    25.4
  ],
  [
    25555,
    66.39342,
    14.52131,
    75.5,
    25.42
  ],
  [
    25557,
    66.3932,
    14.52231,
    75.9,
    25.44
  ],
  [
    25559,
    66.39298,
    14.5233,
    76.2,
    25.52
  ],
  [
    25561,
    66.39271,
    14.52424,
    76.3,
    25.53
  ],
  [
    25563,
    66.3924,
    14.52508,
    75.9,
    25.59
  ],
  [
    25566,
    66.39187,
    14.52617,
    75.1,
    25.64
  ],
  [
    25568,
    66.39147,
    14.52675,
    74.7,
    25.66
  ],
  [
    25570,
    66.39105,
    14.52723,
    74.3,
    25.66
  ],
  [
    25572,
    66.39062,
    14.52768,
    73.8,
    25.71
  ],
  [
    25574,
    66.3902,
    14.52813,
    73.3,
    25.79
  ],
  [
    25576,
    66.38977,
    14.5286,
    72.9,
    25.88
  ],
  [
    25578,
    66.38935,
    14.5291,
    72.6,
    26
  ],
  [
    25580,
    66.38893,
    14.52963,
    72.5,
    26.03
  ],
  [
    25582,
    66.38852,
    14.53019,
    72.4,
    26.05
  ],
  [
    25584,
    66.38812,
    14.53078,
    72.3,
    26.08
  ],
  [
    25586,
    66.38772,
    14.5314,
    72.3,
    26.08
  ],
  [
    25588,
    66.38733,
    14.53203,
    72.3,
    26.03
  ],
  [
    25590,
    66.38695,
    14.5327,
    72.5,
    26.01
  ],
  [
    25592,
    66.38657,
    14.53339,
    72.6,
    25.88
  ],
  [
    25594,
    66.38621,
    14.53411,
    72.6,
    25.8
  ],
  [
    25596,
    66.38586,
    14.53487,
    72.2,
    25.76
  ],
  [
    25598,
    66.38552,
    14.53566,
    72.2,
    25.68
  ],
  [
    25600,
    66.3852,
    14.53648,
    72.3,
    25.57
  ],
  [
    25602,
    66.3849,
    14.53733,
    72.3,
    25.46
  ],
  [
    25604,
    66.3846,
    14.53819,
    72.3,
    25.35
  ],
  [
    25606,
    66.38431,
    14.53907,
    72.4,
    25.27
  ],
  [
    25608,
    66.38404,
    14.53997,
    72.5,
    25.19
  ],
  [
    25610,
    66.3838,
    14.54091,
    72.6,
    25.14
  ],
  [
    25612,
    66.3836,
    14.54193,
    72.7,
    25.1
  ],
  [
    25614,
    66.38346,
    14.54299,
    72.8,
    25.08
  ],
  [
    25616,
    66.38337,
    14.54409,
    73.1,
    25.09
  ],
  [
    25618,
    66.38331,
    14.54521,
    73.3,
    25.1
  ],
  [
    25620,
    66.38325,
    14.54632,
    73.6,
    25.15
  ],
  [
    25622,
    66.38319,
    14.54744,
    74.1,
    25.21
  ],
  [
    25624,
    66.38309,
    14.54854,
    74.4,
    25.3
  ],
  [
    25626,
    66.38295,
    14.54961,
    75.2,
    25.33
  ],
  [
    25628,
    66.38274,
    14.55063,
    75.5,
    25.38
  ],
  [
    25631,
    66.38235,
    14.55202,
    75.7,
    25.4
  ],
  [
    25633,
    66.38203,
    14.55283,
    75.4,
    25.54
  ],
  [
    25635,
    66.38166,
    14.55354,
    74.8,
    25.54
  ],
  [
    25637,
    66.38126,
    14.55412,
    74.3,
    25.76
  ],
  [
    25639,
    66.38084,
    14.5546,
    73.5,
    25.76
  ],
  [
    25641,
    66.3804,
    14.55504,
    72.8,
    25.93
  ],
  [
    25643,
    66.37997,
    14.5555,
    72.4,
    25.95
  ],
  [
    25645,
    66.37956,
    14.55606,
    72.4,
    25.92
  ],
  [
    25647,
    66.37919,
    14.55675,
    72.5,
    25.79
  ],
  [
    25649,
    66.37886,
    14.55754,
    72.5,
    25.71
  ],
  [
    25651,
    66.37857,
    14.55844,
    72.6,
    25.63
  ],
  [
    25653,
    66.37832,
    14.5594,
    72.6,
    25.6
  ],
  [
    25655,
    66.3781,
    14.5604,
    72.7,
    25.59
  ],
  [
    25657,
    66.37787,
    14.5614,
    72.7,
    25.58
  ],
  [
    25659,
    66.37765,
    14.5624,
    72.8,
    25.56
  ],
  [
    25661,
    66.37742,
    14.56339,
    72.9,
    25.56
  ],
  [
    25663,
    66.3772,
    14.56439,
    73,
    25.55
  ],
  [
    25665,
    66.37697,
    14.56539,
    73.1,
    25.54
  ],
  [
    25667,
    66.37675,
    14.56638,
    73.1,
    25.54
  ],
  [
    25669,
    66.37653,
    14.56738,
    73,
    25.55
  ],
  [
    25671,
    66.3763,
    14.56838,
    73,
    25.56
  ],
  [
    25673,
    66.37607,
    14.56937,
    72.9,
    25.57
  ],
  [
    25675,
    66.37583,
    14.57033,
    72.8,
    25.63
  ],
  [
    25677,
    66.37553,
    14.57121,
    73.7,
    25.61
  ],
  [
    25679,
    66.37521,
    14.57202,
    73.7,
    25.62
  ],
  [
    25681,
    66.37487,
    14.5728,
    73.7,
    25.63
  ],
  [
    25683,
    66.37454,
    14.57358,
    73.6,
    25.64
  ],
  [
    25685,
    66.3742,
    14.57437,
    73.6,
    25.64
  ],
  [
    25687,
    66.37387,
    14.57515,
    73.5,
    25.64
  ],
  [
    25689,
    66.37353,
    14.57594,
    73.4,
    25.6
  ],
  [
    25691,
    66.37321,
    14.57676,
    73.4,
    25.57
  ],
  [
    25693,
    66.37292,
    14.57763,
    73.7,
    25.5
  ],
  [
    25695,
    66.37265,
    14.57856,
    73.8,
    25.43
  ],
  [
    25698,
    66.37232,
    14.58004,
    74.2,
    25.38
  ],
  [
    25700,
    66.37214,
    14.58108,
    74.8,
    25.35
  ],
  [
    25702,
    66.37199,
    14.58214,
    75.5,
    25.34
  ],
  [
    25704,
    66.37187,
    14.58324,
    75.9,
    25.34
  ],
  [
    25706,
    66.37179,
    14.58435,
    76.9,
    25.32
  ],
  [
    25708,
    66.37175,
    14.58548,
    77.9,
    25.26
  ],
  [
    25710,
    66.37173,
    14.58661,
    79,
    25.24
  ],
  [
    25712,
    66.37176,
    14.58774,
    80.1,
    25.15
  ],
  [
    25714,
    66.37182,
    14.58886,
    81.1,
    25.07
  ],
  [
    25716,
    66.37194,
    14.58994,
    82.1,
    24.98
  ],
  [
    25718,
    66.37211,
    14.59098,
    83.1,
    24.95
  ],
  [
    25720,
    66.37232,
    14.59197,
    84.1,
    24.91
  ],
  [
    25722,
    66.37258,
    14.59289,
    85.3,
    24.88
  ],
  [
    25724,
    66.37284,
    14.5938,
    86.2,
    24.83
  ],
  [
    25726,
    66.3731,
    14.59471,
    87.1,
    24.82
  ],
  [
    25728,
    66.37335,
    14.59563,
    87.9,
    24.86
  ],
  [
    25730,
    66.37359,
    14.59657,
    88.6,
    24.96
  ],
  [
    25732,
    66.37382,
    14.59754,
    89.3,
    25.03
  ],
  [
    25734,
    66.37403,
    14.59854,
    89.9,
    25.15
  ],
  [
    25736,
    66.37422,
    14.59955,
    90.4,
    25.23
  ],
  [
    25738,
    66.3744,
    14.60059,
    90.9,
    25.35
  ],
  [
    25740,
    66.37457,
    14.60165,
    91.2,
    25.5
  ],
  [
    25742,
    66.37473,
    14.60273,
    91.7,
    25.63
  ],
  [
    25744,
    66.37487,
    14.60383,
    92.2,
    25.74
  ],
  [
    25746,
    66.375,
    14.60494,
    92.5,
    25.92
  ],
  [
    25748,
    66.37511,
    14.60606,
    92.7,
    26
  ],
  [
    25750,
    66.37522,
    14.60719,
    93,
    26.13
  ],
  [
    25752,
    66.37533,
    14.60833,
    93.2,
    26.25
  ],
  [
    25754,
    66.37545,
    14.60947,
    93.6,
    26.38
  ],
  [
    25756,
    66.3756,
    14.6106,
    93.8,
    26.51
  ],
  [
    25758,
    66.37577,
    14.61171,
    93.9,
    26.61
  ],
  [
    25760,
    66.37596,
    14.6128,
    93.9,
    26.73
  ],
  [
    25762,
    66.37618,
    14.61387,
    94.2,
    26.88
  ],
  [
    25765,
    66.37655,
    14.61542,
    94.5,
    27
  ],
  [
    25767,
    66.37682,
    14.61642,
    94.6,
    27.13
  ],
  [
    25769,
    66.37711,
    14.61741,
    94.7,
    27.13
  ],
  [
    25771,
    66.3774,
    14.6184,
    95,
    27.38
  ],
  [
    25773,
    66.3777,
    14.61938,
    95.2,
    27.38
  ],
  [
    25775,
    66.378,
    14.62035,
    95.4,
    27.57
  ],
  [
    25777,
    66.37832,
    14.62131,
    95.6,
    27.69
  ],
  [
    25779,
    66.37863,
    14.62227,
    95.7,
    27.81
  ],
  [
    25781,
    66.37896,
    14.62322,
    95.8,
    27.9
  ],
  [
    25783,
    66.37929,
    14.62416,
    95.8,
    27.99
  ],
  [
    25785,
    66.37963,
    14.62509,
    95.8,
    28.04
  ],
  [
    25787,
    66.37997,
    14.62601,
    95.9,
    28.21
  ],
  [
    25789,
    66.38032,
    14.62693,
    95.8,
    28.22
  ],
  [
    25791,
    66.38067,
    14.62783,
    95.7,
    28.1
  ],
  [
    25793,
    66.38103,
    14.62872,
    95.7,
    27.95
  ],
  [
    25795,
    66.38138,
    14.6296,
    95.8,
    27.82
  ],
  [
    25797,
    66.38174,
    14.63047,
    95.9,
    27.66
  ],
  [
    25799,
    66.38209,
    14.63134,
    96.1,
    27.49
  ],
  [
    25801,
    66.38243,
    14.63221,
    96.3,
    27.31
  ],
  [
    25803,
    66.38278,
    14.63307,
    96.5,
    27.11
  ],
  [
    25805,
    66.38312,
    14.63392,
    96.9,
    26.9
  ],
  [
    25807,
    66.38346,
    14.63476,
    97.2,
    26.68
  ],
  [
    25809,
    66.3838,
    14.6356,
    97.6,
    26.46
  ],
  [
    25811,
    66.38414,
    14.63642,
    98.1,
    26.21
  ],
  [
    25813,
    66.38449,
    14.6372,
    98.4,
    25.96
  ],
  [
    25815,
    66.38484,
    14.63796,
    98.7,
    25.71
  ],
  [
    25817,
    66.38519,
    14.63869,
    99.1,
    25.5
  ],
  [
    25819,
    66.38554,
    14.63941,
    99.6,
    25.28
  ],
  [
    25821,
    66.38589,
    14.64013,
    100,
    25.11
  ],
  [
    25823,
    66.38623,
    14.64086,
    100.2,
    24.96
  ],
  [
    25825,
    66.38656,
    14.64161,
    100.2,
    24.93
  ],
  [
    25827,
    66.38688,
    14.64237,
    100.8,
    24.93
  ],
  [
    25844,
    66.38878,
    14.65026,
    106,
    24.46
  ],
  [
    25846,
    66.38902,
    14.65128,
    107.4,
    24.65
  ],
  [
    25848,
    66.38925,
    14.65223,
    107.6,
    24.65
  ],
  [
    25850,
    66.38948,
    14.65316,
    107.9,
    24.65
  ],
  [
    25852,
    66.38974,
    14.65406,
    108.1,
    24.57
  ],
  [
    25854,
    66.39003,
    14.65489,
    108.2,
    24.5
  ],
  [
    25856,
    66.39034,
    14.65564,
    108.5,
    24.46
  ],
  [
    25858,
    66.39069,
    14.65632,
    108.4,
    24.48
  ],
  [
    25860,
    66.39105,
    14.65692,
    108.5,
    24.53
  ],
  [
    25862,
    66.39144,
    14.65746,
    108.5,
    24.6
  ],
  [
    25864,
    66.39183,
    14.65798,
    108.6,
    24.66
  ],
  [
    25866,
    66.39221,
    14.65852,
    108.9,
    24.73
  ],
  [
    25868,
    66.39259,
    14.65912,
    109.3,
    24.8
  ],
  [
    25870,
    66.39293,
    14.65982,
    109.7,
    24.8
  ],
  [
    25872,
    66.39324,
    14.66063,
    109.9,
    24.84
  ],
  [
    25874,
    66.39351,
    14.6615,
    110.2,
    24.89
  ],
  [
    25876,
    66.39376,
    14.66244,
    110.5,
    24.91
  ],
  [
    25878,
    66.39397,
    14.66342,
    110.9,
    24.98
  ],
  [
    25880,
    66.39416,
    14.66444,
    111.4,
    25.03
  ],
  [
    25882,
    66.39431,
    14.66549,
    112,
    25.05
  ],
  [
    25884,
    66.39443,
    14.66658,
    112.6,
    25.11
  ],
  [
    25886,
    66.39452,
    14.66769,
    113.1,
    25.14
  ],
  [
    25888,
    66.39457,
    14.66881,
    113.1,
    25.15
  ],
  [
    25890,
    66.39459,
    14.66994,
    115.8,
    25.18
  ],
  [
    25892,
    66.39457,
    14.67107,
    116.6,
    25.2
  ],
  [
    25895,
    66.3945,
    14.67276,
    117.9,
    25.25
  ],
  [
    25897,
    66.39444,
    14.67388,
    118.7,
    25.24
  ],
  [
    25899,
    66.39437,
    14.675,
    119.5,
    25.24
  ],
  [
    25901,
    66.39431,
    14.67612,
    120.2,
    25.2
  ],
  [
    25903,
    66.39425,
    14.67723,
    121.1,
    25.15
  ],
  [
    25905,
    66.39419,
    14.67835,
    122,
    25.13
  ],
  [
    25907,
    66.39413,
    14.67946,
    122.6,
    25.09
  ],
  [
    25909,
    66.39407,
    14.68058,
    123.6,
    24.98
  ],
  [
    25911,
    66.39404,
    14.68169,
    124.5,
    24.79
  ],
  [
    25913,
    66.39406,
    14.68279,
    125.5,
    24.59
  ],
  [
    25915,
    66.39413,
    14.68387,
    126.6,
    24.54
  ],
  [
    25917,
    66.39425,
    14.68491,
    127.4,
    24.03
  ],
  [
    25919,
    66.39441,
    14.6859,
    127.7,
    23.77
  ],
  [
    25921,
    66.39461,
    14.68683,
    128.3,
    23.53
  ],
  [
    25923,
    66.39484,
    14.68771,
    128.9,
    23.27
  ],
  [
    25925,
    66.39511,
    14.6885,
    129.5,
    23
  ],
  [
    25927,
    66.39539,
    14.68922,
    130,
    22.42
  ],
  [
    25929,
    66.39569,
    14.68987,
    130.3,
    21.51
  ],
  [
    25931,
    66.39598,
    14.69049,
    130.6,
    20.6
  ],
  [
    25933,
    66.39625,
    14.69108,
    130.9,
    19.72
  ],
  [
    25935,
    66.39649,
    14.69169,
    131.2,
    18.84
  ],
  [
    25937,
    66.3967,
    14.69232,
    131.4,
    17.92
  ],
  [
    25939,
    66.39688,
    14.69295,
    131.5,
    17.02
  ],
  [
    25941,
    66.39705,
    14.69357,
    131.8,
    16.33
  ],
  [
    25943,
    66.3972,
    14.69418,
    132,
    15.88
  ],
  [
    25945,
    66.39735,
    14.69477,
    132.3,
    15.56
  ],
  [
    25947,
    66.3975,
    14.69534,
    132.6,
    15.29
  ],
  [
    25949,
    66.39766,
    14.69589,
    132.8,
    15.02
  ],
  [
    25951,
    66.39783,
    14.6964,
    133.1,
    14.37
  ],
  [
    25953,
    66.398,
    14.69685,
    133.1,
    13.47
  ],
  [
    25955,
    66.39817,
    14.69724,
    133.1,
    12.59
  ],
  [
    25957,
    66.39834,
    14.69759,
    133.3,
    11.7
  ],
  [
    25960,
    66.39858,
    14.69804,
    133.4,
    11.1
  ],
  [
    25962,
    66.39873,
    14.69832,
    133.4,
    10.69
  ],
  [
    25964,
    66.39888,
    14.69858,
    133.4,
    10.69
  ],
  [
    25966,
    66.39903,
    14.69885,
    133.5,
    10.23
  ],
  [
    25968,
    66.39918,
    14.69911,
    133.6,
    10.23
  ],
  [
    25970,
    66.39933,
    14.69938,
    133.7,
    10.24
  ],
  [
    25972,
    66.39948,
    14.69964,
    133.9,
    10.3
  ],
  [
    25974,
    66.39963,
    14.69991,
    134,
    10.4
  ],
  [
    25976,
    66.39978,
    14.70018,
    134.1,
    10.51
  ],
  [
    25978,
    66.39993,
    14.70046,
    134.3,
    10.66
  ],
  [
    25980,
    66.40009,
    14.70073,
    134.5,
    10.75
  ],
  [
    25982,
    66.40025,
    14.70102,
    134.6,
    11.05
  ],
  [
    25984,
    66.40041,
    14.70131,
    134.7,
    11.3
  ],
  [
    25986,
    66.40058,
    14.70161,
    134.9,
    11.66
  ],
  [
    25988,
    66.40075,
    14.70192,
    135.1,
    12.03
  ],
  [
    25990,
    66.40093,
    14.70223,
    135.2,
    12.43
  ],
  [
    25992,
    66.40111,
    14.70257,
    135.4,
    12.91
  ],
  [
    25994,
    66.4013,
    14.70292,
    135.5,
    13.42
  ],
  [
    25996,
    66.40149,
    14.7033,
    135.6,
    13.88
  ],
  [
    25998,
    66.40168,
    14.70371,
    135.8,
    14.36
  ],
  [
    26000,
    66.40187,
    14.70416,
    135.8,
    14.81
  ],
  [
    26002,
    66.40207,
    14.70463,
    135.9,
    15.27
  ],
  [
    26004,
    66.40225,
    14.70514,
    135.9,
    15.72
  ],
  [
    26006,
    66.40244,
    14.70568,
    135.9,
    16.15
  ],
  [
    26008,
    66.40262,
    14.70625,
    136,
    16.59
  ],
  [
    26010,
    66.4028,
    14.70686,
    136.1,
    17.04
  ],
  [
    26012,
    66.40297,
    14.7075,
    136.3,
    17.49
  ],
  [
    26014,
    66.40314,
    14.70818,
    136.4,
    17.94
  ],
  [
    26016,
    66.40331,
    14.70887,
    136.4,
    18.39
  ],
  [
    26018,
    66.40349,
    14.70959,
    136.5,
    18.83
  ],
  [
    26020,
    66.40366,
    14.71032,
    136.6,
    19.28
  ],
  [
    26022,
    66.40385,
    14.71106,
    136.9,
    19.71
  ],
  [
    26024,
    66.40403,
    14.71183,
    137,
    20.12
  ],
  [
    26027,
    66.40432,
    14.713,
    137.3,
    20.52
  ],
  [
    26029,
    66.40451,
    14.71381,
    137.5,
    20.91
  ],
  [
    26031,
    66.40471,
    14.71462,
    137.7,
    21.28
  ],
  [
    26033,
    66.40491,
    14.71545,
    137.8,
    21.28
  ],
  [
    26035,
    66.40512,
    14.7163,
    138,
    21.28
  ],
  [
    26037,
    66.40533,
    14.71716,
    138.2,
    22.39
  ],
  [
    26039,
    66.40554,
    14.71803,
    138.5,
    22.9
  ],
  [
    26041,
    66.40572,
    14.71896,
    138.8,
    23.23
  ],
  [
    26043,
    66.40587,
    14.71994,
    139.1,
    23.51
  ],
  [
    26045,
    66.40598,
    14.72097,
    139.4,
    23.64
  ],
  [
    26047,
    66.40603,
    14.72203,
    139.7,
    24
  ],
  [
    26049,
    66.40604,
    14.72311,
    140.1,
    24.21
  ],
  [
    26051,
    66.40601,
    14.7242,
    140.5,
    24.42
  ],
  [
    26053,
    66.40598,
    14.7253,
    141.1,
    24.62
  ],
  [
    26055,
    66.40596,
    14.7264,
    141.6,
    24.82
  ],
  [
    26057,
    66.40599,
    14.72752,
    142.1,
    25
  ],
  [
    26059,
    66.40606,
    14.72862,
    142.7,
    25.11
  ],
  [
    26061,
    66.4062,
    14.7297,
    143.7,
    25.24
  ],
  [
    26063,
    66.40638,
    14.73073,
    144.3,
    25.25
  ],
  [
    26065,
    66.40661,
    14.73171,
    144.8,
    25.27
  ],
  [
    26067,
    66.40687,
    14.73264,
    145.1,
    25.29
  ],
  [
    26069,
    66.40713,
    14.73356,
    145.4,
    25.27
  ],
  [
    26071,
    66.40741,
    14.73447,
    145.9,
    25.26
  ],
  [
    26073,
    66.40769,
    14.73536,
    146.4,
    25.26
  ],
  [
    26075,
    66.40797,
    14.73624,
    146.7,
    25.27
  ],
  [
    26077,
    66.40826,
    14.73712,
    147.1,
    25.26
  ],
  [
    26079,
    66.40854,
    14.738,
    147.3,
    25.27
  ],
  [
    26081,
    66.40883,
    14.73888,
    147.4,
    25.33
  ],
  [
    26083,
    66.40912,
    14.73975,
    147.6,
    25.38
  ],
  [
    26085,
    66.40943,
    14.74059,
    147.7,
    25.41
  ],
  [
    26087,
    66.40974,
    14.74141,
    147.7,
    25.31
  ],
  [
    26089,
    66.41006,
    14.74222,
    148.1,
    25.31
  ],
  [
    26092,
    66.41064,
    14.7433,
    148.1,
    25.74
  ],
  [
    26094,
    66.41103,
    14.74396,
    147.1,
    25.84
  ],
  [
    26097,
    66.41162,
    14.74486,
    146,
    25.9
  ],
  [
    26099,
    66.41203,
    14.74541,
    145.4,
    25.9
  ],
  [
    26101,
    66.41244,
    14.74595,
    144.8,
    25.87
  ],
  [
    26103,
    66.41284,
    14.74651,
    144.2,
    25.87
  ],
  [
    26105,
    66.41324,
    14.74711,
    143.8,
    25.78
  ],
  [
    26107,
    66.41361,
    14.7478,
    143.4,
    25.79
  ],
  [
    26109,
    66.41394,
    14.74861,
    143.1,
    25.68
  ],
  [
    26111,
    66.41422,
    14.74951,
    142.9,
    25.59
  ],
  [
    26113,
    66.41447,
    14.75048,
    142.7,
    25.56
  ],
  [
    26115,
    66.4147,
    14.75147,
    142.4,
    25.55
  ],
  [
    26117,
    66.41493,
    14.75246,
    142.3,
    25.64
  ],
  [
    26119,
    66.41516,
    14.75345,
    142.3,
    25.74
  ],
  [
    26121,
    66.4154,
    14.75444,
    142.2,
    25.42
  ],
  [
    26123,
    66.41563,
    14.75542,
    142.2,
    25.42
  ],
  [
    26125,
    66.41587,
    14.75639,
    142.2,
    25.42
  ],
  [
    26136,
    66.41665,
    14.76245,
    143.8,
    25.11
  ],
  [
    26138,
    66.41681,
    14.76359,
    144.8,
    25.11
  ],
  [
    26140,
    66.41699,
    14.76467,
    146.1,
    25.11
  ],
  [
    26142,
    66.4172,
    14.7657,
    147.4,
    25.72
  ],
  [
    26144,
    66.41743,
    14.7667,
    147.9,
    25.66
  ],
  [
    26146,
    66.41767,
    14.76768,
    148.5,
    25.52
  ],
  [
    26148,
    66.4179,
    14.76866,
    148.8,
    25.45
  ],
  [
    26150,
    66.41814,
    14.76963,
    149,
    25.41
  ],
  [
    26152,
    66.41841,
    14.77056,
    149.1,
    25.37
  ],
  [
    26154,
    66.41869,
    14.77144,
    149,
    25.37
  ],
  [
    26156,
    66.41898,
    14.77232,
    148.8,
    25.39
  ],
  [
    26158,
    66.41927,
    14.77319,
    148.7,
    25.45
  ],
  [
    26160,
    66.41956,
    14.77407,
    148.4,
    25.52
  ],
  [
    26162,
    66.41986,
    14.77495,
    148.1,
    25.59
  ],
  [
    26164,
    66.42017,
    14.77578,
    147.8,
    25.67
  ],
  [
    26166,
    66.42053,
    14.77652,
    147.4,
    25.75
  ],
  [
    26168,
    66.42091,
    14.77719,
    146.8,
    25.9
  ],
  [
    26170,
    66.42129,
    14.77786,
    146.2,
    26.02
  ],
  [
    26172,
    66.42165,
    14.77861,
    145.7,
    26.13
  ],
  [
    26174,
    66.42198,
    14.77945,
    145.1,
    26.21
  ],
  [
    26176,
    66.42227,
    14.78037,
    144.6,
    26.22
  ],
  [
    26178,
    66.42254,
    14.78132,
    144.2,
    26.27
  ],
  [
    26180,
    66.42282,
    14.78228,
    144.1,
    26.34
  ],
  [
    26182,
    66.42309,
    14.78324,
    143.7,
    26.42
  ],
  [
    26184,
    66.42337,
    14.78421,
    143.2,
    26.49
  ],
  [
    26186,
    66.42364,
    14.78517,
    142.8,
    26.56
  ],
  [
    26188,
    66.42392,
    14.78614,
    142.4,
    26.63
  ],
  [
    26190,
    66.42421,
    14.7871,
    142.2,
    26.68
  ],
  [
    26192,
    66.42451,
    14.78804,
    142.2,
    26.71
  ],
  [
    26194,
    66.42482,
    14.78896,
    141.2,
    26.77
  ],
  [
    26196,
    66.42514,
    14.78985,
    140.8,
    26.81
  ],
  [
    26199,
    66.42564,
    14.79115,
    140.2,
    26.83
  ],
  [
    26201,
    66.42599,
    14.79197,
    139.8,
    26.87
  ],
  [
    26203,
    66.42637,
    14.79271,
    139.5,
    26.87
  ],
  [
    26205,
    66.42679,
    14.79332,
    139.3,
    26.87
  ],
  [
    26207,
    66.42723,
    14.79379,
    139.2,
    26.8
  ],
  [
    26209,
    66.42769,
    14.79412,
    139.1,
    26.75
  ],
  [
    26211,
    66.42816,
    14.79439,
    139.1,
    26.63
  ],
  [
    26213,
    66.42863,
    14.79465,
    139.2,
    26.55
  ],
  [
    26215,
    66.42908,
    14.795,
    139.1,
    26.51
  ],
  [
    26217,
    66.42952,
    14.79546,
    139.1,
    26.3
  ],
  [
    26219,
    66.42992,
    14.79607,
    139,
    26.16
  ],
  [
    26221,
    66.43028,
    14.7968,
    138.8,
    26.05
  ],
  [
    26223,
    66.43061,
    14.79764,
    138.8,
    25.92
  ],
  [
    26225,
    66.43088,
    14.79857,
    138.8,
    25.87
  ],
  [
    26227,
    66.43112,
    14.79956,
    138.8,
    25.78
  ],
  [
    26229,
    66.43135,
    14.80056,
    139,
    25.78
  ],
  [
    26231,
    66.43158,
    14.80155,
    139.1,
    25.78
  ],
  [
    26233,
    66.43182,
    14.80254,
    139.2,
    25.76
  ],
  [
    26235,
    66.43207,
    14.80351,
    139.3,
    25.76
  ],
  [
    26237,
    66.43233,
    14.80446,
    139.3,
    25.71
  ],
  [
    26239,
    66.4326,
    14.8054,
    139.3,
    25.66
  ],
  [
    26241,
    66.43286,
    14.80633,
    139.3,
    25.58
  ],
  [
    26243,
    66.43313,
    14.80725,
    139.2,
    25.09
  ],
  [
    26245,
    66.43338,
    14.80816,
    139,
    24.57
  ],
  [
    26247,
    66.43364,
    14.80904,
    138.8,
    23.98
  ],
  [
    26249,
    66.43388,
    14.8099,
    138.5,
    23.38
  ],
  [
    26251,
    66.43412,
    14.81074,
    138.2,
    22.8
  ],
  [
    26253,
    66.43436,
    14.81156,
    137.9,
    22.15
  ],
  [
    26255,
    66.43458,
    14.81236,
    137.6,
    21.45
  ],
  [
    26257,
    66.4348,
    14.81312,
    137.6,
    20.76
  ],
  [
    26259,
    66.43501,
    14.81386,
    137.1,
    19.88
  ],
  [
    26261,
    66.43521,
    14.81457,
    136.9,
    18.86
  ],
  [
    26264,
    66.43549,
    14.81556,
    136.5,
    18.01
  ],
  [
    26266,
    66.43568,
    14.8162,
    136.3,
    17.55
  ],
  [
    26268,
    66.43585,
    14.81683,
    136,
    17.55
  ],
  [
    26270,
    66.43603,
    14.81745,
    135.8,
    17.55
  ],
  [
    26272,
    66.43621,
    14.81807,
    135.5,
    17.19
  ],
  [
    26274,
    66.4364,
    14.81869,
    135.3,
    17.21
  ],
  [
    26276,
    66.43658,
    14.81929,
    135,
    17.18
  ],
  [
    26278,
    66.43677,
    14.81989,
    134.8,
    16.65
  ],
  [
    26280,
    66.43695,
    14.82046,
    134.6,
    16.41
  ],
  [
    26282,
    66.43713,
    14.821,
    134.3,
    15.58
  ],
  [
    26284,
    66.4373,
    14.82153,
    134.1,
    15
  ],
  [
    26286,
    66.43747,
    14.82202,
    133.9,
    14.42
  ],
  [
    26288,
    66.43764,
    14.8225,
    133.7,
    13.83
  ],
  [
    26290,
    66.4378,
    14.82295,
    133.5,
    13.58
  ],
  [
    26292,
    66.43795,
    14.82339,
    133.4,
    13.09
  ],
  [
    26294,
    66.43811,
    14.82381,
    133.2,
    13.01
  ],
  [
    26296,
    66.43827,
    14.82424,
    133,
    12.98
  ],
  [
    26298,
    66.43843,
    14.82466,
    133,
    13.02
  ],
  [
    26300,
    66.43859,
    14.82507,
    132.9,
    13.09
  ],
  [
    26302,
    66.43876,
    14.82548,
    132.8,
    13.13
  ],
  [
    26304,
    66.43893,
    14.82587,
    132.6,
    13.17
  ],
  [
    26306,
    66.43911,
    14.82626,
    132.5,
    13.22
  ],
  [
    26308,
    66.43929,
    14.82662,
    132.4,
    12.98
  ],
  [
    26310,
    66.43947,
    14.82697,
    132.3,
    12.48
  ],
  [
    26312,
    66.43965,
    14.82728,
    132.3,
    12.01
  ],
  [
    26314,
    66.43983,
    14.82757,
    132.2,
    11.78
  ],
  [
    26316,
    66.44,
    14.82785,
    132.1,
    11.65
  ],
  [
    26318,
    66.44018,
    14.82813,
    132,
    11.59
  ],
  [
    26320,
    66.44036,
    14.8284,
    132,
    11.58
  ],
  [
    26322,
    66.44053,
    14.82866,
    132.1,
    11.56
  ],
  [
    26324,
    66.44071,
    14.82893,
    132.1,
    11.5
  ],
  [
    26326,
    66.44089,
    14.82919,
    132.6,
    11.48
  ],
  [
    26329,
    66.44115,
    14.82959,
    132.7,
    11.43
  ],
  [
    26331,
    66.44132,
    14.82985,
    132.6,
    11.35
  ],
  [
    26333,
    66.4415,
    14.83011,
    132.6,
    11.35
  ],
  [
    26335,
    66.44167,
    14.83037,
    132.5,
    11.35
  ],
  [
    26337,
    66.44183,
    14.83063,
    132.5,
    11.11
  ],
  [
    26339,
    66.442,
    14.83089,
    132.5,
    10.93
  ],
  [
    26341,
    66.44216,
    14.83115,
    132.4,
    10.82
  ],
  [
    26343,
    66.44232,
    14.83142,
    132.3,
    10.61
  ],
  [
    26345,
    66.44247,
    14.83169,
    132.2,
    10.54
  ],
  [
    26347,
    66.44262,
    14.83196,
    132.1,
    10.36
  ],
  [
    26349,
    66.44277,
    14.83222,
    132,
    10.23
  ],
  [
    26351,
    66.44292,
    14.83249,
    132,
    10.12
  ],
  [
    26353,
    66.44306,
    14.83278,
    131.9,
    10.01
  ],
  [
    26355,
    66.44319,
    14.83308,
    131.8,
    9.94
  ],
  [
    26357,
    66.44332,
    14.83337,
    131.7,
    9.73
  ],
  [
    26359,
    66.44345,
    14.83364,
    131.6,
    9.69
  ],
  [
    26361,
    66.44359,
    14.83389,
    131.5,
    9.76
  ],
  [
    26363,
    66.44373,
    14.83415,
    131.3,
    9.83
  ],
  [
    26365,
    66.44388,
    14.83441,
    131.3,
    9.88
  ],
  [
    26367,
    66.44402,
    14.83467,
    131.2,
    9.96
  ],
  [
    26369,
    66.44416,
    14.83493,
    131.1,
    10.06
  ],
  [
    26371,
    66.44431,
    14.8352,
    131,
    10.13
  ],
  [
    26373,
    66.44446,
    14.83546,
    131,
    10.22
  ],
  [
    26375,
    66.44461,
    14.83573,
    130.9,
    10.32
  ],
  [
    26377,
    66.44476,
    14.836,
    130.9,
    10.36
  ],
  [
    26379,
    66.44491,
    14.83628,
    130.9,
    10.34
  ],
  [
    26381,
    66.44506,
    14.83655,
    130.8,
    10.3
  ],
  [
    26383,
    66.4452,
    14.83682,
    130.9,
    10.25
  ],
  [
    26385,
    66.44535,
    14.83708,
    130.8,
    10.21
  ],
  [
    26387,
    66.4455,
    14.83735,
    130.8,
    9.96
  ],
  [
    26389,
    66.44564,
    14.8376,
    130.8,
    9.38
  ],
  [
    26391,
    66.44577,
    14.83784,
    130.7,
    8.8
  ],
  [
    26393,
    66.44589,
    14.83806,
    130.7,
    8.18
  ],
  [
    26396,
    66.44606,
    14.83836,
    130.7,
    7.47
  ],
  [
    26398,
    66.44615,
    14.83854,
    130.7,
    7.47
  ],
  [
    26400,
    66.44624,
    14.8387,
    130.7,
    7.47
  ],
  [
    26402,
    66.44632,
    14.83884,
    130.7,
    5.66
  ],
  [
    26404,
    66.44638,
    14.83895,
    130.7,
    4.62
  ],
  [
    26406,
    66.44644,
    14.83906,
    130.7,
    4.01
  ],
  [
    26408,
    66.44649,
    14.83914,
    130.8,
    3.05
  ],
  [
    26410,
    66.44653,
    14.83921,
    130.8,
    2.71
  ],
  [
    26412,
    66.44655,
    14.83926,
    130.8,
    1.44
  ],
  [
    26414,
    66.44656,
    14.83928,
    130.8,
    0.61
  ],
  [
    26416,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26418,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26420,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26422,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26424,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26426,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26428,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26430,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26432,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26434,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26436,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26438,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26440,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26442,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26444,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26446,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26448,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26450,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26452,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26454,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26456,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26458,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26460,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26463,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26465,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26467,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26469,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26471,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26473,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26475,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26477,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26479,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26481,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26483,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26485,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26487,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26489,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26491,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26493,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26495,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26497,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26499,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26501,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26503,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26505,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26507,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26509,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26511,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26513,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26515,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26517,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26519,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26521,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26524,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26526,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26528,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26530,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26532,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26534,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26536,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26538,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26540,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26542,
    66.44657,
    14.83929,
    130.9,
    0
  ],
  [
    26544,
    66.44659,
    14.83929,
    132.1,
    1.62
  ],
  [
    26546,
    66.44662,
    14.83934,
    132.1,
    2.35
  ],
  [
    26548,
    66.44666,
    14.83941,
    132.1,
    3.24
  ],
  [
    26550,
    66.44671,
    14.83951,
    132.1,
    3.68
  ],
  [
    26552,
    66.44678,
    14.83963,
    132.1,
    4.76
  ],
  [
    26554,
    66.44685,
    14.83976,
    132,
    5.4
  ],
  [
    26556,
    66.44694,
    14.83991,
    132.1,
    5.96
  ],
  [
    26558,
    66.44703,
    14.84008,
    132.2,
    6.54
  ],
  [
    26560,
    66.44712,
    14.84026,
    132.5,
    7.11
  ],
  [
    26562,
    66.44723,
    14.84046,
    132.7,
    7.67
  ],
  [
    26564,
    66.44735,
    14.84067,
    132.9,
    8.21
  ],
  [
    26566,
    66.44748,
    14.84087,
    133.2,
    8.73
  ],
  [
    26568,
    66.44762,
    14.84107,
    133.5,
    9.29
  ],
  [
    26570,
    66.44776,
    14.84129,
    133.7,
    9.79
  ],
  [
    26572,
    66.44792,
    14.84153,
    134,
    10.23
  ],
  [
    26574,
    66.44807,
    14.84181,
    134.2,
    10.38
  ],
  [
    26576,
    66.44822,
    14.84208,
    134.2,
    10.46
  ],
  [
    26578,
    66.44837,
    14.84236,
    134.8,
    10.52
  ],
  [
    26581,
    66.4486,
    14.84278,
    135.2,
    10.55
  ],
  [
    26583,
    66.44875,
    14.84307,
    135.5,
    10.57
  ],
  [
    26585,
    66.4489,
    14.84334,
    135.8,
    10.57
  ],
  [
    26587,
    66.44906,
    14.84362,
    136,
    10.6
  ],
  [
    26589,
    66.44922,
    14.84389,
    136.2,
    10.58
  ],
  [
    26591,
    66.44937,
    14.84415,
    136.4,
    10.58
  ],
  [
    26593,
    66.44954,
    14.84441,
    136.6,
    10.65
  ],
  [
    26595,
    66.4497,
    14.84466,
    136.7,
    10.72
  ],
  [
    26597,
    66.44987,
    14.8449,
    137,
    10.97
  ],
  [
    26599,
    66.45005,
    14.84514,
    137.2,
    11.18
  ],
  [
    26601,
    66.45023,
    14.84537,
    137.5,
    11.42
  ],
  [
    26603,
    66.45041,
    14.84559,
    137.8,
    11.7
  ],
  [
    26605,
    66.45061,
    14.84581,
    138.1,
    11.86
  ],
  [
    26607,
    66.45081,
    14.84603,
    138.4,
    12.3
  ],
  [
    26609,
    66.45102,
    14.84624,
    138.7,
    12.61
  ],
  [
    26611,
    66.45123,
    14.84643,
    139,
    12.91
  ],
  [
    26613,
    66.45146,
    14.84662,
    139.4,
    13.25
  ],
  [
    26615,
    66.45169,
    14.84679,
    139.9,
    13.66
  ],
  [
    26617,
    66.45193,
    14.84695,
    140.4,
    14.09
  ],
  [
    26619,
    66.45218,
    14.8471,
    140.9,
    14.53
  ],
  [
    26621,
    66.45244,
    14.84725,
    141.4,
    14.97
  ],
  [
    26623,
    66.45271,
    14.8474,
    141.8,
    15.44
  ],
  [
    26625,
    66.45298,
    14.84756,
    142.3,
    15.89
  ],
  [
    26627,
    66.45326,
    14.84772,
    142.8,
    16.28
  ],
  [
    26629,
    66.45355,
    14.8479,
    143.4,
    16.67
  ],
  [
    26631,
    66.45384,
    14.8481,
    143.9,
    17.02
  ],
  [
    26633,
    66.45414,
    14.84833,
    144.1,
    17.4
  ],
  [
    26635,
    66.45444,
    14.8486,
    144.1,
    17.7
  ],
  [
    26638,
    66.45489,
    14.84904,
    145.9,
    17.99
  ],
  [
    26640,
    66.45519,
    14.84934,
    146.4,
    18.24
  ],
  [
    26642,
    66.4555,
    14.84965,
    147,
    18.39
  ],
  [
    26644,
    66.45581,
    14.84997,
    147.6,
    18.39
  ],
  [
    26646,
    66.45613,
    14.85028,
    148.2,
    18.98
  ],
  [
    26648,
    66.45645,
    14.8506,
    148.8,
    19.2
  ],
  [
    26650,
    66.45677,
    14.85093,
    149.3,
    19.38
  ],
  [
    26652,
    66.4571,
    14.85125,
    149.9,
    19.69
  ],
  [
    26654,
    66.45743,
    14.85158,
    150.5,
    19.86
  ],
  [
    26656,
    66.45776,
    14.85191,
    151,
    20.02
  ],
  [
    26658,
    66.4581,
    14.85224,
    151.6,
    20.17
  ],
  [
    26660,
    66.45844,
    14.85256,
    152.3,
    20.25
  ],
  [
    26662,
    66.45879,
    14.85285,
    153,
    20.41
  ],
  [
    26664,
    66.45914,
    14.85311,
    153.7,
    20.52
  ],
  [
    26666,
    66.45949,
    14.85335,
    154.5,
    20.62
  ],
  [
    26668,
    66.45986,
    14.85357,
    155.8,
    20.74
  ],
  [
    26670,
    66.46022,
    14.85378,
    156.9,
    20.85
  ],
  [
    26672,
    66.46059,
    14.854,
    157.9,
    20.91
  ],
  [
    26674,
    66.46095,
    14.85422,
    158.7,
    21
  ],
  [
    26676,
    66.46132,
    14.85444,
    159.4,
    21.09
  ],
  [
    26678,
    66.46169,
    14.85466,
    160,
    21.19
  ],
  [
    26680,
    66.46207,
    14.85488,
    160.6,
    21.29
  ],
  [
    26682,
    66.46244,
    14.85511,
    161.3,
    21.39
  ],
  [
    26684,
    66.46281,
    14.85533,
    161.9,
    21.46
  ],
  [
    26686,
    66.46319,
    14.85555,
    162.5,
    21.53
  ],
  [
    26688,
    66.46357,
    14.85578,
    163.1,
    21.58
  ],
  [
    26690,
    66.46395,
    14.856,
    164,
    21.66
  ],
  [
    26692,
    66.46433,
    14.85622,
    164.6,
    21.74
  ],
  [
    26694,
    66.46471,
    14.85644,
    165.6,
    21.77
  ],
  [
    26696,
    66.46509,
    14.85663,
    166.2,
    21.85
  ],
  [
    26699,
    66.46567,
    14.85686,
    167.3,
    21.91
  ],
  [
    26701,
    66.46606,
    14.857,
    168.2,
    21.96
  ],
  [
    26703,
    66.46646,
    14.85713,
    169.4,
    21.96
  ],
  [
    26705,
    66.46685,
    14.85727,
    170.5,
    21.96
  ],
  [
    26707,
    66.46724,
    14.85744,
    171.2,
    22.08
  ],
  [
    26709,
    66.46763,
    14.85765,
    171.9,
    22.15
  ],
  [
    26711,
    66.46801,
    14.8579,
    172.6,
    22.2
  ],
  [
    26713,
    66.46839,
    14.8582,
    173.3,
    22.24
  ],
  [
    26715,
    66.46876,
    14.85855,
    174,
    22.26
  ],
  [
    26717,
    66.46913,
    14.85893,
    174.7,
    22.35
  ],
  [
    26719,
    66.4695,
    14.85936,
    175.5,
    22.36
  ],
  [
    26721,
    66.46985,
    14.85983,
    176.3,
    22.39
  ],
  [
    26723,
    66.4702,
    14.86033,
    176.8,
    22.44
  ],
  [
    26725,
    66.47055,
    14.86085,
    177.3,
    22.49
  ],
  [
    26727,
    66.47089,
    14.86136,
    177.8,
    22.52
  ],
  [
    26729,
    66.47124,
    14.86187,
    178.4,
    22.57
  ],
  [
    26731,
    66.4716,
    14.86235,
    179.1,
    22.63
  ],
  [
    26733,
    66.47196,
    14.86279,
    179.8,
    22.66
  ],
  [
    26735,
    66.47233,
    14.86322,
    180.6,
    22.69
  ],
  [
    26737,
    66.4727,
    14.86363,
    181.3,
    22.71
  ],
  [
    26739,
    66.47308,
    14.86404,
    182,
    22.78
  ],
  [
    26741,
    66.47346,
    14.86443,
    182.7,
    22.81
  ],
  [
    26743,
    66.47384,
    14.86482,
    183.4,
    22.84
  ],
  [
    26745,
    66.47422,
    14.86521,
    184.1,
    22.87
  ],
  [
    26747,
    66.4746,
    14.86559,
    184.9,
    22.91
  ],
  [
    26749,
    66.47498,
    14.86597,
    185.7,
    22.96
  ],
  [
    26751,
    66.47536,
    14.86637,
    185.7,
    22.97
  ],
  [
    26753,
    66.47574,
    14.86678,
    187.8,
    23.03
  ],
  [
    26756,
    66.4763,
    14.86743,
    189.3,
    23.07
  ],
  [
    26758,
    66.47667,
    14.86789,
    190.1,
    23.04
  ],
  [
    26760,
    66.47704,
    14.86836,
    190.8,
    23.04
  ],
  [
    26762,
    66.47741,
    14.86885,
    191.5,
    23.13
  ],
  [
    26764,
    66.47777,
    14.86936,
    192.2,
    23.13
  ],
  [
    26766,
    66.47813,
    14.86989,
    192.8,
    23.22
  ],
  [
    26768,
    66.47848,
    14.87042,
    193.3,
    23.26
  ],
  [
    26770,
    66.47884,
    14.87095,
    193.9,
    23.28
  ],
  [
    26772,
    66.47921,
    14.87147,
    194.4,
    23.31
  ],
  [
    26774,
    66.47958,
    14.87193,
    195,
    23.32
  ],
  [
    26776,
    66.47997,
    14.8723,
    195.8,
    23.37
  ],
  [
    26778,
    66.48038,
    14.87258,
    196.7,
    23.37
  ],
  [
    26780,
    66.48079,
    14.87277,
    197.6,
    23.41
  ],
  [
    26782,
    66.48121,
    14.87291,
    198.5,
    23.4
  ],
  [
    26784,
    66.48162,
    14.87303,
    199.4,
    23.41
  ],
  [
    26786,
    66.48204,
    14.87318,
    200.8,
    23.46
  ],
  [
    26788,
    66.48245,
    14.87339,
    201.9,
    23.52
  ],
  [
    26790,
    66.48286,
    14.87369,
    203,
    23.47
  ],
  [
    26792,
    66.48325,
    14.87408,
    204,
    23.51
  ],
  [
    26794,
    66.48363,
    14.87453,
    204.5,
    23.5
  ],
  [
    26796,
    66.484,
    14.87501,
    205.1,
    23.54
  ],
  [
    26798,
    66.48438,
    14.8755,
    205.7,
    23.57
  ],
  [
    26800,
    66.48475,
    14.87601,
    206.3,
    23.61
  ],
  [
    26802,
    66.48512,
    14.87653,
    207,
    23.62
  ],
  [
    26804,
    66.48548,
    14.87707,
    207.6,
    23.68
  ],
  [
    26806,
    66.48585,
    14.87761,
    208.1,
    23.7
  ],
  [
    26808,
    66.48621,
    14.87818,
    208.6,
    23.71
  ],
  [
    26810,
    66.48656,
    14.87875,
    208.6,
    23.73
  ],
  [
    26812,
    66.48692,
    14.87933,
    210.1,
    23.76
  ],
  [
    26815,
    66.48746,
    14.88019,
    211.3,
    23.78
  ],
  [
    26817,
    66.48782,
    14.88077,
    212,
    23.81
  ],
  [
    26819,
    66.48818,
    14.88132,
    212.7,
    23.81
  ],
  [
    26821,
    66.48855,
    14.88185,
    213.5,
    23.84
  ],
  [
    26823,
    66.48893,
    14.88236,
    214.4,
    23.84
  ],
  [
    26825,
    66.48931,
    14.88284,
    215.3,
    23.86
  ],
  [
    26827,
    66.4897,
    14.88329,
    216.1,
    23.89
  ],
  [
    26829,
    66.49009,
    14.88372,
    216.8,
    23.88
  ],
  [
    26831,
    66.49049,
    14.88412,
    217.6,
    23.88
  ],
  [
    26833,
    66.49089,
    14.88449,
    218.3,
    23.91
  ],
  [
    26835,
    66.4913,
    14.88484,
    219.3,
    23.94
  ],
  [
    26837,
    66.4917,
    14.88519,
    220.4,
    23.98
  ],
  [
    26839,
    66.49211,
    14.88553,
    221.5,
    23.99
  ],
  [
    26841,
    66.49252,
    14.88587,
    222.7,
    24.02
  ],
  [
    26843,
    66.49293,
    14.88622,
    223.8,
    24.03
  ],
  [
    26845,
    66.49334,
    14.88658,
    225.1,
    24.04
  ],
  [
    26847,
    66.49374,
    14.88699,
    226.1,
    24.09
  ],
  [
    26849,
    66.49412,
    14.88749,
    227,
    24.06
  ],
  [
    26851,
    66.49448,
    14.88809,
    228,
    24.03
  ],
  [
    26853,
    66.49481,
    14.88876,
    228.9,
    24.04
  ],
  [
    26855,
    66.49512,
    14.88952,
    229.8,
    24
  ],
  [
    26857,
    66.4954,
    14.89034,
    230.5,
    24.11
  ],
  [
    26859,
    66.49565,
    14.89123,
    231.3,
    24.09
  ],
  [
    26861,
    66.49589,
    14.89213,
    232.1,
    24.14
  ],
  [
    26863,
    66.49613,
    14.89303,
    232.9,
    24.2
  ],
  [
    26865,
    66.49638,
    14.89394,
    233.7,
    24.25
  ],
  [
    26867,
    66.49662,
    14.89485,
    234.7,
    24.3
  ],
  [
    26869,
    66.49686,
    14.89576,
    234.7,
    24.33
  ],
  [
    26871,
    66.4971,
    14.89667,
    236.2,
    24.38
  ],
  [
    26873,
    66.49734,
    14.89759,
    237,
    24.42
  ],
  [
    26876,
    66.49767,
    14.89901,
    237.7,
    24.45
  ],
  [
    26878,
    66.49789,
    14.89997,
    238.1,
    24.45
  ],
  [
    26880,
    66.4981,
    14.90093,
    238.7,
    24.45
  ],
  [
    26882,
    66.49833,
    14.90187,
    239.6,
    24.55
  ],
  [
    26884,
    66.49859,
    14.90277,
    240.5,
    24.6
  ],
  [
    26886,
    66.49886,
    14.90364,
    241.2,
    24.7
  ],
  [
    26888,
    66.49914,
    14.90449,
    242,
    24.72
  ],
  [
    26890,
    66.49943,
    14.90535,
    242.5,
    24.78
  ],
  [
    26892,
    66.49971,
    14.90619,
    243.3,
    24.34
  ],
  [
    26894,
    66.5,
    14.90702,
    244.1,
    24.34
  ],
  [
    26896,
    66.50028,
    14.90784,
    244.8,
    24.34
  ],
  [
    26898,
    66.50019,
    14.90926,
    244.4,
    25.26
  ],
  [
    26900,
    66.50026,
    14.91043,
    245,
    25.36
  ],
  [
    26902,
    66.50031,
    14.91161,
    245,
    25.59
  ],
  [
    26904,
    66.50036,
    14.91279,
    245,
    25.81
  ],
  [
    26906,
    66.50042,
    14.91395,
    245,
    26
  ],
  [
    26908,
    66.50047,
    14.91512,
    245.2,
    26.17
  ],
  [
    26910,
    66.50053,
    14.91629,
    245.2,
    26.27
  ],
  [
    26912,
    66.50059,
    14.91747,
    245.4,
    26.36
  ],
  [
    26914,
    66.50063,
    14.91865,
    246,
    26.42
  ],
  [
    26916,
    66.50063,
    14.91984,
    246.6,
    26.47
  ],
  [
    26918,
    66.50059,
    14.92102,
    247.1,
    26.5
  ],
  [
    26920,
    66.50049,
    14.92219,
    247.7,
    26.53
  ],
  [
    26922,
    66.50035,
    14.92333,
    248.3,
    26.58
  ],
  [
    26924,
    66.50016,
    14.92442,
    248.7,
    26.61
  ],
  [
    26926,
    66.49992,
    14.92546,
    249.2,
    26.68
  ],
  [
    26928,
    66.49965,
    14.92644,
    249.2,
    26.71
  ],
  [
    26930,
    66.49935,
    14.92739,
    250.4,
    26.76
  ],
  [
    26932,
    66.49905,
    14.92833,
    251.1,
    26.8
  ],
  [
    26935,
    66.4986,
    14.92974,
    252.2,
    26.83
  ],
  [
    26937,
    66.4983,
    14.93068,
    252.8,
    26.88
  ],
  [
    26939,
    66.498,
    14.93163,
    253.5,
    26.88
  ],
  [
    26941,
    66.4977,
    14.93258,
    254.1,
    27.03
  ],
  [
    26943,
    66.49742,
    14.93358,
    254.6,
    27.07
  ],
  [
    26945,
    66.49719,
    14.93465,
    255.2,
    27.11
  ],
  [
    26947,
    66.497,
    14.93577,
    255.8,
    27.15
  ],
  [
    26949,
    66.49686,
    14.93694,
    256.6,
    27.19
  ],
  [
    26951,
    66.49678,
    14.93814,
    257.3,
    27.23
  ],
  [
    26953,
    66.49675,
    14.93936,
    258,
    27.27
  ],
  [
    26955,
    66.49677,
    14.94058,
    258.7,
    27.28
  ],
  [
    26957,
    66.49685,
    14.94179,
    259.2,
    27.32
  ],
  [
    26959,
    66.49698,
    14.94298,
    259.9,
    27.39
  ],
  [
    26961,
    66.49716,
    14.94412,
    260.3,
    27.41
  ],
  [
    26963,
    66.49738,
    14.94522,
    260.9,
    27.54
  ],
  [
    26965,
    66.49763,
    14.9463,
    261.4,
    27.68
  ],
  [
    26967,
    66.49787,
    14.94738,
    261.7,
    27.82
  ],
  [
    26969,
    66.49812,
    14.94847,
    262,
    27.95
  ],
  [
    26971,
    66.49838,
    14.94956,
    262.2,
    28.12
  ],
  [
    26973,
    66.49863,
    14.95066,
    262.5,
    28.29
  ],
  [
    26975,
    66.49888,
    14.95177,
    262.8,
    28.44
  ],
  [
    26977,
    66.49914,
    14.95288,
    263,
    28.56
  ],
  [
    26979,
    66.49939,
    14.954,
    263.2,
    28.64
  ],
  [
    26981,
    66.49965,
    14.95512,
    263.5,
    28.66
  ],
  [
    26983,
    66.49993,
    14.9562,
    263.7,
    28.67
  ],
  [
    26985,
    66.50025,
    14.9572,
    263.7,
    28.63
  ],
  [
    26987,
    66.5006,
    14.95815,
    264.2,
    28.61
  ],
  [
    26989,
    66.50095,
    14.9591,
    264.5,
    28.58
  ],
  [
    26992,
    66.50149,
    14.96046,
    265.1,
    28.5
  ],
  [
    26994,
    66.50189,
    14.96125,
    265.7,
    28.5
  ],
  [
    26996,
    66.50231,
    14.96197,
    266.5,
    28.28
  ],
  [
    26998,
    66.50273,
    14.96266,
    267.3,
    28.09
  ],
  [
    27000,
    66.50315,
    14.96335,
    268,
    28.09
  ],
  [
    27011,
    66.50522,
    14.96739,
    273.3,
    26.57
  ],
  [
    27013,
    66.50553,
    14.96846,
    273.3,
    26.44
  ],
  [
    27015,
    66.50574,
    14.96954,
    273.4,
    26.33
  ],
  [
    27017,
    66.50591,
    14.97066,
    273.2,
    26.23
  ],
  [
    27019,
    66.50604,
    14.97177,
    273.4,
    26.13
  ],
  [
    27021,
    66.50612,
    14.97292,
    273.5,
    26.13
  ],
  [
    27023,
    66.50614,
    14.97408,
    273.2,
    26.13
  ],
  [
    27025,
    66.50612,
    14.97526,
    273.1,
    26.2
  ],
  [
    27028,
    66.50601,
    14.977,
    273.1,
    26.27
  ],
  [
    27030,
    66.50591,
    14.97816,
    273.2,
    26.32
  ],
  [
    27032,
    66.50579,
    14.97932,
    273.3,
    26.43
  ],
  [
    27034,
    66.50566,
    14.98047,
    273.4,
    26.43
  ],
  [
    27036,
    66.50548,
    14.98159,
    273.6,
    26.88
  ],
  [
    27038,
    66.50527,
    14.98268,
    274.1,
    27.01
  ],
  [
    27040,
    66.50505,
    14.98377,
    274.5,
    27.13
  ],
  [
    27042,
    66.50483,
    14.98486,
    274.5,
    27.26
  ],
  [
    27044,
    66.5046,
    14.98595,
    274.7,
    27.35
  ],
  [
    27046,
    66.50436,
    14.98701,
    275,
    27.41
  ],
  [
    27048,
    66.50407,
    14.98801,
    275.5,
    27.42
  ],
  [
    27050,
    66.50374,
    14.98893,
    275.9,
    27.38
  ],
  [
    27052,
    66.50338,
    14.98975,
    276.3,
    27.37
  ],
  [
    27054,
    66.50298,
    14.99046,
    277.1,
    27.36
  ],
  [
    27056,
    66.50255,
    14.99106,
    278.3,
    27.29
  ],
  [
    27058,
    66.5021,
    14.99154,
    279.6,
    27.23
  ],
  [
    27060,
    66.50163,
    14.99192,
    280.9,
    27.16
  ],
  [
    27062,
    66.50116,
    14.99225,
    282.1,
    27.13
  ],
  [
    27064,
    66.50069,
    14.99257,
    283.3,
    27.09
  ],
  [
    27066,
    66.50022,
    14.99296,
    284.7,
    27.02
  ],
  [
    27068,
    66.49978,
    14.99344,
    286,
    26.93
  ],
  [
    27070,
    66.49936,
    14.99403,
    287.1,
    26.85
  ],
  [
    27072,
    66.49896,
    14.99472,
    288.2,
    26.78
  ],
  [
    27074,
    66.4986,
    14.9955,
    289.2,
    26.7
  ],
  [
    27076,
    66.49826,
    14.99635,
    290.1,
    26.67
  ],
  [
    27078,
    66.49794,
    14.99723,
    290.6,
    26.64
  ],
  [
    27080,
    66.49761,
    14.99811,
    291.9,
    26.61
  ],
  [
    27082,
    66.49729,
    14.99899,
    292.7,
    26.58
  ],
  [
    27085,
    66.49676,
    15.00021,
    293.7,
    26.56
  ],
  [
    27087,
    66.49638,
    15.00091,
    294.4,
    26.53
  ],
  [
    27089,
    66.49596,
    15.00149,
    295.2,
    26.53
  ],
  [
    27091,
    66.49553,
    15.00198,
    296.3,
    26.37
  ],
  [
    27093,
    66.49509,
    15.00243,
    297.6,
    26.31
  ],
  [
    27095,
    66.49465,
    15.00286,
    298.7,
    26.29
  ],
  [
    27097,
    66.49422,
    15.0033,
    299.9,
    26.28
  ],
  [
    27099,
    66.49378,
    15.00374,
    301,
    26.27
  ],
  [
    27101,
    66.49334,
    15.00418,
    302.3,
    26.22
  ],
  [
    27103,
    66.49291,
    15.00467,
    303.4,
    26.15
  ],
  [
    27105,
    66.4925,
    15.00524,
    304.5,
    26.12
  ],
  [
    27107,
    66.49212,
    15.00593,
    305.5,
    26.04
  ],
  [
    27109,
    66.49177,
    15.0067,
    306.4,
    26.01
  ],
  [
    27111,
    66.49146,
    15.00757,
    307.3,
    25.99
  ],
  [
    27113,
    66.49118,
    15.00851,
    308.4,
    25.98
  ],
  [
    27115,
    66.49094,
    15.00951,
    309.4,
    25.95
  ],
  [
    27117,
    66.49073,
    15.01055,
    310.5,
    25.92
  ],
  [
    27119,
    66.49053,
    15.0116,
    311.6,
    25.91
  ],
  [
    27121,
    66.49033,
    15.01265,
    312.7,
    25.89
  ],
  [
    27123,
    66.49013,
    15.0137,
    313.8,
    25.86
  ],
  [
    27125,
    66.48993,
    15.01475,
    315,
    25.83
  ],
  [
    27127,
    66.48973,
    15.0158,
    316.2,
    25.79
  ],
  [
    27129,
    66.48953,
    15.01685,
    317.3,
    25.76
  ],
  [
    27131,
    66.48935,
    15.01791,
    318.4,
    25.7
  ],
  [
    27133,
    66.48919,
    15.01899,
    319.1,
    25.69
  ],
  [
    27135,
    66.48904,
    15.02008,
    319.1,
    25.66
  ],
  [
    27137,
    66.48889,
    15.02117,
    320.6,
    25.65
  ],
  [
    27139,
    66.48872,
    15.02225,
    321.8,
    25.62
  ],
  [
    27142,
    66.4884,
    15.02377,
    323.5,
    25.59
  ],
  [
    27144,
    66.48814,
    15.02471,
    324.6,
    25.59
  ],
  [
    27146,
    66.48784,
    15.02559,
    325.7,
    25.52
  ],
  [
    27148,
    66.48753,
    15.02642,
    326.8,
    25.52
  ],
  [
    27150,
    66.48721,
    15.02724,
    328,
    25.45
  ],
  [
    27152,
    66.4869,
    15.02807,
    329.1,
    25.4
  ],
  [
    27154,
    66.4866,
    15.02894,
    330.3,
    25.36
  ],
  [
    27156,
    66.48633,
    15.02985,
    331.5,
    25.34
  ],
  [
    27158,
    66.48608,
    15.0308,
    332.6,
    25.32
  ],
  [
    27160,
    66.48585,
    15.03178,
    333.7,
    25.31
  ],
  [
    27162,
    66.48563,
    15.03278,
    334.9,
    25.31
  ],
  [
    27164,
    66.48541,
    15.03378,
    335.8,
    25.31
  ],
  [
    27166,
    66.4852,
    15.03478,
    336.8,
    25.3
  ],
  [
    27168,
    66.48499,
    15.03579,
    337.6,
    25.31
  ],
  [
    27170,
    66.48478,
    15.03679,
    338.5,
    25.3
  ],
  [
    27172,
    66.48456,
    15.03779,
    339.5,
    25.3
  ],
  [
    27174,
    66.48434,
    15.03879,
    340.4,
    25.28
  ],
  [
    27176,
    66.48412,
    15.03978,
    341.4,
    25.27
  ],
  [
    27178,
    66.48389,
    15.04076,
    342.3,
    25.23
  ],
  [
    27180,
    66.48366,
    15.04172,
    343.1,
    25.22
  ],
  [
    27182,
    66.4834,
    15.04265,
    343.7,
    25.18
  ],
  [
    27184,
    66.48311,
    15.0435,
    344.2,
    25.11
  ],
  [
    27186,
    66.48278,
    15.04428,
    344.7,
    25.06
  ],
  [
    27188,
    66.48245,
    15.04502,
    345.5,
    25.04
  ],
  [
    27190,
    66.48211,
    15.04576,
    346.5,
    25.02
  ],
  [
    27192,
    66.48178,
    15.04652,
    347.6,
    24.96
  ],
  [
    27194,
    66.48147,
    15.04734,
    347.6,
    24.95
  ],
  [
    27196,
    66.4812,
    15.04823,
    349.6,
    24.93
  ],
  [
    27199,
    66.48086,
    15.04967,
    351.1,
    24.89
  ],
  [
    27201,
    66.48067,
    15.05068,
    352,
    24.89
  ],
  [
    27203,
    66.48049,
    15.05171,
    353,
    24.89
  ],
  [
    27205,
    66.48031,
    15.05273,
    354,
    24.92
  ],
  [
    27207,
    66.48014,
    15.05376,
    355,
    24.93
  ],
  [
    27209,
    66.47996,
    15.05478,
    356,
    24.93
  ],
  [
    27211,
    66.47978,
    15.05581,
    357,
    24.91
  ],
  [
    27213,
    66.4796,
    15.05683,
    358,
    24.91
  ],
  [
    27215,
    66.47942,
    15.05786,
    359,
    24.91
  ],
  [
    27217,
    66.47924,
    15.05888,
    360,
    24.88
  ],
  [
    27219,
    66.47906,
    15.05991,
    361,
    24.87
  ],
  [
    27221,
    66.47889,
    15.06093,
    362.1,
    24.85
  ],
  [
    27223,
    66.47871,
    15.06195,
    363.1,
    24.83
  ],
  [
    27225,
    66.47852,
    15.06296,
    364.1,
    24.82
  ],
  [
    27227,
    66.47831,
    15.06394,
    365.2,
    24.8
  ],
  [
    27229,
    66.47808,
    15.0649,
    366.2,
    24.78
  ],
  [
    27231,
    66.47784,
    15.06582,
    367.1,
    24.74
  ],
  [
    27233,
    66.47757,
    15.06672,
    368.1,
    24.72
  ],
  [
    27235,
    66.4773,
    15.06758,
    369.1,
    24.69
  ],
  [
    27237,
    66.47701,
    15.06843,
    370,
    24.68
  ],
  [
    27239,
    66.47672,
    15.06927,
    371,
    24.66
  ],
  [
    27241,
    66.47644,
    15.07012,
    372,
    24.65
  ],
  [
    27243,
    66.47616,
    15.07099,
    372.9,
    24.64
  ],
  [
    27245,
    66.47589,
    15.07186,
    373.9,
    24.63
  ],
  [
    27247,
    66.47562,
    15.07273,
    374.8,
    24.62
  ],
  [
    27249,
    66.47536,
    15.07362,
    375.8,
    24.61
  ],
  [
    27251,
    66.4751,
    15.07452,
    375.8,
    24.6
  ],
  [
    27253,
    66.47485,
    15.07543,
    377.6,
    24.59
  ],
  [
    27255,
    66.47461,
    15.07634,
    378.6,
    24.59
  ],
  [
    27258,
    66.47425,
    15.07773,
    379.8,
    24.56
  ],
  [
    27260,
    66.47401,
    15.07867,
    380.8,
    24.56
  ],
  [
    27262,
    66.47379,
    15.07961,
    381.7,
    24.57
  ],
  [
    27264,
    66.47356,
    15.08057,
    382.6,
    24.57
  ],
  [
    27266,
    66.47335,
    15.08152,
    383.5,
    24.53
  ],
  [
    27268,
    66.47314,
    15.08249,
    384.4,
    24.52
  ],
  [
    27270,
    66.47293,
    15.08346,
    385.6,
    24.51
  ],
  [
    27272,
    66.47272,
    15.08443,
    386.4,
    24.5
  ],
  [
    27274,
    66.47251,
    15.0854,
    387.2,
    24.5
  ],
  [
    27276,
    66.4723,
    15.08636,
    388,
    24.51
  ],
  [
    27278,
    66.47208,
    15.08731,
    388.9,
    24.53
  ],
  [
    27280,
    66.47184,
    15.08823,
    389.8,
    24.52
  ],
  [
    27282,
    66.47158,
    15.08912,
    390.6,
    24.49
  ],
  [
    27284,
    66.47131,
    15.08999,
    391.5,
    24.48
  ],
  [
    27286,
    66.47103,
    15.09083,
    392.4,
    24.47
  ],
  [
    27288,
    66.47073,
    15.09165,
    393.2,
    24.47
  ],
  [
    27290,
    66.47044,
    15.09246,
    394.2,
    24.47
  ],
  [
    27292,
    66.47014,
    15.09327,
    395.1,
    24.46
  ],
  [
    27294,
    66.46984,
    15.09407,
    396.2,
    24.43
  ],
  [
    27296,
    66.46955,
    15.09488,
    397.1,
    24.42
  ],
  [
    27298,
    66.46925,
    15.09569,
    398.2,
    24.38
  ],
  [
    27300,
    66.46896,
    15.0965,
    399.3,
    24.37
  ],
  [
    27302,
    66.46867,
    15.09732,
    400.4,
    24.34
  ],
  [
    27304,
    66.46838,
    15.09815,
    401.6,
    24.32
  ],
  [
    27306,
    66.4681,
    15.09899,
    402.6,
    24.32
  ],
  [
    27308,
    66.46783,
    15.09984,
    403.7,
    24.31
  ],
  [
    27310,
    66.46757,
    15.10071,
    403.7,
    24.3
  ],
  [
    27312,
    66.46731,
    15.10158,
    405.7,
    24.29
  ],
  [
    27315,
    66.46693,
    15.10291,
    407.3,
    24.28
  ],
  [
    27317,
    66.46668,
    15.10381,
    408.4,
    24.27
  ],
  [
    27319,
    66.46644,
    15.10471,
    409.5,
    24.27
  ],
  [
    27321,
    66.4662,
    15.10562,
    410.6,
    24.24
  ],
  [
    27323,
    66.46596,
    15.10652,
    411.6,
    24.24
  ],
  [
    27325,
    66.46571,
    15.10742,
    412.8,
    24.2
  ],
  [
    27327,
    66.46546,
    15.1083,
    414,
    24.15
  ],
  [
    27329,
    66.46517,
    15.10911,
    414.8,
    24.1
  ],
  [
    27331,
    66.46486,
    15.10985,
    415.3,
    24.07
  ],
  [
    27333,
    66.46453,
    15.11055,
    416.3,
    24.06
  ],
  [
    27335,
    66.4642,
    15.11126,
    417.3,
    24.08
  ],
  [
    27337,
    66.46388,
    15.11199,
    418.2,
    24.12
  ],
  [
    27339,
    66.46358,
    15.11277,
    418.8,
    24.23
  ],
  [
    27341,
    66.46328,
    15.11357,
    419.2,
    24.38
  ],
  [
    27343,
    66.46299,
    15.11439,
    419.4,
    24.61
  ],
  [
    27345,
    66.4627,
    15.11522,
    419.3,
    24.89
  ],
  [
    27347,
    66.4624,
    15.11606,
    419.4,
    25.16
  ],
  [
    27349,
    66.4621,
    15.11691,
    419.4,
    25.41
  ],
  [
    27351,
    66.46179,
    15.11776,
    419.4,
    25.59
  ],
  [
    27353,
    66.46148,
    15.11862,
    419.3,
    25.72
  ],
  [
    27355,
    66.46118,
    15.11948,
    419.3,
    25.82
  ],
  [
    27357,
    66.46087,
    15.12035,
    419.5,
    25.9
  ],
  [
    27359,
    66.46056,
    15.12122,
    419.8,
    25.94
  ],
  [
    27361,
    66.46025,
    15.12209,
    420.2,
    25.94
  ],
  [
    27363,
    66.45994,
    15.12295,
    420.9,
    25.86
  ],
  [
    27365,
    66.45963,
    15.12382,
    421.9,
    25.7
  ],
  [
    27367,
    66.45933,
    15.12467,
    421.9,
    25.5
  ],
  [
    27369,
    66.45903,
    15.12553,
    423.9,
    25.27
  ],
  [
    27371,
    66.45874,
    15.12639,
    424.8,
    25.06
  ],
  [
    27374,
    66.45832,
    15.1277,
    426,
    24.87
  ],
  [
    27376,
    66.45805,
    15.12858,
    426.9,
    24.74
  ],
  [
    27378,
    66.45779,
    15.12948,
    427.8,
    24.74
  ],
  [
    27380,
    66.45754,
    15.13039,
    428.8,
    24.53
  ],
  [
    27382,
    66.45731,
    15.13131,
    429.6,
    24.48
  ],
  [
    27384,
    66.45708,
    15.13225,
    430.4,
    24.45
  ],
  [
    27386,
    66.45686,
    15.1332,
    431.2,
    24.47
  ],
  [
    27388,
    66.45665,
    15.13415,
    432.1,
    24.47
  ],
  [
    27390,
    66.45643,
    15.1351,
    432.9,
    24.47
  ],
  [
    27392,
    66.4562,
    15.13604,
    433.7,
    24.47
  ],
  [
    27394,
    66.45596,
    15.13696,
    434.4,
    24.47
  ],
  [
    27396,
    66.45571,
    15.13787,
    435.2,
    24.45
  ],
  [
    27398,
    66.45546,
    15.13876,
    436.1,
    24.43
  ],
  [
    27400,
    66.4552,
    15.13964,
    437,
    24.43
  ],
  [
    27402,
    66.45493,
    15.14051,
    437.9,
    24.42
  ],
  [
    27404,
    66.45466,
    15.14137,
    438.7,
    24.4
  ],
  [
    27406,
    66.45439,
    15.14223,
    439.6,
    24.37
  ],
  [
    27408,
    66.45412,
    15.14309,
    440.4,
    24.3
  ],
  [
    27410,
    66.45386,
    15.14396,
    441.4,
    24.3
  ],
  [
    27412,
    66.4536,
    15.14483,
    442.4,
    24.3
  ],
  [
    27425,
    66.45181,
    15.15039,
    448.7,
    23.93
  ],
  [
    27427,
    66.45161,
    15.15133,
    447.8,
    24.19
  ],
  [
    27429,
    66.45144,
    15.1523,
    447.4,
    24.17
  ],
  [
    27431,
    66.4513,
    15.15331,
    447.8,
    24.16
  ],
  [
    27433,
    66.45119,
    15.15437,
    448.5,
    25.56
  ],
  [
    27435,
    66.45113,
    15.15546,
    449.3,
    23.97
  ],
  [
    27437,
    66.45108,
    15.15654,
    450.2,
    24.12
  ],
  [
    27439,
    66.45104,
    15.15762,
    450.9,
    24.12
  ],
  [
    27441,
    66.45099,
    15.15869,
    451.5,
    24.14
  ],
  [
    27443,
    66.45093,
    15.15976,
    451.6,
    24.19
  ],
  [
    27445,
    66.45084,
    15.16082,
    451.7,
    24.18
  ],
  [
    27447,
    66.45074,
    15.16187,
    452,
    24.18
  ],
  [
    27449,
    66.45063,
    15.16292,
    452.5,
    24.16
  ],
  [
    27451,
    66.45053,
    15.16397,
    453,
    24.13
  ],
  [
    27453,
    66.45046,
    15.16504,
    453.9,
    24.1
  ],
  [
    27455,
    66.45043,
    15.16612,
    454.9,
    24.08
  ],
  [
    27457,
    66.45045,
    15.1672,
    456,
    24.09
  ],
  [
    27459,
    66.45049,
    15.16827,
    456.8,
    24.12
  ],
  [
    27461,
    66.45054,
    15.16934,
    457.4,
    24.13
  ],
  [
    27463,
    66.4506,
    15.17042,
    458,
    24.14
  ],
  [
    27465,
    66.45065,
    15.1715,
    458.1,
    24.16
  ],
  [
    27467,
    66.45068,
    15.17258,
    458.1,
    24.16
  ],
  [
    27469,
    66.45069,
    15.17366,
    459.1,
    24.17
  ],
  [
    27472,
    66.4507,
    15.17529,
    459.8,
    24.16
  ],
  [
    27474,
    66.4507,
    15.17638,
    460.6,
    24.17
  ],
  [
    27476,
    66.45071,
    15.17746,
    461.4,
    24.14
  ],
  [
    27478,
    66.45072,
    15.17854,
    462.1,
    24.14
  ],
  [
    27480,
    66.45076,
    15.17962,
    462.9,
    24.11
  ],
  [
    27482,
    66.45083,
    15.18069,
    463.5,
    24.1
  ],
  [
    27484,
    66.45092,
    15.18174,
    464.3,
    24.09
  ],
  [
    27486,
    66.45102,
    15.1828,
    465.1,
    24.08
  ],
  [
    27488,
    66.45113,
    15.18385,
    466.3,
    24.07
  ],
  [
    27490,
    66.45123,
    15.1849,
    467.2,
    24.2
  ],
  [
    27492,
    66.45133,
    15.18595,
    468.2,
    24.11
  ],
  [
    27494,
    66.45142,
    15.187,
    469.1,
    24.13
  ],
  [
    27496,
    66.4515,
    15.18807,
    470,
    24.13
  ],
  [
    27498,
    66.45157,
    15.18913,
    471,
    24.15
  ],
  [
    27500,
    66.45165,
    15.1902,
    471.9,
    24.15
  ],
  [
    27502,
    66.45175,
    15.19126,
    472.9,
    24.12
  ],
  [
    27504,
    66.45188,
    15.19228,
    473.8,
    24.09
  ],
  [
    27506,
    66.45205,
    15.19327,
    474.7,
    24.07
  ],
  [
    27508,
    66.45225,
    15.19422,
    475.4,
    24.08
  ],
  [
    27510,
    66.45248,
    15.19515,
    476.3,
    24.09
  ],
  [
    27512,
    66.45271,
    15.19606,
    477.1,
    24.11
  ],
  [
    27514,
    66.45294,
    15.19698,
    478,
    24.12
  ],
  [
    27516,
    66.45317,
    15.19789,
    478.9,
    24.13
  ],
  [
    27518,
    66.45341,
    15.1988,
    479.8,
    24.13
  ],
  [
    27520,
    66.45364,
    15.1997,
    480.7,
    24.13
  ],
  [
    27522,
    66.45389,
    15.2006,
    481.6,
    24.15
  ],
  [
    27524,
    66.45414,
    15.20148,
    482,
    24.14
  ],
  [
    27526,
    66.45441,
    15.20233,
    483.3,
    24.12
  ],
  [
    27528,
    66.45469,
    15.20315,
    484.1,
    24.14
  ],
  [
    27531,
    66.45515,
    15.20429,
    485.5,
    24.12
  ],
  [
    27533,
    66.45548,
    15.20499,
    486.4,
    24.12
  ],
  [
    27535,
    66.45583,
    15.20565,
    487.3,
    24.12
  ],
  [
    27537,
    66.45617,
    15.2063,
    487.9,
    24.15
  ],
  [
    27539,
    66.45652,
    15.20694,
    488.6,
    24.15
  ],
  [
    27541,
    66.45687,
    15.20758,
    489.2,
    24.17
  ],
  [
    27543,
    66.45722,
    15.20823,
    489.8,
    24.17
  ],
  [
    27545,
    66.45757,
    15.20887,
    490.5,
    24.16
  ],
  [
    27547,
    66.45792,
    15.20951,
    491.1,
    24.19
  ],
  [
    27549,
    66.45827,
    15.21015,
    491.8,
    24.17
  ],
  [
    27551,
    66.45862,
    15.21077,
    492.4,
    24.18
  ],
  [
    27553,
    66.45899,
    15.21136,
    493,
    24.17
  ],
  [
    27555,
    66.45936,
    15.21194,
    493.8,
    24.2
  ],
  [
    27557,
    66.45972,
    15.21252,
    494.5,
    24.17
  ],
  [
    27559,
    66.46008,
    15.21313,
    495.4,
    24.2
  ],
  [
    27561,
    66.46042,
    15.21381,
    496.1,
    24.2
  ],
  [
    27563,
    66.46073,
    15.21458,
    496.9,
    24.17
  ],
  [
    27565,
    66.461,
    15.21541,
    497.7,
    24.16
  ],
  [
    27567,
    66.46125,
    15.21631,
    498.5,
    24.15
  ],
  [
    27569,
    66.46146,
    15.21725,
    499.3,
    24.13
  ],
  [
    27571,
    66.46166,
    15.21821,
    500,
    24.1
  ],
  [
    27573,
    66.46186,
    15.21917,
    500.7,
    24.1
  ],
  [
    27575,
    66.46207,
    15.22011,
    501.5,
    24.08
  ],
  [
    27577,
    66.46231,
    15.221,
    501.9,
    24.08
  ],
  [
    27579,
    66.46259,
    15.22184,
    503.1,
    24.05
  ],
  [
    27581,
    66.46289,
    15.2226,
    503.1,
    24.06
  ],
  [
    27584,
    66.4634,
    15.2236,
    505,
    24.05
  ],
  [
    27586,
    66.46377,
    15.22416,
    505.2,
    24.03
  ],
  [
    27588,
    66.46416,
    15.22462,
    506.3,
    24.06
  ],
  [
    27591,
    66.46476,
    15.22521,
    507.2,
    24.11
  ],
  [
    27593,
    66.46517,
    15.22559,
    507.8,
    24.11
  ],
  [
    27595,
    66.46557,
    15.22598,
    508.8,
    24.11
  ],
  [
    27597,
    66.46597,
    15.22642,
    509.8,
    24.15
  ],
  [
    27599,
    66.46636,
    15.22692,
    510.7,
    24.2
  ],
  [
    27601,
    66.46673,
    15.22745,
    511.6,
    24.16
  ],
  [
    27603,
    66.46711,
    15.228,
    512.3,
    24.15
  ],
  [
    27605,
    66.46748,
    15.22855,
    513,
    24.17
  ],
  [
    27607,
    66.46786,
    15.22906,
    513.7,
    24.1
  ],
  [
    27609,
    66.46826,
    15.2295,
    514.5,
    24.12
  ],
  [
    27611,
    66.46866,
    15.22988,
    515.4,
    24.13
  ],
  [
    27613,
    66.46907,
    15.23024,
    516.3,
    24.16
  ],
  [
    27615,
    66.46948,
    15.23059,
    517.2,
    24.14
  ],
  [
    27617,
    66.46989,
    15.23094,
    518,
    24.14
  ],
  [
    27619,
    66.47031,
    15.23129,
    518.9,
    24.15
  ],
  [
    27621,
    66.47072,
    15.23162,
    519.8,
    24.13
  ],
  [
    27623,
    66.47114,
    15.2319,
    520.4,
    24.12
  ],
  [
    27625,
    66.47156,
    15.2321,
    521,
    24.12
  ],
  [
    27627,
    66.47199,
    15.2322,
    521.6,
    24.14
  ],
  [
    27629,
    66.47243,
    15.23226,
    522.4,
    24.11
  ],
  [
    27631,
    66.47286,
    15.23232,
    523.2,
    24.15
  ],
  [
    27633,
    66.47329,
    15.2324,
    524,
    24.18
  ],
  [
    27635,
    66.47372,
    15.23256,
    524.7,
    24.18
  ],
  [
    27637,
    66.47415,
    15.23276,
    525.4,
    24.17
  ],
  [
    27639,
    66.47458,
    15.23298,
    526.2,
    24.17
  ],
  [
    27641,
    66.475,
    15.23318,
    526.7,
    24.16
  ],
  [
    27643,
    66.47543,
    15.23331,
    526.7,
    24.17
  ],
  [
    27645,
    66.47587,
    15.23333,
    528.7,
    24.15
  ],
  [
    27648,
    66.47652,
    15.23318,
    530,
    24.17
  ],
  [
    27650,
    66.47695,
    15.23298,
    531,
    24.16
  ],
  [
    27652,
    66.47738,
    15.23276,
    532,
    24.18
  ],
  [
    27654,
    66.4778,
    15.23254,
    532.9,
    24.18
  ],
  [
    27656,
    66.47823,
    15.23234,
    533.8,
    24.16
  ],
  [
    27658,
    66.47866,
    15.23216,
    534.7,
    24.14
  ],
  [
    27660,
    66.47909,
    15.23202,
    535.7,
    24.13
  ],
  [
    27662,
    66.47952,
    15.23192,
    536.6,
    24.11
  ],
  [
    27664,
    66.47996,
    15.23185,
    537.5,
    24.1
  ],
  [
    27666,
    66.48039,
    15.23182,
    538.4,
    24.07
  ],
  [
    27668,
    66.48082,
    15.23183,
    539.2,
    24.06
  ],
  [
    27670,
    66.48125,
    15.23186,
    540.1,
    24.06
  ],
  [
    27672,
    66.48169,
    15.23192,
    541,
    24.07
  ],
  [
    27674,
    66.48212,
    15.23198,
    541.8,
    24.06
  ],
  [
    27676,
    66.48255,
    15.23204,
    542.7,
    24.03
  ],
  [
    27678,
    66.48298,
    15.23207,
    543.6,
    24.01
  ],
  [
    27680,
    66.48341,
    15.23206,
    544.6,
    24.01
  ],
  [
    27682,
    66.48384,
    15.232,
    545.4,
    24.01
  ],
  [
    27684,
    66.48428,
    15.23192,
    546.3,
    24.02
  ],
  [
    27686,
    66.48471,
    15.23183,
    547.2,
    24.01
  ],
  [
    27688,
    66.48514,
    15.23173,
    548.1,
    23.99
  ],
  [
    27690,
    66.48557,
    15.23163,
    549.1,
    23.98
  ],
  [
    27692,
    66.486,
    15.23151,
    550,
    23.96
  ],
  [
    27694,
    66.48642,
    15.23135,
    551,
    23.94
  ],
  [
    27696,
    66.48684,
    15.23115,
    551.9,
    23.92
  ],
  [
    27698,
    66.48726,
    15.23093,
    552.7,
    23.91
  ],
  [
    27700,
    66.48769,
    15.23071,
    553.1,
    23.92
  ],
  [
    27702,
    66.48811,
    15.23052,
    554.2,
    23.96
  ],
  [
    27704,
    66.48854,
    15.23041,
    554.9,
    24.01
  ],
  [
    27707,
    66.48918,
    15.23043,
    556.1,
    24.04
  ],
  [
    27709,
    66.48961,
    15.23058,
    556.9,
    24.04
  ],
  [
    27711,
    66.49003,
    15.23083,
    557.7,
    24.06
  ],
  [
    27713,
    66.49044,
    15.23118,
    558.4,
    24.06
  ],
  [
    27715,
    66.49083,
    15.23163,
    559.2,
    24.07
  ],
  [
    27717,
    66.49121,
    15.23217,
    559.9,
    24.06
  ],
  [
    27719,
    66.49156,
    15.2328,
    560.6,
    24.04
  ],
  [
    27721,
    66.49188,
    15.2335,
    561.3,
    24.06
  ],
  [
    27723,
    66.49218,
    15.23428,
    562,
    24.07
  ],
  [
    27725,
    66.49247,
    15.2351,
    562.4,
    24.07
  ],
  [
    27727,
    66.49275,
    15.23592,
    562.6,
    24.09
  ],
  [
    27729,
    66.49303,
    15.23674,
    563.1,
    24.21
  ],
  [
    27731,
    66.49331,
    15.23757,
    563.4,
    24.32
  ],
  [
    27733,
    66.4936,
    15.23839,
    563.7,
    24.46
  ],
  [
    27735,
    66.49392,
    15.23916,
    563.9,
    24.64
  ],
  [
    27737,
    66.49426,
    15.23986,
    564.2,
    24.88
  ],
  [
    27739,
    66.49464,
    15.24047,
    564.3,
    25.11
  ],
  [
    27741,
    66.49503,
    15.24103,
    564.6,
    25.35
  ],
  [
    27743,
    66.49543,
    15.24157,
    564.9,
    25.56
  ],
  [
    27745,
    66.49584,
    15.24212,
    565.4,
    25.73
  ],
  [
    27747,
    66.49625,
    15.24267,
    566.1,
    25.83
  ],
  [
    27749,
    66.49665,
    15.24323,
    567,
    25.93
  ],
  [
    27751,
    66.49705,
    15.24382,
    567.9,
    25.94
  ],
  [
    27753,
    66.49745,
    15.24444,
    568.9,
    25.93
  ],
  [
    27755,
    66.49783,
    15.2451,
    569.9,
    25.93
  ],
  [
    27757,
    66.49821,
    15.24577,
    570.8,
    25.86
  ],
  [
    27759,
    66.49858,
    15.24646,
    570.8,
    25.83
  ],
  [
    27761,
    66.49896,
    15.24715,
    572.5,
    25.81
  ],
  [
    27763,
    66.49933,
    15.24785,
    573.4,
    25.75
  ],
  [
    27766,
    66.49987,
    15.2489,
    574.7,
    25.73
  ],
  [
    27768,
    66.50023,
    15.24963,
    575.6,
    25.73
  ],
  [
    27770,
    66.50058,
    15.2504,
    576.4,
    25.73
  ],
  [
    27772,
    66.50091,
    15.2512,
    577.2,
    25.69
  ],
  [
    27774,
    66.50122,
    15.25203,
    577.8,
    25.62
  ],
  [
    27776,
    66.50152,
    15.2529,
    578.4,
    25.57
  ],
  [
    27778,
    66.50181,
    15.2538,
    579,
    25.54
  ],
  [
    27780,
    66.50208,
    15.25472,
    579.7,
    25.51
  ],
  [
    27782,
    66.50233,
    15.25568,
    580.2,
    25.48
  ],
  [
    27784,
    66.50256,
    15.25666,
    580.9,
    25.41
  ],
  [
    27786,
    66.50277,
    15.25767,
    581.5,
    25.39
  ],
  [
    27788,
    66.50297,
    15.2587,
    582.1,
    25.36
  ],
  [
    27790,
    66.50317,
    15.25972,
    582.7,
    25.26
  ],
  [
    27792,
    66.50337,
    15.26074,
    583.3,
    25.26
  ],
  [
    27794,
    66.50358,
    15.26174,
    583.8,
    25.22
  ],
  [
    27796,
    66.50381,
    15.26271,
    584.5,
    25.18
  ],
  [
    27798,
    66.50406,
    15.26365,
    585.2,
    25.17
  ],
  [
    27800,
    66.50433,
    15.26456,
    585.9,
    25.14
  ],
  [
    27802,
    66.50461,
    15.26545,
    586.7,
    25.13
  ],
  [
    27804,
    66.50488,
    15.26634,
    587.4,
    25.1
  ],
  [
    27806,
    66.50515,
    15.26724,
    588.1,
    25.13
  ],
  [
    27808,
    66.50541,
    15.26816,
    588.8,
    25.09
  ],
  [
    27810,
    66.50565,
    15.26912,
    589.5,
    25.09
  ],
  [
    27812,
    66.50587,
    15.2701,
    589.9,
    25.05
  ],
  [
    27814,
    66.50608,
    15.2711,
    591.1,
    25.06
  ],
  [
    27816,
    66.50628,
    15.2721,
    591.8,
    25.01
  ],
  [
    27819,
    66.50656,
    15.27363,
    592.9,
    24.98
  ],
  [
    27821,
    66.50674,
    15.27465,
    593.6,
    24.98
  ],
  [
    27824,
    66.507,
    15.27621,
    594.7,
    24.94
  ],
  [
    27826,
    66.50717,
    15.27725,
    595,
    24.92
  ],
  [
    27829,
    66.50741,
    15.2788,
    596.5,
    24.89
  ],
  [
    27831,
    66.50758,
    15.27984,
    597.3,
    24.86
  ],
  [
    27833,
    66.50775,
    15.28087,
    598.2,
    24.82
  ],
  [
    27835,
    66.50793,
    15.28188,
    598.9,
    24.82
  ],
  [
    27837,
    66.50815,
    15.28286,
    599.7,
    24.77
  ],
  [
    27839,
    66.50838,
    15.2838,
    600.6,
    24.75
  ],
  [
    27841,
    66.50864,
    15.2847,
    601.3,
    24.74
  ],
  [
    27843,
    66.50892,
    15.28555,
    602.2,
    24.73
  ],
  [
    27845,
    66.50923,
    15.28635,
    603.1,
    24.74
  ],
  [
    27847,
    66.50956,
    15.2871,
    604,
    24.74
  ],
  [
    27849,
    66.5099,
    15.2878,
    604.9,
    24.76
  ],
  [
    27851,
    66.51025,
    15.28849,
    605.8,
    24.8
  ],
  [
    27853,
    66.5106,
    15.28917,
    606.8,
    24.8
  ],
  [
    27855,
    66.51094,
    15.28989,
    607.9,
    24.83
  ],
  [
    27857,
    66.51126,
    15.29065,
    608.9,
    24.82
  ],
  [
    27859,
    66.51157,
    15.29145,
    610,
    24.77
  ],
  [
    27861,
    66.51188,
    15.29226,
    611.1,
    24.75
  ],
  [
    27863,
    66.51219,
    15.29306,
    612.2,
    24.72
  ],
  [
    27865,
    66.51251,
    15.29382,
    613.2,
    24.67
  ],
  [
    27867,
    66.51285,
    15.2945,
    613.9,
    24.66
  ],
  [
    27869,
    66.51322,
    15.2951,
    614.5,
    24.65
  ],
  [
    27871,
    66.51361,
    15.29561,
    615.3,
    24.65
  ],
  [
    27873,
    66.51402,
    15.29608,
    616.3,
    24.66
  ],
  [
    27875,
    66.51442,
    15.29653,
    617.4,
    24.67
  ],
  [
    27877,
    66.51482,
    15.29698,
    618.6,
    24.68
  ],
  [
    27879,
    66.51523,
    15.29742,
    619.6,
    24.68
  ],
  [
    27882,
    66.51584,
    15.29805,
    621.4,
    24.67
  ],
  [
    27883,
    66.51605,
    15.29824,
    621.4,
    24.65
  ],
  [
    27886,
    66.51669,
    15.29865,
    623.5,
    24.63
  ],
  [
    27889,
    66.51735,
    15.29882,
    625.2,
    24.6
  ],
  [
    27892,
    66.51801,
    15.29875,
    626.7,
    24.57
  ],
  [
    27894,
    66.51845,
    15.29856,
    626.7,
    24.54
  ],
  [
    27897,
    66.51908,
    15.29807,
    628.3,
    24.53
  ],
  [
    27899,
    66.51948,
    15.29762,
    629.1,
    24.53
  ],
  [
    27901,
    66.51987,
    15.2971,
    629.9,
    24.49
  ],
  [
    27903,
    66.52026,
    15.29655,
    630.5,
    24.49
  ],
  [
    27905,
    66.52064,
    15.296,
    631.4,
    24.5
  ],
  [
    27907,
    66.52103,
    15.29547,
    632.3,
    24.55
  ],
  [
    27909,
    66.52144,
    15.29501,
    633.2,
    24.59
  ],
  [
    27911,
    66.52185,
    15.29462,
    634.1,
    24.6
  ],
  [
    27913,
    66.52227,
    15.2943,
    635.1,
    24.58
  ],
  [
    27915,
    66.52271,
    15.29406,
    636.2,
    24.6
  ],
  [
    27917,
    66.52315,
    15.2939,
    637.3,
    24.59
  ],
  [
    27919,
    66.52359,
    15.29381,
    638.4,
    24.59
  ],
  [
    27921,
    66.52403,
    15.29381,
    639.4,
    24.6
  ],
  [
    27923,
    66.52447,
    15.29388,
    640.4,
    24.59
  ],
  [
    27925,
    66.52491,
    15.29403,
    641.4,
    24.58
  ],
  [
    27927,
    66.52534,
    15.29426,
    642.6,
    23.98
  ],
  [
    27929,
    66.52575,
    15.29455,
    643.8,
    23.98
  ],
  [
    27931,
    66.52617,
    15.29485,
    643.9,
    24.16
  ],
  [
    27933,
    66.52659,
    15.29516,
    644.1,
    24.26
  ],
  [
    27935,
    66.52703,
    15.29547,
    644.3,
    24.52
  ],
  [
    27937,
    66.52747,
    15.29576,
    644.9,
    24.19
  ],
  [
    27939,
    66.52791,
    15.29601,
    643.9,
    25.07
  ],
  [
    27941,
    66.52838,
    15.29623,
    645.4,
    28.21
  ],
  [
    27943,
    66.52882,
    15.29636,
    649.2,
    24.58
  ],
  [
    27945,
    66.52925,
    15.29652,
    650,
    24.53
  ],
  [
    27947,
    66.52968,
    15.29668,
    652.4,
    24.51
  ],
  [
    27949,
    66.53011,
    15.29684,
    653.8,
    24.51
  ],
  [
    27951,
    66.53054,
    15.29701,
    654.5,
    24.49
  ],
  [
    27953,
    66.53097,
    15.29718,
    656.5,
    24.47
  ],
  [
    27956,
    66.53162,
    15.29743,
    658.4,
    24.46
  ],
  [
    27958,
    66.53205,
    15.2976,
    659.7,
    24.43
  ],
  [
    27960,
    66.53249,
    15.29777,
    660.9,
    24.43
  ],
  [
    27962,
    66.53292,
    15.29794,
    662.1,
    24.4
  ],
  [
    27964,
    66.53335,
    15.29811,
    663.3,
    24.38
  ],
  [
    27966,
    66.53378,
    15.29828,
    664.4,
    24.35
  ],
  [
    27968,
    66.53421,
    15.29845,
    665.6,
    24.36
  ],
  [
    27970,
    66.53464,
    15.29863,
    666.6,
    24.35
  ],
  [
    27972,
    66.53507,
    15.2988,
    667.5,
    24.35
  ],
  [
    27974,
    66.5355,
    15.29897,
    668.2,
    24.44
  ],
  [
    27976,
    66.53593,
    15.29917,
    668.9,
    24.52
  ],
  [
    27978,
    66.53637,
    15.2994,
    669.6,
    24.66
  ],
  [
    27980,
    66.5368,
    15.29964,
    670.2,
    24.79
  ],
  [
    27982,
    66.53723,
    15.29991,
    670.9,
    24.93
  ],
  [
    27984,
    66.53767,
    15.30018,
    671.6,
    25.09
  ],
  [
    27986,
    66.5381,
    15.30045,
    671.8,
    25.25
  ],
  [
    27988,
    66.53854,
    15.30073,
    671.9,
    25.4
  ],
  [
    27990,
    66.53899,
    15.30101,
    671.9,
    25.56
  ],
  [
    27992,
    66.53943,
    15.30129,
    672.1,
    25.72
  ],
  [
    27994,
    66.53988,
    15.30157,
    672.3,
    25.88
  ],
  [
    27996,
    66.54033,
    15.30185,
    672.5,
    26.03
  ],
  [
    27998,
    66.54079,
    15.30213,
    673.2,
    26.15
  ],
  [
    28000,
    66.54125,
    15.30239,
    673.8,
    26.31
  ],
  [
    28002,
    66.54171,
    15.30262,
    674.2,
    26.4
  ],
  [
    28004,
    66.54218,
    15.30284,
    674.7,
    26.32
  ],
  [
    28006,
    66.54264,
    15.30302,
    675.4,
    26.18
  ],
  [
    28008,
    66.5431,
    15.3032,
    675.7,
    26.05
  ],
  [
    28010,
    66.54357,
    15.30337,
    675.7,
    25.95
  ],
  [
    28012,
    66.54403,
    15.30355,
    676.6,
    25.84
  ],
  [
    28015,
    66.54471,
    15.3038,
    677,
    25.71
  ],
  [
    28017,
    66.54515,
    15.30397,
    677.3,
    24.82
  ],
  [
    28019,
    66.54557,
    15.30413,
    677.9,
    24.82
  ],
  [
    28021,
    66.54599,
    15.30429,
    678.6,
    23.36
  ],
  [
    28023,
    66.54639,
    15.30444,
    679.2,
    22.18
  ],
  [
    28025,
    66.54677,
    15.30458,
    679.9,
    21.74
  ],
  [
    28027,
    66.54714,
    15.30472,
    680.5,
    20.43
  ],
  [
    28029,
    66.54749,
    15.30486,
    681.1,
    19.53
  ],
  [
    28031,
    66.54783,
    15.30498,
    681.7,
    18.66
  ],
  [
    28033,
    66.54815,
    15.3051,
    682.2,
    17.8
  ],
  [
    28035,
    66.54846,
    15.30522,
    682.7,
    17.45
  ],
  [
    28037,
    66.54876,
    15.30533,
    683.2,
    16.5
  ],
  [
    28039,
    66.54904,
    15.30544,
    683.7,
    15.86
  ],
  [
    28041,
    66.54932,
    15.30557,
    684.2,
    15.13
  ],
  [
    28043,
    66.54957,
    15.30571,
    684.6,
    14.39
  ],
  [
    28045,
    66.54981,
    15.30587,
    684.9,
    13.62
  ],
  [
    28047,
    66.55004,
    15.30605,
    685.2,
    12.89
  ],
  [
    28049,
    66.55025,
    15.30624,
    685.5,
    12.41
  ],
  [
    28051,
    66.55045,
    15.30645,
    685.6,
    12.09
  ],
  [
    28053,
    66.55064,
    15.30667,
    685.8,
    11.87
  ],
  [
    28055,
    66.55083,
    15.30689,
    685.8,
    11.75
  ],
  [
    28057,
    66.55102,
    15.30712,
    685.5,
    11.7
  ],
  [
    28059,
    66.5512,
    15.30735,
    685.2,
    11.66
  ],
  [
    28061,
    66.55139,
    15.30759,
    684.9,
    11.64
  ],
  [
    28063,
    66.55157,
    15.30782,
    684.5,
    11.64
  ],
  [
    28065,
    66.55176,
    15.30805,
    684.4,
    11.69
  ],
  [
    28067,
    66.55194,
    15.30828,
    684.4,
    11.77
  ],
  [
    28069,
    66.55213,
    15.30852,
    684,
    11.88
  ],
  [
    28072,
    66.55241,
    15.30888,
    683.8,
    12.03
  ],
  [
    28074,
    66.55261,
    15.30913,
    683.7,
    12.13
  ],
  [
    28076,
    66.55281,
    15.30938,
    683.6,
    12.5
  ],
  [
    28078,
    66.55301,
    15.30963,
    683.5,
    12.5
  ],
  [
    28080,
    66.55322,
    15.3099,
    683.4,
    13.08
  ],
  [
    28082,
    66.55343,
    15.31017,
    683.3,
    13.66
  ],
  [
    28084,
    66.55366,
    15.31044,
    683.3,
    14.1
  ],
  [
    28086,
    66.55389,
    15.31073,
    683.2,
    14.51
  ],
  [
    28088,
    66.55412,
    15.31103,
    683.2,
    14.92
  ],
  [
    28090,
    66.55436,
    15.31133,
    683.2,
    15.12
  ],
  [
    28092,
    66.55461,
    15.31164,
    683.2,
    15.69
  ],
  [
    28094,
    66.55486,
    15.31196,
    683.3,
    16.04
  ],
  [
    28096,
    66.55512,
    15.31229,
    683.3,
    16.38
  ],
  [
    28098,
    66.55539,
    15.31262,
    683.2,
    16.77
  ],
  [
    28100,
    66.55566,
    15.31296,
    683.2,
    17.2
  ],
  [
    28102,
    66.55594,
    15.31331,
    683.2,
    17.62
  ],
  [
    28104,
    66.55623,
    15.31367,
    683.2,
    18.07
  ],
  [
    28106,
    66.55652,
    15.31404,
    683.2,
    18.5
  ],
  [
    28108,
    66.55682,
    15.31442,
    683.1,
    18.92
  ],
  [
    28110,
    66.55712,
    15.3148,
    683,
    19.34
  ],
  [
    28112,
    66.55744,
    15.3152,
    682.9,
    19.76
  ],
  [
    28114,
    66.55776,
    15.3156,
    682.8,
    20.15
  ],
  [
    28116,
    66.55808,
    15.31601,
    682.7,
    20.55
  ],
  [
    28118,
    66.55842,
    15.31643,
    682.7,
    20.95
  ],
  [
    28120,
    66.55876,
    15.31685,
    682.6,
    21.32
  ],
  [
    28122,
    66.5591,
    15.31728,
    682.5,
    21.68
  ],
  [
    28124,
    66.55945,
    15.31772,
    682.5,
    22.03
  ],
  [
    28126,
    66.55981,
    15.31817,
    682.7,
    22.35
  ],
  [
    28128,
    66.56017,
    15.31863,
    683,
    22.63
  ],
  [
    28131,
    66.56071,
    15.31935,
    683.5,
    22.9
  ],
  [
    28133,
    66.56107,
    15.31989,
    683.9,
    22.9
  ],
  [
    28135,
    66.56141,
    15.32048,
    684.4,
    22.9
  ],
  [
    28137,
    66.56175,
    15.32112,
    684.8,
    23.37
  ],
  [
    28139,
    66.56207,
    15.32181,
    685.3,
    23.59
  ],
  [
    28141,
    66.56238,
    15.32254,
    685.7,
    23.77
  ],
  [
    28143,
    66.56268,
    15.32332,
    686.3,
    23.85
  ],
  [
    28145,
    66.56296,
    15.32413,
    686.9,
    23.88
  ],
  [
    28147,
    66.56322,
    15.32499,
    687.7,
    23.95
  ],
  [
    28149,
    66.56346,
    15.32588,
    688.5,
    23.98
  ],
  [
    28151,
    66.56369,
    15.3268,
    689.4,
    23.97
  ],
  [
    28153,
    66.5639,
    15.32774,
    690.1,
    23.94
  ],
  [
    28155,
    66.56411,
    15.32868,
    690.8,
    23.92
  ],
  [
    28157,
    66.56431,
    15.32963,
    691.5,
    23.91
  ],
  [
    28159,
    66.56452,
    15.33058,
    692.1,
    23.9
  ],
  [
    28161,
    66.56472,
    15.33152,
    692.7,
    23.92
  ],
  [
    28163,
    66.56493,
    15.33247,
    693.3,
    23.93
  ],
  [
    28165,
    66.56513,
    15.33341,
    693.7,
    23.97
  ],
  [
    28167,
    66.56534,
    15.33436,
    694.1,
    24.01
  ],
  [
    28169,
    66.56556,
    15.33529,
    694.4,
    24.09
  ],
  [
    28171,
    66.56581,
    15.33618,
    694.7,
    24.18
  ],
  [
    28173,
    66.56609,
    15.33701,
    695.1,
    24.31
  ],
  [
    28175,
    66.5664,
    15.33777,
    695.4,
    24.45
  ],
  [
    28177,
    66.56675,
    15.33845,
    695.6,
    24.58
  ],
  [
    28179,
    66.56712,
    15.33905,
    695.9,
    24.76
  ],
  [
    28181,
    66.56751,
    15.3396,
    696.1,
    24.95
  ],
  [
    28184,
    66.5681,
    15.34039,
    696.4,
    25.13
  ],
  [
    28186,
    66.56851,
    15.34092,
    696.4,
    25.42
  ],
  [
    28188,
    66.56891,
    15.34145,
    696.5,
    25.64
  ],
  [
    28190,
    66.56932,
    15.34199,
    696.6,
    25.87
  ],
  [
    28193,
    66.56994,
    15.34282,
    696.7,
    26.09
  ],
  [
    28195,
    66.57036,
    15.34337,
    696.7,
    26.09
  ],
  [
    28197,
    66.57078,
    15.34393,
    696.5,
    26.56
  ],
  [
    28199,
    66.5712,
    15.34449,
    696.3,
    26.56
  ],
  [
    28201,
    66.57163,
    15.34506,
    696.1,
    27.1
  ],
  [
    28203,
    66.57207,
    15.34563,
    695.9,
    27.54
  ],
  [
    28205,
    66.57251,
    15.34622,
    695.4,
    27.71
  ],
  [
    28207,
    66.57295,
    15.34681,
    695,
    28.19
  ],
  [
    28209,
    66.5734,
    15.3474,
    694.6,
    28.53
  ],
  [
    28211,
    66.57386,
    15.34801,
    694.3,
    28.85
  ],
  [
    28213,
    66.57432,
    15.34862,
    694.1,
    29.15
  ],
  [
    28215,
    66.57478,
    15.34923,
    694,
    29.43
  ],
  [
    28217,
    66.57526,
    15.34984,
    694,
    29.62
  ],
  [
    28219,
    66.57574,
    15.35038,
    693.9,
    29.82
  ],
  [
    28221,
    66.57625,
    15.35086,
    694.1,
    29.97
  ],
  [
    28223,
    66.57676,
    15.35127,
    694.3,
    30.12
  ],
  [
    28225,
    66.57729,
    15.3516,
    695.2,
    29.9
  ],
  [
    28227,
    66.57782,
    15.35189,
    697.1,
    30.32
  ],
  [
    28229,
    66.57836,
    15.35216,
    698.5,
    30.43
  ],
  [
    28231,
    66.5789,
    15.35241,
    699.6,
    30.45
  ],
  [
    28233,
    66.57944,
    15.35265,
    700.2,
    30.62
  ],
  [
    28235,
    66.57999,
    15.35287,
    700.6,
    30.8
  ],
  [
    28237,
    66.58054,
    15.35307,
    700.6,
    30.98
  ],
  [
    28239,
    66.58109,
    15.35326,
    700.4,
    31.17
  ],
  [
    28241,
    66.58165,
    15.35343,
    700,
    31.33
  ],
  [
    28244,
    66.5825,
    15.35365,
    699.2,
    31.51
  ],
  [
    28245,
    66.58278,
    15.35371,
    698.9,
    31.65
  ],
  [
    28248,
    66.58363,
    15.35383,
    698.4,
    31.32
  ],
  [
    28250,
    66.58418,
    15.35388,
    698.3,
    30.63
  ],
  [
    28253,
    66.585,
    15.35389,
    698.2,
    30.03
  ],
  [
    28255,
    66.58553,
    15.35387,
    698.3,
    29.69
  ],
  [
    28257,
    66.58605,
    15.35385,
    698.4,
    28.84
  ],
  [
    28259,
    66.58656,
    15.35382,
    698.5,
    28.84
  ],
  [
    28261,
    66.58707,
    15.3538,
    698.4,
    28.84
  ],
  [
    28263,
    66.58758,
    15.35378,
    698.1,
    28.29
  ],
  [
    28265,
    66.58808,
    15.35376,
    697.7,
    28.31
  ],
  [
    28267,
    66.58859,
    15.35373,
    697.2,
    28.5
  ],
  [
    28269,
    66.58911,
    15.35371,
    696.8,
    28.63
  ],
  [
    28271,
    66.58962,
    15.35369,
    696.7,
    28.73
  ],
  [
    28273,
    66.59014,
    15.35366,
    696.6,
    28.8
  ],
  [
    28275,
    66.59066,
    15.35364,
    696.6,
    28.83
  ],
  [
    28277,
    66.59117,
    15.35363,
    696.7,
    28.83
  ],
  [
    28279,
    66.59169,
    15.35364,
    696.6,
    28.84
  ],
  [
    28281,
    66.59221,
    15.35371,
    696.5,
    28.84
  ],
  [
    28283,
    66.59272,
    15.35383,
    696.3,
    28.9
  ],
  [
    28285,
    66.59324,
    15.354,
    696,
    28.93
  ],
  [
    28287,
    66.59375,
    15.35422,
    695.7,
    29
  ],
  [
    28289,
    66.59426,
    15.35449,
    695.4,
    29.09
  ],
  [
    28291,
    66.59477,
    15.35481,
    695,
    29.18
  ],
  [
    28293,
    66.59527,
    15.35518,
    694.7,
    29.31
  ],
  [
    28295,
    66.59577,
    15.35558,
    694.3,
    29.4
  ],
  [
    28297,
    66.59627,
    15.35598,
    693.9,
    29.49
  ],
  [
    28299,
    66.59678,
    15.35639,
    693.7,
    29.6
  ],
  [
    28301,
    66.59729,
    15.35679,
    693.5,
    29.67
  ],
  [
    28304,
    66.59805,
    15.3574,
    693.4,
    29.73
  ],
  [
    28305,
    66.5983,
    15.35761,
    693.3,
    29.76
  ],
  [
    28308,
    66.59906,
    15.35822,
    693.2,
    29.78
  ],
  [
    28310,
    66.59957,
    15.35863,
    693.1,
    29.81
  ],
  [
    28312,
    66.60008,
    15.35904,
    693,
    29.82
  ],
  [
    28314,
    66.60059,
    15.35945,
    692.9,
    29.84
  ],
  [
    28316,
    66.6011,
    15.35986,
    692.9,
    29.79
  ],
  [
    28318,
    66.60161,
    15.36027,
    692.8,
    29.76
  ],
  [
    28320,
    66.60212,
    15.36068,
    692.7,
    29.7
  ],
  [
    28322,
    66.60262,
    15.36112,
    692.6,
    29.6
  ],
  [
    28324,
    66.60312,
    15.36156,
    692.5,
    29.53
  ],
  [
    28326,
    66.60362,
    15.362,
    692.4,
    29.47
  ],
  [
    28328,
    66.60412,
    15.36243,
    692.4,
    29.41
  ],
  [
    28330,
    66.60461,
    15.36287,
    692.3,
    29.36
  ],
  [
    28332,
    66.60511,
    15.36331,
    692.3,
    29.29
  ],
  [
    28334,
    66.60561,
    15.36374,
    692.4,
    29.21
  ],
  [
    28336,
    66.6061,
    15.36417,
    692.5,
    29.12
  ],
  [
    28338,
    66.60659,
    15.36461,
    692.6,
    29.02
  ],
  [
    28340,
    66.60708,
    15.36506,
    692.7,
    28.92
  ],
  [
    28342,
    66.60756,
    15.36551,
    692.8,
    28.85
  ],
  [
    28344,
    66.60805,
    15.36596,
    692.9,
    28.82
  ],
  [
    28346,
    66.60853,
    15.36643,
    693.1,
    28.81
  ],
  [
    28348,
    66.60901,
    15.3669,
    693.1,
    28.77
  ],
  [
    28350,
    66.60949,
    15.36738,
    693,
    28.78
  ],
  [
    28352,
    66.60997,
    15.36786,
    693,
    28.81
  ],
  [
    28354,
    66.61045,
    15.36835,
    692.9,
    28.89
  ],
  [
    28356,
    66.61093,
    15.36885,
    692.9,
    28.97
  ],
  [
    28358,
    66.61141,
    15.36935,
    692.9,
    29.07
  ],
  [
    28360,
    66.61189,
    15.36985,
    692.9,
    29.18
  ],
  [
    28362,
    66.61238,
    15.37035,
    692.8,
    29.28
  ],
  [
    28365,
    66.61311,
    15.37111,
    692.7,
    29.45
  ],
  [
    28368,
    66.61384,
    15.37189,
    692.7,
    29.59
  ],
  [
    28370,
    66.61432,
    15.37244,
    692.7,
    29.75
  ],
  [
    28373,
    66.61505,
    15.37329,
    692.2,
    29.8
  ],
  [
    28375,
    66.61553,
    15.37386,
    691.4,
    29.9
  ],
  [
    28377,
    66.61602,
    15.37444,
    690.7,
    30.07
  ],
  [
    28379,
    66.61651,
    15.37502,
    689.8,
    30.07
  ],
  [
    28381,
    66.617,
    15.37559,
    688.8,
    30.35
  ],
  [
    28383,
    66.6175,
    15.37613,
    687.8,
    30.5
  ],
  [
    28385,
    66.61801,
    15.37659,
    686.8,
    30.54
  ],
  [
    28387,
    66.61854,
    15.37697,
    685.8,
    30.66
  ],
  [
    28389,
    66.61907,
    15.37729,
    685,
    30.75
  ],
  [
    28391,
    66.61961,
    15.37758,
    684.3,
    30.76
  ],
  [
    28393,
    66.62015,
    15.37787,
    683.9,
    30.79
  ],
  [
    28395,
    66.62069,
    15.37817,
    683.5,
    30.75
  ],
  [
    28397,
    66.62123,
    15.37846,
    683.2,
    30.69
  ],
  [
    28399,
    66.62176,
    15.37875,
    683,
    30.62
  ],
  [
    28401,
    66.6223,
    15.37905,
    682.8,
    30.54
  ],
  [
    28403,
    66.62283,
    15.37934,
    682.7,
    30.45
  ],
  [
    28405,
    66.62336,
    15.37963,
    682.5,
    30.41
  ],
  [
    28407,
    66.6239,
    15.37992,
    682.1,
    30.39
  ],
  [
    28409,
    66.62443,
    15.38021,
    681.7,
    30.42
  ],
  [
    28411,
    66.62496,
    15.3805,
    681.2,
    30.48
  ],
  [
    28413,
    66.6255,
    15.3808,
    680.8,
    30.52
  ],
  [
    28415,
    66.62603,
    15.38109,
    680.7,
    30.57
  ],
  [
    28417,
    66.62657,
    15.38138,
    680.5,
    30.59
  ],
  [
    28419,
    66.6271,
    15.38168,
    680.4,
    30.59
  ],
  [
    28421,
    66.62764,
    15.38197,
    680.3,
    30.58
  ],
  [
    28423,
    66.62817,
    15.38226,
    680.3,
    30.54
  ],
  [
    28426,
    66.62897,
    15.3827,
    680.5,
    30.51
  ],
  [
    28428,
    66.62951,
    15.383,
    680.7,
    30.49
  ],
  [
    28430,
    66.63004,
    15.38329,
    680.6,
    30.49
  ],
  [
    28432,
    66.63057,
    15.38358,
    680.4,
    30.4
  ],
  [
    28434,
    66.6311,
    15.38388,
    680.1,
    30.39
  ],
  [
    28436,
    66.63164,
    15.38417,
    679.7,
    30.43
  ],
  [
    28438,
    66.63217,
    15.38446,
    679.5,
    30.52
  ],
  [
    28440,
    66.6327,
    15.38476,
    679.5,
    30.54
  ],
  [
    28442,
    66.63324,
    15.38505,
    679.7,
    30.52
  ],
  [
    28444,
    66.63377,
    15.38535,
    679.9,
    30.44
  ],
  [
    28446,
    66.6343,
    15.38564,
    680,
    30.34
  ],
  [
    28448,
    66.63483,
    15.38595,
    680,
    30.24
  ],
  [
    28450,
    66.63535,
    15.38633,
    680,
    30.18
  ],
  [
    28452,
    66.63586,
    15.38678,
    680,
    30.13
  ],
  [
    28454,
    66.63636,
    15.38731,
    679.9,
    30.07
  ],
  [
    28456,
    66.63684,
    15.38792,
    679.7,
    30.04
  ],
  [
    28458,
    66.63732,
    15.38856,
    679.6,
    30.01
  ],
  [
    28460,
    66.63779,
    15.38921,
    679.5,
    30.03
  ],
  [
    28462,
    66.63826,
    15.38985,
    679.5,
    30.07
  ],
  [
    28464,
    66.63874,
    15.3905,
    679.5,
    30.13
  ],
  [
    28466,
    66.63921,
    15.39115,
    679.5,
    30.17
  ],
  [
    28468,
    66.63969,
    15.39181,
    679.4,
    30.15
  ],
  [
    28470,
    66.64016,
    15.39246,
    679.6,
    30.11
  ],
  [
    28472,
    66.64064,
    15.39311,
    679.9,
    30.06
  ],
  [
    28474,
    66.6411,
    15.39379,
    680.1,
    29.98
  ],
  [
    28476,
    66.64154,
    15.39456,
    680.1,
    29.85
  ],
  [
    28478,
    66.64196,
    15.39541,
    681.2,
    29.64
  ],
  [
    28481,
    66.64256,
    15.39672,
    682,
    29.42
  ],
  [
    28483,
    66.64297,
    15.39754,
    682.6,
    29.3
  ],
  [
    28485,
    66.6434,
    15.39825,
    683.2,
    29.3
  ],
  [
    28487,
    66.64386,
    15.39886,
    683.5,
    28.88
  ],
  [
    28489,
    66.64433,
    15.39936,
    683.7,
    28.88
  ],
  [
    28491,
    66.64481,
    15.39983,
    683.7,
    28.61
  ],
  [
    28493,
    66.64529,
    15.4003,
    683.6,
    28.64
  ],
  [
    28495,
    66.64576,
    15.40077,
    683.4,
    28.65
  ],
  [
    28497,
    66.64624,
    15.40124,
    683.1,
    28.8
  ],
  [
    28499,
    66.64673,
    15.40171,
    682.8,
    28.91
  ],
  [
    28501,
    66.64721,
    15.40219,
    682.5,
    29.02
  ],
  [
    28503,
    66.64769,
    15.40266,
    682.3,
    29.1
  ],
  [
    28505,
    66.64819,
    15.40312,
    682.3,
    29.15
  ],
  [
    28507,
    66.64869,
    15.40349,
    682.4,
    29.2
  ],
  [
    28509,
    66.6492,
    15.40373,
    682.6,
    29.2
  ],
  [
    28511,
    66.64972,
    15.40385,
    683.2,
    29.19
  ],
  [
    28513,
    66.65025,
    15.40385,
    683.2,
    29.23
  ],
  [
    28515,
    66.65077,
    15.40374,
    682.8,
    29.28
  ],
  [
    28517,
    66.65129,
    15.4036,
    682.4,
    29.34
  ],
  [
    28519,
    66.65182,
    15.40346,
    682,
    29.44
  ],
  [
    28521,
    66.65234,
    15.40335,
    681.6,
    29.54
  ],
  [
    28523,
    66.65288,
    15.40336,
    681.9,
    29.62
  ],
  [
    28525,
    66.65341,
    15.40349,
    682.1,
    29.66
  ],
  [
    28527,
    66.65393,
    15.40376,
    681.6,
    29.72
  ],
  [
    28529,
    66.65444,
    15.40416,
    681.3,
    29.73
  ],
  [
    28531,
    66.65493,
    15.40467,
    681.3,
    29.74
  ],
  [
    28533,
    66.65541,
    15.40525,
    681.2,
    29.74
  ],
  [
    28535,
    66.65589,
    15.40584,
    681.3,
    29.74
  ],
  [
    28538,
    66.65661,
    15.40673,
    681.5,
    29.74
  ],
  [
    28540,
    66.65709,
    15.40732,
    681.7,
    29.74
  ],
  [
    28542,
    66.65757,
    15.40791,
    681.7,
    29.79
  ],
  [
    28544,
    66.65805,
    15.4085,
    681.8,
    29.79
  ],
  [
    28546,
    66.65853,
    15.40909,
    681.9,
    29.7
  ],
  [
    28548,
    66.65901,
    15.40968,
    682,
    29.64
  ],
  [
    28550,
    66.65948,
    15.41026,
    682.2,
    29.59
  ],
  [
    28552,
    66.65996,
    15.41085,
    682.3,
    29.33
  ],
  [
    28554,
    66.66043,
    15.41142,
    682.5,
    28.74
  ],
  [
    28556,
    66.66089,
    15.41199,
    682.8,
    28.08
  ],
  [
    28558,
    66.66134,
    15.41254,
    683.2,
    27.6
  ],
  [
    28560,
    66.66178,
    15.41309,
    683.7,
    27.44
  ],
  [
    28562,
    66.66222,
    15.41362,
    684.2,
    27.04
  ],
  [
    28564,
    66.66265,
    15.41416,
    684.6,
    26.86
  ],
  [
    28566,
    66.66308,
    15.41469,
    685,
    26.73
  ],
  [
    28568,
    66.66352,
    15.41518,
    685.3,
    26.58
  ],
  [
    28570,
    66.66397,
    15.41559,
    685.4,
    26.47
  ],
  [
    28572,
    66.66443,
    15.41587,
    685.3,
    26.4
  ],
  [
    28574,
    66.66489,
    15.41603,
    685.9,
    26.34
  ],
  [
    28576,
    66.66537,
    15.41607,
    685.7,
    26.39
  ],
  [
    28578,
    66.66584,
    15.41597,
    685,
    26.4
  ],
  [
    28580,
    66.6663,
    15.41575,
    684.2,
    26.49
  ],
  [
    28582,
    66.66676,
    15.4154,
    683.3,
    26.64
  ],
  [
    28584,
    66.66721,
    15.41495,
    682.3,
    26.83
  ],
  [
    28586,
    66.66765,
    15.41446,
    681.8,
    27.06
  ],
  [
    28588,
    66.6681,
    15.41399,
    681.8,
    27.33
  ],
  [
    28590,
    66.66857,
    15.41359,
    679.4,
    27.58
  ],
  [
    28593,
    66.6693,
    15.41319,
    678.1,
    27.83
  ],
  [
    28595,
    66.6698,
    15.41306,
    677.1,
    27.96
  ],
  [
    28597,
    66.67031,
    15.41304,
    676.5,
    28.22
  ],
  [
    28599,
    66.67082,
    15.41313,
    675.8,
    28.22
  ],
  [
    28601,
    66.67133,
    15.41333,
    675.1,
    28.83
  ],
  [
    28603,
    66.67184,
    15.41366,
    674.4,
    29.17
  ],
  [
    28605,
    66.67234,
    15.41407,
    673.6,
    29.13
  ],
  [
    28607,
    66.67283,
    15.4145,
    672.8,
    28.67
  ],
  [
    28609,
    66.67331,
    15.41492,
    671.9,
    28.33
  ],
  [
    28611,
    66.67379,
    15.41534,
    671.2,
    28
  ],
  [
    28613,
    66.67426,
    15.41573,
    670.6,
    27.7
  ],
  [
    28615,
    66.67475,
    15.41602,
    670,
    27.53
  ],
  [
    28617,
    66.67524,
    15.41618,
    669.3,
    27.42
  ],
  [
    28619,
    66.67573,
    15.41621,
    668.7,
    27.4
  ],
  [
    28621,
    66.67622,
    15.41614,
    668.1,
    27.49
  ],
  [
    28623,
    66.67671,
    15.41606,
    667.4,
    27.57
  ],
  [
    28625,
    66.67721,
    15.41602,
    666.7,
    27.66
  ],
  [
    28627,
    66.6777,
    15.41609,
    665.8,
    27.8
  ],
  [
    28629,
    66.6782,
    15.4163,
    664.9,
    27.86
  ],
  [
    28631,
    66.67868,
    15.41665,
    664.1,
    27.94
  ],
  [
    28633,
    66.67914,
    15.41713,
    663.2,
    28.04
  ],
  [
    28635,
    66.67958,
    15.41774,
    662.4,
    28.1
  ],
  [
    28637,
    66.68,
    15.41848,
    661.5,
    28.19
  ],
  [
    28639,
    66.68037,
    15.41934,
    660.7,
    28.2
  ],
  [
    28641,
    66.68071,
    15.42029,
    660.7,
    28.23
  ],
  [
    28643,
    66.68103,
    15.4213,
    659.3,
    28.22
  ],
  [
    28646,
    66.68149,
    15.42281,
    658.3,
    28.22
  ],
  [
    28648,
    66.68183,
    15.42377,
    657.6,
    28.22
  ],
  [
    28650,
    66.6822,
    15.42462,
    656.8,
    28.22
  ],
  [
    28652,
    66.68261,
    15.42536,
    655.9,
    28.17
  ],
  [
    28654,
    66.68305,
    15.42599,
    655,
    28.2
  ],
  [
    28656,
    66.68352,
    15.42648,
    654,
    28.31
  ],
  [
    28658,
    66.68401,
    15.42683,
    653,
    28.38
  ],
  [
    28660,
    66.68451,
    15.42705,
    652,
    28.45
  ],
  [
    28662,
    66.68502,
    15.42716,
    651,
    28.64
  ],
  [
    28664,
    66.68554,
    15.42725,
    650.1,
    28.92
  ],
  [
    28666,
    66.68606,
    15.42736,
    649.2,
    29.17
  ],
  [
    28668,
    66.68658,
    15.42752,
    648.2,
    29.47
  ],
  [
    28670,
    66.6871,
    15.42776,
    647.1,
    29.69
  ],
  [
    28672,
    66.68762,
    15.42808,
    646.1,
    29.96
  ],
  [
    28674,
    66.68814,
    15.42849,
    645.1,
    30.2
  ],
  [
    28676,
    66.68864,
    15.42898,
    644.2,
    30.4
  ],
  [
    28678,
    66.68914,
    15.42954,
    643.4,
    30.61
  ],
  [
    28680,
    66.68963,
    15.43019,
    642.5,
    30.8
  ],
  [
    28682,
    66.69012,
    15.43087,
    641.7,
    30.97
  ],
  [
    28684,
    66.6906,
    15.43157,
    640.9,
    31.16
  ],
  [
    28686,
    66.69109,
    15.43227,
    639.9,
    31.4
  ],
  [
    28688,
    66.69158,
    15.43297,
    638.6,
    31.36
  ],
  [
    28690,
    66.69206,
    15.43365,
    636.1,
    30.8
  ],
  [
    28692,
    66.69259,
    15.43425,
    636.1,
    32.04
  ],
  [
    28694,
    66.69313,
    15.43475,
    632.4,
    32.29
  ],
  [
    28697,
    66.69396,
    15.43539,
    629.3,
    32.54
  ],
  [
    28699,
    66.69452,
    15.4358,
    627.6,
    32.55
  ],
  [
    28701,
    66.69507,
    15.43621,
    626,
    32.12
  ],
  [
    28703,
    66.69562,
    15.43662,
    624.4,
    32.12
  ],
  [
    28705,
    66.69616,
    15.43702,
    622.9,
    31.39
  ],
  [
    28707,
    66.69669,
    15.43742,
    621.5,
    31.04
  ],
  [
    28709,
    66.69722,
    15.43782,
    620.1,
    30.81
  ],
  [
    28711,
    66.69775,
    15.43825,
    618.8,
    30.81
  ],
  [
    28713,
    66.69827,
    15.43874,
    617.4,
    30.86
  ],
  [
    28715,
    66.69877,
    15.43932,
    616.1,
    30.91
  ],
  [
    28717,
    66.69926,
    15.43997,
    614.8,
    31.05
  ],
  [
    28719,
    66.69975,
    15.44064,
    613.5,
    31.17
  ],
  [
    28721,
    66.70025,
    15.44131,
    612.3,
    31.29
  ],
  [
    28723,
    66.70074,
    15.44199,
    611.1,
    31.42
  ],
  [
    28725,
    66.70124,
    15.44266,
    609.9,
    31.52
  ],
  [
    28727,
    66.70174,
    15.44334,
    608.8,
    31.6
  ],
  [
    28729,
    66.70224,
    15.44403,
    607.6,
    31.69
  ],
  [
    28731,
    66.70273,
    15.4447,
    606.4,
    31.32
  ],
  [
    28733,
    66.70323,
    15.44535,
    605.2,
    30.92
  ],
  [
    28735,
    66.70372,
    15.44598,
    604.3,
    30.53
  ],
  [
    28737,
    66.70421,
    15.44658,
    603.4,
    30.12
  ],
  [
    28739,
    66.7047,
    15.44716,
    602.7,
    29.68
  ],
  [
    28741,
    66.70518,
    15.44771,
    602.1,
    29.3
  ],
  [
    28743,
    66.70565,
    15.44826,
    601.6,
    28.9
  ],
  [
    28745,
    66.70612,
    15.44879,
    601.6,
    28.49
  ],
  [
    28747,
    66.70659,
    15.44932,
    600.6,
    28.12
  ],
  [
    28750,
    66.70727,
    15.4501,
    599.9,
    27.7
  ],
  [
    28752,
    66.70772,
    15.45062,
    599.4,
    27.51
  ],
  [
    28754,
    66.70815,
    15.45116,
    598.9,
    27.51
  ],
  [
    28756,
    66.70857,
    15.45174,
    598.1,
    26.79
  ],
  [
    28758,
    66.70898,
    15.45236,
    597.2,
    26.7
  ],
  [
    28760,
    66.70939,
    15.45302,
    596.5,
    26.76
  ],
  [
    28762,
    66.70978,
    15.45372,
    595.7,
    26.86
  ],
  [
    28764,
    66.71016,
    15.45446,
    594.9,
    26.99
  ],
  [
    28766,
    66.71053,
    15.45524,
    593.8,
    27.05
  ],
  [
    28768,
    66.7109,
    15.45605,
    592.8,
    27.16
  ],
  [
    28770,
    66.71126,
    15.45688,
    591.9,
    27.22
  ],
  [
    28772,
    66.71162,
    15.45772,
    591,
    27.39
  ],
  [
    28774,
    66.71198,
    15.45856,
    590.2,
    27.5
  ],
  [
    28776,
    66.71236,
    15.45935,
    589.2,
    27.56
  ],
  [
    28778,
    66.71277,
    15.46004,
    588.2,
    27.7
  ],
  [
    28780,
    66.71322,
    15.46061,
    587.4,
    27.73
  ],
  [
    28782,
    66.71368,
    15.46105,
    586.7,
    27.84
  ],
  [
    28784,
    66.71416,
    15.46137,
    586.1,
    27.72
  ],
  [
    28786,
    66.71465,
    15.46154,
    585.6,
    27.33
  ],
  [
    28788,
    66.71514,
    15.46157,
    584.9,
    26.94
  ],
  [
    28790,
    66.71562,
    15.46147,
    583.2,
    26.5
  ],
  [
    28792,
    66.71608,
    15.46123,
    581.5,
    26.13
  ],
  [
    28794,
    66.71652,
    15.46089,
    579.8,
    25.79
  ],
  [
    28796,
    66.71696,
    15.4605,
    579.1,
    25.53
  ],
  [
    28798,
    66.71739,
    15.46011,
    577.1,
    25.37
  ],
  [
    28800,
    66.71782,
    15.45975,
    575.9,
    25.41
  ],
  [
    28803,
    66.71849,
    15.4594,
    574.4,
    25.42
  ],
  [
    28805,
    66.71894,
    15.45932,
    573.8,
    25.42
  ],
  [
    28807,
    66.7194,
    15.45935,
    573.2,
    25.53
  ],
  [
    28809,
    66.71986,
    15.45946,
    572.5,
    25.68
  ],
  [
    28811,
    66.72032,
    15.45959,
    571.8,
    25.79
  ],
  [
    28813,
    66.72078,
    15.45972,
    570.9,
    25.93
  ],
  [
    28815,
    66.72125,
    15.45981,
    570,
    25.95
  ],
  [
    28817,
    66.72171,
    15.45984,
    569.2,
    26.13
  ],
  [
    28819,
    66.72218,
    15.45982,
    568.5,
    26.25
  ],
  [
    28821,
    66.72266,
    15.45974,
    567.7,
    26.37
  ],
  [
    28823,
    66.72313,
    15.45963,
    566.8,
    26.48
  ],
  [
    28825,
    66.7236,
    15.4595,
    565.9,
    26.6
  ],
  [
    28827,
    66.72408,
    15.45936,
    565,
    26.74
  ],
  [
    28829,
    66.72456,
    15.45922,
    564.1,
    26.86
  ],
  [
    28831,
    66.72504,
    15.45908,
    563.2,
    27
  ],
  [
    28833,
    66.72552,
    15.45894,
    562.2,
    27.1
  ],
  [
    28835,
    66.726,
    15.45878,
    561.3,
    27.23
  ],
  [
    28837,
    66.72648,
    15.45858,
    560.3,
    27.33
  ],
  [
    28839,
    66.72697,
    15.45834,
    559.4,
    27.42
  ],
  [
    28841,
    66.72745,
    15.45806,
    558.5,
    27.39
  ],
  [
    28843,
    66.72792,
    15.45773,
    557.6,
    27.01
  ],
  [
    28845,
    66.72838,
    15.4574,
    556.6,
    26.63
  ],
  [
    28847,
    66.72884,
    15.45708,
    555.6,
    26.28
  ],
  [
    28849,
    66.72929,
    15.4568,
    555.6,
    25.95
  ],
  [
    28851,
    66.72975,
    15.45657,
    553.7,
    25.64
  ],
  [
    28853,
    66.7302,
    15.45641,
    552.7,
    25.4
  ],
  [
    28856,
    66.73087,
    15.45627,
    551.2,
    25.14
  ],
  [
    28858,
    66.73132,
    15.45622,
    550.4,
    25.14
  ],
  [
    28860,
    66.73176,
    15.45617,
    549.5,
    25.14
  ],
  [
    28862,
    66.73219,
    15.45612,
    548.6,
    24.14
  ],
  [
    28864,
    66.73261,
    15.45608,
    547.8,
    23.55
  ],
  [
    28866,
    66.73303,
    15.45603,
    547,
    23.18
  ],
  [
    28868,
    66.73344,
    15.45599,
    546.1,
    22.8
  ],
  [
    28870,
    66.73385,
    15.45595,
    545.4,
    22.58
  ],
  [
    28872,
    66.73424,
    15.45597,
    544.6,
    21.76
  ],
  [
    28874,
    66.73462,
    15.45605,
    543.8,
    20.9
  ],
  [
    28876,
    66.73498,
    15.4562,
    543,
    19.94
  ],
  [
    28878,
    66.73532,
    15.45636,
    542.2,
    19.02
  ],
  [
    28880,
    66.73565,
    15.45653,
    541.5,
    18.19
  ],
  [
    28882,
    66.73596,
    15.45669,
    540.8,
    17.37
  ],
  [
    28884,
    66.73626,
    15.45682,
    540.3,
    16.69
  ],
  [
    28886,
    66.73655,
    15.45693,
    539.7,
    16.19
  ],
  [
    28888,
    66.73683,
    15.45701,
    539,
    15.69
  ],
  [
    28890,
    66.73711,
    15.45706,
    538.4,
    15.35
  ],
  [
    28892,
    66.73738,
    15.4571,
    537.8,
    15.01
  ],
  [
    28894,
    66.73765,
    15.45713,
    537.3,
    14.65
  ],
  [
    28896,
    66.7379,
    15.45716,
    536.8,
    14.31
  ],
  [
    28898,
    66.73815,
    15.45721,
    536.4,
    13.95
  ],
  [
    28900,
    66.7384,
    15.45729,
    536.1,
    13.49
  ],
  [
    28902,
    66.73863,
    15.45739,
    535.4,
    12.88
  ],
  [
    28904,
    66.73885,
    15.45752,
    534.9,
    12.07
  ],
  [
    28907,
    66.73914,
    15.45774,
    534.3,
    11.16
  ],
  [
    28909,
    66.73931,
    15.45789,
    533.9,
    11.16
  ],
  [
    28911,
    66.73947,
    15.45806,
    533.5,
    9.67
  ],
  [
    28913,
    66.73962,
    15.45822,
    533.3,
    9.67
  ],
  [
    28915,
    66.73976,
    15.4584,
    533.1,
    8.71
  ],
  [
    28917,
    66.73989,
    15.45857,
    532.8,
    8.14
  ],
  [
    28919,
    66.74001,
    15.45875,
    532.5,
    7.75
  ],
  [
    28921,
    66.74013,
    15.45892,
    532.2,
    7.41
  ],
  [
    28923,
    66.74024,
    15.45909,
    531.9,
    7.42
  ],
  [
    28925,
    66.74035,
    15.45926,
    531.7,
    7.47
  ],
  [
    28927,
    66.74047,
    15.45944,
    531.4,
    7.8
  ],
  [
    28929,
    66.74059,
    15.45963,
    531.1,
    8.1
  ],
  [
    28931,
    66.74071,
    15.45982,
    530.8,
    8.41
  ],
  [
    28933,
    66.74084,
    15.46003,
    530.4,
    8.78
  ],
  [
    28935,
    66.74098,
    15.46024,
    530.1,
    9.21
  ],
  [
    28937,
    66.74112,
    15.46046,
    529.8,
    9.61
  ],
  [
    28939,
    66.74127,
    15.46069,
    529.5,
    9.88
  ],
  [
    28941,
    66.74142,
    15.46092,
    529.2,
    10.11
  ],
  [
    28943,
    66.74158,
    15.46116,
    529,
    10.31
  ],
  [
    28945,
    66.74174,
    15.46141,
    528.9,
    10.49
  ],
  [
    28947,
    66.7419,
    15.46165,
    528.7,
    10.6
  ],
  [
    28949,
    66.74206,
    15.4619,
    528.6,
    10.13
  ],
  [
    28951,
    66.74221,
    15.46212,
    528.5,
    9.52
  ],
  [
    28953,
    66.74235,
    15.46233,
    528.5,
    8.8
  ],
  [
    28955,
    66.74248,
    15.46251,
    528.2,
    8.04
  ],
  [
    28957,
    66.7426,
    15.46266,
    528.1,
    7.3
  ],
  [
    28960,
    66.74277,
    15.46285,
    527.8,
    6.95
  ],
  [
    28962,
    66.74287,
    15.46295,
    527.7,
    6.29
  ],
  [
    28964,
    66.74295,
    15.46304,
    527.6,
    6.29
  ],
  [
    28966,
    66.74303,
    15.46312,
    527.5,
    4.8
  ],
  [
    28968,
    66.7431,
    15.46318,
    527.5,
    4.16
  ],
  [
    28970,
    66.74316,
    15.46323,
    527.4,
    3.41
  ],
  [
    28972,
    66.7432,
    15.46326,
    527.4,
    2.37
  ],
  [
    28974,
    66.74324,
    15.46329,
    527.3,
    1.66
  ],
  [
    28976,
    66.74326,
    15.4633,
    527.2,
    0.98
  ],
  [
    28978,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    28980,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    28982,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    28984,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    28986,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    28988,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    28990,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    28992,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    28994,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    28996,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    28998,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29000,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29002,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29004,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29006,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29008,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29010,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29013,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29015,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29017,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29019,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29021,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29023,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29025,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29027,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29029,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29031,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29033,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29035,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29037,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29039,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29041,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29043,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29045,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29047,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29049,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29051,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29053,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29055,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29057,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29059,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29061,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29063,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29066,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29068,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29070,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29072,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29074,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29076,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29078,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29080,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29082,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29084,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29086,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29088,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29090,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29092,
    66.74327,
    15.46331,
    527.2,
    0
  ],
  [
    29094,
    66.74328,
    15.46335,
    529.4,
    1.57
  ],
  [
    29096,
    66.74331,
    15.46338,
    529.4,
    2.28
  ],
  [
    29098,
    66.74336,
    15.46341,
    529.4,
    3.01
  ],
  [
    29100,
    66.74342,
    15.46345,
    529.4,
    3.72
  ],
  [
    29102,
    66.74349,
    15.46349,
    529.4,
    4.34
  ],
  [
    29104,
    66.74357,
    15.46353,
    529.4,
    4.84
  ],
  [
    29106,
    66.74366,
    15.46358,
    529.3,
    5.28
  ],
  [
    29108,
    66.74376,
    15.46362,
    529.3,
    5.67
  ],
  [
    29110,
    66.74386,
    15.46366,
    529.3,
    6.04
  ],
  [
    29112,
    66.74397,
    15.4637,
    529.3,
    6.41
  ],
  [
    29114,
    66.74409,
    15.46373,
    529.3,
    6.76
  ],
  [
    29116,
    66.74422,
    15.46376,
    529.3,
    7.1
  ],
  [
    29118,
    66.74435,
    15.46378,
    529.3,
    7.49
  ],
  [
    29121,
    66.74456,
    15.46379,
    529.3,
    7.86
  ],
  [
    29123,
    66.74471,
    15.4638,
    529.3,
    7.86
  ],
  [
    29125,
    66.74486,
    15.46381,
    529.3,
    7.86
  ],
  [
    29127,
    66.74503,
    15.46381,
    529.2,
    9.15
  ],
  [
    29129,
    66.7452,
    15.46382,
    529.2,
    9.76
  ],
  [
    29131,
    66.74538,
    15.46382,
    529.1,
    10.05
  ],
  [
    29133,
    66.74556,
    15.46383,
    529.1,
    10.24
  ],
  [
    29135,
    66.74575,
    15.46384,
    529,
    10.3
  ],
  [
    29137,
    66.74594,
    15.46384,
    529,
    10.52
  ],
  [
    29139,
    66.74613,
    15.46385,
    528.9,
    10.65
  ],
  [
    29141,
    66.74632,
    15.46386,
    528.7,
    10.77
  ],
  [
    29143,
    66.74652,
    15.46387,
    528.5,
    10.84
  ],
  [
    29145,
    66.74671,
    15.46387,
    528.2,
    10.93
  ],
  [
    29147,
    66.74691,
    15.46388,
    527.9,
    11.05
  ],
  [
    29149,
    66.74711,
    15.4639,
    527.6,
    11.2
  ],
  [
    29151,
    66.74731,
    15.46392,
    527.2,
    11.38
  ],
  [
    29153,
    66.74752,
    15.46395,
    526.8,
    11.58
  ],
  [
    29155,
    66.74773,
    15.46399,
    526.5,
    11.81
  ],
  [
    29157,
    66.74794,
    15.46403,
    526.1,
    12.04
  ],
  [
    29159,
    66.74816,
    15.46407,
    525.7,
    12.3
  ],
  [
    29161,
    66.74839,
    15.46413,
    525.2,
    12.55
  ],
  [
    29163,
    66.74861,
    15.46418,
    524.8,
    12.81
  ],
  [
    29165,
    66.74885,
    15.46423,
    524.3,
    13.16
  ],
  [
    29167,
    66.74909,
    15.46429,
    524.3,
    13.6
  ],
  [
    29169,
    66.74933,
    15.46434,
    523.4,
    14.06
  ],
  [
    29171,
    66.74959,
    15.4644,
    522.9,
    14.52
  ],
  [
    29174,
    66.74999,
    15.4645,
    522.1,
    15.02
  ],
  [
    29176,
    66.75027,
    15.46456,
    521.6,
    15.53
  ],
  [
    29178,
    66.75056,
    15.46463,
    521.1,
    15.53
  ],
  [
    29180,
    66.75086,
    15.4647,
    520.6,
    16.65
  ],
  [
    29182,
    66.75117,
    15.46477,
    520,
    17.53
  ],
  [
    29184,
    66.75149,
    15.46485,
    519.5,
    18.1
  ],
  [
    29186,
    66.75182,
    15.46493,
    519,
    18.66
  ],
  [
    29188,
    66.75216,
    15.46503,
    518.4,
    19.21
  ],
  [
    29190,
    66.7525,
    15.46516,
    517.7,
    19.49
  ],
  [
    29192,
    66.75286,
    15.4653,
    517.1,
    20.3
  ],
  [
    29194,
    66.75322,
    15.46546,
    516.3,
    20.86
  ],
  [
    29196,
    66.7536,
    15.46563,
    515.6,
    21.47
  ],
  [
    29198,
    66.75399,
    15.46581,
    514.8,
    22.1
  ],
  [
    29200,
    66.75438,
    15.46598,
    514.1,
    22.72
  ],
  [
    29202,
    66.75479,
    15.46617,
    513.3,
    23.31
  ],
  [
    29204,
    66.75521,
    15.46636,
    512.5,
    23.92
  ],
  [
    29206,
    66.75564,
    15.46655,
    511.7,
    24.5
  ],
  [
    29208,
    66.75608,
    15.46675,
    510.8,
    25.05
  ],
  [
    29210,
    66.75653,
    15.46695,
    510,
    25.59
  ],
  [
    29212,
    66.75699,
    15.46715,
    509.2,
    26.09
  ],
  [
    29214,
    66.75746,
    15.46736,
    508.3,
    26.58
  ],
  [
    29216,
    66.75793,
    15.46757,
    507.4,
    26.98
  ],
  [
    29218,
    66.75841,
    15.46776,
    507,
    27.27
  ],
  [
    29220,
    66.7589,
    15.46792,
    505.6,
    27.53
  ],
  [
    29222,
    66.7594,
    15.46804,
    504.7,
    27.7
  ],
  [
    29225,
    66.76014,
    15.46815,
    503.3,
    27.37
  ],
  [
    29227,
    66.76062,
    15.46819,
    502.4,
    27.21
  ],
  [
    29229,
    66.7611,
    15.46819,
    501.7,
    27.21
  ],
  [
    29231,
    66.76158,
    15.46816,
    501.1,
    26.45
  ],
  [
    29233,
    66.76205,
    15.46809,
    500.5,
    26.37
  ],
  [
    29235,
    66.76252,
    15.46799,
    499.9,
    26.25
  ],
  [
    29237,
    66.76299,
    15.46782,
    499.3,
    26.22
  ],
  [
    29239,
    66.76345,
    15.46754,
    498.5,
    26.23
  ],
  [
    29241,
    66.76389,
    15.46713,
    497.6,
    26.23
  ],
  [
    29243,
    66.76432,
    15.46662,
    496.5,
    26.3
  ],
  [
    29245,
    66.76473,
    15.46603,
    495.4,
    26.42
  ],
  [
    29247,
    66.76514,
    15.46542,
    494.4,
    26.5
  ],
  [
    29249,
    66.76555,
    15.46481,
    493.3,
    26.66
  ],
  [
    29251,
    66.76597,
    15.46422,
    492.2,
    26.74
  ],
  [
    29253,
    66.7664,
    15.46367,
    491.2,
    26.86
  ],
  [
    29255,
    66.76685,
    15.46321,
    490.2,
    26.92
  ],
  [
    29257,
    66.76731,
    15.46279,
    489.2,
    26.94
  ],
  [
    29259,
    66.76776,
    15.46238,
    488.3,
    26.99
  ],
  [
    29261,
    66.76822,
    15.46198,
    487.3,
    27.03
  ],
  [
    29263,
    66.76869,
    15.46161,
    486.4,
    27.1
  ],
  [
    29265,
    66.76916,
    15.46129,
    485.3,
    27.15
  ],
  [
    29267,
    66.76964,
    15.46104,
    484.2,
    27.22
  ],
  [
    29269,
    66.77012,
    15.4608,
    483,
    27.3
  ],
  [
    29271,
    66.7706,
    15.46057,
    482.5,
    27.42
  ],
  [
    29273,
    66.77108,
    15.46034,
    480.7,
    27.54
  ],
  [
    29276,
    66.77181,
    15.45997,
    479,
    27.63
  ],
  [
    29278,
    66.7723,
    15.45966,
    477.7,
    27.7
  ],
  [
    29280,
    66.77277,
    15.45928,
    476.5,
    27.7
  ],
  [
    29282,
    66.77325,
    15.45885,
    475.3,
    27.9
  ],
  [
    29284,
    66.77372,
    15.4584,
    474.2,
    28.06
  ],
  [
    29286,
    66.77419,
    15.45793,
    473.1,
    28.23
  ],
  [
    29288,
    66.77465,
    15.45739,
    471.7,
    28.48
  ],
  [
    29290,
    66.77509,
    15.45673,
    470.6,
    28.31
  ],
  [
    29292,
    66.77549,
    15.45595,
    469.3,
    28.43
  ],
  [
    29294,
    66.77588,
    15.4551,
    468.1,
    28.55
  ],
  [
    29296,
    66.77627,
    15.45423,
    466.9,
    28.65
  ],
  [
    29298,
    66.77666,
    15.4534,
    465.7,
    28.78
  ],
  [
    29300,
    66.77709,
    15.45266,
    464.6,
    28.87
  ],
  [
    29302,
    66.77754,
    15.45201,
    463.4,
    28.94
  ],
  [
    29304,
    66.778,
    15.45138,
    462.3,
    29.03
  ],
  [
    29306,
    66.77846,
    15.45073,
    461.2,
    29.09
  ],
  [
    29308,
    66.7789,
    15.45005,
    460,
    28.69
  ],
  [
    29310,
    66.77933,
    15.44936,
    458.8,
    28.28
  ],
  [
    29312,
    66.77976,
    15.44866,
    457.7,
    27.94
  ],
  [
    29314,
    66.78018,
    15.44797,
    456.5,
    27.66
  ],
  [
    29316,
    66.78059,
    15.44729,
    455.3,
    27.44
  ],
  [
    29318,
    66.781,
    15.44662,
    454.2,
    27.26
  ],
  [
    29320,
    66.78141,
    15.44592,
    453.7,
    27.19
  ],
  [
    29322,
    66.7818,
    15.44521,
    451.8,
    26.65
  ],
  [
    29324,
    66.78218,
    15.44446,
    450.1,
    26.64
  ],
  [
    29327,
    66.78275,
    15.44328,
    447.6,
    27.84
  ],
  [
    29329,
    66.78316,
    15.44259,
    445.1,
    27.84
  ],
  [
    29331,
    66.78357,
    15.44182,
    443.3,
    27.6
  ],
  [
    29333,
    66.78401,
    15.44117,
    441.9,
    27.6
  ],
  [
    29335,
    66.78445,
    15.44059,
    440.8,
    27.77
  ],
  [
    29337,
    66.7849,
    15.44001,
    439.7,
    27.96
  ],
  [
    29339,
    66.78534,
    15.43939,
    438.6,
    28.09
  ],
  [
    29341,
    66.78578,
    15.43874,
    437.1,
    28.17
  ],
  [
    29343,
    66.7862,
    15.43805,
    435.8,
    28.25
  ],
  [
    29345,
    66.78662,
    15.43732,
    434.5,
    28.33
  ],
  [
    29347,
    66.78704,
    15.43656,
    433.1,
    28.48
  ],
  [
    29349,
    66.78744,
    15.43576,
    431.8,
    28.58
  ],
  [
    29351,
    66.78784,
    15.43493,
    430.4,
    28.66
  ],
  [
    29353,
    66.78823,
    15.4341,
    429,
    28.28
  ],
  [
    29355,
    66.78863,
    15.43331,
    427.7,
    27.93
  ],
  [
    29357,
    66.78903,
    15.43257,
    426.4,
    27.57
  ],
  [
    29359,
    66.78944,
    15.43189,
    425.1,
    27.28
  ],
  [
    29361,
    66.78985,
    15.43124,
    423.9,
    27
  ],
  [
    29363,
    66.79026,
    15.43059,
    422.7,
    26.76
  ],
  [
    29365,
    66.79066,
    15.42991,
    421.5,
    26.5
  ],
  [
    29367,
    66.79103,
    15.42918,
    420.3,
    26.26
  ],
  [
    29369,
    66.79139,
    15.42841,
    419.2,
    26.03
  ],
  [
    29371,
    66.79174,
    15.42762,
    418,
    25.81
  ],
  [
    29373,
    66.79208,
    15.42684,
    418,
    25.6
  ],
  [
    29375,
    66.79241,
    15.42606,
    415.8,
    25.37
  ],
  [
    29378,
    66.79292,
    15.4249,
    414.2,
    25.16
  ],
  [
    29380,
    66.79324,
    15.42413,
    413.3,
    25.16
  ],
  [
    29382,
    66.79356,
    15.42335,
    412.2,
    24.81
  ],
  [
    29384,
    66.79385,
    15.42251,
    411.1,
    24.81
  ],
  [
    29386,
    66.79411,
    15.42165,
    410.5,
    23.82
  ],
  [
    29388,
    66.79435,
    15.4208,
    409.8,
    23.22
  ],
  [
    29390,
    66.7946,
    15.41994,
    409,
    23.22
  ],
  [
    29414,
    66.79397,
    15.40667,
    406.1,
    24.88
  ],
  [
    29416,
    66.79386,
    15.40552,
    407.1,
    25.85
  ],
  [
    29418,
    66.79381,
    15.40443,
    409.4,
    24.92
  ],
  [
    29420,
    66.79375,
    15.40331,
    397.6,
    24.63
  ],
  [
    29422,
    66.7938,
    15.40216,
    397.3,
    25.13
  ],
  [
    29424,
    66.79388,
    15.40102,
    396.7,
    25.24
  ],
  [
    29426,
    66.794,
    15.3999,
    396.3,
    25.33
  ],
  [
    29428,
    66.79417,
    15.39883,
    395.7,
    25.42
  ],
  [
    29430,
    66.79438,
    15.39779,
    395,
    25.48
  ],
  [
    29432,
    66.79462,
    15.3968,
    394.2,
    25.51
  ],
  [
    29434,
    66.79487,
    15.39582,
    393.5,
    25.59
  ],
  [
    29436,
    66.79513,
    15.39484,
    392.9,
    25.7
  ],
  [
    29438,
    66.79538,
    15.39385,
    392.2,
    25.81
  ],
  [
    29440,
    66.79564,
    15.39287,
    391.6,
    25.78
  ],
  [
    29442,
    66.79591,
    15.39192,
    390.9,
    25.4
  ],
  [
    29444,
    66.7962,
    15.39106,
    390.2,
    25.05
  ],
  [
    29446,
    66.79653,
    15.39028,
    389.7,
    24.83
  ],
  [
    29448,
    66.79688,
    15.38958,
    389,
    24.75
  ],
  [
    29450,
    66.79725,
    15.38894,
    389,
    24.7
  ],
  [
    29452,
    66.79762,
    15.3883,
    387.8,
    24.77
  ],
  [
    29455,
    66.79816,
    15.38732,
    386.9,
    24.87
  ],
  [
    29457,
    66.79851,
    15.3866,
    386.2,
    24.92
  ],
  [
    29459,
    66.79884,
    15.38581,
    385.5,
    24.92
  ],
  [
    29461,
    66.79914,
    15.38496,
    384.8,
    25.13
  ],
  [
    29463,
    66.79942,
    15.38406,
    384.1,
    25.31
  ],
  [
    29465,
    66.79969,
    15.38311,
    383.4,
    25.55
  ],
  [
    29467,
    66.79996,
    15.38215,
    382.6,
    25.94
  ],
  [
    29469,
    66.80023,
    15.38118,
    381.9,
    26.22
  ],
  [
    29471,
    66.8005,
    15.3802,
    381.2,
    26.47
  ],
  [
    29473,
    66.80078,
    15.37921,
    380.7,
    26.68
  ],
  [
    29475,
    66.80106,
    15.37821,
    380.4,
    26.78
  ],
  [
    29477,
    66.80133,
    15.37721,
    380.3,
    26.95
  ],
  [
    29479,
    66.80161,
    15.37619,
    380.3,
    26.99
  ],
  [
    29481,
    66.80186,
    15.37514,
    380.2,
    26.98
  ],
  [
    29483,
    66.8021,
    15.37407,
    380.2,
    26.97
  ],
  [
    29485,
    66.80234,
    15.373,
    380.1,
    26.97
  ],
  [
    29487,
    66.80257,
    15.37193,
    380.1,
    26.96
  ],
  [
    29489,
    66.80281,
    15.37086,
    380,
    26.94
  ],
  [
    29491,
    66.80305,
    15.36979,
    379.8,
    26.96
  ],
  [
    29493,
    66.8033,
    15.36874,
    379.5,
    26.98
  ],
  [
    29495,
    66.80357,
    15.36771,
    379.1,
    27.05
  ],
  [
    29497,
    66.80385,
    15.36669,
    378.5,
    27.15
  ],
  [
    29499,
    66.80414,
    15.36569,
    377.7,
    27.33
  ],
  [
    29501,
    66.80445,
    15.36472,
    377.7,
    27.56
  ],
  [
    29503,
    66.80478,
    15.36379,
    375.8,
    27.83
  ],
  [
    29506,
    66.80534,
    15.36251,
    374.5,
    28.08
  ],
  [
    29508,
    66.80574,
    15.36171,
    373.5,
    28.08
  ],
  [
    29510,
    66.80614,
    15.3609,
    372.5,
    28.08
  ],
  [
    29512,
    66.80655,
    15.36009,
    371.6,
    28.69
  ],
  [
    29514,
    66.80695,
    15.35928,
    370.7,
    28.72
  ],
  [
    29516,
    66.80737,
    15.35851,
    369.8,
    28.31
  ],
  [
    29518,
    66.8078,
    15.35784,
    369,
    27.95
  ],
  [
    29520,
    66.80825,
    15.35731,
    368.1,
    27.77
  ],
  [
    29522,
    66.80872,
    15.35691,
    367,
    27.48
  ],
  [
    29524,
    66.8092,
    15.35663,
    366.1,
    27.41
  ],
  [
    29526,
    66.80968,
    15.3564,
    365.1,
    27.39
  ],
  [
    29528,
    66.81017,
    15.35618,
    364.4,
    27.39
  ],
  [
    29530,
    66.81064,
    15.35586,
    363.8,
    27.28
  ],
  [
    29532,
    66.81112,
    15.35555,
    362.7,
    27.52
  ],
  [
    29534,
    66.81159,
    15.35507,
    362,
    27.59
  ],
  [
    29536,
    66.81203,
    15.35445,
    361,
    27.67
  ],
  [
    29538,
    66.81246,
    15.3538,
    360,
    27.79
  ],
  [
    29540,
    66.8129,
    15.35317,
    359.3,
    27.89
  ],
  [
    29542,
    66.81335,
    15.35261,
    358.5,
    28.01
  ],
  [
    29544,
    66.81383,
    15.35219,
    357.9,
    28.04
  ],
  [
    29546,
    66.81432,
    15.35188,
    356.8,
    28.09
  ],
  [
    29548,
    66.81482,
    15.35162,
    355.8,
    28.16
  ],
  [
    29550,
    66.81531,
    15.35135,
    354.6,
    28.25
  ],
  [
    29552,
    66.81581,
    15.35104,
    354.6,
    28.33
  ],
  [
    29554,
    66.81629,
    15.35066,
    352.9,
    28.42
  ],
  [
    29557,
    66.81701,
    15.34995,
    351.5,
    28.5
  ],
  [
    29559,
    66.81747,
    15.34938,
    350.4,
    28.56
  ],
  [
    29561,
    66.81791,
    15.34872,
    349.4,
    28.66
  ],
  [
    29563,
    66.81833,
    15.34794,
    348.4,
    28.66
  ],
  [
    29565,
    66.8187,
    15.34706,
    347.5,
    28.45
  ],
  [
    29567,
    66.81905,
    15.34613,
    346.5,
    27.89
  ],
  [
    29569,
    66.81938,
    15.34519,
    345.6,
    27.52
  ],
  [
    29571,
    66.81971,
    15.34427,
    344.7,
    27.23
  ],
  [
    29573,
    66.82006,
    15.3434,
    343.8,
    27.09
  ],
  [
    29575,
    66.82043,
    15.34262,
    342.9,
    27.05
  ],
  [
    29577,
    66.82083,
    15.34194,
    342,
    27.02
  ],
  [
    29579,
    66.82125,
    15.34131,
    341,
    27.08
  ],
  [
    29581,
    66.82167,
    15.34069,
    340.1,
    27.19
  ],
  [
    29583,
    66.82209,
    15.34007,
    339.1,
    27.31
  ],
  [
    29585,
    66.82252,
    15.33944,
    338.1,
    27.41
  ],
  [
    29587,
    66.82294,
    15.3388,
    337.1,
    27.53
  ],
  [
    29589,
    66.82336,
    15.33813,
    336.1,
    27.62
  ],
  [
    29591,
    66.82377,
    15.33743,
    335.1,
    27.72
  ],
  [
    29593,
    66.82418,
    15.33671,
    334.1,
    27.83
  ],
  [
    29595,
    66.82459,
    15.33598,
    333.2,
    27.93
  ],
  [
    29597,
    66.825,
    15.33525,
    332.3,
    28.01
  ],
  [
    29599,
    66.82541,
    15.3345,
    331.5,
    28.07
  ],
  [
    29601,
    66.8258,
    15.3337,
    330.7,
    28.11
  ],
  [
    29603,
    66.82617,
    15.33281,
    330.7,
    28.14
  ],
  [
    29605,
    66.82649,
    15.33183,
    328.7,
    28.2
  ],
  [
    29608,
    66.82694,
    15.33026,
    327.2,
    28.29
  ],
  [
    29610,
    66.82724,
    15.32922,
    326.1,
    28.36
  ],
  [
    29612,
    66.82755,
    15.32817,
    325.1,
    28.47
  ],
  [
    29614,
    66.82786,
    15.32713,
    324.1,
    28.47
  ],
  [
    29616,
    66.82817,
    15.32609,
    323.1,
    28.73
  ],
  [
    29618,
    66.82848,
    15.32504,
    322,
    28.84
  ],
  [
    29620,
    66.8288,
    15.32401,
    321,
    28.87
  ],
  [
    29622,
    66.82912,
    15.323,
    320,
    28.26
  ],
  [
    29624,
    66.82944,
    15.32201,
    319,
    27.88
  ],
  [
    29626,
    66.82976,
    15.32103,
    318,
    27.54
  ],
  [
    29628,
    66.83006,
    15.32004,
    317.1,
    27.25
  ],
  [
    29630,
    66.83034,
    15.31904,
    316.1,
    27.04
  ],
  [
    29632,
    66.83062,
    15.31804,
    315.1,
    26.82
  ],
  [
    29634,
    66.8309,
    15.31705,
    314.1,
    26.62
  ],
  [
    29636,
    66.83118,
    15.31607,
    313.2,
    26.43
  ],
  [
    29638,
    66.83145,
    15.31509,
    312.2,
    26.35
  ],
  [
    29640,
    66.83173,
    15.31411,
    311.3,
    26.34
  ],
  [
    29642,
    66.83201,
    15.31313,
    310.3,
    26.41
  ],
  [
    29644,
    66.83228,
    15.31215,
    309.4,
    26.52
  ],
  [
    29646,
    66.83256,
    15.31116,
    308.4,
    26.63
  ],
  [
    29648,
    66.83284,
    15.31017,
    307.5,
    26.75
  ],
  [
    29650,
    66.83312,
    15.30918,
    306.6,
    26.84
  ],
  [
    29652,
    66.8334,
    15.30817,
    305.6,
    26.96
  ],
  [
    29654,
    66.83367,
    15.30716,
    305.1,
    27.06
  ],
  [
    29656,
    66.83394,
    15.30613,
    303.7,
    27.18
  ],
  [
    29658,
    66.83422,
    15.3051,
    302.7,
    27.29
  ],
  [
    29661,
    66.83466,
    15.3036,
    301.5,
    27.38
  ],
  [
    29663,
    66.83497,
    15.30262,
    300.7,
    27.38
  ],
  [
    29665,
    66.83529,
    15.30167,
    299.8,
    27.38
  ],
  [
    29667,
    66.8356,
    15.30074,
    298.9,
    27.16
  ],
  [
    29669,
    66.8359,
    15.29979,
    298.1,
    26.52
  ],
  [
    29671,
    66.83615,
    15.2988,
    297.2,
    25.83
  ],
  [
    29673,
    66.83637,
    15.29777,
    296.3,
    25.38
  ],
  [
    29675,
    66.83655,
    15.29672,
    295.5,
    25.18
  ],
  [
    29677,
    66.83672,
    15.29567,
    294.6,
    24.75
  ],
  [
    29679,
    66.8369,
    15.29464,
    293.8,
    24.68
  ],
  [
    29681,
    66.8371,
    15.29363,
    292.9,
    24.67
  ],
  [
    29683,
    66.83734,
    15.2927,
    292,
    24.72
  ],
  [
    29685,
    66.83763,
    15.29185,
    291.1,
    24.8
  ],
  [
    29687,
    66.83797,
    15.29109,
    290.2,
    24.88
  ],
  [
    29689,
    66.83833,
    15.29044,
    289.4,
    24.96
  ],
  [
    29691,
    66.83873,
    15.28991,
    288.8,
    25.03
  ],
  [
    29693,
    66.83914,
    15.28949,
    288.4,
    25
  ],
  [
    29695,
    66.83956,
    15.28908,
    287.8,
    25
  ],
  [
    29697,
    66.83998,
    15.28867,
    287.1,
    25
  ],
  [
    29708,
    66.84241,
    15.28763,
    281.3,
    24.98
  ],
  [
    29710,
    66.84281,
    15.28713,
    280.2,
    24.95
  ],
  [
    29712,
    66.84318,
    15.2865,
    279.2,
    25.09
  ],
  [
    29714,
    66.84356,
    15.28587,
    278.5,
    25.22
  ],
  [
    29716,
    66.84395,
    15.28528,
    277.7,
    25.35
  ],
  [
    29718,
    66.84437,
    15.28482,
    276.9,
    25.38
  ],
  [
    29720,
    66.84481,
    15.28448,
    276,
    25.45
  ],
  [
    29722,
    66.84526,
    15.28428,
    275,
    25.45
  ],
  [
    29724,
    66.84572,
    15.2842,
    274.1,
    25.41
  ],
  [
    29726,
    66.84618,
    15.28417,
    273.3,
    25.37
  ],
  [
    29728,
    66.84663,
    15.28415,
    272.6,
    25.34
  ],
  [
    29730,
    66.84709,
    15.28415,
    272.2,
    25.34
  ],
  [
    29732,
    66.84755,
    15.28418,
    271.7,
    25.32
  ],
  [
    29734,
    66.848,
    15.28426,
    271.3,
    25.29
  ],
  [
    29736,
    66.84845,
    15.28436,
    271.3,
    25.26
  ],
  [
    29738,
    66.84891,
    15.28445,
    270.7,
    25.22
  ],
  [
    29741,
    66.84958,
    15.28445,
    270.3,
    25.16
  ],
  [
    29743,
    66.85003,
    15.28431,
    269.6,
    25.14
  ],
  [
    29745,
    66.85047,
    15.28404,
    269,
    25.14
  ],
  [
    29747,
    66.85089,
    15.28364,
    268.3,
    25.15
  ],
  [
    29749,
    66.85131,
    15.28318,
    267.6,
    25.27
  ],
  [
    29751,
    66.85172,
    15.2827,
    267,
    25.53
  ],
  [
    29753,
    66.85214,
    15.28222,
    266.3,
    25.77
  ],
  [
    29755,
    66.85257,
    15.28173,
    265.5,
    25.91
  ],
  [
    29757,
    66.85299,
    15.28124,
    264.7,
    26.28
  ],
  [
    29759,
    66.85342,
    15.28074,
    263.9,
    26.53
  ],
  [
    29761,
    66.85386,
    15.28025,
    263.1,
    26.81
  ],
  [
    29763,
    66.85432,
    15.27983,
    262.2,
    27.08
  ],
  [
    29765,
    66.85479,
    15.27952,
    261.3,
    27.33
  ],
  [
    29767,
    66.85528,
    15.27935,
    260.4,
    27.54
  ],
  [
    29769,
    66.85577,
    15.2793,
    259.5,
    27.8
  ],
  [
    29771,
    66.85628,
    15.2793,
    258.6,
    28.03
  ],
  [
    29773,
    66.85678,
    15.27932,
    257.9,
    28.3
  ],
  [
    29775,
    66.85729,
    15.27939,
    257.1,
    28.57
  ],
  [
    29777,
    66.8578,
    15.27956,
    256.3,
    28.8
  ],
  [
    29779,
    66.85831,
    15.27987,
    255.5,
    29.05
  ],
  [
    29781,
    66.85881,
    15.28028,
    254.4,
    29.28
  ],
  [
    29783,
    66.85931,
    15.28073,
    253.2,
    29.52
  ],
  [
    29785,
    66.85981,
    15.28116,
    253.2,
    29.43
  ],
  [
    29787,
    66.86031,
    15.28155,
    251.2,
    29.17
  ],
  [
    29790,
    66.86107,
    15.28204,
    250,
    28.86
  ],
  [
    29792,
    66.86157,
    15.28229,
    249.1,
    28.6
  ],
  [
    29794,
    66.86208,
    15.28248,
    248.2,
    28.6
  ],
  [
    29796,
    66.86258,
    15.28262,
    247.3,
    28.3
  ],
  [
    29798,
    66.86309,
    15.28271,
    246.5,
    28.33
  ],
  [
    29800,
    66.8636,
    15.28275,
    245.8,
    28.35
  ],
  [
    29802,
    66.86411,
    15.28274,
    245.1,
    28.44
  ],
  [
    29804,
    66.86462,
    15.28271,
    244.4,
    28.45
  ],
  [
    29806,
    66.86513,
    15.28268,
    243.8,
    28.48
  ],
  [
    29808,
    66.86564,
    15.28265,
    243.1,
    28.47
  ],
  [
    29810,
    66.86615,
    15.28259,
    242.5,
    28.48
  ],
  [
    29812,
    66.86666,
    15.28243,
    241.6,
    28.51
  ],
  [
    29814,
    66.86716,
    15.28214,
    240.6,
    28.51
  ],
  [
    29816,
    66.86764,
    15.28171,
    239.5,
    28.58
  ],
  [
    29818,
    66.86811,
    15.28119,
    238.4,
    28.68
  ],
  [
    29820,
    66.86859,
    15.28067,
    237.3,
    28.78
  ],
  [
    29822,
    66.86907,
    15.28018,
    236.1,
    28.87
  ],
  [
    29824,
    66.86955,
    15.27975,
    234.6,
    28.74
  ],
  [
    29826,
    66.87004,
    15.27936,
    233.6,
    28.31
  ],
  [
    29828,
    66.87053,
    15.279,
    232.7,
    27.93
  ],
  [
    29830,
    66.871,
    15.2786,
    231.6,
    27.48
  ],
  [
    29832,
    66.87144,
    15.27809,
    230.3,
    27.02
  ],
  [
    29834,
    66.87186,
    15.27749,
    229.3,
    26.63
  ],
  [
    29836,
    66.87226,
    15.27683,
    228.7,
    26.27
  ],
  [
    29838,
    66.87265,
    15.27616,
    227.2,
    25.9
  ],
  [
    29840,
    66.87303,
    15.27549,
    226.6,
    25.56
  ],
  [
    29843,
    66.87357,
    15.27442,
    225.6,
    25.19
  ],
  [
    29845,
    66.87388,
    15.27363,
    224.5,
    25.19
  ],
  [
    29847,
    66.87417,
    15.27278,
    223.7,
    24.63
  ],
  [
    29849,
    66.87445,
    15.27192,
    222.8,
    24.3
  ],
  [
    29851,
    66.87472,
    15.27105,
    222.1,
    24.27
  ],
  [
    29853,
    66.875,
    15.2702,
    221.3,
    24.51
  ],
  [
    29855,
    66.87531,
    15.2694,
    220.4,
    24.62
  ],
  [
    29857,
    66.87567,
    15.26871,
    219.4,
    25.02
  ],
  [
    29859,
    66.87605,
    15.26812,
    218.3,
    25.24
  ],
  [
    29861,
    66.87646,
    15.26759,
    217.2,
    25.49
  ],
  [
    29863,
    66.87687,
    15.26706,
    216.4,
    25.72
  ],
  [
    29865,
    66.87728,
    15.26654,
    215.8,
    25.91
  ],
  [
    29867,
    66.8777,
    15.266,
    215.4,
    26.04
  ],
  [
    29869,
    66.87812,
    15.26551,
    215.3,
    26.14
  ],
  [
    29871,
    66.87857,
    15.26512,
    215.2,
    26.15
  ],
  [
    29873,
    66.87903,
    15.26488,
    215,
    26.13
  ],
  [
    29875,
    66.8795,
    15.26478,
    214.8,
    26.09
  ],
  [
    29877,
    66.87996,
    15.26474,
    214.6,
    26.07
  ],
  [
    29879,
    66.88043,
    15.26471,
    214.1,
    25.64
  ],
  [
    29881,
    66.88089,
    15.26468,
    213.7,
    25.17
  ],
  [
    29883,
    66.88133,
    15.26464,
    213,
    24.69
  ],
  [
    29885,
    66.88177,
    15.26461,
    212.3,
    24.38
  ],
  [
    29887,
    66.88221,
    15.26458,
    212,
    24.29
  ],
  [
    29889,
    66.88265,
    15.26455,
    210.8,
    24.28
  ],
  [
    29891,
    66.88308,
    15.26454,
    209.7,
    24.36
  ],
  [
    29894,
    66.88374,
    15.26469,
    208.8,
    24.48
  ],
  [
    29896,
    66.88417,
    15.26491,
    208,
    24.59
  ],
  [
    29898,
    66.8846,
    15.26523,
    207.2,
    24.59
  ],
  [
    29900,
    66.88501,
    15.26565,
    206.1,
    24.76
  ],
  [
    29902,
    66.88542,
    15.26616,
    205.1,
    24.87
  ],
  [
    29904,
    66.8858,
    15.26676,
    204.1,
    24.96
  ],
  [
    29906,
    66.88617,
    15.26741,
    203.2,
    25.02
  ],
  [
    29908,
    66.88654,
    15.26807,
    202.3,
    25.07
  ],
  [
    29910,
    66.88691,
    15.26873,
    201.3,
    25.08
  ],
  [
    29912,
    66.88728,
    15.26939,
    200.3,
    25.18
  ],
  [
    29914,
    66.88766,
    15.27005,
    199.6,
    25.24
  ],
  [
    29916,
    66.88803,
    15.27073,
    199.6,
    25.33
  ],
  [
    29918,
    66.8884,
    15.27141,
    199.2,
    25.44
  ],
  [
    29920,
    66.88878,
    15.27207,
    198.6,
    25.56
  ],
  [
    29922,
    66.88917,
    15.27271,
    197.9,
    25.65
  ],
  [
    29924,
    66.88957,
    15.27329,
    197.2,
    25.75
  ],
  [
    29926,
    66.88998,
    15.27383,
    196,
    25.86
  ],
  [
    29928,
    66.89041,
    15.27433,
    195,
    25.98
  ],
  [
    29930,
    66.89083,
    15.27484,
    194.1,
    26.06
  ],
  [
    29932,
    66.89125,
    15.27535,
    193.4,
    26.17
  ],
  [
    29934,
    66.89167,
    15.2759,
    192.4,
    26.23
  ],
  [
    29936,
    66.89208,
    15.2765,
    191.4,
    26.26
  ],
  [
    29938,
    66.89248,
    15.27715,
    190.8,
    26.3
  ],
  [
    29940,
    66.89287,
    15.27784,
    190.8,
    26.37
  ],
  [
    29942,
    66.89326,
    15.27853,
    188.5,
    26.44
  ],
  [
    29945,
    66.89383,
    15.27957,
    187,
    26.12
  ],
  [
    29947,
    66.89421,
    15.28026,
    186.3,
    25.94
  ],
  [
    29949,
    66.89456,
    15.28099,
    185.5,
    25.94
  ],
  [
    29951,
    66.89489,
    15.28178,
    184,
    25.15
  ],
  [
    29953,
    66.89519,
    15.28263,
    182,
    25.03
  ],
  [
    29955,
    66.89548,
    15.2835,
    179.8,
    24.98
  ],
  [
    29957,
    66.89577,
    15.28435,
    177.9,
    25.09
  ],
  [
    29959,
    66.89609,
    15.28516,
    176.1,
    25.17
  ],
  [
    29961,
    66.89643,
    15.28593,
    174.6,
    25.27
  ],
  [
    29963,
    66.89678,
    15.28667,
    173.1,
    25.37
  ],
  [
    29965,
    66.89713,
    15.28739,
    171.7,
    25.41
  ],
  [
    29967,
    66.89751,
    15.28804,
    170.5,
    25.53
  ],
  [
    29969,
    66.89792,
    15.28858,
    169.5,
    25.4
  ],
  [
    29971,
    66.89833,
    15.28904,
    168.4,
    24.99
  ],
  [
    29973,
    66.89874,
    15.28947,
    167.3,
    24.54
  ],
  [
    29975,
    66.89914,
    15.2899,
    166.5,
    24.29
  ],
  [
    29977,
    66.89954,
    15.29033,
    165.9,
    24.29
  ],
  [
    29980,
    66.90009,
    15.29105,
    165.3,
    23.24
  ],
  [
    29982,
    66.90034,
    15.29179,
    165,
    22.24
  ],
  [
    29984,
    66.90066,
    15.29246,
    164.3,
    21.65
  ],
  [
    29986,
    66.90096,
    15.29307,
    163.4,
    21.15
  ],
  [
    29988,
    66.90126,
    15.29365,
    162.8,
    20.65
  ],
  [
    29990,
    66.90157,
    15.29417,
    162.6,
    20.8
  ],
  [
    29992,
    66.90188,
    15.29463,
    161.8,
    19.68
  ],
  [
    29994,
    66.90219,
    15.29505,
    161.6,
    19.36
  ],
  [
    29996,
    66.90248,
    15.29546,
    160.9,
    17.88
  ],
  [
    29999,
    66.9029,
    15.29605,
    160.2,
    17.88
  ],
  [
    30000,
    66.90304,
    15.29625,
    159.9,
    17.88
  ],
  [
    30010,
    66.9044,
    15.29848,
    158.5,
    17.56
  ],
  [
    30012,
    66.90456,
    15.29899,
    158.4,
    16.93
  ],
  [
    30014,
    66.90481,
    15.29947,
    157.9,
    17.14
  ],
  [
    30016,
    66.90507,
    15.29987,
    156.9,
    17.38
  ],
  [
    30018,
    66.90534,
    15.30028,
    156.5,
    17.71
  ],
  [
    30020,
    66.90561,
    15.30068,
    156,
    17.91
  ],
  [
    30022,
    66.90589,
    15.30108,
    156,
    17.91
  ],
  [
    30024,
    66.90617,
    15.30148,
    154.9,
    17.65
  ],
  [
    30027,
    66.90658,
    15.30207,
    154.1,
    17.3
  ],
  [
    30029,
    66.90684,
    15.30245,
    153.6,
    17.3
  ],
  [
    30031,
    66.9071,
    15.30282,
    153.1,
    16.66
  ],
  [
    30033,
    66.90736,
    15.30318,
    152.6,
    16.66
  ],
  [
    30035,
    66.90761,
    15.30353,
    152,
    16
  ],
  [
    30037,
    66.90786,
    15.30385,
    152,
    15.55
  ],
  [
    30039,
    66.90812,
    15.30411,
    151.6,
    15.28
  ],
  [
    30041,
    66.90838,
    15.30434,
    151.6,
    15.3
  ],
  [
    30043,
    66.90864,
    15.30453,
    151.4,
    15.39
  ],
  [
    30045,
    66.90892,
    15.30471,
    151.3,
    15.51
  ],
  [
    30047,
    66.9092,
    15.30489,
    151.2,
    15.98
  ],
  [
    30049,
    66.90948,
    15.30508,
    151.2,
    16.25
  ],
  [
    30051,
    66.90977,
    15.30528,
    151.4,
    16.52
  ],
  [
    30053,
    66.91006,
    15.3055,
    151.3,
    16.83
  ],
  [
    30055,
    66.91035,
    15.30576,
    150.9,
    17.1
  ],
  [
    30057,
    66.91064,
    15.30604,
    150.3,
    17.43
  ],
  [
    30059,
    66.91093,
    15.30635,
    149.6,
    17.82
  ],
  [
    30061,
    66.91123,
    15.30666,
    148.9,
    18.21
  ],
  [
    30063,
    66.91153,
    15.30698,
    148.2,
    18.63
  ],
  [
    30065,
    66.91185,
    15.3073,
    147.3,
    19.05
  ],
  [
    30067,
    66.91217,
    15.30758,
    146.2,
    19.51
  ],
  [
    30069,
    66.91252,
    15.30779,
    145.2,
    20
  ],
  [
    30071,
    66.91288,
    15.30791,
    144.8,
    20.47
  ],
  [
    30073,
    66.91325,
    15.30794,
    144.8,
    20.96
  ],
  [
    30075,
    66.91362,
    15.30788,
    143.2,
    20.69
  ],
  [
    30078,
    66.91418,
    15.30776,
    142.6,
    20.69
  ],
  [
    30091,
    66.91671,
    15.30464,
    139.4,
    24.91
  ],
  [
    30093,
    66.91712,
    15.30435,
    133.6,
    24.69
  ],
  [
    30095,
    66.91758,
    15.30429,
    135.6,
    25.13
  ],
  [
    30097,
    66.91803,
    15.30436,
    135.5,
    25.33
  ],
  [
    30099,
    66.91848,
    15.30455,
    135.6,
    25.66
  ],
  [
    30101,
    66.91894,
    15.30488,
    134.8,
    25.72
  ],
  [
    30103,
    66.91938,
    15.30524,
    134.1,
    26.14
  ],
  [
    30105,
    66.91982,
    15.3056,
    133.7,
    26.05
  ],
  [
    30107,
    66.92027,
    15.30592,
    133.7,
    25.69
  ],
  [
    30109,
    66.92072,
    15.30612,
    133.2,
    25.32
  ],
  [
    30111,
    66.92117,
    15.30621,
    132.6,
    25.08
  ],
  [
    30113,
    66.92161,
    15.30626,
    132.6,
    25
  ],
  [
    30115,
    66.92206,
    15.30629,
    131.8,
    25
  ],
  [
    30118,
    66.92274,
    15.30635,
    131.6,
    25.19
  ],
  [
    30120,
    66.92319,
    15.30638,
    131.5,
    25.25
  ],
  [
    30122,
    66.92364,
    15.30642,
    131.6,
    25.37
  ],
  [
    30124,
    66.9241,
    15.30645,
    131.7,
    25.37
  ],
  [
    30126,
    66.92455,
    15.30648,
    132,
    25.47
  ],
  [
    30128,
    66.92501,
    15.30652,
    132.1,
    25.44
  ],
  [
    30130,
    66.92546,
    15.30654,
    131.3,
    25.39
  ],
  [
    30132,
    66.92591,
    15.30657,
    130.7,
    25.41
  ],
  [
    30134,
    66.92637,
    15.3066,
    130.8,
    25.54
  ],
  [
    30136,
    66.92682,
    15.30663,
    130.5,
    25.41
  ],
  [
    30138,
    66.92727,
    15.30665,
    130.8,
    24.66
  ],
  [
    30140,
    66.92771,
    15.30667,
    130.6,
    24.66
  ],
  [
    30142,
    66.92812,
    15.30622,
    129.3,
    25.69
  ],
  [
    30144,
    66.92856,
    15.30568,
    127.6,
    25.86
  ],
  [
    30146,
    66.92899,
    15.30521,
    126.4,
    26.02
  ],
  [
    30148,
    66.92944,
    15.3048,
    124.9,
    26.29
  ],
  [
    30150,
    66.9299,
    15.30449,
    123,
    26.55
  ],
  [
    30152,
    66.93038,
    15.30432,
    121.3,
    26.5
  ],
  [
    30154,
    66.93086,
    15.30432,
    119.5,
    26.2
  ],
  [
    30156,
    66.93133,
    15.30448,
    117.6,
    25.85
  ],
  [
    30158,
    66.93177,
    15.30479,
    115.9,
    25.5
  ],
  [
    30160,
    66.9322,
    15.30525,
    114.8,
    25.31
  ],
  [
    30162,
    66.9326,
    15.30582,
    113.8,
    25.22
  ],
  [
    30164,
    66.93299,
    15.3064,
    112.8,
    25.12
  ],
  [
    30166,
    66.93339,
    15.30697,
    110.8,
    25.2
  ],
  [
    30168,
    66.9338,
    15.30746,
    109.8,
    25.3
  ],
  [
    30171,
    66.93446,
    15.30795,
    108.3,
    25.36
  ],
  [
    30173,
    66.93491,
    15.30809,
    107.6,
    25.36
  ],
  [
    30175,
    66.93537,
    15.30817,
    106.9,
    25.36
  ],
  [
    30177,
    66.93581,
    15.30802,
    106.7,
    25.42
  ],
  [
    30179,
    66.93626,
    15.308,
    107.1,
    25.81
  ],
  [
    30181,
    66.93672,
    15.30801,
    106.4,
    25.87
  ],
  [
    30183,
    66.93719,
    15.30801,
    106.8,
    25.94
  ],
  [
    30185,
    66.93766,
    15.3079,
    106.2,
    25.99
  ],
  [
    30187,
    66.93812,
    15.30767,
    105.5,
    26.13
  ],
  [
    30189,
    66.93857,
    15.3073,
    104.5,
    26.17
  ],
  [
    30191,
    66.93899,
    15.3068,
    103.2,
    26.21
  ],
  [
    30193,
    66.9394,
    15.3062,
    101.8,
    26.29
  ],
  [
    30195,
    66.93979,
    15.30552,
    100.8,
    26.38
  ],
  [
    30197,
    66.94019,
    15.30483,
    99.8,
    26.48
  ],
  [
    30199,
    66.94058,
    15.30412,
    98.6,
    26.59
  ],
  [
    30201,
    66.94096,
    15.30339,
    97.4,
    26.67
  ],
  [
    30203,
    66.94133,
    15.3026,
    96.5,
    26.74
  ],
  [
    30205,
    66.94169,
    15.30177,
    95.6,
    27.03
  ],
  [
    30207,
    66.94205,
    15.30086,
    95.3,
    28.05
  ],
  [
    30209,
    66.94241,
    15.29996,
    94.5,
    28.05
  ],
  [
    30211,
    66.94272,
    15.29909,
    93.8,
    26.38
  ],
  [
    30213,
    66.94301,
    15.29822,
    93.8,
    26.12
  ],
  [
    30215,
    66.94335,
    15.29742,
    91.7,
    25.71
  ],
  [
    30218,
    66.94386,
    15.29628,
    90.1,
    25.39
  ],
  [
    30220,
    66.94421,
    15.29554,
    89.2,
    25.27
  ],
  [
    30222,
    66.94456,
    15.29483,
    88.4,
    25.13
  ],
  [
    30224,
    66.94494,
    15.29418,
    87.4,
    25.13
  ],
  [
    30226,
    66.94534,
    15.29363,
    86.5,
    25.16
  ],
  [
    30228,
    66.94576,
    15.29319,
    85.6,
    25.23
  ],
  [
    30230,
    66.94619,
    15.29286,
    84.7,
    25.34
  ],
  [
    30232,
    66.94664,
    15.29264,
    83.8,
    25.48
  ],
  [
    30234,
    66.9471,
    15.29253,
    82.9,
    25.57
  ],
  [
    30236,
    66.94756,
    15.29248,
    82,
    25.62
  ],
  [
    30238,
    66.94803,
    15.29245,
    81.1,
    25.73
  ],
  [
    30240,
    66.94849,
    15.29242,
    80.2,
    25.79
  ],
  [
    30242,
    66.94896,
    15.29241,
    79.3,
    25.95
  ],
  [
    30244,
    66.94943,
    15.2925,
    78.5,
    26.07
  ],
  [
    30246,
    66.94989,
    15.29274,
    77.6,
    26.15
  ],
  [
    30248,
    66.95034,
    15.29313,
    77.1,
    26.27
  ],
  [
    30250,
    66.95078,
    15.29365,
    76.7,
    26.38
  ],
  [
    30252,
    66.9512,
    15.29425,
    76.1,
    26.43
  ],
  [
    30254,
    66.95163,
    15.29484,
    75.1,
    26.46
  ],
  [
    30256,
    66.95206,
    15.29539,
    73.7,
    26.52
  ],
  [
    30258,
    66.95249,
    15.29588,
    72.4,
    26.12
  ],
  [
    30260,
    66.95293,
    15.29632,
    71.4,
    25.72
  ],
  [
    30262,
    66.95336,
    15.29671,
    70.4,
    25.34
  ],
  [
    30264,
    66.95379,
    15.29707,
    69.8,
    25.03
  ],
  [
    30266,
    66.95422,
    15.2974,
    69.8,
    24.78
  ],
  [
    30268,
    66.95465,
    15.2977,
    68.3,
    24.69
  ],
  [
    30271,
    66.9553,
    15.29811,
    67.2,
    24.65
  ],
  [
    30273,
    66.95573,
    15.29835,
    66.4,
    24.73
  ],
  [
    30275,
    66.95617,
    15.29852,
    65.6,
    24.8
  ],
  [
    30277,
    66.95662,
    15.29858,
    64.8,
    24.9
  ],
  [
    30279,
    66.95707,
    15.29852,
    64.2,
    24.99
  ],
  [
    30281,
    66.95752,
    15.29841,
    63.5,
    25.11
  ],
  [
    30283,
    66.95797,
    15.2983,
    62.9,
    25.22
  ],
  [
    30285,
    66.95842,
    15.29824,
    62.3,
    25.3
  ],
  [
    30287,
    66.95887,
    15.29831,
    61.5,
    25.42
  ],
  [
    30289,
    66.95933,
    15.29852,
    60.6,
    25.49
  ],
  [
    30291,
    66.95977,
    15.29881,
    59.9,
    25.57
  ],
  [
    30293,
    66.96022,
    15.29911,
    59.4,
    25.7
  ],
  [
    30295,
    66.96067,
    15.29942,
    59,
    25.77
  ],
  [
    30297,
    66.96112,
    15.29973,
    58.5,
    25.91
  ],
  [
    30299,
    66.96157,
    15.30007,
    58,
    26.01
  ],
  [
    30301,
    66.96202,
    15.30046,
    57.3,
    26.06
  ],
  [
    30303,
    66.96245,
    15.3009,
    56.6,
    25.71
  ],
  [
    30305,
    66.96288,
    15.30134,
    55.9,
    25.28
  ],
  [
    30307,
    66.9633,
    15.30175,
    55.2,
    24.86
  ],
  [
    30309,
    66.96373,
    15.30205,
    54.3,
    24.59
  ],
  [
    30311,
    66.96416,
    15.30224,
    53.4,
    24.48
  ],
  [
    30313,
    66.9646,
    15.30235,
    52.9,
    24.47
  ],
  [
    30315,
    66.96504,
    15.30245,
    51.6,
    24.59
  ],
  [
    30318,
    66.96569,
    15.30264,
    50.5,
    24.69
  ],
  [
    30320,
    66.96613,
    15.30285,
    49.7,
    24.69
  ],
  [
    30322,
    66.96657,
    15.30311,
    48.9,
    24.84
  ],
  [
    30324,
    66.967,
    15.30338,
    48.1,
    24.84
  ],
  [
    30326,
    66.96744,
    15.30364,
    47.2,
    25.03
  ],
  [
    30328,
    66.96788,
    15.30386,
    46.4,
    25.17
  ],
  [
    30330,
    66.96833,
    15.30402,
    45.5,
    25.22
  ],
  [
    30332,
    66.96878,
    15.30416,
    44.7,
    25.39
  ],
  [
    30334,
    66.96924,
    15.30428,
    44.1,
    25.5
  ],
  [
    30336,
    66.96969,
    15.30444,
    43.4,
    25.6
  ],
  [
    30338,
    66.97014,
    15.30466,
    43.1,
    25.38
  ],
  [
    30340,
    66.97058,
    15.30494,
    42.7,
    24.89
  ],
  [
    30342,
    66.97101,
    15.30525,
    42.4,
    24.39
  ],
  [
    30344,
    66.97142,
    15.30556,
    41.6,
    23.9
  ],
  [
    30346,
    66.97183,
    15.30587,
    41.1,
    23.43
  ],
  [
    30348,
    66.97222,
    15.30617,
    40.8,
    22.98
  ],
  [
    30350,
    66.97261,
    15.30646,
    40.5,
    22.51
  ],
  [
    30352,
    66.973,
    15.30675,
    40.3,
    21.99
  ],
  [
    30354,
    66.97337,
    15.30703,
    40.1,
    21.46
  ],
  [
    30356,
    66.97373,
    15.3073,
    39.9,
    20.91
  ],
  [
    30358,
    66.97408,
    15.30756,
    39.7,
    20.35
  ],
  [
    30360,
    66.97443,
    15.30782,
    39.4,
    19.8
  ],
  [
    30362,
    66.97476,
    15.30807,
    39.1,
    19.23
  ],
  [
    30364,
    66.97509,
    15.30831,
    38.8,
    18.68
  ],
  [
    30366,
    66.9754,
    15.30855,
    38.8,
    18.12
  ],
  [
    30368,
    66.9757,
    15.30878,
    38.1,
    17.64
  ],
  [
    30371,
    66.97615,
    15.30912,
    37.6,
    17.42
  ],
  [
    30373,
    66.97645,
    15.30934,
    37.2,
    17.36
  ],
  [
    30375,
    66.97674,
    15.30956,
    36.7,
    17.36
  ],
  [
    30377,
    66.97704,
    15.30978,
    36.3,
    17.32
  ],
  [
    30379,
    66.97734,
    15.31,
    35.9,
    17.39
  ],
  [
    30381,
    66.97763,
    15.31023,
    35.5,
    17.25
  ],
  [
    30383,
    66.97792,
    15.31045,
    35,
    16.76
  ],
  [
    30385,
    66.97821,
    15.31066,
    34.6,
    16.47
  ],
  [
    30387,
    66.97848,
    15.31086,
    34.2,
    15.57
  ],
  [
    30389,
    66.97874,
    15.31105,
    33.8,
    14.99
  ],
  [
    30391,
    66.97899,
    15.31124,
    33.4,
    14.45
  ],
  [
    30393,
    66.97923,
    15.31142,
    33.1,
    13.93
  ],
  [
    30395,
    66.97947,
    15.3116,
    32.9,
    13.67
  ],
  [
    30397,
    66.9797,
    15.31177,
    32.6,
    13.53
  ],
  [
    30399,
    66.97993,
    15.31195,
    32.4,
    13.42
  ],
  [
    30401,
    66.98015,
    15.31212,
    32.2,
    13.21
  ],
  [
    30403,
    66.98037,
    15.31228,
    32,
    12.56
  ],
  [
    30405,
    66.98058,
    15.31244,
    31.8,
    11.75
  ],
  [
    30407,
    66.98077,
    15.31258,
    31.7,
    10.9
  ],
  [
    30409,
    66.98095,
    15.31271,
    31.5,
    10.04
  ],
  [
    30411,
    66.98112,
    15.31284,
    31.3,
    9.32
  ],
  [
    30413,
    66.98127,
    15.31295,
    31.2,
    8.69
  ],
  [
    30415,
    66.98141,
    15.31306,
    31,
    8.09
  ],
  [
    30417,
    66.98154,
    15.31315,
    31,
    7.51
  ],
  [
    30419,
    66.98166,
    15.31324,
    30.6,
    6.94
  ],
  [
    30422,
    66.98183,
    15.31337,
    30.4,
    6.47
  ],
  [
    30424,
    66.98193,
    15.31345,
    30.2,
    6.26
  ],
  [
    30426,
    66.98203,
    15.31352,
    30.3,
    5.87
  ],
  [
    30428,
    66.98212,
    15.31358,
    30,
    5.87
  ],
  [
    30430,
    66.98221,
    15.31364,
    29.7,
    4.94
  ],
  [
    30432,
    66.98227,
    15.31369,
    29.4,
    3.89
  ],
  [
    30434,
    66.98232,
    15.31372,
    29.1,
    2.38
  ],
  [
    30436,
    66.98235,
    15.31374,
    28.9,
    1.56
  ],
  [
    30438,
    66.98236,
    15.31376,
    28.7,
    0.8
  ],
  [
    30440,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30442,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30444,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30446,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30448,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30450,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30452,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30454,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30456,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30458,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30460,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30462,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30464,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30466,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30468,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30470,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30472,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30475,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30477,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30479,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30481,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30483,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30485,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30487,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30489,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30491,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30493,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30495,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30497,
    66.98237,
    15.31376,
    28.6,
    0
  ],
  [
    30499,
    66.98235,
    15.31374,
    26.9,
    1.45
  ],
  [
    30501,
    66.98238,
    15.31376,
    26.6,
    2.16
  ],
  [
    30503,
    66.98242,
    15.3138,
    26.3,
    2.91
  ],
  [
    30505,
    66.98248,
    15.31384,
    26.1,
    3.64
  ],
  [
    30507,
    66.98255,
    15.3139,
    25.9,
    4.37
  ],
  [
    30509,
    66.98263,
    15.31396,
    25.8,
    5.01
  ],
  [
    30511,
    66.98272,
    15.31404,
    25.6,
    5.57
  ],
  [
    30513,
    66.98281,
    15.31413,
    25.5,
    6.11
  ],
  [
    30515,
    66.98292,
    15.31424,
    25.5,
    6.64
  ],
  [
    30517,
    66.98304,
    15.31436,
    25.4,
    7.14
  ],
  [
    30519,
    66.98316,
    15.31449,
    25.3,
    7.63
  ],
  [
    30521,
    66.98329,
    15.31464,
    25.3,
    8.12
  ],
  [
    30523,
    66.98342,
    15.31482,
    25.3,
    8.61
  ],
  [
    30526,
    66.98363,
    15.31511,
    25.3,
    9.1
  ],
  [
    30528,
    66.98378,
    15.31533,
    25.3,
    9.34
  ],
  [
    30530,
    66.98394,
    15.31558,
    25.3,
    9.34
  ],
  [
    30532,
    66.98409,
    15.31586,
    25.3,
    10.3
  ],
  [
    30534,
    66.98425,
    15.31616,
    25.3,
    11.02
  ],
  [
    30536,
    66.98442,
    15.31647,
    25.2,
    11.49
  ],
  [
    30538,
    66.98459,
    15.31681,
    25.1,
    12.18
  ],
  [
    30540,
    66.98476,
    15.31717,
    25.1,
    12.56
  ],
  [
    30542,
    66.98495,
    15.31755,
    25.1,
    13.46
  ],
  [
    30544,
    66.98514,
    15.31795,
    25.1,
    14
  ],
  [
    30546,
    66.98534,
    15.31836,
    25.1,
    14.53
  ],
  [
    30548,
    66.98555,
    15.31879,
    25.1,
    15.04
  ],
  [
    30550,
    66.98577,
    15.31923,
    25.1,
    15.52
  ],
  [
    30552,
    66.98599,
    15.31969,
    25.1,
    15.98
  ],
  [
    30554,
    66.98621,
    15.32016,
    25.2,
    16.41
  ],
  [
    30556,
    66.98645,
    15.32064,
    25.2,
    16.83
  ],
  [
    30558,
    66.98669,
    15.32113,
    25.3,
    17.27
  ],
  [
    30560,
    66.98693,
    15.32163,
    25.3,
    17.74
  ],
  [
    30562,
    66.98719,
    15.32215,
    25.4,
    18.23
  ],
  [
    30564,
    66.98745,
    15.32268,
    25.4,
    18.72
  ],
  [
    30566,
    66.98771,
    15.32323,
    24.8,
    19.16
  ],
  [
    30568,
    66.98798,
    15.32379,
    24.4,
    19.58
  ],
  [
    30570,
    66.98826,
    15.32436,
    24.2,
    20
  ],
  [
    30572,
    66.98854,
    15.32494,
    24.2,
    20.41
  ],
  [
    30574,
    66.98883,
    15.32553,
    23.3,
    20.81
  ],
  [
    30577,
    66.98928,
    15.32644,
    22.8,
    21.2
  ],
  [
    30579,
    66.98958,
    15.32706,
    22.7,
    21.39
  ],
  [
    30581,
    66.98989,
    15.32769,
    22.6,
    21.95
  ],
  [
    30583,
    66.99021,
    15.32833,
    22.6,
    21.95
  ],
  [
    30585,
    66.99053,
    15.32898,
    22.9,
    22.68
  ],
  [
    30587,
    66.99085,
    15.32964,
    23.1,
    23.22
  ],
  [
    30589,
    66.99118,
    15.33031,
    23.1,
    23.6
  ],
  [
    30591,
    66.99152,
    15.33099,
    23.1,
    23.97
  ],
  [
    30593,
    66.99186,
    15.33168,
    23,
    24.36
  ],
  [
    30595,
    66.99221,
    15.33239,
    22.9,
    24.56
  ],
  [
    30597,
    66.99256,
    15.3331,
    22.7,
    25.18
  ],
  [
    30599,
    66.99292,
    15.33382,
    22.2,
    25.58
  ],
  [
    30601,
    66.99328,
    15.33456,
    21.9,
    25.97
  ],
  [
    30603,
    66.99365,
    15.33531,
    21.6,
    26.37
  ],
  [
    30605,
    66.99402,
    15.33608,
    21.5,
    26.74
  ],
  [
    30607,
    66.9944,
    15.33685,
    21.3,
    27.14
  ],
  [
    30609,
    66.99478,
    15.33764,
    20.8,
    27.48
  ],
  [
    30611,
    66.99517,
    15.33843,
    20.4,
    27.86
  ],
  [
    30613,
    66.99557,
    15.33923,
    20,
    28.21
  ],
  [
    30615,
    66.99597,
    15.34004,
    19.6,
    28.53
  ],
  [
    30617,
    66.99637,
    15.34087,
    19.5,
    28.79
  ],
  [
    30619,
    66.99678,
    15.3417,
    19.4,
    28.93
  ],
  [
    30621,
    66.99718,
    15.34253,
    19.4,
    28.98
  ],
  [
    30623,
    66.99759,
    15.34336,
    19.4,
    29
  ],
  [
    30625,
    66.998,
    15.34419,
    19.4,
    28.99
  ],
  [
    30628,
    66.99861,
    15.34543,
    19.4,
    28.92
  ],
  [
    30630,
    66.99902,
    15.34624,
    19.4,
    28.88
  ],
  [
    30632,
    66.99945,
    15.34698,
    19.7,
    28.8
  ],
  [
    30634,
    66.9999,
    15.34762,
    20.1,
    28.8
  ],
  [
    30636,
    67.00037,
    15.34815,
    20.3,
    28.58
  ],
  [
    30638,
    67.00085,
    15.34857,
    20.6,
    28.49
  ],
  [
    30640,
    67.00135,
    15.34885,
    21.1,
    28.38
  ],
  [
    30642,
    67.00186,
    15.34898,
    21.6,
    28.29
  ],
  [
    30644,
    67.00237,
    15.34897,
    22,
    28.17
  ],
  [
    30646,
    67.00287,
    15.34881,
    22.1,
    28.07
  ],
  [
    30648,
    67.00336,
    15.34852,
    21.9,
    28
  ],
  [
    30650,
    67.00385,
    15.34818,
    21.6,
    27.98
  ],
  [
    30652,
    67.00433,
    15.34783,
    21.3,
    27.92
  ],
  [
    30654,
    67.00482,
    15.34752,
    20.9,
    27.89
  ],
  [
    30656,
    67.00531,
    15.34726,
    20.4,
    27.86
  ],
  [
    30658,
    67.0058,
    15.34707,
    20.2,
    27.8
  ],
  [
    30660,
    67.0063,
    15.34692,
    20,
    27.87
  ],
  [
    30662,
    67.0068,
    15.34684,
    20,
    27.76
  ],
  [
    30664,
    67.0073,
    15.34678,
    19.9,
    27.76
  ],
  [
    30666,
    67.0078,
    15.34675,
    19.9,
    27.74
  ],
  [
    30668,
    67.0083,
    15.34675,
    19.5,
    27.8
  ],
  [
    30670,
    67.0088,
    15.34682,
    19.7,
    27.77
  ],
  [
    30672,
    67.00929,
    15.34692,
    19.7,
    27.79
  ],
  [
    30674,
    67.00979,
    15.34703,
    19.7,
    27.85
  ],
  [
    30676,
    67.01029,
    15.34713,
    19.7,
    27.91
  ],
  [
    30678,
    67.01079,
    15.34722,
    19.1,
    28.03
  ],
  [
    30680,
    67.01129,
    15.34727,
    18.7,
    28.17
  ],
  [
    30683,
    67.01206,
    15.34726,
    17.8,
    28.3
  ],
  [
    30685,
    67.01257,
    15.34716,
    17.2,
    28.3
  ],
  [
    30687,
    67.01307,
    15.34692,
    16.9,
    28.45
  ],
  [
    30689,
    67.01357,
    15.34657,
    16.5,
    28.59
  ],
  [
    30691,
    67.01406,
    15.34615,
    16.2,
    28.64
  ],
  [
    30693,
    67.01455,
    15.34572,
    15.9,
    28.65
  ],
  [
    30695,
    67.01504,
    15.3453,
    15.6,
    28.65
  ],
  [
    30697,
    67.01553,
    15.34489,
    15.6,
    28.6
  ],
  [
    30699,
    67.01602,
    15.34452,
    15.6,
    28.59
  ],
  [
    30701,
    67.01652,
    15.34426,
    15.5,
    28.54
  ],
  [
    30703,
    67.01703,
    15.34415,
    15.5,
    28.46
  ],
  [
    30705,
    67.01754,
    15.34417,
    15.5,
    28.47
  ],
  [
    30707,
    67.01805,
    15.34433,
    15.5,
    28.51
  ],
  [
    30709,
    67.01855,
    15.34462,
    15.5,
    28.54
  ],
  [
    30711,
    67.01903,
    15.34505,
    15.6,
    28.59
  ],
  [
    30713,
    67.0195,
    15.3456,
    15.6,
    28.63
  ],
  [
    30715,
    67.01994,
    15.34628,
    15.6,
    28.73
  ],
  [
    30717,
    67.02036,
    15.34704,
    15.5,
    28.83
  ],
  [
    30719,
    67.02078,
    15.34783,
    15.6,
    28.99
  ],
  [
    30721,
    67.0212,
    15.34863,
    15.6,
    29.17
  ],
  [
    30723,
    67.02163,
    15.34942,
    15.7,
    29.35
  ],
  [
    30725,
    67.02205,
    15.35023,
    15.8,
    29.52
  ],
  [
    30727,
    67.02248,
    15.35103,
    15.8,
    29.71
  ],
  [
    30729,
    67.02291,
    15.35185,
    15.7,
    29.9
  ],
  [
    30731,
    67.02335,
    15.35266,
    15.7,
    30.1
  ],
  [
    30734,
    67.024,
    15.3539,
    15.6,
    30.31
  ],
  [
    30736,
    67.02445,
    15.35473,
    15.4,
    30.53
  ],
  [
    30738,
    67.02489,
    15.35556,
    15.3,
    30.53
  ],
  [
    30740,
    67.02534,
    15.3564,
    15.2,
    30.53
  ],
  [
    30742,
    67.02579,
    15.35725,
    15.2,
    31.13
  ],
  [
    30744,
    67.02625,
    15.3581,
    15.4,
    31.39
  ],
  [
    30746,
    67.0267,
    15.35896,
    15.6,
    31.51
  ],
  [
    30748,
    67.02716,
    15.35981,
    16,
    31.59
  ],
  [
    30750,
    67.02762,
    15.36067,
    16.5,
    31.61
  ],
  [
    30752,
    67.02808,
    15.36153,
    17.2,
    31.65
  ],
  [
    30754,
    67.02854,
    15.36239,
    18,
    31.6
  ],
  [
    30756,
    67.029,
    15.36321,
    18.8,
    31.56
  ],
  [
    30758,
    67.02949,
    15.36396,
    19.3,
    31.56
  ],
  [
    30760,
    67.02999,
    15.36463,
    19.5,
    31.57
  ],
  [
    30762,
    67.03051,
    15.36523,
    19.6,
    31.69
  ],
  [
    30764,
    67.03103,
    15.36581,
    19.7,
    31.79
  ],
  [
    30766,
    67.03155,
    15.3664,
    19.8,
    31.87
  ],
  [
    30768,
    67.03208,
    15.36699,
    19.8,
    31.86
  ],
  [
    30770,
    67.0326,
    15.36757,
    19.5,
    31.84
  ],
  [
    30772,
    67.03312,
    15.36816,
    19,
    31.85
  ],
  [
    30774,
    67.03365,
    15.36875,
    18.3,
    31.94
  ],
  [
    30776,
    67.03417,
    15.36934,
    17.5,
    32.06
  ],
  [
    30778,
    67.0347,
    15.36993,
    17.1,
    32.21
  ],
  [
    30780,
    67.03523,
    15.37052,
    16,
    32.35
  ],
  [
    30782,
    67.03576,
    15.37112,
    15.3,
    32.5
  ],
  [
    30785,
    67.03657,
    15.37203,
    14.7,
    32.59
  ],
  [
    30787,
    67.0371,
    15.37264,
    14.4,
    32.63
  ],
  [
    30789,
    67.03763,
    15.37328,
    14.2,
    32.63
  ],
  [
    30791,
    67.03816,
    15.37395,
    14.1,
    32.69
  ],
  [
    30793,
    67.03868,
    15.37464,
    14.2,
    32.65
  ],
  [
    30795,
    67.03919,
    15.37535,
    14.2,
    32.58
  ],
  [
    30797,
    67.03971,
    15.37605,
    14.3,
    32.45
  ],
  [
    30799,
    67.04022,
    15.37675,
    14.4,
    32.36
  ],
  [
    30801,
    67.04074,
    15.37742,
    14.4,
    32.28
  ],
  [
    30803,
    67.04126,
    15.37806,
    14.4,
    32.18
  ],
  [
    30805,
    67.04178,
    15.37868,
    14.4,
    32.11
  ],
  [
    30807,
    67.04231,
    15.37927,
    14.3,
    32.04
  ],
  [
    30809,
    67.04284,
    15.37983,
    14.2,
    31.99
  ],
  [
    30811,
    67.04338,
    15.38037,
    14.1,
    31.95
  ],
  [
    30813,
    67.04391,
    15.38088,
    14.1,
    31.94
  ],
  [
    30815,
    67.04445,
    15.38137,
    14,
    31.94
  ],
  [
    30817,
    67.045,
    15.38183,
    14,
    31.95
  ],
  [
    30819,
    67.04554,
    15.38227,
    13.9,
    31.93
  ],
  [
    30821,
    67.04609,
    15.38268,
    13.8,
    31.95
  ],
  [
    30823,
    67.04665,
    15.38306,
    13.7,
    31.97
  ],
  [
    30825,
    67.04721,
    15.38341,
    13.5,
    32.01
  ],
  [
    30827,
    67.04777,
    15.38374,
    13.3,
    32.03
  ],
  [
    30829,
    67.04833,
    15.38405,
    13.3,
    32.05
  ],
  [
    30831,
    67.04889,
    15.38432,
    13.2,
    32.11
  ],
  [
    30834,
    67.04975,
    15.38469,
    13,
    32.1
  ],
  [
    30836,
    67.05032,
    15.3849,
    12.9,
    32.12
  ],
  [
    30838,
    67.05089,
    15.38508,
    12.8,
    32.12
  ],
  [
    30840,
    67.05146,
    15.38524,
    12.7,
    32.12
  ],
  [
    30842,
    67.05204,
    15.38537,
    12.6,
    32.16
  ],
  [
    30844,
    67.05261,
    15.38548,
    12.4,
    32.2
  ],
  [
    30846,
    67.05319,
    15.38559,
    12.3,
    32.21
  ],
  [
    30848,
    67.05377,
    15.3857,
    12.3,
    32.21
  ],
  [
    30850,
    67.05434,
    15.3858,
    12.2,
    32.21
  ],
  [
    30852,
    67.05492,
    15.38591,
    12.2,
    32.21
  ],
  [
    30854,
    67.0555,
    15.38601,
    12.1,
    32.18
  ],
  [
    30856,
    67.05607,
    15.38607,
    12.1,
    32.16
  ],
  [
    30858,
    67.05665,
    15.38606,
    12.1,
    32.14
  ],
  [
    30860,
    67.05723,
    15.38598,
    12,
    32.13
  ],
  [
    30862,
    67.0578,
    15.38584,
    11.9,
    32.11
  ],
  [
    30864,
    67.05837,
    15.38565,
    11.7,
    32.09
  ],
  [
    30866,
    67.05894,
    15.38545,
    11.6,
    32.09
  ],
  [
    30868,
    67.05951,
    15.38526,
    11.4,
    32.09
  ],
  [
    30870,
    67.06008,
    15.38506,
    11.3,
    32.11
  ],
  [
    30872,
    67.06066,
    15.38486,
    11.1,
    32.12
  ],
  [
    30874,
    67.06123,
    15.38466,
    10.9,
    32.14
  ],
  [
    30876,
    67.0618,
    15.38446,
    10.7,
    32.18
  ],
  [
    30878,
    67.06237,
    15.38426,
    10.4,
    32.23
  ],
  [
    30880,
    67.06295,
    15.38407,
    10.4,
    32.3
  ],
  [
    30882,
    67.06352,
    15.38387,
    10,
    32.36
  ],
  [
    30885,
    67.06439,
    15.38357,
    9.7,
    32.43
  ],
  [
    30887,
    67.06497,
    15.38337,
    9.5,
    32.46
  ],
  [
    30889,
    67.06555,
    15.38316,
    9.5,
    32.46
  ],
  [
    30891,
    67.06613,
    15.38296,
    9.4,
    32.62
  ],
  [
    30893,
    67.06671,
    15.38276,
    9.2,
    32.66
  ],
  [
    30895,
    67.06729,
    15.38256,
    9,
    32.7
  ],
  [
    30897,
    67.06787,
    15.38236,
    8.9,
    32.74
  ],
  [
    30899,
    67.06845,
    15.38216,
    8.6,
    32.75
  ],
  [
    30901,
    67.06904,
    15.38196,
    8.5,
    32.74
  ],
  [
    30903,
    67.06962,
    15.38176,
    8.3,
    32.7
  ],
  [
    30905,
    67.0702,
    15.38156,
    8.3,
    32.67
  ],
  [
    30907,
    67.07078,
    15.38136,
    8.2,
    32.56
  ],
  [
    30909,
    67.07136,
    15.38117,
    8.2,
    32.49
  ],
  [
    30911,
    67.07193,
    15.38097,
    8.2,
    32.39
  ],
  [
    30913,
    67.07251,
    15.38078,
    8.3,
    32.03
  ],
  [
    30915,
    67.07307,
    15.38058,
    8.3,
    31.4
  ],
  [
    30917,
    67.07362,
    15.38039,
    8.4,
    30.67
  ],
  [
    30919,
    67.07416,
    15.38021,
    8.4,
    29.95
  ],
  [
    30921,
    67.07468,
    15.38003,
    8.4,
    29.23
  ],
  [
    30923,
    67.0752,
    15.37985,
    8.1,
    28.75
  ],
  [
    30925,
    67.07571,
    15.37968,
    7.8,
    28.53
  ],
  [
    30927,
    67.07621,
    15.3795,
    7.5,
    28.41
  ],
  [
    30929,
    67.07672,
    15.37933,
    7.2,
    28.35
  ],
  [
    30931,
    67.07722,
    15.37916,
    7,
    28.3
  ],
  [
    30933,
    67.07772,
    15.37899,
    6.6,
    28.29
  ],
  [
    30936,
    67.07847,
    15.37873,
    6.2,
    28.27
  ],
  [
    30938,
    67.07897,
    15.37856,
    5.9,
    28.27
  ],
  [
    30940,
    67.07948,
    15.37839,
    5.7,
    28.27
  ],
  [
    30942,
    67.07997,
    15.37822,
    5.6,
    28.05
  ],
  [
    30944,
    67.08046,
    15.37805,
    5.5,
    27.46
  ],
  [
    30946,
    67.08094,
    15.37789,
    5.5,
    26.41
  ],
  [
    30948,
    67.0814,
    15.37773,
    5.5,
    25.65
  ],
  [
    30950,
    67.08185,
    15.3776,
    5.6,
    25.27
  ],
  [
    30952,
    67.08228,
    15.37753,
    5.6,
    24.16
  ],
  [
    30954,
    67.08271,
    15.37757,
    5.6,
    23.39
  ],
  [
    30956,
    67.08312,
    15.3777,
    5.6,
    22.63
  ],
  [
    30958,
    67.0835,
    15.37792,
    5.6,
    21.86
  ],
  [
    30960,
    67.08387,
    15.37822,
    5.7,
    21.1
  ],
  [
    30962,
    67.08421,
    15.37858,
    5.8,
    20.36
  ],
  [
    30964,
    67.08453,
    15.37899,
    6.1,
    19.65
  ],
  [
    30966,
    67.08484,
    15.37941,
    6.4,
    18.99
  ],
  [
    30968,
    67.08513,
    15.37982,
    6.8,
    18.58
  ],
  [
    30970,
    67.08542,
    15.38022,
    7.2,
    17.64
  ],
  [
    30972,
    67.0857,
    15.3806,
    7.2,
    17.92
  ],
  [
    30974,
    67.08599,
    15.381,
    7.6,
    17.72
  ],
  [
    30976,
    67.08626,
    15.38139,
    7.9,
    17.51
  ],
  [
    30978,
    67.08654,
    15.38177,
    8.3,
    17.31
  ],
  [
    30980,
    67.08681,
    15.38215,
    8.5,
    17.11
  ],
  [
    30982,
    67.08708,
    15.38252,
    8.5,
    16.95
  ],
  [
    30984,
    67.08734,
    15.38289,
    8.7,
    16.81
  ],
  [
    30987,
    67.08774,
    15.38344,
    8.7,
    16.71
  ],
  [
    30989,
    67.088,
    15.38381,
    8.8,
    16.71
  ],
  [
    30991,
    67.08826,
    15.38416,
    8.9,
    16.13
  ],
  [
    30993,
    67.0885,
    15.3845,
    8.9,
    16.13
  ],
  [
    30995,
    67.08873,
    15.38482,
    9,
    14.75
  ],
  [
    30997,
    67.08895,
    15.38512,
    9,
    13.58
  ],
  [
    30999,
    67.08916,
    15.38541,
    9,
    12.83
  ],
  [
    31001,
    67.08936,
    15.38568,
    8.9,
    12.17
  ],
  [
    31003,
    67.08954,
    15.38594,
    8.8,
    11.59
  ],
  [
    31005,
    67.08972,
    15.38618,
    8.8,
    11.33
  ],
  [
    31007,
    67.08989,
    15.38643,
    8.7,
    11.02
  ],
  [
    31009,
    67.09006,
    15.38669,
    8.7,
    10.95
  ],
  [
    31011,
    67.09023,
    15.38696,
    8.7,
    11
  ],
  [
    31013,
    67.09039,
    15.38724,
    8.7,
    11.07
  ],
  [
    31015,
    67.09055,
    15.38755,
    8.7,
    11.15
  ],
  [
    31017,
    67.0907,
    15.38788,
    8.6,
    11.24
  ],
  [
    31019,
    67.09085,
    15.38823,
    8.6,
    11.31
  ],
  [
    31021,
    67.091,
    15.38859,
    8.5,
    11.39
  ],
  [
    31023,
    67.09113,
    15.38898,
    8.4,
    11.48
  ],
  [
    31025,
    67.09127,
    15.38938,
    8.1,
    11.57
  ],
  [
    31027,
    67.09139,
    15.38981,
    8.1,
    11.66
  ],
  [
    31029,
    67.09151,
    15.39025,
    7.9,
    11.75
  ],
  [
    31031,
    67.09163,
    15.39071,
    7.7,
    11.83
  ],
  [
    31033,
    67.09174,
    15.39117,
    7.7,
    11.93
  ],
  [
    31035,
    67.09185,
    15.39165,
    7.4,
    12.03
  ],
  [
    31038,
    67.09201,
    15.39237,
    7.5,
    12.14
  ],
  [
    31040,
    67.09212,
    15.39286,
    7.4,
    12.2
  ],
  [
    31042,
    67.09223,
    15.39335,
    7.2,
    12.29
  ],
  [
    31044,
    67.09234,
    15.39384,
    7,
    12.29
  ],
  [
    31046,
    67.09245,
    15.39434,
    6.7,
    12.45
  ],
  [
    31048,
    67.09257,
    15.39483,
    6.5,
    12.36
  ],
  [
    31050,
    67.09267,
    15.39531,
    6.3,
    12.03
  ],
  [
    31052,
    67.09278,
    15.39577,
    6.1,
    11.15
  ],
  [
    31054,
    67.09287,
    15.3962,
    5.9,
    10.48
  ],
  [
    31056,
    67.09297,
    15.3966,
    5.9,
    9.92
  ],
  [
    31058,
    67.09305,
    15.39698,
    5.9,
    9.44
  ],
  [
    31060,
    67.09314,
    15.39735,
    5.7,
    9
  ],
  [
    31062,
    67.09321,
    15.3977,
    5.5,
    8.61
  ],
  [
    31064,
    67.09329,
    15.39803,
    5.4,
    8.27
  ],
  [
    31066,
    67.09336,
    15.39835,
    5.4,
    7.93
  ],
  [
    31068,
    67.09343,
    15.39866,
    5.4,
    7.55
  ],
  [
    31070,
    67.0935,
    15.39894,
    5.2,
    7
  ],
  [
    31072,
    67.09356,
    15.39921,
    5.2,
    6.45
  ],
  [
    31074,
    67.09361,
    15.39945,
    5.1,
    5.93
  ],
  [
    31076,
    67.09366,
    15.39967,
    5,
    5.32
  ],
  [
    31078,
    67.09371,
    15.39986,
    4.9,
    4.52
  ],
  [
    31080,
    67.09374,
    15.40002,
    4.8,
    3.58
  ],
  [
    31082,
    67.09377,
    15.40014,
    4.6,
    2.55
  ],
  [
    31084,
    67.09378,
    15.40022,
    4.5,
    1.45
  ],
  [
    31086,
    67.09379,
    15.40025,
    4.1,
    0.5
  ],
  [
    31088,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31091,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31093,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31095,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31097,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31099,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31101,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31103,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31105,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31107,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31109,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31111,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31113,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31115,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31117,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31119,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31121,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31123,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31125,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31127,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31129,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31131,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31133,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31135,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31137,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31139,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31142,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31144,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31146,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31148,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31150,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31152,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31154,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31156,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31158,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31160,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31162,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31164,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31166,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31168,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31170,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31172,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31174,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31176,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31178,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31180,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31182,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31184,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31186,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31188,
    67.09379,
    15.40026,
    3.9,
    0
  ],
  [
    31190,
    67.09376,
    15.40037,
    3.9,
    1.73
  ],
  [
    31192,
    67.09378,
    15.40045,
    1.6,
    2.26
  ],
  [
    31195,
    67.09382,
    15.40061,
    1.5,
    2.97
  ],
  [
    31197,
    67.09385,
    15.40076,
    1.4,
    3.32
  ],
  [
    31199,
    67.09389,
    15.40093,
    1.4,
    3.32
  ],
  [
    31201,
    67.09394,
    15.40112,
    1.4,
    4.9
  ],
  [
    31203,
    67.09399,
    15.40134,
    1.4,
    5.44
  ],
  [
    31205,
    67.09404,
    15.40158,
    1.3,
    5.94
  ],
  [
    31207,
    67.0941,
    15.40183,
    1.1,
    6.63
  ],
  [
    31209,
    67.09416,
    15.40211,
    0.8,
    7.09
  ],
  [
    31211,
    67.09423,
    15.4024,
    0.7,
    7.55
  ],
  [
    31213,
    67.0943,
    15.40271,
    0.5,
    8
  ],
  [
    31215,
    67.09437,
    15.40304,
    0.2,
    8.22
  ],
  [
    31217,
    67.09445,
    15.40338,
    0.1,
    8.9
  ],
  [
    31219,
    67.09453,
    15.40375,
    -0.1,
    9.36
  ],
  [
    31221,
    67.09462,
    15.40413,
    -0.3,
    9.78
  ],
  [
    31223,
    67.09471,
    15.40453,
    -0.5,
    10.24
  ],
  [
    31225,
    67.09481,
    15.40495,
    -0.7,
    10.66
  ],
  [
    31227,
    67.09491,
    15.40538,
    -0.9,
    11.06
  ],
  [
    31229,
    67.09501,
    15.40583,
    -1.1,
    11.36
  ],
  [
    31231,
    67.09511,
    15.40628,
    -1.4,
    11.59
  ],
  [
    31233,
    67.09522,
    15.40675,
    -1.7,
    11.82
  ],
  [
    31235,
    67.09533,
    15.40723,
    -1.9,
    12.04
  ],
  [
    31237,
    67.09544,
    15.40771,
    -2.2,
    12.31
  ],
  [
    31239,
    67.09555,
    15.40821,
    -2.3,
    12.57
  ],
  [
    31241,
    67.09567,
    15.40871,
    -2.3,
    12.87
  ],
  [
    31243,
    67.09579,
    15.40923,
    -2.3,
    13.19
  ],
  [
    31246,
    67.09597,
    15.41004,
    -2.5,
    13.54
  ],
  [
    31248,
    67.0961,
    15.41059,
    -2.5,
    13.75
  ],
  [
    31250,
    67.09623,
    15.41117,
    -2.5,
    13.75
  ],
  [
    31252,
    67.09637,
    15.41176,
    -2.3,
    14.69
  ],
  [
    31254,
    67.09651,
    15.41237,
    -2.4,
    15.37
  ],
  [
    31256,
    67.09665,
    15.413,
    -2.3,
    15.98
  ],
  [
    31258,
    67.0968,
    15.41365,
    -2.6,
    16.34
  ],
  [
    31260,
    67.09695,
    15.41431,
    -2.7,
    16.5
  ],
  [
    31262,
    67.0971,
    15.41498,
    -2.6,
    16.94
  ],
  [
    31264,
    67.09725,
    15.41566,
    -2.4,
    17.2
  ],
  [
    31266,
    67.09741,
    15.41635,
    -2.2,
    17.44
  ],
  [
    31268,
    67.09757,
    15.41705,
    -2,
    17.67
  ],
  [
    31270,
    67.09773,
    15.41775,
    -1.8,
    17.9
  ],
  [
    31272,
    67.0979,
    15.41847,
    -1.7,
    18.12
  ],
  [
    31274,
    67.09807,
    15.4192,
    -1.4,
    18.35
  ],
  [
    31276,
    67.09823,
    15.41993,
    -1.3,
    18.58
  ],
  [
    31278,
    67.0984,
    15.42067,
    -0.6,
    18.65
  ],
  [
    31280,
    67.09858,
    15.42142,
    0,
    18.92
  ],
  [
    31282,
    67.09875,
    15.42218,
    0.5,
    19.09
  ],
  [
    31284,
    67.09892,
    15.42295,
    0.7,
    19.26
  ],
  [
    31286,
    67.09907,
    15.42375,
    0.9,
    19.42
  ],
  [
    31288,
    67.0992,
    15.42458,
    1.3,
    19.55
  ],
  [
    31290,
    67.09931,
    15.42544,
    1.8,
    19.7
  ],
  [
    31292,
    67.09939,
    15.42633,
    2.3,
    19.85
  ],
  [
    31294,
    67.09945,
    15.42724,
    2.3,
    20.02
  ],
  [
    31296,
    67.09947,
    15.42817,
    2.8,
    20.18
  ],
  [
    31299,
    67.09948,
    15.42957,
    2.8,
    20.39
  ],
  [
    31301,
    67.09947,
    15.43053,
    2.8,
    20.59
  ],
  [
    31303,
    67.09946,
    15.43149,
    2.9,
    20.59
  ],
  [
    31305,
    67.09946,
    15.43245,
    3,
    20.89
  ],
  [
    31306,
    67.09946,
    15.43293,
    3,
    20.89
  ],
  [
    31327,
    67.09858,
    15.44393,
    4.2,
    24.06
  ],
  [
    31329,
    67.09841,
    15.44494,
    7.1,
    24.12
  ],
  [
    31331,
    67.09822,
    15.44595,
    8.1,
    24.17
  ],
  [
    31333,
    67.09802,
    15.44689,
    9.1,
    23.4
  ],
  [
    31335,
    67.09782,
    15.44784,
    9.5,
    23.33
  ],
  [
    31337,
    67.09762,
    15.44879,
    9.7,
    23.33
  ],
  [
    31340,
    67.09696,
    15.44946,
    10.6,
    24.13
  ],
  [
    31342,
    67.09655,
    15.44997,
    12.1,
    24
  ],
  [
    31344,
    67.09618,
    15.45063,
    16.6,
    24.04
  ],
  [
    31346,
    67.09584,
    15.4513,
    19.1,
    24.09
  ],
  [
    31348,
    67.09553,
    15.45206,
    20.2,
    24.15
  ],
  [
    31350,
    67.09524,
    15.45293,
    20.8,
    24.21
  ],
  [
    31352,
    67.095,
    15.45387,
    21.2,
    24.26
  ],
  [
    31354,
    67.09481,
    15.45488,
    21.8,
    24.3
  ],
  [
    31356,
    67.09466,
    15.45594,
    22.1,
    24.37
  ],
  [
    31358,
    67.09457,
    15.45705,
    22.2,
    24.44
  ],
  [
    31360,
    67.09453,
    15.45818,
    22.2,
    24.54
  ],
  [
    31362,
    67.09454,
    15.45931,
    22.1,
    24.62
  ],
  [
    31365,
    67.09467,
    15.46099,
    21.6,
    24.69
  ],
  [
    31367,
    67.09481,
    15.46208,
    21.1,
    24.75
  ],
  [
    31369,
    67.095,
    15.46312,
    20.6,
    24.75
  ],
  [
    31371,
    67.09521,
    15.46414,
    20,
    25
  ],
  [
    31373,
    67.09545,
    15.46514,
    19.3,
    25.09
  ],
  [
    31375,
    67.0957,
    15.46611,
    18.7,
    25.2
  ],
  [
    31377,
    67.09597,
    15.46705,
    17.9,
    25.38
  ],
  [
    31379,
    67.09626,
    15.46795,
    17.1,
    25.51
  ],
  [
    31381,
    67.09657,
    15.46882,
    15.9,
    25.64
  ],
  [
    31383,
    67.0969,
    15.46966,
    15.1,
    25.79
  ],
  [
    31385,
    67.09725,
    15.47045,
    14.3,
    25.88
  ],
  [
    31387,
    67.09762,
    15.47121,
    13.4,
    26.05
  ],
  [
    31389,
    67.098,
    15.47189,
    12.7,
    26.09
  ],
  [
    31391,
    67.09842,
    15.47246,
    12.3,
    26.15
  ],
  [
    31393,
    67.09886,
    15.47289,
    12,
    26.15
  ],
  [
    31395,
    67.09932,
    15.47316,
    11.6,
    26.15
  ],
  [
    31397,
    67.09978,
    15.47331,
    11.6,
    26.09
  ],
  [
    31399,
    67.10025,
    15.47345,
    11.8,
    26.09
  ],
  [
    31409,
    67.10253,
    15.47076,
    10.4,
    26.53
  ],
  [
    31411,
    67.1029,
    15.46971,
    10.4,
    26.78
  ],
  [
    31413,
    67.10331,
    15.46909,
    10,
    27.07
  ],
  [
    31415,
    67.10372,
    15.4684,
    9.7,
    27.34
  ],
  [
    31417,
    67.10411,
    15.46762,
    9,
    27.52
  ],
  [
    31419,
    67.10448,
    15.46674,
    8.6,
    27.71
  ],
  [
    31421,
    67.10482,
    15.4658,
    8.6,
    27.82
  ],
  [
    31423,
    67.10515,
    15.46484,
    8.6,
    27.87
  ],
  [
    31425,
    67.10549,
    15.46389,
    9.2,
    27.86
  ],
  [
    31427,
    67.10584,
    15.46297,
    9.9,
    27.83
  ],
  [
    31429,
    67.10622,
    15.46214,
    10.4,
    27.74
  ],
  [
    31431,
    67.10663,
    15.46142,
    11.1,
    27.62
  ],
  [
    31433,
    67.10705,
    15.46077,
    12.4,
    27.4
  ],
  [
    31435,
    67.10748,
    15.4602,
    13.4,
    27.27
  ],
  [
    31437,
    67.10795,
    15.45977,
    13.4,
    27.37
  ],
  [
    31439,
    67.10843,
    15.45939,
    14.9,
    27.36
  ],
  [
    31442,
    67.10914,
    15.4588,
    15.7,
    27.28
  ],
  [
    31444,
    67.10959,
    15.45834,
    16.6,
    27.25
  ],
  [
    31446,
    67.11004,
    15.45782,
    17.6,
    27.18
  ],
  [
    31448,
    67.11048,
    15.45727,
    18.6,
    27.18
  ],
  [
    31450,
    67.11091,
    15.4567,
    19.6,
    27.06
  ],
  [
    31482,
    67.11752,
    15.45207,
    27,
    24.57
  ],
  [
    31484,
    67.11792,
    15.45261,
    26.1,
    24.54
  ],
  [
    31486,
    67.11832,
    15.45314,
    26.3,
    24.61
  ],
  [
    31488,
    67.11872,
    15.45365,
    24.9,
    24.68
  ],
  [
    31490,
    67.11911,
    15.45415,
    24.3,
    24.74
  ],
  [
    31492,
    67.11951,
    15.45462,
    24,
    24.71
  ],
  [
    31494,
    67.11992,
    15.4551,
    23.6,
    25.09
  ],
  [
    31497,
    67.12054,
    15.4558,
    23,
    25.09
  ],
  [
    31551,
    67.13193,
    15.44826,
    58.4,
    24.82
  ],
  [
    31553,
    67.13238,
    15.44823,
    56.5,
    24.52
  ],
  [
    31555,
    67.13279,
    15.44836,
    43.7,
    24.42
  ],
  [
    31557,
    67.13321,
    15.44851,
    40.5,
    24.49
  ],
  [
    31559,
    67.13365,
    15.44861,
    38.5,
    24.61
  ],
  [
    31561,
    67.1341,
    15.44863,
    39,
    24.74
  ],
  [
    31563,
    67.13454,
    15.44853,
    38.6,
    24.88
  ],
  [
    31565,
    67.13499,
    15.44839,
    38.4,
    25.05
  ],
  [
    31567,
    67.13544,
    15.44824,
    38.3,
    25.16
  ],
  [
    31569,
    67.13589,
    15.44813,
    38.3,
    25.25
  ],
  [
    31571,
    67.13634,
    15.44813,
    38.5,
    25.26
  ],
  [
    31573,
    67.13679,
    15.44827,
    37.7,
    25.23
  ],
  [
    31575,
    67.13723,
    15.44857,
    38.3,
    25.4
  ],
  [
    31577,
    67.13766,
    15.44896,
    37.9,
    25.35
  ],
  [
    31579,
    67.13809,
    15.44937,
    37.1,
    25.46
  ],
  [
    31582,
    67.13874,
    15.44995,
    35.6,
    25.58
  ],
  [
    31584,
    67.13918,
    15.45025,
    34.8,
    25.58
  ],
  [
    31586,
    67.13964,
    15.45043,
    34.1,
    25.78
  ],
  [
    31588,
    67.1401,
    15.45051,
    33.9,
    25.81
  ],
  [
    31590,
    67.14056,
    15.45054,
    33.5,
    25.83
  ],
  [
    31592,
    67.14103,
    15.45056,
    33.9,
    25.79
  ],
  [
    31594,
    67.14149,
    15.45058,
    34.1,
    25.72
  ],
  [
    31596,
    67.14195,
    15.4506,
    34.5,
    25.66
  ],
  [
    31598,
    67.14241,
    15.45062,
    34.9,
    25.6
  ],
  [
    31600,
    67.14286,
    15.45067,
    35.3,
    25.58
  ],
  [
    31602,
    67.14331,
    15.45074,
    33,
    25.4
  ],
  [
    31604,
    67.14376,
    15.45082,
    30.5,
    25.31
  ],
  [
    31606,
    67.14421,
    15.45088,
    28.7,
    25.31
  ],
  [
    31608,
    67.14466,
    15.45084,
    27.4,
    25.27
  ],
  [
    31610,
    67.14511,
    15.45067,
    26.2,
    25.25
  ],
  [
    31612,
    67.14555,
    15.45041,
    26.1,
    25.32
  ],
  [
    31614,
    67.14599,
    15.45011,
    26.6,
    25.32
  ],
  [
    31616,
    67.14643,
    15.44981,
    27.2,
    25.32
  ],
  [
    31629,
    67.14914,
    15.45193,
    27.6,
    25.29
  ],
  [
    31631,
    67.14946,
    15.45277,
    22,
    25.27
  ],
  [
    31633,
    67.14974,
    15.45369,
    19.5,
    25.32
  ],
  [
    31635,
    67.15002,
    15.45462,
    18.5,
    25.39
  ],
  [
    31637,
    67.15028,
    15.45561,
    18,
    25.66
  ],
  [
    31639,
    67.15051,
    15.45663,
    16.6,
    25.84
  ],
  [
    31641,
    67.15073,
    15.45769,
    15.6,
    25.98
  ],
  [
    31643,
    67.15093,
    15.45877,
    14.4,
    25.81
  ],
  [
    31645,
    67.15112,
    15.45984,
    13.5,
    25.42
  ],
  [
    31647,
    67.15131,
    15.4609,
    12,
    25.12
  ],
  [
    31649,
    67.15154,
    15.46189,
    10.9,
    25.03
  ],
  [
    31651,
    67.15181,
    15.4628,
    9.4,
    25.04
  ],
  [
    31653,
    67.15213,
    15.46362,
    7.7,
    25.11
  ],
  [
    31655,
    67.15248,
    15.46433,
    6.1,
    25.25
  ],
  [
    31657,
    67.15287,
    15.46493,
    4.6,
    25.41
  ],
  [
    31659,
    67.15328,
    15.46549,
    3.2,
    25.56
  ],
  [
    31661,
    67.15368,
    15.46603,
    1.8,
    25.71
  ],
  [
    31663,
    67.1541,
    15.46658,
    1.9,
    25.87
  ],
  [
    31665,
    67.15452,
    15.46712,
    1.9,
    25.95
  ],
  [
    31667,
    67.15494,
    15.46768,
    2.9,
    25.96
  ],
  [
    31670,
    67.15555,
    15.46853,
    2.5,
    25.96
  ],
  [
    31711,
    67.16189,
    15.48397,
    -3.3,
    25.96
  ],
  [
    31713,
    67.16224,
    15.48442,
    -18.3,
    22.66
  ],
  [
    31715,
    67.16264,
    15.48493,
    -12.4,
    22.95
  ],
  [
    31717,
    67.16308,
    15.48528,
    -7.1,
    23.44
  ],
  [
    31719,
    67.16351,
    15.48552,
    -4.2,
    23.68
  ],
  [
    31721,
    67.16395,
    15.48562,
    -1,
    23.96
  ],
  [
    31723,
    67.16439,
    15.48565,
    0.9,
    24.17
  ],
  [
    31725,
    67.16484,
    15.48565,
    2.3,
    24.39
  ],
  [
    31727,
    67.16528,
    15.48565,
    3.4,
    24.59
  ],
  [
    31729,
    67.16573,
    15.4856,
    4.4,
    24.74
  ],
  [
    31731,
    67.16617,
    15.48547,
    5.1,
    24.88
  ],
  [
    31733,
    67.16662,
    15.48524,
    5.6,
    25.01
  ],
  [
    31735,
    67.16705,
    15.48492,
    6,
    25.15
  ],
  [
    31737,
    67.16748,
    15.48451,
    6.2,
    25.3
  ],
  [
    31739,
    67.1679,
    15.48404,
    6.3,
    25.39
  ],
  [
    31741,
    67.16831,
    15.48354,
    5.5,
    25.49
  ],
  [
    31743,
    67.16873,
    15.48305,
    5.3,
    25.54
  ],
  [
    31745,
    67.16915,
    15.48255,
    5.4,
    25.58
  ],
  [
    31747,
    67.16957,
    15.48206,
    5.7,
    25.55
  ],
  [
    31749,
    67.16999,
    15.48158,
    5.8,
    25.54
  ],
  [
    31751,
    67.1704,
    15.48111,
    6.5,
    25.34
  ],
  [
    31754,
    67.17102,
    15.4804,
    7.3,
    25.34
  ],
  [
    31797,
    67.18039,
    15.48014,
    17.9,
    23.81
  ],
  [
    31800,
    67.1809,
    15.48046,
    8.1,
    23.72
  ],
  [
    31802,
    67.1813,
    15.48095,
    8.9,
    23.76
  ],
  [
    31804,
    67.18169,
    15.48145,
    8.6,
    23.76
  ],
  [
    31806,
    67.18209,
    15.48189,
    7.8,
    24.08
  ],
  [
    31808,
    67.18251,
    15.48221,
    7.9,
    24.21
  ],
  [
    31810,
    67.18295,
    15.48239,
    8.1,
    24.34
  ],
  [
    31812,
    67.18339,
    15.48243,
    8.3,
    24.47
  ],
  [
    31814,
    67.18383,
    15.48232,
    8.4,
    24.55
  ],
  [
    31816,
    67.18426,
    15.48207,
    7.8,
    24.72
  ],
  [
    31818,
    67.18469,
    15.48169,
    7.7,
    24.86
  ],
  [
    31820,
    67.18509,
    15.48119,
    7.6,
    24.95
  ],
  [
    31822,
    67.18548,
    15.48061,
    7.3,
    24.99
  ],
  [
    31824,
    67.18587,
    15.48001,
    7.5,
    24.97
  ],
  [
    31826,
    67.18625,
    15.47942,
    7.7,
    24.97
  ],
  [
    31828,
    67.18653,
    15.4785,
    6.3,
    24.78
  ],
  [
    31830,
    67.18687,
    15.47761,
    6.4,
    25.22
  ],
  [
    31832,
    67.18721,
    15.47681,
    6.2,
    25.16
  ],
  [
    31834,
    67.18754,
    15.47602,
    6.1,
    25.16
  ],
  [
    31836,
    67.18789,
    15.47497,
    6.2,
    25.51
  ],
  [
    31838,
    67.18818,
    15.47405,
    6.4,
    25.61
  ],
  [
    31840,
    67.18849,
    15.47318,
    6.5,
    25.64
  ],
  [
    31842,
    67.1888,
    15.47232,
    6.4,
    25.63
  ],
  [
    31844,
    67.18911,
    15.47146,
    6.2,
    25.62
  ],
  [
    31846,
    67.18945,
    15.47065,
    6.5,
    25.58
  ],
  [
    31848,
    67.18981,
    15.46995,
    7.1,
    25.49
  ],
  [
    31851,
    67.19039,
    15.46901,
    8,
    25.52
  ],
  [
    31853,
    67.19078,
    15.46839,
    8.7,
    25.52
  ],
  [
    31867,
    67.19364,
    15.46566,
    12.6,
    25.1
  ],
  [
    31869,
    67.19401,
    15.46494,
    11.7,
    24.99
  ],
  [
    31871,
    67.19432,
    15.4641,
    11.2,
    24.91
  ],
  [
    31873,
    67.19459,
    15.46319,
    11.4,
    24.84
  ],
  [
    31875,
    67.19483,
    15.46222,
    11.7,
    24.76
  ],
  [
    31877,
    67.19503,
    15.46119,
    12.5,
    24.69
  ],
  [
    31879,
    67.19519,
    15.46012,
    13.3,
    24.64
  ],
  [
    31881,
    67.19535,
    15.45905,
    14.1,
    24.62
  ],
  [
    31883,
    67.1955,
    15.45799,
    15.2,
    24.6
  ],
  [
    31885,
    67.19566,
    15.45692,
    16.5,
    24.53
  ],
  [
    31887,
    67.19582,
    15.45586,
    17.1,
    24.46
  ],
  [
    31889,
    67.19597,
    15.45481,
    19.2,
    24.36
  ],
  [
    31892,
    67.19621,
    15.45323,
    21.4,
    24.29
  ],
  [
    31894,
    67.19638,
    15.45221,
    20.1,
    24.29
  ],
  [
    31896,
    67.19655,
    15.4512,
    18.8,
    24.16
  ],
  [
    31898,
    67.19673,
    15.45018,
    19,
    24.16
  ],
  [
    31900,
    67.19693,
    15.4492,
    19.9,
    24.15
  ],
  [
    31902,
    67.19718,
    15.44829,
    21.1,
    24.09
  ],
  [
    31904,
    67.19745,
    15.44741,
    21.9,
    24.09
  ],
  [
    31906,
    67.19772,
    15.44654,
    22.7,
    24.09
  ],
  [
    31965,
    67.20925,
    15.43411,
    9.9,
    25.26
  ],
  [
    31967,
    67.20946,
    15.43302,
    7.6,
    25.26
  ],
  [
    31969,
    67.20966,
    15.43198,
    6.1,
    25.24
  ],
  [
    31971,
    67.20985,
    15.43092,
    6.2,
    25.25
  ],
  [
    31973,
    67.21005,
    15.42988,
    6.2,
    25.31
  ],
  [
    31975,
    67.21029,
    15.42888,
    6.1,
    25.33
  ],
  [
    31977,
    67.21057,
    15.42797,
    5.9,
    25.35
  ],
  [
    31979,
    67.21089,
    15.42712,
    5.6,
    25.35
  ],
  [
    31981,
    67.21121,
    15.42629,
    5.4,
    25.36
  ],
  [
    31983,
    67.21152,
    15.42544,
    5.4,
    25.39
  ],
  [
    31985,
    67.21182,
    15.42455,
    5.6,
    25.44
  ],
  [
    31987,
    67.21211,
    15.42364,
    5.9,
    25.45
  ],
  [
    31990,
    67.21254,
    15.42227,
    6.6,
    25.43
  ],
  [
    31992,
    67.21284,
    15.42138,
    7.1,
    25.41
  ],
  [
    31994,
    67.21316,
    15.42056,
    7.6,
    25.41
  ],
  [
    31996,
    67.21353,
    15.41986,
    8.6,
    25.37
  ],
  [
    31998,
    67.21392,
    15.41929,
    10,
    25.26
  ],
  [
    32000,
    67.21433,
    15.41877,
    11,
    25.15
  ],
  [
    32002,
    67.21473,
    15.41825,
    12,
    25.15
  ],
  [
    32062,
    67.2276,
    15.4229,
    -21.2,
    24
  ],
  [
    32064,
    67.22814,
    15.42267,
    -3.8,
    24.25
  ],
  [
    32066,
    67.22862,
    15.42228,
    5.3,
    24.71
  ],
  [
    32068,
    67.22906,
    15.42189,
    7.3,
    24.85
  ],
  [
    32070,
    67.22949,
    15.42151,
    7.8,
    24.98
  ],
  [
    32072,
    67.22992,
    15.42119,
    7.6,
    25.11
  ],
  [
    32074,
    67.23036,
    15.421,
    6.6,
    25.2
  ],
  [
    32076,
    67.23081,
    15.42096,
    5.8,
    25.24
  ],
  [
    32078,
    67.23126,
    15.42108,
    5.2,
    25.27
  ],
  [
    32080,
    67.23171,
    15.42134,
    3.8,
    24.88
  ],
  [
    32082,
    67.23213,
    15.42175,
    1.4,
    25.16
  ],
  [
    32084,
    67.23252,
    15.42229,
    -1.1,
    25.15
  ],
  [
    32086,
    67.23289,
    15.42296,
    -2.6,
    25.04
  ],
  [
    32089,
    67.23338,
    15.42416,
    -2.8,
    25
  ],
  [
    32091,
    67.23367,
    15.42506,
    -2.8,
    24.92
  ],
  [
    32093,
    67.23393,
    15.42602,
    -3.3,
    24.92
  ],
  [
    32095,
    67.23418,
    15.42697,
    -3.2,
    24.92
  ],
  [
    32097,
    67.23446,
    15.42789,
    -2.6,
    24.76
  ],
  [
    32099,
    67.23476,
    15.42872,
    -2.2,
    24.67
  ],
  [
    32101,
    67.23511,
    15.42944,
    -1.6,
    24.54
  ],
  [
    32103,
    67.23548,
    15.43004,
    -0.6,
    24.42
  ],
  [
    32105,
    67.23585,
    15.43061,
    0.2,
    24.13
  ],
  [
    32107,
    67.23623,
    15.43118,
    1,
    24.13
  ],
  [
    32128,
    67.2408,
    15.43025,
    7.4,
    23.96
  ],
  [
    32130,
    67.24118,
    15.42965,
    3.7,
    23.87
  ],
  [
    32132,
    67.24162,
    15.42963,
    4.8,
    23.83
  ],
  [
    32134,
    67.24206,
    15.42963,
    5.9,
    23.79
  ],
  [
    32136,
    67.24249,
    15.42963,
    6.3,
    23.76
  ],
  [
    32138,
    67.24292,
    15.42963,
    6.3,
    23.46
  ],
  [
    32140,
    67.24333,
    15.42959,
    6.1,
    22.82
  ],
  [
    32142,
    67.24374,
    15.42949,
    6,
    22.12
  ],
  [
    32144,
    67.24413,
    15.42938,
    6.2,
    22.12
  ],
  [
    32146,
    67.24453,
    15.42927,
    6.5,
    22.12
  ],
  [
    32166,
    67.24795,
    15.42764,
    13.7,
    19.61
  ],
  [
    32168,
    67.24829,
    15.42755,
    7.6,
    19.68
  ],
  [
    32171,
    67.2488,
    15.42765,
    8.3,
    19.82
  ],
  [
    32173,
    67.24916,
    15.42785,
    8.1,
    19.82
  ],
  [
    32175,
    67.24951,
    15.42807,
    8,
    19.82
  ],
  [
    32177,
    67.24986,
    15.4283,
    8,
    20.11
  ],
  [
    32179,
    67.25022,
    15.42853,
    8,
    20.22
  ],
  [
    32181,
    67.25057,
    15.42881,
    7.9,
    20.38
  ],
  [
    32183,
    67.25092,
    15.42913,
    7.9,
    20.51
  ],
  [
    32185,
    67.25126,
    15.42954,
    8,
    20.58
  ],
  [
    32187,
    67.25158,
    15.43004,
    8.1,
    20.78
  ],
  [
    32189,
    67.25188,
    15.43062,
    7.9,
    20.94
  ],
  [
    32191,
    67.25216,
    15.43127,
    7.8,
    21.13
  ],
  [
    32193,
    67.25243,
    15.43196,
    7.7,
    21.37
  ],
  [
    32195,
    67.25271,
    15.43267,
    7.7,
    21.65
  ],
  [
    32197,
    67.25298,
    15.43339,
    7.7,
    21.91
  ],
  [
    32199,
    67.25326,
    15.43411,
    7.8,
    22.11
  ],
  [
    32201,
    67.25354,
    15.43484,
    8.4,
    22.34
  ],
  [
    32203,
    67.25383,
    15.43556,
    9,
    22.42
  ],
  [
    32205,
    67.25415,
    15.43621,
    9.5,
    22.5
  ],
  [
    32207,
    67.25449,
    15.43677,
    10.1,
    22.56
  ],
  [
    32209,
    67.25485,
    15.43723,
    10.8,
    22.64
  ],
  [
    32211,
    67.25523,
    15.43756,
    11.3,
    22.54
  ],
  [
    32213,
    67.25562,
    15.43782,
    11.9,
    22.39
  ],
  [
    32215,
    67.25602,
    15.43805,
    12.5,
    22.9
  ],
  [
    32217,
    67.25642,
    15.43827,
    12.5,
    22.9
  ],
  [
    32241,
    67.26136,
    15.43812,
    20.5,
    22.88
  ],
  [
    32243,
    67.26175,
    15.43794,
    21.4,
    23.01
  ],
  [
    32245,
    67.2621,
    15.43748,
    21.7,
    22.89
  ],
  [
    32247,
    67.26244,
    15.4369,
    21.8,
    22.85
  ],
  [
    32250,
    67.2629,
    15.43586,
    23.3,
    22.82
  ],
  [
    32252,
    67.26317,
    15.43508,
    22.7,
    22.78
  ],
  [
    32254,
    67.26341,
    15.43422,
    21.8,
    22.78
  ],
  [
    32256,
    67.26361,
    15.43331,
    20.8,
    22.53
  ],
  [
    32258,
    67.26377,
    15.43235,
    20,
    22.28
  ],
  [
    32260,
    67.2639,
    15.43137,
    19.5,
    22.17
  ],
  [
    32262,
    67.26399,
    15.43036,
    19.1,
    21.82
  ],
  [
    32264,
    67.26405,
    15.42936,
    18.9,
    21.56
  ],
  [
    32266,
    67.26406,
    15.42836,
    18.9,
    21.18
  ],
  [
    32268,
    67.26404,
    15.42738,
    18.9,
    20.41
  ],
  [
    32270,
    67.264,
    15.42645,
    19,
    19.61
  ],
  [
    32272,
    67.26395,
    15.42556,
    19.3,
    18.74
  ],
  [
    32274,
    67.26389,
    15.42471,
    19.5,
    18.08
  ],
  [
    32276,
    67.26383,
    15.42389,
    19.8,
    17.64
  ],
  [
    32278,
    67.26377,
    15.42309,
    20.1,
    17.27
  ],
  [
    32280,
    67.26371,
    15.42231,
    20.7,
    16.97
  ],
  [
    32282,
    67.26364,
    15.42155,
    21.3,
    16.7
  ],
  [
    32284,
    67.26357,
    15.4208,
    21.8,
    16.44
  ],
  [
    32286,
    67.2635,
    15.42006,
    22.3,
    16.17
  ],
  [
    32288,
    67.26343,
    15.41934,
    22.7,
    15.9
  ],
  [
    32290,
    67.26335,
    15.41863,
    23.2,
    15.65
  ],
  [
    32292,
    67.26327,
    15.41793,
    23.8,
    15.47
  ],
  [
    32294,
    67.26319,
    15.41725,
    23.8,
    15.3
  ],
  [
    32296,
    67.26311,
    15.41657,
    25,
    15.11
  ],
  [
    32299,
    67.26298,
    15.41558,
    25.8,
    14.93
  ],
  [
    32301,
    67.2629,
    15.41494,
    26.4,
    14.76
  ],
  [
    32303,
    67.26281,
    15.4143,
    27,
    14.76
  ],
  [
    32305,
    67.26272,
    15.41367,
    27.5,
    14.76
  ],
  [
    32307,
    67.26263,
    15.41305,
    28.1,
    14.26
  ],
  [
    32309,
    67.26253,
    15.41244,
    28.7,
    14.01
  ],
  [
    32311,
    67.26244,
    15.41184,
    29.2,
    13.84
  ],
  [
    32313,
    67.26234,
    15.41125,
    29.6,
    13.63
  ],
  [
    32315,
    67.26225,
    15.41067,
    30.1,
    13.55
  ],
  [
    32317,
    67.26216,
    15.41009,
    30.5,
    13.3
  ],
  [
    32319,
    67.26209,
    15.40951,
    30.9,
    13.15
  ],
  [
    32321,
    67.26203,
    15.40892,
    31.2,
    12.99
  ],
  [
    32323,
    67.26199,
    15.40834,
    31.5,
    12.45
  ],
  [
    32325,
    67.26198,
    15.40778,
    31.8,
    11.94
  ],
  [
    32327,
    67.26198,
    15.40723,
    32,
    11.59
  ],
  [
    32329,
    67.26199,
    15.4067,
    32.4,
    11.32
  ],
  [
    32331,
    67.26202,
    15.40619,
    32.8,
    11.18
  ],
  [
    32333,
    67.26207,
    15.40569,
    33,
    11.08
  ],
  [
    32335,
    67.26213,
    15.4052,
    33.2,
    11.01
  ],
  [
    32337,
    67.2622,
    15.40473,
    33.3,
    10.99
  ],
  [
    32339,
    67.26229,
    15.40427,
    33.5,
    11
  ],
  [
    32341,
    67.2624,
    15.40383,
    33.5,
    11.01
  ],
  [
    32343,
    67.26251,
    15.40341,
    33.5,
    11.06
  ],
  [
    32345,
    67.26263,
    15.40301,
    33.2,
    11.19
  ],
  [
    32348,
    67.26283,
    15.40244,
    32.7,
    11.17
  ],
  [
    32350,
    67.26298,
    15.40207,
    32.6,
    11.17
  ],
  [
    32352,
    67.26312,
    15.4017,
    32.4,
    11.26
  ],
  [
    32354,
    67.26326,
    15.40132,
    32.3,
    11.26
  ],
  [
    32356,
    67.26341,
    15.40093,
    32,
    11.43
  ],
  [
    32358,
    67.26355,
    15.40055,
    31.8,
    11.52
  ],
  [
    32360,
    67.2637,
    15.40016,
    31.7,
    11.55
  ],
  [
    32362,
    67.26384,
    15.3998,
    31.5,
    10.93
  ],
  [
    32364,
    67.26399,
    15.39948,
    31.4,
    10.17
  ],
  [
    32366,
    67.26412,
    15.39918,
    31.5,
    9.44
  ],
  [
    32368,
    67.26424,
    15.39891,
    31.5,
    8.71
  ],
  [
    32370,
    67.26436,
    15.39866,
    31.6,
    7.96
  ],
  [
    32372,
    67.26446,
    15.39843,
    31.7,
    7.38
  ],
  [
    32374,
    67.26456,
    15.39821,
    31.8,
    6.85
  ],
  [
    32376,
    67.26465,
    15.39802,
    31.9,
    6.34
  ],
  [
    32378,
    67.26473,
    15.39783,
    32,
    5.94
  ],
  [
    32380,
    67.26481,
    15.39766,
    32,
    5.74
  ],
  [
    32382,
    67.26489,
    15.39749,
    32.1,
    5.51
  ],
  [
    32384,
    67.26496,
    15.39733,
    32.3,
    4.86
  ],
  [
    32386,
    67.26502,
    15.3972,
    32.4,
    4.08
  ],
  [
    32388,
    67.26507,
    15.39709,
    32.6,
    3.3
  ],
  [
    32390,
    67.26511,
    15.39701,
    32.7,
    2.5
  ],
  [
    32392,
    67.26514,
    15.39695,
    32.9,
    1.65
  ],
  [
    32394,
    67.26516,
    15.39692,
    33.1,
    0.6
  ],
  [
    32397,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32399,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32401,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32403,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32405,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32407,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32409,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32411,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32413,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32415,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32417,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32419,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32421,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32423,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32425,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32427,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32429,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32431,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32433,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32435,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32437,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32439,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32441,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32444,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32446,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32448,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32450,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32452,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32454,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32456,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32458,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32460,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32462,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32464,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32466,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32468,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32470,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32472,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32474,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32476,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32478,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32480,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32482,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32484,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32486,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32488,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32490,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32492,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32494,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32497,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32499,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32501,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32503,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32505,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32507,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32509,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32511,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32513,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32515,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32517,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32519,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32521,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32523,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32525,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32527,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32529,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32531,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32533,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32535,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32537,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32539,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32541,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32543,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32545,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32548,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32550,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32552,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32554,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32556,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32558,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32560,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32562,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32564,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32566,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32568,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32570,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32572,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32574,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32576,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32578,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32580,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32582,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32584,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32586,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32588,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32590,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32592,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32594,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32596,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32599,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32601,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32603,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32605,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32607,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32609,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32611,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32613,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32615,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32617,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32619,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32621,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32623,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32625,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32627,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32629,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32631,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32633,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32635,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32637,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32639,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32641,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32643,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32645,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32647,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32650,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32652,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32654,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32656,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32658,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32660,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32662,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32664,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32666,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32668,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32670,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32672,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32674,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32676,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32678,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32680,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32682,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32684,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32686,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32688,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32690,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32692,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32694,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32696,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32699,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32701,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32703,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32705,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32707,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32709,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32711,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32713,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32715,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32717,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32719,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32721,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32723,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32725,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32727,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32729,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32731,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32733,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32735,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32737,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32739,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32741,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32743,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32745,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32748,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32750,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32752,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32754,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32756,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32758,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32760,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32762,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32764,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32766,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32768,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32770,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32772,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32774,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32776,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32778,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32780,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32782,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32784,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32786,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32788,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32790,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32792,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32794,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32796,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32799,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32801,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32803,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32805,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32807,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32809,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32811,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32813,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32815,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32817,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32819,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32821,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32823,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32825,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32827,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32829,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32831,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32833,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32835,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32837,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32839,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32841,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32843,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32845,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32847,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32850,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32852,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32854,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32856,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32858,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32860,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32862,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32864,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32866,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32868,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32870,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32872,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32874,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32876,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32878,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32880,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32882,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32884,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32886,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32888,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32890,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32892,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32894,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32896,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32899,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32901,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32903,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32905,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32907,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32909,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32911,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32913,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32915,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32917,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32919,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32921,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32923,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32925,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32927,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32929,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32931,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32933,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32935,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32937,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32939,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32941,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32943,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32945,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32947,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32949,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32952,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32954,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32956,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32958,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32960,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32962,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32964,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32966,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32968,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32970,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32972,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32974,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32976,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32978,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32980,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32982,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32984,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32986,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32988,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32990,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32992,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32994,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32996,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    32998,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33000,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33003,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33005,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33007,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33009,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33011,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33013,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33015,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33017,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33019,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33021,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33023,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33025,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33027,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33029,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33031,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33033,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33035,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33037,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33039,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33041,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33043,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33045,
    67.26516,
    15.39691,
    33.2,
    0
  ],
  [
    33047,
    67.26518,
    15.39695,
    41,
    1.86
  ],
  [
    33050,
    67.26523,
    15.39684,
    41,
    2.62
  ],
  [
    33052,
    67.26528,
    15.39673,
    41,
    2.99
  ],
  [
    33054,
    67.26533,
    15.3966,
    41,
    2.99
  ],
  [
    33056,
    67.2654,
    15.39645,
    41,
    4.79
  ],
  [
    33058,
    67.26547,
    15.39629,
    41,
    5.38
  ],
  [
    33060,
    67.26555,
    15.3961,
    41,
    5.91
  ],
  [
    33062,
    67.26564,
    15.3959,
    41,
    6.66
  ],
  [
    33064,
    67.26573,
    15.39569,
    40.9,
    7.17
  ],
  [
    33066,
    67.26583,
    15.39546,
    40.9,
    7.64
  ],
  [
    33068,
    67.26594,
    15.39521,
    40.9,
    8.14
  ],
  [
    33070,
    67.26605,
    15.39495,
    40.9,
    8.4
  ],
  [
    33072,
    67.26617,
    15.39468,
    40.9,
    9.18
  ],
  [
    33074,
    67.2663,
    15.39438,
    40.9,
    9.73
  ],
  [
    33076,
    67.26644,
    15.39407,
    40.9,
    10.21
  ],
  [
    33078,
    67.26658,
    15.39375,
    40.8,
    10.52
  ],
  [
    33080,
    67.26672,
    15.39342,
    40.7,
    10.71
  ],
  [
    33082,
    67.26687,
    15.39309,
    40.7,
    10.88
  ],
  [
    33084,
    67.26701,
    15.39275,
    40.7,
    10.95
  ],
  [
    33086,
    67.26716,
    15.39241,
    40.6,
    10.96
  ],
  [
    33088,
    67.26731,
    15.39207,
    40.5,
    10.96
  ],
  [
    33090,
    67.26746,
    15.39173,
    40.5,
    10.96
  ],
  [
    33092,
    67.26761,
    15.39139,
    40.4,
    10.95
  ],
  [
    33094,
    67.26776,
    15.39105,
    40.4,
    10.95
  ],
  [
    33096,
    67.2679,
    15.39071,
    40.3,
    10.97
  ],
  [
    33099,
    67.26813,
    15.3902,
    40.2,
    11.01
  ],
  [
    33101,
    67.26828,
    15.38986,
    40.1,
    11.05
  ],
  [
    33103,
    67.26842,
    15.38951,
    40.1,
    11.05
  ],
  [
    33105,
    67.26856,
    15.38914,
    40,
    11.05
  ],
  [
    33107,
    67.2687,
    15.38876,
    40,
    11.08
  ],
  [
    33109,
    67.26883,
    15.38838,
    39.9,
    11.08
  ],
  [
    33111,
    67.26896,
    15.38798,
    39.9,
    11.08
  ],
  [
    33113,
    67.26908,
    15.38756,
    39.8,
    11.07
  ],
  [
    33115,
    67.26919,
    15.38714,
    39.7,
    11.07
  ],
  [
    33117,
    67.2693,
    15.38672,
    39.6,
    11.07
  ],
  [
    33119,
    67.26941,
    15.38628,
    39.5,
    11.1
  ],
  [
    33121,
    67.26951,
    15.38583,
    39.3,
    11.12
  ],
  [
    33123,
    67.2696,
    15.38537,
    39.1,
    11.17
  ],
  [
    33125,
    67.26969,
    15.3849,
    38.9,
    11.24
  ],
  [
    33127,
    67.26977,
    15.38442,
    38.7,
    11.33
  ],
  [
    33129,
    67.26985,
    15.38393,
    38.5,
    11.42
  ],
  [
    33131,
    67.26993,
    15.38344,
    38.3,
    11.52
  ],
  [
    33133,
    67.27001,
    15.38293,
    38.1,
    11.63
  ],
  [
    33135,
    67.27008,
    15.38243,
    37.9,
    11.7
  ],
  [
    33137,
    67.27016,
    15.38191,
    37.8,
    11.78
  ],
  [
    33139,
    67.27023,
    15.38139,
    37.7,
    11.84
  ],
  [
    33141,
    67.2703,
    15.38087,
    37.7,
    11.89
  ],
  [
    33143,
    67.27037,
    15.38035,
    37.6,
    11.92
  ],
  [
    33145,
    67.27044,
    15.37982,
    37.6,
    11.94
  ],
  [
    33147,
    67.2705,
    15.37928,
    37.4,
    11.98
  ],
  [
    33150,
    67.27059,
    15.37847,
    37.3,
    12.09
  ],
  [
    33152,
    67.27065,
    15.37792,
    37.1,
    12.19
  ],
  [
    33154,
    67.2707,
    15.37735,
    37,
    12.19
  ],
  [
    33156,
    67.27075,
    15.37676,
    36.8,
    12.8
  ],
  [
    33158,
    67.2708,
    15.37617,
    36.6,
    13.2
  ],
  [
    33160,
    67.27084,
    15.37555,
    36.4,
    13.34
  ],
  [
    33162,
    67.27088,
    15.37492,
    36.2,
    13.94
  ],
  [
    33164,
    67.27092,
    15.37426,
    35.9,
    14.45
  ],
  [
    33166,
    67.27095,
    15.37358,
    35.4,
    15
  ],
  [
    33168,
    67.27098,
    15.37287,
    34.9,
    15.57
  ],
  [
    33170,
    67.271,
    15.37213,
    34.4,
    16.16
  ],
  [
    33172,
    67.27102,
    15.37136,
    33.8,
    16.76
  ],
  [
    33174,
    67.27103,
    15.37057,
    33.3,
    17.38
  ],
  [
    33176,
    67.27103,
    15.36974,
    32.7,
    18.02
  ],
  [
    33178,
    67.27103,
    15.36888,
    32.1,
    18.64
  ],
  [
    33180,
    67.27103,
    15.368,
    31.6,
    19.25
  ],
  [
    33182,
    67.27103,
    15.36708,
    31.3,
    19.81
  ],
  [
    33184,
    67.27103,
    15.36615,
    31,
    20.3
  ],
  [
    33186,
    67.27103,
    15.36519,
    31,
    20.75
  ],
  [
    33188,
    67.27103,
    15.36421,
    31,
    21.09
  ],
  [
    33190,
    67.27103,
    15.36322,
    31.2,
    21.36
  ],
  [
    33192,
    67.27102,
    15.36222,
    31.6,
    21.56
  ],
  [
    33194,
    67.27102,
    15.36121,
    31.6,
    21.69
  ],
  [
    33196,
    67.27102,
    15.36019,
    32.7,
    21.78
  ],
  [
    33199,
    67.27102,
    15.35867,
    33.4,
    21.85
  ],
  [
    33201,
    67.27102,
    15.35764,
    33.8,
    21.92
  ],
  [
    33203,
    67.27104,
    15.35662,
    34.2,
    21.92
  ],
  [
    33205,
    67.27109,
    15.35559,
    34.5,
    21.92
  ],
  [
    33207,
    67.27118,
    15.35458,
    34.6,
    22.31
  ],
  [
    33209,
    67.2713,
    15.35358,
    34.6,
    22.6
  ],
  [
    33211,
    67.27143,
    15.35257,
    34.6,
    22.85
  ],
  [
    33213,
    67.27156,
    15.35156,
    34.5,
    23.13
  ],
  [
    33215,
    67.2717,
    15.35053,
    34.4,
    23.27
  ],
  [
    33217,
    67.27183,
    15.34949,
    34,
    23.67
  ],
  [
    33219,
    67.27195,
    15.34842,
    33.4,
    23.94
  ],
  [
    33221,
    67.27204,
    15.34732,
    32.6,
    24.24
  ],
  [
    33223,
    67.27208,
    15.34619,
    31.8,
    24.53
  ],
  [
    33225,
    67.27208,
    15.34504,
    30.8,
    24.71
  ],
  [
    33227,
    67.27204,
    15.34389,
    30,
    24.91
  ],
  [
    33229,
    67.27195,
    15.34274,
    29.3,
    25.11
  ],
  [
    33231,
    67.27182,
    15.34162,
    28.9,
    25.28
  ],
  [
    33233,
    67.27164,
    15.34053,
    28.7,
    25.37
  ],
  [
    33235,
    67.27142,
    15.33949,
    28.7,
    25.41
  ],
  [
    33237,
    67.27117,
    15.3385,
    28.7,
    25.4
  ],
  [
    33239,
    67.2709,
    15.33754,
    28.8,
    25.38
  ],
  [
    33241,
    67.27063,
    15.33659,
    28.9,
    25.34
  ],
  [
    33243,
    67.27036,
    15.33564,
    28.9,
    25.27
  ],
  [
    33245,
    67.27009,
    15.33468,
    29.2,
    25.1
  ],
  [
    33247,
    67.26985,
    15.33369,
    29.5,
    24.97
  ],
  [
    33250,
    67.26957,
    15.33213,
    30,
    24.76
  ],
  [
    33252,
    67.26943,
    15.33104,
    30.3,
    24.64
  ],
  [
    33254,
    67.26931,
    15.32996,
    30.5,
    24.64
  ],
  [
    33256,
    67.26919,
    15.32888,
    30.7,
    23.96
  ],
  [
    33258,
    67.26908,
    15.32782,
    30.9,
    23.67
  ],
  [
    33260,
    67.26897,
    15.32677,
    31.3,
    23.41
  ],
  [
    33262,
    67.26885,
    15.32574,
    32.1,
    23.06
  ],
  [
    33264,
    67.26871,
    15.32474,
    32.4,
    22.83
  ],
  [
    33266,
    67.26853,
    15.32379,
    32.7,
    22.63
  ],
  [
    33268,
    67.26831,
    15.3229,
    33.1,
    22.44
  ],
  [
    33270,
    67.26808,
    15.32206,
    33.5,
    22.33
  ],
  [
    33272,
    67.26784,
    15.32124,
    34.1,
    22.28
  ],
  [
    33274,
    67.2676,
    15.32042,
    34.8,
    22.28
  ],
  [
    33276,
    67.26737,
    15.31957,
    35.5,
    22.3
  ],
  [
    33278,
    67.26717,
    15.31867,
    36,
    22.33
  ],
  [
    33280,
    67.26701,
    15.31772,
    36.3,
    22.39
  ],
  [
    33282,
    67.2669,
    15.31673,
    36.7,
    22.51
  ],
  [
    33284,
    67.26681,
    15.3157,
    37,
    22.68
  ],
  [
    33286,
    67.26674,
    15.31466,
    37.3,
    22.86
  ],
  [
    33288,
    67.26668,
    15.31361,
    37.6,
    23.12
  ],
  [
    33290,
    67.26663,
    15.31254,
    37.9,
    23.39
  ],
  [
    33292,
    67.26661,
    15.31145,
    38,
    23.63
  ],
  [
    33294,
    67.26664,
    15.31034,
    38,
    23.84
  ],
  [
    33296,
    67.26672,
    15.30925,
    38.1,
    24.02
  ],
  [
    33299,
    67.26693,
    15.30765,
    38.2,
    24.16
  ],
  [
    33301,
    67.26709,
    15.3066,
    37.9,
    24.26
  ],
  [
    33303,
    67.26726,
    15.30554,
    37.5,
    24.26
  ],
  [
    33305,
    67.26741,
    15.30446,
    36.8,
    24.26
  ],
  [
    33307,
    67.26752,
    15.30335,
    36.3,
    24.56
  ],
  [
    33309,
    67.26757,
    15.30221,
    35.8,
    24.6
  ],
  [
    33311,
    67.26757,
    15.30107,
    35.6,
    24.63
  ],
  [
    33313,
    67.26752,
    15.29993,
    35.5,
    24.65
  ],
  [
    33315,
    67.26741,
    15.29883,
    35.1,
    24.67
  ],
  [
    33317,
    67.26726,
    15.29775,
    34.8,
    24.66
  ],
  [
    33319,
    67.2671,
    15.29668,
    34.7,
    24.66
  ],
  [
    33321,
    67.26694,
    15.29562,
    34.7,
    24.66
  ],
  [
    33328,
    67.26591,
    15.29261,
    34.1,
    24.64
  ],
  [
    33330,
    67.26565,
    15.29163,
    33.4,
    24.51
  ],
  [
    33332,
    67.26547,
    15.29054,
    32,
    24.88
  ],
  [
    33334,
    67.26532,
    15.28946,
    31.8,
    25.05
  ],
  [
    33336,
    67.26521,
    15.28833,
    30.4,
    25.28
  ],
  [
    33338,
    67.26514,
    15.28717,
    29.8,
    25.49
  ],
  [
    33340,
    67.26512,
    15.28598,
    29,
    25.69
  ],
  [
    33342,
    67.26513,
    15.28481,
    28.5,
    25.3
  ],
  [
    33344,
    67.26514,
    15.28363,
    27.9,
    25.3
  ],
  [
    33347,
    67.26552,
    15.28197,
    27.7,
    26.23
  ],
  [
    33349,
    67.26583,
    15.28086,
    28.1,
    26.5
  ],
  [
    33351,
    67.2661,
    15.27983,
    27.5,
    26.71
  ],
  [
    33353,
    67.26637,
    15.27879,
    27.1,
    26.89
  ],
  [
    33355,
    67.26661,
    15.27771,
    26.7,
    26.66
  ],
  [
    33357,
    67.2668,
    15.27659,
    26.2,
    26.29
  ],
  [
    33359,
    67.26694,
    15.27543,
    25.7,
    25.83
  ],
  [
    33361,
    67.26705,
    15.27428,
    25.7,
    25.4
  ],
  [
    33363,
    67.26715,
    15.27315,
    24.8,
    25.02
  ],
  [
    33366,
    67.2673,
    15.27147,
    24.1,
    24.69
  ],
  [
    33368,
    67.2674,
    15.27037,
    23.7,
    24.58
  ],
  [
    33370,
    67.26751,
    15.26927,
    23.2,
    24.58
  ],
  [
    33372,
    67.26763,
    15.26818,
    23.4,
    24.36
  ],
  [
    33374,
    67.26774,
    15.26708,
    23.2,
    24.53
  ],
  [
    33376,
    67.26786,
    15.26598,
    23,
    24.53
  ],
  [
    33385,
    67.26948,
    15.26293,
    23.2,
    24.86
  ],
  [
    33387,
    67.26993,
    15.26232,
    27.8,
    24.93
  ],
  [
    33389,
    67.27032,
    15.26167,
    27.8,
    25.14
  ],
  [
    33391,
    67.27071,
    15.26103,
    31.5,
    25.27
  ],
  [
    33394,
    67.27125,
    15.25993,
    31.3,
    25.35
  ],
  [
    33396,
    67.27156,
    15.25908,
    31.8,
    25.4
  ],
  [
    33398,
    67.27185,
    15.25815,
    31.8,
    25.4
  ],
  [
    33400,
    67.27209,
    15.25714,
    31.8,
    25.4
  ],
  [
    33402,
    67.27229,
    15.25607,
    31.8,
    25.52
  ],
  [
    33404,
    67.27245,
    15.25496,
    31.5,
    25.69
  ],
  [
    33406,
    67.27257,
    15.2538,
    31.3,
    25.75
  ],
  [
    33408,
    67.27267,
    15.25263,
    31,
    25.92
  ],
  [
    33410,
    67.27278,
    15.25145,
    30.8,
    26.01
  ],
  [
    33412,
    67.27289,
    15.25026,
    31,
    26.22
  ],
  [
    33414,
    67.27301,
    15.24909,
    31.2,
    26.28
  ],
  [
    33416,
    67.27317,
    15.24793,
    31.3,
    26.28
  ],
  [
    33418,
    67.27335,
    15.24681,
    31.3,
    26.27
  ],
  [
    33420,
    67.27356,
    15.24572,
    31.4,
    26.27
  ],
  [
    33422,
    67.2738,
    15.24468,
    31.2,
    26.28
  ],
  [
    33424,
    67.27407,
    15.24367,
    31.6,
    26.27
  ],
  [
    33426,
    67.27436,
    15.24271,
    32.2,
    26.24
  ],
  [
    33428,
    67.27467,
    15.24181,
    33,
    26.22
  ],
  [
    33430,
    67.27501,
    15.24096,
    33.8,
    26.13
  ],
  [
    33432,
    67.27536,
    15.24018,
    34.6,
    25.53
  ],
  [
    33434,
    67.27571,
    15.23941,
    35.6,
    25.53
  ],
  [
    33436,
    67.27606,
    15.23864,
    36.1,
    25.53
  ],
  [
    33513,
    67.29255,
    15.22089,
    50,
    25.61
  ],
  [
    33515,
    67.2929,
    15.22002,
    39.6,
    25.34
  ],
  [
    33517,
    67.29321,
    15.21912,
    39.6,
    24.81
  ],
  [
    33519,
    67.29347,
    15.21818,
    38,
    24.47
  ],
  [
    33529,
    67.29441,
    15.21316,
    28.4,
    23.45
  ],
  [
    33531,
    67.29459,
    15.21218,
    27.1,
    23.3
  ],
  [
    33533,
    67.29476,
    15.2112,
    25.9,
    23.05
  ],
  [
    33535,
    67.29494,
    15.21024,
    24.8,
    22.88
  ],
  [
    33537,
    67.29511,
    15.20929,
    23.8,
    22.2
  ],
  [
    33539,
    67.29527,
    15.20837,
    22.9,
    21.54
  ],
  [
    33541,
    67.29543,
    15.20749,
    22,
    20.82
  ],
  [
    33543,
    67.29559,
    15.20663,
    21.2,
    20.16
  ],
  [
    33545,
    67.29574,
    15.20579,
    20.4,
    19.5
  ],
  [
    33547,
    67.29588,
    15.20499,
    19.7,
    18.9
  ],
  [
    33549,
    67.29603,
    15.20421,
    18.9,
    18.45
  ],
  [
    33551,
    67.29617,
    15.20344,
    18.3,
    18.11
  ],
  [
    33553,
    67.29631,
    15.2027,
    17.7,
    17.79
  ],
  [
    33555,
    67.29646,
    15.20198,
    17.1,
    17.52
  ],
  [
    33557,
    67.29662,
    15.2013,
    16.6,
    17.15
  ],
  [
    33559,
    67.29678,
    15.20063,
    16.6,
    16.96
  ],
  [
    33561,
    67.29695,
    15.19998,
    15.8,
    16.68
  ],
  [
    33564,
    67.29722,
    15.19907,
    15.4,
    16.41
  ],
  [
    33566,
    67.2974,
    15.19849,
    15.1,
    16.15
  ],
  [
    33568,
    67.29758,
    15.19792,
    15,
    16.15
  ],
  [
    33570,
    67.29775,
    15.19736,
    15,
    16.15
  ],
  [
    33572,
    67.29793,
    15.19681,
    15.1,
    15.24
  ],
  [
    33574,
    67.29809,
    15.19629,
    15.3,
    14.57
  ],
  [
    33576,
    67.29826,
    15.19577,
    15.6,
    14.04
  ],
  [
    33578,
    67.29841,
    15.19528,
    15.9,
    13.45
  ],
  [
    33580,
    67.29856,
    15.19482,
    16.2,
    13.1
  ],
  [
    33582,
    67.2987,
    15.19438,
    16.4,
    11.87
  ],
  [
    33584,
    67.29883,
    15.19397,
    16.7,
    10.95
  ],
  [
    33586,
    67.29895,
    15.1936,
    16.9,
    10.07
  ],
  [
    33588,
    67.29906,
    15.19325,
    17.1,
    9.43
  ],
  [
    33590,
    67.29916,
    15.19293,
    17.3,
    8.9
  ],
  [
    33592,
    67.29926,
    15.19262,
    17.5,
    8.37
  ],
  [
    33594,
    67.29935,
    15.19234,
    17.7,
    7.82
  ],
  [
    33596,
    67.29944,
    15.19207,
    17.9,
    7.27
  ],
  [
    33598,
    67.29952,
    15.19182,
    18.1,
    6.7
  ],
  [
    33600,
    67.29959,
    15.1916,
    18.3,
    6.14
  ],
  [
    33602,
    67.29966,
    15.19139,
    18.4,
    5.57
  ],
  [
    33604,
    67.29971,
    15.19121,
    18.6,
    5.04
  ],
  [
    33606,
    67.29977,
    15.19104,
    18.8,
    4.55
  ],
  [
    33608,
    67.29982,
    15.19089,
    18.9,
    4.11
  ],
  [
    33610,
    67.29986,
    15.19075,
    18.9,
    3.72
  ],
  [
    33612,
    67.2999,
    15.19063,
    19.2,
    3.37
  ],
  [
    33615,
    67.29995,
    15.19047,
    19.4,
    3.14
  ],
  [
    33617,
    67.29999,
    15.19036,
    19.6,
    3.06
  ],
  [
    33619,
    67.30002,
    15.19027,
    19.7,
    3.06
  ],
  [
    33621,
    67.30004,
    15.19019,
    19.8,
    2.28
  ],
  [
    33623,
    67.30006,
    15.19014,
    20,
    1.64
  ],
  [
    33625,
    67.30007,
    15.19011,
    20.1,
    0.82
  ],
  [
    33627,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33629,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33631,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33633,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33635,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33637,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33639,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33641,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33643,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33645,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33647,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33649,
    67.30007,
    15.19011,
    20.1,
    0
  ],
  [
    33651,
    67.30009,
    15.19008,
    21.3,
    1.52
  ],
  [
    33653,
    67.30011,
    15.19002,
    21.4,
    2.27
  ],
  [
    33655,
    67.30014,
    15.18992,
    21.5,
    3.12
  ],
  [
    33657,
    67.30018,
    15.1898,
    21.5,
    3.99
  ],
  [
    33659,
    67.30023,
    15.18964,
    21.6,
    4.7
  ],
  [
    33661,
    67.30029,
    15.18946,
    21.6,
    5.38
  ],
  [
    33663,
    67.30036,
    15.18926,
    21.7,
    5.99
  ],
  [
    33666,
    67.30047,
    15.18891,
    21.7,
    6.59
  ],
  [
    33668,
    67.30055,
    15.18865,
    21.7,
    6.87
  ],
  [
    33670,
    67.30064,
    15.18837,
    21.7,
    6.87
  ],
  [
    33672,
    67.30073,
    15.18807,
    21.7,
    8.02
  ],
  [
    33674,
    67.30083,
    15.18775,
    21.7,
    9.2
  ],
  [
    33676,
    67.30094,
    15.18741,
    21.7,
    9.81
  ],
  [
    33678,
    67.30106,
    15.18705,
    21.8,
    10.41
  ],
  [
    33680,
    67.30119,
    15.18669,
    21.8,
    10.71
  ],
  [
    33682,
    67.30134,
    15.18632,
    21.7,
    11.79
  ],
  [
    33684,
    67.30151,
    15.18595,
    21.6,
    12.52
  ],
  [
    33686,
    67.30169,
    15.18558,
    21.5,
    13.22
  ],
  [
    33688,
    67.30189,
    15.18522,
    21.3,
    13.86
  ],
  [
    33690,
    67.3021,
    15.18485,
    21.2,
    14.49
  ],
  [
    33692,
    67.30233,
    15.18447,
    21,
    15.11
  ],
  [
    33694,
    67.30256,
    15.1841,
    20.9,
    15.72
  ],
  [
    33696,
    67.30281,
    15.18373,
    20.8,
    16.28
  ],
  [
    33698,
    67.30308,
    15.1834,
    20.8,
    16.84
  ],
  [
    33700,
    67.30337,
    15.18312,
    20.7,
    17.41
  ],
  [
    33702,
    67.30368,
    15.1829,
    20.8,
    17.96
  ],
  [
    33704,
    67.304,
    15.18275,
    20.8,
    18.46
  ],
  [
    33706,
    67.30434,
    15.18267,
    20.7,
    18.93
  ],
  [
    33708,
    67.30468,
    15.18264,
    20.6,
    19.35
  ],
  [
    33710,
    67.30504,
    15.18265,
    20.6,
    19.72
  ],
  [
    33712,
    67.30539,
    15.18266,
    20.6,
    20.11
  ],
  [
    33715,
    67.30595,
    15.18267,
    20.5,
    20.5
  ],
  [
    33717,
    67.30632,
    15.18263,
    20.8,
    20.67
  ],
  [
    33719,
    67.3067,
    15.18259,
    20.9,
    20.67
  ],
  [
    33721,
    67.30709,
    15.18234,
    20.8,
    21.85
  ],
  [
    33723,
    67.30748,
    15.18211,
    20.7,
    21.94
  ],
  [
    33725,
    67.30787,
    15.18186,
    20.8,
    22.29
  ],
  [
    33727,
    67.30827,
    15.1816,
    20.7,
    22.82
  ],
  [
    33729,
    67.30868,
    15.18138,
    20.2,
    23.17
  ],
  [
    33731,
    67.30909,
    15.18121,
    19.9,
    23.49
  ],
  [
    33733,
    67.30951,
    15.18109,
    19.8,
    23.75
  ],
  [
    33735,
    67.30994,
    15.18101,
    19.8,
    23.98
  ],
  [
    33737,
    67.31037,
    15.18092,
    19.9,
    24.16
  ],
  [
    33739,
    67.31081,
    15.18083,
    20.1,
    24.3
  ],
  [
    33741,
    67.31125,
    15.18074,
    20.3,
    24.35
  ],
  [
    33743,
    67.31168,
    15.18059,
    20.7,
    24.4
  ],
  [
    33745,
    67.3121,
    15.18032,
    21.1,
    24.47
  ],
  [
    33747,
    67.31251,
    15.17992,
    21.6,
    24.48
  ],
  [
    33749,
    67.3129,
    15.17939,
    22,
    24.55
  ],
  [
    33751,
    67.31328,
    15.17879,
    22.1,
    24.68
  ],
  [
    33753,
    67.31365,
    15.17816,
    22.3,
    24.8
  ],
  [
    33755,
    67.31403,
    15.17753,
    22.6,
    24.99
  ],
  [
    33757,
    67.31441,
    15.17691,
    22.6,
    24.99
  ],
  [
    33781,
    67.31905,
    15.16955,
    25.9,
    25.55
  ],
  [
    33783,
    67.31949,
    15.16943,
    18.9,
    26.03
  ],
  [
    33785,
    67.31996,
    15.16937,
    18.9,
    26.24
  ],
  [
    33787,
    67.32044,
    15.16948,
    11,
    26.34
  ],
  [
    33790,
    67.32113,
    15.16991,
    8.2,
    26.47
  ],
  [
    33792,
    67.32159,
    15.1703,
    6.7,
    26.52
  ],
  [
    33794,
    67.32204,
    15.1707,
    6,
    26.52
  ],
  [
    33796,
    67.32249,
    15.17105,
    5.5,
    26.06
  ],
  [
    33798,
    67.32294,
    15.17131,
    5.2,
    25.72
  ],
  [
    33800,
    67.32339,
    15.17145,
    4.8,
    25.55
  ],
  [
    33802,
    67.32385,
    15.17147,
    4.5,
    25.39
  ],
  [
    33804,
    67.3243,
    15.17136,
    4.3,
    25.39
  ],
  [
    33806,
    67.32475,
    15.17113,
    4,
    25.41
  ],
  [
    33808,
    67.32519,
    15.17084,
    3.8,
    25.44
  ],
  [
    33810,
    67.32563,
    15.17054,
    3.6,
    25.45
  ],
  [
    33812,
    67.32608,
    15.17024,
    3.7,
    25.45
  ],
  [
    33814,
    67.32652,
    15.16994,
    3.7,
    25.43
  ],
  [
    33816,
    67.32696,
    15.16965,
    3.8,
    25.36
  ],
  [
    33818,
    67.3274,
    15.16935,
    3.9,
    25.27
  ],
  [
    33820,
    67.32783,
    15.16899,
    4,
    25.17
  ],
  [
    33822,
    67.32824,
    15.16851,
    4.2,
    25.07
  ],
  [
    33824,
    67.32862,
    15.16791,
    4.3,
    24.96
  ],
  [
    33826,
    67.32897,
    15.16718,
    4.4,
    24.89
  ],
  [
    33828,
    67.32929,
    15.16635,
    4.5,
    24.89
  ],
  [
    33830,
    67.32956,
    15.16542,
    4.6,
    24.92
  ],
  [
    33832,
    67.32978,
    15.16441,
    4.7,
    24.95
  ],
  [
    33834,
    67.32997,
    15.16335,
    4.7,
    25
  ],
  [
    33836,
    67.33013,
    15.16225,
    4.7,
    25.07
  ],
  [
    33839,
    67.33033,
    15.16057,
    4.7,
    25.14
  ],
  [
    33841,
    67.33044,
    15.15942,
    4.6,
    25.21
  ],
  [
    33843,
    67.33053,
    15.15826,
    4.7,
    25.21
  ],
  [
    33845,
    67.33058,
    15.15708,
    4.6,
    25.21
  ],
  [
    33847,
    67.33058,
    15.15589,
    4.6,
    25.31
  ],
  [
    33849,
    67.33051,
    15.15471,
    4.5,
    25.32
  ],
  [
    33851,
    67.3304,
    15.15356,
    4.5,
    25.31
  ],
  [
    33853,
    67.33022,
    15.15246,
    4.3,
    25.33
  ],
  [
    33855,
    67.33001,
    15.1514,
    4.2,
    25.36
  ],
  [
    33857,
    67.32979,
    15.15035,
    4.2,
    25.45
  ],
  [
    33859,
    67.32957,
    15.14929,
    4.5,
    25.5
  ],
  [
    33861,
    67.32939,
    15.14819,
    4.8,
    25.52
  ],
  [
    33863,
    67.32926,
    15.14705,
    4.8,
    25.54
  ],
  [
    33865,
    67.32916,
    15.14587,
    4.8,
    25.48
  ],
  [
    33867,
    67.32909,
    15.14469,
    4.6,
    25.53
  ],
  [
    33869,
    67.32902,
    15.14351,
    4.6,
    25.57
  ],
  [
    33871,
    67.32895,
    15.14233,
    4.3,
    25.61
  ],
  [
    33873,
    67.32888,
    15.14114,
    4.1,
    25.64
  ],
  [
    33875,
    67.32879,
    15.13996,
    3.6,
    25.68
  ],
  [
    33877,
    67.32865,
    15.13882,
    3.8,
    25.6
  ],
  [
    33879,
    67.32846,
    15.13772,
    3.9,
    25.63
  ],
  [
    33881,
    67.32822,
    15.1367,
    4,
    25.62
  ],
  [
    33883,
    67.32792,
    15.13576,
    4,
    25.65
  ],
  [
    33885,
    67.32758,
    15.13493,
    4,
    25.64
  ],
  [
    33887,
    67.32721,
    15.13421,
    4.3,
    25.69
  ],
  [
    33890,
    67.32663,
    15.13318,
    4.2,
    25.75
  ],
  [
    33892,
    67.32625,
    15.13248,
    4.1,
    25.76
  ],
  [
    33894,
    67.3259,
    15.13168,
    4.1,
    25.76
  ],
  [
    33896,
    67.32559,
    15.13077,
    4,
    25.8
  ],
  [
    33898,
    67.32533,
    15.12977,
    3.8,
    25.8
  ],
  [
    33900,
    67.32513,
    15.12869,
    3.7,
    25.84
  ],
  [
    33902,
    67.32497,
    15.12755,
    3.5,
    25.83
  ],
  [
    33904,
    67.32485,
    15.12639,
    3.4,
    25.86
  ],
  [
    33906,
    67.32473,
    15.12522,
    3.3,
    25.88
  ],
  [
    33908,
    67.32461,
    15.12405,
    3.2,
    25.89
  ],
  [
    33910,
    67.32449,
    15.12288,
    3.1,
    25.97
  ],
  [
    33912,
    67.32437,
    15.12171,
    3,
    25.98
  ],
  [
    33914,
    67.32425,
    15.12054,
    2.9,
    25.94
  ],
  [
    33916,
    67.32412,
    15.11938,
    2.9,
    25.97
  ],
  [
    33918,
    67.32398,
    15.11823,
    3,
    26
  ],
  [
    33920,
    67.32385,
    15.11713,
    3.2,
    24.31
  ],
  [
    33922,
    67.32368,
    15.11596,
    4.1,
    25.65
  ],
  [
    33924,
    67.32349,
    15.11484,
    3.9,
    25.7
  ],
  [
    33926,
    67.32328,
    15.11375,
    4.1,
    25.66
  ],
  [
    33928,
    67.32306,
    15.11267,
    4.9,
    25.67
  ],
  [
    33930,
    67.32287,
    15.11158,
    5.3,
    25.67
  ],
  [
    33932,
    67.32269,
    15.11047,
    5.7,
    25.66
  ],
  [
    33934,
    67.32254,
    15.10934,
    5.8,
    25.61
  ],
  [
    33936,
    67.32241,
    15.1082,
    5.9,
    25.61
  ],
  [
    33939,
    67.32226,
    15.10645,
    6,
    25.58
  ],
  [
    33941,
    67.32222,
    15.10527,
    6.1,
    25.54
  ],
  [
    33943,
    67.32224,
    15.10408,
    6.3,
    25.54
  ],
  [
    33945,
    67.3223,
    15.10291,
    6.2,
    25.54
  ],
  [
    33947,
    67.32238,
    15.10174,
    6.1,
    25.38
  ],
  [
    33949,
    67.32245,
    15.10057,
    6.1,
    25.43
  ],
  [
    33951,
    67.32249,
    15.09939,
    6.2,
    25.42
  ],
  [
    33953,
    67.32248,
    15.09821,
    6.3,
    25.45
  ],
  [
    33955,
    67.32242,
    15.09703,
    6.4,
    25.45
  ],
  [
    33957,
    67.32234,
    15.09586,
    6.7,
    25.53
  ],
  [
    33959,
    67.32226,
    15.0947,
    6.9,
    25.55
  ],
  [
    33961,
    67.32218,
    15.09352,
    6.9,
    25.6
  ],
  [
    33963,
    67.3221,
    15.09235,
    6.9,
    25.63
  ],
  [
    33965,
    67.32202,
    15.09117,
    6.9,
    25.69
  ],
  [
    33967,
    67.32194,
    15.09,
    6.9,
    25.72
  ],
  [
    33969,
    67.32186,
    15.08882,
    6.9,
    25.75
  ],
  [
    33971,
    67.32178,
    15.08764,
    6.9,
    25.77
  ],
  [
    33973,
    67.32167,
    15.08647,
    6.9,
    25.78
  ],
  [
    33975,
    67.32153,
    15.08533,
    6.9,
    25.78
  ],
  [
    33977,
    67.32136,
    15.08422,
    6.9,
    25.81
  ],
  [
    33979,
    67.32117,
    15.08311,
    6.9,
    25.85
  ],
  [
    33981,
    67.32099,
    15.08201,
    7.1,
    25.86
  ],
  [
    33983,
    67.32084,
    15.08088,
    7.6,
    25.77
  ],
  [
    33985,
    67.32074,
    15.07971,
    7.6,
    25.63
  ],
  [
    33987,
    67.3207,
    15.07852,
    8.2,
    25.53
  ],
  [
    33990,
    67.32076,
    15.07675,
    8.7,
    25.44
  ],
  [
    33992,
    67.32087,
    15.0756,
    8.8,
    25.45
  ],
  [
    33994,
    67.32104,
    15.0745,
    8.6,
    25.45
  ],
  [
    33996,
    67.32126,
    15.07345,
    8.4,
    25.46
  ],
  [
    33998,
    67.32151,
    15.07245,
    8.4,
    25.48
  ],
  [
    34000,
    67.32176,
    15.07145,
    8.6,
    25.54
  ],
  [
    34002,
    67.322,
    15.07043,
    8.7,
    25.56
  ],
  [
    34004,
    67.3222,
    15.06935,
    8.8,
    25.56
  ],
  [
    34006,
    67.32234,
    15.06822,
    8.8,
    25.55
  ],
  [
    34008,
    67.32242,
    15.06705,
    8.8,
    25.55
  ],
  [
    34010,
    67.32244,
    15.06586,
    8.7,
    25.57
  ],
  [
    34012,
    67.32243,
    15.06467,
    8.6,
    25.62
  ],
  [
    34014,
    67.32242,
    15.06347,
    8.6,
    25.68
  ],
  [
    34016,
    67.32242,
    15.06228,
    8.6,
    25.74
  ],
  [
    34018,
    67.32243,
    15.06108,
    8.6,
    25.77
  ],
  [
    34020,
    67.32246,
    15.05988,
    8.4,
    25.79
  ],
  [
    34022,
    67.3225,
    15.05868,
    8.3,
    25.82
  ],
  [
    34024,
    67.32254,
    15.05749,
    8.3,
    25.85
  ],
  [
    34026,
    67.32257,
    15.05629,
    8.3,
    25.85
  ],
  [
    34028,
    67.3226,
    15.05509,
    8.2,
    25.81
  ],
  [
    34030,
    67.32257,
    15.05389,
    8.2,
    25.76
  ],
  [
    34032,
    67.32249,
    15.05271,
    8.2,
    25.72
  ],
  [
    34034,
    67.32236,
    15.05156,
    8.1,
    25.72
  ],
  [
    34036,
    67.32222,
    15.05043,
    8.3,
    25.72
  ],
  [
    34039,
    67.32202,
    15.0487,
    8.9,
    25.7
  ],
  [
    34041,
    67.32194,
    15.04753,
    9.1,
    25.63
  ],
  [
    34043,
    67.32192,
    15.04634,
    9.3,
    25.63
  ],
  [
    34045,
    67.32196,
    15.04516,
    9.5,
    25.48
  ],
  [
    34047,
    67.32202,
    15.04399,
    9.5,
    25.38
  ],
  [
    34049,
    67.32208,
    15.04282,
    9.7,
    25.37
  ],
  [
    34051,
    67.32215,
    15.04165,
    10,
    25.4
  ],
  [
    34053,
    67.3222,
    15.04048,
    10,
    25.26
  ],
  [
    34055,
    67.32223,
    15.03931,
    10.3,
    25.28
  ],
  [
    34057,
    67.3222,
    15.03814,
    10.6,
    25.17
  ],
  [
    34059,
    67.32211,
    15.037,
    10.8,
    25.11
  ],
  [
    34061,
    67.32197,
    15.03589,
    10.9,
    25.08
  ],
  [
    34063,
    67.32178,
    15.03483,
    11.2,
    25.05
  ],
  [
    34065,
    67.32157,
    15.0338,
    11.4,
    25.04
  ],
  [
    34067,
    67.32134,
    15.0328,
    11.5,
    25.02
  ],
  [
    34069,
    67.3211,
    15.03181,
    11.5,
    25.05
  ],
  [
    34071,
    67.32084,
    15.03085,
    11.5,
    25.1
  ],
  [
    34073,
    67.32057,
    15.02991,
    11.4,
    25.18
  ],
  [
    34075,
    67.32029,
    15.02899,
    11.7,
    25.24
  ],
  [
    34077,
    67.31998,
    15.02812,
    12.1,
    25.44
  ],
  [
    34079,
    67.31964,
    15.02732,
    12.7,
    25.42
  ],
  [
    34081,
    67.31927,
    15.02663,
    12.7,
    25.46
  ],
  [
    34083,
    67.31886,
    15.02605,
    12.8,
    25.53
  ],
  [
    34086,
    67.31822,
    15.02538,
    12.5,
    25.6
  ],
  [
    34088,
    67.31778,
    15.02498,
    12.3,
    25.66
  ],
  [
    34090,
    67.31734,
    15.02458,
    12.4,
    25.66
  ],
  [
    34092,
    67.3169,
    15.02414,
    12.7,
    25.76
  ],
  [
    34094,
    67.31649,
    15.02361,
    13,
    25.75
  ],
  [
    34096,
    67.3161,
    15.02297,
    13,
    25.77
  ],
  [
    34098,
    67.31572,
    15.02227,
    12.9,
    25.75
  ],
  [
    34100,
    67.31535,
    15.02156,
    12.8,
    25.78
  ],
  [
    34102,
    67.31497,
    15.02085,
    12.9,
    25.92
  ],
  [
    34104,
    67.3146,
    15.02014,
    12.8,
    26.07
  ],
  [
    34106,
    67.31422,
    15.01942,
    12.6,
    26.23
  ],
  [
    34108,
    67.31384,
    15.01869,
    12.5,
    26.45
  ],
  [
    34110,
    67.31346,
    15.01794,
    12.4,
    26.69
  ],
  [
    34112,
    67.3131,
    15.01711,
    12.1,
    26.88
  ],
  [
    34114,
    67.31276,
    15.01622,
    11.6,
    27.18
  ],
  [
    34116,
    67.31243,
    15.01528,
    10.8,
    27.52
  ],
  [
    34118,
    67.3121,
    15.01431,
    10.1,
    27.89
  ],
  [
    34120,
    67.31177,
    15.01332,
    9.3,
    28.25
  ],
  [
    34122,
    67.31143,
    15.01232,
    8.7,
    28.61
  ],
  [
    34124,
    67.31109,
    15.01131,
    8.1,
    28.94
  ],
  [
    34126,
    67.31075,
    15.01029,
    7.9,
    29.23
  ],
  [
    34128,
    67.3104,
    15.00926,
    7.7,
    29.45
  ],
  [
    34130,
    67.31005,
    15.00823,
    7.7,
    29.65
  ],
  [
    34132,
    67.3097,
    15.00718,
    7.4,
    29.81
  ],
  [
    34134,
    67.30935,
    15.00613,
    7.3,
    29.99
  ],
  [
    34137,
    67.30882,
    15.00454,
    7.1,
    30.18
  ],
  [
    34139,
    67.30846,
    15.00348,
    7.1,
    30.18
  ],
  [
    34141,
    67.30809,
    15.00244,
    6.9,
    30.53
  ],
  [
    34143,
    67.3077,
    15.00143,
    6.8,
    30.53
  ],
  [
    34145,
    67.30729,
    15.00047,
    6.8,
    30.71
  ],
  [
    34147,
    67.30687,
    14.99956,
    6.8,
    30.61
  ],
  [
    34149,
    67.30644,
    14.99867,
    6.6,
    30.56
  ],
  [
    34151,
    67.30601,
    14.99779,
    6.5,
    30.5
  ],
  [
    34153,
    67.30558,
    14.99691,
    6.4,
    30.42
  ],
  [
    34155,
    67.30515,
    14.99603,
    6.3,
    30.37
  ],
  [
    34157,
    67.30472,
    14.99516,
    6.3,
    30.25
  ],
  [
    34159,
    67.3043,
    14.99428,
    6.3,
    30.17
  ],
  [
    34161,
    67.30387,
    14.99341,
    6.2,
    30.1
  ],
  [
    34163,
    67.30345,
    14.99254,
    6.1,
    30.03
  ],
  [
    34165,
    67.30303,
    14.99168,
    6,
    29.95
  ],
  [
    34167,
    67.30261,
    14.99081,
    5.9,
    29.88
  ],
  [
    34169,
    67.30219,
    14.98995,
    5.8,
    29.81
  ],
  [
    34171,
    67.30177,
    14.98909,
    5.8,
    29.74
  ],
  [
    34173,
    67.30135,
    14.98824,
    5.7,
    29.67
  ],
  [
    34175,
    67.30093,
    14.9874,
    5.7,
    29.6
  ],
  [
    34177,
    67.3005,
    14.98657,
    5.9,
    29.5
  ],
  [
    34179,
    67.30008,
    14.98576,
    6.4,
    29.36
  ],
  [
    34181,
    67.29966,
    14.98496,
    6.4,
    28.87
  ],
  [
    34183,
    67.29924,
    14.98418,
    7.8,
    28.09
  ],
  [
    34186,
    67.29864,
    14.98309,
    8.9,
    27.2
  ],
  [
    34188,
    67.29825,
    14.9824,
    9.5,
    27.2
  ],
  [
    34190,
    67.29787,
    14.98173,
    9.9,
    27.2
  ],
  [
    34192,
    67.2975,
    14.98106,
    10.1,
    25.49
  ],
  [
    34194,
    67.29713,
    14.98038,
    10.3,
    25.08
  ],
  [
    34196,
    67.29679,
    14.97962,
    10.4,
    24.9
  ],
  [
    34198,
    67.29648,
    14.9788,
    10.3,
    24.89
  ],
  [
    34200,
    67.2962,
    14.9779,
    10,
    24.91
  ],
  [
    34202,
    67.29595,
    14.97693,
    9.6,
    25.03
  ],
  [
    34204,
    67.29574,
    14.97591,
    9.5,
    25.09
  ],
  [
    34206,
    67.29556,
    14.97483,
    9.3,
    25.16
  ],
  [
    34208,
    67.29543,
    14.97372,
    9,
    25.22
  ],
  [
    34210,
    67.29532,
    14.97257,
    8.8,
    25.3
  ],
  [
    34212,
    67.29523,
    14.97142,
    8.6,
    25.37
  ],
  [
    34214,
    67.29514,
    14.97026,
    8.4,
    25.45
  ],
  [
    34216,
    67.29506,
    14.96909,
    8.2,
    25.54
  ],
  [
    34218,
    67.29497,
    14.96792,
    8,
    25.6
  ],
  [
    34220,
    67.29488,
    14.96675,
    7.9,
    25.64
  ],
  [
    34222,
    67.29479,
    14.96558,
    7.9,
    25.64
  ],
  [
    34224,
    67.2947,
    14.96441,
    8.1,
    25.61
  ],
  [
    34226,
    67.29462,
    14.96324,
    8.4,
    25.56
  ],
  [
    34228,
    67.29457,
    14.96206,
    8.7,
    25.51
  ],
  [
    34230,
    67.29455,
    14.96087,
    9,
    25.41
  ],
  [
    34232,
    67.29456,
    14.95969,
    9,
    25.31
  ],
  [
    34234,
    67.29459,
    14.95851,
    9.3,
    25.3
  ],
  [
    34237,
    67.29462,
    14.95672,
    9.6,
    25.3
  ],
  [
    34239,
    67.29465,
    14.95552,
    9.7,
    25.3
  ],
  [
    34241,
    67.2946,
    14.95425,
    10,
    25.89
  ],
  [
    34243,
    67.29439,
    14.95318,
    9.7,
    25.09
  ],
  [
    34245,
    67.29421,
    14.95211,
    9.9,
    25.15
  ],
  [
    34247,
    67.29403,
    14.95106,
    9.8,
    25.11
  ],
  [
    34249,
    67.29386,
    14.94999,
    9.8,
    25.05
  ],
  [
    34251,
    67.29371,
    14.94891,
    9.8,
    25.03
  ],
  [
    34253,
    67.29357,
    14.94781,
    9.6,
    25.02
  ],
  [
    34255,
    67.29345,
    14.94669,
    9.4,
    25.08
  ],
  [
    34257,
    67.29333,
    14.94557,
    9.4,
    25.17
  ],
  [
    34259,
    67.29318,
    14.94447,
    9.3,
    25.21
  ],
  [
    34261,
    67.29298,
    14.94342,
    9.2,
    25.3
  ],
  [
    34263,
    67.29273,
    14.94243,
    9.2,
    25.33
  ],
  [
    34265,
    67.29246,
    14.94148,
    9.2,
    25.4
  ],
  [
    34267,
    67.29219,
    14.94053,
    9.2,
    25.49
  ],
  [
    34269,
    67.29193,
    14.93955,
    9.3,
    25.61
  ],
  [
    34271,
    67.2917,
    14.93851,
    9.5,
    25.68
  ],
  [
    34273,
    67.29152,
    14.93742,
    9.5,
    25.79
  ],
  [
    34275,
    67.29138,
    14.93627,
    9.4,
    25.92
  ],
  [
    34277,
    67.29127,
    14.9351,
    9.2,
    26.07
  ],
  [
    34279,
    67.29119,
    14.9339,
    9.1,
    26.25
  ],
  [
    34281,
    67.29113,
    14.93269,
    9,
    26.44
  ],
  [
    34284,
    67.29108,
    14.93083,
    9,
    26.7
  ],
  [
    34286,
    67.29107,
    14.92958,
    9,
    26.93
  ],
  [
    34288,
    67.29108,
    14.92831,
    9.1,
    26.93
  ],
  [
    34290,
    67.29112,
    14.92704,
    9.2,
    26.93
  ],
  [
    34292,
    67.29118,
    14.92577,
    9.3,
    27.57
  ],
  [
    34294,
    67.29127,
    14.92449,
    9.5,
    27.76
  ],
  [
    34296,
    67.29138,
    14.92323,
    9.8,
    28.01
  ],
  [
    34298,
    67.29154,
    14.92198,
    9.9,
    28.11
  ],
  [
    34300,
    67.29173,
    14.92077,
    9.8,
    28.15
  ],
  [
    34302,
    67.29194,
    14.91956,
    9.7,
    28.25
  ],
  [
    34304,
    67.29214,
    14.91836,
    9.8,
    28.34
  ],
  [
    34306,
    67.29234,
    14.91714,
    10,
    28.36
  ],
  [
    34308,
    67.29252,
    14.91591,
    10.2,
    28.31
  ],
  [
    34310,
    67.29267,
    14.91464,
    10.5,
    28.2
  ],
  [
    34312,
    67.29279,
    14.91337,
    10.8,
    28.02
  ],
  [
    34314,
    67.2929,
    14.91211,
    10.9,
    27.82
  ],
  [
    34316,
    67.29302,
    14.91085,
    10.9,
    27.66
  ],
  [
    34318,
    67.29315,
    14.90962,
    10.7,
    27.54
  ],
  [
    34320,
    67.29332,
    14.90842,
    10.5,
    27.45
  ],
  [
    34322,
    67.29353,
    14.90726,
    10.5,
    27.36
  ],
  [
    34324,
    67.29377,
    14.90615,
    10.4,
    27.23
  ],
  [
    34326,
    67.29405,
    14.90513,
    10.1,
    26.69
  ],
  [
    34328,
    67.29436,
    14.90419,
    10.1,
    26.08
  ],
  [
    34330,
    67.29468,
    14.90332,
    9.8,
    25.39
  ],
  [
    34332,
    67.295,
    14.90249,
    9.5,
    24.82
  ],
  [
    34335,
    67.29549,
    14.90133,
    9,
    24.53
  ],
  [
    34337,
    67.29581,
    14.90057,
    8.7,
    24.45
  ],
  [
    34339,
    67.29614,
    14.89982,
    8.4,
    24.45
  ],
  [
    34341,
    67.29647,
    14.89907,
    8.2,
    24.33
  ],
  [
    34343,
    67.29678,
    14.89828,
    8,
    23.71
  ],
  [
    34345,
    67.29704,
    14.89745,
    8,
    23.43
  ],
  [
    34347,
    67.29727,
    14.89656,
    8.1,
    22.45
  ],
  [
    34349,
    67.29744,
    14.89564,
    8.2,
    21.75
  ],
  [
    34351,
    67.29757,
    14.89471,
    8.3,
    21.1
  ],
  [
    34353,
    67.29766,
    14.89377,
    8.3,
    20.46
  ],
  [
    34355,
    67.29771,
    14.89284,
    8.7,
    19.84
  ],
  [
    34357,
    67.29772,
    14.89194,
    9,
    19.25
  ],
  [
    34359,
    67.29771,
    14.89105,
    9.6,
    18.87
  ],
  [
    34361,
    67.29768,
    14.89019,
    10.2,
    18.54
  ],
  [
    34363,
    67.29765,
    14.88934,
    10.8,
    18.17
  ],
  [
    34365,
    67.29762,
    14.8885,
    11.4,
    17.76
  ],
  [
    34367,
    67.2976,
    14.88769,
    12.1,
    17.23
  ],
  [
    34369,
    67.29761,
    14.88691,
    12.8,
    16.61
  ],
  [
    34371,
    67.29764,
    14.88616,
    13.3,
    15.96
  ],
  [
    34373,
    67.29769,
    14.88545,
    13.6,
    15.29
  ],
  [
    34375,
    67.29775,
    14.88477,
    13.9,
    14.84
  ],
  [
    34377,
    67.29783,
    14.88413,
    14.1,
    14.44
  ],
  [
    34379,
    67.29792,
    14.8835,
    14.1,
    14.16
  ],
  [
    34381,
    67.29801,
    14.8829,
    14.4,
    13.9
  ],
  [
    34384,
    67.29814,
    14.88201,
    14.3,
    13.64
  ],
  [
    34386,
    67.29823,
    14.88143,
    14.3,
    13.41
  ],
  [
    34388,
    67.29831,
    14.88085,
    14.1,
    13.41
  ],
  [
    34390,
    67.29838,
    14.88028,
    14,
    13.41
  ],
  [
    34392,
    67.29845,
    14.87972,
    13.9,
    12.82
  ],
  [
    34394,
    67.29852,
    14.87915,
    13.9,
    12.64
  ],
  [
    34396,
    67.29858,
    14.8786,
    14,
    12.4
  ],
  [
    34398,
    67.29864,
    14.87805,
    14.1,
    12.23
  ],
  [
    34400,
    67.29869,
    14.8775,
    14.2,
    12.14
  ],
  [
    34402,
    67.29874,
    14.87696,
    14.2,
    11.88
  ],
  [
    34404,
    67.29879,
    14.87643,
    14.3,
    11.72
  ],
  [
    34406,
    67.29884,
    14.8759,
    14.3,
    11.56
  ],
  [
    34408,
    67.29888,
    14.87538,
    14.3,
    11.41
  ],
  [
    34410,
    67.29892,
    14.87487,
    14.3,
    11.29
  ],
  [
    34412,
    67.29897,
    14.87436,
    14.5,
    11.26
  ],
  [
    34414,
    67.29901,
    14.87385,
    14.6,
    11.3
  ],
  [
    34416,
    67.29905,
    14.87334,
    14.6,
    11.36
  ],
  [
    34418,
    67.2991,
    14.87282,
    14.6,
    11.44
  ],
  [
    34420,
    67.29914,
    14.8723,
    14.5,
    11.53
  ],
  [
    34422,
    67.29919,
    14.87178,
    14.5,
    11.63
  ],
  [
    34424,
    67.29923,
    14.87125,
    14.4,
    11.73
  ],
  [
    34426,
    67.29928,
    14.87071,
    14.4,
    11.87
  ],
  [
    34428,
    67.29932,
    14.87017,
    14.4,
    12.02
  ],
  [
    34430,
    67.29937,
    14.86962,
    14.3,
    12.15
  ],
  [
    34433,
    67.29944,
    14.86879,
    14.3,
    12.27
  ],
  [
    34435,
    67.29949,
    14.86823,
    14.3,
    12.33
  ],
  [
    34437,
    67.29954,
    14.86766,
    14.3,
    12.44
  ],
  [
    34439,
    67.29958,
    14.86709,
    14.3,
    12.44
  ],
  [
    34441,
    67.29963,
    14.86651,
    14.3,
    12.7
  ],
  [
    34443,
    67.29967,
    14.86592,
    14.2,
    12.77
  ],
  [
    34445,
    67.2997,
    14.86533,
    14.3,
    12.87
  ],
  [
    34447,
    67.29973,
    14.86474,
    14.4,
    12.9
  ],
  [
    34449,
    67.29974,
    14.86414,
    14.6,
    12.89
  ],
  [
    34451,
    67.29974,
    14.86354,
    14.8,
    12.91
  ],
  [
    34453,
    67.29974,
    14.86294,
    15.1,
    12.88
  ],
  [
    34455,
    67.29972,
    14.86234,
    15.5,
    12.87
  ],
  [
    34457,
    67.29969,
    14.86175,
    15.9,
    12.89
  ],
  [
    34459,
    67.29966,
    14.86115,
    16.3,
    12.95
  ],
  [
    34461,
    67.29961,
    14.86056,
    16.6,
    13.06
  ],
  [
    34463,
    67.29957,
    14.85996,
    16.9,
    13.18
  ],
  [
    34465,
    67.29952,
    14.85936,
    17.3,
    13.32
  ],
  [
    34467,
    67.29947,
    14.85874,
    17.9,
    13.51
  ],
  [
    34469,
    67.29942,
    14.85812,
    18.4,
    13.75
  ],
  [
    34471,
    67.29937,
    14.85749,
    18.7,
    14.05
  ],
  [
    34473,
    67.29931,
    14.85684,
    18.9,
    14.42
  ],
  [
    34475,
    67.29926,
    14.85617,
    19,
    14.96
  ],
  [
    34477,
    67.2992,
    14.85547,
    19.3,
    15.45
  ],
  [
    34479,
    67.29915,
    14.85476,
    19.3,
    15.97
  ],
  [
    34481,
    67.29909,
    14.85401,
    19.7,
    16.54
  ],
  [
    34484,
    67.29899,
    14.85285,
    20,
    17.11
  ],
  [
    34486,
    67.29893,
    14.85204,
    20.1,
    17.65
  ],
  [
    34488,
    67.29886,
    14.85121,
    20.3,
    17.65
  ],
  [
    34490,
    67.2988,
    14.85036,
    20.5,
    18.64
  ],
  [
    34492,
    67.29873,
    14.84949,
    20.8,
    19.29
  ],
  [
    34494,
    67.29866,
    14.84859,
    21.1,
    19.69
  ],
  [
    34496,
    67.29859,
    14.84769,
    21.7,
    20.02
  ],
  [
    34498,
    67.29852,
    14.84676,
    22.3,
    20.32
  ],
  [
    34500,
    67.29844,
    14.84583,
    22.9,
    20.56
  ],
  [
    34502,
    67.29837,
    14.84488,
    23.6,
    20.78
  ],
  [
    34504,
    67.2983,
    14.84393,
    24.1,
    20.95
  ],
  [
    34506,
    67.29822,
    14.84297,
    24.9,
    21.1
  ],
  [
    34508,
    67.29814,
    14.842,
    25.7,
    21.23
  ],
  [
    34510,
    67.29805,
    14.84104,
    26.5,
    21.31
  ],
  [
    34512,
    67.29793,
    14.8401,
    27.3,
    21.42
  ],
  [
    34514,
    67.29777,
    14.83918,
    28.1,
    21.51
  ],
  [
    34516,
    67.29759,
    14.83829,
    28.9,
    21.6
  ],
  [
    34518,
    67.29738,
    14.83744,
    29.8,
    21.7
  ],
  [
    34520,
    67.29716,
    14.8366,
    30.6,
    21.81
  ],
  [
    34522,
    67.29694,
    14.83576,
    30.6,
    21.9
  ],
  [
    34524,
    67.29672,
    14.83491,
    31.8,
    22
  ],
  [
    34527,
    67.29644,
    14.83355,
    32.9,
    22.07
  ],
  [
    34529,
    67.29628,
    14.8326,
    33.7,
    22.11
  ],
  [
    34531,
    67.29616,
    14.83162,
    34.4,
    22.24
  ],
  [
    34533,
    67.29606,
    14.83061,
    35.1,
    22.24
  ],
  [
    34535,
    67.29599,
    14.82959,
    35.8,
    22.43
  ],
  [
    34537,
    67.29592,
    14.82855,
    36.5,
    22.5
  ],
  [
    34539,
    67.29587,
    14.82751,
    37.2,
    22.66
  ],
  [
    34541,
    67.29584,
    14.82645,
    37.9,
    22.75
  ],
  [
    34543,
    67.29582,
    14.82539,
    38.7,
    22.83
  ],
  [
    34545,
    67.29582,
    14.82433,
    39.6,
    22.87
  ],
  [
    34547,
    67.29585,
    14.82326,
    40.3,
    23
  ],
  [
    34549,
    67.29592,
    14.82221,
    40.9,
    23.05
  ],
  [
    34551,
    67.29601,
    14.82116,
    41.3,
    23.11
  ],
  [
    34553,
    67.29611,
    14.82011,
    41.7,
    23.21
  ],
  [
    34555,
    67.29621,
    14.81906,
    42.2,
    23.32
  ],
  [
    34557,
    67.29631,
    14.818,
    42.7,
    23.41
  ],
  [
    34559,
    67.2964,
    14.81694,
    43.4,
    23.46
  ],
  [
    34561,
    67.2965,
    14.81587,
    44,
    23.55
  ],
  [
    34563,
    67.29661,
    14.81481,
    44.7,
    23.6
  ],
  [
    34565,
    67.29675,
    14.81377,
    45.2,
    23.65
  ],
  [
    34567,
    67.29692,
    14.81276,
    45.5,
    23.71
  ],
  [
    34569,
    67.29711,
    14.81176,
    45.7,
    23.77
  ],
  [
    34571,
    67.2973,
    14.81077,
    45.7,
    23.84
  ],
  [
    34573,
    67.2975,
    14.80978,
    47.2,
    23.92
  ],
  [
    34576,
    67.29778,
    14.80828,
    48.4,
    23.95
  ],
  [
    34578,
    67.29794,
    14.80724,
    49.1,
    23.95
  ],
  [
    34580,
    67.29806,
    14.80618,
    49.9,
    23.95
  ],
  [
    34582,
    67.29815,
    14.80508,
    50.5,
    24
  ],
  [
    34584,
    67.2982,
    14.80397,
    51.2,
    24.06
  ],
  [
    34586,
    67.29821,
    14.80285,
    52.1,
    24.11
  ],
  [
    34588,
    67.29819,
    14.80173,
    52.8,
    24.13
  ],
  [
    34590,
    67.29812,
    14.80062,
    53.6,
    24.16
  ],
  [
    34592,
    67.29802,
    14.79952,
    54.5,
    24.22
  ],
  [
    34594,
    67.29788,
    14.79845,
    55.4,
    24.28
  ],
  [
    34596,
    67.29771,
    14.79741,
    56.2,
    24.36
  ],
  [
    34598,
    67.29754,
    14.79637,
    57.2,
    24.42
  ],
  [
    34600,
    67.29736,
    14.79532,
    58.1,
    24.49
  ],
  [
    34602,
    67.29718,
    14.79428,
    59.1,
    24.54
  ],
  [
    34604,
    67.297,
    14.79323,
    60,
    24.59
  ],
  [
    34606,
    67.29683,
    14.79218,
    60.9,
    24.61
  ],
  [
    34608,
    67.29665,
    14.79113,
    61.8,
    24.67
  ],
  [
    34610,
    67.29647,
    14.79008,
    62.7,
    24.7
  ],
  [
    34612,
    67.2963,
    14.78902,
    63.7,
    24.74
  ],
  [
    34614,
    67.29616,
    14.78793,
    64.2,
    24.81
  ],
  [
    34616,
    67.29605,
    14.7868,
    64.2,
    24.87
  ],
  [
    34618,
    67.29599,
    14.78566,
    65.5,
    24.98
  ],
  [
    34621,
    67.29599,
    14.78391,
    65.8,
    25.15
  ],
  [
    34623,
    67.29603,
    14.78273,
    65.8,
    25.15
  ],
  [
    34625,
    67.29608,
    14.78155,
    65.9,
    25.15
  ],
  [
    34627,
    67.29614,
    14.78035,
    65.9,
    25.9
  ],
  [
    34629,
    67.2962,
    14.77914,
    65.7,
    26.11
  ],
  [
    34631,
    67.29625,
    14.77794,
    65.4,
    26.14
  ],
  [
    34633,
    67.29631,
    14.77673,
    65.1,
    26.18
  ],
  [
    34635,
    67.29637,
    14.77552,
    64.8,
    26.18
  ],
  [
    34654,
    67.29665,
    14.76358,
    61.2,
    27.45
  ],
  [
    34656,
    67.29656,
    14.76239,
    58.5,
    27.62
  ],
  [
    34658,
    67.29647,
    14.76114,
    56.7,
    27.81
  ],
  [
    34660,
    67.29639,
    14.75988,
    54.8,
    28.08
  ],
  [
    34662,
    67.29634,
    14.75859,
    53.1,
    28.02
  ],
  [
    34664,
    67.29633,
    14.75731,
    51.5,
    27.63
  ],
  [
    34666,
    67.29636,
    14.75604,
    50.6,
    27.19
  ],
  [
    34668,
    67.29643,
    14.7548,
    48.9,
    26.78
  ],
  [
    34670,
    67.29655,
    14.7536,
    47.2,
    26.32
  ],
  [
    34672,
    67.29669,
    14.75245,
    47.2,
    25.9
  ],
  [
    34674,
    67.29687,
    14.75135,
    44.6,
    25.54
  ],
  [
    34677,
    67.29723,
    14.74984,
    43,
    25.24
  ],
  [
    34679,
    67.29752,
    14.74896,
    42.2,
    25.24
  ],
  [
    34681,
    67.29784,
    14.74818,
    41.4,
    24.69
  ],
  [
    34683,
    67.2982,
    14.74751,
    40.6,
    24.43
  ],
  [
    34685,
    67.29858,
    14.74697,
    39.7,
    24.2
  ],
  [
    34687,
    67.29899,
    14.74654,
    38.9,
    24.24
  ],
  [
    34689,
    67.29941,
    14.74625,
    38.1,
    24.35
  ],
  [
    34691,
    67.29985,
    14.74609,
    37.1,
    24.61
  ],
  [
    34693,
    67.3003,
    14.74607,
    36.1,
    24.83
  ],
  [
    34695,
    67.30075,
    14.74613,
    35.4,
    25.08
  ],
  [
    34697,
    67.30121,
    14.74621,
    34.7,
    25.36
  ],
  [
    34699,
    67.30167,
    14.74628,
    34,
    25.62
  ],
  [
    34701,
    67.30214,
    14.74636,
    33.3,
    25.87
  ],
  [
    34703,
    67.30261,
    14.74643,
    32.6,
    26.09
  ],
  [
    34705,
    67.30308,
    14.74651,
    32,
    26.3
  ],
  [
    34707,
    67.30355,
    14.74658,
    31.5,
    26.48
  ],
  [
    34709,
    67.30403,
    14.74662,
    31.1,
    26.63
  ],
  [
    34711,
    67.30451,
    14.7466,
    30.7,
    26.74
  ],
  [
    34713,
    67.30499,
    14.74649,
    30.3,
    26.82
  ],
  [
    34715,
    67.30547,
    14.74629,
    29.8,
    26.89
  ],
  [
    34717,
    67.30595,
    14.74601,
    29.8,
    26.98
  ],
  [
    34719,
    67.30641,
    14.74566,
    29.4,
    27.04
  ],
  [
    34722,
    67.30711,
    14.74507,
    29.3,
    27.06
  ],
  [
    34724,
    67.30757,
    14.74467,
    29.2,
    27.06
  ],
  [
    34726,
    67.30803,
    14.74428,
    28.9,
    26.98
  ],
  [
    34728,
    67.30849,
    14.74388,
    28.8,
    26.93
  ],
  [
    34730,
    67.30895,
    14.74349,
    28.6,
    26.88
  ],
  [
    34732,
    67.3094,
    14.7431,
    28.4,
    26.81
  ],
  [
    34734,
    67.30986,
    14.7427,
    28.2,
    26.78
  ],
  [
    34736,
    67.31032,
    14.74231,
    28,
    26.77
  ],
  [
    34738,
    67.31077,
    14.74192,
    27.8,
    26.75
  ],
  [
    34740,
    67.31123,
    14.74153,
    27.4,
    26.75
  ],
  [
    34742,
    67.31169,
    14.74114,
    26.9,
    27.01
  ],
  [
    34744,
    67.31215,
    14.74074,
    26.4,
    27.01
  ],
  [
    34746,
    67.31261,
    14.74035,
    25.9,
    27.01
  ],
  [
    34758,
    67.31485,
    14.73648,
    22.3,
    24.92
  ],
  [
    34760,
    67.31512,
    14.7356,
    22.7,
    25.29
  ],
  [
    34762,
    67.31536,
    14.73457,
    21.8,
    25.43
  ],
  [
    34764,
    67.31554,
    14.73348,
    21.1,
    25.63
  ],
  [
    34766,
    67.31567,
    14.73234,
    21.4,
    25.82
  ],
  [
    34768,
    67.31575,
    14.73114,
    22.3,
    26.24
  ],
  [
    34770,
    67.31575,
    14.72992,
    21.8,
    26.1
  ],
  [
    34772,
    67.31569,
    14.72869,
    21.9,
    26.32
  ],
  [
    34774,
    67.31557,
    14.7275,
    22.6,
    26.34
  ],
  [
    34776,
    67.31543,
    14.72633,
    23.1,
    26.57
  ],
  [
    34778,
    67.31527,
    14.72516,
    23.5,
    26.57
  ],
  [
    34780,
    67.31512,
    14.72399,
    23.9,
    26.57
  ],
  [
    34812,
    67.30827,
    14.71911,
    16.1,
    25.12
  ],
  [
    34814,
    67.30772,
    14.71859,
    30.2,
    25.27
  ],
  [
    34816,
    67.3074,
    14.7177,
    33.7,
    25.21
  ],
  [
    34818,
    67.30712,
    14.71675,
    36.6,
    25.13
  ],
  [
    34820,
    67.30684,
    14.71583,
    39,
    25.1
  ],
  [
    34822,
    67.30658,
    14.71488,
    39.4,
    25.03
  ],
  [
    34824,
    67.30631,
    14.71396,
    39.7,
    24.97
  ],
  [
    34826,
    67.30605,
    14.71302,
    39.9,
    24.94
  ],
  [
    34828,
    67.30578,
    14.71208,
    40.3,
    24.91
  ],
  [
    34830,
    67.30552,
    14.71115,
    40.7,
    24.89
  ],
  [
    34832,
    67.30527,
    14.7102,
    40.8,
    24.88
  ],
  [
    34834,
    67.30502,
    14.70923,
    40.6,
    24.92
  ],
  [
    34836,
    67.30479,
    14.70824,
    40,
    25.01
  ],
  [
    34838,
    67.30456,
    14.70725,
    39.6,
    25.17
  ],
  [
    34840,
    67.30432,
    14.70625,
    39.1,
    25.35
  ],
  [
    34842,
    67.30408,
    14.70524,
    38.9,
    25.52
  ],
  [
    34844,
    67.30384,
    14.70422,
    38,
    25.66
  ],
  [
    34847,
    67.30348,
    14.70269,
    37.3,
    25.77
  ],
  [
    34849,
    67.30326,
    14.70165,
    36.9,
    25.84
  ],
  [
    34851,
    67.30304,
    14.70063,
    37,
    25.12
  ],
  [
    34853,
    67.30283,
    14.6996,
    36.7,
    25.12
  ],
  [
    34857,
    67.30263,
    14.69717,
    37.8,
    25.12
  ],
  [
    34859,
    67.3025,
    14.69587,
    36.9,
    25.12
  ],
  [
    34861,
    67.30225,
    14.69484,
    37,
    26.09
  ],
  [
    34863,
    67.30196,
    14.69387,
    36.5,
    26.01
  ],
  [
    34865,
    67.30166,
    14.69296,
    36.2,
    26
  ],
  [
    34867,
    67.30136,
    14.69206,
    36.4,
    25.87
  ],
  [
    34869,
    67.30106,
    14.69114,
    36.7,
    25.73
  ],
  [
    34871,
    67.30078,
    14.69022,
    37.1,
    25.6
  ],
  [
    34873,
    67.3005,
    14.68928,
    37.6,
    25.44
  ],
  [
    34875,
    67.30023,
    14.68833,
    38.2,
    25.28
  ],
  [
    34877,
    67.29997,
    14.68738,
    38.7,
    25.09
  ],
  [
    34879,
    67.2997,
    14.68645,
    39,
    24.94
  ],
  [
    34881,
    67.29944,
    14.68551,
    39.2,
    24.82
  ],
  [
    34883,
    67.29917,
    14.68459,
    39.4,
    24.77
  ],
  [
    34885,
    67.29888,
    14.68373,
    39.5,
    24.71
  ],
  [
    34887,
    67.29856,
    14.68295,
    39.5,
    24.7
  ],
  [
    34889,
    67.29821,
    14.68224,
    39.4,
    24.69
  ],
  [
    34891,
    67.29786,
    14.68155,
    39.5,
    24.72
  ],
  [
    34893,
    67.29751,
    14.68083,
    39.5,
    24.75
  ],
  [
    34895,
    67.29718,
    14.68005,
    39.5,
    24.78
  ],
  [
    34897,
    67.2969,
    14.67918,
    39,
    24.81
  ],
  [
    34900,
    67.2965,
    14.67778,
    38.2,
    24.92
  ],
  [
    34902,
    67.29625,
    14.67682,
    37.8,
    24.99
  ],
  [
    34904,
    67.29599,
    14.67585,
    37.5,
    24.99
  ],
  [
    34906,
    67.29576,
    14.67484,
    37.3,
    25.3
  ],
  [
    34908,
    67.29558,
    14.67376,
    36.7,
    25.41
  ],
  [
    34910,
    67.29545,
    14.67263,
    36.3,
    25.43
  ],
  [
    34912,
    67.29538,
    14.67145,
    35.2,
    25.67
  ],
  [
    34914,
    67.29536,
    14.67025,
    34.4,
    25.86
  ],
  [
    34916,
    67.29535,
    14.66904,
    33.8,
    26.08
  ],
  [
    34918,
    67.29534,
    14.66782,
    33.1,
    26.31
  ],
  [
    34920,
    67.29534,
    14.66659,
    32.7,
    26.58
  ],
  [
    34922,
    67.29533,
    14.66534,
    32.6,
    26.85
  ],
  [
    34924,
    67.2953,
    14.66409,
    32,
    27.3
  ],
  [
    34926,
    67.29525,
    14.66283,
    31.5,
    27.25
  ],
  [
    34928,
    67.29517,
    14.66157,
    31.3,
    27.43
  ],
  [
    34930,
    67.29507,
    14.66031,
    31.3,
    27.58
  ],
  [
    34932,
    67.29495,
    14.65907,
    31.5,
    27.67
  ],
  [
    34934,
    67.29477,
    14.65786,
    32,
    27.72
  ],
  [
    34936,
    67.29456,
    14.65669,
    32.3,
    27.77
  ],
  [
    34938,
    67.29433,
    14.65555,
    32.4,
    27.83
  ],
  [
    34940,
    67.2941,
    14.6544,
    32.4,
    27.81
  ],
  [
    34942,
    67.29389,
    14.65322,
    33.9,
    27.88
  ],
  [
    34945,
    67.29365,
    14.65138,
    35.2,
    27.89
  ],
  [
    34947,
    67.29353,
    14.65012,
    35.9,
    27.9
  ],
  [
    34949,
    67.29345,
    14.64884,
    36.6,
    27.9
  ],
  [
    34951,
    67.29337,
    14.64756,
    37.3,
    27.93
  ],
  [
    34953,
    67.29331,
    14.64627,
    37.9,
    27.95
  ],
  [
    34955,
    67.29326,
    14.64497,
    38.8,
    27.96
  ],
  [
    34957,
    67.29325,
    14.64367,
    39.2,
    27.98
  ],
  [
    34959,
    67.29326,
    14.64237,
    39.8,
    27.99
  ],
  [
    34961,
    67.29329,
    14.64107,
    40.3,
    28.02
  ],
  [
    34963,
    67.29331,
    14.63977,
    40.7,
    28.03
  ],
  [
    34965,
    67.29332,
    14.63846,
    41.1,
    28.01
  ],
  [
    34967,
    67.29328,
    14.63716,
    41.6,
    28.04
  ],
  [
    34969,
    67.29321,
    14.63587,
    42.2,
    28.04
  ],
  [
    34971,
    67.29314,
    14.63458,
    42.9,
    28.07
  ],
  [
    34973,
    67.29308,
    14.63329,
    43.6,
    28.11
  ],
  [
    34975,
    67.29306,
    14.63198,
    44,
    28.15
  ],
  [
    34977,
    67.29309,
    14.63068,
    44.4,
    28.13
  ],
  [
    34979,
    67.29316,
    14.62938,
    45.3,
    28.21
  ],
  [
    34981,
    67.29323,
    14.62808,
    45.8,
    28.21
  ],
  [
    34983,
    67.29329,
    14.62678,
    45.8,
    28.21
  ],
  [
    34993,
    67.2935,
    14.62016,
    45.6,
    28.1
  ],
  [
    34995,
    67.29348,
    14.61892,
    45.6,
    28.13
  ],
  [
    34997,
    67.29335,
    14.61767,
    44.9,
    28.18
  ],
  [
    34999,
    67.29318,
    14.61646,
    44.9,
    28.17
  ],
  [
    35001,
    67.293,
    14.61524,
    45.7,
    28.15
  ],
  [
    35003,
    67.29283,
    14.61402,
    45.8,
    28.12
  ],
  [
    35005,
    67.29266,
    14.61279,
    45.9,
    28.16
  ],
  [
    35007,
    67.29249,
    14.61156,
    46.2,
    28.23
  ],
  [
    35009,
    67.29232,
    14.61033,
    46.2,
    28.34
  ],
  [
    35011,
    67.29215,
    14.60909,
    47,
    28.43
  ],
  [
    35014,
    67.29189,
    14.60722,
    47.7,
    28.55
  ],
  [
    35016,
    67.29172,
    14.60596,
    48.3,
    28.66
  ],
  [
    35018,
    67.29155,
    14.6047,
    48.8,
    28.66
  ],
  [
    35020,
    67.29138,
    14.60344,
    49.4,
    28.83
  ],
  [
    35022,
    67.29119,
    14.60219,
    49.8,
    28.91
  ],
  [
    35024,
    67.29098,
    14.60096,
    49.8,
    29.08
  ],
  [
    35026,
    67.29075,
    14.59974,
    49.5,
    29.26
  ],
  [
    35028,
    67.29051,
    14.59853,
    49.2,
    29.49
  ],
  [
    35030,
    67.29028,
    14.59729,
    49,
    29.62
  ],
  [
    35032,
    67.29006,
    14.59602,
    48.8,
    29.83
  ],
  [
    35034,
    67.28988,
    14.59472,
    48.7,
    29.78
  ],
  [
    35036,
    67.2897,
    14.59342,
    48.6,
    29.68
  ],
  [
    35038,
    67.28953,
    14.59211,
    48.5,
    29.62
  ],
  [
    35040,
    67.28936,
    14.59081,
    48.3,
    29.55
  ],
  [
    35042,
    67.28919,
    14.58951,
    48.2,
    29.5
  ],
  [
    35044,
    67.28902,
    14.58822,
    48,
    29.47
  ],
  [
    35046,
    67.28885,
    14.58692,
    47.7,
    29.46
  ],
  [
    35048,
    67.28868,
    14.58563,
    47.5,
    29.43
  ],
  [
    35050,
    67.28852,
    14.58433,
    47.4,
    29.41
  ],
  [
    35052,
    67.28835,
    14.58304,
    47.2,
    29
  ],
  [
    35054,
    67.28818,
    14.58178,
    47.2,
    28.47
  ],
  [
    35056,
    67.28802,
    14.58054,
    47,
    27.82
  ],
  [
    35059,
    67.28778,
    14.57874,
    46.4,
    27.54
  ],
  [
    35061,
    67.28759,
    14.5776,
    45.9,
    26.78
  ],
  [
    35063,
    67.28736,
    14.57653,
    45.3,
    26.78
  ],
  [
    35065,
    67.28712,
    14.5755,
    44.8,
    25.95
  ],
  [
    35067,
    67.28687,
    14.5745,
    44.2,
    25.37
  ],
  [
    35069,
    67.28663,
    14.57352,
    43.7,
    24.99
  ],
  [
    35071,
    67.28639,
    14.57255,
    43.3,
    24.59
  ],
  [
    35073,
    67.28616,
    14.57159,
    43.1,
    24.18
  ],
  [
    35075,
    67.28593,
    14.57065,
    43,
    23.96
  ],
  [
    35077,
    67.2857,
    14.56973,
    43,
    23.2
  ],
  [
    35079,
    67.28549,
    14.56884,
    43,
    22.54
  ],
  [
    35081,
    67.28528,
    14.56796,
    43.1,
    21.76
  ],
  [
    35083,
    67.28509,
    14.56711,
    43,
    20.82
  ],
  [
    35085,
    67.28492,
    14.56627,
    43,
    19.79
  ],
  [
    35087,
    67.28477,
    14.56547,
    43,
    18.81
  ],
  [
    35089,
    67.28464,
    14.5647,
    43.1,
    17.83
  ],
  [
    35091,
    67.28452,
    14.56396,
    43.2,
    16.88
  ],
  [
    35093,
    67.28442,
    14.56324,
    43.3,
    16.08
  ],
  [
    35095,
    67.28432,
    14.56256,
    43.5,
    15.39
  ],
  [
    35097,
    67.28424,
    14.5619,
    43.5,
    14.76
  ],
  [
    35099,
    67.28417,
    14.56126,
    43.8,
    14.17
  ],
  [
    35102,
    67.28408,
    14.56034,
    44.1,
    13.56
  ],
  [
    35104,
    67.28403,
    14.55976,
    44.2,
    13.56
  ],
  [
    35106,
    67.28398,
    14.5592,
    44.4,
    12.32
  ],
  [
    35108,
    67.28393,
    14.55868,
    44.5,
    11.68
  ],
  [
    35110,
    67.28389,
    14.55819,
    44.7,
    11.01
  ],
  [
    35112,
    67.28385,
    14.55772,
    44.9,
    10.03
  ],
  [
    35114,
    67.28381,
    14.55729,
    45,
    9.39
  ],
  [
    35116,
    67.28377,
    14.55688,
    45.2,
    8.68
  ],
  [
    35118,
    67.28374,
    14.55651,
    45.3,
    7.89
  ],
  [
    35120,
    67.28371,
    14.55618,
    45.1,
    6.92
  ],
  [
    35122,
    67.28369,
    14.55589,
    44.9,
    5.81
  ],
  [
    35124,
    67.28367,
    14.55566,
    44.7,
    4.66
  ],
  [
    35126,
    67.28366,
    14.55548,
    44.5,
    3.48
  ],
  [
    35128,
    67.28365,
    14.55536,
    44.4,
    2.26
  ],
  [
    35130,
    67.28364,
    14.55529,
    44.2,
    1.04
  ],
  [
    35132,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35134,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35136,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35138,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35140,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35142,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35144,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35146,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35149,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35151,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35153,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35155,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35157,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35159,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35161,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35163,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35165,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35167,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35169,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35171,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35173,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35175,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35177,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35179,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35181,
    67.28364,
    14.55526,
    44.2,
    0
  ],
  [
    35183,
    67.28364,
    14.55525,
    40.1,
    1.48
  ],
  [
    35185,
    67.28363,
    14.55517,
    39.7,
    2.19
  ],
  [
    35187,
    67.28362,
    14.55505,
    40.1,
    2.74
  ],
  [
    35189,
    67.2836,
    14.55491,
    40.2,
    3.28
  ],
  [
    35191,
    67.28359,
    14.55475,
    40.6,
    3.8
  ],
  [
    35194,
    67.28355,
    14.55447,
    40.9,
    4.31
  ],
  [
    35196,
    67.28353,
    14.55426,
    41.2,
    4.73
  ],
  [
    35198,
    67.2835,
    14.55403,
    41.4,
    4.73
  ],
  [
    35200,
    67.28347,
    14.55379,
    41.6,
    4.73
  ],
  [
    35202,
    67.28343,
    14.55353,
    41.9,
    5.88
  ],
  [
    35204,
    67.28339,
    14.55325,
    42.1,
    6.44
  ],
  [
    35206,
    67.28334,
    14.55296,
    42.5,
    6.82
  ],
  [
    35208,
    67.28329,
    14.55266,
    42.8,
    7.17
  ],
  [
    35210,
    67.28324,
    14.55235,
    43.2,
    7.33
  ],
  [
    35212,
    67.28318,
    14.55202,
    43.7,
    7.82
  ],
  [
    35214,
    67.28312,
    14.55168,
    44.2,
    8.14
  ],
  [
    35216,
    67.28305,
    14.55133,
    44.6,
    8.47
  ],
  [
    35218,
    67.28298,
    14.55097,
    44.8,
    8.81
  ],
  [
    35220,
    67.28291,
    14.55059,
    44.9,
    9.16
  ],
  [
    35222,
    67.28284,
    14.5502,
    45.1,
    9.49
  ],
  [
    35224,
    67.28276,
    14.54979,
    45.2,
    9.86
  ],
  [
    35226,
    67.28268,
    14.54937,
    45.1,
    10.26
  ],
  [
    35228,
    67.2826,
    14.54893,
    45.1,
    10.79
  ],
  [
    35230,
    67.28251,
    14.54846,
    45.1,
    11.33
  ],
  [
    35232,
    67.28241,
    14.54798,
    45.5,
    11.85
  ],
  [
    35234,
    67.28231,
    14.54747,
    45.5,
    12.4
  ],
  [
    35236,
    67.28221,
    14.54694,
    45.6,
    12.92
  ],
  [
    35239,
    67.28205,
    14.5461,
    45.6,
    13.49
  ],
  [
    35241,
    67.28194,
    14.54551,
    45.4,
    14.16
  ],
  [
    35243,
    67.28182,
    14.54489,
    44.8,
    14.16
  ],
  [
    35245,
    67.2817,
    14.54423,
    44.2,
    14.16
  ],
  [
    35247,
    67.28157,
    14.54355,
    43.6,
    16.29
  ],
  [
    35249,
    67.28143,
    14.54284,
    43.4,
    17.3
  ],
  [
    35251,
    67.28131,
    14.54208,
    43.3,
    17.97
  ],
  [
    35253,
    67.2812,
    14.54127,
    43.3,
    18.61
  ],
  [
    35255,
    67.28111,
    14.54041,
    43.2,
    18.91
  ],
  [
    35257,
    67.28105,
    14.53952,
    43,
    19.78
  ],
  [
    35259,
    67.281,
    14.53859,
    42.7,
    20.35
  ],
  [
    35261,
    67.28095,
    14.53763,
    42.5,
    20.89
  ],
  [
    35263,
    67.2809,
    14.53665,
    42.4,
    21.4
  ],
  [
    35265,
    67.28083,
    14.53566,
    42.3,
    21.87
  ],
  [
    35267,
    67.28072,
    14.53466,
    42.3,
    22.33
  ],
  [
    35269,
    67.28057,
    14.53369,
    42.4,
    22.75
  ],
  [
    35271,
    67.28039,
    14.53273,
    42.6,
    23.18
  ],
  [
    35273,
    67.28019,
    14.53177,
    42.8,
    23.56
  ],
  [
    35275,
    67.27999,
    14.53079,
    43,
    23.88
  ],
  [
    35277,
    67.27981,
    14.52977,
    43.2,
    24.22
  ],
  [
    35279,
    67.27966,
    14.5287,
    43.3,
    24.55
  ],
  [
    35281,
    67.27956,
    14.52758,
    43.3,
    24.83
  ],
  [
    35283,
    67.27947,
    14.52643,
    43.2,
    25.14
  ],
  [
    35286,
    67.27934,
    14.52469,
    43.3,
    25.41
  ],
  [
    35288,
    67.27922,
    14.52354,
    43.2,
    25.41
  ],
  [
    35290,
    67.27909,
    14.52239,
    43.1,
    25.41
  ],
  [
    35292,
    67.27894,
    14.52124,
    42.9,
    25.91
  ],
  [
    35294,
    67.27879,
    14.52009,
    42.8,
    26.05
  ],
  [
    35296,
    67.27865,
    14.51893,
    42.7,
    26.13
  ],
  [
    35298,
    67.27853,
    14.51776,
    42.6,
    26.16
  ],
  [
    35300,
    67.27842,
    14.51657,
    42.5,
    26.18
  ],
  [
    35302,
    67.27833,
    14.51538,
    42.5,
    26.18
  ],
  [
    35304,
    67.27825,
    14.51418,
    42.5,
    26.13
  ],
  [
    35306,
    67.27819,
    14.51297,
    42.8,
    26.06
  ],
  [
    35308,
    67.27814,
    14.51177,
    43.2,
    25.93
  ],
  [
    35310,
    67.27811,
    14.51057,
    43.7,
    25.78
  ],
  [
    35312,
    67.27809,
    14.50937,
    44.3,
    25.73
  ],
  [
    35314,
    67.27808,
    14.50818,
    44.8,
    25.39
  ],
  [
    35316,
    67.27805,
    14.50701,
    45.3,
    25.2
  ],
  [
    35318,
    67.27798,
    14.50586,
    45.9,
    25.04
  ],
  [
    35320,
    67.27787,
    14.50473,
    46.4,
    24.91
  ],
  [
    35322,
    67.27772,
    14.50364,
    47.2,
    24.84
  ],
  [
    35324,
    67.27756,
    14.50257,
    47.6,
    24.68
  ],
  [
    35326,
    67.2774,
    14.5015,
    48.9,
    24.55
  ],
  [
    35328,
    67.27728,
    14.50041,
    49.9,
    24.47
  ],
  [
    35331,
    67.27719,
    14.49873,
    50.9,
    24.38
  ],
  [
    35333,
    67.27719,
    14.4976,
    51.4,
    24.38
  ],
  [
    35335,
    67.27725,
    14.49648,
    52,
    24.38
  ],
  [
    35337,
    67.27736,
    14.49539,
    52.1,
    24.25
  ],
  [
    35339,
    67.27753,
    14.49435,
    51.9,
    24.29
  ],
  [
    35341,
    67.27773,
    14.49334,
    51.7,
    24.37
  ],
  [
    35343,
    67.27794,
    14.49235,
    51.3,
    24.51
  ],
  [
    35345,
    67.27816,
    14.49135,
    50.9,
    24.61
  ],
  [
    35347,
    67.27836,
    14.49032,
    50.6,
    24.95
  ],
  [
    35349,
    67.27853,
    14.48923,
    50.1,
    25.36
  ],
  [
    35351,
    67.27868,
    14.4881,
    49.6,
    25.71
  ],
  [
    35353,
    67.27881,
    14.48695,
    49.3,
    26.1
  ],
  [
    35355,
    67.27895,
    14.48578,
    48.7,
    26.47
  ],
  [
    35357,
    67.2791,
    14.4846,
    48.4,
    26.82
  ],
  [
    35359,
    67.27926,
    14.48341,
    48.3,
    27.13
  ],
  [
    35361,
    67.27943,
    14.48223,
    48.5,
    27.4
  ],
  [
    35363,
    67.27961,
    14.48105,
    48.6,
    27.74
  ],
  [
    35365,
    67.2798,
    14.47985,
    49,
    27.79
  ],
  [
    35367,
    67.28,
    14.47865,
    49.4,
    27.99
  ],
  [
    35369,
    67.28019,
    14.47743,
    49.7,
    28.17
  ],
  [
    35371,
    67.28038,
    14.47621,
    49.7,
    28.31
  ],
  [
    35374,
    67.28067,
    14.47437,
    51.5,
    28.41
  ],
  [
    35376,
    67.28086,
    14.47314,
    52.3,
    28.53
  ],
  [
    35378,
    67.28105,
    14.4719,
    53.7,
    28.53
  ],
  [
    35380,
    67.28125,
    14.47066,
    54.2,
    28.53
  ],
  [
    35382,
    67.28146,
    14.46944,
    54.4,
    28.79
  ],
  [
    35384,
    67.28168,
    14.46822,
    54.7,
    29.05
  ],
  [
    35386,
    67.28191,
    14.46701,
    54.9,
    29.13
  ],
  [
    35388,
    67.28218,
    14.46582,
    55,
    29.66
  ],
  [
    35390,
    67.28247,
    14.46464,
    54.4,
    29.65
  ],
  [
    35392,
    67.28276,
    14.46348,
    53.6,
    30.02
  ],
  [
    35394,
    67.28307,
    14.46233,
    53.1,
    30.31
  ],
  [
    35396,
    67.28339,
    14.46118,
    52.9,
    30.31
  ],
  [
    35398,
    67.28374,
    14.46009,
    52.3,
    30.71
  ],
  [
    35400,
    67.28412,
    14.45901,
    51.5,
    30.8
  ],
  [
    35402,
    67.2845,
    14.45796,
    50.6,
    30.84
  ],
  [
    35404,
    67.28489,
    14.45695,
    49.7,
    30.88
  ],
  [
    35406,
    67.28529,
    14.45597,
    48.7,
    30.86
  ],
  [
    35408,
    67.28571,
    14.45501,
    47.9,
    30.87
  ],
  [
    35410,
    67.28613,
    14.45407,
    47.5,
    30.85
  ],
  [
    35412,
    67.28654,
    14.45312,
    47.2,
    30.78
  ],
  [
    35414,
    67.28696,
    14.45218,
    47,
    30.65
  ],
  [
    35416,
    67.28737,
    14.45124,
    47,
    30.49
  ],
  [
    35418,
    67.28778,
    14.45031,
    47,
    30.22
  ],
  [
    35421,
    67.28838,
    14.44895,
    47,
    29.48
  ],
  [
    35423,
    67.28877,
    14.44807,
    46.9,
    29.48
  ],
  [
    35425,
    67.28914,
    14.44722,
    46.9,
    29.48
  ],
  [
    35427,
    67.28951,
    14.44639,
    46.4,
    27.17
  ],
  [
    35429,
    67.28986,
    14.44555,
    46,
    26.21
  ],
  [
    35431,
    67.29018,
    14.44469,
    45.5,
    25.99
  ],
  [
    35433,
    67.29051,
    14.44382,
    45.1,
    25.99
  ],
  [
    35440,
    67.29126,
    14.44032,
    41.6,
    25.99
  ],
  [
    35442,
    67.29142,
    14.43919,
    42.4,
    25.03
  ],
  [
    35444,
    67.29159,
    14.43812,
    41.9,
    25.08
  ],
  [
    35446,
    67.29176,
    14.43704,
    41.3,
    25.17
  ],
  [
    35448,
    67.29193,
    14.43595,
    40.6,
    25.19
  ],
  [
    35450,
    67.29209,
    14.43487,
    39.9,
    25.16
  ],
  [
    35452,
    67.29226,
    14.4338,
    39.1,
    24.99
  ],
  [
    35454,
    67.29243,
    14.43273,
    38.4,
    24.86
  ],
  [
    35456,
    67.2926,
    14.43166,
    37.6,
    24.73
  ],
  [
    35458,
    67.29277,
    14.4306,
    36.9,
    24.59
  ],
  [
    35460,
    67.29293,
    14.42955,
    36.3,
    24.46
  ],
  [
    35462,
    67.2931,
    14.4285,
    35.8,
    24.22
  ],
  [
    35464,
    67.29326,
    14.42746,
    35.8,
    24.48
  ],
  [
    35466,
    67.29342,
    14.42643,
    35.2,
    23.66
  ],
  [
    35468,
    67.29357,
    14.42541,
    34.4,
    23.32
  ],
  [
    35470,
    67.29367,
    14.42437,
    32.8,
    23
  ],
  [
    35472,
    67.29373,
    14.42332,
    31.5,
    22.71
  ],
  [
    35474,
    67.29374,
    14.42227,
    30.4,
    22.4
  ],
  [
    35476,
    67.29371,
    14.42124,
    30.4,
    22.13
  ],
  [
    35478,
    67.29363,
    14.42024,
    28.8,
    21.86
  ],
  [
    35481,
    67.29344,
    14.41881,
    27.7,
    21.59
  ],
  [
    35483,
    67.29327,
    14.41793,
    27.3,
    21.59
  ],
  [
    35485,
    67.29307,
    14.41712,
    27,
    21.59
  ],
  [
    35487,
    67.29284,
    14.41638,
    26.5,
    20.05
  ],
  [
    35489,
    67.2926,
    14.41573,
    26,
    19.17
  ],
  [
    35491,
    67.29234,
    14.41514,
    26.7,
    18.81
  ],
  [
    35493,
    67.29209,
    14.41457,
    26,
    18.19
  ],
  [
    35495,
    67.29184,
    14.41403,
    25.1,
    18.01
  ],
  [
    35497,
    67.2916,
    14.41349,
    24.3,
    17.55
  ],
  [
    35499,
    67.29136,
    14.41296,
    23.4,
    17.27
  ],
  [
    35501,
    67.29113,
    14.41244,
    22.5,
    17.02
  ],
  [
    35503,
    67.29089,
    14.41193,
    21.6,
    16.76
  ],
  [
    35505,
    67.29067,
    14.41142,
    20.7,
    16.48
  ],
  [
    35507,
    67.29044,
    14.41092,
    19.9,
    16.21
  ],
  [
    35509,
    67.29022,
    14.41043,
    19,
    15.89
  ],
  [
    35511,
    67.29001,
    14.40995,
    18.2,
    15.52
  ],
  [
    35513,
    67.2898,
    14.40948,
    17.3,
    15.14
  ],
  [
    35515,
    67.2896,
    14.40902,
    16.6,
    14.8
  ],
  [
    35517,
    67.28942,
    14.40854,
    16,
    14.42
  ],
  [
    35519,
    67.28925,
    14.40805,
    15.5,
    14.01
  ],
  [
    35521,
    67.28909,
    14.40755,
    15.5,
    13.57
  ],
  [
    35523,
    67.28895,
    14.40705,
    14.5,
    13.09
  ],
  [
    35526,
    67.28877,
    14.4063,
    13.9,
    12.58
  ],
  [
    35528,
    67.28867,
    14.40581,
    13.6,
    12.58
  ],
  [
    35530,
    67.28859,
    14.40532,
    13.4,
    12.58
  ],
  [
    35532,
    67.28851,
    14.40486,
    13.1,
    10.93
  ],
  [
    35534,
    67.28843,
    14.40442,
    12.8,
    10.19
  ],
  [
    35536,
    67.28836,
    14.40399,
    12.7,
    9.99
  ],
  [
    35538,
    67.28829,
    14.40357,
    12.6,
    9.92
  ],
  [
    35540,
    67.28822,
    14.40314,
    12.6,
    9.91
  ],
  [
    35542,
    67.28815,
    14.40272,
    12.5,
    9.95
  ],
  [
    35544,
    67.28808,
    14.4023,
    12.5,
    9.96
  ],
  [
    35546,
    67.28801,
    14.40187,
    12.5,
    9.97
  ],
  [
    35548,
    67.28794,
    14.40145,
    12.5,
    9.95
  ],
  [
    35550,
    67.28787,
    14.40102,
    12.5,
    9.94
  ],
  [
    35552,
    67.2878,
    14.4006,
    12.5,
    9.91
  ],
  [
    35554,
    67.28774,
    14.40017,
    12.5,
    9.9
  ],
  [
    35556,
    67.28769,
    14.39973,
    12.6,
    9.86
  ],
  [
    35558,
    67.28764,
    14.39929,
    12.6,
    9.81
  ],
  [
    35560,
    67.28759,
    14.39886,
    12.6,
    9.78
  ],
  [
    35562,
    67.28754,
    14.39842,
    12.7,
    9.76
  ],
  [
    35564,
    67.28748,
    14.39799,
    12.7,
    9.72
  ],
  [
    35566,
    67.28742,
    14.39757,
    12.7,
    9.67
  ],
  [
    35568,
    67.28735,
    14.39716,
    12.8,
    9.66
  ],
  [
    35571,
    67.28725,
    14.39654,
    12.9,
    9.63
  ],
  [
    35573,
    67.28718,
    14.39613,
    12.9,
    9.63
  ],
  [
    35575,
    67.28712,
    14.39572,
    13,
    9.63
  ],
  [
    35577,
    67.28705,
    14.39531,
    13,
    9.53
  ],
  [
    35579,
    67.28699,
    14.39491,
    13.1,
    9.48
  ],
  [
    35581,
    67.28692,
    14.3945,
    13.1,
    9.44
  ],
  [
    35583,
    67.28686,
    14.3941,
    13.2,
    8.98
  ],
  [
    35585,
    67.2868,
    14.39373,
    13.2,
    8.66
  ],
  [
    35587,
    67.28674,
    14.39339,
    13.3,
    7.58
  ],
  [
    35589,
    67.28669,
    14.39308,
    13.4,
    6.97
  ],
  [
    35591,
    67.28664,
    14.39279,
    13.4,
    6.65
  ],
  [
    35593,
    67.2866,
    14.39251,
    13.5,
    6.44
  ],
  [
    35595,
    67.28655,
    14.39224,
    13.5,
    6.19
  ],
  [
    35597,
    67.28651,
    14.39198,
    13.5,
    5.83
  ],
  [
    35599,
    67.28648,
    14.39175,
    13.5,
    5
  ],
  [
    35601,
    67.28645,
    14.39156,
    13.4,
    3.93
  ],
  [
    35603,
    67.28642,
    14.39142,
    13.4,
    2.8
  ],
  [
    35605,
    67.28641,
    14.39133,
    13.7,
    1.61
  ],
  [
    35607,
    67.2864,
    14.39129,
    13.8,
    0.33
  ],
  [
    35609,
    67.2864,
    14.39129,
    13.8,
    0
  ],
  [
    35611,
    67.2864,
    14.39129,
    13.9,
    0
  ],
  [
    35614,
    67.2864,
    14.39129,
    13.9,
    0
  ],
  [
    35616,
    67.2864,
    14.39129,
    13.9,
    0
  ],
  [
    35618,
    67.2864,
    14.39129,
    13.9,
    0
  ],
  [
    35620,
    67.2864,
    14.39129,
    13.9,
    0
  ],
  [
    35622,
    67.2864,
    14.39129,
    13.9,
    0
  ],
  [
    35624,
    67.2864,
    14.39129,
    13.9,
    0
  ],
  [
    35626,
    67.2864,
    14.39129,
    13.9,
    0
  ],
  [
    35628,
    67.2864,
    14.39129,
    13.9,
    0
  ],
  [
    35630,
    67.2864,
    14.39129,
    13.9,
    0
  ],
  [
    35767,
    67.287158,
    14.418387,
    14,
    1.18
  ]
];

export default [
    {
        "time": 0,
        "name": "Trondheim S"
    },
    {
        "time": 2209,
        "name": "Værnes",
        "notes": "Trondheim lufthavn"
    },
    {
        "time": 2501,
        "name": "Stjørdal"
    },
    {
        "time": 5063,
        "name": "Levanger"
    },
    {
        "time": 6059,
        "name": "Verdal"
    },
    {
        "time": 7774,
        "name": "Steinkjer"
    },
    {
        "time": 9750,
        "name": "Jørstad",
        "notes": "Request Stop"
    },
    {
        "time": 10056,
        "name": "Snåsa"
    },
    {
        "time": 11673,
        "name": "Grong"
    },
    {
        "time": 12605,
        "name": "Harran",
        "notes": "Request Stop"
    },
    {
        "time": 13359,
        "name": "Lassemoen",
        "notes": "Request Stop"
    },
    {
        "time": 14826,
        "name": "Namsskogan",
        "notes": "Request Stop"
    },
    {
        "time": 16132,
        "name": "Majavatn",
        "notes": "Request Stop"
    },
    {
        "time": 18035,
        "name": "Trofors"
    },
    {
        "time": 19736,
        "name": "Mosjøen"
    },
    {
        "time": 21570,
        "name": "Drevvatn",
        "notes": "Request Stop"
    },
    {
        "time": 22810,
        "name": "Bjerka"
    },
    {
        "time": 24122,
        "name": "Mo i Rana"
    },
    {
        "time": 28976,
        "name": "Lønsdal",
        "notes": "Request Stop"
    },
    {
        "time": 30438,
        "name": "Røkland",
        "notes": "Request Stop"
    },
    {
        "time": 31086,
        "name": "Rognan"
    },
    {
        "time": 32394,
        "name": "Fauske"
    },
    {
        "time": 33625,
        "name": "Valnesfjord",
        "notes": "Request Stop"
    },
    {
        "time": 34430,
        "name": "Oteråga",
        "notes": "Request Stop"
    },
    {
        "time": 35132,
        "name": "Mørkved",
        "notes": "Request Stop"
    },
    {
        "time": 35607,
        "name": "Bodø",
        "notes": "Final call of this train"
    }
]
import { icon, Map as LeafletMap } from "leaflet";
import React, { Component } from "react";
import { MapContainer, MapConsumer, Marker, Popup, TileLayer } from "react-leaflet";
import { Waypoint } from "./waypoints/waypoints";

type Props = {
    waypoint: Waypoint
}

export class Map extends Component<Props> {
    render() {
        const markerIcon = icon({
            iconUrl: './location-duotone.svg',
            iconSize: [38, 95],
        });

        return (
            <MapContainer 
              center={[this.props.waypoint.position.latitude, this.props.waypoint.position.longitude]} 
              zoom={12} 
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
                maxZoom={18}
                id={'mapbox/streets-v11'}
                tileSize={512}
                zoomOffset={-1}
                accessToken='pk.eyJ1IjoiYWxleHRpbW1lcm1hbm4iLCJhIjoiY2trdXM0dGRjMXRpdzJ1czFhYzlsdHJnZCJ9.dHo5iTyFKxVaiss-ArJmKw'
              />
              <TileLayer 
                url="https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png"
                attribution='Style: <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a> <a href="http://www.openrailwaymap.org/">OpenRailwayMap</a>'
              />
              <Marker 
                position={[this.props.waypoint.position.latitude, this.props.waypoint.position.longitude]} 
                icon={markerIcon}
              >
                <Popup>
                  {Math.round(this.props.waypoint.speed * 3.6)} km/h
                </Popup>
              </Marker>

              <MapConsumer>
                {(map: LeafletMap) => {
                  map.setView([this.props.waypoint.position.latitude, this.props.waypoint.position.longitude])
                  return null
                }}
              </MapConsumer>
            </MapContainer>
        );
    }
}
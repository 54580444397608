import { defaultCipherList } from "constants";
import { NONAME } from "dns";
import { Component } from "react";

type Props = {
    color?: string;
}

export class NsbLogo extends Component<Props, {}> {
    private color: string;

    constructor(props: Props) {
        super(props);
        this.color = props.color ?? '#D52B1E';
    }

    render() {
        return (
            <svg version="1.1" id="Layer_1" x="0px" y="0px"
                viewBox="0 0 340.2 340.2" 
                // style={{"enable-background": "new 0 0 340.2 340.2"}}
                >
                <path style={{fill: 'none'}} d="M340.2,310.2c0,16.5-13.5,29.9-29.9,29.9H29.9C13.5,340.2,0,326.7,0,310.2V29.9C0,13.5,13.5,0,29.9,0h280.3
        c16.5,0,29.9,13.5,29.9,29.9v280.3H340.2z"/>
                <g>
                    <path style={{fill: this.color}} d="M296.9,162.8c-0.2-0.5-0.5-0.7-0.9-0.7s-99-0.2-99-0.2c-0.5,0-0.9,0.5-0.9,1.1c0,0.4,0.2,0.7,0.6,1
            c5.4,3.8,12.4,11.1,15.8,17.9l0,0c0.2,0.3,0.5,0.6,0.9,0.7h0.3l58.6,0.1c1.7,0,4.1-0.9,5.3-2l18.9-16.4
            C296.9,163.8,297.1,163.3,296.9,162.8z"/>
                    <path style={{fill: this.color}} d="M176.2,114.5c-7.1-3.2-9.4-5.2-9.4-8.5c0-2.4,2-5.2,7.6-5.2c5.8,0,10.2,2.5,12.3,3.5c0.1,0.1,0.2,0.1,0.4,0
            c0.1,0,0.2-0.2,0.2-0.3l-0.1-11c0-0.2,0-0.4-0.2-0.5c-2-0.9-6.2-2.5-12.5-2.5c-12.6,0-21.1,6.9-21.1,17.2c0,6.8,4.9,13,14.2,17.2
            c6.5,2.9,8.6,4.9,8.6,8.2c0,3.7-3.3,5.9-8.6,5.9c-4.7,0-11.6-2.5-14.3-3.9c-0.1,0-0.3-0.1-0.4,0c-0.1,0.1-0.2,0.2-0.2,0.3V146
            c0,0.2,0.1,0.4,0.2,0.5c2.7,1.4,8.5,2.9,14.4,2.9c13.6,0,22.3-7,22.3-17.8C189.5,124.1,185.3,118.7,176.2,114.5z M111.3,111.9
            l18.4,36c0.1,0.2,0.2,0.2,0.4,0.2h12.4c0.2,0,0.4-0.2,0.4-0.4V91.1c0-0.2-0.2-0.4-0.4-0.4h-11.3c-0.2,0-0.4,0.2-0.4,0.4l0.7,35
            l-17.9-35.2c-0.1-0.1-0.2-0.2-0.4-0.2H100c-0.2,0-0.4,0.2-0.4,0.4v56.7c0,0.2,0.2,0.4,0.4,0.4h11.3c0.2,0,0.4-0.2,0.4-0.4
            L111.3,111.9z M228.5,117.4c5.6-2.4,8.8-6.9,8.8-12.4c0-4.5-2.2-8.5-6-11c-3.9-2.6-8.7-3.7-16.6-3.7c-6.2,0-12,0.3-15.4,0.3
            c-0.2,0-0.3,0.2-0.3,0.4v57.2c0,0.2,0.2,0.4,0.4,0.4c2.1,0,6.1,0.1,12.7,0.1c10.6,0,17.6-1.7,21.9-5.5c3.5-3.2,5.4-7.3,5.4-11.9
            C239.2,124.7,235.1,119.4,228.5,117.4z M210.7,99.9c1,0,3.8,0,5.5,0c4.9,0,7.7,2.4,7.7,6.6c0,4.3-3.4,6.9-8.7,6.9h-4.5V99.9z
             M215.7,138.9c-1.5,0-4,0-5,0v-15.7h4.6c4.8,0,9.9,2,9.9,7.8C225.3,135.9,221.7,138.9,215.7,138.9z"/>
                    <path style={{fill: this.color}} d="M209.2,199.3h42c1.7,0,2,0.9,0.7,2l-7.9,6.9c-1.3,1.1-3.6,2-5.3,2h-29.1c-1,17.8-17.3,39-41.7,39.9
            c-25.7,0.9-37.9-16-39.7-19.7c-0.6-1.3,0.2-2.1,1.1-1.8c1.1,0.3,19.9,8.3,38,3.2c14.3-4,19.8-12.9,20.6-21.6h-85
            c-1.7,0-4.1-0.9-5.3-2l-7.9-6.9c-1.3-1.1-0.9-2,0.7-2h97.1c-3.2-9.4-12.3-16.6-25-16.9c-11-0.3-94.4,0.2-94.4,0.2
            c-1.6,0-4-0.9-5.3-2L43.9,164c-1.3-1.1-0.9-2,0.8-2c0,0,104.1-0.2,118-0.2C178.4,161.8,203.4,170,209.2,199.3z"/>
                </g>
            </svg>
        )
    }
}
import { Component, createRef, MutableRefObject, ReactNode, useRef, VideoHTMLAttributes } from "react";

type Props = {
    videoLinks: string[],
    onTick: (video: HTMLVideoElement) => void,
    showControls?: boolean,
    startTime?: number,
}

export class Video extends Component<Props> {
    videoRef: MutableRefObject<HTMLVideoElement|null>;
    showControls: boolean;

    constructor(props: Props) {
        super(props)
        this.videoRef = createRef();
        this.showControls = props.showControls ?? true;

        this.tick = this.tick.bind(this);
    }

    componentDidMount(): void {
        if (this.videoRef.current === null || this.props.startTime === undefined) return;
        this.videoRef.current.currentTime = this.props.startTime;
    }

    private tick(): void {
        const video = this.videoRef.current;
        if (video === null) return;

        this.props.onTick(video);
    }

    render(): ReactNode {
        return (
            <video ref={this.videoRef} controls={this.showControls} onTimeUpdate={this.tick}>
                {this.props.videoLinks.map((link) => {
                    return <source src={link} key={link} />
                })}
            </video>
        );
    }
}
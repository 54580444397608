import { Component } from "react";

import { StopInfo } from './stops/stops'

type Props = {
    stopInfo: StopInfo
}

export class NextStopDisplay extends Component<Props> {
    render() {
        return (
            <div>
            <div className="nextStopLabel">{this.props.stopInfo.isCurrentlyStopped ? "This is" : "Next stop"}</div>
            <div className="nextStop">{this.props.stopInfo.stop.name}</div>
            {this.props.stopInfo.stop.notes && <div className="nextStopNotes">{this.props.stopInfo.stop.notes}</div>}
          </div>
        );
    }
}